import React, { Component, useEffect, useState } from "react";
import AboutGsl from '../../assets/inner-images/about/about_gsl.jpg';
import companyProfile from '../../assets/inner-images/about/company_profile.jpg';
import companyHistory from '../../assets/inner-images/about/company_history.jpg';
import factsFigures from '../../assets/inner-images/about/facts_figures.jpg';
import cargoServices from '../../assets/inner-images/cargo_services/cargo_services.jpg';
import dangerousCargo from '../../assets/inner-images/cargo_services/dangerous_cargo.jpg';
import dryCargo from '../../assets/inner-images/cargo_services/dry_cargo.jpg';
import gslMonitor from '../../assets/inner-images/cargo_services/gsl_monitor.jpg';
import inlandTransport from '../../assets/inner-images/cargo_services/inland_transport.jpg';
import projectCargo from '../../assets/inner-images/cargo_services/project_cargo.jpg';
import reeferCargo from '../../assets/inner-images/cargo_services/reefer_cargo.jpg';
import temperatureControlledShipping from '../../assets/inner-images/cargo_services/temperature_controlled_shipping.jpg';
import highValueOrSensitiveCargo from '../../assets/inner-images/cargo_services/high_value_or_sensitive_cargo.jpg';
import Catalogue from '../../assets/inner-images/tools/catalogue.jpg';
import eblService from '../../assets/inner-images/tools/ebl_service.jpg';
import requestaQuote from '../../assets/inner-images/tools/request_a_quote.jpg';
import siSubmission from '../../assets/inner-images/tools/si_submission.jpg';
import bookingSubmission from '../../assets/inner-images/tools/booking_submission.jpg';
import vgmMassUpload from '../../assets/inner-images/tools/vgm_mass_upload.jpg';
import careers from '../../assets/inner-images/careers/careers.jpg';
import demurrage_and_detention_tariff from '../../assets/inner-images/local_info/demurrage_and_detention_tariff.jpg';
import local_info from '../../assets/inner-images/local_info/local_info.jpg';
import help_customer_advisory from '../../assets/inner-images/help/help_customer_advisory.jpg';
import newsletter from '../../assets/inner-images/newsletter/newsletter.jpg';
import terms_of_use from '../../assets/inner-images/help/terms_of_use.jpg';
import enquiry_us from '../../assets/inner-images/enquiry/enquiry_us.jpg';
import faq_gsl from '../../assets/inner-images/enquiry/faq_gsl.jpg';
import catalogues from '../../assets/inner-images/catalogue/catalogue.jpg';
import roro from '../../assets/inner-images/roro/roro.jpg';
import subscribe from '../../assets/inner-images/about/subscribe.jpg';
import shapeBlue from '../../../src/assets/banner-shape-blue.svg';
import shapeYellow from '../../../src/assets/banner-shape.svg';

const innerJSON =
{
    "subscribe": {
        "title": "Theo dõi",
        "discription": "Nhận thông báo theo thời gian thực về lô hàng của bạn và dịch vụ hiển thị đầy đủ về tình trạng hàng hóa",
        "url": subscribe,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
        
    },
    "about_gsl": {
        "title": "Danh mục hàng hóa",
        "discription": "Lập kế hoạch vận chuyển của bạn theo thông số kỹ thuật và nhu cầu hàng hóa của bạn",
        "url": AboutGsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',

    },
    "cargo_catalogue": {
        "title": "Danh mục hàng hóa",
        "discription": "Lập kế hoạch vận chuyển của bạn theo thông số kỹ thuật và nhu cầu hàng hóa của bạn",
        "url": catalogues,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
        
    },
    "request_a_quote": {
        "title": "Yêu cầu Báo giá",
        "discription": "Sứ mệnh và mục tiêu của chúng tôi là cung cấp nhiều dịch vụ vận chuyển hàng hóa để phục vụ công việc kinh doanh của bạn",
        "url": requestaQuote,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_submission": {
        "title": "Đặt chỗ",
        "discription": "Sứ mệnh và mục tiêu của chúng tôi là cung cấp nhiều dịch vụ vận chuyển hàng hóa để phục vụ công việc kinh doanh của bạn",
        "url": bookingSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ebl_service": {
        "title": "Dịch vụ vận đơn điện tử",
        "discription": "Sứ mệnh và mục tiêu của chúng tôi là cung cấp nhiều dịch vụ vận chuyển hàng hóa để phục vụ công việc kinh doanh của bạn",
        "url": eblService,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "si_submission": {
        "title": "Gửi SI",
        "discription": "Gửi chi tiết hàng hóa, các bên liên quan và các yêu cầu một cách liền mạch",
        "url": siSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ecommerce_partners": {
        "title": "Đối tác thương mại điện tử",
        "discription": "Sứ mệnh và mục tiêu của chúng tôi là cung cấp nhiều dịch vụ vận chuyển hàng hóa để phục vụ công việc kinh doanh của bạn",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "vgm_mass_upload": {
        "title": "Khai báo VGM hàng loạt",
        "discription": "Khai báo VGM cho nhiều lô hàng một cách dễ dàng và phù hợp",
        "url": vgmMassUpload,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "high_value_or_sensitive_cargo": {
        "title": "Hàng giá trị cao hoặc hàng nhạy cảm",
        "discription": "GSL Monitor cung cấp cho bạn các công cụ chính xác để theo dõi, giám sát và kiểm soát hàng hóa có giá trị của bạn, đảm bảo hàng hóa được vận chuyển an toàn",
        "url": highValueOrSensitiveCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "enquiry": {
        "title": "Hãy liên hệ với chúng tôi",
        "discription": "Hãy liên lạc với chúng tôi! Chúng tôi rất sẵn lòng cung cấp cho bạn tất cả thông tin bạn cần",
        "url": enquiry_us,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "faq": {
        "title": "Các câu hỏi thường gặp",
        "discription": "Tìm hiểu thêm về Gold Star Line và các dịch vụ của chúng tôi",
        "url": faq_gsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "roro-private": {
        "title": "Dịch vụ vận chuyển xe hơi",
        "discription": "Đáp ứng nhu cầu ngày càng tăng đối với các lô hàng xe hơi trên toàn thế giới",
        "url": roro,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "newsletter": {
        "title": "Tin tức & Cập nhật",
        "discription": "Hãy theo dõi các thông tin và thông báo mới nhất!",
        "url": newsletter,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_newsletter": {
        "title": "Xem bản tin",
        "discription": "Sứ mệnh và mục tiêu của chúng tôi là cung cấp nhiều dịch vụ vận chuyển hàng hóa để phục vụ công việc kinh doanh của bạn",
        "url": newsletter,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "cargo_services": {
        "title": "Các dịch vụ hàng hóa",
        "discription": "Nhiệm vụ của chúng tôi là cung cấp đầy đủ các dịch vụ vận tải đường biển cho tất cả các loại hàng hóa.",
        "url": cargoServices,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "gsl_monitor": {
        "title": "GSL Monitor",
        "discription": "Theo dõi, giám sát và thậm chí kiểm soát môi trường hàng hóa của bạn, đặc biệt khi vận chuyển hàng nhạy cảm, giá trị cao hoặc hàng lạnh",
        "url": gslMonitor,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dry_cargo": {
        "title": "Hàng khô",
        "discription": "Với hơn 60 năm kinh nghiệm chuyên xử lý bất kỳ loại hàng khô nào, chúng tôi sẽ đáp ứng đầy đủ nhu cầu của bạn.",
        "url": dryCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "reefer_cargo": {
        "title": "Hàng lạnh",
        "discription": "Công nghệ tiên tiến của chúng tôi đảm bảo hàng hóa lạnh của bạn được vận chuyển an toàn, ở nhiệt độ phù hợp và đúng thời gian.",
        "url": reeferCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "project_cargo": {
        "title": "Hàng dự án",
        "discription": "Chúng tôi có các giải pháp sáng tạo và kiến thức chuyên môn cần thiết để vận chuyển hàng hóa đặc biệt của bạn đi khắp thế giới một cách an toàn.",
        "url": projectCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "inland_transport": {
        "title": "Vận chuyển nội địa",
        "discription": "Cung cấp dịch vụ vận tải đường bộ đáng tin cậy thông qua mạng lưới giao hàng tận nơi rộng lớn của chúng tôi",
        "url": inlandTransport,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "temperature_controlled_shipping": {
        "title": "Vận chuyển yêu cầu kiểm soát nhiệt độ ",
        "discription": "Ướp lạnh hoặc đông lạnh, chúng tôi cung cấp dịch vụ vận chuyển an toàn cho tất cả các sản phẩm nhạy cảm với nhiệt độ",
        "url": temperatureControlledShipping,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dangerous_cargo": {
        "title": "Hàng nguy hiểm",
        "discription": "Chúng tôi cung cấp dịch vụ tận tâm và chuyên nghiệp cho việc lưu trữ và vận chuyển an toàn hàng hóa nguy hiểm",
        "url": dangerousCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "about_gsl": {
        "title": "Về chúng tôi",
        "discription": "Gold Star Line - một công ty lớn chuyên các tuyến Nội Á, Châu Đại Dương và Châu Phi, phục vụ các thị trường năng động với cách tiếp cận linh hoạt",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_profile": {
        "title": "Hồ sơ công ty",
        "discription": "Nhiệm vụ của chúng tôi là tiếp tục tìm kiếm các giải pháp sáng tạo để hỗ trợ nhu cầu của khách hàng",
        "url": companyProfile,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_history": {
        "title": "Câu chuyện của chúng tôi",
        "discription": "Cùng du hành xuyên thời gian và khám phá lịch sử, các cột mốc và sự phát triển của Gold Star Line",
        "url": companyHistory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "facts_figures": {
        "title": "Sự kiện & Số liệu",
        "discription": "Nhiệm vụ của chúng tôi là tiếp tục tìm kiếm các giải pháp sáng tạo để hỗ trợ nhu cầu của khách hàng",
        "url": factsFigures,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "demurrage_and_detention_tariff": {
        "title": "Bảng phí lưu container và lưu bãi",
        "discription": "Phí lưu container, lưu bãi không phải là một bí ẩn",
        "url": demurrage_and_detention_tariff,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "local_info": {
        "title": "Thông tin địa phương",
        "discription": "Luôn cập nhật thông tin hữu ích dành riêng cho vị trí của bạn",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "unsubscribe_schedule": {
        "title": "Bỏ theo dõi lịch tàu",
        "discription": "",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "contact_us": {
        "title": "Thông tin các văn phòng đại lý của GSL",
        "discription": "Nhận các chi tiết văn phòng, địa chỉ và các liên hệ của chúng tôi",
        "url": enquiry_us,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "careers": {
        "title": "Việc làm",
        "discription": "Tham gia Gold Star Line và phát triển cùng chúng tôi!",
        "url": careers,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "solas_regulation": {
        "title": "Quy định solas",
        "discription": "Sứ mệnh và mục tiêu của chúng tôi là cung cấp nhiều dịch vụ vận chuyển hàng hóa để phục vụ công việc kinh doanh của bạn",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "customer_advisory": {
        "title": "Tư vấn khách hàng",
        "discription": "Hãy theo dõi các thông báo và tư vấn của chúng tôi",
        "url": help_customer_advisory,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "accessibility": {
        "title": "Khả năng tiếp cận",
        "discription": "Cải thiện chất lượng cuộc sống với mô hình trợ năng của chúng tôi",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "data_protection_policy": {
        "title": "Chính sách bảo vệ dữ liệu",
        "discription": "Nhận thông báo về các chính sách bảo vệ dữ liệu cá nhân của chúng tôi",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_cookies_policy": {
        "title": "Chính sách của GSL về Cookie và truy vết dữ liệu duyệt web",
        "discription": "Cam kết của chúng tôi về dữ liệu cá nhân và quyền riêng tư dữ liệu của bạn",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "quotation_terms_conditions": {
        "title": "Các điều kiện và điều khoản chào giá",
        "discription": "Tuân thủ các điều khoản và điều kiện trong báo giá của chúng tôi",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "terms_of_use": {
        "title": "Điều khoản sử dụng",
        "discription": "Nguyên tắc và Điều khoản dịch vụ",
        "url": terms_of_use,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_customer_advisory": {
        "title": "Tư vấn khách hàng",
        "discription": "Các thông báo và tư vấn tới khách hàng",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "bl_terms_conditions": {
        "title": "Điều khoản B/L",
        "discription": "Luôn được thông báo về các điều khoản pháp lý về vận đơn",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_go_terms_conditions": {
        "title": "Điều khoản và điều kiện của GSL GO",
        "discription": "Luôn được thông báo về các điều khoản pháp lý về GSL GO",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "about_car_carrier": {
        "title": "Về vận chuyển ô tô",
        "discription": "",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "egsl_terms_conditions": {
        "title": "Điều khoản và điều kiện của eGSL",
        "discription": "Luôn được thông báo về các điều khoản pháp lý về vận đơn",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_clause": {
        "title": "Các điều khoản đặt chỗ",
        "discription": "Tìm hiểu các điều khoản liên quan đến tài liệu xác nhận đặt chỗ của chúng tôi",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_company_news": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_regulation": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_industry": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },"sea_waybill_terms_conditions": {
        "title": " SEA WAYBILL TERMS AND CONDITIONS",
        "discription": "TERMS AND CONDITIONS",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
}


export default innerJSON;