const getintouch =
{
    "en": {
        "GETINTOUCHWITHOURSALES": "Get In Touch!",
        "ADDRESS": "ADDRESS",
        "PHONE": "PHONE",
        "EMAIL": "EMAIL",
        "Typethecodeyousee": "Type the code you see",
        "SENDMESSAGE":"SEND MESSAGE"
      
    
    },
    "cn": {
        "GETINTOUCHWITHOURSALES": "保持聯繫！",
        "ADDRESS": "地址",
        "PHONE": "電話",
        "EMAIL": "電郵",
        "Typethecodeyousee": "請輸入驗證碼",
        "SENDMESSAGE":"發送"
       
    },
    "zh": {
        "GETINTOUCHWITHOURSALES": "保持联系！",
        "ADDRESS": "地址",
        "PHONE": "电话",
        "EMAIL": "电邮",
        "Typethecodeyousee": "请输入验证码",
        "SENDMESSAGE":"发送"
       
    },
    "ko": {
        "GETINTOUCHWITHOURSALES": "연락 주세요",
        "ADDRESS": "주소",
        "PHONE": "연락처",
        "EMAIL": "EMAIL",
        "Typethecodeyousee": "보이는 코드를 입력하십시오",
        "SENDMESSAGE":"제출"
       
    },
    "vi": {
        "GETINTOUCHWITHOURSALES": "Hãy giữ liên lạc!",
        "ADDRESS": "ĐỊA CHỈ",
        "PHONE": "SỐ ĐIỆN THOẠI",
        "EMAIL": "THƯ ĐIỆN TỬ",
        "Typethecodeyousee": "Vui lòng nhập mã mà bạn thấy",
        "SENDMESSAGE":"GỬI TIN NHẮN"
       
    },
    "th": {
        "GETINTOUCHWITHOURSALES": "ได้รับการติดต่อ!",
        "ADDRESS": "ที่อยู่",
        "PHONE": "เบอร์โทร",
        "EMAIL": "อีเมล์",
        "Typethecodeyousee": "โปรดใส่รหัสตามภาพ",
        "SENDMESSAGE":"ส่งข้อความ"
       
    },
    "in": {
        "GETINTOUCHWITHOURSALES": "Berhubungan",
        "ADDRESS": "ALAMAT",
        "PHONE": "TELEPON",
        "EMAIL": "EMAIL",
        "Typethecodeyousee": "Masukkan kode yang anda lihat",
        "SENDMESSAGE":"KIRIM PESAN"
      
    
    },
    "ja": {
        "GETINTOUCHWITHOURSALES": "ご連絡ください！",
        "ADDRESS": "住所",
        "PHONE": "電話",
        "EMAIL": "メールアドレス",
        "Typethecodeyousee": "表示されたコードを入力します",
        "SENDMESSAGE":"メッセージを送信"
      
    
    },
}
export default getintouch;