import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../layout";
import GET_IN_TOUCH from "../../get_in_touch/index";
import "flatpickr/dist/themes/material_green.css";
import { useForm, Controller } from "react-hook-form";
import * as axios from "axios";
import Api from "../../../../package.json";
import "../tools/css/css-loader.css";
import moment from "moment";
import downarrow from "../../../assets/down-arrow.png";
import { Accordion, Card, Button, ButtonGroup, Modal } from "react-bootstrap";
import track_shipment_lang from "../../lang/track_shipment";
import "./shipment_unsubscribe.css";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import swal from "sweetalert";
const qs = require("qs");
var jwt = require("jsonwebtoken");
const Shipment_unsubscribe = (props) => {
  const [country_code, setCountryCode] = useState(window.country_code);
  const [trackShipmentRes, settrackShipmentRes] = useState([]);
  const [loaderFlag, setloaderFlag] = useState("is-inactive");
  const [pdfbuttonstatus, setpdfbuttonstatus] = useState(false);
  const [outputflag, setoutputflag] = useState(false);
  const [trackShipmentflag, settrackShipmentflag] = useState(false);
  const submitForm = useRef();
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);
  const tagPattern = /^[A-Z]{7}[0-9]{7}$/;
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [token, setToken] = useState(null);
  const contentRefs = useRef([]);
  const [visibility, setVisibility] = useState([false, false, false]);
  const captchaRef = useRef(null);
  const [seletedTags, setSeletedTags] = useState([]);
  // Function to handle the input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // Function to handle key press (space or comma)
  const handleKeyDown = (event) => {
    if (event.key === " " || event.key === "," || event.key === "Enter") {
      event.preventDefault(); // Prevent default behavior

      // Split input on space, comma, or enter and remove empty strings
      const newTags = inputValue
        .split(/[\s,]+/) // Split by space, comma, or multiple spaces
        .filter((tag) => tag.trim() !== ""); // Remove empty entries

      let validTags = [];
      let invalidTags = [];
      let existingTags = [];

      newTags.forEach((tag) => {
        if (tagPattern.test(tag.trim())) {
          if (!tags.includes(tag.trim())) {
            // If tag is valid and not a duplicate
            validTags.push(tag.trim());
          } else {
            existingTags.push(tag.trim());
          }
        } else {
          // Collect invalid tags
          invalidTags.push(tag.trim());
        }
      });

      if (invalidTags.length > 0) {
        setError(`Invalid B/L: ${invalidTags.join(", ")}.`);
      } else if (existingTags.length > 0) {
        setError(`Already existing B/L: ${existingTags.join(", ")}`);
      } else {
        setError("");
      }

      // Add valid tags to the tag list
      if (validTags.length > 0) {
        setTags([...tags, ...validTags]);
      }

      setInputValue(""); // Clear the input field
    }
  };
  const toggleVisibility = (index) => {
    if (contentRefs.current[index]) {
      // Update visibility state for that specific item
      const updatedVisibility = [...visibility];
      updatedVisibility[index] = !updatedVisibility[index];
      setVisibility(updatedVisibility);

      // Toggle the display of the corresponding ref element
      contentRefs.current[index].style.display = updatedVisibility[index]
        ? "block"
        : "none";
    }
  };
  // Function to remove a tag
  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
    setError("");
  };

  const remoaveAllTag = () => {
    setTags([]);
    setError("");
  };

  var lang = window.localStorage.getItem("lang");
  if (window.localStorage.getItem("lang") == null) {
    lang = "en";
    window.localStorage.setItem("lang", "en");
  }

  const headers = {
    type: "application/json",
    Authorization: `Bearer ` + window.localStorage.getItem("access_token"),
  };
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (token != null) {

      if (seletedTags.length > 0) {
        const response = await axios.post(
          Api.userdata.url + "/track_a_shipment_subscription",
          qs.stringify({ containerids: seletedTags, email: data.email, subscription_captcha_captcha: token, }),
          { headers: headers }
        );
        const res = await response.data;

        if (res['status'] === 'OK') {
          swal("Thank you !!!", res['message'], "success");
        }
        else {
          swal("Error !", res['message'], "error");
        }
        console.log(data);
      } else {
        swal("Error !", "Please select alteast one BL Number", "error");
      }

    }
    else {
      swal("Error !", "Invalid captcha", "error");
    }
    //track_shipment_submit(data);
  };
  const checkcontainer = async (val) => {
    const response = await axios.post(
      Api.userdata.url + "/container_bl_validation",
      qs.stringify({ containerid: val }),
      { headers: headers }
    );
    const res = await response.data;
    if (res == false) {
      return "Enter container number - format : 4 letters followed by 7 digits";
    } else {
      return true;
    }
  };

  const track_shipment_submit = async () => {

    if (tags.length > 0) {
      setpdfbuttonstatus(false);
      setloaderFlag("is-active");
      const response = await axios.post(
        Api.userdata.url + "/get_track_shipment_val_new",
        qs.stringify({ containerids: tags, country_code: country_code, }),
        { headers: headers }
      );
      const json = await response.data;

      setSeletedTags([]);
      settrackShipmentRes([]);
      if (json.length > 0) {

        setoutputflag(true);
        setpdfbuttonstatus(true);
        settrackShipmentRes(json);
        console.log(trackShipmentRes);
      } else {
        setoutputflag(false);
        setpdfbuttonstatus(false);
      }
      settrackShipmentflag(true);
      setloaderFlag("is-inactive");
    }
    else {
      swal("Error !", 'Please enter your BL or Contianer Number', "error");
    }


  };
  const track_shipment_submit_pdf = async () => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/get_track_shipment_pdf",
      qs.stringify({
        country_code: country_code,
        containerid: getValues("containerid"),
      }),
      {
        headers: headers,
        responseType: "blob",
      }
    );
    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const tempLink = document.createElement("a");
    tempLink.href = fileURL;
    tempLink.setAttribute("download", getValues("containerid") + ".pdf");
    tempLink.click();

    setloaderFlag("is-inactive");
  };
  const track_shipment_submit_print = async () => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/get_track_shipment_pdf",
      qs.stringify({
        country_code: country_code,
        containerid: getValues("containerid"),
      }),
      {
        headers: headers,
        responseType: "blob",
      }
    );
    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    window.focus();
    setloaderFlag("is-inactive");
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSeletedTags([...seletedTags, value]);
    } else {
      setSeletedTags(seletedTags.filter((item) => item !== value));
    }
  };
  /* useEffect(() => {
    var id = props.match.params.containerid;
    if(id != null)
    {
     setValue('containerid',id);
    //submitForm.current.click();
    }
  
  }, []); */
  return (
    <Layout>
      <div class={"loadernew loader-double " + loaderFlag}></div>
      <section class="inner-center">
        <br></br>
      
        <div class="container">
          <div class="row m-0">
            <div
              class="col-lg-12 col-md-12 col-lg-12"
              style={{ minHeight: "500px;" }}
            >
             
              <div class="form-fback">
                <div class="row dout-inner m-0">
                  <div class="col-lg-12 col-md-12 col-sm-12 pot-form p-0">
                    <div class="">
                      <h3>Un Subscribe Shipment Notification</h3>
                      <p>Unsubscribe your existing notification</p>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 p-0 pot-form siv-unsubscribe-box">
                    <div class="row">
                      <div class="col-md-5 col-lg-5 col-sm-12">
                        <label>
                          Please enter your email
                        </label>
                       
                         
                          <div className="inputBox">
                            <input
                              type="text"
                              id="containerid"
                              name="containerid"
                              class="form-control"
                              style={{ textTransform: "uppercase" }}
                              value={inputValue}
                              onChange={handleInputChange}
                              onKeyDown={handleKeyDown}
                            />

                            
                          </div>
                       
                        {error && <p class="errors">{error}</p>}
                      </div>
                      <div class="col-md-2 col-lg-2 col-sm-12 track-cont-no">
                        <button
                          class="btn btn-default hovereffect portal-searc-tariff"
                          id="submitDetails"
                          name="my-buttons"
                          type="button"
                          onClick={track_shipment_submit}
                          ref={submitForm}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  </form> */}
            </div>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="row trackshipments_results">
              {(tags.length > 0) ? <div className="col-md-12 form-fback notifcationBar d-flex">
                {/* <label>
                  <input type="checkbox" className="ts-checkbox" />
                  Select All
                </label> */}
                <a
                  href="javascript:void(0);"
                  onClick={handleShow}
                  className="notificationBtn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={22}
                    height={24}
                    fill="none"
                  >
                    <path
                      fill="#666"
                      d="M11.454 0c.834 0 1.507.67 1.507 1.5v.9c3.437.694 6.026 3.722 6.026 7.35v.881a9 9 0 0 0 2.283 5.982l.349.389a1.5 1.5 0 0 1-1.126 2.498H2.415a1.51 1.51 0 0 1-1.374-.886 1.491 1.491 0 0 1 .25-1.612l.348-.39a8.99 8.99 0 0 0 2.283-5.98V9.75c0-3.628 2.59-6.656 6.026-7.35v-.9c0-.83.673-1.5 1.506-1.5ZM9.322 23.123A2.994 2.994 0 0 1 8.442 21h6.025c0 .797-.315 1.56-.88 2.123a3.021 3.021 0 0 1-2.133.877 3.02 3.02 0 0 1-2.132-.877Z"
                    />
                  </svg>{" "}
                  Notify me about all shipments ({seletedTags.length})
                </a>
              </div> : <></>}


              <div className="trackShipmentResultCard">
                <div className="col-md-12">

                  {(trackShipmentRes.length > 0) ?

                    trackShipmentRes.map(
                      (trackShipmentRess, i) => {
                        {
                          {
                            var res = trackShipmentRess['message'];
                            return (
                              <>  <div className="trackShipmentResultRow">
                                <div className="trackShipmentResultRowInner">
                                  <div className="checkbox">
                                    <input type="checkbox" name="select_container[]" value={res['containerid']} onChange={handleCheckboxChange} className="ts-checkbox" />
                                  </div>
                                  <div className="cartItem">
                                    <label>BL NUMBER</label>
                                    <h3>{res['containerid']}</h3>
                                  </div>
                                  <div className="cartItem">
                                    <label>Port of Loading (POL)</label>
                                    <h3>{res['pol']['port_of_loading']}</h3>
                                  </div>
                                  {/*  <div className="cartItem">
                                    <label>Terminal Name</label>
                                    <h3>N/A</h3>
                                  </div> */}
                                  <div className="cartItem">
                                    <label>Sailing Date</label>
                                    <h3>{res['pol']['sailingdate']}</h3>
                                  </div>
                                  <div className="cartItem">
                                    <label>Port of Discharge (POD)</label>
                                    <h3>{res['pod']['port_of_discharge']}</h3>
                                  </div>
                                  {/*  <div className="cartItem">
                                    <label>Terminal Name</label>
                                    <h3>N/A</h3>
                                  </div> */}
                                  <div className="cartItem">
                                    <label>ETD</label>
                                    <h3>{res['pod']['estimated_time_of_arrival']}</h3>
                                  </div>
                                  <div className="cartItem1">
                                    <div
                                      class="arrowButton" style={{ transform: visibility[0] ? "rotate(90deg)" : "rotate(0deg)" }}
                                      onClick={() => toggleVisibility(i)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M9 18L15 12L9 6"
                                          stroke="white"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div
                                  ref={(el) => (contentRefs.current[i] = el)}
                                  style={{ display: visibility[i] ? "block" : "none" }}
                                  className="trackShipmentResultRowInnerResult"
                                >
                                  <>
                                    {" "}
                                    <div>
                                      {res["container_flag"] == true ? (
                                        <div class="row track-shipment-output">
                                          <table class="table track-shipment-output-tb1">
                                            <thead>
                                              <th>
                                                <h3 class="track-shipment-heading">
                                                  <span>{track_shipment_lang[lang].BLNo} </span>
                                                  {res["containerid"]}
                                                </h3>
                                              </th>
                                            </thead>
                                          </table>
                                          <div class="col-md-6 col-lg-6 col-sm-12 p-1">
                                            <div class="card p-3 main-cargo-head-container">
                                              <table class="table table1">
                                                <tbody>
                                                  {res["pol"]["port_of_receipt"] !=
                                                    "" ? (
                                                    <tr>
                                                      <td class="table-font-weight">
                                                        {track_shipment_lang[lang].PortofReceipt}
                                                      </td>
                                                      <td>
                                                        {
                                                          res["pol"][
                                                          "port_of_receipt"
                                                          ]
                                                        }
                                                      </td>
                                                    </tr>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  <tr>
                                                    <td class="table-font-weight">
                                                      {track_shipment_lang[lang].PortofLoading}
                                                    </td>
                                                    <td>
                                                      {res["pol"]["port_of_loading"]}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    {" "}
                                                    <td class="table-font-weight">
                                                      {track_shipment_lang[lang].TerminalName}
                                                    </td>
                                                    <td>
                                                      {res["pol"]["terminal_name"]}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td class="table-font-weight">
                                                      {track_shipment_lang[lang].SailingDate}
                                                    </td>
                                                    <td>
                                                      {moment(
                                                        res["pol"]["sailingdate"]
                                                      ).format("DD-MMM-YYYY")}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                          <div class="col-md-6 col-lg-6 col-sm-12 p-1 ">
                                            <div class="card p-3 main-cargo-head-container">
                                              <table class="table table1">
                                                <tbody>
                                                  <tr>
                                                    {" "}
                                                    <td class="table-font-weight">
                                                      {track_shipment_lang[lang].PortofDischarge}
                                                    </td>
                                                    <td>
                                                      {
                                                        res["pod"][
                                                        "port_of_discharge"
                                                        ]
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td class="table-font-weight">
                                                      {track_shipment_lang[lang].FinalDestination}:
                                                    </td>
                                                    <td>
                                                      {
                                                        res["pod"][
                                                        "final_destination"
                                                        ]
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td class="table-font-weight">
                                                      {track_shipment_lang[lang].EstimatedTimeofArrival}
                                                    </td>
                                                    <td>
                                                      {
                                                        res["pod"][
                                                        "estimated_time_of_arrival"
                                                        ]
                                                      }
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                          {typeof res["response"]["blRouteLegs"] !==
                                            "undefined" &&
                                            res["response"]["blRouteLegs"].map(
                                              (blRouteLegsss, i) => {
                                                {
                                                  {
                                                    var last_leg = true;
                                                    if (
                                                      res["response"]["blRouteLegs"]
                                                        .length -
                                                      1 ==
                                                      i
                                                    ) {
                                                      if (
                                                        blRouteLegsss["arrivalDateTz"][
                                                        "dateTime"
                                                        ] != null &&
                                                        blRouteLegsss["portToType"] == "DEL"
                                                      ) {
                                                        last_leg = true;
                                                      } else {
                                                        last_leg = false;
                                                      }
                                                    }

                                                    var ETA = "";

                                                    if (i != 0) {
                                                      var arrivalDateTz =
                                                        res["response"][
                                                        "blRouteLegs"
                                                        ][i - 1]["arrivalDateTz"]["dateTime"];
                                                      if (arrivalDateTz != null) {
                                                        ETA =
                                                          moment(arrivalDateTz).format(
                                                            "DD-MMM-YYYY"
                                                          );
                                                        /* 	var date = date_create($arrivalDateTz);
                       ETA = date_format($date,"d-M-Y"); */
                                                      }
                                                    }
                                                  }
                                                  return (
                                                    <>
                                                      <div class="table-responsive p-1">
                                                        <table
                                                          class="table"
                                                          style={{
                                                            background: `#f3f3f3`,
                                                            color: `#123c60`,
                                                          }}
                                                        >
                                                          <tbody>
                                                            <tr
                                                              style={{
                                                                background:
                                                                  last_leg == true
                                                                    ? ``
                                                                    : `#ffe787`,
                                                              }}
                                                            >
                                                              <td width="25%">
                                                                <p
                                                                  class="table-font-weight"
                                                                  style={{ paddingRight: `20px` }}
                                                                >
                                                                  {typeof res[
                                                                    "array"
                                                                  ][
                                                                    blRouteLegsss["portFromType"]
                                                                  ] !== "undefined"
                                                                    ? res["array"][
                                                                    blRouteLegsss[
                                                                    "portFromType"
                                                                    ]
                                                                    ]
                                                                    : blRouteLegsss[
                                                                    "portFromType"
                                                                    ]}
                                                                </p>
                                                                <p>
                                                                  {blRouteLegsss["portNameFrom"] +
                                                                    ", " +
                                                                    blRouteLegsss[
                                                                    "countryNameFrom"
                                                                    ]}
                                                                </p>
                                                              </td>
                                                              {blRouteLegsss["voyage"] != null ? (
                                                                <td
                                                                  width="25%"
                                                                  style={{
                                                                    background: `#ffe2d1`,
                                                                  }}
                                                                >
                                                                  <p
                                                                    class="table-font-weight"
                                                                    style={{
                                                                      paddingRight: `20px`,
                                                                    }}
                                                                  >
                                                                    {track_shipment_lang[lang].VesselVoyage}
                                                                  </p>
                                                                  <p>
                                                                    {blRouteLegsss["vesselName"]}/
                                                                    {blRouteLegsss["voyage"] +
                                                                      " (" +
                                                                      blRouteLegsss["vessel"] +
                                                                      ")"}
                                                                  </p>
                                                                </td>
                                                              ) : last_leg ? (
                                                                <td
                                                                  width="25%"
                                                                  style={{
                                                                    background: `#ffe2d1`,
                                                                  }}
                                                                >
                                                                  <p
                                                                    class="table-font-weight"
                                                                    style={{
                                                                      paddingRight: `20px`,
                                                                    }}
                                                                  >
                                                                    {track_shipment_lang[lang].TruckRail}
                                                                  </p>
                                                                  <p></p>
                                                                </td>
                                                              ) : (
                                                                <td width="25%"> </td>
                                                              )}
                                                              <td width="25%">
                                                                {ETA != "" ? (
                                                                  <>
                                                                    <p
                                                                      class="table-font-weight"
                                                                      style={{
                                                                        paddingRight: `20px`,
                                                                      }}
                                                                    >
                                                                      {" "}
                                                                      {track_shipment_lang[lang].ETA}
                                                                    </p>
                                                                    <p>{ETA}</p>
                                                                  </>
                                                                ) : (
                                                                  <></>
                                                                )}
                                                              </td>
                                                              <td width="25%">
                                                                {typeof blRouteLegsss[
                                                                  "sailingDateTz"
                                                                ]["dateTime"] !== "undefined" &&
                                                                  blRouteLegsss["sailingDateTz"][
                                                                  "dateTime"
                                                                  ] != null ? (
                                                                  <>
                                                                    <p
                                                                      class="table-font-weight"
                                                                      style={{
                                                                        paddingRight: `20px`,
                                                                      }}
                                                                    >
                                                                      {track_shipment_lang[lang].ETD}
                                                                    </p>
                                                                    <p>
                                                                      {moment(
                                                                        blRouteLegsss[
                                                                        "sailingDateTz"
                                                                        ]["dateTime"]
                                                                      ).format("DD-MMM-YYYY")}
                                                                    </p>
                                                                  </>
                                                                ) : (
                                                                  <></>
                                                                )}
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                      {res["response"]["blRouteLegs"]
                                                        .length !=
                                                        i + 1 ? (
                                                        <div class="col-12 text-center">
                                                          <img
                                                            src={downarrow}
                                                            style={{ width: `25px` }}
                                                          />
                                                        </div>
                                                      ) : (
                                                        <></>
                                                      )}
                                                      {blRouteLegsss["arrivalDateTz"][
                                                        "dateTime"
                                                      ] != null &&
                                                        blRouteLegsss["portToType"] == "DEL" ? (
                                                        <>
                                                          <div class="col-12 text-center">
                                                            <img
                                                              src={downarrow}
                                                              style={{ width: `25px` }}
                                                            />
                                                          </div>
                                                          <div class="table-responsive p-1">
                                                            <table
                                                              class="table"
                                                              style={{
                                                                background: `#f3f3f3`,
                                                                color: `#123c60`,
                                                              }}
                                                            >
                                                              <tbody>
                                                                <tr
                                                                  style={{
                                                                    background: `#ffe787`,
                                                                  }}
                                                                >
                                                                  <td width="25%">
                                                                    <p
                                                                      class="table-font-weight"
                                                                      style={{
                                                                        paddingRight: `20px`,
                                                                      }}
                                                                    >
                                                                      {typeof res[
                                                                        "array"
                                                                      ][
                                                                        blRouteLegsss[
                                                                        "portToType"
                                                                        ]
                                                                      ] !== "undefined"
                                                                        ? res[
                                                                        "array"
                                                                        ][
                                                                        blRouteLegsss[
                                                                        "portToType"
                                                                        ]
                                                                        ]
                                                                        : blRouteLegsss[
                                                                        "portToType"
                                                                        ]}
                                                                    </p>
                                                                    <p>
                                                                      {blRouteLegsss[
                                                                        "portNameTo"
                                                                      ] +
                                                                        ", " +
                                                                        blRouteLegsss[
                                                                        "countryNameTo"
                                                                        ]}
                                                                    </p>
                                                                  </td>
                                                                  <td width="25%"></td>
                                                                  <td width="25%">
                                                                    <p
                                                                      class="table-font-weight"
                                                                      style={{
                                                                        paddingRight: `20px;`,
                                                                      }}
                                                                    >
                                                                      {track_shipment_lang[lang].ETA}{" "}
                                                                    </p>
                                                                    <p>
                                                                      {" "}
                                                                      {moment(
                                                                        blRouteLegsss[
                                                                        "arrivalDateTz"
                                                                        ]["dateTime"]
                                                                      ).format("DD-MMM-YYYY")}
                                                                    </p>
                                                                  </td>
                                                                  <td width="25%"></td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              }
                                            )}
                                        </div>
                                      ) : (
                                        <div class="row track-shipment-output">
                                          <table class="table track-shipment-output-tb1">
                                            <thead>
                                              <th>
                                                <h3 class="track-shipment-heading">
                                                  <span>{track_shipment_lang[lang].ContainerNo}</span>
                                                  {res["containerid"]}
                                                </h3>
                                              </th>
                                            </thead>
                                          </table>
                                        </div>
                                      )}
                                    </div>
                                    <Accordion>
                                      <div class="table-responsive">
                                        <table class="table ">
                                          <thead >
                                            <tr style={{ background: '#133d60', color: '#FFF' }}>
                                              <th scope="col" width="15%">
                                                {track_shipment_lang[lang].Container}
                                              </th>
                                              <th scope="col" width="35%">
                                                {track_shipment_lang[lang].LastActivity}
                                              </th>
                                              <th scope="col" width="20%">
                                                {track_shipment_lang[lang].Location}
                                              </th>
                                              <th scope="col" width="12%">
                                                {track_shipment_lang[lang].Date}
                                              </th>
                                              <th scope="col" width="18%">
                                                {track_shipment_lang[lang].VesselVoyage}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {typeof res["result"] !== "undefined" &&
                                              res["result"].map((results, i) => {
                                                {
                                                }
                                                return (
                                                  <>
                                                    <tr class="expand-button">
                                                      <Accordion.Toggle
                                                        as={ButtonGroup}
                                                        variant="link"
                                                        eventKey={i + 1}
                                                      >
                                                        <td class="expand-button">
                                                          {results["container"]}
                                                        </td>
                                                      </Accordion.Toggle>
                                                      <td>{results["last_activity"]}</td>
                                                      <td>
                                                        {results["placeFromDesc"] != null
                                                          ? results["placeFromDesc"]
                                                          : "" +
                                                            ", " +
                                                            (results["location"] != null)
                                                            ? results["location"]
                                                            : ""}
                                                      </td>
                                                      <td>
                                                        {results["date"] != null
                                                          ? moment(results["date"]).format(
                                                            "DD-MMM-YYYY"
                                                          )
                                                          : ""}
                                                      </td>
                                                      <td> </td>
                                                    </tr>

                                                    <tr>
                                                      <td
                                                        colSpan={5}
                                                        style={{ padding: `0px !important` }}
                                                      >
                                                        <Accordion.Collapse eventKey={i + 1}>
                                                          <table class="table table2">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col" width="15%"></th>
                                                                <th scope="col" width="35%">
                                                                  {track_shipment_lang[lang].LastActivity}
                                                                </th>
                                                                <th scope="col" width="20%">
                                                                  {track_shipment_lang[lang].Location}
                                                                </th>
                                                                <th scope="col" width="12%">
                                                                  {track_shipment_lang[lang].Date}
                                                                </th>
                                                                <th scope="col" width="18%">
                                                                  {track_shipment_lang[lang].VesselVoyage}
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>

                                                              {typeof results["new_rec"] !==
                                                                "undefined" &&
                                                                results["new_rec"]
                                                                  .reverse()
                                                                  .map((new_rec, i) => {
                                                                    {
                                                                    }
                                                                    return (
                                                                      <tr
                                                                        style={{
                                                                          background: `#ffffff`,
                                                                        }}
                                                                      >
                                                                        <td width="15%"></td>
                                                                        <td>
                                                                          {
                                                                            new_rec[
                                                                            "activityDesc"
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td>
                                                                          {new_rec[
                                                                            "placeFromDesc"
                                                                          ] +
                                                                            ", " +
                                                                            new_rec[
                                                                            "countryFromName"
                                                                            ]}
                                                                        </td>
                                                                        <td>
                                                                          {moment(
                                                                            new_rec[
                                                                            "activityDateTz"
                                                                            ]
                                                                          ).format(
                                                                            "DD-MMM-YYYY"
                                                                          )}
                                                                        </td>
                                                                        <td>
                                                                          {new_rec["voyage"] !=
                                                                            " / "
                                                                            ? new_rec["voyage"]
                                                                            : ""}
                                                                        </td>
                                                                      </tr>
                                                                    );
                                                                  })}

                                                            </tbody>
                                                          </table>
                                                        </Accordion.Collapse>
                                                      </td>
                                                    </tr>
                                                  </>
                                                );
                                              })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </Accordion>
                                  </>
                                </div>
                              </div></>)
                          }
                        }
                      }) : <></>}


                </div>
              </div>
            </div>
          </div>
        </section>

      </section>

      <GET_IN_TOUCH />
    </Layout>
  );
};
export default Shipment_unsubscribe;
