const homelang =
{
    "en": {
        "EMail": "E-Mail",
        "Company": "Company",
        "Email_Type": "Email - Type",
        "Group": "Group",
        "Individual": "Individual",
        "FirstName": "First Name",
        "LastName": "Last Name",
        "CountryName": "Country Name",
        "Selecttheschedule1": "Select the schedule you want to receive",
        "Selecttheschedule2": "To UNSUBSCRIBE schedules, please uncheck below checkboxes",
        "Typethecodeyousee": "Type the code you see",
        "Submit": "Submit",
       
    },
    "cn": {
        "EMail": "電郵",
        "Company": "公司",
        "Email_Type": "電郵 - 類別",
        "Group": "群組",
        "Individual": "個人",
        "FirstName": "名",
        "LastName": "姓",
        "CountryName": "國家 / 地區",
        "Selecttheschedule1": "請選擇所需船期",
        "Selecttheschedule2": "請留空以下方格取消訂閱",
        "Typethecodeyousee": "請輸入驗證碼",
        "Submit": "提交",
     
    },
    "zh": {
        "EMail": "电邮",
        "Company": "公司",
        "Email_Type": "电邮 - 类别",
        "Group": "群组",
        "Individual": "个人",
        "FirstName": "名",
        "LastName": "姓",
        "CountryName": "国家 / 地区",
        "Selecttheschedule1": "请选择所需船期",
        "Selecttheschedule2": "不选取方框取消订阅",
        "Typethecodeyousee": "请输入验证码",
        "Submit": "递交",
     
    },
    "ko": {
        "EMail": "E-Mail",
        "Company": "회사",
        "Email_Type": "Email - Type",
        "Group": "그룹",
        "Individual": "개인",
        "FirstName": "이름",
        "LastName": "성",
        "CountryName": "국가명",
        "Selecttheschedule1": "구독을 원하는 스케줄 선택",
        "Selecttheschedule2": "구독을 원하지 않을 경우, 체크 제거",
        "Typethecodeyousee": "보이는 코드를 입력하십시오",
        "Submit": "제출",
     
    },
    "vi": {
        "EMail": "Thư điện tử",
        "Company": "Công ty",
        "Email_Type": "Loại thư điện tử",
        "Group": "Nhóm",
        "Individual": "Cá nhân",
        "FirstName": "Họ",
        "LastName": "Tên",
        "CountryName": "Tên quốc gia",
        "Selecttheschedule1": "Lựa chọn lịch tàu mà quý khách muốn nhận",
        "Selecttheschedule2": "Để bỏ đăng ký nhận lịch tàu, vui lòng bỏ chọn ở hộp bên dưới",
        "Typethecodeyousee": "Vui lòng nhập mã mà bạn thấy",
        "Submit": "Nhấn để gửi",
     
    },
    "th": {
        "EMail": "อีเมล",
        "Company": "บริษัท",
        "Email_Type": "อีเมล - ประเถท",
        "Group": "กลุ่ม",
        "Individual": "รายบุคคล",
        "FirstName": "ชื่อ",
        "LastName": "นามสกุล",
        "CountryName": "ประเทศ",
        "Selecttheschedule1": "เลือกตารางเรือที่ต้องการ",
        "Selecttheschedule2": "หากต้องการยกเลิกรับตารางเรือ โปรดยกเลิกช่องทำเครื่องหมายด้านล่าง",
        "Typethecodeyousee": "โปรดใส่รหัสตามภาพ",
        "Submit": "ส่ง",
     
    },
    "in": {
        "EMail": "Surat Elektronik",
        "Company": "Perusahaan",
        "Email_Type": "Tipe Email",
        "Group": "Grup",
        "Individual": "Perseorangan",
        "FirstName": "Nama depan",
        "LastName": "Nama belakang",
        "CountryName": "Nama Negara",
        "Selecttheschedule1": "Pilih Jadwal yang ingin anda terima",
        "Selecttheschedule2": "untuk UNSUBSCRIBE jadwal, silakan hapus tanda centang di bawah",
        "Typethecodeyousee": "Masukkan kode yang anda lihat",
        "Submit": "Ajukan",
       
    },
    "ja": {
        "EMail": "メールアドレス",
        "Company": "会社",
        "Email_Type": "メールアドレス－種類",
        "Group": "団体",
        "Individual": "個人",
        "FirstName": "名",
        "LastName": "姓",
        "CountryName": "国名",
        "Selecttheschedule1": "受信するスケジュールを選択します",
        "Selecttheschedule2": "スケジュールの配信を停止するには、以下のチェックボックスをオフにしてください",
        "Typethecodeyousee": "表示されたコードを入力します",
        "Submit": "送信",
       
    },
}
export default homelang;