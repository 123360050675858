import React, { Component, useEffect, useState, useRef } from "react";
import Layout from '../../../components/layout';
import Api from '../../../../package.json'
import * as axios from 'axios';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import Inner_container from '../../innerpages/inner-container';
import { useParams } from "react-router-dom";
import 'react-tabs/style/react-tabs.css';

const qs = require('qs');
var jwt = require('jsonwebtoken');
const View_regulation = () => {
    const headers = {
		type: "application/json",
		Authorization: `Bearer `+window.localStorage.getItem('access_token'),
	  };
    const [country_code, setCountryCode] = useState(window.$country_code);
    const [regulation, setregulation] = useState([]);
    const { id } = useParams();
    const getLoadData = async () => {
        const response_regulation = await axios.post(
            Api.userdata.url + "single_regulation_news",
            qs.stringify(
                { country_code: window.$country_code,id:id },
                { parseArrays: false }
            ),{headers:headers}
        );

        const json_regulation = await response_regulation.data;
         console.log(json_regulation);
        if (json_regulation["status"] == 1) {
           
            setregulation(json_regulation["message"][0]);
        }
    }
    useEffect(() => {
        getLoadData();
    }, [id]);
    return (

        <Layout>
            <Inner_container innerpage="view_regulation" titles={regulation['title']} description={regulation['description']}/>


            <section class="inner-center">
                <div class="container-fluid col-lg-10">
                    <p style={{lineHeight: `35px`}} dangerouslySetInnerHTML={{ __html: regulation['desp'] }} ></p>

                </div>





            </section>
            <Services />
            <Packages />
            <GET_IN_TOUCH />
        </Layout>);
}
export default View_regulation;