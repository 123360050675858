import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import Services from "../../../components/get_in_touch/services";
import Packages from "../../../components/get_in_touch/packages_block";
import Api from "../../../../package.json";
import * as axios from "axios";
import Select from "react-select";
import GoToTop from "../../../components/go-to-top";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { useForm, Controller } from "react-hook-form";
import "../tools/css/css-loader.css";
import { Accordion, Card, Modal, Button } from "react-bootstrap";
import moment from "moment";
import tariff_calculator_lang from "../../lang/tariff_calculator";
import down_arrow from "../../../../src/assets/custom-down-arrow.webp";
import big_arrow from "../../../../src/assets/toggle-arrow-big.webp";
import toolTip from "../../../../src/assets/tool-tip.webp";
import cmd_icon from "../../../../src/assets/cmd-icon.webp";

const qs = require("qs");
var jwt = require("jsonwebtoken");

const Tariff_calculator = () => {
  const [country_code, setCountryCode] = useState(window.$country_code);
  const [port_code, setportCode] = useState([]);
  const [date, setdate] = useState(new Date());
  const [loaderFlag, setloaderFlag] = useState("is-inactive");
  const [tareRes, setTareRes] = useState("");
  const buttonRef = useRef();
  const [resultstaus, setresultstaus] = useState(false);
  const [routes, setroutes] = useState([]);
  const [port, setport] = useState([]);
  const [schedulesearchflag, setschedulesearchflag] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  var lang = window.localStorage.getItem("lang");
  if (window.localStorage.getItem("lang") == null) {
    lang = "en";
    window.localStorage.setItem("lang", "en");
  }

  const headers = {
    type: "application/json",
    Authorization: `Bearer ` + window.localStorage.getItem("access_token"),
  };
  const onSubmit = (data) => {
    tariff_calculator(data);
  };
  const tariff_calculator = async (data) => {
    setloaderFlag("is-active");
    setschedulesearchflag(true);
    const response = await axios.post(
      Api.userdata.url + "/get_tariff_calculator",
      qs.stringify({
        country_code: country_code,
        from_port: data.from_port,
        to_port: data.to_port,
        date: data.date,
      }),
      { headers: headers }
    );
    const json = await response.data;
    if (json["status"] == "OK") {
      var routes = json["message"]["routes"];
      setroutes(routes);
      var port = json["message"]["port"];
      setport(port);
      setresultstaus(true);
    } else {
      setresultstaus(false);
    }
    setTareRes(json);
    setloaderFlag("is-inactive");
  };
  const tariff_calculator_submit_pdf = async () => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/get_tariff_calculator_pdf",
      qs.stringify({
        country_code: country_code,
        from_port: getValues("from_port"),
        to_port: getValues("to_port"),
        date: getValues("date"),
      }),
      {
        headers: headers,
        responseType: "blob",
      }
    );
    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const tempLink = document.createElement("a");
    tempLink.href = fileURL;
    tempLink.setAttribute(
      "download",
      getValues("from_port") + "-" + getValues("to_port") + ".pdf"
    );
    tempLink.click();

    setloaderFlag("is-inactive");
  };
  const tariff_calculator_submit_print = async () => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/get_tariff_calculator_pdf",
      qs.stringify({
        country_code: country_code,
        from_port: getValues("from_port"),
        to_port: getValues("to_port"),
        date: getValues("date"),
      }),
      {
        headers: headers,
        responseType: "blob",
      }
    );

    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    window.focus();
    setloaderFlag("is-inactive");
  };
  const getLoadData = async () => {
    const response = await axios.post(
      Api.userdata.url + "port_codes",
      qs.stringify({ country_code: country_code }, { parseArrays: false }),
      { headers: headers }
    );
    const json = await response.data;
    setportCode(json["message"]);
  };

  const handleToggle = () => {
    setIsRotated(!isRotated);
  };

  useEffect(() => {
    getLoadData();
    setValue("date", date);
  }, []);
  return (
    <Layout>
      <div id="capture">
        <p>{tariff_calculator_lang[lang].Helloinmylife}</p>
        <span>{tariff_calculator_lang[lang].Howcanhellpyou}</span>
      </div>
      <div class={"loadernew loader-double " + loaderFlag}></div>
      <section class="inner-center n-40">
        <br></br>
        <div class="container">
          <div class="row m-0">
            <div class="col-lg-12 col-md-12 col-lg-12">
              <form
                autocomplete="on"
                name="point-to-point-new"
                id="point-to-point-new"
                method="get"
                action="#"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div class="form-fback">
                  <div class="row m-0">
                    <div class="col-lg-5 col-md-12 col-sm-12 pot-form p-3">
                      <div class="dout-inner">
                        <h3>{tariff_calculator_lang[lang].TariffCalculator}</h3>
                        <p>{tariff_calculator_lang[lang].TariffCalculator1}</p>
                      </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 p-0 pot-form">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12">
                          <div class="autocomplete">
                            <div>
                              <label>{tariff_calculator_lang[lang].From}</label>
                              <Controller
                                control={control}
                                rules={{
                                  required: "Please select from port",
                                }}
                                render={({ onChange, value, ref }) => (
                                  <Select
                                    options={port_code.map((data) => ({
                                      value: data["port_code"],
                                      label:
                                        data["port_name"] +
                                        "-" +
                                        data["country_name"] +
                                        "(" +
                                        data["port_code"] +
                                        ")",
                                    }))}
                                    onChange={(val) => {
                                      setValue("from_port", val.value);
                                    }}
                                  />
                                )}
                                defaultValue=""
                                placeholder="Select platform"
                                name="from_port"
                                id="from_port"
                                setValue={setValue}
                              />

                              {errors.from_port && (
                                <span class="errors">
                                  {errors.from_port.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12">
                          <div class="autocomplete">
                            <div>
                              <label for="toends">
                                {tariff_calculator_lang[lang].To}
                              </label>
                              <Controller
                                control={control}
                                rules={{
                                  required: "Please select to port",
                                }}
                                render={({ onChange, value, ref }) => (
                                  <Select
                                    options={port_code.map((data) => ({
                                      value: data["port_code"],
                                      label:
                                        data["port_name"] +
                                        "-" +
                                        data["country_name"] +
                                        "(" +
                                        data["port_code"] +
                                        ")",
                                    }))}
                                    onChange={(val) => {
                                      setValue("to_port", val.value);
                                    }}
                                  />
                                )}
                                defaultValue=""
                                placeholder="Select platform"
                                name="to_port"
                                id="to_port"
                                setValue={setValue}
                              />
                              {errors.to_port && (
                                <span class="errors">
                                  {errors.to_port.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12">
                          <div class="autocomplete">
                            <div class="form-group">
                              <label>
                                {tariff_calculator_lang[lang].StartDate}
                              </label>
                              <div
                                id="datepicker"
                                class="input-group date datepicker"
                                data-date-format="dd/mm/yyyy"
                              >
                                <Controller
                                  control={control}
                                  rules={{
                                    required: "Please select your date",
                                  }}
                                  render={({ onChange, value, ref }) => (
                                    <Flatpickr
                                      options={{ dateFormat: "d-m-Y" }}
                                      value={date}
                                      onChange={(val) => {
                                        var dateTime1 = moment(val[0]).format(
                                          "DD-MM-YYYY"
                                        );

                                        setdate(dateTime1);
                                        setValue("date", dateTime1);
                                      }}
                                    />
                                  )}
                                  id="date"
                                  name="date"
                                  class="form-control"
                                  setValue={date}
                                />

                                {errors.date && (
                                  <span class="errors">
                                    {errors.date.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12  start-date-btn">
                          <div class="form-group">
                            <button
                              class="btn btn-default hovereffect portal-searc-tariff"
                              id="submitDetails"
                              name="my-buttons"
                              ref={buttonRef}
                            >
                              {tariff_calculator_lang[lang].Calculate}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="container" style={{ minHeight: `200px;` }}>
            {resultstaus == true ? (
              <div class="row">
                <div
                  id="fill"
                  class="col-md-12 col-lg-12 col-sm-12 p-0 p-md-4 sch-line-tb-form"
                  style={{ display: schedulesearchflag ? "block" : "none" }}
                >
                  <div class="table-responsive1 tariff-form-cont">
                    <div className="container">
                      <div className="tariff-card custom-card-container">
                        <Accordion defaultActiveKey={null}>
                          {routes &&
                            routes.map((routess, index) => {
                              {
                                {
                                  var k = 0;
                                  const getArraySum = (a) => {
                                    var total = 0;
                                    for (var i in a) {
                                      total += a[i];
                                    }
                                    return total;
                                  };
                                  var td1 =
                                    "RATE INCLUDING (" +
                                    routess["rates"][
                                    routess["rates"].length - 2
                                    ]["chargeDescription"] +
                                    ")";
                                  var td2 =
                                    getArraySum(
                                      routess["rates"][
                                      routess["rates"].length - 2
                                      ]["dv20Rate"]
                                    ) +
                                    " " +
                                    routess["rates"][
                                    routess["rates"].length - 2
                                    ]["localCurrency"];
                                  var td3 =
                                    getArraySum(
                                      routess["rates"][
                                      routess["rates"].length - 2
                                      ]["dv40Rate"]
                                    ) +
                                    " " +
                                    routess["rates"][
                                    routess["rates"].length - 2
                                    ]["localCurrency"];
                                  var td4 =
                                    getArraySum(
                                      routess["rates"][
                                      routess["rates"].length - 2
                                      ]["hc40Rate"]
                                    ) +
                                    " " +
                                    routess["rates"][
                                    routess["rates"].length - 2
                                    ]["localCurrency"];
                                  var td5 =
                                    routess["rates"][
                                    routess["rates"].length - 2
                                    ]["validFrom"];
                                  var td6 =
                                    routess["rates"][
                                    routess["rates"].length - 2
                                    ]["validTo"];
                                }

                                return (
                                  <>
                                    {" "}

                                    <Card>
                                      <Card.Header>
                                        <div className="card-grid-container">
                                          <div className="grid-item">
                                            <div className="card-tittle">  {tariff_calculator_lang[lang].Ref}</div>
                                            <div className="card-data">  {routess["referenceCode"]}</div>
                                          </div>
                                          <div className="grid-item">
                                            <div className="card-tittle">{tariff_calculator_lang[lang].Service}</div>
                                            <div className="card-data">{routess["service"]}</div>
                                          </div>
                                          <div className="grid-item">
                                            <div className="card-tittle"> {tariff_calculator_lang[lang].EstTT}</div>
                                            <div className="card-data">{routess["transitTimeDays"]}</div>
                                          </div>
                                          <div className="grid-item">
                                            <div className="card-tittle"> {tariff_calculator_lang[lang].Pol}</div>
                                            <div className="card-data">{port[routess["pol"]]}</div>
                                          </div>
                                          <div className="grid-item">
                                            <div className="card-tittle"> {tariff_calculator_lang[lang].VIA1}</div>
                                            <div className="card-data">{port[routess["via1"]]}</div>
                                          </div>
                                          <div className="grid-item">
                                            <div className="card-tittle"> {tariff_calculator_lang[lang].Pod}</div>
                                            <div className="card-data">{port[routess["pod"]]}</div>
                                          </div>
                                        </div>

                                        <Accordion.Toggle
                                          className="custom-toggle-link"
                                          eventKey="0"
                                          onClick={handleToggle}
                                        >
                                         {/*  <img
                                            src={big_arrow}
                                            alt="Arrow"
                                            className={`arrow-icon ${isRotated ? "rotate" : ""}`}
                                          /> */}

<div
                                        class= {`arrowButton arrow-icon ${isRotated ? "rotate" : ""}`}
                                     
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M9 18L15 12L9 6"
                                            stroke="white"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></path>
                                        </svg>
                                      </div>
                                        </Accordion.Toggle>
                                      </Card.Header>
                                      <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                          <div className="data-table-tariff">

                                            {/*   <div class="grid-header"> {
                                              tariff_calculator_lang[lang]
                                                .RateperContainer
                                            }</div>
                                            <div class="grid-header">DV20</div>
                                            <div class="grid-header">DV40</div>
                                            <div class="grid-header">HC40</div>
                                            <div class="grid-header"> {
                                              tariff_calculator_lang[lang]
                                                .Validfrom
                                            }</div>
                                            <div class="grid-header">{
                                              tariff_calculator_lang[lang]
                                                .Validto
                                            }</div> */}


                                            {routess["rates"] &&
                                              routess["rates"].map(
                                                (ratess, j) => {
                                                  {
                                                    {
                                                      const getArraySums = (
                                                        a
                                                      ) => {
                                                        var total = 0;
                                                        for (var i in a) {
                                                          total += a[i];
                                                        }
                                                        return total;
                                                      };
                                                      var end_row = false;
                                                      var last_row = false;
                                                      var mid_row = false;
                                                      if (
                                                        j ==
                                                        routess["rates"]
                                                          .length -
                                                        2
                                                      ) {
                                                        end_row = true;
                                                      } else if (
                                                        j ==
                                                        routess["rates"]
                                                          .length -
                                                        1
                                                      ) {
                                                        last_row = true;
                                                      } else {
                                                        mid_row = true;
                                                      }

                                                      var td11 =
                                                        ratess[
                                                        "chargeDescription"
                                                        ];
                                                      var td12 =
                                                        getArraySums(
                                                          ratess["dv20Rate"]
                                                        ) +
                                                        " " +
                                                        ratess[
                                                        "localCurrency"
                                                        ];
                                                      var td13 =
                                                        getArraySums(
                                                          ratess["dv40Rate"]
                                                        ) +
                                                        " " +
                                                        ratess[
                                                        "localCurrency"
                                                        ];
                                                      var td14 =
                                                        getArraySums(
                                                          ratess["hc40Rate"]
                                                        ) +
                                                        " " +
                                                        ratess[
                                                        "localCurrency"
                                                        ];
                                                      var td15 =
                                                        ratess["validFrom"];
                                                      var td16 =
                                                        ratess["validTo"];
                                                    }
                                                  }
                                                  return end_row == false ? (
                                                    <>
                                                      {(td11 == 'Total') ? <>


                                                        <div class="grid-container mb-3 ">
                                                          {/*  <div class="grid-header">Rate per Container</div> */}

                                                          <div class="grid-item">
                                                            <div class="grid-header">Total</div>

                                                          </div>
                                                          <div class="grid-item">
                                                            <div class="grid-header">DV20</div>
                                                            {td12}
                                                          </div>
                                                          <div class="grid-item">
                                                            <div class="grid-header">DV40</div>{td13}
                                                          </div>
                                                          <div class="grid-item">
                                                            <div class="grid-header">HC40</div>
                                                            {td14}
                                                          </div>

                                                        </div>



                                                      </> : <>
                                                        <div class="grid-container mb-3 ">
                                                          {/*  <div class="grid-header">Rate per Container</div> */}

                                                          <div class="grid-item">
                                                            <div class="grid-header">Rate per Container</div>
                                                            {td11}
                                                          </div>
                                                          <div class="grid-item">
                                                            <div class="grid-header">DV20</div>
                                                            {td12}
                                                          </div>
                                                          <div class="grid-item">
                                                            <div class="grid-header">DV40</div>{td13}
                                                          </div>
                                                          <div class="grid-item">
                                                            <div class="grid-header">HC40</div>
                                                            {td14}
                                                          </div>
                                                          <div class="grid-item">
                                                            <div class="grid-header">Valid from</div>
                                                            {td15}
                                                          </div>
                                                          <div class="grid-item">
                                                            <div class="grid-header">Valid to</div>
                                                            {td16}
                                                          </div>
                                                        </div>

                                                      </>
                                                      }

                                                    </>


                                                  ) : '';
                                                }
                                              )}

                                          </div>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>

                                  </>
                                );
                              }
                            })}




                        </Accordion>
                      </div></div>

                  </div>
                </div>

                <br />
                <div class="row  p-4">
                  <p class="tariff-print" style={{ padding: `10px` }}>
                    <a
                      class="btn btn-info btn-lg"
                      onClick={tariff_calculator_submit_print}
                    >
                      <span class="glyphicon glyphicon-print"></span>{" "}
                      {tariff_calculator_lang[lang].Print}
                    </a>
                  </p>
                  <p class="tariff-print" style={{ padding: `10px` }}>
                    <a
                      class="btn btn-info btn-lg"
                      onClick={tariff_calculator_submit_pdf}
                    >
                      <span class="glyphicon glyphicon-print"></span>{" "}
                      {tariff_calculator_lang[lang].PDF}
                    </a>
                  </p>
                </div>

                <p className="tariff-print-para">
                  <h3>{tariff_calculator_lang[lang].Disclaimer}</h3>
                  GSL Tariff Simulator provides FAK Rates only for sea carriage
                  between base ports. For further details on GSL additional
                  services which the Tariff simulator does not support such as
                  special commodities, land transport, etc.. contact local GSL
                  Sales. FAK Rates are subject to all other charges for
                  additional services, local charges and contingency charges.
                  Kindly note that the rates provided by the GSL tariff
                  simulator do not constitute a price quotation.
                </p>
                <p className="tariff-print-para">
                  Customers interested in receiving a quotation and/or obtaining
                  GSL services are required to contact GSL local sales. For
                  rates presented in non USD currencies – the applicable
                  exchange rate to the local currency will be applied. The
                  exchange rates used in this tariff simulator is for indication
                  purpose for customer convenience only For further details -
                  please contact GSL local sales office
                </p>
              </div>
            ) : (
              <div
                class="row"
                style={{
                  minHeight: `200px`,
                  border: `solid 1px`,
                  display: schedulesearchflag ? "block" : "none",
                }}
              >
                <div class="col">
                  <h3 style={{ paddingTop: `72px`, textAlign: `center` }}>
                    <i class="fa fa-search" aria-hidden="true"></i>
                    {tariff_calculator_lang[lang].NoRecordFound}.
                  </h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <Services />
      <Packages />
      <GET_IN_TOUCH /> <GoToTop />
    </Layout>
  );
};

export default Tariff_calculator;
