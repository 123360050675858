import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import Inner_container from '../../innerpages/inner-container';
import Api from '../../../../package.json'
import Moment from 'moment';
import * as axios from 'axios';
import abt_icn from '../../../../src/assets/abt-icn.png';
import {
    NavLink as Link
} from "react-router-dom";


const qs = require('qs');
var jwt = require('jsonwebtoken');


export default class SolasRegulation extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            newletter_list: [],


        };

    }
    async componentDidMount() {

        const headers = {
            'type': 'application/json',
            "Access-Control-Origin": "*",
            'Authorization': jwt.sign({ foo: 'bar' }, '123456')
        }





    }
    render() {
        return (

            <Layout>
                <Inner_container innerpage="solas_regulation"/>
                <section class="inner-center">
                    <div class="container">
                        <form class="abt-full">
                            <div class="row ml-0 mr-0 ">

                                <div class="col-lg-12 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s"  >
                                    <div class="abt-text-con">
                                        <img src={abt_icn} />
                                        <h3>SOLAS VGM REQUIREMENT</h3>


                                    </div>
                                    <div class="form-fback">
							
							<div class="row m-0">
								<div class="col-lg-12 col-sm-12 pot-form pl-0">
												<p> In order to address safety problems at sea and on shore arising from container shipments that have incorrect weight declarations, the International Maritime Organization (IMO) adopted amendments to the Safety of Life at Sea (SOLAS) Convention and will enter in to force on July 1st, 2016. <br></br>
                                                <br></br>
										
									At this time, the verification of the gross weight (VGM) of a packed export container will be required before the container is loaded aboard a ship. The responsibility for obtaining and documenting the verified gross weight (VGM) of a packed container lies with the shipper. container lies with the shipper.
									</p>
									
								</div>
								<div class="col-lg-12 col-sm-12 pot-form pl-0">
										<h3 class="solas h"> The SOLAS regulations provides two methods by which the shipper may obtain the VGM of a packed container. </h3>
									
								</div>
								
								<div class="col-lg-12 col-sm-12 pot-form p-0">
									
									
									<p> <b> Method No. 1: </b></p>
									<p> 
									 After packing and sealing a container and using calibrated and certified equipment, the shipper may weigh, or have arranged that a third party weigh, the packed container. The scale, weighbridge, lifting equipment or other devices used to verify the gross mass of the container must meet the applicable accuracy standards and requirements of the State in which the equipment is being used.	
									</p>	 
									<p> <b> Method No. 2: </b></p>
									<p> 
									The shipper (or, by arrangement of the shipper, a third party) may weigh all packages and cargo items, including the mass of pallets, dunnage and other packing and securing material to be packed in the container, and add the tare mass of the container to the sum of the single masses of the container’s contents. <br></br>
										The weighing equipment used to weigh the contents of the container must meet the applicable accuracy standards and requirements of the State in which the equipment is being used. <br></br>
										 
										Furthermore, a container that does not have a verified gross weight will not be loaded onboard the vessels. We will continue to keep you updated of any changes to this requirement. More information can be found at:<a href="http://www.worldshipping.org/industry-issues/safety/cargo-eight"> http://www.worldshipping.org/industry-issues/safety/cargo-eight </a> which include a FAQs page
										 
										Please be advised that when providing your VGM we ask that you break down the weight to cargo weight and container Tare weight separately.
										Should you have any questions, please contact your local Laurel Navigation (Mauritius) Ltd Representative who will be happy to assist.	
									</p>									
								</div>
								
								<div class="col-lg-12 col-sm-12 pot-form p-0">								
									<p style={{fontStyle:'italic'}}>
										 These Regulatory News-Flashes are purely informative in nature and are provided only for the convenience of the users without any representation or warranty by Laurel Navigation (Mauritius) Ltd as to the accuracy, completeness or timeliness of the information provided therein. In no event will the information and/or data provided through this website constitute and/or come in place of professional legal or tax advice.
									</p>
								</div>
							</div>
							
						</div>
                                    
                                </div>

                            </div>
                        </form>
                    </div>
                </section>
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}