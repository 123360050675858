import React, { Component, useEffect, useState } from "react";
import AboutGsl from '../../assets/inner-images/about/about_gsl.jpg';
import companyProfile from '../../assets/inner-images/about/company_profile.jpg';
import companyHistory from '../../assets/inner-images/about/company_history.jpg';
import factsFigures from '../../assets/inner-images/about/facts_figures.jpg';
import cargoServices from '../../assets/inner-images/cargo_services/cargo_services.jpg';
import dangerousCargo from '../../assets/inner-images/cargo_services/dangerous_cargo.jpg';
import dryCargo from '../../assets/inner-images/cargo_services/dry_cargo.jpg';
import gslMonitor from '../../assets/inner-images/cargo_services/gsl_monitor.jpg';
import inlandTransport from '../../assets/inner-images/cargo_services/inland_transport.jpg';
import projectCargo from '../../assets/inner-images/cargo_services/project_cargo.jpg';
import reeferCargo from '../../assets/inner-images/cargo_services/reefer_cargo.jpg';
import temperatureControlledShipping from '../../assets/inner-images/cargo_services/temperature_controlled_shipping.jpg';
import highValueOrSensitiveCargo from '../../assets/inner-images/cargo_services/high_value_or_sensitive_cargo.jpg';
import Catalogue from '../../assets/inner-images/tools/catalogue.jpg';
import eblService from '../../assets/inner-images/tools/ebl_service.jpg';
import requestaQuote from '../../assets/inner-images/tools/request_a_quote.jpg';
import siSubmission from '../../assets/inner-images/tools/si_submission.jpg';
import bookingSubmission from '../../assets/inner-images/tools/booking_submission.jpg';
import vgmMassUpload from '../../assets/inner-images/tools/vgm_mass_upload.jpg';
import careers from '../../assets/inner-images/careers/careers.jpg';
import demurrage_and_detention_tariff from '../../assets/inner-images/local_info/demurrage_and_detention_tariff.jpg';
import local_info from '../../assets/inner-images/local_info/local_info.jpg';
import help_customer_advisory from '../../assets/inner-images/help/help_customer_advisory.jpg';
import newsletter from '../../assets/inner-images/newsletter/newsletter.jpg';
import terms_of_use from '../../assets/inner-images/help/terms_of_use.jpg';
import enquiry_us from '../../assets/inner-images/enquiry/enquiry_us.jpg';
import faq_gsl from '../../assets/inner-images/enquiry/faq_gsl.jpg';
import catalogues from '../../assets/inner-images/catalogue/catalogue.jpg';
import roro from '../../assets/inner-images/roro/roro.jpg';
import subscribe from '../../assets/inner-images/about/subscribe.jpg';
import shapeBlue from '../../../src/assets/banner-shape-blue.svg';
import shapeYellow from '../../../src/assets/banner-shape.svg';

const innerJSON =
{
    "subscribe": {
        "title": "订阅",
        "discription": "接收您的货物的实时警报并全面了解您的货物状态",
        "url": subscribe,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
        
    },
    "about_gsl": {
        "title": "货箱分类",
        "discription": "根据您的货物规格和需求计划您的装运",
        "url": AboutGsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',

    },
    "cargo_catalogue": {
        "title": "货箱分类",
        "discription": "根据您的货物规格和需求计划您的装运",
        "url": catalogues,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
        
    },
    "request_a_quote": {
        "title": "请报价",
        "discription": "我们的使命和目标是提供各种货物运输服务来支持您的业务",
        "url": requestaQuote,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_submission": {
        "title": "Booking Submission",
        "discription": "我们的使命和目标是提供各种货物运输服务来支持您的业务",
        "url": bookingSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ebl_service": {
        "title": "电子提单服务",
        "discription": "我们的使命和目标是提供各种货物运输服务来支持您的业务",
        "url": eblService,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "si_submission": {
        "title": "交通运输指示(SI)",
        "discription": "无缝提货物详情、相关和需求",
        "url": siSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ecommerce_partners": {
        "title": "电子商务合作伙伴",
        "discription": "我们的使命和目标是提供各种货物运输服务来支持您的业务",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "vgm_mass_upload": {
        "title": "VGM 批发上传",
        "discription": "多批发货的VGM 提交变得简单而熟悉",
        "url": vgmMassUpload,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "high_value_or_sensitive_cargo": {
        "title": "高价值或敏感货物",
        "discription": "金星监控为您提供精准的工具来跟踪、监控和控制您的贵重物品，确保安全交付",
        "url": highValueOrSensitiveCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "enquiry": {
        "title": "联系我们",
        "discription": "请与我们联系！我们很乐意为您提供所需的所有资讯",
        "url": enquiry_us,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "faq": {
        "title": "常见问题",
        "discription": "了解金星航运和我们的服务",
        "url": faq_gsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "roro-private": {
        "title": "汽车运输服务",
        "discription": "满足全球不断增长的汽车运输需求",
        "url": roro,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "newsletter": {
        "title": "新闻与更新",
        "discription": "请继续关注最新资讯和公告！",
        "url": newsletter,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_newsletter": {
        "title": "查看新闻",
        "discription": "我们的使命和目标是提供各种货物运输服务来支持您的业务",
        "url": newsletter,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "cargo_services": {
        "title": "货物运输服务",
        "discription": "我们的使命是为所有类型的货物提供全方位的海运服务。",
        "url": cargoServices,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "gsl_monitor": {
        "title": "金星监控器",
        "discription": "跟踪、监控甚至控制货物环境，尤其是在运输敏感、高价值或冷藏货物时",
        "url": gslMonitor,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dry_cargo": {
        "title": "干货",
        "discription": "凭借60多年致力于处理任何类型的干货的经验，我们将满足您的需求。",
        "url": dryCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "reefer_cargo": {
        "title": "冷藏货物",
        "discription": "我们的先进技术可确保您的冷藏货物在适当的温度下安全、准时地交付。",
        "url": reeferCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "project_cargo": {
        "title": "项目货物",
        "discription": "我们拥有在世界各地安全运送您的特殊货物所需的创造性解决方案和专业知识。",
        "url": projectCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "inland_transport": {
        "title": "内陆运输",
        "discription": "通过我们广泛的门到门网络提供可靠的陆路运输服务",
        "url": inlandTransport,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "temperature_controlled_shipping": {
        "title": "温控输送",
        "discription": "冷藏或冷冻，我们为所有温度敏感产品提供安全运输",
        "url": temperatureControlledShipping,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dangerous_cargo": {
        "title": "危险品",
        "discription": "我们为危险品的安全储运提供专注专业的服务",
        "url": dangerousCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "about_gsl": {
        "title": "关于我们",
        "discription": "金星航运 - 亚洲内部、大洋洲和非洲贸易的主要参与者，以灵活的方式服务于动态市场",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_profile": {
        "title": "公司简介",
        "discription": "我们的重点是提供最适合客户特定需求的服务",
        "url": companyProfile,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_history": {
        "title": "我们的故事",
        "discription": "穿越时空，探索金星航运的历史、里程碑和发展",
        "url": companyHistory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "facts_figures": {
        "title": "事实与数据",
        "discription": "我们的使命是继续寻找创造性的解决方案来支持我们客户的需求",
        "url": factsFigures,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "demurrage_and_detention_tariff": {
        "title": "滞港费和滞留费",
        "discription": "滞港费和滞留费不必是个谜",
        "url": demurrage_and_detention_tariff,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "local_info": {
        "title": "本地信息",
        "discription": "了解与您所在位置相关的有用信息",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "unsubscribe_schedule": {
        "title": "取消订阅船期表",
        "discription": "",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "contact_us": {
        "title": "金星办公室详情",
        "discription": "获取我们的各种办公室详细信息、地址和联系方式",
        "url": enquiry_us,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "careers": {
        "title": "招聘",
        "discription": "加入金星航运，与我们一起成长！",
        "url": careers,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "solas_regulation": {
        "title": "上海安全公约规定",
        "discription": "我们的使命和目标是提供各种货物运输服务来支持您的业务",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "customer_advisory": {
        "title": "客户咨询",
        "discription": "请继续关注我们的通知和咨询",
        "url": help_customer_advisory,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "accessibility": {
        "title": "辅助功能",
        "discription": "通过我们的无障碍模式提供高生活质量",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "data_protection_policy": {
        "title": "数据保护政策",
        "discription": "了解了我们的个人数据保护政策",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_cookies_policy": {
        "title": "网页浏览记录政策",
        "discription": "我们对您的个人数据和数据保密的承诺",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "quotation_terms_conditions": {
        "title": "报价款条款",
        "discription": "守护我们报价的条款和条款",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "terms_of_use": {
        "title": "使用条款",
        "discription": "指南和服务条款",
        "url": terms_of_use,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_customer_advisory": {
        "title": "客户须知",
        "discription": "对我们客户的公告和建议",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "bl_terms_conditions": {
        "title": "提单款物品",
        "discription": "随着时间的推移了解我们提出的法律条款",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_go_terms_conditions": {
        "title": "GSL GO 条款和条件",
        "discription": "随着时间的推移了解 GSL GO 的法律条款",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "about_car_carrier": {
        "title": "关于汽车承运人",
        "discription": "",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "egsl_terms_conditions": {
        "title": "eGSL 条款和条款",
        "discription": "随着时间的推移了解我们提出的法律条款",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_clause": {
        "title": "订舱款",
        "discription": "了解了与我们订舱确认文件相关的条款",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_company_news": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_regulation": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_industry": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },"sea_waybill_terms_conditions": {
        "title": " SEA WAYBILL TERMS AND CONDITIONS",
        "discription": "TERMS AND CONDITIONS",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
}


export default innerJSON;