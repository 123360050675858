import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import Services from "../../../components/get_in_touch/services";
import Packages from "../../../components/get_in_touch/packages_block";
import Inland from "../../../../src/assets/Inland.jpeg";
import abt_icn from "../../../../src/assets/abt-icn.png";
import Inner_container from "../../innerpages/inner-container";
import Api from "../../../../package.json";
import * as axios from "axios";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import "../tools/css/css-loader.css";
import moment from "moment";
import { useParams } from "react-router-dom";

const qs = require("qs");
var jwt = require("jsonwebtoken");
const Schedule_by_line = () => {
  const headers = {
		type: "application/json",
		Authorization: `Bearer `+window.localStorage.getItem('access_token'),
	  };
  const [country_code, setCountryCode] = useState(window.$country_code);
  const [shipline, setshipline] = useState([]);
  const [tradeOption, settradeOption] = useState("");
  const [loaderFlag, setloaderFlag] = useState("is-inactive");
  const [scheduleByLine, setscheduleByLine] = useState([]);
  const [schedulesearchflag, setschedulesearchflag] = useState(false);
  const [publishDate, setpublishDate] = useState("");
  const [lineName, setlineName] = useState("");
  const [weekNumber, setweekNumber] = useState("");
  const [vslRemarks, setvslRemarks] = useState([]);
  const [rec1, setrec1] = useState([]);
  const [sort_array, setsort_array] = useState([]);
  const [resultstaus, setresultstaus] = useState(false);
  const ref = useRef(null);
  const { linecode } = useParams();
  const { trades } = useParams();
  const buttonRef = useRef();
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const get_line_code = async (val) => {
    setshipline([]);
    if (val != "") {
      settradeOption(val);
      const response = await axios.post(
        Api.userdata.url + "/sailing_schedule_by_trade",
        qs.stringify({ line_type: val }, { parseArrays: false }),{headers:headers}
      );
      const json = await response.data;
      setshipline(json["message"]);
    
     
    }

    //track_shipment_submit(data);
  };

  const onSubmit = (data) => {
    console.log(data);
    schedule_by_line_submit(data);
  };
  const schedule_by_line_submit = async (shipliness) => {
    setloaderFlag("is-active");
    const response = await axios.post(
      Api.userdata.url + "/get_schedule_by_line",
      qs.stringify({
        country_code: country_code,
        sailing: shipliness,
      }),{headers:headers}
    );
    const json = await response.data;

    setloaderFlag("is-inactive");
    setschedulesearchflag(true);

    if (json["status"] == "OK") {
      setpublishDate(json["result"]["publishDate"]);
      setweekNumber(json["result"]["weekNumber"]);
      setlineName(json["result"]["lineName"]);
      var vslRemarkss = json["result"]["vslRemarks"];
      setvslRemarks(vslRemarkss);
      console.log(vslRemarkss);
      var rec1s = json["result"]["rec1"];
      setrec1(rec1s);
      var sort_arrays = json["result"]["sort_array"];
      setsort_array(sort_arrays);
      setresultstaus(true);
    } else {
      setresultstaus(false);
    
    }
  };
  useEffect(() => {
    if(linecode != null && trades != "")
    {
       settradeOption(trades);
       get_line_code(trades);
       setValue('trade',trades);
       setValue("shipline", linecode);
       schedule_by_line_submit(linecode);
   
    }
  }, [linecode,trades]);

  return (
    <Layout>
      <div class={"loadernew loader-double " + loaderFlag}></div>
      <section class="inner-center">
        <br></br>
     
        <div class="container" style={{ minHeight: `200px;` }}>
          {resultstaus == true ? (
          
              <div
                id="fill"
                class="col-md-12 col-lg-12 col-sm-12 p-4 sch-line-tb-form"
                style={{ display: schedulesearchflag ? "block" : "none" }}
              >

                <div border="1" ref={ref}>
                  <table align="center" border="1">
                    <thead class="sch-tb-row-head">
                      <tr>
                        <th valign="top">
                          <p>
                            Vessel<br></br>
                            <br></br>
                          </p>
                          <p>Voyage</p>
                          <p>Partner voyage</p>
                        </th>
                        {sort_array &&
                          sort_array.map((sort_arrays, index) => {
                            {
                              {
                                var h1 = rec1[sort_arrays[1]]["vesselName"];
                                var h3 = rec1[sort_arrays[1]]["vesselCode"];
                                var h2 = rec1[sort_arrays[1]]["voyNo"];
                                var partnerVoyage = [];
                                var partnerVoyage_new = [];
                                var test = "";
                                rec1[sort_arrays[1]]["ports"].forEach(
                                  (ports) => {
                                    if (
                                      ports["partnerVoyage"] != "" &&
                                      ports["partnerVoyage"] != null
                                    ) {
                                      partnerVoyage.push(
                                        ports["partnerVoyage"]
                                      );
                                    }
                                  }
                                );
                                const unique = new Set(partnerVoyage);

                                partnerVoyage_new = [...unique]; // array
                              }
                            }
                            return (
                              <th valign="top">
                                <p>{h1}<br></br> {"(" + h3 + ")"}</p>
                                <p>{h2}</p>
                                {partnerVoyage_new &&
                                  partnerVoyage_new.map((ports, index) => {
                                    return <p>{ports}</p>;
                                  })}
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody class="sch-tb-row-body">
                      {rec1.length > 0 &&
                        rec1[0]["ports"].map((ports, k) => {
                          {
                            {
                            }
                          }
                          return (
                            <tr>
                              <td>
                                <p> Port</p>
                                <p> Arrival</p>
                                <p> Departure</p>
                              </td>
                              {sort_array &&
                                sort_array.map((sort_arrays, n) => {
                                  {
                                    {
                                      var port_key = "";
                                      var exceptionPort = "";
                                      var direction = "";
                                      var output_v1 = "-";
                                      var output_v2 = "-";
                                      var output_v3 = "-";
                                      if (
                                        rec1[sort_arrays[1]]["ports"][k][
                                          "locArrDate"
                                        ]
                                      ) {
                                        if (
                                          rec1[sort_arrays[1]]["ports"][k][
                                            "exceptionPort"
                                          ] == "Y"
                                        ) {
                                          exceptionPort = "*";
                                        }
                                        direction =
                                          rec1[sort_arrays[1]]["ports"][k][
                                            "direction"
                                          ];
                                        if (
                                          rec1[sort_arrays[1]]["ports"][k][
                                            "locArrDate"
                                          ]
                                        ) {
                                          output_v1 =
                                            exceptionPort +
                                            " " +
                                            rec1[sort_arrays[1]]["ports"][k][
                                              "portName"
                                            ] +
                                            "(" +
                                            direction +
                                            ")";
                                        }
                                      }
                                      if (
                                        rec1[sort_arrays[1]]["ports"][k][
                                          "locArrDate"
                                        ]
                                      ) {
                                        var dt =
                                          rec1[sort_arrays[1]]["ports"][k][
                                            "locArrDate"
                                          ];

                                        output_v2 = moment(dt).format("D-MMM");
                                      }
                                      if (
                                        rec1[sort_arrays[1]]["ports"][k][
                                          "locDepDate"
                                        ]
                                      ) {
                                        var dt =
                                          rec1[sort_arrays[1]]["ports"][k][
                                            "locDepDate"
                                          ];
                                        output_v3 = moment(dt).format("D-MMM");
                                      }
                                    }
                                  }
                                  return (
                                    <td
                                      className={
                                        "schedule-" + (n % 2 ? "odd" : "even")
                                      }
                                    >
                                      <p>{output_v1}</p>
                                      <p>{output_v2}</p>
                                      <p>{output_v3}</p>
                                    </td>
                                  );
                                })}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div class="voyage_details_base">
                  <div class="container">
                    <div class="voyage_details_inner">
                      <h3>VOYAGE REMARKS</h3>
                      {vslRemarks &&
                        vslRemarks.map((vslRemarkss, n) => {
                          return <p>{vslRemarkss}</p>;
                        })}
                    </div>
                    </div>
                    </div>
                    <div class="voyage_details_base">
                      <div class="container">
                        <div class="voyage_details_inner">
                          <h3>*Exceptional port for voyage</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                
          ) : (
            <div
              class="row"
              style={{
                minHeight: `200px;`,
                border: `solid 1px;`,
                display: schedulesearchflag ? "block" : "none",
              }}
            >
              <div class="col">
                <h3 style={{ paddingTop: `72px;`, textAlign: `center;` }}>
                  <i class="fa fa-search" aria-hidden="true"></i> No Record
                  Found.
                </h3>
              </div>
            </div>
          )}
        </div>
      </section>
     
    </Layout>
  );
};
export default Schedule_by_line;
