import React, { Component, useEffect, useState } from "react";
import AboutGsl from '../../assets/inner-images/about/about_gsl.jpg';
import companyProfile from '../../assets/inner-images/about/company_profile.jpg';
import companyHistory from '../../assets/inner-images/about/company_history.jpg';
import factsFigures from '../../assets/inner-images/about/facts_figures.jpg';
import cargoServices from '../../assets/inner-images/cargo_services/cargo_services.jpg';
import dangerousCargo from '../../assets/inner-images/cargo_services/dangerous_cargo.jpg';
import dryCargo from '../../assets/inner-images/cargo_services/dry_cargo.jpg';
import gslMonitor from '../../assets/inner-images/cargo_services/gsl_monitor.jpg';
import inlandTransport from '../../assets/inner-images/cargo_services/inland_transport.jpg';
import projectCargo from '../../assets/inner-images/cargo_services/project_cargo.jpg';
import reeferCargo from '../../assets/inner-images/cargo_services/reefer_cargo.jpg';
import temperatureControlledShipping from '../../assets/inner-images/cargo_services/temperature_controlled_shipping.jpg';
import highValueOrSensitiveCargo from '../../assets/inner-images/cargo_services/high_value_or_sensitive_cargo.jpg';
import Catalogue from '../../assets/inner-images/tools/catalogue.jpg';
import eblService from '../../assets/inner-images/tools/ebl_service.jpg';
import requestaQuote from '../../assets/inner-images/tools/request_a_quote.jpg';
import siSubmission from '../../assets/inner-images/tools/si_submission.jpg';
import bookingSubmission from '../../assets/inner-images/tools/booking_submission.jpg';
import vgmMassUpload from '../../assets/inner-images/tools/vgm_mass_upload.jpg';
import careers from '../../assets/inner-images/careers/careers.jpg';
import demurrage_and_detention_tariff from '../../assets/inner-images/local_info/demurrage_and_detention_tariff.jpg';
import local_info from '../../assets/inner-images/local_info/local_info.jpg';
import help_customer_advisory from '../../assets/inner-images/help/help_customer_advisory.jpg';
import newsletter from '../../assets/inner-images/newsletter/newsletter.jpg';
import terms_of_use from '../../assets/inner-images/help/terms_of_use.jpg';
import enquiry_us from '../../assets/inner-images/enquiry/enquiry_us.jpg';
import faq_gsl from '../../assets/inner-images/enquiry/faq_gsl.jpg';
import catalogues from '../../assets/inner-images/catalogue/catalogue.jpg';
import roro from '../../assets/inner-images/roro/roro.jpg';
import subscribe from '../../assets/inner-images/about/subscribe.jpg';
import shapeBlue from '../../../src/assets/banner-shape-blue.svg';
import shapeYellow from '../../../src/assets/banner-shape.svg';

const innerJSON =
{
    "subscribe": {
        "title": "申し込む",
        "discription": "貨物に関するアラートをリアルタイムに受信し、貨物の状況を完全に把握することができます。",
        "url": subscribe,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
        
    },
    "about_gsl": {
        "title": "コンテナサイス",
        "discription": "貨物の仕様やニーズに合わせた輸送計画を立てます。",
        "url": AboutGsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',

    },
    "cargo_catalogue": {
        "title": "コンテナサイス",
        "discription": "貨物の仕様やニーズに合わせた輸送計画を立てます。",
        "url": catalogues,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
        
    },
    "request_a_quote": {
        "title": "見積り依頼",
        "discription": "私たちの使命と目標は、あなたのビジネスのためにさまざまな貨物サービスを提供することです",
        "url": requestaQuote,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_submission": {
        "title": "Booking Submission",
        "discription": "私たちの使命と目標は、あなたのビジネスのためにさまざまな貨物サービスを提供することです",
        "url": bookingSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ebl_service": {
        "title": "eBLサービス",
        "discription": "私たちの使命と目標は、あなたのビジネスのためにさまざまな貨物サービスを提供することです",
        "url": eblService,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "si_submission": {
        "title": "SI Submission",
        "discription": "貨物の詳細、要件のシームレスな提出",
        "url": siSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ecommerce_partners": {
        "title": "eコマースパートナー",
        "discription": "私たちの使命と目標は、あなたのビジネスのためにさまざまな貨物サービスを提供することです",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "vgm_mass_upload": {
        "title": "Vgm Mass Upload",
        "discription": "VGM submission for multiple shipments  made easy and familiar",
        "url": vgmMassUpload,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "high_value_or_sensitive_cargo": {
        "title": "High value or sensitive cargo",
        "discription": "GSL Monitor は、貴重な貨物を追跡、監視、制御するための正確なツールを提供し、安全に配達されるようにします。",
        "url": highValueOrSensitiveCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "enquiry": {
        "title": "お問い合わせ",
        "discription": "当社へご連絡ください！お客さまに必要な情報を喜んでご提供します。",
        "url": enquiry_us,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "faq": {
        "title": "よくある質問",
        "discription": "ゴールドスターラインと当社のサービスについての詳細",
        "url": faq_gsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "roro-private": {
        "title": "自動車輸送サービス",
        "discription": "世界各地で高まる自動車輸送の需要に対応しています",
        "url": roro,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "newsletter": {
        "title": "ニュースとアップデート",
        "discription": "最新の情報やお知らせをご確認ください！",
        "url": newsletter,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_newsletter": {
        "title": "ニュースレター一覧",
        "discription": "私たちの使命と目標は、あなたのビジネスのためにさまざまな貨物サービスを提供することです",
        "url": newsletter,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "cargo_services": {
        "title": "カ－ゴサービス",
        "discription": "あらゆる貨物に対応する海上輸送サービスを提供することが、私たちの使命です。",
        "url": cargoServices,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "gsl_monitor": {
        "title": "GSL モニター",
        "discription": "繊細な貨物、高価な貨物、冷凍貨物などを輸送する際に、貨物の環境を追跡、監視、制御します",
        "url": gslMonitor,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dry_cargo": {
        "title": "ドライカーゴ",
        "discription": "60年以上にわたり、あらゆる種類の乾燥貨物を扱ってきた経験から、お客様のニーズにお応えします。",
        "url": dryCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "reefer_cargo": {
        "title": "リ－ファ－カ－ゴ",
        "discription": "当社の高度な技術により、お客様の冷凍貨物を安全に、適切な温度で、時間通りにお届けすることができます。",
        "url": reeferCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "project_cargo": {
        "title": "プロジェクトカーゴ",
        "discription": "お客様の特殊な貨物を安全に世界中に輸送するために必要な、創造的なソリューションと専門知識を有しています。",
        "url": projectCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "inland_transport": {
        "title": "内陸輸送",
        "discription": "ドア・ツー・ドアの広範なネットワークで、信頼性の高い陸上輸送サービスを提供します。",
        "url": inlandTransport,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "temperature_controlled_shipping": {
        "title": "温度設定貨物",
        "discription": "チルド、冷凍、あらゆる温度変化に敏感な製品の安全輸送を実現します。",
        "url": temperatureControlledShipping,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dangerous_cargo": {
        "title": "危険品",
        "discription": "危険物の安全な保管と輸送のための専門的なサービスを提供します。",
        "url": dangerousCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "about_gsl": {
        "title": "Gold Star Linesについて",
        "discription": "ゴールドスターライン - アジア、オセアニア、アフリカ域内航路の主要企業として、ダイナミックな市場に機敏なアプローチでサービスを提供します。",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_profile": {
        "title": "会社概要",
        "discription": "私たちはお客様のニーズに最適なサービスを提供することに重点を置いています。",
        "url": companyProfile,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_history": {
        "title": "当社の歩み",
        "discription": "ゴールドスターラインの歴史、画期的な出来事、発展についてご紹介します。",
        "url": companyHistory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "facts_figures": {
        "title": "事実と数値",
        "discription": "私たちの使命は、お客様のニーズをサポートするための創造的な解決策を見つけ続けることです。",
        "url": factsFigures,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "demurrage_and_detention_tariff": {
        "title": "コンテナ保管価格",
        "discription": "保管料、拘留料、1日あたりの料金は、謎である必要はありません。",
        "url": demurrage_and_detention_tariff,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "local_info": {
        "title": "ローカル情報",
        "discription": "常にお客様のロケーションにおける最新の情報を入手することができます。",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "unsubscribe_schedule": {
        "title": "Unsubscribe Schedule",
        "discription": "",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "contact_us": {
        "title": "GSL関連オフィス",
        "discription": "各地オフィスの住所、連絡先が確認できます",
        "url": enquiry_us,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "careers": {
        "title": "Careers",
        "discription": "ゴールドスターラインに入社して、私たちと共に成長しましょう！",
        "url": careers,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "solas_regulation": {
        "title": "solas regulation",
        "discription": "Misi dan tujuan kami adalah menyediakan berbagai layanan kargo untuk mengutamakan bisnis Anda",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "customer_advisory": {
        "title": "カスタマーアドバイザリー",
        "discription": "通知や アドバイスを常に確認してください",
        "url": help_customer_advisory,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "accessibility": {
        "title": "アクセシビリティ",
        "discription": "アクセシビリティ モデルで生活の質を向上",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "data_protection_policy": {
        "title": "データ保護ポリシー",
        "discription": "個人データの保護に関する当社のポリシーについてお知らせ",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_cookies_policy": {
        "title": " Cookies and Tracking Policy",
        "discription": "お客様の個人データとデータのプライバシーに関する当社のコミットメント",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "quotation_terms_conditions": {
        "title": " 見積もり条件",
        "discription": "見積もりの条件に合わせてください",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "terms_of_use": {
        "title": "利用規約",
        "discription": "ガイドラインとサービス利用規約",
        "url": terms_of_use,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_customer_advisory": {
        "title": " カスタマーアドバイザリー",
        "discription": "お客様へのお知らせと注意事項",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "bl_terms_conditions": {
        "title": "B/L条件",
        "discription": "B/L (Bill of Ladings) の法的条件について通知を受ける",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_go_terms_conditions": {
        "title": "GSL GO 利用規約",
        "discription": "GSL GO の法的条件に関する通知を受け取る",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "about_car_carrier": {
        "title": "自動車運搬船について",
        "discription": "",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "egsl_terms_conditions": {
        "title": "eGSL 利用規約",
        "discription": "B/L (Bill of Ladings) の法的条件について通知を受ける",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_clause": {
        "title": "予約条項",
        "discription": "予約確認書に関連する条項を知る",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_company_news": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_regulation": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_industry": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },"sea_waybill_terms_conditions": {
        "title": " SEA WAYBILL TERMS AND CONDITIONS",
        "discription": "TERMS AND CONDITIONS",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
}


export default innerJSON;