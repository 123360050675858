import React, { Component } from 'react';
import Layout from '../../layout';
import GET_IN_TOUCH from '../../get_in_touch/index';
import Services from '../../get_in_touch/services';
import Packages from '../../get_in_touch/packages_block';
import qrcode from '../../../../src/assets/inner-images/qrcode/gslqrcode.jpg';
import Inner_container from '../inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import 'react-tabs/style/react-tabs.css';



const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class QrCode extends Component {
    render() {
        return (

            <Layout>
            

                <section class="inner-center n-40 rr-padd">
                    <div class="container">
                        <div class="row m-0">
                            <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12 p-0 mt-5 text-center">
                              
                            <p> <img src={qrcode}  alt="qrcode" /></p>    
                            </div>
                        </div>
                        
                    </div>
                </section>
          
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}