import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import "flatpickr/dist/themes/material_green.css";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as axios from "axios";
import Api from "../../../../package.json";
import "../tools/css/css-loader.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import cont_gsl from "../../../assets/cont-gsl.png";
import cont_search from "../../../assets/cont-search.png";
import swal from "sweetalert";
import vgm_form_lang from '../../lang/vgm_form';
import HCaptcha from "@hcaptcha/react-hcaptcha";

const qs = require("qs");
var jwt = require("jsonwebtoken");

const Vgm_form = () => {

  var lang = window.localStorage.getItem("lang");
  if (window.localStorage.getItem("lang") == null) {
    lang = 'en';
    window.localStorage.setItem("lang", 'en');
  }

  const headers = {
    type: "application/json",
    Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const containweightRegex = /^[a-zA-Z]{4}[0-9]{7}$/;
  const validationSchema = Yup.object().shape({
    notification_email: Yup.string().required(
      "Please enter our notification email address"
    ),
    country: Yup.string().required(
      "Please select your booking office country location"
    ),
    bl_number: Yup.string().required("Please enter BL number").
      test(
        "Invalid format", // Msg
        async (bl_number) => {
          if (checkBL == true) {
            const response = await axios.post(
              Api.userdata.url + "/bl_validation",
              qs.stringify({ containerid: bl_number }), { headers: headers }
            );
            const res = await response.data;
            if (res == false) {
              return false;
            } else {

              return true;
            }
          }

        }
      ),
    partyinformation: Yup.array().of(
      Yup.object().shape({
        location: Yup.string().required("Please select your country"),
        city: Yup.string().required("Please select your city"),
        certificate_name: Yup.string().required(
          "Please select your certificate name"
        ),
        state: Yup.string().required("Please select your state"),
        company_name: Yup.string().required(
          "Please select your certificate name"
        ),
        phone: Yup.string()
          .required("Please enter your phone number")
          .matches(phoneRegExp, "Invalid")
          .min(6, "Invalid")
          .max(15, "Invalid"),
        phoneone: Yup.string().required("*"),
      })
    ),
    containerinformation: Yup.array().of(
      Yup.object().shape({
        container_number: Yup.string()
          .required("Please enter your container number")
          .matches(
            containweightRegex,
            "Enter container number format : 4 letters followed by 7 digits"
          ),
        net_weight: Yup.string().required("Net weight required"),
        tare_weight: Yup.string().required("Net weight required"),
        verified_gross_mass: Yup.string().required(
          "verified gross mass weight required"
        ),
        certificate_name_op: Yup.string().required(
          "Please select certificate name"
        ),
      })
    ),
  });
  const buttonRef = useRef();
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const [SubmitButton, setSubmitButton] = useState("I Agree And Submit");
  const formOptions = { resolver: yupResolver(validationSchema) };
  const [country_list, Setcountry_list] = useState([]);
  const [checkBL, setcheckBL] = useState(false);
  const [certifierName, setCertifierName] = useState([]);
  const [BookingOfficeLocation, setBookingOfficeLocation] = useState([]);
  const [country_code, setCountryCode] = useState(window.$country_code);
  const [ebl_captcha, setebl_captcha] = useState([]);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState,
    watch,
    setValue,
    getValues,
  } = useForm(formOptions);
  const { errors } = formState;
  const {
    fields: containerInformationFields,
    append: containerInformationAppend,
    remove: containerInformationRemove,
  } = useFieldArray({ name: "containerinformation", control });
  const vgm_office = async (country) => {
    const response = await axios.post(
      Api.userdata.url + "/vgm_office",
      qs.stringify({ country: country}, { parseArrays: false }), { headers: headers }
    );
    const json = await response.data;
    if (json["status"] == 1) {
      setBookingOfficeLocation(json["message"]);
    } else {
      setBookingOfficeLocation([]);
    }
  };
  const {
    fields: partyInformationFields,
    append: partyInformationAppend,
    remove: partyinformationRemove,
  } = useFieldArray({ name: "partyinformation", control });

  const addpartyinformation = () => {
    partyInformationAppend({ party_type: "Weighing Party" });
  };
  const addcontainerInformation = () => {
    containerInformationAppend({ container_number: "" });
  };
  useEffect(() => {
    getLoadData();
    vgm_office();
    partyInformationAppend({ party_type: "Shipper" });
    containerInformationAppend({
      container_number: "",
      tare_weight: "0",
      net_weight: "0",
    });
  }, []);

  const tare_weight_inquiry_val = async (containerid) => {
    const response = await axios.post(
      Api.userdata.url + "/tare_weight_inquiry_val",
      qs.stringify({
        containerid: containerid,
      }), { headers: headers }
    );
    const json = await response.data;
    return response.data;
  };
  function onSubmit(data) {
    // display form data on success
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
    save_vgm(data);
  }

  const save_vgm = async (data) => {
    if(token != null)
    {
       data['vgm_form_captcha'] = token;
       buttonRef.current.disabled = true;
    setSubmitButton("Submitting...");
    const response = await axios.post(
      Api.userdata.url + "/submit_vgm_form",
      qs.stringify(data), { headers: headers }
    );
    const json = await response.data;
    if (json["status"] == "OK") {
      swal("Successful Submitted", json["message"], "success").then((value) => {
        window.location.reload(false);
      });
    } else {
      swal("Error !", json["message"], "error");
    }
    buttonRef.current.disabled = false;
    setSubmitButton("I Agree And Submit");
    //var jsval = JSON.parse([0]["details"]);
    //setHeadercaptcha(json["captcha"]);

    //setHeaderJsonContent(jsval);
    }else{
      swal("Error !", "Invalid captcha", "error");
    }
   
  };
  const getLoadData = async () => {
    const response = await axios.post(
      Api.userdata.url + "/country_list",
      qs.stringify({ token: "2" }, { parseArrays: false }), { headers: headers }
    );
    const json = await response.data;
    Setcountry_list(json["message"]);

    const response_ebl_captcha = await axios.post(
      Api.userdata.url + "/vgm_form_captcha",
      qs.stringify({ token: "2" }, { parseArrays: false }), { headers: headers }
    );
    
    const json_ebl_captcha = await response_ebl_captcha.data;
    setebl_captcha(json_ebl_captcha["captcha"]);
  };

  return (
    <Layout>
      <section class="inner-center">
        <br></br>
        <div class="container  p-4">
          <div class="form-fback">
            <div class="row m-0">
              <div class="col-lg-4 col-md-12 col-sm-12 pot-form p-3">
                <div class="dout-inner">
                  <h3>{vgm_form_lang[lang].SUBMITVGM}</h3>
                  <p>{vgm_form_lang[lang].SUBMITVGM1}</p>
                </div>
              </div>

            </div>
          </div>
          <div class="row m-0">
            <div
              class="col-lg-12 col-md-12 col-lg-12"
              style={{ minHeight: "500px;" }}
            >
              <form
                action="#"
                name="vgm_form"
                id="vgm_form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div class="tab-content p-3 mt-2 border trade-tab-con booking-tab-cont">
                  <div class="tab-pane active" role="tabpanel" id="step1">
                    <h4>{vgm_form_lang[lang].BookingDetails}</h4>
                    <div class="row m-0">
                      <div class="col-lg-6 col-sm-6 pot-form pl-0">
                        <label>
                          {vgm_form_lang[lang].BookingBLNumber}<sup class="starss">*</sup>
                        </label>
                        <input
                          {...register("bl_number")}
                          type="text"
                          placeholder=""
                          className={`form-control ${errors.bl_number ? "is-invalid" : ""
                            }`}
                          onBlur={(e) => {

                            if (e.target.value != "") {
                              setcheckBL(true)
                            }
                            else {
                              setcheckBL(false)
                            }
                          }
                          }
                          style={{ textTransform: `uppercase` }}
                        />
                        <div className="errors">
                          {errors.bl_number?.message}
                        </div>
                        <label>
                        {vgm_form_lang[lang].NotificationEmail}<sup class="starss">*</sup>
                        </label>
                        <input
                          {...register("notification_email")}
                          type="text"
                          name="notification_email"
                          id="notification_email"
                          placeholder=""
                          className={`form-control ${errors.notification_email ? "is-invalid" : ""
                            }`}
                        />
                        <div className="errors">
                          {errors.notification_email?.message}
                        </div>
                        <label>{vgm_form_lang[lang].addmultipleemailsseparatedby} ","</label>
                        <br></br>
                      </div>
                      <div class="col-lg-6 col-sm-6 pot-form pl-0">
                        <label>
                        {vgm_form_lang[lang].BookingOfficeCountryLocation}
                          <sup class="starss">*</sup>
                        </label>
                        <Controller
                          control={control}
                          render={({ onChange, value, ref }) => (
                            <Select
                              options={country_list.map((data) => ({
                                value: data["name"],
                                phone_code: data["phonecode"],
                                label: data["name"],
                              }))}
                              onChange={(val) => {
                                vgm_office(val.label);
                                setValue("country", val.label);
                              }}
                            />
                          )}
                          defaultValue=""
                          placeholder="Select platform"
                          name="country"
                          className={`form-control ${errors.country ? "is-invalid" : ""
                            }`}
                          setValue={setValue}
                        />
                        <div className="errors">{errors.country?.message}</div>
                        <div class="booking_office_location">
                          <label>{vgm_form_lang[lang].BookingOffice}</label>
                          <Controller
                            control={control}
                            render={({ onChange, value, ref }) => (
                              <Select
                                options={BookingOfficeLocation.map((data) => ({
                                  value: data["booking_offices"],
                                  label: data["booking_offices"],
                                }))}
                                onChange={(val) => {
                                  setValue("booking_office", val.label);
                                }}
                              />
                            )}
                            defaultValue=""
                            placeholder="Select platform"
                            name="booking_office"
                            setValue={setValue}
                          />
                          {errors.date && (
                            <span class="errors">{errors.date.message}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  {partyInformationFields.map((item, i) => (
                    <div>
                      <div class="col-lg-10 col-sm-10 pot-form pl-0">
                        <h4>{vgm_form_lang[lang].PartyInformation}</h4>
                      </div>

                      {i != 0 ? (
                        <div class="col-lg-2 col-sm-2">
                          <a
                            class="remove_more_bt"
                            href="javascript:void(0)"
                            onClick={() => partyinformationRemove(i)}
                          >
                            <h4>
                              - <span>{vgm_form_lang[lang].Delete}</span>
                            </h4>
                          </a>
                        </div>
                      ) : (
                        ""
                      )}

                      <div class="row m-0">
                        <div class="col-lg-6 col-sm-6 pot-form pl-0">
                          <label> {vgm_form_lang[lang].Type}</label>
                          <input
                            {...register(`partyinformation.${i}.party_type`)}
                            type="text"
                            className={`form-control ${errors.partyinformation?.[i]?.party_type
                                ? "is-invalid"
                                : ""
                              }`}
                            value="Shipper"
                            readonly
                          />
                          <div className="errors">
                            {errors.partyinformation?.[i]?.party_type?.message}
                          </div>
                          <label>
                          {vgm_form_lang[lang].Country}<sup class="starss">*</sup>
                          </label>
                          <Controller
                            control={control}
                            render={({ onChange, value, ref }) => (
                              <Select
                                options={country_list.map((data) => ({
                                  value: data["name"],
                                  label: data["name"],
                                  phonecode: data["phonecode"],
                                }))}
                                onChange={(val) => {
                                  setValue(
                                    `partyinformation.[${i}].location`,
                                    val.value
                                  );
                                  setValue(
                                    `partyinformation.[${i}].phoneone`,
                                    val.phonecode
                                  );
                                }}
                              />
                            )}
                            name={`partyinformation.[${i}].location`}
                            className={`selectpicker ${errors.partyinformation?.[i]?.location
                                ? "is-invalid"
                                : ""
                              }`}
                            placeholder="--Select Location--"
                          />
                          <div className="errors">
                            {errors.partyinformation?.[i]?.location?.message}
                          </div>
                          <label>
                            {" "}
                            {vgm_form_lang[lang].StateProvince} <sup class="starss">*</sup>
                          </label>
                          <input
                            className={`form-control ${errors.partyinformation?.[i]?.state
                                ? "is-invalid"
                                : ""
                              }`}
                            {...register(`partyinformation.${i}.state`)}
                            type="text"
                            placeholder=""
                          />
                          <div className="errors">
                            {errors.partyinformation?.[i]?.state?.message}
                          </div>
                          <label>
                            {" "}
                            {vgm_form_lang[lang].City}  <sup class="starss">*</sup>
                          </label>
                          <input
                            className={`form-control ${errors.partyinformation?.[i]?.city
                                ? "is-invalid"
                                : ""
                              }`}
                            {...register(`partyinformation.${i}.city`)}
                            type="text"
                            placeholder=""
                          />
                          <div className="errors">
                            {errors.partyinformation?.[i]?.city?.message}
                          </div>
                          <label>
                          {vgm_form_lang[lang].CertifierName} <sup class="starss">*</sup>
                          </label>
                          <input
                            {...register(
                              `partyinformation.${i}.certificate_name`
                            )}
                            className={`form-control ${errors.partyinformation?.[i]?.certificate_name
                                ? "is-invalid"
                                : ""
                              }`}
                            onBlur={(e) => {
                              var dublicate_flag = false;
                              var newcertifierName = [];

                              if (certifierName.length == 0) {
                                newcertifierName[i] = e.target.value;
                              } else {
                                certifierName.map(function (value, index) {
                                  if (i != index && value == e.target.value) {
                                    dublicate_flag = true;
                                  }
                                });
                              }

                              if (dublicate_flag == false) {
                                certifierName.map(function (value, index) {
                                  newcertifierName[index] = value;
                                });
                                newcertifierName[i] = e.target.value;
                                setCertifierName(newcertifierName);
                                setValue(
                                  `containerinformation.[${i}].certificate_name_op`,
                                  ""
                                );
                              } else {
                                setValue(
                                  `partyinformation.[${i}].certificate_name`,
                                  ""
                                );
                                alert("Duplicate Certifier Name");
                              }
                            }}
                            type="text"
                            placeholder=""
                          />
                          <div className="errors">
                            {
                              errors.partyinformation?.[i]?.certificate_name
                                ?.message
                            }
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 pot-form pl-0 info-right-form">
                          <label>
                          {vgm_form_lang[lang].CompanyName} <sup class="starss">*</sup>
                          </label>
                          <input
                            className={`form-control ${errors.partyinformation?.[i]?.company_name
                                ? "is-invalid"
                                : ""
                              }`}
                            {...register(`partyinformation.${i}.company_name`)}
                            type="text"
                            placeholder=""
                          />
                          <div className="errors">
                            {
                              errors.partyinformation?.[i]?.company_name
                                ?.message
                            }
                          </div>
                          <label>{vgm_form_lang[lang].Address} </label>
                          <textarea
                            {...register(`partyinformation.${i}.address`)}
                            style={{
                              marginTop: `0px`,
                              marginBottom: `0px !important`,
                              height: `131px`,
                            }}
                          ></textarea>
                          <li
                            class="ind-f-full"
                            style={{ listStyle: `none`, margin: `6px 0` }}
                          >
                            <label>
                            {vgm_form_lang[lang].PhoneNumber}   <sup class="starss">*</sup>
                            </label>
                            <div class="phone-country">
                              <div class="feedback-code">
                                <label class="pl-feed">+</label>
                                <input
                                  {...register(
                                    `partyinformation.${i}.phoneone`
                                  )}
                                  placeholder="852"
                                  className={`form-control ${errors.partyinformation?.[i]?.phoneone
                                      ? "is-invalid"
                                      : ""
                                    }`}
                                  type="text"
                                  style={{ marginBottom: `0 !important` }}
                                />
                                <div className="errors">
                                  {
                                    errors.partyinformation?.[i]?.phoneone
                                      ?.message
                                  }
                                </div>
                              </div>
                              <div class="feedback-number">
                                <label class="min-feed">-</label>
                                <input
                                  {...register(`partyinformation.${i}.phone`)}
                                  placeholder="Contact number"
                                  type="text"
                                  className={`form-control ${errors.partyinformation?.[i]?.phone
                                      ? "is-invalid"
                                      : ""
                                    }`}
                                  style={{ marginBottom: `0 !important` }}
                                />
                                <div className="errors">
                                  {errors.partyinformation?.[i]?.phone?.message}
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div class="col-lg-4 col-sm-12">
                    <a
                      id="add_more_bt"
                      href="javascript:void(0)"
                      onClick={addpartyinformation}
                    >
                      <h4>
                        + <span>{vgm_form_lang[lang].AddMoreCertifier} </span>
                      </h4>
                      <br></br>
                    </a>
                  </div>
                  {containerInformationFields.map((item, i) => {
                    var methodss;
                    var container_numberss;
                    var unit_of_measurementss;
                    var tare_weightss;
                    var net_weightss;
                    const calcu = async () => {
                      methodss = getValues(`containerinformation.${i}.method`);
                      container_numberss = getValues(`containerinformation.${i}.container_number`);
                      unit_of_measurementss = getValues(`containerinformation.${i}.unit_of_measurement`);
                      tare_weightss = getValues(`containerinformation.${i}.tare_weight`);
                      net_weightss = getValues(`containerinformation.${i}.net_weight`);

                      if (methodss == "Method 1") {
                        setValue(`containerinformation.${i}.tare_weight`, "0");
                        setValue(`containerinformation.${i}.net_weight`, "0");
                      } else if (
                        methodss == "Method 2" &&
                        container_numberss != ""
                      ) {

                        setValue(`containerinformation.${i}.tare_weight`, "");
                        setValue(`containerinformation.${i}.net_weight`, "");

                        tare_weightss = 0;
                        net_weightss = 0;
                        var res = await tare_weight_inquiry_val(
                          container_numberss
                        );
                        if (res["status"] == "OK") {
                          if (unit_of_measurementss == "KG") {
                            tare_weightss = res["unitTare"];
                            setValue(
                              `containerinformation.${i}.tare_weight`,
                              res["unitTare"]
                            );
                          } else {
                            tare_weightss = res["unitTareCalc"];
                            setValue(
                              `containerinformation.${i}.tare_weight`,
                              res["unitTareCalc"]
                            );
                          }
                        }
                        else {
                          alert('Tarewt not retrievable - Use Method 1.');
                          setValue(`containerinformation.${i}.container_number`, "");
                          setValue(`containerinformation.${i}.tare_weight`, "");
                          setValue(`containerinformation.${i}.net_weight`, "");
                        }
                      } else {
                        setValue(`containerinformation.${i}.tare_weight`, "");
                        setValue(`containerinformation.${i}.net_weight`, "");
                      }
                    };
                    const calcu_tare = async () => {
                      tare_weightss = getValues(`containerinformation.${i}.tare_weight`);
                      net_weightss = getValues(`containerinformation.${i}.net_weight`);
                      if (tare_weightss != 0 && net_weightss != 0) {

                        //alert(Number(tare_weight)+ Number(net_weight));
                        setValue(
                          `containerinformation.${i}.verified_gross_mass`,
                          Number(tare_weightss) + Number(net_weightss)
                        );
                      }
                    };
                    return (
                      <div>
                        <div class="col-lg-10 col-sm-10 pot-form pl-0 ">
                          <h4>{vgm_form_lang[lang].ContainerInformation}</h4>
                        </div>
                        {i != 0 ? (
                          <div class="col-lg-2 col-sm-2">
                            <a
                              class="remove_more_bt"
                              href="javascript:void(0)"
                              onClick={() => containerInformationRemove(i)}
                            >
                              <h4>
                                - <span>{vgm_form_lang[lang].Delete}</span>
                              </h4>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                        <div class="row m-0">
                          <div
                            class="alert alert-danger col-lg-12 method-alert"
                            role="alert"
                            style={{ display: `none` }}
                          >
                            <h4 class="alert-heading">{vgm_form_lang[lang].Alert}!</h4>
                            <p>{vgm_form_lang[lang].TarewtUseMethod1}.</p>
                          </div>
                          <div class="col-lg-6 col-sm-6 pot-form pl-0">
                            <label>{vgm_form_lang[lang].Method}</label>
                            <select
                              class="con-selet-red selectpicker method"
                              {...register(`containerinformation.${i}.method`)}
                              onChange={(e) => {
                                setValue(
                                  `containerinformation.${i}.verified_gross_mass`,
                                  ""
                                );
                                setValue(
                                  `containerinformation.${i}.container_number`,
                                  ""
                                );
                                if (e.target.value == "Method 2") {
                                  var id = `containerinformation.${i}.tareblk`;
                                  var x = document.getElementById(id);
                                  x.style.display = "block";
                                  var y = document.getElementById(
                                    `containerinformation.${i}.tare_weight`
                                  );
                                  y.readOnly = true;
                                  var z = document.getElementById(
                                    `containerinformation.${i}.verified_gross_mass`
                                  );
                                  z.readOnly = true;
                                } else {
                                  var id = `containerinformation.${i}.tareblk`;
                                  var x = document.getElementById(id);
                                  x.style.display = "none";
                                  var y = document.getElementById(
                                    `containerinformation.${i}.tare_weight`
                                  );
                                  y.readOnly = false;
                                  var z = document.getElementById(
                                    `containerinformation.${i}.verified_gross_mass`
                                  );
                                  z.readOnly = false;
                                }

                                calcu();

                              }}
                            >
                              <option value="Method 1">Method 1</option>
                              <option value="Method 2">Method 2</option>
                            </select>
                            <label> {vgm_form_lang[lang].ContainerNumber}</label>
                            <input
                              {...register(
                                `containerinformation.${i}.container_number`
                              )}
                              type="text"
                              placeholder=""
                              className={`form-control ${errors.partyinformation?.[i]?.container_number
                                  ? "is-invalid"
                                  : ""
                                }`}
                              style={{ textTransform: `uppercase` }}
                              onBlur={(e) => {

                                calcu();
                              }}
                            />
                            <div className="errors">
                              {
                                errors.containerinformation?.[i]
                                  ?.container_number?.message
                              }
                            </div>
                            <label>
                            {vgm_form_lang[lang].UnitOfMeasure} <sup class="starss">*</sup>
                            </label>
                            <select
                              {...register(
                                `containerinformation.${i}.unit_of_measurement`
                              )}
                              class="con-selet-red selectpicker unit_of_measurement"
                              onBlur={(e) => {

                                calcu();
                              }}
                            >
                              <option value="KG">KG</option>
                              <option value="LBS">LBS</option>
                            </select>
                            <div
                              id={`containerinformation.${i}.tareblk`}
                              class="method2"
                              style={{ display: `none` }}
                            >
                              <label>
                              {vgm_form_lang[lang].TareWeight} <sup class="starss">*</sup>
                              </label>
                              <input
                                {...register(
                                  `containerinformation.${i}.tare_weight`
                                )}
                                onBlur={(e) => {

                                  calcu_tare();
                                  // alert(tare_weight);
                                }}
                                type="text"
                                placeholder=""
                                id={`containerinformation.${i}.tare_weight`}
                                className={`form-control ${errors.containerinformation?.[i]?.tare_weight
                                    ? "is-invalid"
                                    : ""
                                  }`}
                              />
                              <div className="errors">
                                {
                                  errors.containerinformation?.[i]?.tare_weight
                                    ?.message
                                }
                              </div>
                              <label>
                              {vgm_form_lang[lang].NetWeight} <sup class="starss">*</sup>
                              </label>
                              <input
                                type="number"
                                {...register(
                                  `containerinformation.${i}.net_weight`
                                )}
                                onBlur={(e) => {

                                  calcu_tare();
                                }}
                                placeholder=""
                                className={`form-control ${errors.containerinformation?.[i]?.net_weight
                                    ? "is-invalid"
                                    : ""
                                  }`}
                              />
                              <div className="errors">
                                {
                                  errors.containerinformation?.[i]?.net_weight
                                    ?.message
                                }
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-sm-6 pot-form pl-0">
                            <label>
                            {vgm_form_lang[lang].VerifiedGrossMass} <sup class="starss">*</sup>
                            </label>
                            <input
                              {...register(
                                `containerinformation.${i}.verified_gross_mass`
                              )}
                              type="text"
                              placeholder=""
                              id={`containerinformation.${i}.verified_gross_mass`}
                              className={`form-control ${errors.containerinformation?.[i]
                                  ?.verified_gross_mass
                                  ? "is-invalid"
                                  : ""
                                }`}
                            />
                            <div className="errors">
                              {
                                errors.containerinformation?.[i]
                                  ?.verified_gross_mass?.message
                              }
                            </div>
                            <label> {vgm_form_lang[lang].VerificationDate} </label>
                            <div
                              id="datepicker"
                              class="input-group date datepickerss"
                              data-date-format="dd/mm/yyyy"
                            >
                              <Controller
                                control={control}
                                render={({ onChange, value, ref }) => (
                                  <Flatpickr
                                    options={{ maxDate: "today", dateFormat: "d-m-Y" }}
                                    onChange={(val) => {
                                      setValue(
                                        `containerinformation.[${i}].verification_date`,
                                        val[0]
                                      );
                                    }}
                                  />
                                )}
                                name={`containerinformation.[${i}].verification_date`}
                                className={`form-control ${errors.containerinformation?.[i]
                                    ?.verification_date
                                    ? "is-invalid"
                                    : ""
                                  }`}
                              />
                              <div className="errors">
                                {
                                  errors.containerinformation?.[i]
                                    ?.verification_date?.message
                                }
                              </div>

                              <span class="input-group-addon">
                                <i class="glyphicon glyphicon-calendar"></i>
                              </span>
                            </div>
                            <label>
                            {vgm_form_lang[lang].CertifierName} <sup class="starss">*</sup>
                            </label>
                            <Controller
                           
                              control={control}
                              render={({ onChange, value, ref }) => (
                                <Select
                              
                                  options={certifierName.map((data) => ({
                                    value: data,
                                    label: data,
                                  }))}
                                  onChange={(val) => {
                                    setValue(`containerinformation.[${i}].certificate_name_op`, val.label);
                                  }}
                                />
                              )}
                              defaultValue=""
                              placeholder="Select platform"
                              name={`containerinformation.[${i}].certificate_name_op`}
                              className={`form-control ${errors.country ? "is-invalid" : ""
                                }`}
                              setValue={setValue}
                            />
                            <div className="errors">
                              {
                                errors.containerinformation?.[i]
                                  ?.certificate_name_op?.message
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div class="col-lg-4 col-sm-12">
                    <a
                      id="add_more_bt"
                      href="javascript:void(0)"
                      onClick={addcontainerInformation}
                    >
                      <h4>
                        + <span> {vgm_form_lang[lang].AddNewContainer}</span>
                      </h4>
                      <br></br>
                    </a>
                  </div>
                  <div className="vgm-form-button">
                  <HCaptcha
                  sitekey="a8400b17-0f2c-4116-85cb-91a16f434d49"
                  onVerify={setToken}
                  ref={captchaRef}
                  apihost="https://cn1.hcaptcha.com"
                  endpoint="https://cn1.hcaptcha.com"
                  assethost="https://assets-cn1.hcaptcha.com"
                  imghost="https://imgs-cn1.hcaptcha.com"
                  reportapi="https://reportapi-cn1.hcaptcha.com"
                />
                {errors.hcpactha && (
                    <span class="errors">{errors.message.hcpactha}</span>
                )}
                    <br></br>
                    <button
                      type="submit"
                      name="submit"
                      id="submit"
                      class="btn btn-default hovereffect vgm-btn-submit mr-1"
                      ref={buttonRef}
                    >
                     {vgm_form_lang[lang].Submit} 
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="container" style={{ minHeight: `200px` }}>
          <div class="row">
            <div
              class="col-sm-12 col-md-3 col-lg-3 col-xs-12 siv_rightside_box"
              style={{ display: `none` }}
            >
              <a target="_new" href="vgm_mass_upload.php">
                <div class="col-sm-12 col-xs-12 col-md-12 col-lg-12 siv_main_box p-0 ">
                  <div class="col-sm-12 col-xs-12 col-md-12 col-lg-12 siv_img_bg1">
                    <h3>{vgm_form_lang[lang].VGMMASSUPLOAD} </h3>
                  </div>
                </div>
              </a>
              <a href="https://www.shipcont.com/VGMForm.aspx" target="_new">
                <div class="col-sm-12 col-xs-12 col-md-12 col-lg-12 siv_main_box p-0 ">
                  <div class="col-sm-12 col-xs-12 col-md-12 col-lg-12 siv_img_bg">
                    <h3>{vgm_form_lang[lang].SOLASVGM}</h3>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <GET_IN_TOUCH />
    </Layout>
  );
};
export default Vgm_form;
