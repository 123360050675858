const homelang =
{
    "en": {
        "DryCargo": "Dry Cargo",
        "Aspioneersofcontainer": "As pioneers of container shipping on the high seas, we've handled every type of cargo you can imagine and have been honing our skills for over 60 years. With this extensive experience, we are able to offer expert guidance through every step of the shipping process. Whether you're shipping ceramic kittens or football jerseys, paper towels or big-screen TVs, our shipshape containers are always ready to accommodate your needs.",
        "Everyshipmentdeservespersonal": "Every shipment deserves personal care and truly top-notch service. As an independent shipping line, GSL has the flexibility to cater to your unique requirements, whatever they may be. We have the expertise to ensure that everything from electrical appliances to commodities and any other dry cargo receives first-class treatment. Our personal, exclusive service provides optimal results and peace-of-mind, thanks to hands-on attention for each and every consignment.",
        "Fromtheinitialbooking": "From the initial booking to the final destination, GSL combines cutting-edge technology with a human touch to make sure that all cargo arrives on time and in perfect condition. Taking advantage of one-on-one support and user-friendly online systems, you can track your cargo with the touch of a button – or just give us a call!",
 
    },
    "cn": {
        "DryCargo": "一般貨物",
        "Aspioneersofcontainer": "作爲貨櫃航運界的先驅, 我們擁有超過60年的貨運經驗. 憑籍這些經驗, 必能爲您在貨物運輸每個環節上提供專業指引. 不論是瓷器裝飾或衣物服飾, 餐巾紙又或大電視, 我們的貨櫃隨時為您準備使用.",
        "Everyshipmentdeservespersonal": "每件貨物都值得悉心處理. 作爲一家獨立船公司,金星航運能爲您提供最合適服務. 我們的專業團隊能確保您的貨物得到優質處理. 還有我們的個人化服務, 對每件貨物運送時都加倍關注, 令客戶安心.",
        "Fromtheinitialbooking": "由訂艙開始到目的地, 金星航運結合先進技術及個人化服務,確保貨物準時抵達及維持最佳狀態. 加上個人支援配合簡易線上系統, 透過電話或系統就能追蹤您的貨物",
       
     
        
    },
    "zh": {
        "DryCargo": "一般货物",
        "Aspioneersofcontainer": "作为集装箱航运界的先驱, 我们拥有超过60年的货运经验.凭籍这些经验, 必能为您在货物运输每个环节上提供专业指引. 无论是瓷器装饰或衣物服饰, 餐巾纸又或大电视, 我们的货箱随时为您准备使用",
        "Everyshipmentdeservespersonal": "每件货物都值得悉心处理.作为一家独立船公司, 金星航运能为您提供最合适服务. 我们的专业团队能确保您的货物得到优质处理. 还有我们的个人化服务, 对每件货物运送时都加倍关注, 令客户安心",
        "Fromtheinitialbooking": "由订舱开始到目的地,金星航运结合先进技术及个人化服务, 确保货物准时抵达及维持最佳状态. 加上个人支援配合简易线上系统, 透过电话或系统就能追踪您的货物",
 
    },
    "ko": {
        "DryCargo": "Dry Cargo",
        "Aspioneersofcontainer": "공해상 컨테이너 운송의 선구자로서, 우리는 여러분이 상상할 수 있는 모든 종류의 화물을 취급해 왔고 60년 이상 우리의 기술을 연마해 왔습니다. 이러한 광범위한 경험을 통해 당사는 배송 과정의 모든 단계를 통해 전문가의 안내를 제공할 수 있습니다. 도자기, 아기 고양이, 축구 유니폼, 종이 타월, 대형 스크린 TV를 배송하실 때, 저희 배 모양의 컨테이너는 항상 여러분의 요구를 수용할 준비가 되어 있습니다.",
        "Everyshipmentdeservespersonal": "모든 화물은 개인적인 보살핌과 진정한 최고의 서비스를 받을 자격이 있습니다. GSL은 독립적인 운송 업체로서 어떤 것이든 고객의 고유한 요구 사항을 충족할 수 있는 유연성을 갖추고 있습니다. 우리는 전기 제품부터 상품 및 기타 모든 건조 화물에 이르기까지 모든 것이 일급 대우를 받을 수 있도록 보장하는 전문 지식을 보유하고 있습니다. 우리의 개인적이고 독점적인 서비스는 모든 위탁에 대한 실무적인 관심 덕분에 최적의 결과와 마음의 평화를 제공합니다.",
        "Fromtheinitialbooking": "GSL은 최초 부킹부터 최종 목적지까지 모든 화물이 제시간에 완벽한 상태로 도착하도록 하기 위해 인간미와 최첨단 기술을 갖추었습니다. 일대일 지원과 사용자 친화적인 온라인 시스템을 이용하여 버튼 터치 한 번으로 화물을 추적할 수 있습니다. 또는 전화 한 통이면 됩니다!",
 
    },
    "vi": {
        "DryCargo": "Hàng khô",
        "Aspioneersofcontainer": "Là những người tiên phong trong lĩnh vực vận chuyển đường biển với sự dày dặn kinh nghiệm được tích lũy trong hơn 60 năm qua, chúng tôi đã xử lý mọi loại hàng hóa mà bạn có thể tưởng tượng. Với kinh nghiệm dày dặn, chúng tôi có thể cung cấp hướng dẫn chuyên môn cho từng bước của quy trình vận chuyển. Cho dù bạn đang vận chuyển mèo con bằng gốm hay áo bóng đá, khăn giấy hay TV màn hình lớn, sự đa dạng các loại container của chúng tôi luôn sẵn sàng đáp ứng nhu cầu của bạn.",
        "Everyshipmentdeservespersonal": "Mỗi chuyến hàng đều xứng đáng được chăm sóc với dịch vụ hàng đầu. Là một hãng vận chuyển độc lập, GSL có khả năng linh hoạt để đáp ứng các yêu cầu riêng của bạn, bất kể đó là gì. Chúng tôi có chuyên môn để đảm bảo rằng mọi thứ từ thiết bị điện đến hàng hóa và bất kỳ hàng hóa khô nào khác đều được xử lý một cách tốt nhất. Dịch vụ cá nhân hóa, độc quyền của chúng tôi mang lại kết quả tối ưu và sự an tâm, nhờ sự quan tâm đối với từng lô hàng.",
        "Fromtheinitialbooking": "Từ đặt chỗ ban đầu đến điểm đến cuối cùng, GSL kết hợp công nghệ tiên tiến với nhân viên hỗ trợ tận tâm để đảm bảo rằng tất cả hàng hóa đến đúng giờ và trong tình trạng hoàn hảo. Tận dụng lợi thế của hỗ trợ 1-1 và hệ thống trực tuyến thân thiện với người dùng, bạn có thể theo dõi hàng hóa của mình chỉ bằng một nút bấm - hoặc chỉ cần gọi cho chúng tôi!",
 
    },
    "th": {
        "DryCargo": "สินค้าทั่วไป",
        "Aspioneersofcontainer": "ในฐานะผู้บุกเบิกการขนส่งตู้คอนเทนเนอร์ทางทะเล เราได้จัดการขนส่งสินค้าทุกประเภทที่ด้วยทักษะประสบการณ์มากว่า 60 ปี เราสามารถให้คำแนะนำในทุกขั้นตอนของกระบวนการจัดส่ง ไม่ว่าคุณจะจัดส่ง ตุ๊กตาเซรามิกหรือเสื้อฟุตบอล กระดาษเช็ดมือ หรือทีวีจอใหญ่เราก็พร้อมเสมอที่จะตอบสนองความต้องการของคุณ",
        "Everyshipmentdeservespersonal": "ทุกการจัดส่งสมควรได้รับการดูแลส่วนบุคคลและบริการชั้นยอดอย่างแท้จริง ในฐานะสายการเดินเรือ GSL มีความยืดหยุ่นในการตอบสนองความต้องการเฉพาะของคุณ รามีความเชี่ยวชาญเพื่อให้แน่ใจว่าทุกอย่างตั้งแต่เครื่องใช้ไฟฟ้าไปจนถึงสินค้าโภคภัณฑ์และสินค้าแห้งอื่น ๆ จะได้รับการรักษาระดับเฟิร์สคลาส บริการที่เป็นส่วนตัวและเป็นเอกสิทธิ์ของเราให้ผลลัพธ์ที่ดีที่สุดและความอุ่นใจ ",
        "Fromtheinitialbooking": "ตั้งแต่การจองไปจนถึงจุดหมายปลายทางสุดท้าย GSL ผสมผสานเทคโนโลยีล้ำสมัยเพื่อให้แน่ใจว่าสินค้าทั้งหมดจะมาถึงตรงเวลาและอยู่ในสภาพที่สมบูรณ์ จากการสนับสนุนของพนักงานและระบบออนไลน์ที่ใช้งานง่าย คุณสามารถติดตามสินค้าของคุณด้วยการกดปุ่ม - หรือเพียงแค่โทรหาเรา!",
 
    },
    "in": {
        "DryCargo": "Dry Cargo",
        "Aspioneersofcontainer": "Sebagai pelopor pengiriman peti kemas di laut lepas, kami telah menangani setiap jenis kargo yang dapat Anda bayangkan dan telah mengasah keterampilan kami selama lebih dari 60 tahun. Dengan pengalaman yang luas ini, kami dapat menawarkan panduan ahli melalui setiap langkah proses pengiriman. Baik Anda mengirimkan anak kucing keramik atau kaus sepak bola, handuk kertas, atau TV layar lebar, kontainer kapal kami selalu siap untuk mengakomodasi kebutuhan Anda.",
        "Everyshipmentdeservespersonal": "Setiap pengiriman layak mendapatkan layanan pribadi dan layanan yang benar-benar terbaik. Sebagai jalur pelayaran independen, GSL memiliki fleksibilitas untuk memenuhi kebutuhan unik Anda, apa pun itu. Kami memiliki keahlian untuk memastikan bahwa segala sesuatu mulai dari peralatan listrik hingga komoditas dan kargo kering lainnya menerima layanan kelas satu. Layanan pribadi dan eksklusif kami memberikan hasil yang optimal dan ketenangan pikiran, berkat perhatian langsung untuk setiap pengiriman.",
        "Fromtheinitialbooking": "Dari pemesanan awal hingga tujuan akhir, GSL memadukan teknologi mutakhir dengan sentuhan manusia untuk memastikan semua kargo tiba tepat waktu dan dalam kondisi sempurna. Memanfaatkan dukungan satu-satu dan sistem online yang mudah digunakan, Anda dapat melacak kargo Anda dengan satu sentuhan tombol – atau cukup hubungi kami!",
 
    },
    "ja": {
        "DryCargo": "ドライ貨物",
        "Aspioneersofcontainer": "当社は洋上コンテナ輸送の先駆者として、60年以上にわたってあらゆる貨物を取り扱い、その技術を磨いてきました。この豊富な経験をもとに、船積みプロセスの各ステップにおいて専門的なガイダンスを提供することができます。陶器の子猫やサッカーのジャージ、ペーパータオルや大型テレビなど、お客様のニーズに合わせて、常に出荷可能なコンテナをご用意しています。",
        "Everyshipmentdeservespersonal": "すべての貨物は、個別の配慮と最高級のサービスを受けるに値します。GSLは独立系海運会社として、お客様独自のご要望に柔軟に対応します。電化製品から日用品まで、あらゆるドライ貨物に一流のサービスを提供するノウハウを持っています。私たちのサービスはお預かりした荷物の一つひとつに丁寧に対応し、最適な結果と安心をお届けします。",
        "Fromtheinitialbooking": "最初のご予約から最終目的地まで、GSLは最先端のテクノロジーと人の手によるサービスを融合させ、すべての貨物が時間通りに完璧な状態で到着することをお約束します。マンツーマンのサポートと使いやすいオンラインシステムにより、ボタンを押すだけ、または当社にお電話をいただくだけで貨物を追跡することができます。",
 
    },
}
export default homelang;