import React, { Component } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import Services from "../../../components/get_in_touch/services";
import Packages from "../../../components/get_in_touch/packages_block";
import Inner_container from "../../innerpages/inner-container";
import inside_heading from "../../../assets/inside-heading.png";
import Api from "../../../../package.json";
import abt_icn from "../../../../src/assets/abt-icn.png";
import container_cargo from "../../../../src/assets/container-cargo.png";
import Moment from "moment";
import * as axios from "axios";

const qs = require("qs");
var jwt = require("jsonwebtoken");
export default class View_Customer_advisory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      country_code: window.$country_code,
      customer_advisory_data: [],
    };

    //  alert(this.props.match.params.id);
  }
  async componentDidMount() {
    var id = this.props.match.params.id;
    var lang = window.localStorage.getItem("lang");
    const headers = {
      type: "application/json",
      Authorization: `Bearer `+window.localStorage.getItem('access_token'),
      };
     
    const response = await axios.post(
      Api.userdata.url + "/customer_advisory_data",
      qs.stringify({ id: id, 'lang': lang }, { parseArrays: false }),
      { headers: headers }
    );
    const json = await response.data;
    this.setState({ customer_advisory_data: json["message"][0] });
  }

  render() {
    return (
      <Layout>
        <Inner_container innerpage="view_customer_advisory"/>
        <section class="inner-center n-40">
          <div class="container">
            <form class="">
              <div
                class="sche-title"
                style={{ backgroundImage: `url(${inside_heading})` }}
              >
                <h3> {this.state.customer_advisory_data["title"]}</h3>
              </div>
            </form>
          </div>
        </section>
        <section class="inner-center-data">
          <div class="container">
            <div class="">
              <form class="">
                <div class="row m-0">
                  <div class="col-lg-12 col-sm-12 pot-form pl-0">
                  <p dangerouslySetInnerHTML={{ __html: this.state.customer_advisory_data['description_1'] }} ></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <Services />
        <Packages />
        <GET_IN_TOUCH />
      </Layout>
    );
  }
}
