import React, { Component } from 'react';
import { render } from 'react-dom'
import ReactDOM from 'react-dom';
import classnames from "classnames";
import Api from '../../../package.json'
import * as axios from 'axios';
import counters_back1 from '../../../src/assets/inner-counters-back.jpg';
import count_ic_1 from '../../../src/assets/count-ic-1.png';
import count_ic_2 from '../../../src/assets/count-ic-2.png';
import count_ic_3 from '../../../src/assets/count-ic-3.png';
import count_ic_4 from '../../../src/assets/count-ic-4.png';

const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class packages extends Component {

    constructor(props) {
        super(props);
      
        this.state = {

            package_detail: [],

        };


    }
    async componentDidMount() {


        const headers = {
            type: "application/json",
            Authorization: `Bearer `+window.localStorage.getItem('access_token'),
          };
        const response_package_detail = await axios.post(Api.userdata.url + 'package_detail', qs.stringify({ 'country_code': window.$country_code }, { parseArrays: false }), { headers: headers });
        const json_package_detail = await response_package_detail.data;
        this.setState({ package_detail: json_package_detail['message'] });
    }
    render() {
        return <section>
        </section>
    }
}