const homelang =
{
    "en": {
        "DryVan": "Dry Van",
        "HighCube": "High Cube",
        "Opentop": "Open top",
        "Flexitank": "Flexitank",
        "Reefer": "Reefer",
        "ReeferHighCube": "Reefer High Cube",
        "Flatrack": "Flat rack",
        "DryVan": "Dry Van",
        "GeneralUse": "General Use",
        "Suitableforcommoditiesinbundles": "Suitable for commodities in bundles, cartons, boxes, loose cargo, bulk, furniture, etc. (containers can be modified into hanger containers suitable for garments)",
        "Size": "Size",
        "WeightKgsLbs": "Weight (Kgs, Lbs)",
        "InternalDimensionsMMFtInches": "Internal Dimensions (MM,Ft,Inches)",
        "DoorOpeningsMMFtInches": "Door Openings (MM,Ft,Inches)",
        "Vol": "Vol",
        "m": "m",
        "fl": "fl",
        "notes": "notes",
        "Feet": "Feet",
        "MaxGrossWeight": "Max.Gross Weight",
        "TareWeight": "Tare Weight",
        "MaxPayload": "Max Payload",
        "Length": "Length",
        "Width": "Width",
        "Height": "Height",
        "Capacity": "Capacity",
        "Allsteelconstruction": "All steel construction",
        "Allinformationinthisguide": "All information in this guide is general data only. The accurate max.gross Weight should be according to the container CSC plate. The dimensions and weights are based on manufacture's specifications. Tolerance factor must always be taken into account by supplier",
        "OpenTop": "Open Top",
        "HighCube": "High Cube",
        "GeneralUse": "General Use",
        "Suitableforcommoditiesinbundles": "Suitable for commodities in bundles, cartons, boxes, loose cargo, bulk, furniture, etc.(containers can be modified into hanger containers suitable for garments)",
        "Suitableforallorlargecargo": "Suitable for tall or large cargo that cannot be loaded through the container doors, such as machinery, sheet glass and building materials",
        "Doorheadlockremovablesteel": "Door headlock removable steel",
        "Flexitank": "Flexitank",
        "Flexitankisahermeticsealed": "Flexitank is a hermetic sealed, collapsible and flexible bag/bladder which is stuffed in a 20’ DV Container, and rely on a 20' dry-van container walls and flooring, to contain the bulk liquid cargo bag. The flexitank’s size/volume can range between 16,000-24,000 liters",
        "InstallationofFlexitankin": "Installation of Flexitank in the container takes about 20-30 minutes, after which it is ready for filling the liquid cargo which takes another about 30-40 minutes. After filling the cargo the container with your cargo is ready to be shipped. The container can be transported by road, railway or sea. The container with your cargo can be delivered to any point worldwide. In place of destination you or your Consignee/Receivers can unload a cargo quickly and without any losses, by using just the pump. Flexitanks are disposable and can be recycled",
        "Whatcargocanbeshipped ": "What cargo can be shipped inside flexitanks?",
        "InFlexitankitispossible": "In Flexitank it is possible to transport almost any non-hazardous liquids, both for industrial use and food application, such as: food, edible and animal oils and fats, juices concentrates, juices, syrups, water, wine, food additives, pharmaceutical, oils, malt, sorbitol, industrial oils, additives, cleaning liquid, emulsions, glycerin, ink, fertilizers, latex, etc",
        "Someliquidspropertiesare": "Some liquids properties are to get thick during storage or at in low temperature. This will slow down the effective emptying of the cargo from the Flexitanks, for this purpose there are heating pads which are installed together with FLEXITANK. It is used just before start of emptying using steam or hot water inside, in order to increase temperature of a cargo.",
        "Reefer": "Reefer",
        "Suitablefortransporting": "Suitable for transporting perishable cargo such as vegetables, fruit, meat, etc. Same as reefer 40' (perishable cargo such as vegetables, fruit, meat) but higher volume capacity.",
        "AluminiumStainlessSteel": "Aluminium Aluminium & Stainless Steel",
        "Flatrack": "Flat rack",
        "Usedforcarryingoddlyshaped": "Used for carrying oddly shaped and oversize loads such as machinery, cables, drums, metal sheets and steel coils, heavy vehicles, timber and forest products, or yachts.Flat Rack containers are also known as Flats (FL)",
        "Steel": "Steel",
        "BetweenHeaders": "Between Headers",
        "DeckWidth": "Deck Width",
        "BetweenPosts": "Between Posts",
        "BetweenStanchions": "Between Stanchions",
        "Loading": "Loading", 
       
    },
    "cn": {
        "DryVan": "普通貨櫃",
        "HighCube": "高容量貨櫃",
        "Opentop": "開頂貨櫃",
        "Flexitank": "液袋",
        "Reefer": "冷凍貨櫃",
        "ReeferHighCube": "高容量冷凍貨櫃",
        "Flatrack": "平板櫃",
        "DryVan": "普通貨櫃",
        "GeneralUse": "用途",
        "Suitableforcommoditiesinbundles": "適用於捆狀, 箱型, 盒狀, 散貨, 不規則形狀及家私等貨物.(貨櫃可改裝帶有衣架用以裝載成衣製品)",
        "Size": "尺寸",
        "WeightKgsLbs": "重量 (公斤, 磅)",
        "InternalDimensionsMMFtInches": "櫃内體積 (毫米, 英尺, 英吋)",
        "DoorOpeningsMMFtInches": "櫃門開啓 (毫米, 英尺, 英吋)",
        "Vol": "容量",
        "m": "立方公尺",
        "fl": "立方英尺)",
        "notes": "要點",
        "Feet": "英尺",
        "MaxGrossWeight": "最大總重量",
        "TareWeight": "净櫃重量",
        "MaxPayload": "最大負重量",
        "Length": "長度",
        "Width": "濶度",
        "Height": "高度",
        "Capacity": "容量",
        "Allsteelconstruction": "全鋼結構",
        "Allinformationinthisguide": "在此資訊只是一般數據, 最大總重量應以貨櫃箱的CSC牌上的數據為準. 體積及重量則根據生產規格. 供應商的容許系數已考慮在内 ",
        "OpenTop": "開頂貨櫃",
        "HighCube": "高容量貨櫃",
        "GeneralUse": "用途",
        "Suitableforcommoditiesinbundles": "適用於捆狀, 箱型, 盒狀, 散貨, 不規則形狀及家私等貨物.(貨櫃可改裝帶有衣架用以裝載成衣製品)",
        "Suitableforallorlargecargo": "適用於過高或過大以致不能安放於櫃内的貨物. 如機械, 玻璃膜及建築材料.",
        "Doorheadlockremovablesteel": "可拆門鎖鋼材",
        "Flexitank": "液袋",
        "Flexitankisahermeticsealed": "液袋是一種完全防漏, 可擠壓及擴展的袋/囊安裝在 20' DV櫃内.因櫃内有墻壁及地板,可容納大型液體貨物袋. 液袋可載容量在16,000 - 24,000公升之間",
        "InstallationofFlexitankin": "安裝液袋於貨櫃只需20-30分鐘, 之後另需30-40分鐘來把液體貨物填滿, 裝滿後已可運送. 這種貨櫃可用於陸路, 鐵路或海上運輸. 爲您的貨物送達全球每一角落. 收貨者可在目的地利用泵迅速把貨物卸下而沒有任何漏失. 液袋是可棄置及循環再造 ",
        "Whatcargocanbeshipped ": "液袋可裝載甚麽貨物?",
        "InFlexitankitispossible": "液袋可裝載任何無害液體, 不論用於工業或食品製造,如食物, 可食用動物油脂, 濃縮果汁, 糖漿, 水, 酒, 食物添加劑, 藥物, 食用油, 麥芽漿, 山梨糖醇, 工業用油, 添加劑, 清潔劑, 乳化劑, 甘油, 油墨, 肥田料, 乳膠等.",
        "Someliquidspropertiesare": "有些液體在儲存或低溫時呈現粘液狀態, 這情況會導致清空液袋時需較長時間. 爲此, 須要把暖包和液袋一起安裝. 在清空液袋時, 會用熱水把暖袋加熱, 讓貨物溫度提升, 利於液袋清空.",
        "Reefer": "冷凍貨櫃",
        "Suitablefortransporting": "適用於運載保鮮貨物. 如蔬菜, 水果及肉類等. 等同 40' 冷凍貨櫃 (保鮮貨物如蔬菜, 水果及肉類等), 只是容量更大.",
        "AluminiumStainlessSteel": "鋁, 鋁及不銹鋼, 鋼",
        "Flatrack": "平板櫃",
        "Usedforcarryingoddlyshaped": "適用於運載不規則形狀或大型體積貨物. 如機械, 電纜, 桶狀, 金屬片或卷, 車輛, 木材原料和游艇等.",
        "Steel": "鋼材",
        "BetweenHeaders": "頂柱間長度",
        "DeckWidth": "底板濶度",
        "BetweenPosts": "底柱間長度",
        "BetweenStanchions": "柱間濶度",
        "Loading": "可載高度", 
     
        
    },
    "zh": {
        "DryVan": "普通货箱",
        "HighCube": "高容量货箱",
        "Opentop": "开顶货箱",
        "Flexitank": "液袋",
        "Reefer": "冷冻货箱",
        "ReeferHighCube": "高容量冷冻货箱",
        "Flatrack": "平板货箱",
        "DryVan": "普通货箱",
        "GeneralUse": "用途",
        "Suitableforcommoditiesinbundles": "适用于捆状, 箱型, 盒状, 散货, 不规则形状及家俱等货物.(货箱可改装带有衣架用以装载成衣制品)",
        "Size": "尺寸",
        "WeightKgsLbs": "重量 (公斤, 磅)",
        "InternalDimensionsMMFtInches": "箱内体积 (毫米, 英尺, 英寸)",
        "DoorOpeningsMMFtInches": "箱门开启 (毫米, 英尺, 英寸)",
        "Vol": "容量",
        "m": "立方公尺",
        "fl": "立方英尺)",
        "notes": "要点",
        "Feet": "英尺",
        "MaxGrossWeight": "最大总重量",
        "TareWeight": "净箱重量",
        "MaxPayload": "最大负重量",
        "Length": "长度",
        "Width": "阔度",
        "Height": "高度",
        "Capacity": "容量",
        "Allsteelconstruction": "全钢结构",
        "Allinformationinthisguide": "在此资讯只是一般数据,最大总重量应以货箱的CSC牌上的数据为准. 体积及重量则根据生产规格. 供应商的容许系数已考虑在内",
        "OpenTop": "开顶货箱",
        "HighCube": "高容量货箱",
        "GeneralUse": "用途",
        "Suitableforcommoditiesinbundles": "适用于捆状, 箱型, 盒状, 散货, 不规则形状及家俱等货物.(货箱可改装带有衣架用以装载成衣制品)",
        "Suitableforallorlargecargo": "适用于过高或过大以致不能安放于箱内的货物.如机械, 玻璃膜及建筑材料",
        "Doorheadlockremovablesteel": "可拆门锁钢材",
        "Flexitank": "液袋",
        "Flexitankisahermeticsealed": "液袋是一种完全防漏,可挤压及扩展的袋/囊安装在 20' DV箱内. 因箱内有墙壁及地板, 可容纳大型液体货物袋. 液袋可载容量在 16,000 - 24,000 公升之间",
        "InstallationofFlexitankin": "安装液袋于货箱只需20-30分钟, 然后另需30-40分钟来吧液体货物填满, 装满后已可运送. 这种货箱可用于陆路, 铁路或海上运输. 为您的货物送达全球每一角落. 收货者可在目的地利用泵迅速把货物卸下而没有任何漏失. 液袋是可弃置及循环再造.",
        "Whatcargocanbeshipped ": "液袋可装载什么货物?",
        "InFlexitankitispossible": "液袋可装载任何无害液体, 无论用于工业或食品制造, 如食物, 可食用动物油脂, 浓缩果汁, 糖浆, 水, 酒, 食物添加剂, 药物, 食用油, 麦芽浆, 山梨糖醇, 工业用油, 添加剂, 清洁剂, 乳化剂, 甘油, 油墨, 肥田料, 乳胶等",
        "Someliquidspropertiesare": "有些液体在储存或低温时呈现粘液状态, 这情况会导致清空液袋需时较长. 为此, 须把暖包和液袋一起安装. 在清空液袋时, 会用热水把暖袋加热, 让货物温度提升, 利于液袋清空",
        "Reefer": "冷冻货箱",
        "Suitablefortransporting": "适用于运载保鲜货物. 如蔬菜, 水果及肉类等. 等同 40' 冷冻货箱 (保鲜货物如蔬菜, 水果及肉类等), 只是容量更大",
        "AluminiumStainlessSteel": "铝, 铝及不锈钢, 钢",
        "Flatrack": "平板货箱",
        "Usedforcarryingoddlyshaped": "适用于运载不规则形状或大型体积货物. 如机械, 电缆, 桶装, 金属片或卷, 车辆, 木材原料和游艇等",
        "Steel": "钢材",
        "BetweenHeaders": "顶柱间长度",
        "DeckWidth": "底板阔度",
        "BetweenPosts": "底柱间长度",
        "BetweenStanchions": "柱间阔度",
        "Loading": "可载高度", 
     
        
    },
    "ko": {
        "DryVan": "Dry Van",
        "HighCube": "High Cube",
        "Opentop": "Open top",
        "Flexitank": "Flexitank",
        "Reefer": "Reefer",
        "ReeferHighCube": "Reefer High Cube",
        "Flatrack": "Flat rack",
        "DryVan": "Dry Van",
        "GeneralUse": "일반용도",
        "Suitableforcommoditiesinbundles": "꾸러미, 상자, 상자, 느슨한 화물, 대부분, 가구 등(용기 격납고 용기로 옷에 적합한 수정할 수 있)에 상품에 적합합니다.",
        "Size": "크기",
        "WeightKgsLbs": "무게 (Kgs, Lbs)",
        "InternalDimensionsMMFtInches": "내부 치수 (MM,Ft,Inches)",
        "DoorOpeningsMMFtInches": "문 열림 (MM,Ft,Inches)",
        "Vol": "부피",
        "m": "m",
        "fl": "fl)",
        "notes": "비고",
        "Feet": "피트",
        "MaxGrossWeight": "최대. 총 중량",
        "TareWeight": "컨테이너 무게",
        "MaxPayload": "최대 적재량",
        "Length": "길이",
        "Width": "폭",
        "Height": "높이",
        "Capacity": "용량",
        "Allsteelconstruction": "모든 강철 구조물",
        "Allinformationinthisguide": "이 안내서의 모든 정보는 일반 데이터입니다. 정확한 최대 총 중량은 용기 CSC plate에 따라야 합니다. 치수와 중량은 제조사 사양을 기준으로 합니다. 공급업체는 항상 공차 계수를 고려해야 합니다.",
        "OpenTop": "Open top",
        "HighCube": "High Cube",
        "GeneralUse": "일반 사용 :",
        "Suitableforcommoditiesinbundles": "묶음, 상자, 상자, 느슨한 화물, 벌크, 가구 등의 물품에 적합합니다(용기는 의류에 적합한 행거 용기로 개조할 수 있습니다).",
        "Suitableforallorlargecargo": "기계, 판유리 및 건축 자재와 같이 컨테이너 문을 통해 적재할 수 없는 대형 또는 대형 화물에 적합합니다.",
        "Doorheadlockremovablesteel": "문 잠금 장치 제거 강철",
        "Flexitank": "Flexitank",
        "Flexitankisahermeticsealed": "Flexitank는 밀봉되고 접을 수 있으며 유연한 백/블라드로, 20' DV 컨테이너에 채워지며, 20' dry-van 컨테이너 벽과 flooring에 의존하여 대량 액체cargo bag을 수용합니다. 플렉시트 탱크의 크기/부피 범위는 16,000-24,000리터입니다.",
        "InstallationofFlexitankin": "컨테이너에 Flexitank를 설치하는 데 약 20-30분이 소요되며, 그 후 액체 화물을 채울 준비가 되며 이는 약 30-40분이 소요됩니다. 화물을 채우고 나면 당신의 화물이 담긴 컨테이너가 선적될 준비가 되어 있습니다. 컨테이너는 도로, 철도 또는 해상 운송이 가능합니다. 당신의 화물이 실린 컨테이너는 전 세계 어느 지점에도 배달될 수 있습니다. 목적지 대신 귀하 또는 귀하의 수취인/수취인은 펌프만 사용하여 손실 없이 신속하게 화물을 내릴 수 있습니다. Flexitank는 일회용이며 재활용할 수 있습니다.",
        "Whatcargocanbeshipped ": "Flexitank 내에서 선적할 수 있는 화물은 무엇입니까?",
        "InFlexitankitispossible": "Flexitank에서는 식품, 식용 및 동물성 유지, 주스 농축액, 주스, 시럽, 물, 와인, 식품 첨가물, 제약, 오일, 맥아, 소르비톨, 산업용 오일, 첨가물, 세척액, 유화액, 글리세린, 잉크, 비료와 같은 거의 모든 비위험한 액체를 운송할 수 있습니다.아이저, 라텍스 등입니다.",
        "Someliquidspropertiesare": "일부 액체 특성은 보관 중 또는 저온에서 두꺼워집니다. 이렇게 하면 Flexitank에서 화물을 효과적으로 비우는 속도가 느려집니다. 이 목적을 위해 Flexitank와 함께 장착되는 난방 패드가 있습니다. 화물의 온도를 높이기 위해 내부의 증기나 뜨거운 물을 사용하여 비우기 직전에 사용합니다.",
        "Reefer": "Reefer",
        "Suitablefortransporting": "채소, 과일, 고기 등 부패하기 쉬운 화물을 운송하기에 적합합니다.  reefer 40'(채소, 과일, 고기 등 부패하기 쉬운 화물)과 동일하지만 부피 용량이 더 큽니다.",
        "AluminiumStainlessSteel": "Aluminium Aluminium & Stainless Steel",
        "Flatrack": "Flat rack",
        "Usedforcarryingoddlyshaped": "기계, 케이블, 드럼, 금속판 및 강철 코일, 무거운 차량, 목재 및 임산물 또는 요트와 같은 이상하고 큰 크기의 하중을 운반하는 데 사용됩니다..Flat Rack container는 Flats (FL)이라고도 합니다.",
        "Steel": "강철",
        "BetweenHeaders": "양 머리 사이",
        "DeckWidth": "데크 폭",
        "BetweenPosts": "기둥 사이",
        "BetweenStanchions": "지주 관 사이",
        "Loading": "선적", 
     
        
    },
    "vi": {
        "DryVan": "Container khô",
        "HighCube": "Container cao",
        "Opentop": "Cont Open Top",
        "Flexitank": "Flexitank",
        "Reefer": "Container lạnh",
        "ReeferHighCube": "Container lạnh cao",
        "Flatrack": "Cont Flat Rack",
        "DryVan": "Container khô",
        "GeneralUse": "Sử dụng chung:",
        "Suitableforcommoditiesinbundles": "Thích hợp cho các loại hàng hóa dạng bó, thùng carton, hộp, hàng hóa lỏng, hàng rời, đồ nội thất, ... (container có thể biến đổi thành container có móc treo phù hợp với hàng may mặc).",
        "Size": "Kích cỡ",
        "WeightKgsLbs": "Trọng lượng (Kgs, Lbs)",
        "InternalDimensionsMMFtInches": "Kích thước bên trong (MM, Ft, Inches)",
        "DoorOpeningsMMFtInches": "문 열림 (MM,Ft,Inches)",
        "Vol": "Thể tích",
        "m": "m",
        "fl": "fl)",
        "notes": "Ghi chú",
        "Feet": "Feet",
        "MaxGrossWeight": "Tổng trọng lượng tối đa",
        "TareWeight": "Trọng lượng vỏ container",
        "MaxPayload": "Troọng lượng đóng hàng tối đa",
        "Length": "Chiều dài",
        "Width": "Chiều rộng",
        "Height": "Chiều cao",
        "Capacity": "Dung tích",
        "Allsteelconstruction": "Container được tạo từ chất liệu thép",
        "Allinformationinthisguide": "Tất cả thông tin trong hướng dẫn này chỉ là dữ liệu chung. Trọng lượng tổng tối đa chính xác phải theo CSC của container. Kích thước và trọng lượng dựa trên thông số kỹ thuật của nhà sản xuất. Yếu tố dung sai luôn phải được nhà cung cấp tính đến",
        "OpenTop": "Cont Open Top",
        "HighCube": "Cont cao",
        "GeneralUse": "Sử dụng chung: :",
        "Suitableforcommoditiesinbundles": "Thích hợp cho các loại hàng hóa dạng bó, thùng carton, hộp, hàng hóa lỏng, hàng rời, đồ nội thất, ... (container có thể biến đổi thành container có móc treo phù hợp với hàng may mặc).",
        "Suitableforallorlargecargo": "Thích hợp cho hàng hóa cao hoặc lớn không thể xếp đi qua cửa container, chẳng hạn như máy móc, kính tấm và vật liệu xây dựng.",
        "Doorheadlockremovablesteel": "Khóa cửa bằng thép có thể tháo rời",
        "Flexitank": "Túi Flexitank",
        "Flexitankisahermeticsealed": "Flexitank là một túi kín, có thể thu gọn và linh hoạt, được đặt trong Container 20 'DV, dựa vào vách và sàn của container khô 20', để chứa loại hàng lỏng số lượng lớn. Kích cỡ / thể tích của flexitank có thể dao động trong khoảng 16.000-24.000 lít.",
        "InstallationofFlexitankin": "Việc lắp đặt Flexitank vào container mất khoảng 20-30 phút, sau đó sẽ mất khoảng 30-40 phút để bơm chất lỏng vào. Sau khi bơm đầy, hàng hóa của bạn đã sẵn sàng để được vận chuyển. Container có thể được vận chuyển bằng đường bộ, đường sắt hoặc đường biển. Container với hàng hóa của bạn có thể được chuyển đến bất kỳ điểm nào trên toàn thế giới. Tại nơi đến, bạn hoặc Người nhận hàng / Người nhận của bạn có thể dỡ hàng một cách nhanh chóng và không bị tổn thất gì, chỉ bằng cách sử dụng máy bơm. Flexitanks dùng một lần và có thể tái chế..",
        "Whatcargocanbeshipped ": "Những hàng hóa nào có thể được vận chuyển bên trong flexitanks?",
        "InFlexitankitispossible": "Trong Flexitank, có thể vận chuyển hầu hết mọi chất lỏng không nguy hiểm, cho cả sử dụng trong công nghiệp và ứng dụng thực phẩm, chẳng hạn như: thực phẩm, dầu và mỡ động vật, nước ép cô đặc, nước trái cây, xi-rô, nước, rượu, phụ gia thực phẩm, dược phẩm, dầu, mạch nha, sorbitol, dầu công nghiệp, phụ gia, chất lỏng tẩy rửa, nhũ tương, glycerin, mực in, phân bón, cao su, v.v.",
        "Someliquidspropertiesare": "Một số đặc tính của chất lỏng là trở nên đặc trong quá trình bảo quản hoặc ở nhiệt độ thấp. Điều này sẽ làm chậm quá trình làm rỗng hàng hóa hiệu quả từ Flexitanks, vì mục đích này, có các tấm đệm sưởi được lắp cùng với FLEXITANK. Nó được sử dụng ngay trước khi bắt đầu làm rỗng bằng hơi nước hoặc nước nóng bên trong, để tăng nhiệt độ của hàng hóa.",
        "Reefer": "Cont Lạnh",
        "Suitablefortransporting": "Thích hợp để vận chuyển hàng hóa dễ hư hỏng như rau, trái cây, thịt, v.v.Giống như hàng lạnh 40 '(hàng dễ hỏng như rau, trái cây, thịt) nhưng dung tích cao hơn.",
        "AluminiumStainlessSteel": "Nhôm và Thép không gỉ",
        "Flatrack": "Cont Flat Rack",
        "Usedforcarryingoddlyshaped": "Được sử dụng để chở hàng hóa có kích cỡ lớn hoặc có hình dạng quá khổ như máy móc, dây cáp, thùng phuy, tấm kim loại và cuộn thép, xe hạng nặng, gỗ và lâm sản hoặc du thuyền. Cont Flat Rack cũng được biết như Flats (FL)",
        "Steel": "Thép",
        "BetweenHeaders": "Chiều dài giữa hai đầu mặt dựng",
        "DeckWidth": "Độ rộng của sàn container",
        "BetweenPosts": "Chiều dài giữa hai cột chống",
        "BetweenStanchions": "Chiều rộng giữa hai cột",
        "Loading": "Chiều cao tối đa để xác định hàng quá khổ", 
     
        
    },
    "th": {
        "DryVan": "Dry Van",
        "HighCube": "High Cube",
        "Opentop": "Open top",
        "Flexitank": "Flexitank",
        "Reefer": "Reefer",
        "ReeferHighCube": "Reefer High Cube",
        "Flatrack": "Flat rack",
        "DryVan": "Dry Van",
        "GeneralUse": "การใช้งานทั่วไป",
        "Suitableforcommoditiesinbundles": "เหมาะสำหรับสินค้าที่เป็นมัด, กล่อง, สินค้าเทกอง, เฟอร์นิเจอร์, ฯลฯ. (ภาชนะสามารถปรับเปลี่ยนเป็นไม้แขวนเหมาะสำหรับเสื้อผ้า)",
        "Size": "ขนาด",
        "WeightKgsLbs": "น้ำหนัก (กิโลกรัม, ปอนด์)",
        "InternalDimensionsMMFtInches": "ขนาดภายใน (มม., ฟุต, นิ้ว)",
        "DoorOpeningsMMFtInches": "ที่เปิดประตู (มม., ฟุต, นิ้ว)",
        "Vol": "Vol",
        "m": "m",
        "fl": "fl)",
        "notes": "notes",
        "Feet": "Feet",
        "MaxGrossWeight": "น้ำหนักรวมสูงสุด",
        "TareWeight": "น้ำหนักตู้สินค้า",
        "MaxPayload": "น้ำหนักบรรทุกสูงสุด",
        "Length": "ความยาว",
        "Width": "ความกว้าง",
        "Height": "ความสูง",
        "Capacity": "ความจุ",
        "Allsteelconstruction": "โครงสร้างเหล็กทั้งหมด",
        "Allinformationinthisguide": "ข้อมูลทั้งหมดในคู่มือนี้เป็นข้อมูลทั่วไปเท่านั้น น้ำหนักรวมสูงสุดที่ถูกต้องควรเป็นไปตามแผ่น CSC ของคอนเทนเนอร์ ขนาดและน้ำหนักขึ้นอยู่กับข้อกำหนดของผู้ผลิต ",
        "OpenTop": "Open Top",
        "HighCube": "High Cube",
        "GeneralUse": "การใช้งานทั่วไป",
        "Suitableforcommoditiesinbundles": "เหมาะสำหรับสินค้าที่เป็นมัด, กล่อง, สินค้าเทกอง, เฟอร์นิเจอร์, ฯลฯ. (ภาชนะสามารถปรับเปลี่ยนเป็นไม้แขวนเหมาะสำหรับเสื้อผ้า)",
        "Suitableforallorlargecargo": "เหมาะสำหรับสินค้าที่เป็นมัด, กล่อง, สินค้าเทกอง, เฟอร์นิเจอร์, ฯลฯ. (ภาชนะสามารถปรับเปลี่ยนเป็นไม้แขวนเหมาะสำหรับเสื้อผ้า)",
        "Doorheadlockremovablesteel": "ตัวล็อคประตู เหล็กถอดได้",
        "Flexitank": "Flexitank",
        "Flexitankisahermeticsealed": "Flexitank เป็นถุงที่ปิดสนิท พับได้ และยืดหยุ่นได้ ซึ่งบรรจุในคอนเทนเนอร์ DV ขนาด 20 นิ้ว และใช้ผนังและพื้นตู้คอนเทนเนอร์แบบตู้แห้งขนาด 20 นิ้ว เพื่อบรรจุถุงบรรจุของเหลวจำนวนมาก ขนาด/ปริมาตรของ flexitank สามารถอยู่ในช่วงระหว่าง 16,000-24,000 ลิตร",
        "InstallationofFlexitankin": "การติดตั้ง Flexitank ในคอนเทนเนอร์ใช้เวลาประมาณ 20-30 นาที หลังจากนั้นก็พร้อมสำหรับการเติมสินค้าเหลวซึ่งใช้เวลาประมาณ 30-40 นาที หลังจากบรรจุสินค้าแล้ว ตู้คอนเทนเนอร์พร้อมสินค้าของคุณจะพร้อมสำหรับการขนส่ง คอนเทนเนอร์สามารถขนส่งทางถนน ทางรถไฟ หรือทางทะเล ตู้คอนเทนเนอร์พร้อมสินค้าของคุณสามารถจัดส่งไปยังจุดใดก็ได้ทั่วโลก ในสถานที่ปลายทาง คุณหรือผู้รับ/ผู้รับของคุณสามารถขนถ่ายสินค้าได้อย่างรวดเร็วและไม่สูญเสียใด Flexitanks เป็นแบบใช้แล้วทิ้งและสามารถนำกลับมาใช้ใหม่ได้",
        "Whatcargocanbeshipped ": "สินค้าใดบ้างที่สามารถจัดส่งภายใน flexitanks?",
        "InFlexitankitispossible": "Flexitank เหมาะสำหรับขนส่งของเหลวที่ไม่เป็นอันตรายได้เกือบทุกชนิด ทั้งสำหรับใช้ในอุตสาหกรรมและการประยุกต์ใช้ในอาหาร เช่น อาหาร น้ำมันและไขมันที่บริโภคได้และจากสัตว์ น้ำผลไม้เข้มข้น น้ำผลไม้ น้ำเชื่อม น้ำ ไวน์ วัตถุเจือปนอาหาร ยา น้ำมัน มอลต์ ซอร์บิทอล น้ำมันอุตสาหกรรม สารเติมแต่ง น้ำยาทำความสะอาด อิมัลชั่น กลีเซอรีน หมึก ปุ๋ย น้ำยาง ฯลฯ",
        "Someliquidspropertiesare": "คุณสมบัติของของเหลวบางชนิดมีความหนาแน่นระหว่างการเก็บรักษาหรือที่อุณหภูมิต่ำ การดำเนินการนี้จะช่วยชะลอการเทสินค้าออกจาก Flexitanks อย่างมีประสิทธิภาพ ด้วยเหตุนี้จึงมีแผ่นทำความร้อนซึ่งติดตั้งร่วมกับ FLEXITANK ใช้ก่อนเริ่มเทของเหลวทิ้งโดยใช้ไอน้ำหรือน้ำร้อนภายใน เพื่อเพิ่มอุณหภูมิของสินค้า",
        "Reefer": "Reefer",
        "Suitablefortransporting": "เหมาะสำหรับขนส่งสินค้าที่เน่าเสียง่าย เช่น ผัก ผลไม้ เนื้อสัตว์ เป็นต้น เช่นเดียวกับตู้เย็น 40' (สินค้าที่เน่าเสียง่าย เช่น ผัก ผลไม้ เนื้อ) ",
        "AluminiumStainlessSteel": "อลูมิเนียม อลูมิเนียม & สแตนเลส",
        "Flatrack": "Flat rack",
        "Usedforcarryingoddlyshaped": "ใช้สำหรับบรรทุกของที่มีรูปร่างและมีขนาดใหญ่พิเศษ เช่น เครื่องจักร สายเคเบิล ดรัม แผ่นโลหะและขดลวดเหล็ก ยานพาหนะหนัก ไม้ซุงและผลิตภัณฑ์จากป่า หรือเรือยอทช์ คอนเทนเนอร์ Flat Rack เรียกอีกอย่างว่า Flats (FL)",
        "Steel": "เหล็ก",
        "BetweenHeaders": "Between Headers",
        "DeckWidth": "Deck Width",
        "BetweenPosts": "Between Posts",
        "BetweenStanchions": "Between Stanchions",
        "Loading": "Loading", 
     
        
    },
    "in": {
        "DryVan": "Dry Van",
        "HighCube": "High Cube",
        "Opentop": "Open top",
        "Flexitank": "Flexitank",
        "Reefer": "Reefer",
        "ReeferHighCube": "Reefer High Cube",
        "Flatrack": "Flat rack",
        "DryVan": "Dry Van",
        "GeneralUse": "Penggunaan umum",
        "Suitableforcommoditiesinbundles": "Cocok untuk komoditas dalam bundel, karton, kotak, kargo longgar, curah, furnitur, dll. (kontainer dapat dimodifikasi menjadi kontainer gantungan yang cocok untuk pakaian).",
        "Size": "Ukuran",
        "WeightKgsLbs": "Berat (Kgs, Lbs)",
        "InternalDimensionsMMFtInches": "Dimensi Internal (MM, Ft, Inci)",
        "DoorOpeningsMMFtInches": "Bukaan Pintu (MM, Ft, Inci)",
        "Vol": "Volume",
        "m": "m",
        "fl": "fl",
        "notes": "catatan",
        "Feet": "Kaki",
        "MaxGrossWeight": "Maks. Berat Kotor",
        "TareWeight": "Berat Tara",
        "MaxPayload": "Muatan Maks",
        "Length": "Panjang",
        "Width": "Lebar",
        "Height": "Tinggi",
        "Capacity": "Kapasitas",
        "Allsteelconstruction": "Semua konstruksi baja",
        "Allinformationinthisguide": "Semua informasi dalam panduan ini hanyalah data umum. Berat kotor maks. yang akurat harus sesuai dengan pelat CSC kontainer. Dimensi dan bobot didasarkan pada spesifikasi pabrikan. Faktor toleransi harus selalu diperhatikan oleh pemasok",
        "OpenTop": "Open Top",
        "HighCube": "High Cube",
        "GeneralUse": "Penggunaan umum",
        "Suitableforcommoditiesinbundles": "Cocok untuk komoditas dalam bundel, karton, kotak, kargo longgar, curah, furnitur, dll. (kontainer dapat dimodifikasi menjadi kontainer gantungan yang cocok untuk pakaian).",
        "Suitableforallorlargecargo": "Cocok untuk kargo tinggi atau besar yang tidak dapat dimuat melalui pintu peti kemas, seperti mesin, kaca lembaran, dan bahan bangunan.",
        "Doorheadlockremovablesteel": "Pintu headlock baja lepasan",
        "Flexitank": "Flexitank",
        "Flexitankisahermeticsealed": "Flexitank adalah tas/kantong yang disegel kedap udara, dapat dilipat dan fleksibel yang dimasukkan ke dalam Kontainer 20' DV, dan mengandalkan dinding dan lantai kontainer dry-van 20', untuk menampung kantong kargo cair curah. Ukuran/volume flexitank dapat berkisar antara 16.000-24.000 liter.",
        "InstallationofFlexitankin": "Pemasangan Flexitank dalam kontainer membutuhkan waktu sekitar 20-30 menit, setelah itu siap untuk mengisi kargo cair yang membutuhkan waktu sekitar 30-40 menit lagi. Setelah mengisi kargo, kontainer dengan kargo Anda siap dikirim. Kontainer dapat diangkut melalui jalan darat, kereta api atau laut. Kontainer dengan kargo Anda dapat dikirim ke titik mana pun di seluruh dunia. Di tempat tujuan Anda atau Consignee/Receiver Anda dapat membongkar muatan dengan cepat dan tanpa kerugian, hanya dengan menggunakan pompa. Flexitanks dapat dibuang dan dapat didaur ulang.",
        "Whatcargocanbeshipped ": "Kargo apa yang bisa dikirim di dalam flexitank?",
        "InFlexitankitispossible": "Di Flexitank dimungkinkan untuk mengangkut hampir semua cairan tidak berbahaya, baik untuk keperluan industri maupun aplikasi makanan, seperti: makanan, minyak dan lemak hewani dan yang dapat dimakan, konsentrat jus, jus, sirup, air, anggur, bahan tambahan makanan, farmasi, minyak, malt, sorbitol, minyak industri, aditif, cairan pembersih, emulsi, gliserin, tinta, pupuk, lateks, dll.",
        "Someliquidspropertiesare": "Beberapa sifat cairan menjadi kental selama penyimpanan atau pada suhu rendah. Ini akan memperlambat pengosongan kargo yang efektif dari Flexitanks, untuk tujuan ini ada bantalan pemanas yang dipasang bersama dengan FLEXITANK. Ini digunakan sesaat sebelum memulai pengosongan menggunakan uap atau air panas di dalamnya, untuk meningkatkan suhu muatan.",
        "Reefer": "Reefer",
        "Suitablefortransporting": "Cocok untuk mengangkut kargo yang mudah rusak seperti sayuran, buah, daging, dll. Sama seperti reefer 40' (kargo yang mudah rusak seperti sayuran, buah, daging) tetapi kapasitas volume lebih tinggi.",
        "AluminiumStainlessSteel": "Aluminium Aluminium & Stainless Steel",
        "Flatrack": "Flat rack",
        "Usedforcarryingoddlyshaped": "Digunakan untuk membawa beban berbentuk aneh dan besar seperti mesin, kabel, drum, lembaran logam dan gulungan baja, kendaraan berat, produk kayu dan hutan, atau yacht. Kontainer Rak Datar juga dikenal sebagai Rumah Susun (FL).",
        "Steel": "Baja",
        "BetweenHeaders": "Antara Header",
        "DeckWidth": "Lebar Dek",
        "BetweenPosts": "Antara Pos",
        "BetweenStanchions": "Antara Kandang",
        "Loading": "Memuat", 
       
    },
    "ja": {
        "DryVan": "レギュラーコンテナ",
        "HighCube": "大容量コンテナ",
        "Opentop": "オープントップコンテナ",
        "Flexitank": "フレキシタンク",
        "Reefer": "冷凍コンテナ",
        "ReeferHighCube": "大容量冷凍コンテナ",
        "Flatrack": "フラットコンテナ",
        "DryVan": "レギュラーコンテナ",
        "GeneralUse": "用途",
        "Suitableforcommoditiesinbundles": "束ねられた商品、カートン、箱、ばら荷、大荷物、家具などに適しています（コンテナは衣料品に適したハンガーコンテナに変更可能です）。",
        "Size": "サイズ",
        "WeightKgsLbs": "重量（Kgs、Lbs）",
        "InternalDimensionsMMFtInches": "内部寸法 (MM、Ft、インチ)",
        "DoorOpeningsMMFtInches": "ドア開口部 (MM、Ft、インチ)",
        "Vol": "容積",
        "m": "m",
        "fl": "fl",
        "notes": "備考",
        "Feet": "重量",
        "MaxGrossWeight": "最大総重量",
        "TareWeight": "標識重量",
        "MaxPayload": "最大積載量",
        "Length": "長さ",
        "Width": "幅",
        "Height": "高さ",
        "Capacity": "容量",
        "Allsteelconstruction": "全スチール製",
        "Allinformationinthisguide": "このガイドに記載されている情報はすべて一般的なデータです。正確な最大総重量は、コンテナCSCプレートに記載されています。寸法と重量は、製造業者の仕様に基づくものです。許容誤差は、常にサプライヤーによって考慮されなければなりません。",
        "OpenTop": "オープントップコンテナ",
        "HighCube": "大容量コンテナ",
        "GeneralUse": "一般的な使用方法",
        "Suitableforcommoditiesinbundles": "束ねられた商品、カートン、箱、ばら荷、大荷物、家具などに適しています（コンテナは衣料品に適したハンガーコンテナに変更可能です）。",
        "Suitableforallorlargecargo": "機械、板ガラス、建材など、コンテナドアから積み込むことができない背の高い貨物や大型の貨物に適しています。",
        "Doorheadlockremovablesteel": "ドアヘッドロック着脱式スチール",
        "Flexitank": "フレキシタンク",
        "Flexitankisahermeticsealed": "フレキシタンクは密閉された折りたたみ可能なフレキシブルバッグ/ブラダーで、20フィートDVコンテナに詰められ、20フィート乾燥貨物コンテナの壁と床を利用して、バルク液体貨物バッグを格納するものです。フレキシタンクのサイズ/容積は16,000～24,000リットルが可能です。",
        "InstallationofFlexitankin": "フレキシタンクのコンテナへの設置には約20-30分かかり、その後、液体貨物を充填する準備が整いますが、これにはさらに約30-40分かかります。貨物の積み込みが終わると、貨物を積んだコンテナは出荷の準備に入ります。コンテナは陸路、鉄道、海路で輸送することができます。コンテナは世界中のどの地点にも配送することができます。目的地ではお客様または受取人がポンプを使うだけで、貨物を素早く、損失なく降ろすことができます。フレキシタンクは消耗品で、リサイクルすることができます。",
        "Whatcargocanbeshipped ": "フレキシタンクではどのような貨物を輸送することができますか？",
        "InFlexitankitispossible": "フレキシタンクでは、食品、食用・動物用油脂、濃縮ジュース、ジュース、シロップ、水、ワイン、食品添加物、医薬品、オイル、モルト、ソルビトール、工業油、添加物、洗浄液、乳液、グリセリン、インク、肥料、ゴム液など、産業用・食品用問わずほぼすべての無害な液体の輸送に対応しています。",
        "Someliquidspropertiesare": "液体には、貯蔵中や低温で濃くなる性質があります。このため、フレキシタンクと一緒に設置される加熱装置があります。フレキシタンクの内部にスチームや温水を入れて、貨物の温度を上昇させ、空輸を開始する直前に使用します。",
        "Reefer": "冷凍コンテナ",
        "Suitablefortransporting": "「野菜、果物、肉などの生鮮貨物の輸送に適しています。冷凍コンテナ40'（野菜、果物、肉などの生鮮貨物）と同じですが、容積が大きくなります。",
        "AluminiumStainlessSteel": "アルミニウム アルミニウム＆ステンレススチール",
        "Flatrack": "フラットコンテナ",
        "Usedforcarryingoddlyshaped": "機械、ケーブル、ドラム、金属板、鋼鉄コイル、大型車両、木材、林産物、ヨットなど、異形で特大の荷物を運ぶために使用される。　フラットラックコンテナは、フラット（FL）とも呼ばれます。",
        "Steel": "スチール",
        "BetweenHeaders": "ヘッダー間",
        "DeckWidth": "デッキの幅",
        "BetweenPosts": "支柱間",
        "BetweenStanchions": "スタンション間",
        "Loading": "積載量", 
       
    },
}
export default homelang;