const homelang =
{
    "en": {
        "WHOWEARE": "WHO WE ARE",
       
    },
    "cn": {
        "WHOWEARE": "我們是誰",
     
        
    },
    "zh": {
        "WHOWEARE": "这就是我们",
     
        
    },
    "ko": {
        "WHOWEARE": "당사는",
     
        
    },
    "vi": {
        "WHOWEARE": "Chúng tôi là ai",
     
        
    },
    "th": {
        "WHOWEARE": "รู้จัก GSL",
     
        
    },
    "in": {
        "WHOWEARE": "SIAPA KAMI",
     
        
    },
    "ja": {
        "WHOWEARE": "会社概要",
     
        
    },
}
export default homelang;