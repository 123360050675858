const homelang =
{
    "en": {
        "TrackaShipment": "Track a Shipment",
        "TrackaShipment1": "Get latest shipment status !",
        "EnterBLNoorContainerNo": "Enter B/L No or Container No or Booking No",
        "FindContainer": "Track Shipment",
        "BLNo": "B/L No",
        "PortofReceipt": "Port of Receipt (POR)",
        "PortofLoading": "Port of Loading (POL)",
        "TerminalName": "Terminal Name",
        "SailingDate": "Sailing Date",
        "PortofDischarge": "Port of Discharge (POD)",
        "FinalDestination": "Final Destination",
        "EstimatedTimeofArrival": "Estimated Time of Arrival",
        "VesselVoyage": "Vessel / Voyage",
        "TruckRail": "Truck / Rail",
        "ETA": "ETA",
        "ETD": "ETD",
        "ContainerNo": "Container No",
        "Container": "Container",
        "LastActivity": "Last Activity",
        "Location": "Location",
        "Date": "Date",
        "VesselVoyage": "Vessel / Voyage",
        "LastActivity": "Last Activity",
        "Location": "Location",
        "Date": "Date",
        "VesselVoyage": "Vessel / Voyage",
        "NoRecordFound": "No Record Found",
        "PDF": "PDF",
        "Print": "Print",
       
       
    },
    "cn": {
        "TrackaShipment": "貨物追蹤",
        "TrackaShipment1": "最新貨物動態!",
        "EnterBLNoorContainerNo": "輸入提單號或貨櫃號或訂艙號",
        "FindContainer": "追蹤貨物",
        "BLNo": "提單號",
        "PortofReceipt": "收貨地",
        "PortofLoading": "裝貨港",
        "TerminalName": "碼頭名",
        "SailingDate": "開航日期",
        "PortofDischarge": "卸貨港",
        "FinalDestination": "最終目的港",
        "EstimatedTimeofArrival": "預計到達日期",
        "VesselVoyage": "船名 / 航次",
        "TruckRail": "貨車 / 鐵路",
        "ETA": "預計到達日期",
        "ETD": "預計出發日期",
        "ContainerNo": "貨櫃號",
        "Container": "貨櫃",
        "LastActivity": "最新動態",
        "Location": "地點",
        "Date": "日期",
        "VesselVoyage": "船名 / 航次",
        "LastActivity": "最新動態",
        "Location": "地點",
        "Date": "日期",
        "VesselVoyage": "船名 / 航次",
        "NoRecordFound": "沒有記錄",
        "PDF": "PDF",
        "Print": "列印",
    },
    "zh": {
        "TrackaShipment": "货物追踪",
        "TrackaShipment1": "最新货物动态!",
        "EnterBLNoorContainerNo": "输入提单号或集装箱号或订舱号",
        "FindContainer": "追踪货物",
        "BLNo": "提单号",
        "PortofReceipt": "收货地",
        "PortofLoading": "装货港",
        "TerminalName": "码头名",
        "SailingDate": "开航日期",
        "PortofDischarge": "卸货港",
        "FinalDestination": "最终目的港",
        "EstimatedTimeofArrival": "预计到达日期",
        "VesselVoyage": "船名 / 航次",
        "TruckRail": "货车 / 铁路",
        "ETA": "预计到达日期",
        "ETD": "预计出发日期",
        "ContainerNo": "集装箱号",
        "Container": "集装箱",
        "LastActivity": "最新动态",
        "Location": "地点",
        "Date": "日期",
        "VesselVoyage": "船名 / 航次",
        "LastActivity": "最新动态",
        "Location": "地点",
        "Date": "日期",
        "VesselVoyage": "船名 / 航次",
        "NoRecordFound": "没有记录",
        "PDF": "PDF",
        "Print": "列印",
    },
    "ko": {
        "TrackaShipment": "화물 추적",
        "TrackaShipment1": "화물의 최종 상태 받기!",
        "EnterBLNoorContainerNo": "B/L 번호 또는 컨테이너 번호 또는 예약 번호를 입력하세요",
        "FindContainer": "화물 추적",
        "BLNo": "B/L 번호",
        "PortofReceipt": "화물 수령지",
        "PortofLoading": "선적항",
        "TerminalName": "터미널 이름",
        "SailingDate": "출항 일자",
        "PortofDischarge": "도착항",
        "FinalDestination": "최종 목적지",
        "EstimatedTimeofArrival": "도착 예정 일자",
        "VesselVoyage": "Vessel / 항차",
        "TruckRail": "트럭 / 기차",
        "ETA": "도착 예정 일자",
        "ETD": "출항 예정 일자",
        "ContainerNo": "컨테이너 번호",
        "Container": "컨테이너",
        "LastActivity": "최종 상태",
        "Location": "지역",
        "Date": "일자",
        "VesselVoyage": "Vessel / 항차",
        "LastActivity": "최종 상태",
        "Location": "지역",
        "Date": "일자",
        "VesselVoyage": "Vessel / 항차",
        "NoRecordFound": "조회된 기록 없음",
        "PDF": "PDF",
        "Print": "출력",
    },
    "vi": {
        "TrackaShipment": "Theo dõi lô hàng",
        "TrackaShipment1": "Nhận thông tin mới nhất của lô hàng",
        "EnterBLNoorContainerNo": "Nhập số B/L hoặc số Container hoặc số Booking",
        "FindContainer": "Theo dõi lô hàng",
        "BLNo": "Số vận đơn (B/L)",
        "PortofReceipt": "Điểm nhận hàng (POR)",
        "PortofLoading": "Cảng xếp hàng (POL)",
        "TerminalName": "Tên cảng",
        "SailingDate": "Ngày tàu chạy ",
        "PortofDischarge": "Cảng dỡ hàng (POD)",
        "FinalDestination": "Điểm đến cuối cùng",
        "EstimatedTimeofArrival": "Thời gian dự kiến đến",
        "VesselVoyage": "Tên tàu/ Số chuyến",
        "TruckRail": "Xe tải / Xe lửa",
        "ETA": "Ngày dự kiến đến (ETA)",
        "ETD": "Ngày dự kiến rời đi (ETD)",
        "ContainerNo": "Số container",
        "Container": "Container",
        "LastActivity": "Hoạt động mới nhất",
        "Location": "Vị trí",
        "Date": "Ngày",
        "VesselVoyage": "Tên tàu/ Số chuyến",
        "LastActivity": "Hoạt động mới nhất",
        "Location": "Vị trí",
        "Date": "Ngày",
        "VesselVoyage": "Tên tàu/ Số chuyến",
        "NoRecordFound": "Không tìm thấy thông tin",
        "PDF": "Tải xuống bản PDF",
        "Print": "In ấn",
    },
    "th": {
        "TrackaShipment": "ตรวจสอบสถานะสินค้า",
        "TrackaShipment1": "รับสถานะการจัดส่งล่าสุด!",
        "EnterBLNoorContainerNo": "กรอกหมายเลข BL หรือ หมายเลขคอนเทนเนอร์ หรือ หมายเลขการจอง",
        "FindContainer": "ตรวจสอบสถานะสินค้า",
        "BLNo": "หมายเลข B/L",
        "PortofReceipt": "สถานที่รับสินค้า",
        "PortofLoading": "สถานที่ต้นทางที่ส่งออก",
        "TerminalName": "ท่าเรือ",
        "SailingDate": "วันเรือออก",
        "PortofDischarge": "สถานที่ปลายทาง",
        "FinalDestination": "สถานที่ปลายทางสุดท้าย",
        "EstimatedTimeofArrival": "ประมาณการวันเรือถึง",
        "VesselVoyage": "ชื่อเรือ / เที่ยวเรือ",
        "TruckRail": "รถบรรทุก / รถไฟ",
        "ETA": "ประมาณการวันเรือถึง",
        "ETD": "ประมาณการวันเรือออก",
        "ContainerNo": "หมายเลขตู้คอนเทนเนอร์",
        "Container": "ตู้คอนเทนเนอร์",
        "LastActivity": "สถานะตู้ล่าสุด",
        "Location": "สถานที่",
        "Date": "วันที่",
        "VesselVoyage": "ชื่อเรือ / เที่ยวเรือ",
        "LastActivity": "สถานะตู้ล่าสุด",
        "Location": "สถานที่",
        "Date": "วันที่",
        "VesselVoyage": "ชื่อเรือ / เที่ยวเรือ",
        "NoRecordFound": "ไม่พบข้อมูล",
        "PDF": "PDF",
        "Print": "พิมพ์",
    },
    "in": {
        "TrackaShipment": "Lacak Pengiriman",
        "TrackaShipment1": "Dapatkan status pengapalan terkini!",
        "EnterBLNoorContainerNo": "Masukkan No. B/L atau No. Kontainer atau No. Pemesanan",
        "FindContainer": "Lacak Pengiriman",
        "BLNo": "No.B/L",
        "PortofReceipt": "Pelabuhan terima",
        "PortofLoading": "Pelabuhan muat",
        "TerminalName": "Nama Terminal",
        "SailingDate": "Tanggal berlayar",
        "PortofDischarge": "Pelabuhan bongkar",
        "FinalDestination": "Tujuan akhir:",
        "EstimatedTimeofArrival": "Estimasi waktu tiba",
        "VesselVoyage": "Kapal / Perjalanan",
        "TruckRail": "Truk / Rel",
        "ETA": "ETA",
        "ETD": "ETD",
        "ContainerNo": "No.Kontainer",
        "Container": "Kontainer",
        "LastActivity": "Aktifitas terakhir",
        "Location": "Lokasi",
        "Date": "Tanggal",
        "VesselVoyage": "Kapal / Perjalanan",
        "LastActivity": "Aktifitas terakhir",
        "Location": "Lokasi",
        "Date": "Tanggal",
        "VesselVoyage": "Kapal / Perjalanan",
        "NoRecordFound": "Data tidak ditemukan",
        "PDF": "PDF",
        "Print": "Cetak",
       
       
    },
    "ja": {
        "TrackaShipment": "貨物を追跡",
        "TrackaShipment1": "最新の出荷状態を取得します",
        "EnterBLNoorContainerNo": "B/L番号またはコンテナ番号または予約番号を入力してください",
        "FindContainer": "貨物を追跡",
        "BLNo": "B/L番号",
        "PortofReceipt": "荷受港（POR）",
        "PortofLoading": "積地港（POL）",
        "TerminalName": "ターミナル名",
        "SailingDate": "航行日",
        "PortofDischarge": "荷揚港（POD）",
        "FinalDestination": "最終目的地：",
        "EstimatedTimeofArrival": "到着予定時刻",
        "VesselVoyage": "船/航海",
        "TruckRail": "トラック/鉄道",
        "ETA": "到着予定時刻",
        "ETD": "出発予定時刻",
        "ContainerNo": "コンテナ番号",
        "Container": "コンテナ",
        "LastActivity": "最後のアクティビティ",
        "Location": "場所",
        "Date": "日付",
        "VesselVoyage": "船/航海",
        "LastActivity": "最後のアクティビティ",
        "Location": "場所",
        "Date": "日付",
        "VesselVoyage": "船/航海",
        "NoRecordFound": "記録が見つかりません。",
        "PDF": "PDF",
        "Print": "印刷",
       
       
    },
}
export default homelang;