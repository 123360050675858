import React, { Component } from 'react';
import Layout from '../../layout';
import GET_IN_TOUCH from '../../get_in_touch/index';
import Services from '../../get_in_touch/services';
import Packages from '../../get_in_touch/packages_block';
import inside_heading from '../../../../src/assets/inside-heading.png';
import Inner_container from '../inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import company_profile_lang from '../../lang/company-profile';



const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class Company_profile extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            liner_profile: [],
            linerser: [],
            about_us_whoweare: [],
            shipfleet: [],
            orgchart: [],

        };

    }
    async componentDidMount() {
        var lang = window.localStorage.getItem("lang");
        const headers = {
            type: "application/json",
            Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
        };

        const response = await axios.post(Api.userdata.url + '/liner_profile', qs.stringify({ 'lang': lang }, { parseArrays: false }), { headers: headers });
        const json = await response.data;
        this.setState({ liner_profile: json['message'][0] });

        const linerser_response= await axios.post(
            Api.userdata.url + "get_routemap_trades",
            qs.stringify(
              { country_code: window.$country_code,lang: lang },
              { parseArrays: false }
            ),
            { headers: headers }
          );
        const linerser_json = await linerser_response.data;
        this.setState({ linerser: linerser_json['message'] });

        const about_us_whoweare_response = await axios.post(Api.userdata.url + '/about_us_whoweare', qs.stringify({ 'lang': lang }, { parseArrays: false }), { headers: headers });
        const about_us_whoweare_json = await about_us_whoweare_response.data;
        this.setState({ about_us_whoweare: about_us_whoweare_json['message'][0] });

        const shipfleet_response = await axios.post(Api.userdata.url + '/shipfleet', qs.stringify({ 'lang': lang }, { parseArrays: false }), { headers: headers });
        const shipfleet_json = await shipfleet_response.data;
        this.setState({ shipfleet: shipfleet_json['message'] });

        const orgchart_response = await axios.post(Api.userdata.url + '/orgchart', qs.stringify({ 'lang': lang }, { parseArrays: false }), { headers: headers });
        const orgchart_json = await orgchart_response.data;
        this.setState({ orgchart: orgchart_json['message'][0] });




    }
    render() {
        var lang = window.localStorage.getItem("lang");
        return (

            <Layout>
                <Inner_container innerpage="company_profile" />


                <section class="inner-center n-40">
                    <div class="container">

                        <Tabs>
                            <TabList className="nav nav-tabs trade-tab-li" >
                                <Tab className="nav-link" selectedClassName="active">
                                    <a href="javascript:void(0);" class="nav-link " >{company_profile_lang[lang].LinerProfile}</a>
                                </Tab>
                                <Tab className="nav-link " selectedClassName="active">
                                    <a href="javascript:void(0);" class="nav-link " >{company_profile_lang[lang].LinerService}</a>
                                </Tab>
                                <Tab className="nav-link " selectedClassName="active">
                                    <a href="javascript:void(0);" class="nav-link " >{company_profile_lang[lang].ShipFleet}</a>
                                </Tab>
                                <Tab className="nav-link " selectedClassName="active">
                                    <a href="javascript:void(0);" class="nav-link " >{company_profile_lang[lang].Management}</a>
                                </Tab>
                            </TabList>
                            <div class="tab-content border trade-tab-con booking-tab-cont">
                                <TabPanel className="tab-pane active">

                                    <div class="row m-0 ">
                                        <h2 class="text-center table-heading">{company_profile_lang[lang].LinerProfile} </h2>
                                        <div class="table-responsive">
                                            <table width="90%" border="0" align="center" cellpadding="0" cellspacing="1" bgcolor="#28367B" class="table table-bordered">
                                                <tbody><tr>
                                                    <td bgcolor="#FFFFFF"><table width="100%" border="0" cellspacing="1" cellpadding="4">
                                                        <tbody><tr>
                                                            <td colspan="2" class="table_content"><font ><b>{company_profile_lang[lang].GoldStarLineLtd}. </b></font></td>
                                                            <td colspan="3" class="table_content"><font ><b>{company_profile_lang[lang].WorldRanking} {this.state.liner_profile['ranking']} </b></font></td>
                                                        </tr>
                                                            <tr>
                                                                <td colspan="2" class="table_content"><b>{company_profile_lang[lang].Address}</b></td>
                                                                <td width="24%" class="table_content"><b>{company_profile_lang[lang].ShipFleet}</b></td>
                                                                <td width="24%" align="right" class="table_content"><b>{company_profile_lang[lang].Teu}</b></td>
                                                                <td width="24%" align="right" class="table_content"><b>{company_profile_lang[lang].Ships}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2" rowspan="4" valign="top" class="table_content">
                                                                    {this.state.liner_profile['address']}
                                                                </td>
                                                                <td width="24%" class="table_content">{company_profile_lang[lang].CharteredIn}</td>
                                                                <td width="24%" align="right" class="table_content">{this.state.liner_profile['shipfleetteu']} </td>
                                                                <td width="24%" align="right" class="table_content">
                                                                    {this.state.liner_profile['shipfleetships']}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="24%" class="table_content">&nbsp;</td>
                                                                <td width="24%" align="right" class="table_content">&nbsp;</td>
                                                                <td width="24%" align="right" class="table_content">&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="24%" class="table_content">{company_profile_lang[lang].Total}</td>
                                                                <td width="24%" align="right" class="table_content">{this.state.liner_profile['shipfleetteut']}</td>
                                                                <td width="24%" align="right" class="table_content">
                                                                    {this.state.liner_profile['shipfleetshipst']} </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="3" rowspan="2" class="table_content">{company_profile_lang[lang].RankingUpdated}: {this.state.liner_profile['rankingupdated']}</td>
                                                            </tr>

                                                            <tr>
                                                                <td colspan="2" class="table_content"><b>{company_profile_lang[lang].Contact}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td width="20%" class="table_content">{company_profile_lang[lang].Telephone}</td>
                                                                <td width="30%" class="table_content">{this.state.liner_profile['telephone']}</td>
                                                                <td width="24%" class="table_content"><b>{company_profile_lang[lang].ContainerFleetTEU}</b></td>
                                                                <td width="24%" align="right" class="table_content"><b>{company_profile_lang[lang].TEU}</b></td>
                                                                <td width="24%" align="right" class="table_content"><b>{company_profile_lang[lang].Units}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td class="table_content">{company_profile_lang[lang].Website}</td>
                                                                <td class="table_content"><a href="http://www.gslltd.com.hk">{this.state.liner_profile['website']}</a></td>
                                                                <td width="24%" class="table_content">{company_profile_lang[lang].Total}</td>
                                                                <td width="24%" align="right" class="table_content">{this.state.liner_profile['totalteu']}</td>
                                                                <td width="24%" align="right" class="table_content">&nbsp;</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="20%" class="table_content">&nbsp;</td>
                                                                <td width="30%" class="table_content">&nbsp;</td>
                                                                <td width="24%" class="table_content"><b>{company_profile_lang[lang].ContainersIDs}</b></td>
                                                                <td width="24%" align="right" class="table_content"><b>{this.state.liner_profile['container']}</b></td>
                                                                <td width="24%" align="right" class="table_content">&nbsp;</td>
                                                            </tr>

                                                        </tbody></table></td>
                                                </tr>
                                                </tbody></table>
                                        </div>

                                    </div>

                                </TabPanel>
                                <TabPanel>
                                    <div class="row m-0">
                                        <h2 class="text-center table-heading">{company_profile_lang[lang].LinerService} </h2>

                                        <div class="table-responsive">

                                            <table width="90%" border="0" align="center" cellpadding="0" cellspacing="1" bgcolor="#28367B" class="table table-bordered">
                                                <tbody><tr>

                                                    <td bgcolor="#FFFFFF"><table border="0" cellspacing="1" cellpadding="4" width="100%">
                                                        <tbody><tr >
                                                            <td class="table_content"><font ><b>{company_profile_lang[lang].Service}</b></font></td>
                                                            <td class="table_content"><font ><b>{company_profile_lang[lang].TradeRoute}</b></font></td>
                                                        </tr>
                                                            {this.state.linerser.map(data => (
                                                                <tr>
                                                                    <td ><p>{data['service']}</p></td>
                                                                    <td><p>{data['troute']}</p></td>
                                                                </tr>
                                                            ))}
                                                            <tr bgcolor="#ccc">
                                                                <td colspan="2" class="table_content"><font ><b>{company_profile_lang[lang].NumberofService}:
                                                                    {this.state.linerser.length} </b></font></td>
                                                            </tr>
                                                        </tbody></table></td>
                                                </tr>
                                                </tbody></table>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div class="row m-0">
                                        <h2 class="text-center table-heading">{company_profile_lang[lang].ShipFleet} </h2>
                                        <div class="table-responsive">
                                            <table width="90%" border="0" align="center" cellpadding="0" cellspacing="1" bgcolor="#28367B" class="table table-bordered">
                                                <tbody><tr>
                                                    <td bgcolor="#FFFFFF"><table width="100%" border="0" cellspacing="1" cellpadding="4">
                                                        <tbody><tr bgcolor="#FFFFFF">

                                                            <td width="27%" ><b><font >{company_profile_lang[lang].Name}</font></b></td>
                                                            <td width="6%" ><b><font >{company_profile_lang[lang].TEU}</font></b></td>
                                                            <td width="6%" ><b><font >{company_profile_lang[lang].Type}</font></b></td>
                                                            <td width="6%" ><b><font >{company_profile_lang[lang].Built}</font></b></td>
                                                            <td width="10%" ><b><font >{company_profile_lang[lang].Flag}</font></b></td>
                                                            <td width="33%" ><b><font >{company_profile_lang[lang].Owner}</font></b></td>
                                                            <td width="8%" ><font >{company_profile_lang[lang].Operator}</font></td>

                                                        </tr>

                                                            {this.state.shipfleet.map(data => (
                                                                <tr>
                                                                    <td ><p>{data['sname']}</p></td>
                                                                    <td><p>{data['teu']}</p></td>
                                                                    <td ><p>{data['type']}</p></td>
                                                                    <td><p>{data['built']}</p></td>
                                                                    <td ><p>{data['flag']}</p></td>
                                                                    <td><p>{data['owner']}</p></td>
                                                                    <td ><p>{data['operator']}</p></td>

                                                                </tr>

                                                            ))}

                                                            <tr>
                                                                <td colspan="7" align="left" bgcolor="#ccc"><i><b><font >{company_profile_lang[lang].NumberofVessels} :
                                                                    {this.state.shipfleet.length}</font></b></i></td>
                                                            </tr>
                                                        </tbody></table></td>
                                                </tr>
                                                </tbody></table>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div class="row m-0">
                                        <div class="col-md-6">
                                            <div class="org-chart-full">
                                                <h3 class="table-heading">{this.state.orgchart['title']}</h3>
                                                <ul class="orgg-chart">

                                                    <li>{this.state.orgchart['description']}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <img src={Api.userdata.siteurl + "gsl-dashboard/uploads/support_org/" + this.state.orgchart['filep']} style={{ width: `100%` }} />
                                        </div>
                                    </div>

                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </section>
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}