const homelang =
{
    "en": {
        "CustomerAdvisory": "Customer Advisory",
        "ReadMore": "Read More",
       
     
    },
    "cn": {
        "CustomerAdvisory": "客戶諮詢",
        "ReadMore": "閱讀更多",
    },
    "zh": {
        "CustomerAdvisory": "고객에 유용한 정보",
        "ReadMore": "더 알아보기",
    },
    "vi": {
        "CustomerAdvisory": "Tư vấn khách hàng",
        "ReadMore": "Đọc thêm",
    },
    "th": {
        "CustomerAdvisory": "ประกาศ",
        "ReadMore": "เพิ่มเติม",
    },
    "in": {
        "CustomerAdvisory": "Penasehat Pelanggan",
        "ReadMore": "Baca selengkapnya",
    },
    "ja": {
        "CustomerAdvisory": "お客様相談室",
        "ReadMore": "詳細",
    },
}
export default homelang;