import React, { Component } from 'react';
import services_2 from "../../../src/assets/services-2.png";
import services_1 from "../../../src/assets/services-1.png";
import services_4 from "../../../src/assets/services-4.png"
import services_3 from "../../../src/assets/services-3.png";
import equipm_ic from '../../../src/assets/equipm-ic.png';
import port_cd_ic from '../../../src/assets/port-cd-ic.png';
import rotati_ic from '../../../src/assets/rotati-ic.png';
import sailing_schedule_ic from '../../../src/assets/sailing-schedule-ic.png';
import company_news_bg from "../../../src/assets/Service-news-bg.png";
import { NavLink as Link } from "react-router-dom";
import ServiceLang from "../lang/home_service"
var jwt = require('jsonwebtoken');
const Services = () => {
  var langs = window.localStorage.getItem("lang");
  return (<section className="services">
    <div className="container-fluid sevices-box">
      <div className="container ">
      <h2 className='services-box-heading'>{ServiceLang[langs].SERVICES}</h2>
        <div className="row">
          <div className="service-cont-1 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
       
            <Link to="/schedule_by_line" className="drop-inner">
              <div className="service-column-bg">
                <div className="service-cont-img">
                  <img className="service-cont-main-img" src={services_1} />
                  {/* <div className="service-img-ic">
                    <img src={sailing_schedule_ic} />
                  </div> */}
                </div>
                <div className="service-cont-text">
                  <h6>{ServiceLang[langs].Sailingschedule}</h6>
                  <p>{ServiceLang[langs].Sailingschedule_p1}</p>
                  <button className="btn btn-default hovereffect hovereffect service-cont-text-btn">{ServiceLang[langs].KNOWMORE}</button>
                </div>
              </div>
            </Link>

          </div>
          <div className="service-cont-1 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
            <Link to="/cargo_catalogue" className="drop-inner">
              <div className="service-column-bg">
                <div className="service-cont-img">
                  <img className="service-cont-main-img" src={services_2} />
                  {/* <div className="service-img-ic">
                    <img src={equipm_ic} />
                  </div> */}
                </div>
                <div className="service-cont-text">
                  <h6>{ServiceLang[langs].Equipments}</h6>
                  <p>{ServiceLang[langs].Equipments_p1}</p>
                  <button className="btn btn-default hovereffect service-cont-text-btn">{ServiceLang[langs].KNOWMORE}</button>
                </div>
              </div>
            </Link>
          </div>
          <div className="service-cont-1 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
            <Link to="/cargo_services" className="drop-inner">
              <div className="service-column-bg">
                <div className="service-cont-img">
                  <img className="service-cont-main-img" src={services_3} />
                  {/* <div className="service-img-ic">
                    <img src={port_cd_ic} />
                  </div> */}
                </div>
                <div className="service-cont-text">
                  <h6>{ServiceLang[langs].CargoServices}</h6>
                  <p>{ServiceLang[langs].CargoServices_p1}</p>
                  <button className="btn btn-default hovereffect service-cont-text-btn">{ServiceLang[langs].KNOWMORE}</button>
                </div>
              </div>
            </Link>

          </div>
          <div className="service-cont-1 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">

            <Link to="/ecommerce-partners" className="drop-inner">
              <div className="service-column-bg">
                <div className="service-cont-img">
                  <img className="service-cont-main-img" src={services_4} />
                  {/* <div className="service-img-ic">
                    <img src={rotati_ic} />
                  </div> */}
                </div>
                <div className="service-cont-text">
                  <h6>{ServiceLang[langs].ECommercePartners}</h6>
                  <p>{ServiceLang[langs].ECommercePartners_p1}</p>
                  <button className="btn btn-default hovereffect service-cont-text-btn">{ServiceLang[langs].KNOWMORE}</button>
                </div>
              </div>
            </Link>
          </div>


        </div>
      </div>
    </div>

  </section>)
}
export default Services
