import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';

import Inner_container from '../../innerpages/inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';


import inside_heading from '../../../../src/assets/inside-heading.png';


const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class Company_history extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            time_line: [],


        };

    }
    async componentDidMount() {
        var lang = window.localStorage.getItem("lang");
        const headers = {
            type: "application/json",
            Authorization: `Bearer `+window.localStorage.getItem('access_token'),
          };

        const response = await axios.post(Api.userdata.url + '/time_line', qs.stringify({ 'lang':lang  }, { parseArrays: false }), { headers: headers });
        const json = await response.data;
        this.setState({ time_line: json['message'] });




    }
    render() {
        var i = 1;
        return (

            <Layout>
                <Inner_container innerpage ="company_history"/>


                <section class="inner-center n-40">
                    <div class="container">
                        

                        {this.state.time_line.map(data => {

                            var classd = ((i % 2 == 0) ? "times-con left-time " : "times-con right-times");
                            i++;
                            return <div class="timeline">
                                <div class={classd} >
                                    <div class="content-timel wow fadeInDown" >
                                        <h2>{data['year']}</h2>
                                        <h3>{data['title']}</h3>
                                        <p>{data['description']}</p>
                                    </div>
                                </div>


                            </div>

                        })}
                    </div>
                </section>
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}