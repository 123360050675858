const homelang =
{
    "en": {
        "SchedulebyLine": "Schedule by Line",
        "SchedulebyLine1": "Search for another route",
        "Trade": "Trade",
        "SelectTrade": "Select Trade",
        "ShippingLine": "Shipping Line",
        "Search": "Search",
        "Clicktoviewschedule": "Click to view full schedule",
        "LineName": "Line Name",
        "Number": "Number",
        "PublishDate": "Publish Date",
        "Vessel": "Vessel",
        "Voyage": "Voyage",
        "Partnervoyage": "Partner voyage",
        "Port": "Port",
        "Arrival": "Arrival",
        "Departure": "Departure",
        "VOYAGEREMARKS": "VOYAGE REMARKS",
        "Exceptionalportforvoyage": "*Exceptional port for voyage",
        "Print": "Print",
        "PDF": "PDF",
        "NoRecordFound": "No Record Found.",
        "IntraAsia": "Intra-Asia",
        "AsiaAfrica": "Asia-Africa",
        "AsiaOceania": "Asia-Oceania",
        "RORO": "RORO",
        "IndiaSubconMiddleEast": "India Subcon. & Middle East",
    },
    "cn": {
        "SchedulebyLine": "航線船期表",
        "SchedulebyLine1": "查詢其它航線",
        "Trade": "服務區",
        "SelectTrade": "選擇服務區",
        "ShippingLine": "航線",
        "Search": "查詢",
        "Clicktoviewschedule": "點擊查看完整船期",
        "LineName": "航線名",
        "Number": "週",
        "PublishDate": "查詢日期",
        "Vessel": "船名",
        "Voyage": "航線",
        "Partnervoyage": "其它船公司",
        "Port": "港口",
        "Arrival": "到港",
        "Departure": "離港",
        "VOYAGEREMARKS": "航線備注",
        "Exceptionalportforvoyage": "*非常規港口",
        "Print": "列印",
        "PDF": "PDF",
        "NoRecordFound": "No Record Found.",
        "IntraAsia": "亞洲區内",
        "AsiaAfrica": "亞洲-非洲",
        "AsiaOceania": "亞洲-大洋洲",
        "RORO": "RORO",
        "IndiaSubconMiddleEast": "India Subcon. & Middle East",
        "NoRecordFound": "沒有記錄",
        
    },
    "zh": {
        "SchedulebyLine": "航线船期表",
        "SchedulebyLine1": "查询其它航线",
        "Trade": "服务区",
        "SelectTrade": "选择服务区",
        "ShippingLine": "航線",
        "Search": "查询",
        "Clicktoviewschedule": "点击查看完整船期",
        "LineName": "航线名",
        "Number": "週",
        "PublishDate": "查询日期",
        "Vessel": "船名",
        "Voyage": "航次",
        "Partnervoyage": "伙伴的航次",
        "Port": "港口",
        "Arrival": "到港",
        "Departure": "离港",
        "VOYAGEREMARKS": "航次备註",
        "Exceptionalportforvoyage": "*非常规港口",
        "Print": "列印",
        "PDF": "PDF",
        "NoRecordFound": "没有记录.",
        "IntraAsia": "亚洲区内",
        "AsiaAfrica": "亚洲-非洲",
        "AsiaOceania": "亚洲-大洋州",
        "RORO": "RORO (车辆滚装)",
        "IndiaSubconMiddleEast": "India Subcon. & Middle East",
        "NoRecordFound": "没有记录",
    },
    "ko": {
        "SchedulebyLine": "노선별 스케줄",
        "SchedulebyLine1": "다른 노선 조회",
        "Trade": "무역 및 배송",
        "SelectTrade": "무역 및 배송 조회",
        "ShippingLine": "선적 노선",
        "Search": "조회",
        "Clicktoviewschedule": "전체 스케줄 보기",
        "LineName": "노선 이름",
        "Number": "번호",
        "PublishDate": "공표 일자",
        "Vessel": "Vessel",
        "Voyage": "항차",
        "Partnervoyage": "파트너 항차",
        "Port": "항구",
        "Arrival": "도착",
        "Departure": "출발",
        "VOYAGEREMARKS": "항차 설명",
        "Exceptionalportforvoyage": "*이번항해에서 예외적으로 입항하는 항구",
        "Print": "출력",
        "PDF": "PDF",
        "NoRecordFound": "조회된 record 없음.",
        "IntraAsia": "아시아권",
        "AsiaAfrica": "아시아 - 아프리카",
        "AsiaOceania": "아시아 - 오세아니아",
        "RORO": "RORO",
        "IndiaSubconMiddleEast": "India Subcon. & Middle East",
       
    },
    "vi": {
        "SchedulebyLine": "Lịch tàu theo tuyến dịch vụ",
        "SchedulebyLine1": "Tìm kiếm theo hành trình khác",
        "Trade": "Tuyến",
        "SelectTrade": "Lựa chọn tuyến",
        "ShippingLine": "Tên dịch vụ",
        "Search": "Tìm kiếm",
        "Clicktoviewschedule": "Nhấp để xem lịch tàu đầy đủ",
        "LineName": "Tên dịch vụ:",
        "Number": "Số:",
        "PublishDate": "Ngày phát hành:",
        "Vessel": "Tên tàu",
        "Voyage": "Số chuyến",
        "Partnervoyage": "Số chuyến theo đăng ký của đối tác",
        "Port": "Cảng",
        "Arrival": "Ngày đến",
        "Departure": "Ngày đi",
        "VOYAGEREMARKS": "LƯU Ý",
        "Exceptionalportforvoyage": "*Cảng ghé ngoài lịch trình",
        "Print": "In ấn",
        "PDF": "Tải xuống bản PDF",
        "NoRecordFound": "Không tìm thấy thông tin.",
        "IntraAsia": "Nội Á",
        "AsiaAfrica": "Châu Á- Châu Phi",
        "AsiaOceania": "Châu Á- Châu Đại Dương",
        "RORO": "RORO",
        "IndiaSubconMiddleEast": "India Subcon. & Middle East",
       
    },
    "th": {
        "SchedulebyLine": "ตารางเรือโดยเส้นทางบริการ",
        "SchedulebyLine1": "ค้นหาเส้นทางอื่น",
        "Trade": "เส้นทางการค้า",
        "SelectTrade": "เลือกเส้นทางการค้า",
        "ShippingLine": "เส้นทางบริการ",
        "Search": "ค้นหา",
        "Clicktoviewschedule": "คลิกเพื่อดุตารางเรือฉบับเต็ม",
        "LineName": "ชื่อเส้นทางบริการ",
        "Number": "หมายเลข",
        "PublishDate": "วันที่ประกาศ",
        "Vessel": "ชื่อเรือ",
        "Voyage": "เที่ยวเรือ",
        "Partnervoyage": "เที่ยวเรือ  - Partner voyage",
        "Port": "ท่าเรือ",
        "Arrival": "วันเรือเข้า",
        "Departure": "วันเรือออก",
        "VOYAGEREMARKS": "หมายเหตุ - Voyage",
        "Exceptionalportforvoyage": "*Exceptional port for voyage",
        "Print": "พิมพ์",
        "PDF": "PDF",
        "NoRecordFound": "ไม่พบข้อมูล",
        "IntraAsia": "ภายในเอเชีย",
        "AsiaAfrica": "เอเชีย - แอฟริกา",
        "AsiaOceania": "เอเชีย - โอชีเนีย",
        "RORO": "เรือบรรทุกรถยนต์",
        "IndiaSubconMiddleEast": "India Subcon. & Middle East",
       
    },
    "in": {
        "SchedulebyLine": "Jadwal per Jalur",
        "SchedulebyLine1": "Cari rute lain",
        "Trade": "Perdagangan",
        "SelectTrade": "Pilih Perdagangan",
        "ShippingLine": "Shipping Line",
        "Search": "Cari",
        "Clicktoviewschedule": "Klik untuk menampilkan jadwal seluruhnya",
        "LineName": "Nama Jalur:",
        "Number": "Nomor:",
        "PublishDate": "Tanggal Publikasi :",
        "Vessel": "Kapal",
        "Voyage": "Perjalanan",
        "Partnervoyage": "Partner Perjalanan",
        "Port": "Pelabuhan",
        "Arrival": "Tiba",
        "Departure": "Berangkat",
        "VOYAGEREMARKS": "Keterangan Perjalanan",
        "Exceptionalportforvoyage": "*Pelabuhan pengecualian untuk Perjalanan",
        "Print": "Cetak",
        "PDF": "PDF",
        "NoRecordFound": "Data tidak ditemukan",
        "IntraAsia": "Intra-Asia",
        "AsiaAfrica": "Asia-Africa",
        "AsiaOceania": "Asia-Oceania",
        "RORO": "RORO",
        "NoRecordFound": "Data tidak ditemukan",
        "IndiaSubconMiddleEast": "India Subcon. & Middle East",
    },
    "ja": {
        "SchedulebyLine": "航路別スケジュール",
        "SchedulebyLine1": "別の経路を検索します",
        "Trade": "業界",
        "SelectTrade": "業界を選択します",
        "ShippingLine": "船路",
        "Search": "検索",
        "Clicktoviewschedule": "クリックするとスケジュール全体が表示されます",
        "LineName": "航路名：",
        "Number": "番号：",
        "PublishDate": "公開日：",
        "Vessel": "船",
        "Voyage": "航海",
        "Partnervoyage": "パートナー航海",
        "Port": "港",
        "Arrival": "着",
        "Departure": "発",
        "VOYAGEREMARKS": "航海コメント",
        "Exceptionalportforvoyage": "*例外的な寄航港",
        "Print": "印刷",
        "PDF": "PDF",
        "NoRecordFound": "記録が見つかりません。",
        "IntraAsia": "アジア域内",
        "AsiaAfrica": "アジアーアフリカ",
        "AsiaOceania": "アジアーオセアニア",
        "RORO": "RORO",
        "NoRecordFound": "記録が見つかりません。",
        "IndiaSubconMiddleEast": "India Subcon. & Middle East",
    },
}
export default homelang;