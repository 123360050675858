const homelang =
{
    "en": {
        "SUBMITVGM": "SUBMIT VGM",
        "SUBMITVGM1": "Declare your VGM weight",
        "BookingDetails": "Booking Details",
        "BookingBLNumber": "Booking / BL Number",
        "NotificationEmail": "Notification Email",
        "addmultipleemailsseparatedby": "add multiple emails separated byBooking / BL Number",
        "BookingOfficeCountryLocation": "Booking Office Country Location",
        "BookingOffice": "Booking Office",
        "PartyInformation": "Party Information",
        "Delete": "Delete",
        "Country": "Country",
        "StateProvince": "State / Province",
        "City": "City",
        "CertifierName": "Certifier Name",
        "CompanyName": "Company Name",
        "Address": "Address",
        "PhoneNumber": "PhoneNumber",
        "AddMoreCertifier": "Add More Certifier",
        "ContainerInformation": "Container Information",
        "Delete": "Delete",
        "Type": "Type",
        "Alert": "Alert",
        "TarewtUseMethod1": "Tarewt not retrievable - Use Method 1",
        "Method": "Method",
        "ContainerNumber": "Container Number",
        "UnitOfMeasure": "Unit Of Measure",
        "TareWeight": "Tare Weight",
        "NetWeight": "Net Weight",
        "VerifiedGrossMass": "Verified Gross Mass",
        "VerificationDate": "Verification Date",
        "CertifierName": "Certifier Name",
        "AddNewContainer": "Add New Container",
        "Typethecodeyousee": "Type the code you see",
        "Submit": "Submit",
        "VGMMASSUPLOAD": "VGM MASS UPLOAD",
        "SOLASVGM": "SOLAS VGM",
       
       
       
    },
    "cn": {
        "SUBMITVGM": "申報 VGM",
        "SUBMITVGM1": "在此申報 VGM",
        "BookingDetails": "訂艙單資料",
        "BookingBLNumber": "訂艙單號 / 提單號",
        "NotificationEmail": "通知電郵",
        "addmultipleemailsseparatedby": "增加電郵",
        "BookingOfficeCountryLocation": "訂艙國家",
        "BookingOffice": "訂艙地區",
        "PartyInformation": "申報者資料",
        "Delete": "刪除",
        "Type": "類別",
        "Country": "國家",
        "StateProvince": "州 / 省",
        "City": "城市",
        "CertifierName": "申報者名字",
        "CompanyName": "公司名字",
        "Address": "地址",
        "PhoneNumber": "電話",
        "AddMoreCertifier": "增加申報者",
        "ContainerInformation": "貨櫃資料",
        "Delete": "刪除",
        "Alert": "提示",
        "TarewtUseMethod1": "未能提供空櫃净重 - 請選方法 1",
        "Method": "方法",
        "ContainerNumber": "櫃號",
        "UnitOfMeasure": "重量單位",
        "TareWeight": "空櫃重量",
        "NetWeight": "貨物净重",
        "VerifiedGrossMass": "VGM",
        "VerificationDate": "核重日期",
        "CertifierName": "核重機構",
        "AddNewContainer": "增加櫃號",
        "Typethecodeyousee": "請輸入驗證碼",
        "Submit": "提交",
        "VGMMASSUPLOAD": "統一上傳 VGM",
        "SOLASVGM": "SOLAS VGM",
       
    },
    "zh": {
        "SUBMITVGM": "申报 VGM",
        "SUBMITVGM1": "在此申報 VGM",
        "BookingDetails": "订舱单资料",
        "BookingBLNumber": "订舱单号 / 提单号",
        "NotificationEmail": "通知电邮",
        "addmultipleemailsseparatedby": "增加电邮",
        "BookingOfficeCountryLocation": "订舱国家",
        "BookingOffice": "订舱地区",
        "PartyInformation": "申报者资料",
        "Delete": "删除",
        "Type": "类别",
        "Country": "国家",
        "StateProvince": "州 / 省",
        "City": "城市",
        "CertifierName": "申报者名字",
        "CompanyName": "公司名字",
        "Address": "地址",
        "PhoneNumber": "电话",
        "AddMoreCertifier": "增加申报者",
        "ContainerInformation": "集装箱资料",
        "Delete": "删除",
        "Alert": "提示",
        "TarewtUseMethod1": "未能提供空箱净重 - 请选方法 1",
        "Method": "方法",
        "ContainerNumber": "箱号",
        "UnitOfMeasure": "重量单位",
        "TareWeight": "空箱重量",
        "NetWeight": "货物净重",
        "VerifiedGrossMass": "VGM",
        "VerificationDate": "核重日期",
        "CertifierName": "核重机构",
        "AddNewContainer": "增加箱号",
        "Typethecodeyousee": "请输入验证码",
        "Submit": "递交",
        "VGMMASSUPLOAD": "批量上传 VGM",
        "SOLASVGM": "核实货柜总重",
       
    },
    "ko": {
        "SUBMITVGM": "VGM 제출",
        "SUBMITVGM1": "VGM 무게 제출",
        "BookingDetails": "예약 세부 내용",
        "BookingBLNumber": "Booking / BL Number",
        "NotificationEmail": "알림 Email",
        "addmultipleemailsseparatedby": "예약 / BL 번호로 구분된 여러 메일은 ,로 추가합니다",
        "BookingOfficeCountryLocation": "예약 사무소 국가 위치",
        "BookingOffice": "예약 사무소",
        "PartyInformation": "거래처 정보",
        "Delete": "삭제",
        "Type": "형식",
        "Country": "국가 ",
        "StateProvince": "주/도",
        "City": "도시",
        "CertifierName": "인증자 이름",
        "CompanyName": "회사 이름",
        "Address": "주소",
        "PhoneNumber": "전화 번호",
        "AddMoreCertifier": "인증자 추가",
        "ContainerInformation": "컨테이너 정보",
        "Delete": "삭제",
        "Alert": "알림",
        "TarewtUseMethod1": "공컨테이너 무게 확인 불가 - 방법(Method) 1 사용",
        "Method": "방법",
        "ContainerNumber": "컨테이너 번호",
        "UnitOfMeasure": "측정 단위",
        "TareWeight": "공컨테이너 무게",
        "NetWeight": "화물 내품 무게",
        "VerifiedGrossMass": "확인된 총 무게",
        "VerificationDate": "확인 일자",
        "CertifierName": "인증자 이름",
        "AddNewContainer": "컨테이너 추가",
        "Typethecodeyousee": "보이는 코드를 입력하십시오",
        "Submit": "제출",
        "VGMMASSUPLOAD": "VGM 일괄 업로드",
        "SOLASVGM": "SOLAS VGM",
       
    },
    "vi": {
        "SUBMITVGM": "Khai báo VGM",
        "SUBMITVGM1": "Khai báo VGM cho hàng hóa của bạn",
        "BookingDetails": "Thông tin đặt chỗ",
        "BookingBLNumber": "Số đặt chỗ/ Vận đơn",
        "NotificationEmail": "Thư điện tử nhận thông báo",
        "addmultipleemailsseparatedby": "Thêm các thư điện tử khác, ngăn cách bằng",
        "BookingOfficeCountryLocation": "Vị trí quốc gia của Đại lý đặt chỗ",
        "BookingOffice": "Đại lý đặt chỗ",
        "PartyInformation": "Thông tin khách hàng",
        "Delete": "Xóa",
        "Type": "Loại khách hàng",
        "Country": "Quốc gia",
        "StateProvince": "Bang/ Tỉnh",
        "City": "Thành phố",
        "CertifierName": "Tên đơn vị chứng nhận",
        "CompanyName": "Tên công ty",
        "Address": "Địa chỉ",
        "PhoneNumber": "Số điện thoại",
        "AddMoreCertifier": "Điền thêm tên đơn vị chứng nhận",
        "ContainerInformation": "Thông tin container",
        "Delete": "Xóa",
        "Alert": "Báo động",
        "TarewtUseMethod1": "Trọng lượng vỏ không thể truy xuất - Vui lòng sử dụng phương pháp 1",
        "Method": "Phương pháp",
        "ContainerNumber": "Số container",
        "UnitOfMeasure": "Đơn vị đo lường",
        "TareWeight": "Trọng lượng vỏ",
        "NetWeight": "Trọng lượng tịnh",
        "VerifiedGrossMass": "VGM",
        "VerificationDate": "Ngày xác minh",
        "CertifierName": "Đơn vị chứng nhận",
        "AddNewContainer": "Thêm container mới",
        "Typethecodeyousee": "Vui lòng nhập mã mà bạn thấy",
        "Submit": "Nhấn để gửi",
        "VGMMASSUPLOAD": "Khai báo VGM số lượng lớn",
        "SOLASVGM": "SOLAS VGM",
       
    },
    "th": {
        "SUBMITVGM": "ส่ง VGM",
        "SUBMITVGM1": "แจ้งน้ำหนัก VGM",
        "BookingDetails": "รายละเอียดการจอง",
        "BookingBLNumber": "หมายเลขบุคกิ้ง / BL",
        "NotificationEmail": "อีเมลแจ้งเตือน",
        "addmultipleemailsseparatedby": "เพิ่มอีเมลหลายฉบับด้วยการแยก หมายเลขบุ๊คกิ้ง/ หมายเลข BL",
        "BookingOfficeCountryLocation": "สถานที่จองบุคกิ้ง",
        "BookingOffice": "สาขาที่ของ",
        "PartyInformation": "ข้อมูลลูกค้า",
        "Delete": "ลบ",
        "Type": "ประเภท",
        "Country": "ประเทศ",
        "StateProvince": "จังหวัด",
        "City": "เมือง",
        "CertifierName": "ชื่อผู้รับรอง",
        "CompanyName": "ชื่อบริษัท",
        "Address": "ที่อยู่",
        "PhoneNumber": "เบอร์โทรติดต่อ",
        "AddMoreCertifier": "เพิ่มผู้รับรองเพิ่มเติม",
        "ContainerInformation": "ข้อมูลตู้คอนเทนเนอร์",
        "Delete": "ลบ",
        "Alert": "แจ้งเตือน",
        "TarewtUseMethod1": "ไม่สามารถเรียกข้อมูลน้ำหนักตู้เปล่าได้ - ใช้ Method 1",
        "Method": "Method",
        "ContainerNumber": "หมายเลขตู้",
        "UnitOfMeasure": "หน่วย",
        "TareWeight": "น้ำหนักตู้เปล่า",
        "NetWeight": "น้ำหนักสุทธิ",
        "VerifiedGrossMass": "Verified Gross Mass",
        "VerificationDate": "วันที่ตรวจสอบ",
        "CertifierName": "รับรองชื่อ",
        "AddNewContainer": "เพิ่มตู้สินค้า",
        "Typethecodeyousee": "โปรดกรอกรหัสตามภาพ",
        "Submit": "ส่ง",
        "VGMMASSUPLOAD": "VGM MASS อัปโหลด",
        "SOLASVGM": "SOLAS VGM",
       
    },
    "in": {
        "SUBMITVGM": "Ajukan VGM",
        "SUBMITVGM1": "Deklarasi berat VGM anda",
        "BookingDetails": "Detail Pesanan",
        "BookingBLNumber": "Nomor Pesanan / BL",
        "NotificationEmail": "Notifikasi Email",
        "addmultipleemailsseparatedby": "Tambahkan beberapa email dibatasi oleh",
        "BookingOfficeCountryLocation": "Lokasi Kantor Negara Pemesanan",
        "BookingOffice": "Kantor Pemesanan",
        "PartyInformation": "Informasi Pihak terkait",
        "Delete": "Hapus",
        "Country": "Negara",
        "StateProvince": "Propinsi",
        "City": "Kota",
        "CertifierName": "Nama pemberi sertifikat",
        "CompanyName": "Nama Perusahaan",
        "Address": "Alamat",
        "PhoneNumber": "Nomor Telepon",
        "AddMoreCertifier": "Tambahkan pemberi sertifikat",
        "ContainerInformation": "Informasi Kontainer",
        "Delete": "Hapus",
        "Type": "Jenis",
        "Alert": "Peringatan",
        "TarewtUseMethod1": "Berat tara tidak dapat diambil - Gunakan Metode 1",
        "Method": "Metode",
        "ContainerNumber": "Nomor Kontainer",
        "UnitOfMeasure": "Unit Ukuran",
        "TareWeight": "Berat Tara",
        "NetWeight": "Berat Net",
        "VerifiedGrossMass": "Massa kotor terverifikasi",
        "VerificationDate": "Tanggal Verifikasi",
        "CertifierName": "Nama pemberi sertifikat",
        "AddNewContainer": "Tambah Kontainer Baru",
        "Typethecodeyousee": "Masukkan kode yang anda lihat",
        "Submit": "Ajukan",
        "VGMMASSUPLOAD": "UNGGAH MASSA VGM",
        "SOLASVGM": "SOLAS VGM",
       
       
       
    },
    "ja": {
        "SUBMITVGM": "VGMを送信",
        "SUBMITVGM1": "VGM重量を申告します",
        "BookingDetails": "予約の詳細",
        "BookingBLNumber": "予約/BL番号",
        "NotificationEmail": "通知先メールアドレス",
        "addmultipleemailsseparatedby": "複数のメールアドレスを追加するときの区切り",
        "BookingOfficeCountryLocation": "予約を行った事務所の所在国",
        "BookingOffice": "予約を行った事務所",
        "PartyInformation": "当事者情報",
        "Delete": "削除",
        "Country": "国",
        "StateProvince": "都道府県",
        "City": "市区町村",
        "CertifierName": "認証者名",
        "CompanyName": "会社名",
        "Address": "住所",
        "PhoneNumber": "電話番号",
        "AddMoreCertifier": "認証者をさらに追加",
        "ContainerInformation": "コンテナ情報",
        "Delete": "削除",
        "Type": "種類",
        "Alert": "注意",
        "TarewtUseMethod1": "風袋重量を取得できません－方法1をご利用ください。",
        "Method": "方法",
        "ContainerNumber": "コンテナ番号",
        "UnitOfMeasure": "測定単位",
        "TareWeight": "風袋重量",
        "NetWeight": "正味重量",
        "VerifiedGrossMass": "確認済の総重量",
        "VerificationDate": "確認日",
        "CertifierName": "認証者名",
        "AddNewContainer": "新しいコンテナを追加します",
        "Typethecodeyousee": "表示されたコードを入力します",
        "Submit": "送信",
        "VGMMASSUPLOAD": "VGM一括アップロード",
        "SOLASVGM": "SOLAS VGM",
       
       
       
    },
}
export default homelang;