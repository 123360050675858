const homelang =
{
    "en": {
        "LinerProfile": "Liner Profile",
        "LinerService": "Liner Service",
        "ShipFleet": "Ship Fleet",
        "Management": "Management",
        "GoldStarLineLtd": "Gold Star Line Ltd",
        "WorldRanking": "World Ranking",
        "Teu": "Teu",
        "Ships": "Ships",
        "CharteredIn": "Chartered In",
        "Total": "Total",
        "RankingUpdated": "Ranking Updated",
        "Contact": "Contact",
        "Telephone": "Telephone",
        "ContainerFleetTEU": "Container Fleet TEU",
        "Units": "Units",
        "Website": "Website",
        "ContainersIDs": "Containers IDs",
        "Service": "Service",
        "TradeRoute": "Trade Route",
        "NumberofService": "Number of Service",
        "Name": "Name",
        "Type": "Type",
        "Built": "Built",
        "Flag": "Flag",
        "Owner": "Owner",
        "Operator": "Operator",
        "NumberofVessels": "Number of Vessels",
      
       
    },
    "cn": {
        "LinerProfile": "公司簡介",
        "LinerService": "航綫服務",
        "ShipFleet": "船隊",
        "Management": "管理",
        "GoldStarLineLtd": "金星航運",
        "WorldRanking": "排名",
        "Teu": "標準箱",
        "Ships": "船隻",
        "CharteredIn": "包船",
        "Total": "總共",
        "RankingUpdated": "最新排名",
        "Contact": "聯絡",
        "Telephone": "電話",
        "ContainerFleetTEU": "船隊箱量",
        "Units": "單位",
        "Website": "網址",
        "ContainersIDs": "貨櫃代碼",
        "Service": "航綫服務",
        "TradeRoute": "途經",
        "NumberofService": "航綫服務總數",
        "Name": "船名",
        "Type": "種類",
        "Built": "製造年份",
        "Flag": "注冊地",
        "Owner": "船隻擁有",
        "Operator": "營運公司",
        "NumberofVessels": "船隻總數",
      
     
        
    },
    "zh": {
        "LinerProfile": "公司简介",
        "LinerService": "航线服务",
        "ShipFleet": "船队",
        "Management": "管理",
        "GoldStarLineLtd": "金星航运",
        "WorldRanking": "排名",
        "Teu": "标准箱",
        "Ships": "船只",
        "CharteredIn": "包船",
        "Total": "总共",
        "RankingUpdated": "最新排名",
        "Contact": "联络",
        "Telephone": "电话",
        "ContainerFleetTEU": "船队箱量",
        "Units": "单位",
        "Website": "网址",
        "ContainersIDs": "集装箱代码",
        "Service": "航线服务",
        "TradeRoute": "途经",
        "NumberofService": "航线服务总数",
        "Name": "船名",
        "Type": "种类",
        "Built": "制造年份",
        "Flag": "注册地",
        "Owner": "船只拥有",
        "Operator": "营运公司",
        "NumberofVessels": "船只总数",
      
     
        
    },
    "ko": {
        "LinerProfile": "운송사 프로필",
        "LinerService": "운송사 서비스",
        "ShipFleet": "선대",
        "Management": "경영",
        "GoldStarLineLtd": "Gold Star Line Ltd",
        "WorldRanking": "세계 순위",
        "Teu": "Teu",
        "Ships": "배",
        "CharteredIn": "용선 계약",
        "Total": "합계",
        "RankingUpdated": "순위 갱신",
        "Contact": "연락처",
        "Telephone": "전화번호",
        "ContainerFleetTEU": "컨테이너 선복 TEU",
        "Units": "단위",
        "Website": "웹싸이트",
        "ContainersIDs": "컨테이너 IDs",
        "Service": "서비스",
        "TradeRoute": "운송 경로",
        "NumberofService": "서비스 수",
        "Name": "이름",
        "Type": "종류",
        "Built": "건조",
        "Flag": "국적",
        "Owner": "선주",
        "Operator": "운영자",
        "NumberofVessels": "선박 수",
      
     
        
    },
    "vi": {
        "LinerProfile": "Hồ sơ hãng tàu",
        "LinerService": "Tuyến dịch vụ",
        "ShipFleet": "Danh sách đội tàu",
        "Management": "Ban lãnh đạo",
        "GoldStarLineLtd": "Gold Star Line Ltd",
        "WorldRanking": "Xếp hạng thế giới",
        "Teu": "Teu",
        "Ships": "Số lượng tàu",
        "CharteredIn": "Thuê vào năm",
        "Total": "Tổng cộng",
        "RankingUpdated": "Cập nhật xếp hạng",
        "Contact": "Liên hệ",
        "Telephone": "Số điện thoại",
        "ContainerFleetTEU": "Container Fleet TEU",
        "Units": "Đơn vị",
        "Website": "Website",
        "ContainersIDs": "Số hiệu Container",
        "Service": "Tên dịch vụ",
        "TradeRoute": "Lịch trình cập cảng",
        "NumberofService": "Số lượng tuyến dịch vụ",
        "Name": "Tên",
        "Type": "Loại",
        "Built": "Đóng năm",
        "Flag": "Cờ hiệu",
        "Owner": "Chủ sở hữu",
        "Operator": "Nhà khai thác",
        "NumberofVessels": "Số lượng tàu",
  
    },
    "th": {
        "LinerProfile": "ข้อมูลสายเรือ",
        "LinerService": "เส้นทางบริการ",
        "ShipFleet": "กองเรือ",
        "Management": "คณะกรรมการบริษัท",
        "GoldStarLineLtd": "Gold Star Line Ltd",
        "WorldRanking": "อันดับโลก",
        "Teu": "Teu",
        "Ships": "เรือ",
        "CharteredIn": "Chartered In",
        "Total": "ทั้งหมด",
        "RankingUpdated": "อันดับ",
        "Contact": "ติดต่อ",
        "Telephone": "เบอร์โทรศัพท์",
        "ContainerFleetTEU": "กองตู้สินค้า TEU",
        "Units": "ตู้",
        "Website": "เว็บไซต์",
        "ContainersIDs": "หมายเลขประจำตู้",
        "Service": "บริการ",
        "TradeRoute": "เส้นทางการค้า",
        "NumberofService": "จำนวนเส้นทางบริการ",
        "Name": "ชื่อ",
        "Type": "ปรเภท",
        "Built": "ปีที่ผลิต",
        "Flag": "สัญชาติ",
        "Owner": "เจ้าของ",
        "Operator": "โอเปอเรเตอร์",
        "NumberofVessels": "จำนวนเรือ",
  
    },
    "in": {
        "LinerProfile": "Profil Kapal",
        "LinerService": "Layanan kapal",
        "ShipFleet": "Armada Kapal",
        "Management": "Pengelolaan",
        "GoldStarLineLtd": "Gold Star Line Ltd",
        "WorldRanking": "Peringkat Dunia",
        "Teu": "Teu",
        "Ships": "Kapal-kapal",
        "CharteredIn": "Disewa Di",
        "Total": "Total",
        "RankingUpdated": "Peringkat Diperbarui",
        "Contact": "Kontak",
        "Telephone": "Telepon",
        "ContainerFleetTEU": "Armada Kontainer TEU",
        "Units": "Satuan",
        "Website": "Situs Web",
        "ContainersIDs": "ID kontainer",
        "Service": "Layanan",
        "TradeRoute": "Rute Perdagangan",
        "NumberofService": "Jumlah Layanan",
        "Name": "Nama",
        "Type": "Jenis",
        "Built": "Dibuat",
        "Flag": "Bendera",
        "Owner": "Pemilik",
        "Operator": "Operator",
        "NumberofVessels": "Jumlah Kapal",
      
       
    },
    "ja": {
        "LinerProfile": "客船概要",
        "LinerService": "定期船事業",
        "ShipFleet": "船籍",
        "Management": "管理",
        "GoldStarLineLtd": "ゴールドスターライン株式会社",
        "WorldRanking": "世界ランキング",
        "Teu": "Teu",
        "Ships": "船舶",
        "CharteredIn": "保有隻数",
        "Total": "合計",
        "RankingUpdated": "ランキング更新",
        "Contact": "連絡先",
        "Telephone": "電話番号",
        "ContainerFleetTEU": "コンテナ船 TEU",
        "Units": "台数",
        "Website": "ウェブサイト",
        "ContainersIDs": "コンテナID",
        "Service": "サービス内容",
        "TradeRoute": "航路",
        "NumberofService": "サービス数",
        "Name": "船名",
        "Type": "船種",
        "Built": "製造",
        "Flag": "旗印",
        "Owner": "所有者",
        "Operator": "オペレーター",
        "NumberofVessels": "船舶数",
      
       
    },
}
export default homelang;