const homelang =
{
    "en": {
        "PointtoPoint": "Point to Point",
        "PointtoPoint1": "Search point to point route",
        "from": "From",
        "to": "To",
        "Startdate": "Start Date",
        "Weeksahead": "Weeks ahead",
        "Departure": "Departure",
        "Arrival": "Arrival",
        "Search": "Search",
    },
    "cn": {
        "PointtoPoint": "港口到港口",
        "PointtoPoint1": "查詢港口到港口船期",
        "from": "由",
        "to": "到",
        "Startdate": "從",
        "Weeksahead": "查詢週數",
        "Departure": "離港",
        "Arrival": "到港",
        "Search": "查詢",
    },
    "zh": {
        "PointtoPoint": "港口到港口",
        "PointtoPoint1": "查詢港口到港口船期",
        "from": "由",
        "to": "到",
        "Startdate": "开始日期",
        "Weeksahead": "查询週数",
        "Departure": "离港",
        "Arrival": "到港",
        "Search": "查询",
    },
    "ko": {
        "PointtoPoint": "항구별",
        "PointtoPoint1": "항구별 루트 조회",
        "from": "From",
        "to": "To",
        "Startdate": "시작일자",
        "Weeksahead": "주간조회",
        "Departure": "출발",
        "Arrival": "도착",
        "Search": "조회",
    },
    "vi": {
        "PointtoPoint": "Điểm đầu đến Điểm cuối",
        "PointtoPoint1": "Tìm kiếm lịch trình theo điểm đầu và điểm cuối",
        "from": "Từ",
        "to": "Đến",
        "Startdate": "Ngày bắt đầu",
        "Weeksahead": "Số tuần thể hiện lịch tàu",
        "Departure": "Khởi hành",
        "Arrival": "Cập cảng",
        "Search": "Tìm kiếm",
    },

    "th": {
        "PointtoPoint": "สถานที่ต้นทาง ถึง สถานที่ปลายทาง",
        "PointtoPoint1": "ค้นหาตารางเรือจากสถานที่ต้นทาง ถึง สถานที่ปลายทาง",
        "from": "จาก",
        "to": "ถึง",
        "Startdate": "วันเรือออก",
        "Weeksahead": "จำนวนสัปดาห์",
        "Departure": "ขาออก",
        "Arrival": "ขาเข้า",
        "Search": "ค้นหา",
    },
    "in": {
        "PointtoPoint": "Poin ke poin",
        "PointtoPoint1": "Cari rute poin ke poin",
        "from": "dari",
        "to": "ke",
        "Startdate": "Tanggal mulai",
        "Weeksahead": "minggu berikutnya",
        "Departure": "Berangkat",
        "Arrival": "Tiba",
        "Search": "Cari",
    },
    "ja": {
        "PointtoPoint": "ポイント・トゥ・ポイント",
        "PointtoPoint1": "ポイント・トゥ・ポイント航路を検索します",
        "from": "出発地",
        "to": "目的地",
        "Startdate": "開始日",
        "Weeksahead": "何週間先か",
        "Departure": "発",
        "Arrival": "着",
        "Search": "検索",
    },
}
export default homelang;