const homelang =
{
    "en": {
        "Helloinmylife": "Hello in my life",
        "Howcanhellpyou": "How can hellp you",
        "TariffCalculator": "Tariff Calculator",
        "TariffCalculator1": "Get instant container shipping rates!",
        "From": "From",
        "To": "To",
        "StartDate": "Start Date",
        "Calculate": "Calculate",
        "Ref": "Ref",
        "Service": "Service",
        "EstTT": "Est. T/T",
        "Pol": "Pol",
        "VIA1": "VIA 1",
        "Pod": "Pod",
        "Pol": "Pol",
        "RateperContainer": "Rate per Container",
        "Validfrom": "Valid from",
        "Validto": "Valid to",
        "Print": "Print",
        "PDF": "PDF",
        "Disclaimer": "Disclaimer",
        "NoRecordFound": "No Record Found",
    
    },
    "cn": {
        "Helloinmylife": "你在我的心中",
        "Howcanhellpyou": "怎麼幫你",
        "TariffCalculator": "運費計算器",
        "TariffCalculator1": "立刻取得運費價格!!",
        "From": "由",
        "To": "到",
        "StartDate": "從",
        "Calculate": "計算",
        "Ref": "參考編號",
        "Service": "直航 / 中轉",
        "EstTT": "預計航程",
        "Pol": "裝貨港",
        "VIA1": "中轉港",
        "Pod": "卸貨港",
        "Pol": "裝貨港",
        "RateperContainer": "價格由每櫃計算",
        "Validfrom": "有效日期由",
        "Validto": "有效日期到",
        "Print": "列印",
        "PDF": "PDF",
        "Disclaimer": "免責聲明",
        "NoRecordFound": "沒有記錄",
       
    },
    "zh": {
        "Helloinmylife": "你在我的心中",
        "Howcanhellpyou": "怎么帮你",
        "TariffCalculator": "运费计算器",
        "TariffCalculator1": "立刻取得运费价格!",
        "From": "由",
        "To": "到",
        "StartDate": "从",
        "Calculate": "计算",
        "Ref": "参考编号",
        "Service": "直航 / 中转",
        "EstTT": "预计航程",
        "Pol": "装货港",
        "VIA1": "中转港",
        "Pod": "卸货港",
        "Pol": "装货港",
        "RateperContainer": "每箱价格",
        "Validfrom": "有效日由",
        "Validto": "到期日",
        "Print": "列印",
        "PDF": "PDF",
        "Disclaimer": "免责声明",
        "NoRecordFound": "没有记录",
       
    },
    "ko": {
        "Helloinmylife": "Hello in my life",
        "Howcanhellpyou": "How can hellp you",
        "TariffCalculator": "운임 계산",
        "TariffCalculator1": "즉시 컨테이너 운송 요율 받기!",
        "From": "From",
        "To": "To",
        "StartDate": "시작 일자",
        "Calculate": "계산",
        "Ref": "참조 사항",
        "Service": "서비스",
        "EstTT": "예상 운송 일자",
        "Pol": "선적지",
        "VIA1": "경유지 1",
        "Pod": "도착지",
        "RateperContainer": "컨테이너별 요율",
        "Validfrom": "유효 시점",
        "Validto": "유효 종점",
        "Print": "출력",
        "PDF": "PDF",
        "Disclaimer": "면책",
        "NoRecordFound": "조회된 기록 없음",
       
    },
    "vi": {
        "Helloinmylife": "Xin chào",
        "Howcanhellpyou": "Tôi có thể giúp gì cho bạn",
        "TariffCalculator": "Cách tính biểu phí",
        "TariffCalculator1": "Nhận báo giá vận chuyển ngay tức thời!",
        "From": "Từ",
        "To": "Đến",
        "StartDate": "Ngày bắt đầu",
        "Calculate": "Tính toán",
        "Ref": "Mã tham chiếu",
        "Service": "Dịch vụ",
        "EstTT": "Tổng thời gian vận chuyển dự kiến",
        "Pol": "Cảng xếp hàng",
        "VIA1": "Cảng chuyển tải 1",
        "Pod": "Cảng dỡ hàng",
        "RateperContainer": "Giá trên 1 container",
        "Validfrom": "Có hiệu lực từ",
        "Validto": "Có hiệu lực đến",
        "Print": "In ấn",
        "PDF": "Tải xuống bản PDF",
        "Disclaimer": "Tuyên bố từ chối trách nhiệm",
        "NoRecordFound": "Không tìm thấy thông tin",
       
    },
    "th": {
        "Helloinmylife": "สวัสดี",
        "Howcanhellpyou": "ช่วยคุณได้อย่างไร",
        "TariffCalculator": "คำนวณค่าขนส่ง",
        "TariffCalculator1": "รับอัตราค่าขนส่งทันที!",
        "From": "จาก",
        "To": "ถึง",
        "StartDate": "วันที่เริ่มต้น",
        "Calculate": "คำนวณ",
        "Ref": "อ้างอิง",
        "Service": "บริการ",
        "EstTT": "ระยะเวลาขนส่ง",
        "Pol": "สถานที่ต้นทางที่ส่งออก",
        "VIA1": "ถ่ายลำ",
        "Pod": "สถานที่ปลายทาง",
        "RateperContainer": "ราคาต่อตู้สินค้า",
        "Validfrom": "ใช้ได้ตั้งแต่",
        "Validto": "ใช้ได้จนถึง",
        "Print": "พิมพ์",
        "PDF": "PDF",
        "Disclaimer": "ข้อสงวนสิทธิ์",
        "NoRecordFound": "ไม่พบข้อมูล",
       
    },
    "in": {
        "Helloinmylife": "Halo dalam hidup saya",
        "Howcanhellpyou": "Apa yang kami bisa bantu",
        "TariffCalculator": "Penghitung tarif",
        "TariffCalculator1": "Dapatkan harga pengapalan kontainer secara instan",
        "From": "Dari",
        "To": "Ke",
        "StartDate": "Tanggal mulai",
        "Calculate": "Hitung",
        "Ref": "Ref",
        "Service": "Layanan",
        "EstTT": "Est T/T",
        "Pol": "Pelabuhan muat",
        "VIA1": "VIA 1",
        "Pod": "Pelabuhan tujuan",
        "RateperContainer": "Harga per kontainer",
        "Validfrom": "Valid dari",
        "Validto": "Valid ke",
        "Print": "Cetak",
        "PDF": "PDF",
        "Disclaimer": "Disclaimer",
        "NoRecordFound": "Data tidak ditemukan",
    
    },
    "ja": {
        "Helloinmylife": "こんにちは",
        "Howcanhellpyou": "いらっしゃいませ",
        "TariffCalculator": "運送料計算機",
        "TariffCalculator1": "コンテナの運送料が確認できます。",
        "From": "出発地",
        "To": "目的地",
        "StartDate": "出発日",
        "Calculate": "計算します",
        "Ref": "参照",
        "Service": "サービス",
        "EstTT": "推定航程",
        "Pol": "積地港",
        "VIA1": "中継港1",
        "Pod": "荷揚港",
        "Pol": "積地港",
        "RateperContainer": "コンテナあたりの料金",
        "Validfrom": "有効期間開始日",
        "Validto": "有効期間終了日",
        "Print": "印刷",
        "PDF": "PDF",
        "Disclaimer": "免責事項",
        "NoRecordFound": "記録が見つかりません。",
    
    },
}
export default homelang;