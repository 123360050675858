import React, { Component, useEffect, useState, useRef } from "react";
import Layout from '../../../components/layout';
import { render } from "react-dom";
import ReactDOM from "react-dom";
import classnames from "classnames";
import Api from '../../../../package.json'
import { useForm } from "react-hook-form";
import * as axios from "axios";
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import Inner_container from '../../innerpages/inner-container';

import swal from "sweetalert";
import { useHistory } from "react-router-dom";

const qs = require("qs");
var jwt = require("jsonwebtoken");
const Unsubscribe_newsletter = () => {
    const [country_code, setCountryCode] = useState(window.$country_code);
    const [footerHoverIconIndex, setfooterHoverIconIndex] = useState(-1);
    const [popHide, setpopHide] = useState(true);
    const [footerPopBottom, setfooterPopBottom] = useState(-1);
    const [fixedBottomBar, setfixedBottomBar] = useState(false);
    const [SubmitButton, setSubmitButton] = useState("Go!");
    const headers = {
        type: "application/json",
        Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const {
        register: register1,
        handleSubmit: handleSubmit1,
        formState: { errors: errors1 },
        getValues,
    } = useForm();
    const buttonRef = useRef();
    const history = useHistory();
    const onSubmit = (data) => {
        buttonRef.current.disabled = true;
        setSubmitButton("submitting...");
        save_newsletter(data);
    };
    const onSubmit_container = (data) => {
        console.log(data);
        var containerid = getValues('containerid');
        if (containerid != "") {
            history.push("/track_shipment/" + data.containerid);
        }
    };
    const save_newsletter = async (data) => {
        const response = await axios.post(
            Api.userdata.url + "/unsubscribe_newsletter",
            qs.stringify({
                country_code: country_code,
                email: data.email,
            }), { headers: headers }
        );
        const json = await response.data;
        if (json["status"] == "OK") {
            swal("Successful Submitted", json["message"], "success").then((value) => {
                window.location.reload(false);
            });
        } else {
            swal("Error !", json["message"], "error");
        }
        buttonRef.current.disabled = false;
        setSubmitButton("Submit");
    };
    const showFixedBar = (data) => {
        setfooterHoverIconIndex(-1);
        setfooterPopBottom(-1);
        setpopHide(true);
        var bt_flag = (window.localStorage.getItem('fixedBottomBar') == 'true') ? 'false' : 'true';
        window.localStorage.setItem('fixedBottomBar', bt_flag);
        setfixedBottomBar(window.localStorage.getItem('fixedBottomBar') == 'true' ? true : false);
        setfooterHoverIconIndex(-1);
    };
    useEffect(() => {
           });
    return (

        <Layout>
            <Inner_container innerpage="unsubscribe_schedule" />



            <section class="inner-center n-40">
                <div class="container">
                    <div class="row m-0 ">
                        <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12">
                            <form class="point-to" >

                                <div class="form-fback">
                                    <div class="row m-0 justify-content-md-center">
                                        <div class="col-lg-6 col-sm-6 col-md-6 local-inf-back">



                                            <form
                                                id="subscribe_email_form"
                                                onSubmit={handleSubmit(onSubmit)}
                                                name="subscribe_email_form"
                                            >
                                                <div class="footer-subs unsubscribe-subs">
                                                    <h4> Do You Really Want To Unsubscribe Schedule</h4>
                                                    <input
                                                        type="email"
                                                        placeholder="Your email address"
                                                        name="email"
                                                        {...register("email", {
                                                            required: {
                                                                value: true,
                                                                message: "Enter your email-id",
                                                            },
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: "Invalid email address",
                                                            },
                                                        })}
                                                    />

                                                    <input
                                                        type="hidden"
                                                        name="footer_captcha"
                                                        id="footer_captcha"
                                                    />
                                                    <button id="subscribe_email_bt" ref={buttonRef}>
                                                        {" "}
                                                        {SubmitButton}
                                                    </button>
                                                </div>
                                                {errors.email && (
                                                    <span class="errors">{errors.email.message}</span>
                                                )}
                                            </form>



                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Services />
            <Packages />
            <GET_IN_TOUCH />
        </Layout >);
};

export default Unsubscribe_newsletter;