const homelang =
{
    "en": {
        "WHOWEARE": "WHO WE ARE",
        "OURMISSION": "OUR MISSION",
        "KnowMore": "Know More",
       
       
    },
    "cn": {
        "WHOWEARE": "這就是我們",
        "OURMISSION": "我們的任務",
        "KnowMore": "知道更多",
     
        
    },
    "zh": {
        "WHOWEARE": "这就是我们",
        "OURMISSION": "我们的任务",
        "KnowMore": "知道更多",
  
    },
    "ko": {
        "WHOWEARE": "당사는",
        "OURMISSION": "당사의 사명",
        "KnowMore": "더 알아보기",
  
    },
    "vi": {
        "WHOWEARE": "Chúng tôi là ai",
        "OURMISSION": "Nhiệm vụ của chúng tôi",
        "KnowMore": "Tìm hiểu thêm",
  
    },
    "th": {
        "WHOWEARE": "รู้จัก GSL",
        "OURMISSION": "พันธกิจ",
        "KnowMore": "รายละเอียดเพิ่มเติม",
  
    },
    "in": {
        "WHOWEARE": "SIAPA KAMI",
        "OURMISSION": "MISI KAMI",
        "KnowMore": "Tahu lebih banyak",
       
       
    },
    "ja": {
        "WHOWEARE": "会社概要",
        "OURMISSION": "企業理念",
        "KnowMore": "詳細はこちら",
       
       
    },
}
export default homelang;