const homelang =
{
    "en": {
        "Cargo_Services": "Cargo Services",
        "GSLensuressmoothsailing": "GSL ensures smooth sailing for all types of cargo. Our reefer containers are ready for your frozen food, medical supplies and refrigerated cargo of all kinds, while our project cargo team always finds the best creative solutions for oversize, hazardous, or other specialized cargo. At GSL, all types of cargo receive expert hands-on service, ensuring every customer has the support they need to steer their business forward.",
        "Differentcargoesnaturally": "Different cargoes naturally have different needs, while every customer requires the same accessible, knowledgeable support. By building ongoing relationships over many years, we learn the ins and outs of your business, so we can provide the right service for your individual needs. This is what sets GSL apart from the large, impersonal conglomerates of the industry. As an independent carrier, we have a proud tradition of integrity and accountability, so you can trust that we will be by your side during every step of the transport process. Drawing on more than seven decades of diverse transportation experience, where container shipping expertise comes with a personal touch.",
        "Weunderstandthateach": "We understand that each customer and cargo deserves personal attention together with custom-built solutions. GSL has the infrastructure and experience to deliver exactly that, for a wide variety of unique freight. GSL means more to our customers than containers on ships. We offer a variety of services and advanced technologies to maximize your peace of mind and facilitate the entire transport process. Our professional team stays in close, continual contact, working hand in hand with your staff to ensure that every cargo benefits from genuine first-class care.",
          
    },
    "cn": {
        "Cargo_Services": "貨櫃服務",
        "GSLensuressmoothsailing": "金星航運確保運送任何貨物順暢如流. 我們的冷凍櫃能時刻保持食物及醫藥用品處於最佳冷藏狀態. 而我們擁有專業團隊為您的大型貨物, 危險貨物, 特殊貨物提供獨有的解決方案.金星航運有豐富處理任何貨物的經驗,能與客戶一起共同邁進.",
        "Differentcargoesnaturally": "不同的貨物就會有不同的需要, 客戶自會有特定要求.經過多年建立長期合作夥伴關係的經驗, 我們絕對了解您所需, 從而爲您提供專有服務. 亦因如此, 使金星航運能從船務行業裏有突出優秀的表現, 是您值得信賴的合作夥伴. 隨著七載的航運經驗, 個人化的專業航運服務也隨之而來.",
        "Weunderstandthateach": "我們明白專注每位客戶所需而提供獨有貨運解決方案.以金星航運擁有的設備及經驗, 就為此而設. 金星航運重於客戶, 所以提供一系列的服務及先進技術來管控整個貨運流程, 使客戶安心. 我們的專業團隊給予客戶最貼身服務, 與客戶合作無間以確保貨物得到最一流的處理及安排.",
          
    },
    "zh": {
        "Cargo_Services": "货箱服务",
        "GSLensuressmoothsailing": "金星航运确保运送货物顺畅如流.我们的冷冻箱能保持食物及医药用品处于最佳冷藏状态. 而我们拥有专业团队为您的大型货物, 危险货物, 特殊货物提供独有的解决方案. 金星航运有丰富处理任何货物的经验, 能与客户一起携手迈进.",
        "Differentcargoesnaturally": "不同的货物就会有不同的需要, 客户自会有特定要求. 经过多年建立长期合作伙伴关系的经验, 我们绝对了解您所需, 从而为您提供专有服务. 亦因如此, 使金星航运能从船务行业里有突出优秀的表现, 是您值得信赖的合作伙伴. 随着七载的航运经验, 个人化的专业航运服务也随之而来.",
        "Weunderstandthateach": "我们明白专注每位客户所需而提供独有货运解决方案. 以金星航运拥有的设备及经验, 就为此而设. 金星航运重于客户, 所以提供一系列的服务及先进技术来管控整个货运流程,使客户安心. 我们的专业团队给于客户最贴身服务, 与客户合作无间以确保货物得到最一流的处理及安排.",
          
    },
    "ko": {
        "Cargo_Services": "화물 서비스",
        "GSLensuressmoothsailing": "GSL은 모든 유형의 화물에 대해 원활한 항해를 보장합니다. 당사의 리퍼 컨테이너는 귀하의 냉동 식품, 의료 용품 및 모든 종류의 냉장 화물을 위한 준비가 되어 있으며, 당사의 프로젝트 화물 팀은 항상 초과 크기, 위험 또는 기타 전문 화물을 위한 최고의 창의적인 솔루션을 찾고 있습니다. GSL에서는 모든 유형의 화물이 전문적인 체험 서비스를 받아 모든 고객이 비즈니스를 추진하는 데 필요한 지원을 받을 수 있습니다.",
        "Differentcargoesnaturally": "화물에 따라 요구 사항이 다른 반면, 모든 고객은 동일한 접근성과 지식을 갖춘 지원을 필요로 합니다. 우리는 오랜 세월에 걸쳐 지속적인 관계를 구축함으로써 귀사의 비즈니스 세부 사항을 파악하여 개별 요구사항에 적합한 서비스를 제공할 수 있습니다. 이것이 GSL을 업계의 거대하고 비인격적인 대기업과 차별화하는 이유입니다. 당사는 독립 운송업체로서 정직과 책임에 대한 자랑스러운 전통을 가지고 있으므로 운송 과정의 모든 단계에서 귀사와 함께 할 것이라고 믿어도 됩니다. 70년 이상의 다양한 운송 경험을 바탕으로 컨테이너 운송에 대한 전문 지식이 개인적인 손길로 제공됩니다.",
        "Weunderstandthateach": "우리는 각 고객과 화물이 맞춤형 솔루션과 함께 개인적인 관심을 가질 만하다는 것을 알고 있습니다. GSL은 다양한 고유 화물에 대해 정확하게 제공할 수 있는 인프라와 경험을 보유하고 있습니다. GSL은 선박의 컨테이너보다 고객에게 더 큰 의미를 가집니다. 당사는 고객의 안심을 극대화하고 전체 운송 과정을 원활하게 할 수 있도록 다양한 서비스와 첨단 기술을 제공합니다. 저희 전문팀은 귀사의 직원들과 긴밀히 연락을 취하며 모든 화물이 진정한 일등석 케어의 혜택을 받을 수 있도록 협력하고 있습니다.",
          
    },
    "vi": {
        "Cargo_Services": "Dịch vụ hàng hóa",
        "GSLensuressmoothsailing": "GSL đảm bảo vận chuyển suôn sẻ cho tất cả các loại hàng hóa. Các container lạnh của chúng tôi luôn sẵn sàng cho thực phẩm đông lạnh, vật tư y tế và hàng lạnh các loại của bạn, trong khi đội vận chuyển hàng hóa dự án của chúng tôi luôn tìm ra các giải pháp sáng tạo tốt nhất cho các loại hàng hóa quá khổ, nguy hiểm hoặc chuyên dụng khác. Tại GSL, tất cả các loại hàng hóa đều nhận được sự phục vụ tận tâm của chuyên gia, đảm bảo mọi khách hàng đều có được sự hỗ trợ cần thiết để đưa doanh nghiệp của họ tiến lên.",
        "Differentcargoesnaturally": "Các loại hàng hóa khác nhau đương nhiên có những nhu cầu khác nhau, trong khi mọi khách hàng đều yêu cầu sự hỗ trợ có thể tiếp cận và hiểu biết như nhau. Bằng cách xây dựng các mối quan hệ liên tục trong nhiều năm, chúng tôi tìm hiểu thông tin chi tiết về doanh nghiệp của bạn, vì vậy chúng tôi có thể cung cấp dịch vụ phù hợp cho nhu cầu cá nhân của bạn. Đây là điều khiến GSL trở nên khác biệt so với các tập đoàn lớn, không có tính cá nhân trong ngành. Là một nhà vận chuyển độc lập, chúng tôi có truyền thống đáng tự hào về tính chính trực và tinh thần trách nhiệm, vì vậy bạn có thể tin tưởng rằng chúng tôi sẽ ở bên cạnh bạn trong mọi bước của quá trình vận chuyển. Dựa trên hơn bảy thập kỷ kinh nghiệm vận chuyển đa dạng, nơi chuyên môn vận chuyển container đi kèm với một sự chăm sóc tận tình đến từng khách hàng.",
        "Weunderstandthateach": "Chúng tôi hiểu rằng mỗi khách hàng và hàng hóa đều xứng đáng nhận được sự chăm sóc tận tình cùng các giải pháp được xây dựng riêng. GSL có cơ sở hạ tầng và kinh nghiệm để cung cấp chính xác điều đó cho nhiều loại hàng hóa khác nhau. Sự hài lòng của khách hàng luôn là mối bận tâm hàng đầu của chúng tôi. Chúng tôi cung cấp nhiều loại dịch vụ và công nghệ tiên tiến nhằm tối đa hóa sự an tâm của bạn và tạo thuận lợi cho toàn bộ quá trình vận chuyển. Đội ngũ chuyên nghiệp của chúng tôi luôn liên hệ chặt chẽ, liên tục, làm việc song song với nhân viên của bạn để đảm bảo rằng mọi hàng hóa đều được hưởng lợi từ dịch vụ chăm sóc hàng đầu chính hãng.",
          
    },
    "th": {
        "Cargo_Services": "บริการขนส่งสินค้า",
        "GSLensuressmoothsailing": "GSL รับรองการขนส่งสินค้าทางทะเลสำหรับสินค้าทุกประเภท ตู้คอนเทนเนอร์เย็นของเราพร้อมสำหรับอาหารแช่แข็ง เวชภัณฑ์ และสินค้าแช่เย็นทุกประเภท ในขณะที่ทีมขนส่งสินค้าของโครงการของเราค้นหาโซลูชันที่สร้างสรรค์ที่ดีที่สุดสำหรับสินค้าขนาดใหญ่ อันตราย หรือสินค้าเฉพาะทางอื่นๆ ที่ GSL สินค้าทุกประเภทได้รับบริการจากผู้เชี่ยวชาญ เพื่อให้มั่นใจว่าลูกค้าทุกรายจะได้รับการสนับสนุนในการขับเคลื่อนธุรกิจไปข้างหน้า",
        "Differentcargoesnaturally": "สินค้าที่แตกต่างมีความต้องการที่แตกต่างกัน ในขณะที่ลูกค้าทุกรายต้องการความช่วยเหลือที่เข้าถึงได้และมีความรู้ในการขนส่งด้วยการสร้างความสัมพันธ์อย่างต่อเนื่องตลอดหลายปีที่ผ่านมา เราเรียนรู้รายละเอียดเกี่ยวกับธุรกิจของคุณ เพื่อให้เราสามารถให้บริการที่เหมาะสมกับความต้องการส่วนบุคคลของคุณ นี่คือสิ่งที่ทำให้ GSL แตกต่างจากกลุ่มบริษัทขนาดใหญ่ ในฐานะผู้ให้บริการขนส่งอิสระ เรามีความภาคภูมิใจในด้านความซื่อสัตย์และความรับผิดชอบ คุณจึงวางใจได้ว่าเราจะอยู่เคียงข้างคุณในทุกขั้นตอนของกระบวนการขนส่ง จากประสบการณ์การขนส่งที่หลากหลายกว่าเจ็ดทศวรรษ โดยความเชี่ยวชาญด้านการขนส่งพร้อมกับสัสร้างประสบการณ์ที่ดีแก่ลูกค้า",
        "Weunderstandthateach": "เราเข้าใจดีว่าลูกค้าและสินค้าแต่ละรายสมควรได้รับความสนใจเป็นรายบุคคลร่วมกับโซลูชันที่สร้างขึ้น GSL มีโครงสร้างพื้นฐานและประสบการณ์ในการส่งมอบการให้บริการอย่างแท้จริง สำหรับการขนส่งสินค้าที่มีเอกลักษณ์หลากหลาย  เรานำเสนอบริการที่หลากหลายและเทคโนโลยีขั้นสูงเพื่อให้คุณสบายใจได้สูงสุดและอำนวยความสะดวกให้กับกระบวนการขนส่งทั้งหมด ทีมงานมืออาชีพของเราอยู่ในการติดต่ออย่างใกล้ชิด ต่อเนื่อง ทำงานร่วมกับพนักงานของคุณเพื่อให้มั่นใจว่าสินค้าทุกชิ้นจะได้รับประโยชน์จากการดูแลระดับเฟิร์สคลาสอย่างแท้จริง",
          
    },
    "in": {
        "Cargo_Services": "Layanan Kargo",
        "GSLensuressmoothsailing": "GSL memastikan kelancaran pelayaran untuk semua jenis kargo. Kontainer reefer kami siap untuk makanan beku, persediaan medis, dan kargo berpendingin Anda dari segala jenis, sementara tim kargo proyek kami selalu menemukan solusi kreatif terbaik untuk kargo besar, berbahaya, atau kargo khusus lainnya. Di GSL, semua jenis kargo menerima layanan ahli langsung, memastikan setiap pelanggan mendapatkan dukungan yang mereka butuhkan untuk mengarahkan bisnis mereka ke depan.",
        "Differentcargoesnaturally": "Kargo yang berbeda tentu saja memiliki kebutuhan yang berbeda, sementara setiap pelanggan membutuhkan dukungan yang dapat diakses dan berpengetahuan yang sama. Dengan membangun hubungan yang berkelanjutan selama bertahun-tahun, kami mempelajari seluk beluk bisnis Anda, sehingga kami dapat memberikan layanan yang tepat untuk kebutuhan pribadi Anda. Inilah yang membedakan GSL dari konglomerat industri yang besar dan impersonal. Sebagai pengangkut independen, kami memiliki tradisi integritas dan akuntabilitas yang membanggakan, sehingga Anda dapat percaya bahwa kami akan berada di sisi Anda selama setiap langkah proses pengangkutan. Berbekal lebih dari tujuh dekade pengalaman transportasi yang beragam, di mana keahlian pengiriman peti kemas hadir dengan sentuhan pribadi.",
        "Weunderstandthateach": "Kami memahami bahwa setiap pelanggan dan kargo berhak mendapatkan perhatian pribadi bersama dengan solusi yang dibuat khusus. GSL memiliki infrastruktur dan pengalaman untuk memberikan hal itu dengan tepat, untuk berbagai macam pengiriman unik. GSL lebih mementingkan pelanggan kami daripada peti kemas di kapal. Kami menawarkan berbagai layanan dan teknologi canggih untuk memaksimalkan ketenangan pikiran Anda dan memfasilitasi seluruh proses transportasi. Tim profesional kami tetap dekat, kontak terus-menerus, bekerja bergandengan tangan dengan staf Anda untuk memastikan bahwa setiap kargo mendapat manfaat dari pelayanan kelas satu yang asli.",
          
    },
    "ja": {
        "Cargo_Services": "貨物サービス",
        "GSLensuressmoothsailing": "GSLはあらゆる貨物の輸送を円滑に行います。冷凍食品、医療品、冷蔵品などあらゆる貨物に対応できる冷凍コンテナ、大型貨物、危険物、その他特殊貨物にはプロジェクト貨物チームが常に最適な創造的ソリューションを提供します。GSLでは、あらゆる種類の貨物に専門家による行き届いたサービスを提供し、お客様のビジネスを前進させるために必要なサポートをお約束します。",
        "Differentcargoesnaturally": "貨物の種類によってニーズは異なりますが、どのお客様にも同じアクセスしやすく、知識豊富なサポートが必要です。長年にわたる継続的な関係を築くことで、お客様のビジネスの内情を知り、それぞれのニーズに合ったサービスを提供することができるのです。これが、GSLが業界の大手複合企業とは異なる点です。独立系輸送業者として、誠実さと責任感を誇りにしてきた私たちは、輸送プロセスのすべての段階において、お客様に寄り添うことをお約束します。70年以上にわたる多様な輸送経験をもとに、コンテナ輸送の専門知識を個別対応で提供します。",
        "Weunderstandthateach": "私たちは、お客様それぞれと貨物が、カスタムメイドのソリューションとともに、個別な配慮を受ける大切さを理解しています。GSLは、様々な特殊貨物に対して、まさにそれを実現するためのインフラと経験を持っています。GSLはお客様にとって、船に積まれたコンテナだけを意味するのではありません。私たちはお客様の安心感を最大限に高めるため、輸送プロセス全体を円滑にするさまざまなサービスと高度な技術を提供しています。私たちのプロフェッショナルなチームは、常に緊密に連絡を取り合い、お客様側のスタッフと手を取り合って、すべての貨物に最高のサービスを提供することをお約束します。",
          
    },
}
export default homelang;