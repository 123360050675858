import React, { Component } from 'react';
import Layout from '../../layout';
import GET_IN_TOUCH from '../../get_in_touch/index';
import Services from '../../get_in_touch/services';
import Packages from '../../get_in_touch/packages_block';
import inside_heading from '../../../../src/assets/inside-heading.png';
import Inner_container from '../inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import 'react-tabs/style/react-tabs.css';
import { Accordion, Card, Button, ButtonGroup } from 'react-bootstrap'
import banner1 from '../../../../src/assets/banner1.jpg';
import Terms_of_use_lang from '../../lang/terms-of-use';


const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class Terms_of_use extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            quotation_terms_conditions_data: [],

        };

    }
    async componentDidMount() {
        const headers = {
          
            type: "application/json",
            Authorization: `Bearer `+window.localStorage.getItem('access_token'),
          };
        const response = await axios.post(Api.userdata.url + '/quotation_terms_conditions', qs.stringify({ 'token': '7' }, { parseArrays: false }),{headers:headers});
        const json = await response.data;
        this.setState({ quotation_terms_conditions_data: json['message'] });

    }
    render() {
        var lang = window.localStorage.getItem('lang');
        return (

            <Layout>
                <Inner_container innerpage="terms_of_use" />


                <section class="inner-center n-40 n-terms_of_use">
                    <div class="container">
                        <div class="row m-0">
                            <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12 p-0">
                              
                           
                                   {/* <div class="sche-title" style={{ backgroundImage: `url(${inside_heading})` }}>
                                         <h3>Terms and Conditions of Use</h3>
                                        
                                     </div> */}
                                     <div class="row m-0">
							
                            <div class="col-lg-12">
                            <Accordion defaultActiveKey="0">
                                <div class="accordion-toggle acc-pannel-foot">	 
                                <p>{Terms_of_use_lang[lang].Thefollowingtermsandconditions}.</p>
                                <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="0">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].AccessandUseofthisWebsite}.</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body><p>{Terms_of_use_lang[lang].Youarepermittedtousedownload}.</p>
												<p>{Terms_of_use_lang[lang].AsaconditionofyouruseoftheWebsite}.</p></Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="1">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].IntellectualPropertyRights}.</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body><p>{Terms_of_use_lang[lang].Ownershipofallcopyrights}.</p></Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="2">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].InteractivityThirdPartyLinksandServiceProviders}.</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body><p>{Terms_of_use_lang[lang].GSLmaymakeavailablecertain}.
												</p>
												<p>{Terms_of_use_lang[lang].GSLmayprovidethroughtheWebsite}.</p></Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="3">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].MonitoringandUnlawfulActivity}.</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body><p>{Terms_of_use_lang[lang].GSLhasnoobligationtomonitortheWebsite}.											
												</p></Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="4">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].AccuracyofthisWebsite}.</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body><p>{Terms_of_use_lang[lang].WhileGSLusescommerciallyreasonable}. </p></Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="5">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].InformativeData}.</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="5">
                                        <Card.Body><p>{Terms_of_use_lang[lang].Withoutderogatingfromtheaforesaid}. </p>
												<p>{Terms_of_use_lang[lang].Youacknowledgeandassumesole}.
													</p></Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="6">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].Conflict}.</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="6">
                                        <Card.Body><p> {Terms_of_use_lang[lang].Youherebyacknowledgeandconfirmthat}. </p>												
												<p>{Terms_of_use_lang[lang].Furthermoreandwithoutderogating}.
												</p></Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="7">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].PersonalInformationandPrivacy}.</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="7">
                                        <Card.Body><p>{Terms_of_use_lang[lang].GSLiscommittedtopreserving}.														
												</p></Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="8">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].DisclaimerofWarranties}.</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="8">
                                        <Card.Body><p>{Terms_of_use_lang[lang].EXCEPTASEXPRESSLYPROVIDEDOTHERWISE}.
												
												</p>
												<p>
                                                {Terms_of_use_lang[lang].GSLMAKESNOWARRANTYTHAT}.													
												</p>
												<p>{Terms_of_use_lang[lang].AlthoughGSLusesreasonableattempts}.</p></Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="8">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].LimitationofLiability}.</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="8">
                                        <Card.Body><p>{Terms_of_use_lang[lang].TOTHEFULLESTEXTENTPERMITTED}.</p></Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="9">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].InternationalUsers}.</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="10">
                                        <Card.Body><p>{Terms_of_use_lang[lang].TheWebsitecanbeaccessedfrom}.</p></Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="10">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].RemediesforViolations}.</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="10">
                                        <Card.Body><p>{Terms_of_use_lang[lang].GSLreservestherighttoseek}.</p>				</Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="11">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].GoverningLawandJurisdiction}.</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="11">
                                        <Card.Body><p>{Terms_of_use_lang[lang].TheTermsaregoverned}.</p></Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="12">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].Revision}</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="12">
                                        <Card.Body><p>{Terms_of_use_lang[lang].GSLreservestherighttochange}.</p></Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
                                        <Accordion.Toggle as={ButtonGroup} variant="link" eventKey="13">
                                        <h3 class="accordion-link accordion-on">{Terms_of_use_lang[lang].General}</h3>
                                        </Accordion.Toggle>
                                    </Card.Header>

                                    <Accordion.Collapse eventKey="13">
                                        <Card.Body><p>{Terms_of_use_lang[lang].TheseTermsconstitutetheentire}.</p>			</Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                </div>
                            </Accordion>
                                
                            </div>
                        </div>   
                            </div>
                        </div>
                        
                    </div>
                </section>
      
          
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}