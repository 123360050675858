const homelang =
{
    "en": {
        "ReeferCargo": "Reefer Cargo",
        "Whetheryoureshipping": "Whether you're shipping vaccines or vegetables, GSL's refrigerated containers are equipped to provide advanced transport you can rely on. Fruit, meat, medicines, and other frozen or refrigerated cargo all require precise temperature monitoring and expert care to maintain the cold chain and ensure complete safety. That's why GSL has developed unique technologies to ensure that our reefer container transportation is as secure, reliable, and efficient as possible",
        "Delicatereefercargo": "Delicate reefer cargo demands specific, hard-earned expertise. From port, throughout the voyage, and across land to the final destination, GSL's experienced professional team provides personal support, carefully analyzing and overseeing the needs of each cargo. Traversing crucial, niche trades throughout the world, we offer you first-class service and easy entry into expanding markets.",
 
    },
    "cn": {
        "ReeferCargo": "冷凍貨物",
        "Whetheryoureshipping": "無論您運送的是疫苗或蔬菜, 我們的冷凍貨櫃會是您的選擇.不論是水果, 肉類, 醫藥及其它須要冷藏或冷凍貨物, 是要嚴格溫控及格外處理，確保物流過程絕對安全. 而金星航運已有一套技術能使冷凍貨櫃在運送過程中安全, 可靠有效率.",
        "Delicatereefercargo": "冷凍貨櫃須要格外專注及專業處理. 在整個貨運流程, 金星航運擁有豐富經驗的專業團隊, 提供個人支援及對每件貨物的細心處理. 無論要運送到世界任何地方, 我們都給予您最一等的服務, 助您拓展業務.",
     
    },
    "zh": {
        "ReeferCargo": "冷冻货物",
        "Whetheryoureshipping": "无论您运送的是疫苗或蔬菜, 我们的冷冻货箱会是您的选择.不管是水果, 肉类, 医药及其它须要冷藏或冷冻货物, 是要严格温控及格外处理, 确保物流过程绝对安全. 而金星航运已有一套技术能使冷冻货箱在运送过程中安全, 可靠由效率",
        "Delicatereefercargo": "冷冻货箱须要格外专注及专业处理.在整过货运流程, 金星航运拥有丰富经验的专业团队, 提供个人支援及对每件货物的细心处理. 无论要运送到世界任何地方, 我们都给予您最一等的服务, 助您拓展业务",
     
    },
    "ko": {
        "ReeferCargo": "냉동 화물",
        "Whetheryoureshipping": "백신이든 야채든 GSL의 냉장 컨테이너는 신뢰할 수 있는 첨단 운송 수단을 제공합니다. 과일, 고기, 의약품 및 기타 냉동 또는 냉장 화물은 모두 냉간 체인을 유지하고 완벽한 안전을 보장하기 위해 정확한 온도 모니터링과 전문가의 관리가 필요합니다. 그렇기 때문에 GSL은 리퍼 컨테이너 운송이 가능한 한 안전하고, 안정적이며, 효율적일 수 있도록 고유한 기술을 개발했습니다.",
        "Delicatereefercargo": "섬세한 리퍼 화물은 구체적이고 힘들게 얻은 전문 지식을 필요로 합니다. 항구에서, 항해를 통해, 그리고 육지를 가로질러 최종 목적지까지, GSL의 숙련된 전문 팀은 각 화물의 요구를 면밀히 분석하고 감독하면서 개인적인 지원을 제공합니다. 전 세계 주요 틈새시장을 누비며 일류 서비스와 시장 진출을 제공합니다.",
     
    },
    "vi": {
        "ReeferCargo": "Hàng lạnh",
        "Whetheryoureshipping": "Cho dù bạn đang vận chuyển vắc xin hay rau quả, các container lạnh của GSL đều được trang bị để cung cấp dịch vụ vận chuyển tiên tiến mà bạn có thể tin tưởng. Trái cây, thịt, thuốc và hàng hóa đông lạnh hoặc các hàng lạnh khác đều yêu cầu giám sát nhiệt độ chính xác và sự theo dõi của chuyên gia để duy trì dây chuyền lạnh và đảm bảo an toàn hoàn toàn. Đó là lý do tại sao GSL đã phát triển các công nghệ độc đáo để đảm bảo rằng việc vận chuyển container lạnh của chúng tôi an toàn, đáng tin cậy và hiệu quả nhất có thể",
        "Delicatereefercargo": "Hàng hóa lạnh đặc thù đòi hỏi chuyên môn cụ thể, đặc biệt. Từ cảng, trong suốt hành trình, và xuyên đất liền đến điểm đến cuối cùng, đội ngũ chuyên nghiệp giàu kinh nghiệm của GSL hỗ trợ cá nhân, phân tích và giám sát cẩn thận nhu cầu của từng loại hàng hóa. Chúng tôi cung cấp cho bạn dịch vụ hạng nhất và dễ dàng thâm nhập vào các thị trường đang mở rộng.",
     
    },
    "th": {
        "ReeferCargo": "สินค้าควบคุมอุณหภูมิ",
        "Whetheryoureshipping": "ไม่ว่าคุณจะจัดส่งวัคซีนหรือผัก ตู้คอนเทนเนอร์เย็นของ GSL พร้อมสำหรับการขนส่งขั้นสูงที่คุณวางใจได้ ผลไม้ เนื้อสัตว์ ยารักษาโรค และสินค้าแช่แข็งหรือแช่เย็นอื่นๆ ล้วนต้องการการตรวจสอบอุณหภูมิที่แม่นยำและการดูแลจากผู้เชี่ยวชาญเพื่อดูแลรักษาห่วงโซ่ความเย็นและรับรองความปลอดภัยอย่างสมบูรณ์ นั่นคือเหตุผลที่ GSL ได้พัฒนาเทคโนโลยีเฉพาะเพื่อให้แน่ใจว่าการขนส่งของเรามีความปลอดภัย เชื่อถือได้ และมีประสิทธิภาพมากที่สุด",
        "Delicatereefercargo": "การขนส่งสินค้าแบบห้องเย็นที่ละเอียดอ่อนต้องการความเชี่ยวชาญเฉพาะด้านที่ได้รับมาอย่างยากลำบาก จากท่าเรือ ตลอดการเดินทาง และข้ามฝั่งไปจนถึงปลายทาง ทีมงานมืออาชีพที่มีประสบการณ์ของ GSL จะให้การสนับสนุนส่วนบุคคล วิเคราะห์และดูแลความต้องการของสินค้าแต่ละประเภทอย่างรอบคอบ เรานำเสนอบริการระดับเฟิร์สคลาสและการเข้าสู่ตลาดที่ได้ง่ายในการสำรวจตลาดเฉพาะกลุ่มที่สำคัญทั่วโลก",
     
    },
    "in": {
        "ReeferCargo": "Reefer Cargo",
        "Whetheryoureshipping": "Baik Anda mengirimkan vaksin atau sayuran, kontainer berpendingin GSL dilengkapi untuk menyediakan transportasi canggih yang dapat Anda andalkan. Buah, daging, obat-obatan, dan kargo beku atau berpendingin lainnya semuanya memerlukan pemantauan suhu yang tepat dan perawatan ahli untuk menjaga rantai dingin dan memastikan keamanan sepenuhnya. Itulah mengapa GSL telah mengembangkan teknologi unik untuk memastikan transportasi peti kemas reefer kami seaman, andal, dan seefisien mungkin",
        "Delicatereefercargo": "Kargo reefer yang halus menuntut keahlian khusus yang diperoleh dengan susah payah. Dari pelabuhan, sepanjang pelayaran, dan melintasi daratan hingga tujuan akhir, tim profesional GSL yang berpengalaman memberikan dukungan pribadi, dengan cermat menganalisis dan mengawasi kebutuhan setiap kargo. Melintasi ceruk perdagangan yang penting di seluruh dunia, kami menawarkan layanan kelas satu dan akses mudah ke pasar yang berkembang.",
 
    },
    "ja": {
        "ReeferCargo": "冷凍貨物",
        "Whetheryoureshipping": "ワクチンや野菜を輸送する際にも、GSLの冷蔵コンテナは信頼における高度な輸送を提供します。果物、肉、医薬品などの冷凍・冷蔵貨物は、的確な温度管理と専門的なケアにより、コールドチェーンを維持し、安全性を確保する必要があります。そのため、GSLでは独自の技術を開発し、安全性、信頼性、効率性に優れた冷凍コンテナ輸送を実現しています。",
        "Delicatereefercargo": "デリケートな冷凍貨物の輸送には、蓄積された専門的な技術が必要です。港から航海中、そして陸路で最終目的地まで、GSLの経験豊富なプロフェッショナルチームが、それぞれの貨物のニーズを丁寧に分析・監督し、個別のサポートを提供します。世界各地の重要な特定商取引に対応し、最高水準のサービスと、拡大する市場への円滑な参入を提供します。",
 
    },
}
export default homelang;