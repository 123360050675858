import React, { Component } from 'react';
import Layout from '../../layout';
import GET_IN_TOUCH from '../../get_in_touch/index';
import Services from '../../get_in_touch/services';
import Packages from '../../get_in_touch/packages_block';
import inside_heading from '../../../../src/assets/inside-heading.png';
import Inner_container from '../inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import 'react-tabs/style/react-tabs.css';



const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class GSLGo_terms_and_conditions extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            quotation_terms_conditions_data: [],

        };

    }
    async componentDidMount() {
        var lang = window.localStorage.getItem("lang");
        const headers = {
            
            type: "application/json",
            Authorization: `Bearer `+window.localStorage.getItem('access_token'),
          };
        const response = await axios.post(Api.userdata.url + '/quotation_terms_conditions', qs.stringify({ 'token':'gsl_go_terms','lang': lang }, { parseArrays: false }),{headers:headers});
        const json = await response.data;
        this.setState({ quotation_terms_conditions_data: json['message'] });

    }
    render() {
        return (

            <Layout>
                <Inner_container innerpage="gsl_go_terms_conditions" />


                <section class="inner-center n-40 bl_terms_conditions">
                    <div class="container">
                        <div class="row m-0">
                            <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12 p-0">
                              
                            {this.state.quotation_terms_conditions_data.map(data => (	
                                  <div>   
                                      {/* <div class="sche-title" style={{ backgroundImage: `url(${inside_heading})` }}>
                                         <h3>{ data['title'] } </h3>
                                        
                                     </div> */}
                                     <div dangerouslySetInnerHTML={{ __html: data['description'] }} >
                                     </div></div>
                                    
                            ))}                         
                            </div>
                        </div>
                        
                    </div>
                </section>
          
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}