const homelang =
{
    "en": {
        "DemurrageandDetentionTariff": "Demurrage and Detention Tariff",
        "Find": "Find",
   
    },
    "cn": {
        "DemurrageandDetentionTariff": "貨櫃倉儲價目表",
        "Find": "查閲",
    },
    "zh": {
        "DemurrageandDetentionTariff": "货箱仓储价目表",
        "Find": "查阅",
    },
    "ko": {
        "DemurrageandDetentionTariff": "체화료 및 지체료 요금표",
        "Find": "찾기",
    },
    "vi": {
        "DemurrageandDetentionTariff": "Biểu phí Lưu container- Lưu bãi",
        "Find": "Tìm kiếm",
    },
    "th": {
        "DemurrageandDetentionTariff": "อัตรา Demurrage และ Detention",
        "Find": "ค้นหา",
    },
    "in": {
        "DemurrageandDetentionTariff": "Tarif Demurrage dan Detention",
        "Find": "Temukan",
   
    },
    "ja": {
        "DemurrageandDetentionTariff": "コンテナ保管価格表",
        "Find": "検索",
   
    },
}
export default homelang;