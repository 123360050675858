const homelang =
{
    "en": {
        "SISubmission": "SI Submission",
        "Submitshippinginstructionsonline": "Submit shipping instructions online",
        "Error": "Error",
        "InvalidPortofLoading": "Invalid Port of Loading",
        "DownloadShippinginstructionformtemplate": "Download Shipping instruction form template",
        "ContactPersonName": "Contact Person Name",
        "IntendedShippingDate": "Intended Shipping Date",
        "PlaceofReceipt": "Place of Receipt",
        "PortofLoading": "Port of Loading",
        "CUCCCode": "CUCC Code(Valid Customer Code Provide by GSL)",
        "ExcelFileonly": "Excel File only (xlsx)",
        "Typethecodeyousee": "Type the code you see",
        "Submit": "Submit",
        "Email": "Email",
        "Country": "Country",
    },
    "cn": {
        "SISubmission": "補料",
        "Submitshippinginstructionsonline": "網上補料",
        "Error": "錯誤",
        "InvalidPortofLoading": "裝貨港不正確",
        "DownloadShippinginstructionformtemplate": "下載補料表格",
        "ContactPersonName": "聯絡人姓名",
        "IntendedShippingDate": "預計運貨日期",
        "PlaceofReceipt": "收貨地",
        "PortofLoading": "載貨港",
        "CUCCCode": "金星航運提供的客戶代碼",
        "ExcelFileonly": "Excel 文件",
        "Typethecodeyousee": "請輸入驗證碼",
        "Submit": "提交",
        "Email": "電郵",
        "Country": "國家",
        
    },
    "zh": {
        "SISubmission": "补料",
        "Submitshippinginstructionsonline": "网上补料",
        "Error": "错误",
        "InvalidPortofLoading": "装货港不正确",
        "DownloadShippinginstructionformtemplate": "下载补料表格",
        "ContactPersonName": "联络人姓名",
        "IntendedShippingDate": "预计运货日期",
        "PlaceofReceipt": "收货地",
        "PortofLoading": "载货港",
        "CUCCCode": "金星航运提供的客户代码",
        "ExcelFileonly": "Excel 文件",
        "Typethecodeyousee": "请输入验证码",
        "Submit": "递交",
        "Email": "电邮",
        "Country": "国家",
        
    },
    "ko": {
        "SISubmission": "선적 지시서 제출",
        "Submitshippinginstructionsonline": "온라인 선적 지시서 제출",
        "Error": "장애",
        "InvalidPortofLoading": "선적항 오류",
        "DownloadShippinginstructionformtemplate": "선적 지시서 양식 받기",
        "ContactPersonName": "연락 담당 이름",
        "IntendedShippingDate": "선적 예정 일자",
        "PlaceofReceipt": "화물 인도 장소",
        "PortofLoading": "선적항",
        "CUCCCode": "화주 코드",
        "ExcelFileonly": "Excel file만 사용",
        "Typethecodeyousee": "보이는 코드를 입력하시오",
        "Submit": "제출",
        "Email": "Email",
        "Country": "국가",
        
    },
    "vi": {
        "SISubmission": "Khai báo thông tin vận đơn",
        "Submitshippinginstructionsonline": "Khai báo thông tin vận đơn trực tuyến",
        "Error": "Lỗi",
        "InvalidPortofLoading": "Cảng xếp hàng không tồn tại",
        "DownloadShippinginstructionformtemplate": "Tải xuống mẫu thông tin vận đơn",
        "ContactPersonName": "Tên người liên hệ",
        "IntendedShippingDate": "Ngày dự định xuất hàng",
        "PlaceofReceipt": "Nơi nhận hàng",
        "PortofLoading": "Cảng xếp hàng",
        "CUCCCode": "Mã CUCC (mã khách hàng cung cấp bởi GSL)",
        "ExcelFileonly": "Chỉ nhận tệp excel (xlsx)",
        "Typethecodeyousee": "Vui lòng nhập mã mà bạn thấy",
        "Submit": "Nhấn để gửi",
        "Email": "Thư điện tử",
        "Country": "Quốc gia",
        
    },
    "th": {
        "SISubmission": "ส่ง SI",
        "Submitshippinginstructionsonline": "ส่ง SI ออนไลน์",
        "Error": "ข้อผิดพลาด",
        "InvalidPortofLoading": "สถานที่ต้นทางที่ส่งออกไม่ถูกต้อง",
        "DownloadShippinginstructionformtemplate": "ดาวน์โหลดแบบฟอร์ม SI",
        "ContactPersonName": "ชื่อผู้ติดต่อ",
        "IntendedShippingDate": "วันที่ต้องการขนส่ง",
        "PlaceofReceipt": "สถานที่รับสินค้า",
        "PortofLoading": "สถานที่ต้นทางที่ส่งออก",
        "CUCCCode": "รหัส CUCC (รหัสลูกค้าที่ถูกต้องให้โดย GSL)",
        "ExcelFileonly": "แนบไฟล์ Excel (xlsx)",
        "Typethecodeyousee": "โปรดใส่รหัสตามภาพ",
        "Submit": "ส่ง",
        "Email": "อีเมล",
        "Country": "ประเทศ",
        
    },
    "in": {
        "SISubmission": "Pengajuan SI",
        "Submitshippinginstructionsonline": "Ajukan Instruksi Pengapalan online",
        "Error": "Kesalahan",
        "InvalidPortofLoading": "Pelabuhan muat tidak sah",
        "DownloadShippinginstructionformtemplate": "Unduh template lembar instruksi pengapalan",
        "ContactPersonName": "Nama kontak person",
        "IntendedShippingDate": "Tanggal pengiriman yang dimaksudkan",
        "PlaceofReceipt": "Tempat penerimaan",
        "PortofLoading": "Pelabuhan Muat",
        "CUCCCode": "Kode CUCC (kode pelanggan yang valid dari GSL)",
        "ExcelFileonly": "Hanya berkas Excel (xlsx)",
        "Typethecodeyousee": "Masukkkan kode yang anda lihat",
        "Submit": "Ajukan",
        "Email": "Email",
        "Country": "Negara",
    },
    
    "ja": {
        "SISubmission": "SIの送信",
        "Submitshippinginstructionsonline": "オンラインで発送指示を送信します",
        "Error": "エラー",
        "InvalidPortofLoading": "積地港が無効です",
        "DownloadShippinginstructionformtemplate": "発送指示フォームテンプレートをダウンロードします",
        "ContactPersonName": "担当者の氏名",
        "IntendedShippingDate": "出荷予定日",
        "PlaceofReceipt": "荷受地",
        "PortofLoading": "積地港",
        "CUCCCode": "CUCCコード（GSLが提供する有効な顧客コード）",
        "ExcelFileonly": "Excelファイル（xlsx）のみ",
        "Typethecodeyousee": "表示されたコードを入力します",
        "Submit": "送信",
        "Email": "電子メール",
        "Country": "国",
    },
}
export default homelang;