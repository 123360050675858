const headerlang =
{
    "en": {
        "ContactUs": "Contact Us",
        "Enquiry": "Enquiry",
        "HelpFAQGlossary": "Help-FAQ / glossary",
        "Newsletter": "Newsletter",
        "Career": "Career",
        "Helps": "Help",
        "Knowledgecenter": "Useful information",
        "CustomerAdvisory": "Customer Advisory",
        "QuotationTermsConditions": "Quotation Terms Conditions",
        "BookingConfirmationclause": "Booking Confirmation clause",
        "BLTermsConditions": "B/L Terms & Conditions",
        "SEAWAYBILL": "Sea Waybill Terms & Conditions",
        "GslgoTermsConditions": "GSL GO Terms & Conditions",
        "eGSLTermsConditions": "eGSL Terms & Conditions",
        "Termsofuse": "Terms of use",
        "Schedules": "Schedules",
        "PointtoPoint": "Point to Point",
        "SchedulebyLine": "Schedule by Line",
        "SubscribeSchedules": "Subscribe Schedules",
        "Tools": "Tools",
        "TrackaShipment": "Track a Shipment",
        "TariffCalculator": "Tariff Calculator",
        "TareWeightInquiry": "Tare Weight Inquiry",
        "SubmitVGM": "Submit VGM",
        "VGMMassUpload": "VGM Mass Upload",
        "SOBIndiaOnly": "SOB - India Only!",
        "CarShippingQuote": "Car Shipping Quote (RORO)",
        "RequestQuotation": "Request Quotation",
        "BookingSubmission": "Booking Submission",
        "SISubmission": "SI Submission",
        "RegistereBLService": "Register eB/L Service",
        "PrintBLService": "Print B/L Service",
        "GSLGOQuoteBookGo": "GSL GO – Quote. Book. Go.",
        "CargoServices": "Cargo Services",
        "CargoCatalogue": "Cargo Catalogue",
        "GSLMonitor": "GSL Monitor",
        "DryCargo": "Dry Cargo",
        "ReeferCargo": "Reefer Cargo",
        "ProjectCargo": "Project Cargo",
        "InlandTransport": "Inland Transport",
        "TemperatureControlled": "Temperature Controlled",
        "DangerousCargo": "Dangerous Cargo",
        "ValuableCargo": "Valuable Cargo",
        "AboutGSL": "About GSL",
        "CompanyProfile": "Company Profile",
        "CompanyHistory": "Company History",
        "FactsFigures": "Facts & Figures",
        "LocalInfo": "Local Info",
        "DemurrageandDetentionTariff": "Demurrage and Detention Tariff",
        "LocalInformation": "Local Information",
        "Chinese": "繁體中文 (Traditional Chinese)",
        "CN": "CN",
        "Global": "GLOBAL",
        "GlobalHongKongSARChina": "Global Hong Kong SAR,China",
        "India": "India",
        "Japan": "Japan",
        "Malaysia": "Malaysia",
        "Nigeria": "Nigeria",
        "NorthChina": "North China",
        "Singapore": "Singapore",
        "SouthKorea": "South Korea",
        "Southchina": "South China",
        "Taiwan": "Taiwan, China",
        "Thailand": "Thailand",
        "Vietnam": "Vietnam",
        "Indonesia": "Indonesia",
        "Bangladesh": "Bangladesh",
        "Australia": "Australia",
        "new_zealand": " New Zealand",
        "Usefulinformation": "Useful information",
        
        
       
    },
    "cn": {
        "ContactUs": "聯絡我們",
        "Enquiry": "查詢",
        "HelpFAQGlossary": "常見問題協助 / 指引",
        "Newsletter": "最新資訊",
        "Career": "招聘",
        "Helps": "協助",
        "Knowledgecenter": "知識中心",
        "CustomerAdvisory": "客戶須知",
        "QuotationTermsConditions": "報價條款",
        "BookingConfirmationclause": "確認訂艙條款",
        "BLTermsConditions": "提單條款",
        "SEAWAYBILL": "海運單條款",
        "GslgoTermsConditions": "Gslgo 條款",
        "eGSLTermsConditions": "eGSL 條款",
        "Termsofuse": "使用條款",
        "Schedules": "船期表",
        "PointtoPoint": "港口到港口",
        "SchedulebyLine": "航線船期表",
        "SubscribeSchedules": "訂閱船期表",
        "Tools": "工具",
        "TrackaShipment": "貨物追蹤",
        "TariffCalculator": "運費計算器",
        "TareWeightInquiry": "空櫃净重查詢",
        "SubmitVGM": "申報 VGM",
        "VGMMassUpload": "上傳 VGM 申報表",
        "SOBIndiaOnly": "SOB - 只供印度使用!",
        "CarShippingQuote": "車輛托運報價 (RORO)",
        "RequestQuotation": "申請報價",
        "BookingSubmission": "網上訂艙",
        "SISubmission": "網上補料",
        "RegistereBLService": "登記數碼提單服務",
        "PrintBLService": "打印提單服務",
        "GSLGOQuoteBookGo": "GSL GO – 報價和訂艙",
        "CargoServices": "貨櫃托運服務",
        "CargoCatalogue": "貨櫃分類",
        "GSLMonitor": "金星航運監察服務",
        "DryCargo": "一般貨物",
        "ReeferCargo": "冷藏貨物",
        "ProjectCargo": "工程貨物",
        "InlandTransport": "内陸運輸",
        "TemperatureControlled": "溫度調節貨物",
        "DangerousCargo": "危險貨品",
        "ValuableCargo": "貴重貨品",
        "AboutGSL": "關於金星航運",
        "CompanyProfile": "公司簡介",
        "CompanyHistory": "公司回顧",
        "FactsFigures": "記事及數據",
        "LocalInfo": "各地區資訊",
        "DemurrageandDetentionTariff": "貨櫃倉儲價目表",
        "LocalInformation": "各地區資訊",
        "headerLang": "繁體中文",
        "CN": "CN",
        "Global": "全球的",
        "GlobalHongKongSARChina": "全球 中國香港特別行政區",
        "India": "印度",
        "Japan": "日本語",
        "Malaysia": "馬來西亞",
        "Nigeria": "尼日利亞",
        "Singapore": "新加坡",
        "SouthKorea": "韓國",
        "Taiwan": "中國, 台灣",
        "Thailand": "泰國",
        "Vietnam": "越南",
        "Indonesia": "印度尼西亞",
        "Bangladesh": "孟加拉",
        "Australia": "Australia",
        "NorthChina": "North China",
        "Southchina": "South China",
        "new_zealand": "New Zealand",
        "Usefulinformation": "有用資訊",
  
    },
    
    "zh": {
        "ContactUs": "联络我们",
        "Enquiry": "查询",
        "HelpFAQGlossary": "常见问题协助 / 指引",
        "Newsletter": "最新资讯",
        "Career": "招聘",
        "Helps": "协助",
        "Knowledgecenter": "知识中心",
        "CustomerAdvisory": "客户须知",
        "QuotationTermsConditions": "报价条款",
        "BookingConfirmationclause": "确认订舱条款",
        "BLTermsConditions": "提单条款",
        "SEAWAYBILL": "海运单条款",
        "GslgoTermsConditions": "Gslgo 条款",
        "eGSLTermsConditions": "eGSL 条款",
        "Termsofuse": "使用条款",
        "Schedules": "船期表",
        "PointtoPoint": "港口到港口",
        "SchedulebyLine": "航线船期表",
        "SubscribeSchedules": "订阅船期表",
        "Tools": "工具",
        "TrackaShipment": "货物追踪",
        "TariffCalculator": "运费计算器",
        "TareWeightInquiry": "空箱净重查询",
        "SubmitVGM": "申报 VGM",
        "VGMMassUpload": "上传批量 VGM",
        "SOBIndiaOnly": "SOB - 只供印度使用!",
        "CarShippingQuote": "车辆托运报价 (RORO)",
        "RequestQuotation": "申请报价",
        "BookingSubmission": "网上订舱",
        "SISubmission": "网上补料",
        "RegistereBLService": "登记数码提单服务",
        "PrintBLService": "打印提单服务",
        "GSLGOQuoteBookGo": "GSL GO – 报价和订舱",
        "CargoServices": "集装箱托运服务",
        "CargoCatalogue": "集装箱分类",
        "GSLMonitor": "金星航运监察服务",
        "DryCargo": "一般货物",
        "ReeferCargo": "冷藏货物",
        "ProjectCargo": "工程货物",
        "InlandTransport": "内陆运输",
        "TemperatureControlled": "温度控制",
        "DangerousCargo": "危险货品",
        "ValuableCargo": "贵重货品",
        "AboutGSL": "关于金星航运",
        "CompanyProfile": "公司简介",
        "CompanyHistory": "公司回顾",
        "FactsFigures": "记事及数据",
        "LocalInfo": "各地区资讯",
        "DemurrageandDetentionTariff": "集装箱仓储价目表",
        "LocalInformation": "各地区资讯",
        "Chinese": "Chinese",
        "CN": "CN",
        "Global": "全球各地",
        "GlobalHongKongSARChina": "全球 中国香港特别行政区",
        "India": "印度",
        "Japan": "日本語",
        "Malaysia": "马来西亚",
        "Nigeria": "尼日尼亚",
        "Singapore": "星加坡",
        "SouthKorea": "南韩",
        "Taiwan": "中国 台湾",
        "Thailand": "泰国",
        "Vietnam": "越南",
        "headerLang": "简体中文",
        "Indonesia": "印度尼西亚",
        "Bangladesh": "孟加拉",
        "Australia": "Australia",
        "NorthChina": "North China",
        "Southchina": "South China",
        "new_zealand": "New Zealand",
        "Usefulinformation": "有用资讯",
  
    },
    "ko": {
        "ContactUs": "연락처",
        "Enquiry": "문의",
        "HelpFAQGlossary": "Help-FAQ / 용어사전",
        "Newsletter": "소식지(Newsletter)",
        "Career": "커리어",
        "Helps": "도움",
        "Knowledgecenter": "지식센터",
        "CustomerAdvisory": "고객에 유용한 정보",
        "QuotationTermsConditions": "견적 이용 약관",
        "BookingConfirmationclause": "예약(부킹) 확인 및 약관",
        "BLTermsConditions": "B/L 약관",
        "SEAWAYBILL": "Sea Waybill Terms & Conditions",
        "GslgoTermsConditions": "GSLGo 이용 약관",
        "eGSLTermsConditions": " eGSL 이용 약관",
        "Termsofuse": "이용 약관",
        "Schedules": "스케쥴",
        "PointtoPoint": "항구별 검색",
        "SchedulebyLine": "노선별 스케쥴",
        "SubscribeSchedules": "스케줄 구독",
        "Tools": "도구",
        "TrackaShipment": "화물 추적",
        "TariffCalculator": "운임 계산기",
        "TareWeightInquiry": "용기(컨테이너) 무게 문의",
        "SubmitVGM": "VGM 제출",
        "VGMMassUpload": "VGM 일괄 제출",
        "SOBIndiaOnly": "SOB - 인도 전용!",
        "CarShippingQuote": "자동차 선적 견적",
        "RequestQuotation": "견적 요청",
        "BookingSubmission": "예약(부킹) 제출",
        "SISubmission": "선적 지시서 제출",
        "RegistereBLService": "eB/L 등록 서비스",
        "PrintBLService": "B/L 출력 서비스",
        "GSLGOQuoteBookGo": "GSL Go - 견적, 예약(부킹) 이동",
        "CargoServices": "화물 서비스",
        "CargoCatalogue": "화물 목록",
        "GSLMonitor": "GSL Monitor",
        "DryCargo": "일반(건) 화물",
        "ReeferCargo": "냉동 화물",
        "ProjectCargo": "프로젝트 화물(Project Cargo)",
        "InlandTransport": "내륙 운송",
        "TemperatureControlled": "온도 조절",
        "DangerousCargo": "위험 화물",
        "ValuableCargo": "고가 화물",
        "AboutGSL": "GSL 소개",
        "CompanyProfile": "회사 이력",
        "CompanyHistory": "회사 연혁",
        "FactsFigures": "상세 정보",
        "LocalInfo": "지역별 정보",
        "DemurrageandDetentionTariff": "체화료",
        "LocalInformation": "지역별 정보",
        "Chinese": "Chinese",
        "CN": "CN",
        "Global": "언어",
        "GlobalHongKongSARChina": "중국 홍콩",
        "India": "인도",
        "Japan": "일본",
        "Malaysia": "말레이시아",
        "Nigeria": "나이지리아",
        "Singapore": "싱카폴",
        "SouthKorea": "한국어",
        "Taiwan": "대만",
        "Thailand": "태국",
        "Vietnam": "베트남",
        "headerLang": "한국어",
        "Indonesia": "인도네시아",
        "Bangladesh": "방글라데시",
        "Australia": "Australia",
        "NorthChina": "North China",
        "Southchina": "South China",
        "new_zealand": "New Zealand",
        "Usefulinformation": "유용한 정보",
  
    },
    "vi": {
        "ContactUs": "Liên hệ chúng tôi",
        "Enquiry": "Yêu cầu",
        "HelpFAQGlossary": "Trợ giúp - Câu hỏi thường gặp / Thuật ngữ",
        "Newsletter": "Bản tin",
        "Career": "Cơ hội nghề nghiệp",
        "Helps": "Trợ giúp",
        "Knowledgecenter": "Trung tâm kiến thức",
        "CustomerAdvisory": "Tư vấn khách hàng",
        "QuotationTermsConditions": "Điều khoản và điều kiện báo giá",
        "BookingConfirmationclause": "Điều khoản xác nhận đặt chỗ",
        "BLTermsConditions": "Điều khoản và điều kiện B/L",
        "SEAWAYBILL": "Sea Waybill Terms & Conditions",
        "GslgoTermsConditions": "Điều khoản và điều kiện GSLGo",
        "eGSLTermsConditions": "Điều khoản và điều kiện eGSL",
        "Termsofuse": "Điều khoản sử dụng",
        "Schedules": "Lịch tàu",
        "PointtoPoint": "Lịch tàu theo Điểm đi - Điểm đến",
        "SchedulebyLine": "Lịch tàu theo tuyến",
        "SubscribeSchedules": "Theo dõi lịch tàu",
        "Tools": "Công cụ",
        "TrackaShipment": "Theo dõi lô hàng",
        "TariffCalculator": "Tính biểu phí",
        "TareWeightInquiry": "Yêu cầu về trọng lượng vỏ",
        "SubmitVGM": "Khai báo VGM",
        "VGMMassUpload": "Khai báo VGM số lượng nhiều",
        "SOBIndiaOnly": "SOB - Chỉ dành cho Ấn Độ",
        "CarShippingQuote": "Báo giá vận chuyển xe hơi (RORO)",
        "RequestQuotation": "Yêu cầu báo giá",
        "BookingSubmission": "Đặt chỗ",
        "SISubmission": "Gửi chi tiết vận đơn",
        "RegistereBLService": "Đăng ký dịch vụ eB/L",
        "PrintBLService": "Dịch vụ in B/L",
        "GSLGOQuoteBookGo": "GSL GO - Chào giá. Đặt chỗ. Lên đường",
        "CargoServices": "Các dịch vụ hàng hóa",
        "CargoCatalogue": "Danh mục hàng hóa",
        "GSLMonitor": "GSL Monitor",
        "DryCargo": "Hàng khô",
        "ReeferCargo": "Hàng lạnh",
        "ProjectCargo": "Hàng dự án",
        "InlandTransport": "Vận chuyển nội địa",
        "TemperatureControlled": "Kiểm soát nhiệt độ",
        "DangerousCargo": "Hàng nguy hiểm",
        "ValuableCargo": "Hàng giá trị cao",
        "AboutGSL": "Về GSL",
        "CompanyProfile": "Hồ sơ công ty",
        "CompanyHistory": "Lịch sử công ty",
        "FactsFigures": "Sự kiện & Số liệu",
        "LocalInfo": "Thông tin đại lý",
        "DemurrageandDetentionTariff": "Biểu phí lưu container, lưu bãi",
        "LocalInformation": "Thông tin đại lý",
        "Chinese": "Chinese",
        "CN": "CN",
        "Global": "Toàn cầu",
        "GlobalHongKongSARChina": "Toàn cầu - Đặc khu hành chính Hong Kong - Trung Quốc",
        "India": "Ấn Độ",
        "Japan": "Nhật Bản",
        "Malaysia": "Malaysia",
        "Nigeria": "Nigeria",
        "Singapore": "Singapore",
        "SouthKorea": "Hàn Quốc",
        "Taiwan": "Đài Loan",
        "Thailand": "Thái Lan",
        "Vietnam": "Việt Nam",
        "headerLang": "Tiếng Việt",
        "Indonesia": "Indonesia",
        "Bangladesh": "Bangladesh",
        "Australia": "Australia",
        "NorthChina": "North China",
        "Southchina": "South China",
        "new_zealand": "New Zealand",
        "Usefulinformation": "Thông tin hữu ích",
  
    },
    "th": {
        "ContactUs": "ติดต่อเรา",
        "Enquiry": "ติดต่อสอบถาม",
        "HelpFAQGlossary": "คำถามที่พบบ่อย / อภิธานศัพท์",
        "Newsletter": "ข่าวและความเคลื่อนไหว",
        "Career": "ร่วมงานกับเรา",
        "Helps": "บริการช่วยเหลือ",
        "Knowledgecenter": "ศูนย์ความรู้",
        "CustomerAdvisory": "ประกาศ",
        "QuotationTermsConditions": "ข้อตกลงและเงื่อนไขใบเสนอราคา",
        "BookingConfirmationclause": "ข้อยืนยันการจอง",
        "BLTermsConditions": "ข้อตกลงและเงื่อนไขการใช้งาน B/L",
        "SEAWAYBILL": "Sea Waybill Terms & Conditions",
        "GslgoTermsConditions": "ข้อตกลงและเงื่อนไขการใช้งาน Gslgo",
        "eGSLTermsConditions": "ข้อตกลงและเงื่อนไขการใช้งาน eGSL",
        "Termsofuse": "ข้อกำหนดการใช้งาน",
        "Schedules": "ตารางเรือ",
        "PointtoPoint": "สถานที่ต้นทาง ถึง สถานที่ปลายทาง",
        "SchedulebyLine": "ตารางเรือโดยเส้นทางบริการ",
        "SubscribeSchedules": "ติดตามตารางเรือ",
        "Tools": "เครื่องมือ",
        "TrackaShipment": "ตรวจสอบสถานะสินค้า",
        "TariffCalculator": "คำนวณค่าขนส่ง",
        "TareWeightInquiry": "สอบถามน้ำหนักตู้สินค้า",
        "SubmitVGM": "ส่ง VGM",
        "VGMMassUpload": "อัปโหลดVGM Mass",
        "SOBIndiaOnly": "SOB - India Only!",
        "CarShippingQuote": "ใบเสนอราคาสำหรับบริการเรือบรรทุกรถยนต์",
        "RequestQuotation": "ขอใบเสนอราคา",
        "BookingSubmission": "จองระวางเรือ",
        "SISubmission": "ส่ง SI",
        "RegistereBLService": "สมัครใช้บริการ eB/L",
        "PrintBLService": "บริการพิมพ์ B/L",
        "GSLGOQuoteBookGo": "GSL GO – Quote. Book. Go.",
        "CargoServices": "บริการขนส่งสินค้า",
        "CargoCatalogue": "แคตตาล็อกสินค้า",
        "GSLMonitor": "GSL Monitor",
        "DryCargo": "สินค้าทั่วไป",
        "ReeferCargo": "สินค้าควบคุมอุณหภูมิ",
        "ProjectCargo": "สินค้าโครงการ",
        "InlandTransport": "การขนส่งทางบก",
        "TemperatureControlled": "การควบคุมอุณหภูมิ",
        "DangerousCargo": "สินค้าอันตราย",
        "ValuableCargo": "สินค้ามูลค่าสูง",
        "AboutGSL": "รู้จัก GSL",
        "CompanyProfile": "ข้อมูลบริษัท",
        "CompanyHistory": "ประวัติบริษัท",
        "FactsFigures": "ข้อมูลและตัวเลข",
        "LocalInfo": "ข้อมูล - Local Info",
        "DemurrageandDetentionTariff": "Demurrage and Detention Tariff",
        "LocalInformation": "ข้อมูล - Local Information",
        "Chinese": "Chinese",
        "CN": "CN",
        "Global": "ทั่วโลก",
        "GlobalHongKongSARChina": "ฮ่องกง",
        "India": "อินเดีย",
        "Japan": "ญี่ปุ่น",
        "Malaysia": "มาเลเซีย",
        "Nigeria": "ไนจีเรีย",
        "Singapore": "สิงคโปร์",
        "SouthKorea": "เกาหลีใต้",
        "Taiwan": "ไต้หวัน",
        "Thailand": "ไทย",
        "Vietnam": "เวียดนาม",
        "headerLang": "ไทย",
        "Indonesia": "อินโดนีเซีย",
        "Bangladesh": "บังกลาเทศ",
        "Australia": "Australia",
        "NorthChina": "North China",
        "Southchina": "South China",
        "new_zealand": "New Zealand",
        "Usefulinformation": "ข้อมูลน่ารู้",
  
    },
    "in": {
        "ContactUs": "Hubungi Kami",
        "Enquiry": "Pertanyaan",
        "HelpFAQGlossary": "Bantuan-FAQ / glosarium",
        "Newsletter": "Buletin",
        "Career": "Karier",
        "Helps": "Bantuan",
        "Knowledgecenter": "Pusat Pengetahuan",
        "CustomerAdvisory": "Penasehat Pelanggan",
        "QuotationTermsConditions": "Syarat Ketentuan Penawaran",
        "BookingConfirmationclause": "Klausa Konfirmasi Pemesanan",
        "BLTermsConditions": "Syarat dan Ketentuan B/L",
        "SEAWAYBILL": "Sea Waybill Terms & Conditions",
        "GslgoTermsConditions": "Syarat dan Ketentuan Gslgo",
        "eGSLTermsConditions": " Syarat dan Ketentuan eGSL",
        "Termsofuse": "Syarat Penggunaan",
        "Schedules": "Jadwal",
        "PointtoPoint": "Poin ke poin",
        "SchedulebyLine": "Jadwal per Jalur",
        "SubscribeSchedules": "Berlangganan Jadwal",
        "Tools": "Peralatan",
        "TrackaShipment": "Lacak Pengiriman",
        "TariffCalculator": "Perhitungan Tarif",
        "TareWeightInquiry": "Pertanyaan Berat Tara",
        "SubmitVGM": "Mengajukan VGM",
        "VGMMassUpload": "Unggahan massal VGM",
        "SOBIndiaOnly": "SOB - hanya India",
        "CarShippingQuote": "Penawaran Pengapalan Mobil (RORO)",
        "RequestQuotation": "Permintaan Penawaran",
        "BookingSubmission": "Pengajuan Pemesanan",
        "SISubmission": "Pengajuan SI",
        "RegistereBLService": "Pelayanan Registrasi eB/L",
        "PrintBLService": "Pelayanan Cetak B/L",
        "GSLGOQuoteBookGo": "GSL GO -Penawaran. Pesan Kirim",
        "CargoServices": "Pelayanan Kargo",
        "CargoCatalogue": "Katalog Kargo",
        "GSLMonitor": "Pemantauan GSL",
        "DryCargo": "Kargo Dry",
        "ReeferCargo": "Kargo Reefer",
        "ProjectCargo": "Kargo Project",
        "InlandTransport": "Transportasi Darat",
        "TemperatureControlled": "Pengaturan Temperatur",
        "DangerousCargo": "Kargo Berbahaya",
        "ValuableCargo": "Kargo Bernilai",
        "AboutGSL": "Tentang GSL",
        "CompanyProfile": "Profil Perusahaan",
        "CompanyHistory": "Sejarah Perusahaan",
        "FactsFigures": "Fakta & Figur",
        "LocalInfo": "Info lokal",
        "DemurrageandDetentionTariff": "Tarif Demurrage dan Detention",
        "LocalInformation": " Informasi lokal",
        "Chinese": "Chinese",
        "CN": "CN",
        "Global": "Global",
        "GlobalHongKongSARChina": "GlobalHongKongSARChina",
        "India": "India",
        "Japan": "Japan",
        "Malaysia": "Malaysia",
        "Nigeria": "Nigeria",
        "Singapore": "Singapura",
        "SouthKorea": "Korea Selatan",
        "Taiwan": "Taiwan",
        "Thailand": "Thailand",
        "Vietnam": "Vietnam",
        "headerLang": "Bahasa",
        "Indonesia": "Indonesia",
        "Bangladesh": "Bangladesh",
        "Australia": "Australia",
        "NorthChina": "North China",
        "Southchina": "South China",
        "new_zealand": "New Zealand",
        "Usefulinformation": "Informasi yang berguna",
  
    },
    "ja": {
        "ContactUs": "連絡先",
        "Enquiry": "照会",
        "HelpFAQGlossary": "ヘルプ- FAQ/用語集",
        "Newsletter": "メールマガジン",
        "Career": "採用情報",
        "Helps": "ヘルプ",
        "Knowledgecenter": "ナレッジセンター",
        "CustomerAdvisory": "お客様相談室",
        "QuotationTermsConditions": "見積約款",
        "BookingConfirmationclause": "予約確認条項",
        "BLTermsConditions": "B/L約款",
        "SEAWAYBILL": "Sea Waybill Terms & Conditions",
        "GslgoTermsConditions": "Gslgo約款",
        "eGSLTermsConditions": "eGSL約款",
        "Termsofuse": "利用規約",
        "Schedules": "スケジュール",
        "PointtoPoint": "ポイント・トゥ・ポイント",
        "SchedulebyLine": "航路別スケジュール",
        "SubscribeSchedules": "スケジュールを購読",
        "Tools": "ツール",
        "TrackaShipment": "貨物を追跡する",
        "TariffCalculator": "運送料計算機",
        "TareWeightInquiry": "風袋重量照会",
        "SubmitVGM": "VGMを送信",
        "VGMMassUpload": "VGM一括アップロード",
        "SOBIndiaOnly": "SOB－インド限定",
        "CarShippingQuote": "自動車配送見積書（RORO）",
        "RequestQuotation": "見積を依頼",
        "BookingSubmission": "予約の送信",
        "SISubmission": "SIの送信",
        "RegistereBLService": "eB/Lサービスを登録",
        "PrintBLService": "B/Lサービスを印刷",
        "GSLGOQuoteBookGo": "GSL GO–見積もり、予約、出発",
        "CargoServices": "貨物サービス",
        "CargoCatalogue": "貨物目録",
        "GSLMonitor": "GSLモニタ",
        "DryCargo": "ドライカーゴ",
        "ReeferCargo": "リーファーカーゴ",
        "ProjectCargo": "プロジェクト貨物",
        "InlandTransport": "内陸輸送",
        "TemperatureControlled": "温度制御",
        "DangerousCargo": "危険貨物",
        "ValuableCargo": "貴重品",
        "AboutGSL": "GSLについて",
        "CompanyProfile": "会社概要",
        "CompanyHistory": "会社沿革",
        "FactsFigures": "詳細",
        "LocalInfo": "地元情報",
        "DemurrageandDetentionTariff": "滞貨・滞船料",
        "LocalInformation": "地元情報",
        "Chinese": "繁體中文 (Traditional Chinese)",
        "CN": "CN",
        "Global": "グローバル",
        "GlobalHongKongSARChina": "グローバル・香港特別自治区・中国",
        "India": "インド",
        "Japan": "日本語",
        "Malaysia": "マレーシア",
        "Nigeria": "ナイジェリア",
        "Singapore": "シンガポール",
        "SouthKorea": "韓国",
        "Taiwan": "台湾",
        "Thailand": "タイ",
        "Vietnam": "ベトナム",
        "headerLang": "日本語",
        "Indonesia": "インドネシア",
        "Bangladesh": "バングラデシュ",
        "Australia": "Australia",
        "NorthChina": "North China",
        "Southchina": "South China",
        "new_zealand": "New Zealand",
        "Usefulinformation": "便利な情報",
       
       
    },
}
export default headerlang;