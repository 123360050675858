import React, { Component, useEffect, useState } from "react";
import Layout from "../../../components/layout";
import Api from "../../../../package.json";
import * as axios from "axios";
import { Redirect } from 'react-router'
import { useHistory } from "react-router-dom";


const qs = require("qs");
var jwt = require("jsonwebtoken");

const Get_ip = (props) => {
  const [country_code, setCountryCode] = useState(window.$country_code);
  const [ip, setIP] = useState('');
  const [url, seturl] = useState("");
  const [url_status, seturl_status] = useState(false);
  let history = useHistory()
  const getData = async () => {
    var type =  props.match.params.id;
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    const response_si_booking_redirect_url = await axios.post(
      Api.userdata.url + "get_si_booking_redirect_url",
      qs.stringify({ id: res.data.country_code,type:type }, { parseArrays: false }),
    );
    const json_ebl_captcha = await response_si_booking_redirect_url.data;
    if(json_ebl_captcha["status"] == 0)
    {
        alert(props.match.params.id);
        seturl(type);
        alert(url)
    }
    else{
      seturl(json_ebl_captcha["message"]['url']);
      seturl_status(true);
    }
    alert(url_status);
    
  }
  useEffect(() => {
   const getDatas= async () => {
      var type =  props.match.params.id;
      const res = await axios.get('https://geolocation-db.com/json/')
      console.log(res.data);
      const response_si_booking_redirect_url = await axios.post(
        Api.userdata.url + "get_si_booking_redirect_url",
        qs.stringify({ id: res.data.country_code,type:type }, { parseArrays: false }),
      );
      const json_ebl_captcha = await response_si_booking_redirect_url.data;
      if(json_ebl_captcha["status"] == 0)
      {
         // alert(props.match.params.id);
          seturl(type);
         // alert(type)
          //history.push('/'+type)
      }
      else{
        seturl(json_ebl_captcha["message"]['url']);
        //history.push(json_ebl_captcha["message"]['url'])
        seturl_status(true);
      }
     // alert(url_status);
      
    }
    getDatas();
  }, [url,props,history]);


  return (
    <div>
      {url_status ? (
        <Redirect to={url} /> 
      ) : (
        <Redirect to={"/"+url}/>
      )}
     </div>
  );
};
export default Get_ip;
