import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import Inland from '../../../../src/assets/Inland.jpeg';
import abt_icn from '../../../../src/assets/abt-icn.png';
import Inner_container from '../../innerpages/inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import who_we_are from '../../../../src/assets/who-we-are.png';
import GoToTop from '../../../components/go-to-top';
import abt_mission_ic from "../../../assets/abt-mission-ic.png";
import abt_who_ic from "../../../assets/abt-who-ic.png";
import abt_who_bg from "../../../assets/abt-who-bg.png";
import mission_sec from "../../../assets/mission-sec.png";
import about_us_bg from "../../../assets/about-us-bg.png";
import services_1 from "../../../assets/services-1.png";
import sailing_schedule_ic from "../../../assets/sailing-schedule-ic.png";
import services_2 from "../../../assets/services-2.png";
import equipm_ic from "../../../assets/equipm-ic.png";
import services_3 from "../../../assets/services-3.png";
import port_cd_ic from "../../../assets/port-cd-ic.png";
import services_4 from "../../../assets/services-4.png"
import rotati_ic from "../../../assets/rotati-ic.png";
import company_news_bg from "../../../assets/company-news-bg.png";
import view_our_mission_lang from '../../lang/view_our_mission';
import about from '../../../../src/assets/inner-images/about/about.jpg';
import '../about_gsl/about.css';


const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class View_our_mission extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {
            country_code: window.$country_code,
            mission: [],
            about_us_banner: [],

        };

    }
    async componentDidMount() {
        var lang = window.localStorage.getItem("lang");
       
       
        const headers = {
            type: "application/json",
            Authorization: `Bearer `+window.localStorage.getItem('access_token'),
          };

        const response = await axios.post(Api.userdata.url + '/mission', qs.stringify({ 'token': '2','lang':lang }, { parseArrays: false }), { headers: headers });
        const json = await response.data;
        this.setState({ mission: json['message'][0] });

        const about_us_banner_response = await axios.post(Api.userdata.url + '/about_us_banner', qs.stringify({ 'token': '2' }, { parseArrays: false }), { headers: headers });
        const about_us_banner_json = await about_us_banner_response.data;
        this.setState({ about_us_banner: about_us_banner_json['message'] });


    }
    render() {
        var lang = window.localStorage.getItem("lang");
        return (

            <Layout>
                <Inner_container innerpage="about_gsl" />

                <section class="inner-center">
                    <div class="container">


                        <div class="cargo-inner cargo-des">
                            <div class="row ml-0 mr-0 mt-3 mb-3 ">
                                <div class=" col-sm-12 col-md-12 col-lg-12">
                                    <div class="abt-text-con">
                                       
                                        <h3>{view_our_mission_lang[lang].OURMISSION}</h3>
                                    </div>
                                    <section class="">
                                        <div class="slider2 wow fadeInDown" data-wow-delay=".4s">
                                        <div class=" dat-relat who-wee-image wware-imggg" style={{ backgroundImage: `url(` + about + `)` }}>
                                            </div>
                                        </div>
                                    </section>


                                </div>
                                <div class="col-lg-12 col-sm-12 col-md-12 our-mis-pad" >

                                    <p class="text-justify">{this.state.mission['ourmission']}</p>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>

                <Packages />
                <GET_IN_TOUCH /> <GoToTop />
            </Layout >);
    }
}