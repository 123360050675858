import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import Inland from '../../../../src/assets/Inland.jpeg';
import abt_icn from '../../../../src/assets/abt-icn.png';
import Inner_container from '../../innerpages/inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';


const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class temperature_controlled_shipping extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            gsl_monitor_content: [],

        };

    }
    async componentDidMount() {
        var lang = window.localStorage.getItem("lang");
        const headers = {
           
            type: "application/json",
            Authorization: `Bearer `+window.localStorage.getItem('access_token'),
          };
        const response = await axios.post(Api.userdata.url + '/gsl_monitor', qs.stringify({ 'id': 'Temp','lang':lang }, { parseArrays: false }), { headers: headers });
        const json = await response.data;
        this.setState({ gsl_monitor_content: json['message'][0] });

    }
    render() {
        return (

            <Layout>
                <Inner_container innerpage="temperature_controlled_shipping" />
                

                <section class="inner-center n-40 temperature_controlled_shipping">
                    <div class="container-fluid col-lg-10">
                        <div dangerouslySetInnerHTML={{ __html: this.state.gsl_monitor_content['description'] }} ></div>


                    </div>


                </section>
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout>);
    }
}