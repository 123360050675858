import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import Services from "../../../components/get_in_touch/services";
import Packages from "../../../components/get_in_touch/packages_block";
import Inland from "../../../../src/assets/Inland.jpeg";
import abt_icn from "../../../../src/assets/abt-icn.png";
import Inner_container from "../../innerpages/inner-container";
import Api from "../../../../package.json";
import Moment from "moment";
import * as axios from "axios";
import swal from "sweetalert";
import address_new from "../../../../src/assets/address-new.png";

//import './header.css';
import phone_ic from "../../../../src/assets/phone-ic.png";
import addr_at from "../../../../src/assets/addr-at.png";
import { Carousel } from "react-responsive-carousel";
import who_we_are from "../../../../src/assets/who-we-are.png";
import inside_heading from "../../../../src/assets/inside-heading.png";
import { Button, Modal } from "react-bootstrap";
import "react-accessible-accordion/dist/fancy-example.css";
import { useForm } from "react-hook-form";
import enquiry_lang from '../../lang/enquiry';
import HCaptcha from "@hcaptcha/react-hcaptcha";

const qs = require("qs");
var jwt = require("jsonwebtoken");
const Enquiry = () => {
  var lang = window.localStorage.getItem("lang");
  if (window.localStorage.getItem("lang") == null) {
    lang = 'en';
    window.localStorage.setItem("lang", 'en');
  }
  const headers = {
    type: "application/json",
    Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
  };
  const [country_code, setCountryCode] = useState(window.$country_code);
  const [header_json_content, setHeaderJsonContent] = useState([]);
  const [captcha, setHeadercaptcha] = useState([]);
  const [SubmitButton, setSubmitButton] = useState(enquiry_lang[lang].SENDMESSAGE);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const buttonRef = useRef();
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const onSubmit = (data) => {
    buttonRef.current.disabled = true;
    setSubmitButton(enquiry_lang[lang].Submit+'...');
    save_enquiry(data);
  };
  const save_enquiry = async (data) => {
    if(token != null)
    {
      const response = await axios.post(
        Api.userdata.url + "/save_enquiry",
        qs.stringify({
          country_code: country_code,
          ename: data.ename,
          email: data.email,
          phoneone: data.phoneone,
          mobile: data.mobile,
          message: data.message,
          enquiry_captcha: token,
        }), { headers: headers }
      );
      const json = await response.data;
      if (json["status"] == "OK") {
        swal("Successful Submitted", json["message"], "success").then((value) => {
          window.location.reload(false);
        });
      } else {
        swal("Error !", json["message"], "error");
      }
    }else{
      swal("Error !", "Invalid captcha", "error");
    }
    
    buttonRef.current.disabled = false;
    setSubmitButton(enquiry_lang[lang].Submit);
  };
  const getLoadData = async () => {
    var lang = window.localStorage.getItem('lang');
    const response = await axios.post(
      Api.userdata.url + "/header_contents",
      qs.stringify({ country_code: country_code,lang:lang }, { parseArrays: false }), { headers: headers }
    );
    const json = await response.data;
    var jsval = JSON.parse(json["message"][0]["details"]);
    setHeadercaptcha(json["captcha"]);
    setHeaderJsonContent(jsval);
  };
  useEffect(() => {
    getLoadData();
  }, []);
  return (
    <Layout>
      <Inner_container innerpage="enquiry" />
      <section className="inner-center n-40">
        <section className="get-intouch">
          <div className="container contact-box">
            <div className="row">
              <div className="contact-section-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="contact-address col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                  <div className="contact-ic-bg">
                    <img src={address_new} />
                  </div>
                  <div className="contact-content">
                    <h6>{enquiry_lang[lang].ADDRESS}</h6>
                    <p>{header_json_content.address}</p>
                  </div>
                </div>
                <div className="contact-address col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                  <div className="contact-ic-bg">
                    <img src={phone_ic} />
                  </div>
                  <div className="contact-content">
                    <h6>{enquiry_lang[lang].PHONE}</h6>
                    <p>{header_json_content.contact}</p>
                  </div>
                </div>
                <div className="contact-address col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                  <div className="contact-ic-bg">
                    <img src={addr_at} />
                  </div>
                  <div className="contact-content">
                    <h6>{enquiry_lang[lang].EMAIL}</h6>
                    <p>{header_json_content.sales_email}</p>
                  </div>
                </div>
              </div>
              <div className="contact-section-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <form
                  id="enquire_common"
                  method="POST"
                  name="enquire_common"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div class="form-row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 form-list">
                      <input
                        name="ename"
                        type="text"
                        class="form-control form-bg"
                        placeholder="Name"
                        {...register("ename", {
                          required: {
                            value: true,
                            message: "Please enter your full name",
                          },
                        })}
                      />
                      {errors.ename && (
                        <span class="errors">{errors.ename.message}</span>
                      )}
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 form-list">
                      <input
                        name="email"
                        type="text"
                        class="form-control form-bg"
                        placeholder="Email"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Enter your email-id",
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors.email && (
                        <span class="errors">{errors.email.message}</span>
                      )}
                    </div>
                    <div class="country-codes col-xl-12 col-lg-12 col-md-12 col-12 form-list">
                      {/* <label for="code-id" class="phone-code">+</label> */}
                      <input
                        name="phoneone"
                        type="text"
                        class="form-control code-input form-bg"
                        placeholder={header_json_content.telephone_code}
                        {...register("phoneone", {
                          required: {
                            value: true,
                            message: "*",
                          },
                          minLength: {
                            value: 1,
                            message: "Min length 1",
                          },
                          maxLength: {
                            value: 3,
                            message: "Max length 3",
                          },
                          pattern: {
                            value: /^-?[0-9]\d*\.?\d*$/i,
                            message: "Invalid",
                          },
                        })}
                      />
                      {errors.phoneone && (
                        <span class="errors">{errors.phoneone.message}</span>
                      )}
                      <input
                        name="mobile"
                        class="form-control form-bg"
                        type="text"
                        placeholder="Contact Number"
                        {...register("mobile", {
                          required: {
                            value: true,
                            message: "Please enter your mobile number",
                          },
                          minLength: {
                            value: 6,
                            message: "Invalid phone number",
                          },
                          maxLength: {
                            value: 15,
                            message: "Invalid phone number",
                          },
                          pattern: {
                            value: /^-?[0-9]\d*\.?\d*$/i,
                            message: "Invalid",
                          },
                        })}
                      />
                      {errors.mobile && (
                        <span class="errors">{errors.mobile.message}</span>
                      )}
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 form-list">
                      <textarea
                        class="form-control form-bg text-form-h"
                        placeholder="Type Your Message"
                        name="message"
                        type="text"
                        {...register("message", {
                          required: {
                            value: true,
                            message: "Please enter your request in message box",
                          },
                          minLength: {
                            value: 6,
                            message: "please provide atleast 10 char",
                          },
                        })}
                      ></textarea>
                      {errors.message && (
                        <span class="errors">{errors.message.message}</span>
                      )}
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 form-list captcha-form-list">
                    <HCaptcha
                      sitekey="a8400b17-0f2c-4116-85cb-91a16f434d49"
                      onVerify={setToken}
                      ref={captchaRef}
                      apihost="https://cn1.hcaptcha.com"
                      endpoint="https://cn1.hcaptcha.com"
                      assethost="https://assets-cn1.hcaptcha.com"
                      imghost="https://imgs-cn1.hcaptcha.com"
                      reportapi="https://reportapi-cn1.hcaptcha.com"
                    />
                    {errors.hcpactha && (
                        <span class="errors">{errors.message.hcpactha}</span>
                    )}
                    </div>
                    <button
                      type="submit"
                      className="btn btn-default hovereffect form-submit-btn"
                      ref={buttonRef}
                    >
                      {SubmitButton}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default Enquiry;
