const homelang =
{
    "en": {
        "AnnualTEUCarried": "Annual TEU Carried",
        "TotalTEUCapacity": "Total TEU Capacity",
        "OwnedandCharteredVessel": "Owned and Chartered Vessel",
        "Vessels": "Vessels",
        "PortsofCall": "Ports of Call",
        "Services": "Services",
        "Employees": "Employees",
        "Agents": "Agents",
        "StrongChinesePresence": "Strong Chinese Presence",
        "ITSystems": "IT Systems",
        
       
    },
    "cn": {
        "AnnualTEUCarried": "每年貨櫃貨運量",
        "TotalTEUCapacity": "總貨櫃處理量",
        "OwnedandCharteredVessel": "名下及包船船隻",
        "Vessels": "船隻",
        "PortsofCall": "停泊港口",
        "Services": "航綫服務",
        "Employees": "員工",
        "Agents": "代辦處",
        "StrongChinesePresence": "中國國内團隊覆蓋",
        "ITSystems": "科技系統",
        
    },
    "zh": {
        "AnnualTEUCarried": "每年货箱载运量",
        "TotalTEUCapacity": "总货箱处理量",
        "OwnedandCharteredVessel": "名下及包船船只",
        "Vessels": "船只",
        "PortsofCall": "停泊港口",
        "Services": "航线服务",
        "Employees": "员工",
        "Agents": "代办处",
        "StrongChinesePresence": "中国国内团队覆盖",
        "ITSystems": "科技系统",
        
    },
    "ko": {
        "AnnualTEUCarried": "연간 운송 TEU",
        "TotalTEUCapacity": "총 용량 TEU",
        "OwnedandCharteredVessel": "자선과 용선",
        "Vessels": "선박",
        "PortsofCall": "기항 항구",
        "Services": "서비스",
        "Employees": "종업원",
        "Agents": "지사",
        "StrongChinesePresence": "중국 서비스에 특화",
        "ITSystems": "IT 시스템",
        
    },
    "vi": {
        "AnnualTEUCarried": "Số TEU chuyên chở hàng năm",
        "TotalTEUCapacity": "Tổng năng suất chuyên chở theo TEU",
        "OwnedandCharteredVessel": "Tàu sở hữu và tàu thuê",
        "Vessels": "Số lượng tàu",
        "PortsofCall": "Số lượng cảng ghé làm hàng",
        "Services": "Số lượng tuyến dịch vụ",
        "Employees": "Số lượng nhân viên",
        "Agents": "Số lượng đại lý",
        "StrongChinesePresence": "Sự hiện diện mạnh mẽ ở Trung Quốc",
        "ITSystems": "Hệ thống công nghệ thông tin",
        
    },
    "th": {
        "AnnualTEUCarried": "กำลังการขนส่งรายปี",
        "TotalTEUCapacity": "กำลังการขนส่ง",
        "OwnedandCharteredVessel": "เรือของสายเรือและเรือเช่าเหมาลำ",
        "Vessels": "กองเรือ",
        "PortsofCall": "ท่าเทียบเรือ",
        "Services": "เส้นทางบริการ",
        "Employees": "พนักงาน",
        "Agents": "ตัวแทน",
        "StrongChinesePresence": "Strong Chinese Presence",
        "ITSystems": "ระบบเทคโนโลยีสารสนเทศ",
        
    },
    "in": {
        "AnnualTEUCarried": "TEU Tahunan Dibawa",
        "TotalTEUCapacity": "Total Kapasitas TEU",
        "OwnedandCharteredVessel": "Kapal Milik dan Sewa",
        "Vessels": "Kapal",
        "PortsofCall": "Pelabuhan Panggilan",
        "Services": "Layanan",
        "Employees": "Pekerja",
        "Agents": "Agen",
        "StrongChinesePresence": "Kehadiran China yang Kuat",
        "ITSystems": "Sistem IT",
        
       
    },

    "ja": {
        "AnnualTEUCarried": "年間TEU輸送量",
        "TotalTEUCapacity": "総TEU輸送量",
        "OwnedandCharteredVessel": "所有船とチャーター船舶",
        "Vessels": "船舶",
        "PortsofCall": "寄港地",
        "Services": "サービス内容",
        "Employees": "従業員数",
        "Agents": "代理店",
        "StrongChinesePresence": "中国の強力な影響力",
        "ITSystems": "ITシステム",
        
       
    },
}
export default homelang;