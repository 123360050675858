import React, { Component, useEffect, useState } from "react";
import AboutGsl from '../../assets/inner-images/about/about_gsl.jpg';
import companyProfile from '../../assets/inner-images/about/company_profile.jpg';
import companyHistory from '../../assets/inner-images/about/company_history.jpg';
import factsFigures from '../../assets/inner-images/about/facts_figures.jpg';
import cargoServices from '../../assets/inner-images/cargo_services/cargo_services.jpg';
import dangerousCargo from '../../assets/inner-images/cargo_services/dangerous_cargo.jpg';
import dryCargo from '../../assets/inner-images/cargo_services/dry_cargo.jpg';
import gslMonitor from '../../assets/inner-images/cargo_services/gsl_monitor.jpg';
import inlandTransport from '../../assets/inner-images/cargo_services/inland_transport.jpg';
import projectCargo from '../../assets/inner-images/cargo_services/project_cargo.jpg';
import reeferCargo from '../../assets/inner-images/cargo_services/reefer_cargo.jpg';
import temperatureControlledShipping from '../../assets/inner-images/cargo_services/temperature_controlled_shipping.jpg';
import highValueOrSensitiveCargo from '../../assets/inner-images/cargo_services/high_value_or_sensitive_cargo.jpg';
import Catalogue from '../../assets/inner-images/tools/catalogue.jpg';
import eblService from '../../assets/inner-images/tools/ebl_service.jpg';
import requestaQuote from '../../assets/inner-images/tools/request_a_quote.jpg';
import siSubmission from '../../assets/inner-images/tools/si_submission.jpg';
import bookingSubmission from '../../assets/inner-images/tools/booking_submission.jpg';
import vgmMassUpload from '../../assets/inner-images/tools/vgm_mass_upload.jpg';
import careers from '../../assets/inner-images/careers/careers.jpg';
import demurrage_and_detention_tariff from '../../assets/inner-images/local_info/demurrage_and_detention_tariff.jpg';
import local_info from '../../assets/inner-images/local_info/local_info.jpg';
import help_customer_advisory from '../../assets/inner-images/help/help_customer_advisory.jpg';
import newsletter from '../../assets/inner-images/newsletter/newsletter.jpg';
import terms_of_use from '../../assets/inner-images/help/terms_of_use.jpg';
import enquiry_us from '../../assets/inner-images/enquiry/enquiry_us.jpg';
import faq_gsl from '../../assets/inner-images/enquiry/faq_gsl.jpg';
import catalogues from '../../assets/inner-images/catalogue/catalogue.jpg';
import roro from '../../assets/inner-images/roro/roro.jpg';
import subscribe from '../../assets/inner-images/about/subscribe.jpg';
import shapeBlue from '../../../src/assets/banner-shape-blue.svg';
import shapeYellow from '../../../src/assets/banner-shape.svg';

const innerJSON =
{
    "subscribe": {
        "title": "Subscribe",
        "discription": "Receive real-time alerts for your shipment and get full visibility on the status of your cargo",
        "url": subscribe,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
        
    },
    "about_gsl": {
        "title": "Cargo Catalogue",
        "discription": "Plan your shipment according to your cargo’s specifications and needs",
        "url": AboutGsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',

    },
    "cargo_catalogue": {
        "title": "Cargo Catalogue",
        "discription": "Plan your shipment according to your cargo’s specifications and needs",
        "url": catalogues,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
        
    },
    "request_a_quote": {
        "title": "Request A Quote",
        "discription": "Our Mission and goals is to provide variety of cargo services to adore your business",
        "url": requestaQuote,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_submission": {
        "title": "Booking Submission",
        "discription": "Our Mission and goals is to provide variety of cargo services to adore your business",
        "url": bookingSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ebl_service": {
        "title": "eBL Service",
        "discription": "Our Mission and goals is to provide variety of cargo services to adore your business",
        "url": eblService,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "si_submission": {
        "title": "SI Submission",
        "discription": "Seamless submission of details of the cargo, the parties involved & requirements",
        "url": siSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ecommerce_partners": {
        "title": "Ecommerce Partners",
        "discription": "Our Mission and goals is to provide variety of cargo services to adore your business",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "vgm_mass_upload": {
        "title": "Vgm Mass Upload",
        "discription": "VGM submission for multiple shipments  made easy and familiar",
        "url": vgmMassUpload,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "high_value_or_sensitive_cargo": {
        "title": "High value or sensitive cargo",
        "discription": "GSL Monitor gives you the precise tools to track, monitor and control your valuable cargo, making sure it is delivered safely",
        "url": highValueOrSensitiveCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "enquiry": {
        "title": "Contact Us",
        "discription": "Get in touch with us! We'll be happy to give you all the information you'll need",
        "url": enquiry_us,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "faq": {
        "title": "Frequently Asked Questions",
        "discription": "Learn more about Gold Star Line and our services",
        "url": faq_gsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "roro-private": {
        "title": "Car Carrier Service",
        "discription": "Meeting the growing demand for automotive shipments across the world",
        "url": roro,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "newsletter": {
        "title": "News & Updates",
        "discription": "Stay tuned for the latest information and announcements!",
        "url": newsletter,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_newsletter": {
        "title": "View News Letter",
        "discription": "Our Mission and goals is to provide variety of cargo services to adore your business",
        "url": newsletter,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "cargo_services": {
        "title": "Cargo Services",
        "discription": "Our mission is to offer a complete range of ocean-freight services for all types of cargo.",
        "url": cargoServices,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "gsl_monitor": {
        "title": "GSL Monitor",
        "discription": "Track, monitor, and even control your cargo’s environment especially when shipping sensitive, high-value, or reefer cargo",
        "url": gslMonitor,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dry_cargo": {
        "title": "Dry Cargo",
        "discription": "With over 60 years of experience dedicated to handling any type of dry cargo, we will fulfill your needs.",
        "url": dryCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "reefer_cargo": {
        "title": "Reefer Cargo",
        "discription": "Our advanced technology ensures your reefer cargo is delivered safely, at the right temperature, and on time.",
        "url": reeferCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "project_cargo": {
        "title": "Project Cargo",
        "discription": "We have the creative solutions and expertise needed to move your special cargo safely around the world.",
        "url": projectCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "inland_transport": {
        "title": "Inland Transport",
        "discription": "Providing reliable land transportation services through our extensive door-to-door network",
        "url": inlandTransport,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "temperature_controlled_shipping": {
        "title": "Temperature Controlled Shipping",
        "discription": "Chilled or frozen, we provide safe transportation for all temperature sensitive products",
        "url": temperatureControlledShipping,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dangerous_cargo": {
        "title": "Dangerous Cargo",
        "discription": "We offer a dedicated and professional service for the safe storage and transportation of dangerous goods",
        "url": dangerousCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "about_gsl": {
        "title": "About us",
        "discription": "Gold Star Line - a major player in the Intra Asia, Oceania and Africa Trades, serving dynamic markets with an agile approach",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_profile": {
        "title": "Company Profile",
        "discription": "Our focus is providing services that best suit our customers' specific needs",
        "url": companyProfile,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_history": {
        "title": "Our Story",
        "discription": "Take a trip through time and discover Gold Star Line's history, milestones and developments",
        "url": companyHistory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "facts_figures": {
        "title": "Facts Figures",
        "discription": "Our mission is to continue finding creative solutions to support our customers' needs",
        "url": factsFigures,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "demurrage_and_detention_tariff": {
        "title": "Demurrage and Detention Tariff",
        "discription": "Demurrage, detention, and per diem fees don't have to be a mystery",
        "url": demurrage_and_detention_tariff,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "local_info": {
        "title": "Local Info",
        "discription": "Always stay up to date with useful information specific to your location",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "unsubscribe_schedule": {
        "title": "Unsubscribe Schedule",
        "discription": "",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "contact_us": {
        "title": "GSL Office Details",
        "discription": "Get our various office details, addresses and contacts",
        "url": enquiry_us,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "careers": {
        "title": "Careers",
        "discription": "Join Gold Star Line and grow with us!",
        "url": careers,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "solas_regulation": {
        "title": "solas regulation",
        "discription": "Our mission and goals is to provide variety of cargo services to adore your business",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "customer_advisory": {
        "title": "Customer Advisory",
        "discription": "Stay tuned with our notifications and advisory",
        "url": help_customer_advisory,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "accessibility": {
        "title": "Accessibility",
        "discription": "    ",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "data_protection_policy": {
        "title": "Data Protection Policy",
        "discription": "Get informed on our policies on protection of personal data",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_cookies_policy": {
        "title": " Cookies and Tracking Policy",
        "discription": "Our commitment on your personal data and data privacy",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "quotation_terms_conditions": {
        "title": " Quotation Terms Conditions",
        "discription": "Stay aligned to the terms and conditions of our quotations",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "terms_of_use": {
        "title": " Terms of use",
        "discription": "Guidelines and Terms of services",
        "url": terms_of_use,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_customer_advisory": {
        "title": " Customer Advisory",
        "discription": "Announcements and Advisory to our Customers",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "bl_terms_conditions": {
        "title": " B/L terms conditions",
        "discription": "Stay notified on legal terms of our B/L (Bill of Ladings)",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_go_terms_conditions": {
        "title": " GSL GO terms and conditions",
        "discription": "Stay notified on legal terms of GSL GO",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "about_car_carrier": {
        "title": "About Car Carrier",
        "discription": "",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "egsl_terms_conditions": {
        "title": " eGSL terms and conditions",
        "discription": "Stay notified on legal terms of our B/L (Bill of Ladings)",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_clause": {
        "title": " Booking Clause",
        "discription": "Get to know the clauses relevant to our Booking confirmation documents",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_company_news": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_regulation": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_industry": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "sea_waybill_terms_conditions": {
        "title": " SEA WAYBILL TERMS AND CONDITIONS",
        "discription": "TERMS AND CONDITIONS",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
}


export default innerJSON;