import React, { Component, useEffect, useState } from "react";
import AboutGsl from '../../assets/inner-images/about/about_gsl.jpg';
import companyProfile from '../../assets/inner-images/about/company_profile.jpg';
import companyHistory from '../../assets/inner-images/about/company_history.jpg';
import factsFigures from '../../assets/inner-images/about/facts_figures.jpg';
import cargoServices from '../../assets/inner-images/cargo_services/cargo_services.jpg';
import dangerousCargo from '../../assets/inner-images/cargo_services/dangerous_cargo.jpg';
import dryCargo from '../../assets/inner-images/cargo_services/dry_cargo.jpg';
import gslMonitor from '../../assets/inner-images/cargo_services/gsl_monitor.jpg';
import inlandTransport from '../../assets/inner-images/cargo_services/inland_transport.jpg';
import projectCargo from '../../assets/inner-images/cargo_services/project_cargo.jpg';
import reeferCargo from '../../assets/inner-images/cargo_services/reefer_cargo.jpg';
import temperatureControlledShipping from '../../assets/inner-images/cargo_services/temperature_controlled_shipping.jpg';
import highValueOrSensitiveCargo from '../../assets/inner-images/cargo_services/high_value_or_sensitive_cargo.jpg';
import Catalogue from '../../assets/inner-images/tools/catalogue.jpg';
import eblService from '../../assets/inner-images/tools/ebl_service.jpg';
import requestaQuote from '../../assets/inner-images/tools/request_a_quote.jpg';
import siSubmission from '../../assets/inner-images/tools/si_submission.jpg';
import bookingSubmission from '../../assets/inner-images/tools/booking_submission.jpg';
import vgmMassUpload from '../../assets/inner-images/tools/vgm_mass_upload.jpg';
import careers from '../../assets/inner-images/careers/careers.jpg';
import demurrage_and_detention_tariff from '../../assets/inner-images/local_info/demurrage_and_detention_tariff.jpg';
import local_info from '../../assets/inner-images/local_info/local_info.jpg';
import help_customer_advisory from '../../assets/inner-images/help/help_customer_advisory.jpg';
import newsletter from '../../assets/inner-images/newsletter/newsletter.jpg';
import terms_of_use from '../../assets/inner-images/help/terms_of_use.jpg';
import enquiry_us from '../../assets/inner-images/enquiry/enquiry_us.jpg';
import faq_gsl from '../../assets/inner-images/enquiry/faq_gsl.jpg';
import catalogues from '../../assets/inner-images/catalogue/catalogue.jpg';
import roro from '../../assets/inner-images/roro/roro.jpg';
import subscribe from '../../assets/inner-images/about/subscribe.jpg';
import shapeBlue from '../../../src/assets/banner-shape-blue.svg';
import shapeYellow from '../../../src/assets/banner-shape.svg';

const innerJSON =
{
    "subscribe": {
        "title": "訂閱",
        "discription": "接收您的貨物的實時警報並全面了解您的貨物狀態",
        "url": subscribe,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
        
    },
    "about_gsl": {
        "title": "貨物目錄",
        "discription": "根據您的貨物規格和需求計劃您的裝運",
        "url": AboutGsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',

    },
    "cargo_catalogue": {
        "title": "货箱分类",
        "discription": "根據您的貨物規格和需求計劃您的裝運",
        "url": catalogues,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
        
    },
    "request_a_quote": {
        "title": "請求報價",
        "discription": "我們的使命和目標是提供各種貨運服務來支持您的業務",
        "url": requestaQuote,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_submission": {
        "title": "網上訂艙",
        "discription": "我們的使命和目標是提供各種貨運服務來支持您的業務",
        "url": bookingSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ebl_service": {
        "title": "數碼提單服務",
        "discription": "我們的使命和目標是提供各種貨運服務來支持您的業務",
        "url": eblService,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "si_submission": {
        "title": "提交裝運指示(SI)",
        "discription": "無縫提交貨物詳情、相關和要求",
        "url": siSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ecommerce_partners": {
        "title": "電商合作夥伴",
        "discription": "我們的使命和目標是提供各種貨運服務來支持您的業務",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "vgm_mass_upload": {
        "title": "VGM 批量上傳",
        "discription": "多批貨物的 VGM 提交變得簡單而熟悉",
        "url": vgmMassUpload,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "high_value_or_sensitive_cargo": {
        "title": "高價值或敏感貨物",
        "discription": "金星監控為您提供精確的工具來跟踪、監控和控制您的貴重貨物，確保安全交付",
        "url": highValueOrSensitiveCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "enquiry": {
        "title": "聯繫我們",
        "discription": "請與我們聯繫！我們很樂意為您提供所需的所有資訊",
        "url": enquiry_us,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "faq": {
        "title": "常見問題",
        "discription": "了解金星航運和我們的服務",
        "url": faq_gsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "roro-private": {
        "title": "汽車運輸服務",
        "discription": "滿足全球不斷增長的汽車運輸需求",
        "url": roro,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "newsletter": {
        "title": "新聞與更新",
        "discription": "請繼續關注最新資訊和公告！",
        "url": newsletter,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_newsletter": {
        "title": "查看新聞",
        "discription": "我們的使命和目標是提供各種貨運服務來支持您的業務",
        "url": newsletter,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "cargo_services": {
        "title": "貨運服務",
        "discription": "我們的使命是為所有類型的貨物提供全方位的海運服務。",
        "url": cargoServices,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "gsl_monitor": {
        "title": "金星監視器",
        "discription": "跟踪、監控甚至控制貨物環境，尤其是在運輸敏感、高價值或冷藏貨物時",
        "url": gslMonitor,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dry_cargo": {
        "title": "乾貨",
        "discription": "憑藉60多年致力於處理任何類型的乾貨的經驗，我們將滿足您的需求。",
        "url": dryCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "reefer_cargo": {
        "title": "冷藏貨物",
        "discription": "我們的先進技術可確保您的冷藏貨物在適當的溫度下安全、準時地交付。",
        "url": reeferCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "project_cargo": {
        "title": "項目貨物",
        "discription": "我們擁有在世界各地安全運送您的特殊貨物所需的創造性解決方案和專業知識。",
        "url": projectCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "inland_transport": {
        "title": "內陸運輸",
        "discription": "通過我們廣泛的門到門網絡提供可靠的陸路運輸服務",
        "url": inlandTransport,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "temperature_controlled_shipping": {
        "title": "溫控運輸",
        "discription": "冷藏或冷凍，我們為所有溫度敏感產品提供安全運輸",
        "url": temperatureControlledShipping,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dangerous_cargo": {
        "title": "危險品",
        "discription": "我們為危險品的安全儲運提供專注專業的服務",
        "url": dangerousCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "about_gsl": {
        "title": "關於我們",
        "discription": "金星航運 - 亞洲內部、大洋洲和非洲貿易的主要參與者，以靈活的方式服務於動態市場",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_profile": {
        "title": "公司簡介",
        "discription": "我們的重點是提供最適合客戶特定需求的服務",
        "url": companyProfile,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_history": {
        "title": "我們的故事",
        "discription": "穿越時空，探索金星航運的歷史、里程碑和發展",
        "url": companyHistory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "facts_figures": {
        "title": "事實與數據",
        "discription": "我們的使命是繼續尋找創造性的解決方案來支持我們客戶的需求",
        "url": factsFigures,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "demurrage_and_detention_tariff": {
        "title": "滯港費和滯留費",
        "discription": "滯港費和滯留費不必是個謎",
        "url": demurrage_and_detention_tariff,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "local_info": {
        "title": "本地信息",
        "discription": "了解與您所在位置相關的有用信息",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "unsubscribe_schedule": {
        "title": "取消訂閱船期表",
        "discription": "",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "contact_us": {
        "title": "金星辦公室詳情",
        "discription": "獲取我們的各種辦公室詳細信息、地址和聯繫方式",
        "url": enquiry_us,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "careers": {
        "title": "招聘",
        "discription": "加入金星航運，與我們一起成長！",
        "url": careers,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "solas_regulation": {
        "title": "海上安全公約規定",
        "discription": "我們的使命和目標是提供各種貨運服務來支持您的業務",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "customer_advisory": {
        "title": "客戶諮詢",
        "discription": "請繼續關注我們的通知和諮詢",
        "url": help_customer_advisory,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "accessibility": {
        "title": "輔助功能",
        "discription": "通過我們的無障礙模式提高生活質量",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "data_protection_policy": {
        "title": "數據保護政策",
        "discription": "了解我們的個人數據保護政策",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_cookies_policy": {
        "title": "網頁瀏覽記錄政策",
        "discription": "我們對您的個人數據和數據隱私的承諾",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "quotation_terms_conditions": {
        "title": "報價條款條件",
        "discription": "遵守我們報價的條款和條件",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "terms_of_use": {
        "title": "使用條款",
        "discription": "指南和服務條款",
        "url": terms_of_use,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_customer_advisory": {
        "title": "客戶須知",
        "discription": "對我們客戶的公告和建議",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "bl_terms_conditions": {
        "title": "提單條款條件",
        "discription": "隨時了解我們提單的法律條款",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_go_terms_conditions": {
        "title": "GSL GO 條款和條件",
        "discription": "隨時了解 GSL GO 的法律條款",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "about_car_carrier": {
        "title": "關於汽車承運人",
        "discription": "",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "egsl_terms_conditions": {
        "title": "eGSL 條款和條件",
        "discription": "隨時了解我們提單的法律條款",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_clause": {
        "title": "訂艙條款",
        "discription": "了解與我們訂艙確認文件相關的條款",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_company_news": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_regulation": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_industry": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },"sea_waybill_terms_conditions": {
        "title": " SEA WAYBILL TERMS AND CONDITIONS",
        "discription": "TERMS AND CONDITIONS",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
}


export default innerJSON;