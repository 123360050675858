import React, { Component, useEffect, useState } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import "flatpickr/dist/themes/material_green.css";
import { useForm, Controller } from "react-hook-form";
import * as axios from "axios";
import Api from "../../../../package.json";
import '../tools/css/css-loader.css';
import cont_gsl from "../../../assets/cont-gsl.png";
import cont_search from "../../../assets/cont-search.png";
import { NavLink as Link } from "react-router-dom";
import tare_weight_inqury_lang from '../../lang/tare_weight_inquiry'


const qs = require("qs");
var jwt = require("jsonwebtoken");

const Tare_weight_inquiry = () => {
  const [country_code, setCountryCode] = useState(window.$country_code);
  const [tareRes, setTareRes] = useState([]);
  const [loaderFlag, setloaderFlag] = useState('is-inactive');
  const [tareRessearchflag, settareRessearchflag] = useState(false);

  var lang = window.localStorage.getItem("lang");
  if (window.localStorage.getItem("lang") == null) {
    lang = 'en';
    window.localStorage.setItem("lang", 'en');
  }

  const headers = {
		type: "application/json",
		Authorization: `Bearer `+window.localStorage.getItem('access_token'),
	  };
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    //console.log(data);
    tare_weight_inquiry_submit(data);
  };
  const tare_weight_inquiry_submit = async (data) => {
    setloaderFlag('is-active');
    const response = await axios.post(
      Api.userdata.url + "/tare_weight_inquiry",
      qs.stringify({
        country_code: country_code,
        containerid: data.containerid,
      }),{headers:headers}
    );
    const json = await response.data;
    settareRessearchflag(true);
    if (json['status'] == 'OK') {
      var data = json['message'];
      setTareRes(data);

    }
    else {
      setTareRes([]);
    }

    setloaderFlag('is-inactive');
  };
  const tare_weight_submit_pdf = async () => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/tare_weight_inquiry_pdf",
      qs.stringify({
        country_code: country_code,
        containerid: getValues("containerid"),
      }),
      {
        headers:headers,
        responseType: "blob",
      }
    );
    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const tempLink = document.createElement('a');
    tempLink.href = fileURL;
    tempLink.setAttribute('download', getValues("containerid") + '.pdf');
    tempLink.click();

    setloaderFlag("is-inactive");
  };
  const tare_weight_submit_print = async () => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/tare_weight_inquiry_pdf",
      qs.stringify({
        country_code: country_code,
        containerid: getValues("containerid"),
      }),
      {
        headers:headers,
        responseType: "blob",
      }
    );
    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    window.focus();
    setloaderFlag("is-inactive");
  };
  const checkcontainer = async (val) => {
    const response = await axios.post(
      Api.userdata.url + "/container_validation",
      qs.stringify({ containerid: val }),{headers:headers}
    );
    const res = await response.data;
    if (res == false) {
      return "Enter container number - format : 4 letters followed by 7 digits";
    } else {
      return true;
    }
  };
  return (
    <Layout>
      <div class={"loadernew loader-double " + loaderFlag}></div>
      <section class="inner-center">
        <br></br>
        <div class="container">
          <div class="row m-0">
            <div
              class="col-lg-12 col-md-12 col-lg-12"
              style={{ minHeight: "500px;" }}
            >
              <form
                autocomplete="on"
                name="point-to-point-new"
                id="point-to-point-new"
                method="get"
                action="#"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div class="form-fback">
                  <div class="row m-0">
                    <div class="col-lg-6 col-md-12 col-sm-12 pot-form p-3">
                      <div class="dout-inner">
                        <h3>{tare_weight_inqury_lang[lang].TareWeightInquiry}</h3>
                        <p>{tare_weight_inqury_lang[lang].TareWeightInquiry1}</p>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 p-0 pot-form">
                      <div class="row">
                        <div class="col-md-8 col-lg-8 col-sm-12">
                          <label>{tare_weight_inqury_lang[lang].CONTAINERNO}</label>
                          <input
                            type="text"
                            id="containerid"
                            name="containerid"
                            class="form-control"
                            style={{ textTransform: "uppercase" }}
                            {...register("containerid", {
                              validate: (value) => {
                                return checkcontainer(value);
                              },
                              required: {
                                value: true,
                                message: "This field is required.",
                              },
                            })}
                          />
                          {errors.containerid && (
                            <span class="errors">
                              {errors.containerid.message}
                            </span>
                          )}
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-12 tare-cont-no">
                          <div class="form-group">
                            <button
                              class="btn btn-default hovereffect portal-searc-tariff"
                              id="submitDetails"
                              name="my-buttons"
                              type="submit"
                            >
                              {tare_weight_inqury_lang[lang].FindContainer}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="container" style={{ minHeight: `200px;` }}>
          <div class="row">

            <div id="fill" class="col-md-9 col-lg-9 col-sm-12 p-4">
              {(tareRessearchflag == true) ?
                (tareRes.length > 0) ? <> <table style={{ width: `100%` }} id="myTable">
                  <thead class="thead-dark">
                    <th>{tare_weight_inqury_lang[lang].ContainerID}.</th>
                    <th>{tare_weight_inqury_lang[lang].WeightinKg}.</th>
                    <th>{tare_weight_inqury_lang[lang].Weightinlbs}.</th>
                  </thead>
                  <tbody>
                    {tareRes &&
                      tareRes.map((resultssss, index) => {
                        {
                          {

                          }
                          return <tr class="border_bottom">
                            <td>{resultssss['unitPrefix']+resultssss['unitNo']}</td>
                            <td>{resultssss['unitTare'] + " Kg"}</td>
                            <td>{resultssss['unitTareCalc'] + " lbs"}
                            </td>
                          </tr>

                        }
                      })}
                  </tbody>
                </table> <p>{tare_weight_inqury_lang[lang].Formoreinformationaboutthe}

                    <Link to="/solas-regulation" className="drop-inner">
                    {tare_weight_inqury_lang[lang].VGMSOLASregulationclickhere}
                    </Link>
                  </p> <div class="row tare-pdf-btn" >
                    <a
                      onClick={tare_weight_submit_pdf}
                      class="btn btn-info btn-lg"
                    >
                      <span class="glyphicon glyphicon-print"></span> {tare_weight_inqury_lang[lang].PDF}
                    </a>
                    <a
                      onClick={tare_weight_submit_print}
                      class="btn btn-info btn-lg"
                    >
                      <span class="glyphicon glyphicon-print"></span>{tare_weight_inqury_lang[lang].Print} 
                    </a>
                  </div> </> : <div class="row" style={{ minHeight: `200px`, border: `solid 1px` }}>
                  <div class="col">
                    <h3 style={{ paddingTop: `72px`, textAlign: `center` }}><i class="fa fa-search" aria-hidden="true"></i>{tare_weight_inqury_lang[lang].NoRecordFound}.</h3>
                  </div>
                </div> : ''}


            </div>
        
          </div>
        </div>
      </section>

      <GET_IN_TOUCH />
    </Layout>
  );
};
export default Tare_weight_inquiry;
