import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import Inland from '../../../../src/assets/Inland.jpeg';
import abt_icn from '../../../../src/assets/abt-icn.png';
import Inner_container from '../../innerpages/inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import about from '../../../../src/assets/inner-images/about/about.jpg';
import GoToTop from '../../../components/go-to-top';
import '../about_gsl/about.css';
import { NavLink as Link } from "react-router-dom";
import about_gsl_lang from '../../lang/about_gsl_lang';


const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class About_gsl extends Component {
    timeout = null;

    constructor(props) {

        super(props);
        this.state = {
            country_code: window.$country_code,
            mission: [],
            about_us_banner: [],
            about_us_whoweare: [],

        };

    }
    async componentDidMount() {
        var lang = window.localStorage.getItem("lang");
        const headers = {
            type: "application/json",
            Authorization: `Bearer `+window.localStorage.getItem('access_token'),
          };

        const response = await axios.post(Api.userdata.url + '/mission', qs.stringify({ 'token': '2','lang':lang }, { parseArrays: false }), { headers: headers });
        const json = await response.data;
        this.setState({ mission: json['message'][0] });

        const about_us_banner_response = await axios.post(Api.userdata.url + '/about_us_banner', qs.stringify({ 'token': '2' }, { parseArrays: false }), { headers: headers });
        const about_us_banner_json = await about_us_banner_response.data;
        this.setState({ about_us_banner: about_us_banner_json['message'] });

        const about_us_whoweare_response = await axios.post(Api.userdata.url + '/about_us_whoweare', qs.stringify({ 'token': '2','lang':lang }, { parseArrays: false }), { headers: headers });
        const about_us_whoweare_json = await about_us_whoweare_response.data;
        this.setState({ about_us_whoweare: about_us_whoweare_json['message'][0] });


    }
    render() {
        var lang = window.localStorage.getItem("lang");
        return (

            <Layout>
                <Inner_container innerpage="about_gsl" />

                <section class="inner-center n-40">
                    <div class="container">
                        <form class="abt-full">
                            <div class="row ml-0 mr-0 ">


                                <div class="col-lg-6 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s" >
                                    <div class="abt-text-con">
                                       
                                        <h3>{about_gsl_lang[lang].OURMISSION}</h3>

                                        <p>{this.state.mission['ourmission']}</p>
                                        <Link to="/view-our-mission" className="abt-gsl-but">
                                        {about_gsl_lang[lang].KnowMore}
                                        </Link>

                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-12 pot-form pl-0 dat-relat who-wee-image wow fadeInDown" style={{ backgroundImage: `url(`+ about+ `)` }} data-wow-delay=".2s" >
                                </div>
                               
                            </div>

                        </form>
                    </div>
                </section>
                <section class="inner-center">
                    <div class="container">
                        <form class="abt-full">
                            <div class="row ml-0 mr-0 ">

                                <div class="col-lg-6 col-sm-12 pot-form pl-0 dat-relat who-wee-image wow fadeInDown" style={{ backgroundImage: `url(` + Api.userdata.siteurl + `gsl-dashboard/uploads/support_org/` + this.state.about_us_whoweare['filep'] + `)` }} data-wow-delay=".2s" >
                                </div>
                                <div class="col-lg-6 col-sm-12 pot-form pl-0  ">
                                    <div class="abt-text-con who-wee wow fadeInDown" data-wow-delay=".4s"  >
                                        
                                        <h3>{about_gsl_lang[lang].WHOWEARE}</h3>

                                        <p>{this.state.about_us_whoweare['whoweare']}</p>
                                        <Link to="/who-we-are" className="abt-gsl-but">
                                        {about_gsl_lang[lang].KnowMore}
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </section>

                 <Services /> 
                <Packages />
                <GET_IN_TOUCH /> <GoToTop />
            </Layout >);
    }
}