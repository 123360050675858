const homelang =
{
    "en": {
        "Openings": "Openings",
        "PostedDate": "Posted Date",
        "ExpireDate": "Expire Date",
        "Apply": "Apply",
        "NojobOpenings": "No job Openings",
        "ApplyNow": "Apply Now",
        "Name": "Name",
        "Email": "Email",
        "Contact": "Contact",
        "Resume": "Resume",
        "pdfjpgpngdocx": "pdf,jpg,png,docx",
        "Typethecodeyousee": "Type the code you see",
     
    },
    "cn": {
        "Openings": "職位",
        "PostedDate": "刊登日期",
        "ExpireDate": "到期日",
        "Apply": "申請",
        "NojobOpenings": "沒有職位空缺",
        "ApplyNow": "現在申請",
        "Name": "姓名",
        "Email": "電郵",
        "Contact": "聯絡人",
        "Resume": "簡歷",
        "pdfjpgpngdocx": "pdf,jpg,png,docx",
        "Typethecodeyousee": "請輸入驗證碼",
    },
    "zh": {
        "Openings": "职位",
        "PostedDate": "刊登日期",
        "ExpireDate": "到期日",
        "Apply": "申请",
        "NojobOpenings": "招聘职位总数",
        "ApplyNow": "现在申请",
        "Name": "姓名",
        "Email": "电邮",
        "Contact": "联络人",
        "Resume": "简历",
        "pdfjpgpngdocx": "pdf,jpg,png,docx",
        "Typethecodeyousee": "请输入验证码",
    },
    "ko": {
        "Openings": "채용",
        "PostedDate": "등록 일자",
        "ExpireDate": "마감 일자",
        "Apply": "신청",
        "NojobOpenings": "채용 계획 없음",
        "ApplyNow": "지금 신청",
        "Name": "이름",
        "Email": "Email",
        "Contact": "연락처",
        "Resume": "이력서",
        "pdfjpgpngdocx": "pdf,jpg,png,docx",
        "Typethecodeyousee": "보이는 코드를 입력하시오",
    },
    "vi": {
        "Openings": "Viị trí đang tuyển",
        "PostedDate": "Ngày đăng tuyển",
        "ExpireDate": "Ngày hết hạn nộp đơn",
        "Apply": "Nộp đơn",
        "NojobOpenings": "No job Openings",
        "ApplyNow": "Nộp đơn ngay",
        "Name": "Tên",
        "Email": "Thư điện tử",
        "Contact": "Liên lạc",
        "Resume": "Lý lịch cá nhân",
        "pdfjpgpngdocx": "pdf,jpg,png,docx",
        "Typethecodeyousee": "Vui lòng nhập mã mà bạn thấy",
    },
    "th": {
        "Openings": "ตำแหน่งงานที่เปิดรับ",
        "PostedDate": "วันที่ประกาศ",
        "ExpireDate": "วันที่ปิดรับสมัคร",
        "Apply": "สมัคร",
        "NojobOpenings": "No job Openings",
        "ApplyNow": "สมัครงาน",
        "Name": "ชื่อ",
        "Email": "อีเมล",
        "Contact": "เบอร์โทรติดต่อ",
        "Resume": "เรซูเม่",
        "pdfjpgpngdocx": "pdf,jpg,png,docx",
        "Typethecodeyousee": "โปรดใส่รหัสตามภาพ",
    },
    "in": {
        "Openings": "Bukaan",
        "PostedDate": "Tanggal Diposting",
        "ExpireDate": "Tanggal kadaluarsa",
        "Apply": "Menerapkan",
        "NojobOpenings": "Tidak ada lowongan pekerjaan",
        "ApplyNow": "Terapkan Sekarang",
        "Name": "Nama",
        "Email": "Email",
        "Contact": "Kontak",
        "Resume": "Resume",
        "pdfjpgpngdocx": "pdf,jpg,png,docx",
        "Typethecodeyousee": "Ketik kode yang Anda lihat",
     
    },
    "ja": {
        "Openings": "募集職種",
        "PostedDate": "掲載日",
        "ExpireDate": "最終期限日",
        "Apply": "応募する",
        "NojobOpenings": "募集はしておりません",
        "ApplyNow": "今すぐ応募する",
        "Name": "名前",
        "Email": "電子メール",
        "Contact": "連絡先",
        "Resume": "略歴",
        "pdfjpgpngdocx": "pdf,jpg,png,docx",
        "Typethecodeyousee": "表示されているコードを入力してください",
     
    },
}
export default homelang;