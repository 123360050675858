const homelang =
{
    "en": {
        "eBLService": "eBL Service",
        "eBLService1": "Secure, Smooth and Sustainable",
        "eBLService2": "GSL Digital Documentation Platform is Now Available!",
        "GSLleadsthewaywitharevolutionary": "GSL leads the way with a revolutionary platform that digitizes all trade-related documents, including Bills of Lading, Bank Guarantees, Certificates of Origin, Bills of Exchange, Promissory notes, Certificates of Inspection,Invoices, etc",
        "Weinviteyoutojoin": "We invite you to join the cutting-edge platform, powered by Wave, mirroring the physical paper process into the digital world without any disruption",
        "Youcanstarttoday": "You can start today! These challenging days are just the time to start!",
        "Transformingtothenew": "Transforming to the new platform is easier than ever, and allows you to benefit from faster process, reduced documentation costs, increased accuracy, all with complete safety!",
        "GSLinvitesyoutojoin": "GSL invites you to join its innovative shipping services by paperless trade via a digital courier platform powered by Wave",
        "GSLTermsConditions": "GSL Terms & Conditions",
        "Weherebyconfirmandacknowledge": "We hereby confirm and acknowledge that electronic Bill of Lading services (“eBL services”) are being provided to us directly by the WAVE network application, and that GSL, as well as ourselves and any other party making use of this application, are merely users of this application",
        "WeareawarethattheelectronicBill": "We are aware that the electronic Bill of lading contractual terms of carriage are subjected to GSL’s standard bill of lading terms and conditions",
        "Weherebyundertakeupon": "We hereby undertake upon ourselves the liability and indemnity to GSL for the consequences of the usage of the eBL services",
        "RegistertoeBLService": "Register to eBL Service",
        "Email": "Email",
        "EnteryourBUSINESSemail": "Enter your BUSINESS email, the one you use while working with GSL",
        "FirstName": "First Name",
        "LastName": "Last Name",
        "FullCompanyName": "Full Company Name",
        "Entertheaccuratecompanyname": "Enter the accurate company name as appears on your contact with GSL",
        "JobTitle": "Job Title",
        "Location": "Location",
        "Phone": "Phone",
        "Referencenumber": "Reference number",
        "EntereitherReferencenumbers": "Enter either Reference numbers, BL number or CUCC number",
        "GSLsalescontactname": "GSL sales contact name",
        "MainIndustry": "Main Industry",
        "IacceptGSLsTermsConditions": "I accept GSL’s Terms & Conditions",
        "Typethecodeyousee": "Type the code you see",
        "IAgreeAndSubmit": "I Agree And Submit",
        "Submit": "Submit",
        
    },
    "cn": {
        "eBLService": "eBL 服務",
        "eBLService1": "安全, 暢順, 可持續",
        "eBLService2": "金星航運數碼文件平台現已投入服務!",
        "GSLleadsthewaywitharevolutionary": "金星航運領先於將平台數碼化的改變,把所有商務文件都能夠數碼化 ",
        "Weinviteyoutojoin": "我們誠意邀請您加入高端技術平台, 一個無紙化的數碼世界, 由 Wave 提供技術支援",
        "Youcanstarttoday": "您現在可以參與, 期待充滿機遇的日子!",
        "Transformingtothenew": "轉換平台從此變得輕鬆, 您可減省文件費用,得到更快, 更準確, 全面又安全的服務!",
        "GSLinvitesyoutojoin": "金星航運邀請您加入嶄新無紙商務 由數碼遞送平台 Wave 提供",
        "GSLTermsConditions": "金星航運條款",
        "Weherebyconfirmandacknowledge": "We hereby confirm and acknowledge that electronic Bill of Lading services (“eBL services”) are being provided to us directly by the WAVE network application, and that GSL, as well as ourselves and any other party making use of this application, are merely users of this application",
        "WeareawarethattheelectronicBill": "We are aware that the electronic Bill of lading contractual terms of carriage are subjected to GSL’s standard bill of lading terms and conditions",
        "Weherebyundertakeupon": "We hereby undertake upon ourselves the liability and indemnity to GSL for the consequences of the usage of the eBL services",
        "RegistertoeBLService": "登記 eBL 服務",
        "Email": "電郵",
        "EnteryourBUSINESSemail": "請輸入電郵, 用於與金星航運聯繋",
        "FirstName": "名",
        "LastName": "姓",
        "FullCompanyName": "公司全名",
        "Entertheaccuratecompanyname": "請輸入公司名字, 用於與金星航運聯繋",
        "JobTitle": "職位",
        "Location": "所在地",
        "Phone": "電話",
        "Referencenumber": "參考編號",
        "EntereitherReferencenumbers": "請輸入參考編號, 提單號或客戶代碼",
        "GSLsalescontactname": "金星航運銷售代表名字",
        "MainIndustry": "主要行業",
        "IacceptGSLsTermsConditions": "我接受金星航運的條款",
        "Typethecodeyousee": "請輸入驗證碼",
        "IAgreeAndSubmit": "我同意及遞交",
        "Submit": "提交",
    },
    "zh": {
        "eBLService": "电子提单服务",
        "eBLService1": "安全, 畅顺, 可持续",
        "eBLService2": "金星航运数码文件平台现已投入服务!",
        "GSLleadsthewaywitharevolutionary": "金星航运领先于将平台数码化的改变,把所有商务文件也能够数码化",
        "Weinviteyoutojoin": "我们诚意邀请您加入高端技术平台,一个无纸化的数码世界, 由 Wave 提供技术支援",
        "Youcanstarttoday": "您现在可以参与, 期待充满机遇的日子!",
        "Transformingtothenew": "转换平台从此变得轻松, 您可减省文件费用,得到更快, 更准确, 全面又安全的服务!",
        "GSLinvitesyoutojoin": "金星航运邀请您加入崭新无纸商务 由数码递送平台 Wave 提供",
        "GSLTermsConditions": "金星航运条款",
        "Weherebyconfirmandacknowledge": "We hereby confirm and acknowledge that electronic Bill of Lading services (“eBL services”) are being provided to us directly by the WAVE network application, and that GSL, as well as ourselves and any other party making use of this application, are merely users of this application",
        "WeareawarethattheelectronicBill": "We are aware that the electronic Bill of lading contractual terms of carriage are subjected to GSL’s standard bill of lading terms and conditions",
        "Weherebyundertakeupon": "We hereby undertake upon ourselves the liability and indemnity to GSL for the consequences of the usage of the eBL services",
        "RegistertoeBLService": "登记电子提单服务",
        "Email": "电邮",
        "EnteryourBUSINESSemail": "请输入电邮, 用于与金星航运联系",
        "FirstName": "名",
        "LastName": "姓",
        "FullCompanyName": "公司全名",
        "Entertheaccuratecompanyname": "请输入公司名字, 用于与金星航运联系",
        "JobTitle": "职位",
        "Location": "所在地",
        "Phone": "电话",
        "Referencenumber": "参考编号",
        "EntereitherReferencenumbers": "请输入参考编号, 提单号或客户代码",
        "GSLsalescontactname": "金星航运销售代表名字",
        "MainIndustry": "所属行业",
        "IacceptGSLsTermsConditions": "我接受金星航运的条款",
        "Typethecodeyousee": "请输入验证码",
        "IAgreeAndSubmit": "我同意及递交",
        "Submit": "递交",
    },
    "ko": {
        "eBLService": "eBL 서비스",
        "eBLService1": "안전하고 매끄럽고 지속 가능한",
        "eBLService2": "이제 GSL 디지털 문서 플랫폼을 사용할 수 있습니다.!",
        "GSLleadsthewaywitharevolutionary": "GSL은 선하증권, 은행보증서, 원산지증명서, 환어음, 어음, 검사증명서, 송장 등 모든 무역 관련 문서를 디지털화하는 혁신적인 플랫폼으로 선도하고 있다.",
        "Weinviteyoutojoin": "Wave를 기반하며 물리적 종이 프로세스와 동일한 멈춤 없는 디지털 세계로 전환하는 최첨단 플랫폼으로의 참여로 초대합니다.",
        "Youcanstarttoday": "지금 바로 시작할 수 있습니다! 이 도전적인 날들이 바로 시작할 때입니다!",
        "Transformingtothenew": "새로운 플랫폼으로의 전환은 매우 쉽고, 더욱 빠른 proce가 가능하며, 서류 처리 비용을 줄이며, 향상된 정확도와 안전함기할 수 있습니다.",
        "GSLinvitesyoutojoin": "GSL은 Wave에 비반한 전자 통신 플랫폼을 통해 서류 작업 절차를 줄인 혁신적 선적 system으로 초대합니다.",
        "GSLTermsConditions": "GSL 약관",
        "Weherebyconfirmandacknowledge": '우리는 전자 B/L service("eB/L service")가 Wave 네트워크를 통해 우리에게 직접 전덜되도록 하였으며, 당사와 다른 고객들도 어플리케이션을 사용하도돌 하였습니다.',
        "WeareawarethattheelectronicBill": "우리는 전자 선하증권 계약 운송 약관이 GSL의 표준 선하증권 약관의 적용을 받는다는 것을 알고 있습니다.",
        "Weherebyundertakeupon": "우리는 이에 따라 eBL 서비스 사용의 결과에 대한 GSL에 대한 책임과 보상을 스스로 책임진다.",
        "RegistertoeBLService": "eB/L 서비스 등록",
        "Email": "Email",
        "EnteryourBUSINESSemail": "GSL 업무가 가능한 email 주소 입력",
        "FirstName": "이름",
        "LastName": "성",
        "FullCompanyName": "회사 이름",
        "Entertheaccuratecompanyname": "GSLsystem과 일치하는 정확한 회사 이름",
        "JobTitle": "직책",
        "Location": "국가",
        "Phone": "전화",
        "Referencenumber": "등록 번호",
        "EntereitherReferencenumbers": "등록 번호, B/L  번호, 화주 코드",
        "GSLsalescontactname": "GSL 영업 담덩 이름",
        "MainIndustry": "업종",
        "IacceptGSLsTermsConditions": "GSL 약관에 동의",
        "Typethecodeyousee": "보이는 코드를 입력하시오",
        "IAgreeAndSubmit": "동의하고 제출",
        "Submit": "제출",
    },
    "vi": {
        "eBLService": "Dịch vụ vận đơn điện tử (eBL)",
        "eBLService1": "An toàn, nhanh chóng và bền vững",
        "eBLService2": "Nền tảng tài liệu kỹ thuật số GSL hiện đã có sẵn!",
        "GSLleadsthewaywitharevolutionary": "GSL dẫn đầu với một nền tảng mang tính cách mạng giúp số hóa tất cả các tài liệu liên quan đến thương mại, bao gồm Vận đơn, Bảo lãnh ngân hàng, Giấy chứng nhận xuất xứ, Hóa đơn hối đoái, Phiếu xuất kho, Giấy chứng nhận kiểm tra, Hóa đơn, v.v.",
        "Weinviteyoutojoin": "Chúng tôi mời bạn tham gia nền tảng tiên tiến, được cung cấp bởi Wave, phản chiếu quy trình tài liệu giấy vào thế giới kỹ thuật số mà không bị gián đoạn.",
        "Youcanstarttoday": "Bạn có thể bắt đầu ngay hôm nay! Những ngày đầy thử thách này đã đến lúc để bắt đầu!",
        "Transformingtothenew": "Chuyển đổi sang nền tảng mới dễ dàng hơn bao giờ hết, cho phép bạn hưởng lợi từ quy trình nhanh hơn, giảm chi phí tài liệu, tăng độ chính xác, tất cả đều hoàn toàn an toàn!",
        "GSLinvitesyoutojoin": "GSL mời bạn tham gia các dịch vụ vận chuyển sáng tạo của mình bằng hoạt động thương mại không cần giấy tờ thông qua nền tảng kỹ thuật số do Wave cung cấp.",
        "GSLTermsConditions": "Điều kiện và điều khoản của GSL",
        "Weherebyconfirmandacknowledge": 'Chúng tôi xác nhận và thừa nhận rằng các dịch vụ Vận đơn điện tử (“dịch vụ eBL”) đang được ứng dụng mạng WAVE cung cấp trực tiếp cho chúng tôi và GSL, cũng như chúng tôi và bất kỳ bên nào khác sử dụng ứng dụng này, chỉ là người dùng của ứng dụng này',
        "WeareawarethattheelectronicBill": "Chúng tôi biết rằng các điều khoản vận chuyển theo hợp đồng của Vận đơn điện tử phải tuân theo các điều khoản và điều kiện vận đơn tiêu chuẩn của GSL",
        "Weherebyundertakeupon": "Theo đây, chúng tôi cam kết chịu trách nhiệm và bồi thường đối với GSL về hậu quả của việc sử dụng các dịch vụ eBL",
        "RegistertoeBLService": "Đăng ký sử dụng Dịch vụ eBL ",
        "Email": "Thư điện tử",
        "EnteryourBUSINESSemail": "Nhập thư điện tử kinh doanh của bạn, thư điện tử bạn đang sử dụng để giao dịch với GSL",
        "FirstName": "Tên",
        "LastName": "họ",
        "FullCompanyName": "Tên công ty",
        "Entertheaccuratecompanyname": "Vui lòng điền chính xác tên công ty bạn đang sử dụng để giao dịch với GSL",
        "JobTitle": "Chức vụ",
        "Location": "Địa chỉ",
        "Phone": "Điện thoại",
        "Referencenumber": "Số tham chiếu",
        "EntereitherReferencenumbers": "Vui lòng điền số tham chiếu, số vận đơn hoặc mã khách hàng với GSL (CUCC)",
        "GSLsalescontactname": "Nhân viên kinh doanh của GSL",
        "MainIndustry": "Bạn là",
        "IacceptGSLsTermsConditions": "Tôi đồng ý Các điều khoản và điều kiện của GSL",
        "Typethecodeyousee": "Vui lòng nhập mã mà bạn thấy",
        "IAgreeAndSubmit": "Tôi đồng ý và đăng ký",
        "Submit": "Nhấn để gửi",
    },
    "th": {
        "eBLService": "บริการ eBL",
        "eBLService1": "ปลอดภัย ราบรื่นและยั่งยืน",
        "eBLService2": "แพลตฟอร์มเอกสารดิจิทัล GSL พร้อมให้บริการแล้ว!",
        "GSLleadsthewaywitharevolutionary": "GSL เป็นผู้นำด้วยแพลตฟอร์มปฏิวัติที่แปลงเอกสารที่เกี่ยวข้องกับการค้าทั้งหมดให้เป็นดิจิทัล รวมถึงใบตราส่ง หนังสือค้ำประกันจากธนาคาร หนังสือรับรองแหล่งกำเนิดสินค้า ตั๋วแลกเงิน ตั๋วสัญญาใช้เงิน ใบรับรองการตรวจสอบ ใบแจ้งหนี้ ฯลฯ",
        "Weinviteyoutojoin": "เราขอเชิญคุณเข้าร่วมแพลตฟอร์มล้ำสมัยซึ่งขับเคลื่อนโดย Wave ซึ่งสะท้อนกระบวนการสู่โลกดิจิทัลโดยไม่หยุดชะงัก",
        "Youcanstarttoday": "เริ่มได้แล้ววันนี้! วันที่ท้าทายเหล่านี้เป็นเพียงเวลาที่จะเริ่มต้น!",
        "Transformingtothenew": "การเปลี่ยนไปใช้แพลตฟอร์มใหม่นั้นง่ายกว่าที่เคย และช่วยให้คุณได้รับประโยชน์จากกระบวนการที่เร็วขึ้น ลดต้นทุนด้านเอกสาร ความแม่นยำที่เพิ่มขึ้น ทั้งหมดนี้มีความปลอดภัยอย่างสมบูรณ์!",
        "GSLinvitesyoutojoin": "GSL ขอเชิญคุณเข้าร่วมบริการจัดส่งนวัตกรรมโดยการค้าแบบไร้กระดาษผ่านแพลตฟอร์มการจัดส่งแบบดิจิทัลที่ขับเคลื่อนโดย Wave",
        "GSLTermsConditions": "ข้อกำหนดและเงื่อนไข GSL",
        "Weherebyconfirmandacknowledge": 'เราขอยืนยันและรับทราบว่าบริการ Bill of Lading อิเล็กทรอนิกส์ ("บริการ eBL") นั้นมอบให้เราโดยตรงโดยแอปพลิเคชันเครือข่าย WAVE และ GSL รวมถึงตัวเราและบุคคลอื่นใดที่ใช้แอปพลิเคชันนี้ เป็นเพียงผู้ใช้ ของแอปพลิเคชั่นนี้',
        "WeareawarethattheelectronicBill": "เราทราบดีว่าเงื่อนไขการรับขนตามสัญญาใบตราส่งสินค้าอิเล็กทรอนิกส์นั้นอยู่ภายใต้ข้อกำหนดและเงื่อนไขใบตราส่งสินค้ามาตรฐานของ GSL",
        "Weherebyundertakeupon": "เราขอรับผิดชอบในความรับผิดและการชดใช้ค่าเสียหายต่อ GSL สำหรับผลที่ตามมาของการใช้บริการ eBL",
        "RegistertoeBLService": "สมัครใช้งาน eBL Service",
        "Email": "อีเมล",
        "EnteryourBUSINESSemail": "ป้อนอีเมลที่คุณใช้ทำงานติดต่อกับ GSL",
        "FirstName": "ชื่อ",
        "LastName": "นามสกุล",
        "FullCompanyName": "บริษัท",
        "Entertheaccuratecompanyname": "ป้อนชื่อบริษัทที่ถูกต้องตามที่ปรากฏในการติดต่อของคุณกับ GSL",
        "JobTitle": "ตำแหน่งงาน",
        "Location": "ประเทศ",
        "Phone": "เบอร์โทร",
        "Referencenumber": "หมายเลขอ้างอิง",
        "EntereitherReferencenumbers": "หมายเลข BL หรือหมายเลข CUCC",
        "GSLsalescontactname": "ชื่อพนักงานขาย GSL",
        "MainIndustry": "อุตสาหกรรมหลัก",
        "IacceptGSLsTermsConditions": "ข้าพเจ้ายอมรับข้อตกลงและเงื่อนไขในการให้บริการ",
        "Typethecodeyousee": "โปรดใส่รหัสตามภาพ",
        "IAgreeAndSubmit": "ข้าพเจ้าเห็นด้วยและส่ง",
        "Submit": "ส่ง",
    },
    "in": {
        "eBLService": "Layanan eBL",
        "eBLService1": "Aman, Lancar dan Berkelanjutan",
        "eBLService2": "Platform Dokumentasi Digital GSL Tersedia Sekarang",
        "GSLleadsthewaywitharevolutionary": "GSL leads the way with a revolutionary platform that digitizes all trade-related documents, including Bills of Lading, Bank Guarantees, Certificates of Origin, Bills of Exchange, Promissory notes, Certificates of Inspection,Invoices, etc",
        "Weinviteyoutojoin": "Kami mengundang anda untuk bergabung platform canggih, dipersembahkan oleh Wave, pencerminan proses dokumen kertas ke dalam dunia digital tanpa ada gangguan",
        "Youcanstarttoday": "Anda bisa mulai sekarang! Hari-hari yang menantang ini hanya masalah waktu untuk memulai",
        "Transformingtothenew": "Memungkinkan anda untuk mendapatkan keuntungan dengan proses yang lebih cepat, mengurangi biaya dokumentasi, meningkatkan akurasi, semua dengan keamanan lengkap!",
        "GSLinvitesyoutojoin": "GSL mengundang anda untuk bergabung dengan layanan pengiriman inovatif melalui perdagangan tanpa kertas dan platform kurir digital dipersembahkan oleh Wave.",
        "GSLTermsConditions": "Syarat & Ketentuan GSL",
        "Weherebyconfirmandacknowledge": "Kami dengan ini mengonfirmasi dan mengakui bahwa layanan Bill of Lading elektronik ('layanan eBL') diberikan kepada kami secara langsung oleh aplikasi jaringan WAVE, dan bahwa GSL, serta kami sendiri dan pihak lain mana pun yang menggunakan aplikasi ini, hanyalah pengguna dari aplikasi ini",
        "WeareawarethattheelectronicBill": "Kami menyadari bahwa persyaratan kontrak pengangkutan bill of lading elektronik tunduk pada syarat dan ketentuan bill of lading standar GSL",
        "Weherebyundertakeupon": "Kami dengan ini menanggung tanggung jawab dan ganti rugi kepada GSL atas konsekuensi dari penggunaan layanan eBL",
        "RegistertoeBLService": "Mendaftar layanan eBL",
        "Email": "Email",
        "EnteryourBUSINESSemail": "Masukkan email bisnis anda, yang akan anda gunakan berhubungan dengan GSL",
        "FirstName": "Nama Depan",
        "LastName": "Nama Belakang",
        "FullCompanyName": "Nama Perusahaan Lengkap",
        "Entertheaccuratecompanyname": "Masukkan nama perusahaan yang akurat sesuai yang nampak pada kontak anda dengan GSL",
        "JobTitle": "Judul Pekerjaan",
        "Location": "Lokasi",
        "Phone": "Telepon",
        "Referencenumber": "Nomor referensi",
        "EntereitherReferencenumbers": "Masukkan nomor Referensi, nomor BL, atau nomor CUCC.",
        "GSLsalescontactname": "Nama kontak penjualan GSL",
        "MainIndustry": "Industri Utama",
        "IacceptGSLsTermsConditions": "Saya menerima Syarat & Ketentuan GSL",
        "Typethecodeyousee": "Ketik kode yang Anda lihat",
        "IAgreeAndSubmit": "Saya Setuju Dan Kirim",
        "Submit": "Negara",
        
    },
    "ja": {
        "eBLService": "eBLサービス",
        "eBLService1": "安全、スムーズ、サステナブル",
        "eBLService2": "GSLデジタルドキュメンテーションプラットフォームがご利用になれます。",
        "GSLleadsthewaywitharevolutionary": "GSLは、船荷証券、銀行保証、原産地証明書、為替手形、約束手形、検査証明書、請求書など、あらゆる貿易関連文書をデジタル化する革新的なプラットフォームに関する業界の先駆者です。",
        "Weinviteyoutojoin": "業務を中断することなく、物理的な紙の作業をデジタルの世界へ移し替える、Waveを搭載した最先端のプラットフォームにぜひご参加ください。",
        "Youcanstarttoday": "今すぐ始められます。この挑戦の日々はちょうど始まったばかりです。",
        "Transformingtothenew": "新しいプラットフォームへの移行はこれまで以上に簡単になり、プロセスの高速化、ドキュメントコストの削減、精度の向上など、すべてをまったく安全に実現できます。",
        "GSLinvitesyoutojoin": "GSLでは、Waveを搭載したデジタル配送プラットフォームによるペーパーレス貿易という、革新的な配送サービスを提供しております。ぜひご利用ください。",
        "GSLTermsConditions": "GSL約款",
        "Weherebyconfirmandacknowledge": "We当社は、電子船荷証券サービス（以下「eBLサービス」といいます）がWAVEネットワークアプリケーションによって直接当社に提供されていることと、GSL、当社およびこのアプリケーションを使用する他の当事者が、このアプリケーションのユーザーに過ぎないことをここに確認し、承認します",
        "WeareawarethattheelectronicBill": "当社は、電子船荷証券の運送約款が、GSLの標準船荷証券約款の適用を受けることを認識しています",
        "Weherebyundertakeupon": "当社は、eBLサービスの利用の結果について、GSLに対して自ら責任を負い、補償を行います。",
        "RegistertoeBLService": "eBLサービスに登録します",
        "Email": "電子メール",
        "EnteryourBUSINESSemail": "GSLとの業務で使用するビジネス用電子メールを入力してください。",
        "FirstName": "名",
        "LastName": "姓",
        "FullCompanyName": "正式社名",
        "Entertheaccuratecompanyname": "GSLとの連絡先として表示されている正確な会社名を入力します。",
        "JobTitle": "役職",
        "Location": "場所",
        "Phone": "電話番号",
        "Referencenumber": "整理番号",
        "EntereitherReferencenumbers": "整理番号、BL番号、またはCUCC番号のいずれかを入力します。",
        "GSLsalescontactname": "GSL営業担当者の名前",
        "MainIndustry": "主な業種",
        "IacceptGSLsTermsConditions": "私はGSL約款を受諾します",
        "Typethecodeyousee": "表示されたコードを入力します",
        "IAgreeAndSubmit": "同意して送信します",
        "Submit": "送信",
        
    },
}
export default homelang;