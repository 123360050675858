import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import "flatpickr/dist/themes/material_green.css";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as axios from "axios";
import Api from "../../../../package.json";
import "../tools/css/css-loader.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import cont_gsl from "../../../assets/cont-gsl.png";
import cont_search from "../../../assets/cont-search.png";
import swal from "sweetalert";
import { FileUploader } from "react-drag-drop-files";
import { useHistory } from 'react-router-dom';
import { Accordion, Card, Modal, Button } from "react-bootstrap";
import { NavLink as Link } from "react-router-dom";
import roro_lang from '../../lang/roro';
import HCaptcha from "@hcaptcha/react-hcaptcha";
const fileTypes = ["JPG", "PNG", "GIF", "PDF"];
const qs = require("qs");
var jwt = require("jsonwebtoken");

var lang = window.localStorage.getItem("lang");
if (window.localStorage.getItem("lang") == null) {
  lang = 'en';
  window.localStorage.setItem("lang", 'en');
}

const Roro = () => {
    const history = useHistory();
    const headers = {
        "Content-Type": "multipart/form-data",
        type: "application/json",
        Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
    };
    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const containweightRegex = /^[a-zA-Z]{4}[0-9]{7}$/;
    const validationSchema = Yup.object().shape({
        shipper: Yup.string().required(
            "Please enter our shipper details"
        ),
        from_port: Yup.string().required(
            "Please select your booking office country location"
        ),
        to_port: Yup.string().required(
            "Please select your booking office country location"
        ),
        shipment_date: Yup.string().required(
            "Please select your shipment date"
        ),
        contact_name: Yup.string().required(
            "Please enter your contact name"
        ),
        contact_email: Yup.string().email('Must be a valid email').max(255).required('Please enter your contact email'),
        cargodetails: Yup.array().of(
            Yup.object().shape({
                cargo_class: Yup.string().required("Required"),
                condition: Yup.string().required("Required"),

                quantity: Yup.number().required("Please provide plan cost.").typeError('Invaild Quantity')
                    .min(0, "Invaild Quantity")
                    .max(10000, 'Invaild Quantity!'),
                unit_weight: Yup.number().required("Please provide plan cost.").typeError('Invaild Unit Weight'),
                length: Yup.number().required("Please provide plan cost.").typeError('Invaild Length'),
                width: Yup.number().required("Please provide plan cost.").typeError('Invaild Width'),
                height: Yup.number().required("Please provide plan cost.").typeError('Invaild Height'),
                edit_hazardous_details: Yup.boolean(),
                package_desc_cargo: Yup.string().when("edit_hazardous_details", { is: true, then: Yup.string().required("Please provide package and description of cargo.") }),
                proper_shipping_name: Yup.string().when("edit_hazardous_details", { is: true, then: Yup.string().required("Please Proper Shipping Name.") }),
                class: Yup.string().when("edit_hazardous_details", { is: true, then: Yup.string().required("Please Proper class Name.") }),
                un_number: Yup.string().when("edit_hazardous_details", { is: true, then: Yup.string().required("Please Proper UN number") }),
                additional_documents: Yup.mixed().
                    test('additional_documents',
                        "Invalid File Format", // Msg
                        (additional_documents) => {
                            if (additional_documents == null) {
                                return true;
                            }
                            else if (additional_documents.length > 5 || additional_documents.length == 0) {
                                return false;
                            }
                            else if (additional_documents.length < 5 && additional_documents.length > 0) {
                                var valid_file = true;
                                for (var i = 0; i < additional_documents.length; i++) {
                                    if (additional_documents[i]['type'] != '' && additional_documents[i]['type'] != 'image/png' && additional_documents[i]['type'] != 'image/jpeg' && additional_documents[i]['type'] != 'application/pdf') {
                                        valid_file = false;
                                    }
                                }
                                return valid_file;
                            }
                            else {
                                return true;
                            }
                        }),
                imo_dangerousc: Yup.mixed().when("edit_hazardous_details", {
                    is: true, then: Yup.mixed().
                        test('imo_dangerousc',
                            "Invalid File Format", // Msg
                            (imo_dangerousc) => {
                                if (imo_dangerousc == null) {
                                    return true;
                                }
                                else if (imo_dangerousc.length > 5 || imo_dangerousc.length == 0) {
                                    return false;
                                }
                                else if (imo_dangerousc.length < 5 && imo_dangerousc.length > 0) {
                                    var valid_file = true;
                                    for (var i = 0; i < imo_dangerousc.length; i++) {
                                        if (imo_dangerousc[i]['type'] != '' && imo_dangerousc[i]['type'] != 'image/png' && imo_dangerousc[i]['type'] != 'image/jpeg' && imo_dangerousc[i]['type'] != 'application/pdf') {
                                            valid_file = false;
                                        }
                                    }
                                    return valid_file;
                                }
                                else {
                                    return true;
                                }
                            })
                }),
                material_safety: Yup.mixed().when("edit_hazardous_details", {
                    is: true, then: Yup.mixed().
                        test('material_safety',
                            "Invalid File Format", // Msg
                            (material_safety) => {

                                if (material_safety == null) {
                                    return true;
                                }
                                else if (material_safety.length > 5 || material_safety.length == 0) {
                                    return false;
                                }
                                else if (material_safety.length < 5 && material_safety.length > 0) {
                                    var valid_file = true;
                                    for (var i = 0; i < material_safety.length; i++) {
                                        if (material_safety[i]['type'] != '' && material_safety[i]['type'] != 'image/png' && material_safety[i]['type'] != 'image/jpeg' && material_safety[i]['type'] != 'application/pdf') {
                                            valid_file = false;
                                        }
                                    }
                                    return valid_file;
                                }
                                else {
                                    return true;
                                }
                            })
                }),

            })
        ),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
        register,
        control,
        handleSubmit,
        reset,
        formState,
        watch,
        setValue,
        getValues,
    } = useForm(formOptions);

    const { errors } = formState;
    const [checkBL, setcheckBL] = useState(false);
    const [videoShow, setVideoShow] = useState(true);
    const [roroCaptcha, setroroCaptcha] = useState([]);
    const [unitOfMeasureWeight, setunitOfMeasureWeight] = useState('kg');
    const [unitOfMeasureDimension, setunitOfMeasureDimension] = useState('m');
    const [port_code, setPortcode] = useState([]);
    const [hazardousflag, sethazardousflag] = useState([]);
    const buttonRef = useRef();
    const [token, setToken] = useState(null);
    const captchaRef = useRef(null);
    const [SubmitButton, setSubmitButton] = useState(roro_lang[lang].Submit);
    function onSubmit(data) {
        // display form data on success
        // alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
        buttonRef.current.disabled = true;
        setSubmitButton(roro_lang[lang].Submit+'...');
        //console.log(JSON.stringify(data, null, 4));
        save_roro(data);
    }

    const save_roro = async (data) => {
        if(token != null)
        {
            const bodyFormData = new FormData();
            bodyFormData.append("roro_captcha", token);
            Object.keys(data).forEach((key) => {
                if (key == 'cargodetails') {
                    for (var i = 0; i < data[key].length; i++) {
                        Object.keys(data[key][i]).forEach((keyss) => {
    
                            if (keyss == 'additional_documents') {
                                if (data[key][i][keyss].length > 0) {
                                    for (var k = 0; k < data[key][i][keyss].length; k++) {
                                        bodyFormData.append('additional_documents[' + i + '][]', data[key][i][keyss][k]);
                                    }
                                }
                                else {
                                    bodyFormData.append('additional_documents[' + i + '][]', []);
                                }
    
                            }
                            else if (keyss == 'imo_dangerousc') {
                                if (data[key][i][keyss].length > 0) {
                                    for (var k = 0; k < data[key][i][keyss].length; k++) {
                                        bodyFormData.append('imo_dangerousc[' + i + '][]', data[key][i][keyss][k]);
                                    }
                                }
                                else {
                                    bodyFormData.append('imo_dangerousc[' + i + '][]', []);
                                }
                            }
                            else if (keyss == 'material_safety') {
                                if (data[key][i][keyss].length > 0) {
                                    for (var k = 0; k < data[key][i][keyss].length; k++) {
                                        bodyFormData.append('material_safety[' + i + '][]', data[key][i][keyss][k]);
                                    }
                                }
                                else {
                                    bodyFormData.append('material_safety[' + i + '][]', []);
                                }
                            }
                            else {
                                bodyFormData.append('cargodetails[' + i + '][' + keyss + ']', data[key][i][keyss]);
                            }
                        })
    
                    }
    
                   
                }
                else {
                    bodyFormData.append(key, data[key]);
                }
    
            })
          
    
    
    
            const response = await axios({
                method: "post",
                url: Api.userdata.url + "/submit_roro",
                data: bodyFormData, headers: headers
            }
            );
            const json = await response.data;
            if (json["status"] == "OK") {
                swal("Successful Submitted", json["message"], "success").then((value) => {
                    window.location.reload(true);
    
                });
            } else {
                swal("Error !", json["message"], "error");
                buttonRef.current.disabled = false;
                setSubmitButton(roro_lang[lang].Submit);
            }
        }else{
            swal("Error !", "Invalid captcha", "error");
          }
      


        //var jsval = JSON.parse([0]["details"]);
        //setHeadercaptcha(json["captcha"]);

        //setHeaderJsonContent(jsval);
    };
    const getLoadData = async () => {
        const headers1 = {
            type: "application/json",
            Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
        };
        const port_codes_response = await axios.post(
            Api.userdata.url + "/port_codes",
            qs.stringify({ token: "2" }, { parseArrays: false }), { headers: headers1 }
        );
        const port_codes_response_json = await port_codes_response.data;
        setPortcode(port_codes_response_json["message"]);

        const roro_token = await axios.post(
            Api.userdata.url + "roro_token",
            qs.stringify({ token: "2" }, { parseArrays: false }), { headers: headers1 }
        );
        const roro_token_json = await roro_token.data;
        setroroCaptcha(roro_token_json["captcha"]);
     /*    if (roro_token_json['status'] == "OK") {
            setroroCaptcha(roro_token_json["message"]);

        } */
    };
    useEffect(() => {
        getLoadData();
        cargodetailsAppend({ quantity: "" });
    }, []);
    const {
        fields: cargodetailsFields,
        append: cargodetailsAppend,
        remove: cargodetailsRemove,
    } = useFieldArray({ name: "cargodetails", control });

    const addcargodetails = () => {
        cargodetailsAppend({ quantity: "" });
    };
    return (
        <Layout>
            <section class="inner-center roroId">
                <br></br>
                <div class="container  p-4">
                    <div class="form-fback">
                        <div class="row m-0">
                            <div class="col-lg-6 col-md-12 col-sm-12 pot-form p-3">
                                <div class="dout-inner">
                                    <h3>{roro_lang[lang].CARCARRIERSERVICE}</h3>
                                    <p>{roro_lang[lang].CARCARRIERSERVICE1}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-lg-12 col-md-12 col-lg-12"
                            style={{ minHeight: "500px;" }} >
                            <form
                                action="#"
                                name="vgm_form"
                                id="vgm_form"
                                onSubmit={handleSubmit(onSubmit)}
                            >  <div class="tab-content p-3 mt-2 border trade-tab-con booking-tab-cont">
                                    <div class="tab-pane active" role="tabpanel" id="step1">
                                        <h4>{roro_lang[lang].BookingDetails}</h4>
                                        <div class="row m-0">
                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                <label>
                                                {roro_lang[lang].Wheredoyouwanttoshipfrom}?<sup class="starss">*</sup>
                                                </label>

                                                <Controller
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <Select
                                                            options={port_code.map((data) => ({
                                                                value: data["port_code"],
                                                                label:
                                                                    data["port_name"] +
                                                                    "-" +
                                                                    data["country_name"] +
                                                                    "(" +
                                                                    data["port_code"] +
                                                                    ")",
                                                            }))}
                                                            onChange={(val) => {
                                                                setValue("from_port", val.value);
                                                            }}


                                                        />
                                                    )}
                                                    defaultValue=""
                                                    placeholder="Select platform"
                                                    name="receipt"
                                                    id="receipt"
                                                    className={`form-control ${errors.from_port ? "is-invalid" : ""
                                                        }`}
                                                    setValue={setValue}
                                                />
                                                <div className="errors">{errors.from_port?.message}</div>
                                            </div>

                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                <label>
                                                {roro_lang[lang].Wheredoyouwanttoshipto}?<sup class="starss">*</sup>
                                                </label>

                                                <Controller
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <Select
                                                            options={port_code.map((data) => ({
                                                                value: data["port_code"],
                                                                label:
                                                                    data["port_name"] +
                                                                    "-" +
                                                                    data["country_name"] +
                                                                    "(" +
                                                                    data["port_code"] +
                                                                    ")",
                                                            }))}
                                                            onChange={(val) => {
                                                                setValue("to_port", val.value);
                                                            }}
                                                        />
                                                    )}
                                                    defaultValue=""
                                                    placeholder="Select platform"
                                                    name="receipt"
                                                    id="receipt"
                                                    className={`form-control ${errors.to_port ? "is-invalid" : ""
                                                        }`}
                                                    setValue={setValue}
                                                />
                                                <div className="errors">{errors.to_port?.message}</div>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                <label>
                                                {roro_lang[lang].Shipper}<sup class="starss">*</sup>
                                                </label>
                                                <input
                                                    {...register("shipper")}
                                                    type="text"
                                                    name="shipper"
                                                    id="shipper"
                                                    placeholder=""
                                                    className={`form-control ${errors.shipper ? "is-invalid" : ""
                                                        }`}
                                                />
                                                <div className="errors">
                                                    {errors.shipper?.message}
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                <label>
                                                {roro_lang[lang].Forwarder}
                                                </label>
                                                <input
                                                    {...register("forwarder")}
                                                    type="text"
                                                    name="forwarder"
                                                    id="forwarder"
                                                    placeholder=""

                                                />
                                            </div>
                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                <label>
                                                {roro_lang[lang].ShipmentDate} 
                                                </label>
                                                <Controller
                                                    control={control}
                                                    render={({ onChange, value, ref }) => (
                                                        <Flatpickr

                                                            options={{ minDate: "today", dateFormat: "d-m-Y" }}
                                                            onChange={(val) => {

                                                                setValue('shipment_date',
                                                                    new Date(val[0]).toISOString()
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                    name={'shipment_date'}
                                                    className={`form-control ${errors.shipment_date ? "is-invalid" : ""
                                                        }`}

                                                /> <div className="errors">
                                                    {
                                                        errors.shipment_date?.message
                                                    }
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 pot-form pl-0">
                                                <label> {roro_lang[lang].Additionalcomments}</label>
                                                <textarea
                                                    {...register('additional_comments')}
                                                    style={{
                                                        marginTop: `0px`,
                                                        marginBottom: `0px !important`,
                                                        height: `140px`,
                                                    }}
                                                ></textarea>
                                            </div>
                                            <div class="col-lg-10 col-sm-10 pot-form pl-0 ">
                                                <h4>{roro_lang[lang].Contactdetails}</h4>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                <label>
                                                {roro_lang[lang].Contactname} 
                                                </label>
                                                <input
                                                    {...register("contact_name")}
                                                    type="text"
                                                    name="contact_name"
                                                    id="contact_name"
                                                    placeholder=""
                                                    className={`form-control ${errors.contact_name ? "is-invalid" : ""
                                                        }`}
                                                />
                                                <div className="errors">
                                                    {errors.contact_name?.message}
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                <li
                                                    class="ind-f-full"
                                                    style={{ listStyle: `none`, }}
                                                >
                                                    <label>
                                                    {roro_lang[lang].PhoneNumber}  <sup class="starss">*</sup>
                                                    </label>
                                                    <div class="phone-country">
                                                        <div class="feedback-code">
                                                            <label class="pl-feed">+</label>
                                                            <input
                                                                {...register(
                                                                    'phoneone'
                                                                )}
                                                                placeholder="852"
                                                                className={`form-control ${errors.phoneone
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                                type="text"
                                                                style={{ marginBottom: `0 !important`, paddingLeft: `25px` }}
                                                            />
                                                            <div className="errors">
                                                                {
                                                                    errors.phoneone
                                                                        ?.message
                                                                }
                                                            </div>
                                                        </div>
                                                        <div class="feedback-number">
                                                            <label class="min-feed">-</label>
                                                            <input
                                                                {...register('phone')}
                                                                placeholder="Contact number"
                                                                type="text"
                                                                className={`form-control ${errors.phone
                                                                    ? "is-invalid"
                                                                    : ""
                                                                    }`}
                                                                style={{ marginBottom: `0 !important` }}
                                                            />
                                                            <div className="errors">
                                                                {errors.phone?.message}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </li>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                <label>
                                                {roro_lang[lang].Contactemail} 
                                                </label>
                                                <input
                                                    {...register("contact_email")}
                                                    type="text"
                                                    name="contact_email"
                                                    id="contact_email"
                                                    placeholder=""
                                                    className={`form-control ${errors.contact_email ? "is-invalid" : ""
                                                        }`}
                                                />
                                                <div className="errors">
                                                    {errors.contact_email?.message}
                                                </div>
                                            </div>
                                            <div class="col-lg-10 col-sm-10 pot-form pt-5 pl-0">
                                                <h4>{roro_lang[lang].EnterCargodetails} </h4>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                <label>
                                                {roro_lang[lang].EnterCargodetails1}
                                                </label>
                                                <ul class="sec-pot-check new-pot-radio"><li><div class="custom-control custom-radio"> <input
                                                    {...register("pickup_unit_of_dimension")}
                                                    type="radio"
                                                    name="pickup_unit_of_dimension"
                                                    id="Meters"
                                                    placeholder=""
                                                    value="m"
                                                    defaultChecked
                                                    onClick={(e) => {
                                                        //  setValue('pickup_unit_of_dimension', 'm');
                                                        setunitOfMeasureDimension('m');

                                                    }}
                                                    className={`custom-control-input`}
                                                /><label class="custom-control-label radio-nane" for="Meters">{roro_lang[lang].Meters}</label></div></li>
                                                    <li><div class="custom-control custom-radio"><input
                                                        {...register("pickup_unit_of_dimension")}
                                                        type="radio"
                                                        name="pickup_unit_of_dimension"
                                                        id="Inches"
                                                        placeholder=""
                                                        className={`custom-control-input`}
                                                        value="in"
                                                        onClick={(e) => {
                                                            //   setValue('pickup_unit_of_dimension', 'in');
                                                            setunitOfMeasureDimension('in');

                                                        }}
                                                    /><label class="custom-control-label radio-nane" for="Inches">{roro_lang[lang].Inches}</label></div></li></ul>


                                            </div>
                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                <label>
                                                {roro_lang[lang].Pickaunitofmeasureforweight} 
                                                </label>
                                                <ul class="sec-pot-check new-pot-radio"><li><div class="custom-control custom-radio"> <input
                                                    {...register("pickup_unit_of_weight")}
                                                    type="radio"
                                                    name="pickup_unit_of_weight"
                                                    id="Kilograms"
                                                    placeholder=""
                                                    value="kg"
                                                    defaultChecked
                                                    onClick={(e) => {
                                                        setValue('pickup_unit_of_weight', 'kg');
                                                        setunitOfMeasureWeight('kg');

                                                    }}
                                                    className={`custom-control-input`}
                                                /><label class="custom-control-label radio-nane" for="Kilograms">{roro_lang[lang].Kilograms}</label></div></li>
                                                    <li><div class="custom-control custom-radio"><input
                                                        {...register("pickup_unit_of_weight")}
                                                        type="radio"
                                                        name="pickup_unit_of_weight"
                                                        id="Pounds"
                                                        placeholder=""
                                                        value="lbs"
                                                        className={`custom-control-input`}
                                                        onClick={(e) => {
                                                            setValue('pickup_unit_of_weight', 'lbs');
                                                            setunitOfMeasureWeight('lbs');

                                                        }}
                                                    /><label class="custom-control-label radio-nane" for="Pounds">{roro_lang[lang].Pounds}</label></div></li></ul>

                                            </div>
                                            <br></br>
                                            {cargodetailsFields.map((item, i) => {
                                                var quantity, unit_weight, length, width, height, total_weight, total_measurement;
                                                const calcu = async () => {
                                                    quantity = getValues(`cargodetails.${i}.quantity`);
                                                    unit_weight = getValues(`cargodetails.${i}.unit_weight`);
                                                    length = getValues(`cargodetails.${i}.length`);
                                                    width = getValues(`cargodetails.${i}.width`);
                                                    height = getValues(`cargodetails.${i}.height`);
                                                    total_weight = 0;
                                                    total_measurement = 0;
                                                    if (unit_weight != '' && quantity != "") {
                                                        total_weight = quantity * unit_weight;
                                                        setValue(`cargodetails.${i}.total_weight`, total_weight);

                                                    }
                                                    if (length != '' && width != '' && height != '' && quantity != "") {
                                                        total_measurement = width * length * height * quantity;
                                                        setValue(`cargodetails.${i}.total_measurement`, total_measurement);
                                                    }
                                                };
                                                const fill_val_addition_doc = async () => {

                                                    var additional_documents = getValues(`cargodetails.${i}.additional_documents`);
                                                    var additional_documents_text = " Selected Files : ";
                                                    console.log(additional_documents);
                                                    if (additional_documents.length <= 5 && additional_documents.length > 0) {
                                                        var valid_file = true;
                                                        for (var k = 0; k < additional_documents.length; k++) {
                                                            if (additional_documents.length - 1 == k) {
                                                                additional_documents_text += additional_documents[k]['name'];
                                                            }
                                                            else {
                                                                additional_documents_text += additional_documents[k]['name'] + ",";
                                                            }

                                                        }

                                                    }
                                                    else if (additional_documents.length >= 5) {
                                                        additional_documents_text = "Maximum 5 files only allowed";
                                                    }
                                                    else {
                                                        additional_documents_text = "Nothing selected"
                                                    }
                                                    setValue(`cargodetails.${i}.additional_documents_text`, additional_documents_text);
                                                }
                                                const get_imo_dangerousc_file = async () => {

                                                    var imo_dangerousc = getValues(`cargodetails.${i}.imo_dangerousc`);
                                                    var imo_dangerousc_text = " Selected Files : ";

                                                    if (imo_dangerousc.length <= 5 && imo_dangerousc.length > 0) {

                                                        for (var k = 0; k < imo_dangerousc.length; k++) {
                                                            if (imo_dangerousc.length - 1 == k) {
                                                                imo_dangerousc_text += imo_dangerousc[k]['name'];
                                                            }
                                                            else {
                                                                imo_dangerousc_text += imo_dangerousc[k]['name'] + ",";
                                                            }

                                                        }

                                                    }
                                                    else if (imo_dangerousc.length >= 5) {
                                                        imo_dangerousc_text = "Maximum 5 files only allowed";
                                                    }
                                                    else {
                                                        imo_dangerousc_text = "Nothing selected"
                                                    }
                                                    setValue(`cargodetails.${i}.imo_dangerousc_text`, imo_dangerousc_text);
                                                }
                                                const get_material_safety_file = async () => {

                                                    var material_safety = getValues(`cargodetails.${i}.material_safety`);
                                                    var material_safety_text = " Selected Files : ";

                                                    if (material_safety.length <= 5 && material_safety.length > 0) {

                                                        for (var k = 0; k < material_safety.length; k++) {
                                                            if (material_safety.length - 1 == k) {
                                                                material_safety_text += material_safety[k]['name'];
                                                            }
                                                            else {
                                                                material_safety_text += material_safety[k]['name'] + ",";
                                                            }

                                                        }

                                                    }
                                                    else if (material_safety.length >= 5) {
                                                        material_safety_text = "Maximum 5 files only allowed";
                                                    }
                                                    else {
                                                        material_safety_text = "Nothing selected"
                                                    }
                                                    setValue(`cargodetails.${i}.material_safety_text`, material_safety_text);
                                                }

                                                return (<>

                                                    <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                        <label> {roro_lang[lang].CargoClass}</label>
                                                        <select className={`form-control ${errors.cargodetails?.[i]?.cargo_class
                                                            ? "is-invalid"
                                                            : ""
                                                            }`} {...register(`cargodetails.${i}.cargo_class`)} id={`cargodetails.${i}.cargo_class`}>
                                                            <option value="">Select</option>
                                                            <option value="Vehicles">Vehicles</option>

                                                        </select>
                                                        <div className="errors">
                                                            {errors.cargodetails?.[i]?.cargo_class?.message}
                                                        </div>

                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                        <label>{roro_lang[lang].Condition}</label>
                                                        <select className={`form-control ${errors.cargodetails?.[i]?.condition
                                                            ? "is-invalid"
                                                            : ""
                                                            }`} {...register(`cargodetails.${i}.condition`)} id={`cargodetails.${i}.condition`}>
                                                            <option value="">Select</option>
                                                            <option value="New">New</option>
                                                            <option value="Used">Used</option>
                                                        </select>
                                                        <div className="errors">
                                                            {errors.cargodetails?.[i]?.condition?.message}
                                                        </div>

                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                        <label>{roro_lang[lang].Quantity}</label>
                                                        <input
                                                            {...register(`cargodetails.${i}.quantity`)}
                                                            id={`cargodetails.${i}.quantity`}
                                                            type="text"
                                                            name={`cargodetails.${i}.quantity`}
                                                            placeholder=""
                                                            className={`form-control ${errors.cargodetails?.[i]?.quantity ? "is-invalid" : ""
                                                                }`}
                                                        />
                                                        <div className="errors">
                                                            {errors.cargodetails?.[i]?.quantity?.message}
                                                        </div>

                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                        <label>{roro_lang[lang].UnitWeight}</label>
                                                        <input
                                                            {...register(`cargodetails.${i}.unit_weight`)}
                                                            id={`cargodetails.${i}.unit_weight`}
                                                            type="text"
                                                            name={`cargodetails.${i}.unit_weight`}
                                                            placeholder=""
                                                            className={`form-control ${errors.cargodetails?.[i]?.unit_weight ? "is-invalid" : ""
                                                                }`}
                                                            onBlur={(e) => {

                                                                calcu();
                                                            }}
                                                        />
                                                        <span className="innerLabelright">{unitOfMeasureWeight}</span>
                                                        <div className="errors">
                                                            {errors.cargodetails?.[i]?.unit_weight?.message}
                                                        </div>

                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                        <label>{roro_lang[lang].Length}</label>
                                                        <input
                                                            {...register(`cargodetails.${i}.length`)}
                                                            id={`cargodetails.${i}.length`}
                                                            type="text"
                                                            name={`cargodetails.${i}.length`}
                                                            placeholder=""
                                                            className={`form-control ${errors.cargodetails?.[i]?.length ? "is-invalid" : ""
                                                                }`}
                                                            onBlur={(e) => {

                                                                calcu();
                                                            }}
                                                        />
                                                        <span className="innerLabelright">{unitOfMeasureDimension}</span>
                                                        <div className="errors">
                                                            {errors.cargodetails?.[i]?.length?.message}
                                                        </div>

                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                        <label>{roro_lang[lang].Width}</label>
                                                        <input
                                                            {...register(`cargodetails.${i}.width`)}
                                                            id={`cargodetails.${i}.width`}
                                                            type="text"
                                                            name={`cargodetails.${i}.width`}
                                                            placeholder=""
                                                            className={`form-control ${errors.cargodetails?.[i]?.width ? "is-invalid" : ""
                                                                }`}
                                                            onBlur={(e) => {

                                                                calcu();
                                                            }}
                                                        />
                                                        <span className="innerLabelright">{unitOfMeasureDimension}</span>
                                                        <div className="errors">
                                                            {errors.cargodetails?.[i]?.width?.message}
                                                        </div>

                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                        <label>{roro_lang[lang].Height}</label>
                                                        <input
                                                            {...register(`cargodetails.${i}.height`)}
                                                            id={`cargodetails.${i}.height`}
                                                            type="text"
                                                            name={`cargodetails.${i}.height`}
                                                            placeholder=""
                                                            className={`form-control ${errors.cargodetails?.[i]?.height ? "is-invalid" : ""
                                                                }`}
                                                            onBlur={(e) => {

                                                                calcu();
                                                            }}
                                                        />
                                                        <span className="innerLabelright">{unitOfMeasureDimension}</span>
                                                        <div className="errors">
                                                            {errors.cargodetails?.[i]?.height?.message}
                                                        </div>

                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                        <label>{roro_lang[lang].TotalMeasurement}</label>
                                                        <input
                                                            {...register(`cargodetails.${i}.total_measurement`)}
                                                            readOnly
                                                            id={`cargodetails.${i}.total_measurement`}
                                                            type="text"
                                                            name={`cargodetails.${i}.total_measurement`}
                                                            placeholder=""
                                                            className={`form-control ${errors.cargodetails?.[i]?.total_measurement ? "is-invalid" : ""
                                                                }`}

                                                        />
                                                        <span className="innerLabelright">{unitOfMeasureDimension}<span>3</span></span>
                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                        <label>{roro_lang[lang].TotalWeight}</label>
                                                        <input
                                                            {...register(`cargodetails.${i}.total_weight`)}
                                                            readOnly
                                                            id={`cargodetails.${i}.total_weight`}
                                                            type="text"
                                                            name={`cargodetails.${i}.total_weight`}
                                                            placeholder=""
                                                            className={`form-control ${errors.cargodetails?.[i]?.total_weight ? "is-invalid" : ""
                                                                }`}

                                                        />
                                                        <span className="innerLabelright">{unitOfMeasureWeight}</span>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 pot-form pl-0">
                                                        <label>{roro_lang[lang].PackageandDescriptionofCargo}</label>
                                                        <textarea
                                                            {...register(`cargodetails.${i}.package_desc_cargo`)}
                                                            style={{
                                                                marginTop: `0px`,
                                                                marginBottom: `0px !important`,
                                                                height: `140px`,
                                                            }}
                                                            name={`cargodetails.${i}.package_desc_cargo`}
                                                            id={`cargodetails.${i}.package_desc_cargo`}
                                                            className={`form-control ${errors.cargodetails?.[i]?.package_desc_cargo ? "is-invalid" : ""}`}
                                                        ></textarea>
                                                        <div className="errors">
                                                            {errors.cargodetails?.[i]?.package_desc_cargo?.message}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-12 pot-form pl-0">
                                                        <label>{roro_lang[lang].AdditionalDocumentsallowedonly}</label>
                                                        <FileUploader
                                                            {...register(`cargodetails.${i}.additional_documents`)}
                                                            multiple={true}
                                                            types={fileTypes}
                                                            maxSize={5}
                                                            width={100}
                                                            name={`cargodetails.${i}.additional_documents`}
                                                            id={`cargodetails.${i}.additional_documents`}
                                                            handleChange={(val) => {
                                                                setValue(`cargodetails.${i}.additional_documents`, val);
                                                                console.log(getValues(`cargodetails.${i}.additional_documents`));
                                                                fill_val_addition_doc();
                                                            }}
                                                            label="Additional Documents allowed only * (pdf,png,jpeg) - Size(2M)"
                                                            className={` form-control ${errors.cargodetails?.[i]?.additional_documents ? "is-invalid" : ""}`}

                                                        />


                                                        <div className="errors">
                                                            {errors.cargodetails?.[i]?.additional_documents?.message}
                                                        </div>
                                                    </div>
                                                    <input style={{ backgroundColor: `#fffdf9`, opacity: `1`, border: `0` }}
                                                        {...register(`cargodetails.${i}.additional_documents_text`)}
                                                        disabled
                                                        id={`cargodetails.${i}.additional_documents_text`}
                                                        type="text"
                                                        name={`cargodetails.${i}.additional_documents_text`}
                                                        placeholder=""
                                                        className={`form-control`}

                                                    />
                                                    <div class="col-lg-10 col-sm-10 pot-form1 pt-5 pl-0">

                                                        <input
                                                            {...register(`cargodetails.${i}.edit_hazardous_details`)}
                                                            type="checkbox" name={`cargodetails.${i}.edit_hazardous_details`}
                                                            id={`cargodetails.${i}.edit_hazardous_details`}
                                                            style={{ width: `20px !important;` }}
                                                            onChange={(e) => {
                                                                var hazardousflag_new = hazardousflag;
                                                                hazardousflag_new[i] = e.target.checked;
                                                                sethazardousflag([...hazardousflag, hazardousflag_new]);
                                                            }}
                                                        /> <label for={`cargodetails.${i}.edit_hazardous_details`}>{roro_lang[lang].Thiscargoishazardous}
                                                        </label>  </div>
                                                    <div class="col-lg-12 col-sm-12 pot-form pt-5 pl-0" style={(hazardousflag[i] == true) ? { display: "inline-flex" } : { display: "none" }}>


                                                        <div class="row m-0">
                                                            <div class="col-lg-10 col-sm-10 pot-form pl-0">
                                                                <h4>{roro_lang[lang].EditHazardousDetails}</h4>
                                                            </div>
                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>{roro_lang[lang].ProperShippingName}</label>
                                                                <input

                                                                    {...register(`cargodetails.${i}.proper_shipping_name`)}
                                                                    id={`cargodetails.${i}.proper_shipping_name`}
                                                                    type="text"
                                                                    name={`cargodetails.${i}.proper_shipping_name`}
                                                                    placeholder=""
                                                                    className={`form-control ${errors.cargodetails?.[i]?.proper_shipping_name ? "is-invalid" : ""
                                                                        }`}
                                                                />
                                                                <div className="errors">
                                                                    {errors.cargodetails?.[i]?.proper_shipping_name?.message}
                                                                </div>

                                                            </div>

                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>{roro_lang[lang].UNNumber}</label>
                                                                <input
                                                                    {...register(`cargodetails.${i}.un_number`)}
                                                                    id={`cargodetails.${i}.un_number`}
                                                                    type="text"
                                                                    name={`cargodetails.${i}.un_number`}
                                                                    placeholder=""
                                                                    className={`form-control ${errors.cargodetails?.[i]?.un_number ? "is-invalid" : ""
                                                                        }`}
                                                                />
                                                                <div className="errors">
                                                                    {errors.cargodetails?.[i]?.un_number?.message}
                                                                </div>

                                                            </div>
                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>{roro_lang[lang].Class}</label>
                                                                <input
                                                                    {...register(`cargodetails.${i}.class`)}
                                                                    id={`cargodetails.${i}.class`}
                                                                    type="text"
                                                                    name={`cargodetails.${i}.class`}
                                                                    placeholder=""
                                                                    className={`form-control ${errors.cargodetails?.[i]?.class ? "is-invalid" : ""
                                                                        }`}
                                                                />
                                                                <div className="errors">
                                                                    {errors.cargodetails?.[i]?.class?.message}
                                                                </div>

                                                            </div>
                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>{roro_lang[lang].SubsidiaryRisk}</label>
                                                                <input
                                                                    {...register(`cargodetails.${i}.subsidiary_risk`)}
                                                                    id={`cargodetails.${i}.subsidiary_risk`}
                                                                    type="text"
                                                                    name={`cargodetails.${i}.subsidiary_risk`}
                                                                    placeholder=""
                                                                    className={`form-control ${errors.cargodetails?.[i]?.subsidiary_risk ? "is-invalid" : ""
                                                                        }`}
                                                                />
                                                                <div className="errors">
                                                                    {errors.cargodetails?.[i]?.subsidiary_risk?.message}
                                                                </div>

                                                            </div>
                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>{roro_lang[lang].DangerousComponent}</label>
                                                                <input
                                                                    {...register(`cargodetails.${i}.dangerous_component`)}
                                                                    id={`cargodetails.${i}.dangerous_component`}
                                                                    type="text"
                                                                    name={`cargodetails.${i}.dangerous_component`}
                                                                    placeholder=""
                                                                    className={`form-control ${errors.cargodetails?.[i]?.dangerous_component ? "is-invalid" : ""
                                                                        }`}
                                                                />
                                                                <div className="errors">
                                                                    {errors.cargodetails?.[i]?.dangerous_component?.message}
                                                                </div>

                                                            </div>
                                                            <div class="col-lg-12 col-sm-12 pot-form pl-0">
                                                                <label>  {roro_lang[lang].optionalIfyouhaveIMO} </label>

                                                                <FileUploader
                                                                    {...register(`cargodetails.${i}.imo_dangerousc`)}
                                                                    multiple={true}
                                                                    types={fileTypes}
                                                                    maxSize={5}
                                                                    name={`cargodetails.${i}.imo_dangerousc`}
                                                                    id={`cargodetails.${i}.imo_dangerousc`}
                                                                    handleChange={(val) => {
                                                                        setValue(`cargodetails.${i}.imo_dangerousc`, val);
                                                                        get_imo_dangerousc_file();
                                                                    }}
                                                                    className={`form-control ${errors.cargodetails?.[i]?.imo_dangerousc ? "is-invalid" : ""}`} />
                                                                <div className="errors">
                                                                    {errors.cargodetails?.[i]?.imo_dangerousc?.message}
                                                                </div>
                                                            </div>
                                                            <input style={{ backgroundColor: `#fffdf9`, opacity: `1`, border: `0` }}
                                                                {...register(`cargodetails.${i}.imo_dangerousc_text`)}
                                                                disabled
                                                                id={`cargodetails.${i}.imo_dangerousc_text`}
                                                                type="text"
                                                                name={`cargodetails.${i}.imo_dangerousc_text`}
                                                                placeholder=""
                                                                className={`form-control`}

                                                            />
                                                            <div class="col-lg-12 col-sm-12 pot-form pl-0">
                                                                <label> {roro_lang[lang].MaterialSafety}</label>
                                                                <FileUploader
                                                                    {...register(`cargodetails.${i}.material_safety`)}
                                                                    multiple={true}
                                                                    types={fileTypes}
                                                                    maxSize={5}
                                                                    name={`cargodetails.${i}.material_safety`}
                                                                    id={`cargodetails.${i}.material_safety`}
                                                                    handleChange={(val) => {
                                                                        setValue(`cargodetails.${i}.material_safety`, val);
                                                                        get_material_safety_file();
                                                                    }}
                                                                    className={`form-control ${errors.cargodetails?.[i]?.material_safety ? "is-invalid" : ""}`} />
                                                                <div className="errors">
                                                                    {errors.cargodetails?.[i]?.material_safety?.message}
                                                                </div>
                                                            </div>
                                                            <input style={{ backgroundColor: `#fffdf9`, opacity: `1`, border: `0` }}
                                                                {...register(`cargodetails.${i}.material_safety_text`)}
                                                                disabled
                                                                id={`cargodetails.${i}.material_safety_text`}
                                                                type="text"
                                                                name={`cargodetails.${i}.material_safety_text`}
                                                                placeholder=""
                                                                className={`form-control`}

                                                            />
                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>
                                                                {roro_lang[lang].UnitofMeasureforWeight} 
                                                                </label>

                                                                <ul class="sec-pot-check new-pot-radio"><li><div class="custom-control custom-radio"> <input
                                                                    {...register(`cargodetails.${i}.pickup_unit_of_dimension`)}
                                                                    type="radio"
                                                                    name={`cargodetails.${i}.pickup_unit_of_dimension`}
                                                                    id={`scargodetails.${i}.pickup_unit_of_dimension`}
                                                                    value="Kilograms (kg)"
                                                                    onClick={(e) => {
                                                                        setValue(`cargodetails.${i}.pickup_unit_of_dimension`, 'Kilograms (kg)');
                                                                    }}

                                                                    placeholder=""
                                                                    checked
                                                                    className={`custom-control-input`}
                                                                /><label class="custom-control-label radio-nane" for={`scargodetails.${i}.pickup_unit_of_dimension`}>Kilograms (kg)</label></div></li>
                                                                    <li><div class="custom-control custom-radio"><input
                                                                        {...register(`cargodetails.${i}.pickup_unit_of_dimension`)}
                                                                        type="radio"
                                                                        name={`cargodetails.${i}.pickup_unit_of_dimension`}
                                                                        id={`cargodetails.${i}.pickup_unit_of_dimension`}
                                                                        value="Pound (lbs)"
                                                                        onClick={(e) => {
                                                                            setValue(`cargodetails.${i}.pickup_unit_of_dimension`, 'Pound (lbs)');
                                                                        }}
                                                                        placeholder=""
                                                                        className={`custom-control-input`}
                                                                    /><label class="custom-control-label radio-nane" for={`cargodetails.${i}.pickup_unit_of_dimension`} >Pound (lbs)</label></div></li></ul>


                                                            </div>
                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>
                                                                {roro_lang[lang].UnitofMeasureforTemperature} 
                                                                </label>

                                                                <ul class="sec-pot-check new-pot-radio"><li><div class="custom-control custom-radio"> <input
                                                                    {...register(`cargodetails.${i}.pickup_unit_of_temperature`)}
                                                                    type="radio"
                                                                    name={`cargodetails.${i}.pickup_unit_of_temperature`}
                                                                    id={`scargodetails.${i}.pickup_unit_of_temperature`}
                                                                    placeholder=""
                                                                    value="Celsius (C)"
                                                                    onClick={(e) => {
                                                                        setValue(`cargodetails.${i}.pickup_unit_of_temperature`, 'Celsius (C)');
                                                                    }}
                                                                    checked
                                                                    className={`custom-control-input`}
                                                                /><label class="custom-control-label radio-nane" for={`scargodetails.${i}.pickup_unit_of_temperature`}>Celsius (C)</label></div></li>
                                                                    <li><div class="custom-control custom-radio"><input
                                                                        {...register(`cargodetails.${i}.pickup_unit_of_temperature`)}
                                                                        type="radio"
                                                                        name={`cargodetails.${i}.pickup_unit_of_temperature`}
                                                                        id={`s1cargodetails.${i}.pickup_unit_of_temperature`}
                                                                        value="Fahrenheit (F)"
                                                                        onClick={(e) => {
                                                                            setValue(`cargodetails.${i}.pickup_unit_of_temperature`, 'Fahrenheit (F)');
                                                                        }}
                                                                        placeholder=""
                                                                        className={`custom-control-input`}
                                                                    /><label class="custom-control-label radio-nane" for={`s1cargodetails.${i}.pickup_unit_of_temperature`} >Fahrenheit (F)</label></div></li></ul>


                                                            </div>
                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>{roro_lang[lang].NetWeight}</label>
                                                                <input
                                                                    {...register(`cargodetails.${i}.net_weight`)}
                                                                    id={`cargodetails.${i}.net_weight`}
                                                                    type="text"
                                                                    name={`cargodetails.${i}.net_weight`}
                                                                    placeholder=""
                                                                    className={`form-control ${errors.cargodetails?.[i]?.net_weight ? "is-invalid" : ""
                                                                        }`}
                                                                />
                                                                <div className="errors">
                                                                    {errors.cargodetails?.[i]?.net_weight?.message}
                                                                </div>

                                                            </div>
                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>{roro_lang[lang].GrossWeight}</label>
                                                                <input
                                                                    {...register(`cargodetails.${i}.gross_weight`)}
                                                                    id={`cargodetails.${i}.gross_weight`}
                                                                    type="text"
                                                                    name={`cargodetails.${i}.gross_weight`}
                                                                    placeholder=""
                                                                    className={`form-control ${errors.cargodetails?.[i]?.gross_weight ? "is-invalid" : ""
                                                                        }`}
                                                                />
                                                                <div className="errors">
                                                                    {errors.cargodetails?.[i]?.gross_weight?.message}
                                                                </div>

                                                            </div>


                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>{roro_lang[lang].FlashpointTemperature}</label>
                                                                <input
                                                                    {...register(`cargodetails.${i}.flash_point_temp`)}
                                                                    id={`cargodetails.${i}.flash_point_temp`}
                                                                    type="text"
                                                                    name={`cargodetails.${i}.flash_point_temp`}
                                                                    placeholder=""
                                                                    className={`form-control ${errors.cargodetails?.[i]?.flash_point_temp ? "is-invalid" : ""
                                                                        }`}
                                                                />
                                                                <div className="errors">
                                                                    {errors.cargodetails?.[i]?.flash_point_temp?.message}
                                                                </div>

                                                            </div>
                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>{roro_lang[lang].NetExplosiveQuantity}</label>
                                                                <input
                                                                    {...register(`cargodetails.${i}.net_explosive_quantity`)}
                                                                    id={`cargodetails.${i}.net_explosive_quantity`}
                                                                    type="text"
                                                                    name={`cargodetails.${i}.net_explosive_quantity`}
                                                                    placeholder=""
                                                                    className={`form-control ${errors.cargodetails?.[i]?.net_explosive_quantity ? "is-invalid" : ""
                                                                        }`}
                                                                />
                                                                <div className="errors">
                                                                    {errors.cargodetails?.[i]?.net_explosive_quantity?.message}
                                                                </div>

                                                            </div>
                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>{roro_lang[lang].PackingGroup}</label>
                                                                <input
                                                                    {...register(`cargodetails.${i}.packing_group`)}
                                                                    id={`cargodetails.${i}.packing_group`}
                                                                    type="text"
                                                                    name={`cargodetails.${i}.packing_group`}
                                                                    placeholder=""
                                                                    className={`form-control ${errors.cargodetails?.[i]?.packing_group ? "is-invalid" : ""
                                                                        }`}
                                                                />
                                                                <div className="errors">
                                                                    {errors.cargodetails?.[i]?.packing_group?.message}
                                                                </div>

                                                            </div>

                                                            <div class="col-lg-12 col-sm-12 pot-form pl-0">
                                                                <label>{roro_lang[lang].PackageandDescriptionofCargo}</label>
                                                                <textarea
                                                                    {...register(`cargodetails.${i}.package_description`)}
                                                                    style={{
                                                                        marginTop: `0px`,
                                                                        marginBottom: `0px !important`,
                                                                        height: `140px`,
                                                                    }}
                                                                    name={`cargodetails.${i}.package_description`}
                                                                    id={`cargodetails.${i}.package_description`}
                                                                    className={`form-control ${errors.cargodetails?.[i]?.package_description ? "is-invalid" : ""}`}
                                                                ></textarea>

                                                            </div>
                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>
                                                                {roro_lang[lang].MarinePollution} 
                                                                </label>

                                                                <ul class="sec-pot-check new-pot-radio"><li><div class="custom-control custom-radio"> <input
                                                                    {...register(`cargodetails.${i}.marine_pollution`)}
                                                                    type="radio"
                                                                    name={`cargodetails.${i}.marine_pollution`}
                                                                    id={`cargodetails.${i}.marine_pollution`}
                                                                    placeholder=""
                                                                    checked
                                                                    className={`custom-control-input`}
                                                                /><label class="custom-control-label radio-nane" for={`cargodetails.${i}.marine_pollution`}>Yes</label></div></li>
                                                                    <li><div class="custom-control custom-radio"><input
                                                                        {...register(`cargodetails.${i}.marine_pollution`)}
                                                                        type="radio"
                                                                        name={`cargodetails.${i}.marine_pollution`}
                                                                        id={`s1cargodetails.${i}.marine_pollution`}
                                                                        placeholder=""
                                                                        className={`custom-control-input`}
                                                                    /><label class="custom-control-label radio-nane" for={`s1cargodetails.${i}.marine_pollution`} >No</label></div></li></ul>


                                                            </div>
                                                            <div class="col-lg-6 col-sm-6 pot-form pl-0">
                                                                <label>
                                                                {roro_lang[lang].LimitedQuantity}  
                                                                </label>

                                                                <ul class="sec-pot-check new-pot-radio"><li><div class="custom-control custom-radio"> <input
                                                                    {...register(`cargodetails.${i}.limited_quantity`)}
                                                                    type="radio"
                                                                    name={`cargodetails.${i}.limited_quantity`}
                                                                    id={`cargodetails.${i}.limited_quantity`}
                                                                    placeholder=""
                                                                    checked
                                                                    className={`custom-control-input`}
                                                                /><label class="custom-control-label radio-nane" for={`cargodetails.${i}.limited_quantity`}>Yes</label></div></li>
                                                                    <li><div class="custom-control custom-radio"><input
                                                                        {...register(`cargodetails.${i}.limited_quantity`)}
                                                                        type="radio"
                                                                        name={`cargodetails.${i}.limited_quantity`}
                                                                        id={`s1cargodetails.${i}.limited_quantity`}
                                                                        placeholder=""
                                                                        className={`custom-control-input`}
                                                                    /><label class="custom-control-label radio-nane" for={`s1cargodetails.${i}.limited_quantity`} >No</label></div></li></ul>


                                                            </div>
                                                            <div class="col-lg-12 col-sm-12 pot-form pl-0">
                                                                <label>{roro_lang[lang].Comments} </label>
                                                                <textarea
                                                                    {...register(`cargodetails.${i}.comments`)}
                                                                    style={{
                                                                        marginTop: `0px`,
                                                                        marginBottom: `0px !important`,
                                                                        height: `140px`,
                                                                    }}
                                                                    name={`cargodetails.${i}.comments`}
                                                                    id={`cargodetails.${i}.comments`}
                                                                    className={`form-control ${errors.cargodetails?.[i]?.comments ? "is-invalid" : ""}`}
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {i != 0 ? (
                                                        <div class="col-lg-2 col-sm-2">
                                                            <a
                                                                class="remove_more_bt"
                                                                href="javascript:void(0)"
                                                                onClick={() => cargodetailsRemove(i)}
                                                            >
                                                                <h4>
                                                                    - <span>{roro_lang[lang].Delete}</span>
                                                                </h4>
                                                            </a>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>)

                                            })}
                                            <div class="col-lg-4 col-sm-12">
                                                <a
                                                    id="add_more_bt"
                                                    href="javascript:void(0)"
                                                    onClick={addcargodetails}
                                                >
                                                    <h4>
                                                        + <span>{roro_lang[lang].AddCargodetails}</span>
                                                    </h4>
                                                    <br></br>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex si-booking-next">
                                        <div class="col-lg-12 col-sm-12 pot-form pl-0">
                                            <div class="">
                                            <HCaptcha
                                                sitekey="a8400b17-0f2c-4116-85cb-91a16f434d49"
                                                onVerify={setToken}
                                                ref={captchaRef}
                                                apihost="https://cn1.hcaptcha.com"
                                                endpoint="https://cn1.hcaptcha.com"
                                                assethost="https://assets-cn1.hcaptcha.com"
                                                imghost="https://imgs-cn1.hcaptcha.com"
                                                reportapi="https://reportapi-cn1.hcaptcha.com"
                                                />
                                                {errors.hcpactha && (
                                                    <span class="errors">{errors.message.hcpactha}</span>
                                                )}

                                                <div className="booking-next">
                                                    <button
                                                        type="submit"
                                                        name="submit"
                                                        id="submit"
                                                        class="btn btn-default hovereffect vgm-btn-submit mr-1"
                                                        ref={buttonRef}
                                                    >
                                                        {SubmitButton}
                                                    </button></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Modal
                        size="md"
                        show={videoShow}
                        onHide={() => videoShow}
                        dialogClassName="modal-90w youtubePopup"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                    >

                        <Modal.Body>

                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <p>{roro_lang[lang].Fromtheoptionsbelowselectifyouarea} </p>
                                    </div>
                                    <div class="col-md-7 col-lg-7 col-sm-12">
                                        <button type="submit" onClick={() => setVideoShow(false)} class="btn btn-default hovereffect portal-searc mt-1 w-100">{roro_lang[lang].Businessrepresentative}</button>
                                    </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12">
                                        <Link className=""
                                            to="roro-private"
                                        ><button type="submit" class="btn btn-default hovereffect portal-searc mt-1 w-100">{roro_lang[lang].Privateperson} </button></Link>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                    </Modal>
                </div>
            </section>
        </Layout>);
}
export default Roro;