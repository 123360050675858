import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import Inner_container from '../../innerpages/inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import inside_heading from '../../../../src/assets/inside-heading.png';
import demurrage_and_detention_tariff_lang from '../../lang/demurrage-and-detention-tariff'

const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class demurrage_and_detention_tariff extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            demselect: [],


        };

    }
    async componentDidMount() {

        const headers = {

            type: "application/json",
            Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
        };


        const response = await axios.post(Api.userdata.url + '/demselect', qs.stringify({ 'country_code': this.state.country_code }, { parseArrays: false }), { headers: headers });
        const json = await response.data;
        if (json['status'] != 0) {
            this.setState({ demselect: json['message'] });
        }
        else {
            this.setState({ demselect: [] });
        }


    }
    open_dem() {
        var selectElem = document.getElementById('filenamee').value;
        window.open(selectElem);

    }
    render() {
        var lang = window.localStorage.getItem("lang");
        return (

            <Layout>
                <Inner_container innerpage="demurrage_and_detention_tariff" />
                <section class="inner-center n-40">
                    <div class="container">
                        <div class="row m-0">
                            <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12">
                                <form class="point-to" >

                                    <div class="form-fback">
                                        <div class="row m-0">
                                            <div class="col-lg-12 col-sm-12  pl-0">


                                                <div class="carco-track local">
                                                    <label>{demurrage_and_detention_tariff_lang[lang].DemurrageandDetentionTariff}   </label>


                                                    <form action="#">

                                                        <select id="filenamee" name="filenamee" >
                                                            {this.state.demselect.map(data => {
                                                                var dem = data['dem'];
                                                                var filep = data['filep'];
                                                                {

                                                                    {

                                                                        if (data['more_lang_details'] != null && data['more_lang_details'] != '') {
                                                                            const obj = JSON.parse(data['more_lang_details']);

                                                                            if (typeof obj[lang]  !== "undefined") {
                                                                                dem = obj[lang]['dem']
                                                                                if (obj[lang]['filep'] != '') {
                                                                                    filep = obj[lang]['filep']
                                                                                }

                                                                            }

                                                                        }
                                                                    }
                                                                }
                                                                return (<option value={Api.userdata.siteurl + "gsl-dashboard/uploads/support_pdf/" + filep}>{(data['country_code'] != "HK") ? data['country_code'] + " - " + dem : dem}</option>)
                                                            }

                                                            )}
                                                        </select>
                                                        <input type="button" id="dem-det" formtarget="_blank" value={demurrage_and_detention_tariff_lang[lang].Find } class="btn btn-default hovereffect grey-btn" onClick={this.open_dem} />
                                                    </form>



                                                </div>



                                            </div>

                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}