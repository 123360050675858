const homelang =
{
    "en": {
        "ADDRESS": "ADDRESS",
        "PHONE": "PHONE",
        "EMAIL": "EMAIL",
        "SENDMESSAGE": "SEND MESSAGE",
        "Submit": "Submit",
        "Typethecodeyousee": "Type the code you see",
     
    },
    "cn": {
        "ADDRESS": "地址",
        "PHONE": "電話",
        "EMAIL": "電郵",
        "SENDMESSAGE": "發送",
        "Submit": "提交",
        "Typethecodeyousee": "請輸入驗證碼",
    },
    "zh": {
        "ADDRESS": "地址",
        "PHONE": "电话",
        "EMAIL": "电邮",
        "SENDMESSAGE": "发送",
        "Submit": "递交",
        "Typethecodeyousee": "请输入验证码",
    },
    "ko": {
        "ADDRESS": "주소",
        "PHONE": "전화",
        "EMAIL": "Email",
        "SENDMESSAGE": "메시지 전송",
        "Submit": "제출",
        "Typethecodeyousee": "보이는 코드를 입력하시오",
    },
    "vi": {
        "ADDRESS": "ĐỊA CHỈ",
        "PHONE": "ĐIỆN THOẠI",
        "EMAIL": "THƯ ĐIỆN TỬ",
        "SENDMESSAGE": "GỬI TIN NHẮN",
        "Submit": "Nhấn để gửi",
        "Typethecodeyousee": "Vui lòng nhập mã mà bạn thấy",
    },
    "th": {
        "ADDRESS": "ที่อยู่",
        "PHONE": "เบอร์โทรศัพท์",
        "EMAIL": "อีเมล",
        "SENDMESSAGE": "ส่งข้อความ",
        "Submit": "ส่ง",
        "Typethecodeyousee": "โปรดใส่รหัสตามภาพ",
    },
    "in": {
        "ADDRESS": "ALAMAT",
        "PHONE": "TELEPON",
        "EMAIL": "EMAIL",
        "SENDMESSAGE": "KIRIM PESAN",
        "Submit": "Kirim",
        "Typethecodeyousee": "Ketik kode yang Anda lihat",
     
    },
    "ja": {
        "ADDRESS": "住所",
        "PHONE": "電話番号",
        "EMAIL": "電子メール",
        "SENDMESSAGE": "メッセージを送信",
        "Submit": "投稿する",
        "Typethecodeyousee": "表示されているコードを入力してください",
     
    },
}
export default homelang;