const homelang =
{
    "en": {
        "BookingSubmission": "Booking Submission",
        "BookingSubmission1": "Submit bookings online",
        "Error": "Error",
        "InvalidPortLoading": "Invalid Port of Loading",
        "DownloadBookinginstructionformtemplate": "Download Booking instruction form template",
        "ContactPersonName": "Contact Person Name",
        "Email": "Email",
        "IntendedShippingDate": "Intended Shipping Date",
        "PlaceofReceipt": "Place of Receipt",
        "PortofLoading": "Port of Loading",
        "CUCCCode": "CUCC Code(Valid Customer Code Provide by GSL)",
        "ExcelFileonly": "Excel File only (xlsx)",
        "Typethecodeyousee": "Type the code you see ",
        "Submit": "Submit",
        "Country": "Country",
  

    },
    "cn": {
        "BookingSubmission": "訂艙",
        "BookingSubmission1": "網上訂艙",
        "Error": "錯誤",
        "InvalidPortLoading": "裝貨港不正確",
        "DownloadBookinginstructionformtemplate": "下載訂艙表格",
        "ContactPersonName": "聯絡人姓名",
        "Email": "電郵",
        "IntendedShippingDate": "預計運貨日期",
        "PlaceofReceipt": "收貨地",
        "PortofLoading": "載貨港",
        "CUCCCode": "金星航運提供的客戶代碼",
        "ExcelFileonly": "Excel 文件",
        "Typethecodeyousee": "請輸入驗證碼",
        "Submit": "提交",
        "Country": "國家",
        
    },
    "zh": {
        "BookingSubmission": "订舱",
        "BookingSubmission1": "网上订舱",
        "Error": "错误",
        "InvalidPortLoading": "装货港不正确",
        "DownloadBookinginstructionformtemplate": "下载订舱表格",
        "ContactPersonName": "联络人姓名",
        "Email": "电邮",
        "IntendedShippingDate": "预计运货日期",
        "PlaceofReceipt": "收货地",
        "PortofLoading": "载货港",
        "CUCCCode": "金星航运提供的客户代码",
        "ExcelFileonly": "Excel 文件",
        "Typethecodeyousee": "请输入验证码",
        "Submit": "递交",
        "Country": "国家",
        
    },
    "ko": {
        "BookingSubmission": "예약 제출",
        "BookingSubmission1": "온라인 예약 제출",
        "Error": "장애",
        "InvalidPortLoading": "선적항 오류",
        "DownloadBookinginstructionformtemplate": "예약 지시서 양식 받기",
        "ContactPersonName": "연락 담당 이름",
        "Email": "Email",
        "IntendedShippingDate": "선적 예정 일자",
        "PlaceofReceipt": "화물 인도 장소",
        "PortofLoading": "선적항",
        "CUCCCode": "화주 코드",
        "ExcelFileonly": "Excel file만 사용",
        "Typethecodeyousee": "보이는 코드를 입력하시오",
        "Submit": "제출",
        "Country": "국가",
        
    },
    "vi": {
        "BookingSubmission": "Đặt chỗ",
        "BookingSubmission1": "Đặt chỗ trực tuyến",
        "Error": "Lỗi",
        "InvalidPortLoading": "Cảng xếp hàng không tồn tại",
        "DownloadBookinginstructionformtemplate": "Tải xuống mẫu đặt chỗ",
        "ContactPersonName": "Tên người liên hệ",
        "Email": "Thư điện tử",
        "IntendedShippingDate": "Ngày dự định xuất hàng",
        "PlaceofReceipt": "Nơi nhận hàng",
        "PortofLoading": "Cảng xếp hàng",
        "CUCCCode": "Mã CUCC (mã khách hàng cung cấp bởi GSL)",
        "ExcelFileonly": "Chỉ nhận tệp excel (xlsx)",
        "Typethecodeyousee": "Vui lòng nhập mã mà bạn thấy",
        "Submit": "Nhấn để gửi",
        "Country": "Quốc gia",
        
    },
    "th": {
        "BookingSubmission": "จองระวางเรือ",
        "BookingSubmission1": "ส่งจองระวางเรือแบบออนไลน์",
        "Error": "ข้อผิดพลาด",
        "InvalidPortLoading": "สถานที่ต้นทางที่ส่งออก ไม่ถูกต้อง",
        "DownloadBookinginstructionformtemplate": "ดาวน์โหลดเทมเพลตแบบฟอร์มคำสั่งจอง",
        "ContactPersonName": "ชื่อผู้ติดต่อ",
        "Email": "อีเมล",
        "IntendedShippingDate": "วันที่ต้องการขนส่ง",
        "PlaceofReceipt": "สถานที่รับสินค้า",
        "PortofLoading": "สถานที่ต้นทางที่ส่งออก",
        "CUCCCode": "รหัส CUCC (รหัสลูกค้าที่ถูกต้องให้โดย GSL)",
        "ExcelFileonly": "ไฟล์ Excel เท่านั้น (xlsx)",
        "Typethecodeyousee": "โปรดใส่รหัสตามภาพ",
        "Submit": "ส่ง",
        "Country": "ประเทศ",
        
    },
    "in": {
        "BookingSubmission": "Pengajuan Pesanan",
        "BookingSubmission1": "Ajukan pesanan secara online",
        "Error": "Kesalahan",
        "InvalidPortLoading": "Pelabuhan muat tidak sah",
        "DownloadBookinginstructionformtemplate": "Unduh template lembar instruksi pesanan",
        "ContactPersonName": "Nama kontak person",
        "Email": "Email",
        "IntendedShippingDate": "Tanggal pengiriman yang dimaksudkan",
        "PlaceofReceipt": "Tempat penerimaan",
        "PortofLoading": "Pelabuhan Muat",
        "CUCCCode": "Kode CUCC (kode pelanggan yang valid dari GSL)",
        "ExcelFileonly": "Hanya berkas Excel (xlsx)",
        "Typethecodeyousee": "Masukkkan kode yang anda lihat",
        "Submit": "Ajukan",
        "Country": "Negara",
        
  

    },
    "ja": {
        "BookingSubmission": "予約の送信",
        "BookingSubmission1": "オンラインで予約を送信",
        "Error": "エラー",
        "InvalidPortLoading": "積地港が無効です",
        "DownloadBookinginstructionformtemplate": "予約指示フォームテンプレートをダウンロードします",
        "ContactPersonName": "担当者の氏名",
        "Email": "メールアドレス",
        "IntendedShippingDate": "出荷予定日",
        "PlaceofReceipt": "荷受地",
        "PortofLoading": "積地港",
        "CUCCCode": "CUCCコード（GSLが提供する有効な顧客コード）",
        "ExcelFileonly": "Excelファイル（xlsx）のみ",
        "Typethecodeyousee": "表示されたコードを入力します",
        "Submit": "送信",
        "Country": "国",
  

    },
}
export default homelang;