import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import Inner_container from '../../innerpages/inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import inside_heading from '../../../../src/assets/inside-heading.png';
import local_info_lang from '../../lang/local-info'



const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class Local_info extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            formsdocs: [],
            local_info: [],
            local_schedules: []

        };

    }
    async componentDidMount() {

        const headers = {

            type: "application/json",
            Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
        };

        const formsdocs_response = await axios.post(Api.userdata.url + '/formsdocs', qs.stringify({ 'country_code': window.$country_code }, { parseArrays: false }), { headers: headers });
        const formsdocs_json = await formsdocs_response.data;
        if (formsdocs_json['status'] != 0) {
            this.setState({ formsdocs: formsdocs_json['message'] });
        }
        else {
            this.setState({ formsdocs: [] });
        }


        const local_info_response = await axios.post(Api.userdata.url + '/local_info', qs.stringify({ 'country_code': window.$country_code }, { parseArrays: false }), { headers: headers });
        const local_info_json = await local_info_response.data;
        if (local_info_json['status'] != 0) {
            this.setState({ local_info: local_info_json['message'] });
        }
        else {
            this.setState({ local_info: [] });
        }


        const local_schedules_response = await axios.post(Api.userdata.url + '/local_schedules', qs.stringify({ 'country_code': window.$country_code }, { parseArrays: false }), { headers: headers });
        const local_schedules_json = await local_schedules_response.data;

        if (local_schedules_json['status'] != 0) {
            this.setState({ local_schedules: local_schedules_json['message'] });
        }
        else {
            this.setState({ local_schedules: [] });
        }



    }
    open_formsdocs() {
        var selectElem = document.getElementById('filenameef').value;
        window.open(selectElem);

    }

    open_local_info() {
        var selectElem = document.getElementById('filenames').value;
        window.open(selectElem);

    }
    open_local_schedules() {
        var selectElem = document.getElementById('filenamee1').value;
        window.open(selectElem);

    }
    render() {
        var lang = window.localStorage.getItem("lang");
       
        return (

            <Layout>
                <Inner_container innerpage="local_info" />



                <section class="inner-center n-40">
                    <div class="container">
                        <div class="row m-0">
                            <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12">
                                <form class="point-to" >

                                    <div class="form-fback">
                                        <div class="row m-0">
                                            <div class="col-lg-12 col-sm-12 col-md-12 local-inf-back">


                                                <div class="carco-track local">
                                                    <label> {local_info_lang[lang].FormsandDocuments}  </label>


                                                    <form >
                                                        <select id="filenameef">
                                                            {this.state.formsdocs.map(data => {
                                                                var formsdoc = data['formsdoc'];
                                                                var filepf = data['filepf'];
                                                                {
                                                                    {

                                                                        if (data['more_lang_details'] != null && data['more_lang_details'] != '') {
                                                                            const obj = JSON.parse(data['more_lang_details']);

                                                                            if (typeof obj[lang] !== "undefined") {
                                                                                formsdoc = obj[lang]['formsdoc']
                                                                                if (obj[lang]['filepf'] != '') {
                                                                                    filepf = obj[lang]['filepf']
                                                                                }

                                                                            }

                                                                        }
                                                                    }
                                                                }
                                                                return (<option value={Api.userdata.siteurl + "gsl-dashboard/uploads/support_pdf/" + filepf}>{data['country_code'] + "-" + formsdoc}</option>)

                                                            })}

                                                        </select>
                                                        <input type="submit" id="activetest" value={local_info_lang[lang].Find} class="btn btn-default hovereffect grey-btn" onClick={this.open_formsdocs} /></form>
                                                </div>




                                                <div class="carco-track local">
                                                    <label> {local_info_lang[lang].LocalCharge}  </label>
                                                    <select id="filenames">
                                                        {this.state.local_info.map(data => {
                                                            var title = data['title'];
                                                            var file_name = data['file_name'];
                                                            {
                                                                {

                                                                    if (data['more_lang_details'] != null && data['more_lang_details'] != '') {
                                                                        const obj = JSON.parse(data['more_lang_details']);

                                                                        if (typeof obj[lang] !== "undefined") {
                                                                            title = obj[lang]['title']
                                                                            if (obj[lang]['file_name'] != '') {
                                                                                file_name = obj[lang]['file_name']
                                                                            }

                                                                        }

                                                                    }
                                                                }
                                                            }
                                                            return <option value={Api.userdata.siteurl + "tariff/" + file_name}>{(data['country_code'] != "HK") ? data['country_code'] + "-" + title : title}</option>
                                                        })}
                                                    </select>
                                                    <input type="button" id="local_charge" value={local_info_lang[lang].Find} class="btn btn-default hovereffect grey-btn" onClick={this.open_local_info} />
                                                </div>
                                                <div class="carco-track local">
                                                    <label> {local_info_lang[lang].LocalSchedules}  </label>

                                                    <form>
                                                        <select id="filenamee1">

                                                            {this.state.local_schedules.map(data => {
                                                                var title = data['title'];
                                                                var file_name = data['file_name'];
                                                                {
                                                                    {

                                                                        if (data['more_lang_details'] != null && data['more_lang_details'] != '') {
                                                                            const obj = JSON.parse(data['more_lang_details']);

                                                                            if (typeof obj[lang] !== "undefined") {
                                                                                title = obj[lang]['title']
                                                                                if (obj[lang]['file_name'] != '') {
                                                                                    file_name = obj[lang]['file_name']
                                                                                }

                                                                            }

                                                                        }
                                                                    }
                                                                }
                                                                return <option value={Api.userdata.siteurl + "tariff/" + file_name}>{(data['country_code'] != "HK") ? data['country_code'] + "-" + title : title}</option>
                                                            })}

                                                        </select>
                                                        <input type="button" id="local_schedules" value={local_info_lang[lang].Find} class="btn btn-default hovereffect grey-btn" onClick={this.open_local_schedules} />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}