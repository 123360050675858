const homelang =
{
    "en": {
        "SOB_India": "S.O.B - India",
        "SOB_India1": "Get Shipment On Board Certificate",
        "BillofLadingNo": "Bill of Lading No",
        "DownloadCertificate": "Download Certificate",
        "NoRecordFound": "No Record Found",

    },
    "cn": {
        "SOB_India": "S.O.B. - 印度",
        "SOB_India1": "取得貨物裝船證書",
        "BillofLadingNo": "提單號",
        "DownloadCertificate": "下載證書",
        "NoRecordFound": "沒有記錄",
        
    },
    "zh": {
        "SOB_India": "S.O.B. - 印度",
        "SOB_India1": "取得货物装船证书",
        "BillofLadingNo": "提单号",
        "DownloadCertificate": "下载证书",
        "NoRecordFound": "没有记录",
        
    },
    "ko": {
        "SOB_India": "S.O.B - India",
        "SOB_India1": "Get Shipment On Board Certificate",
        "BillofLadingNo": "Bill of Lading No",
        "DownloadCertificate": "Download Certificate",
        "NoRecordFound": "조회된 기록 없음",
        
    },
    "vi": {
        "SOB_India": "S.O.B - Ấn độ",
        "SOB_India1": "Giấy chứng nhận lô hàng được vận chuyển trên tàu",
        "BillofLadingNo": "Số vận đơn",
        "DownloadCertificate": "Tải xuống chứng nhận",
        "NoRecordFound": "Không tìm thấy dữ liệu",
        
    },
    "th": {
        "SOB_India": "S.O.B - India",
        "SOB_India1": "รับใบรับรอง SOB",
        "BillofLadingNo": "หมายเลข BL",
        "DownloadCertificate": "ดาวน์โหลดใบรับรอง",
        "NoRecordFound": "ไม่พบข้อมูล",
        
    },
    "in": {
        "SOB_India": "S.O.B - India",
        "SOB_India1": "Dapatkan Sertifikat Shipment On Board",
        "BillofLadingNo": "No. Bill of Lading",
        "DownloadCertificate": "Unduh Sertifikat",
        "NoRecordFound": "Data tidak ditemukan",

    },
    "ja": {
        "SOB_India": "S.O.B -インド",
        "SOB_India1": "船積証明を取得します",
        "BillofLadingNo": "船荷証券番号",
        "DownloadCertificate": "証明書をダウンロード",
        "NoRecordFound": "記録が見つかりません",

    },
}
export default homelang;