


import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import Services from "../../../components/get_in_touch/services";
import Packages from "../../../components/get_in_touch/packages_block";
import Inland from "../../../../src/assets/Inland.jpeg";
import abt_icn from "../../../../src/assets/abt-icn.png";
import Inner_container from "../../innerpages/inner-container";
import Api from "../../../../package.json";
import * as axios from "axios";
import { Carousel } from "react-responsive-carousel";
import who_we_are from "../../../../src/assets/who-we-are.png";
import Select from "react-select";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import Register_eBL_Service from "../../../assets/Register-eBL-Service.jpg";
import ebl_img from "../../../assets/ebl-img.png";
import ebl_img_1 from "../../../assets/ebl-img-old.png";
import "../tools/css/ebl.css";
import { useForm, Controller } from "react-hook-form";
import swal from "sweetalert";
import ebl_service_lang from '../../lang/ebl_service';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { NavLink as Link } from "react-router-dom";

const qs = require("qs");
var jwt = require("jsonwebtoken");

const Ebl_service = () => {

  var lang = window.localStorage.getItem("lang");
  if (window.localStorage.getItem("lang") == null) {
    lang = 'en';
    window.localStorage.setItem("lang", 'en');
  }

  const headers = {
    type: "application/json",
    Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
  };
  const [country_code, setCountryCode] = useState(window.$country_code);
  const [country_list, setcountry_list] = useState([]);
  const [country_phone_code, setcountry_phone_code] = useState([]);
  const [country_short_code, setcountry_short_code] = useState([]);
  const [ebl_captcha, setebl_captcha] = useState([]);
  const [SubmitButton, setSubmitButton] = useState(ebl_service_lang[lang].IAgreeAndSubmit);
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // save_enquiry(data);
    //console.log(data)
  //  buttonRef.current.disabled = true;
    window.open(
      'https://register.wavebl.com/gsl/?trustedOrganizationCode=gsl&registerFormId=GSL&referralCompanyCode=GSL',
      '_blank' // <- This is what makes it open in a new window.
    );
    //setSubmitButton(ebl_service_lang[lang].Submit + '...');
    //save_ebl(data);
  };
  const buttonRef = useRef();
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const save_ebl = async (data) => {
    if (token != null) {
      const response = await axios.post(
        Api.userdata.url + "/save_ebl_form",
        qs.stringify({
          country_code: country_code,
          company_name: data.company_name,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          job_title: data.job_title,
          location: data.location,
          country_short_code: country_short_code,
          main_industry: data.main_industry,
          mobile: data.mobile,
          phoneone: data.phoneone,
          reference_number: data.reference_number,
          sales_contact_name: data.sales_contact_name,
          ebl_captcha: token,
        }), { headers: headers }
      );
      const json = await response.data;
      if (json["status"] == "OK") {
        swal("Successful Submitted", json["message"], "success").then((value) => {
          window.location.reload(false);
        });
      } else {
        swal("Error !", json["message"], "error");
      }
    } else {
      swal("Error !", "Invalid captcha", "error");
    }

    buttonRef.current.disabled = false;
    setSubmitButton(ebl_service_lang[lang].IAgreeAndSubmit);
    //var jsval = JSON.parse([0]["details"]);
    //setHeadercaptcha(json["captcha"]);
    //setHeaderJsonContent(jsval);
  };
  const getLoadData = async () => {

    const response = await axios.post(
      Api.userdata.url + "/country_list",
      qs.stringify({ token: "2" }, { parseArrays: false }),
      { headers: headers }
    );
    const json = await response.data;
    setcountry_list(json["message"]);
    setebl_captcha(json["captcha"]);

    const response_ebl_captcha = await axios.post(
      Api.userdata.url + "/ebl_captcha",
      qs.stringify({ token: "2" }, { parseArrays: false }), { headers: headers }
    );
    /*     const json_ebl_captcha = await response_ebl_captcha.data;
        setebl_captcha(json_ebl_captcha["message"]); */

  };
  useEffect(() => {
    getLoadData();
  }, []);

  return (
    <Layout>


      <section class="inner-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12">
              <div class="row m-0" style={{ paddingTop: `10rem` }}>
                <div class="col-lg-7 col-md-12 col-sm-12 pot-form ">
                  <div class="dout-inner">
                    <h3>{ebl_service_lang[lang].eBLService}</h3>
                    <h4>{ebl_service_lang[lang].eBLService1}</h4>
                    <p>{ebl_service_lang[lang].eBLService2}</p>
                  </div>
                  <div>
                    <br></br>
                    <br></br>

                    <p>{ebl_service_lang[lang].GSLleadsthewaywitharevolutionary}. </p>
                    <p>
                      {ebl_service_lang[lang].Weinviteyoutojoin}.
                    </p>
                    <p>
                      {ebl_service_lang[lang].Youcanstarttoday}
                    </p>
                    <p>
                      {ebl_service_lang[lang].Transformingtothenew}
                    </p>
                    <p>
                      {ebl_service_lang[lang].GSLinvitesyoutojoin}.
                    </p>
                    <div class="">
                      <img src={ebl_img} class="img-fluid" />
                    </div>
                 {/* <a href="terms-of-use.php">{ebl_service_lang[lang].GSLTermsConditions}</a> 
                    <p>
                      {ebl_service_lang[lang].Weherebyconfirmandacknowledge} .{" "}
                    </p>
                    <p>
                      {ebl_service_lang[lang].WeareawarethattheelectronicBill}.{" "}
                    </p>
                    <p>
                      {ebl_service_lang[lang].Weherebyundertakeupon}.
                    </p>*/}
                  </div>
                </div>
                <div class="col-lg-5 col-md-12 col-sm-12 p-3 pot-form pot-form-ebl">
                  <h2>{ebl_service_lang[lang].RegistertoeBLService}</h2>
                  <form
                    id="ebl_service"
                    method="POST"
                    name="ebl_service"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                <img src={ebl_img_1} class="img-fluid" />
                    <div class="terms-conditions">
                      <div class="checkbox-animated">
                        <input
                          id="terms-conditions"
                          name="terms_conditions"
                          type="checkbox"
                          value="Yes"
                          {...register("terms_conditions", {
                            required: {
                              value: true,
                              message:
                                "Please agree the terms&conditions before submit ",
                            },
                          })}
                        />
                        {errors.terms_conditions && (
                          <span for="terms_conditions" class="errors">
                            {errors.terms_conditions.message}
                          </span>
                        )}
                        <label for="terms-conditions">
                          <span class="check"></span>
                          <span class="box"></span>* {ebl_service_lang[lang].IacceptGSLsTermsConditions}
                        </label>
                      </div>
                      <p>
                        * {ebl_service_lang[lang].Weherebyconfirmandacknowledge}.{" "}
                      </p>

                      <p>
                        * {ebl_service_lang[lang].WeareawarethattheelectronicBill}.{" "}
                      </p>

                      <p>
                        * {ebl_service_lang[lang].Weherebyundertakeupon+"\n"}.
                      
                      </p>
                     <p>
                       <Link
                        to="/faq">eBL FAQ</Link>
                     </p>
                    </div>
                    <div class="booking-next">

                      <button
                        type="submit"
                        name="submit"
                        id="submit-enq"
                        class="btn btn-default hovereffect mr-1"
                        ref={buttonRef}
                      >
                        {SubmitButton}
                      </button>
                    </div>
                   
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GET_IN_TOUCH />
    </Layout>
  );
};
export default Ebl_service;
