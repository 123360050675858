const homelang =
{
    "en": {
        "OURMISSION": "OUR MISSION",
       
    },
    "cn": {
        "OURMISSION": "我們的使命",
     
        
    },
    "zh": {
        "OURMISSION": "我们的任务",
     
        
    },
    "ko": {
        "OURMISSION": "당사의 사명",
     
        
    },
    "vi": {
        "OURMISSION": "Nhiệm vụ của chúng tôi",
     
        
    },
    "th": {
        "OURMISSION": "พันธกิจ",
     
        
    },
    "in": {
        "OURMISSION": "MISI KAMI",
     
        
    },
    "ja": {
        "OURMISSION": "企業理念",
     
        
    },
}
export default homelang;