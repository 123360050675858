import React, { Component } from "react";
import Layout from "../../layout";
import Moment from "moment";
import GET_IN_TOUCH from "../../get_in_touch/index";
import Services from "../../get_in_touch/services";
import Packages from "../../get_in_touch/packages_block";
import inside_heading from "../../../../src/assets/inside-heading.png";
import Inner_container from "../inner-container";
import Api from "../../../../package.json";
import * as axios from "axios";
import "react-tabs/style/react-tabs.css";
import { Accordion, Card, Button, ButtonGroup } from "react-bootstrap";
import banner1 from "../../../../src/assets/banner1.jpg";
import abt_icn from "../../../../src/assets/abt-icn.png";
import "../help/customer-advisory.css";
import { NavLink as Link } from "react-router-dom";
import customer_advisory_lang from '../../lang/customer-advisory'

const qs = require("qs");
var jwt = require("jsonwebtoken");
export default class Terms_of_use extends Component {
  timeout = null;
  constructor(props) {
    super(props);
    this.state = {
      country_code: window.$country_code,
      customer_advisory_data: [],
    };
  }
  async componentDidMount() {
    var lang = window.localStorage.getItem("lang");
    const headers = {
      type: "application/json",
      Authorization: `Bearer `+window.localStorage.getItem('access_token'),
      };
    const response = await axios.post(
      Api.userdata.url + "/customer_advisory",
      qs.stringify({ token: "7", 'lang': lang }, { parseArrays: false }),{headers:headers}
    );
    const json = await response.data;
    this.setState({ customer_advisory_data: json["message"] });
  }
  render() {
    var lang = window.localStorage.getItem('lang');
    return (
      <Layout>
        <Inner_container innerpage="customer_advisory"/>
        <section class="inner-center n-40">
          <div class="container">
            <div class="row ml-0 mr-0 "></div>
            <div
              class="col-lg-12 col-sm-12 pot-form pl-0  wow fadeInDown"
              data-wow-delay=".2s"
            >
              <div class="abt-text-con">
                <h3>{customer_advisory_lang[lang].CustomerAdvisory}</h3>
              </div>
              <div class="customer-adv-press-release-base">
                <div class="container">
                  {this.state.customer_advisory_data.map((data) => {
                    {
                      {
                        var url = "";
                        var date = Moment(data["createdon"]).format(
                          "DD-MMM-YYYY"
                        );
                        var time = Moment(data["createdon"]).format("hh:mm");
                      }
                    }
                    return (
                      <div class="customer-adv-press-release-inner">
                        <h3>
                          <Link to={"/view_customer_advisory/" + data["id"]}>
                            {data["title"]}
                          </Link>
                        </h3>
                        <h6>{date + " | " + time}</h6>
                        <div class="customer-adv-press-release-content">
                          <p>{data["short_des"]}</p>
                        </div>
                        <div class="customer-adv-press-release-readmore">
                          <Link to={"/view_customer_advisory/" + data["id"]}>
                          {customer_advisory_lang[lang].ReadMore}  <span> {">"} </span>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Services />
        <Packages />
        <GET_IN_TOUCH />
      </Layout>
    );
  }
}
