import React, { Component, useEffect, useState } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import Services from "../../../components/get_in_touch/services";
import Packages from "../../../components/get_in_touch/packages_block";
import Api from "../../../../package.json";
import * as axios from "axios";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import GoToTop from "../../../components/go-to-top";
import { useForm, Controller } from "react-hook-form";
import "../tools/css/css-loader.css";
import moment from "moment";
import point_to_point_lang from '../../lang/schedule_point_to_point'

const qs = require("qs");
var jwt = require("jsonwebtoken");
const Point_to_point = () => {

  var lang = window.localStorage.getItem("lang");
  if (window.localStorage.getItem("lang") == null) {
    lang = 'en';
    window.localStorage.setItem("lang", 'en');
  }

  const headers = {
		type: "application/json",
		Authorization: `Bearer `+window.localStorage.getItem('access_token'),
	  };
  const [country_code, setCountryCode] = useState(window.$country_code);
  const [port_code, setport_code] = useState([]);
  const [date, setdate] = useState(new Date());
  const [loaderFlag, setloaderFlag] = useState("is-inactive");
  const [pointToPoint, setpointToPoint] = useState([]);
  const [limit, setlimit] = useState("10");
  const [offset, setoffset] = useState("0");
  const [totalPages, settotalPages] = useState(0);
  const [firstsearch, setfirstsearch] = useState(false);
  const [page, setpage] = useState(0);
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    point_to_point_submit(data);
  };
  const point_to_point_submit = async (data) => {
    setloaderFlag("is-active");
    const response = await axios.post(
      Api.userdata.url + "/get_point_to_point",
      qs.stringify({
        country_code: country_code,
        originCode: data.from_port,
        destCode: data.to_port,
        departureArrivalIndicator: data.optradio,
        schedule: data.schedule,
        date: data.date,
      }),{headers:headers}
    );
    const json = await response.data;
    if (json["status"] == "OK") {
      var new_data = json["result"];
      var totalPages = new_data.length;
      var limit = 10;
      var page = 0;
      var offset = 0;

      page = Math.max(page, 1); //get 1 page when $_GET['page'] <= 0
      page = Math.min(page, totalPages); //get last page when $_GET['page'] > $totalPages
      offset = (page - 1) * limit;
    //  alert(page);
      setpointToPoint(new_data);
      if (offset < 0) offset = 0;

      settotalPages(totalPages);
      setlimit(limit);
      setpage(page);
     setoffset(offset);
    } else {
      setpointToPoint([]);
    }
    setfirstsearch(true);
    setloaderFlag("is-inactive");
  };
  const point_to_point_submit_print = async () => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/get_point_to_point_pdf",
      qs.stringify({
        country_code: country_code,
        originCode: getValues("from_port"),
        destCode: getValues("to_port"),
        departureArrivalIndicator: getValues("optradio"),
        schedule: getValues("schedule"),
        date: getValues("date"),
      }),
      {
        headers:headers,
        responseType: "blob",
      }
    );
    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    window.focus();
    setloaderFlag("is-inactive");
  };
  const point_to_point_submit_pdf = async () => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/get_point_to_point_pdf",
      qs.stringify({
        country_code: country_code,
        originCode: getValues("from_port"),
        destCode: getValues("to_port"),
        departureArrivalIndicator: getValues("optradio"),
        schedule: getValues("schedule"),
        date: getValues("date"),
      }),
      {
        headers:headers,
        responseType: "blob",
      }
    );
    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const tempLink = document.createElement("a");
    tempLink.href = fileURL;
    tempLink.setAttribute("download", "Point-to-Point" + ".pdf");
    tempLink.click();
    setloaderFlag("is-inactive");
  };
  const getLoadData = async () => {
    const response = await axios.post(
      Api.userdata.url + "/port_codes",
      qs.stringify({ token: "2" }, { parseArrays: false }),{headers:headers}
    );
    const json = await response.data;
    setport_code(json["message"]);
  };
  useEffect(() => {
    getLoadData();
    setValue("date", date);
  }, [offset]);
  return (
    <Layout>
      <div class={"loadernew loader-double " + loaderFlag}></div>
      <section class="inner-center">
        <br></br>
        <div class="container">
          <div class="row m-0">
            <div class="col-lg-12 col-md-12 col-lg-12">
              <form
                autocomplete="on"
                name="point-to-point-new"
                id="point-to-point-new"
                method="get"
                action="#"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div class="form-fback">
                  <div class="row m-0">
                    <div class="col-lg-5 col-md-12 col-sm-12 pot-form p-3">
                      <div class="dout-inner">
                        <h3>{point_to_point_lang[lang].PointtoPoint}</h3>
                        <p>{point_to_point_lang[lang].PointtoPoint1}</p>
                      </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 p-0 pot-form">
                      <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12">
                          <div class="autocomplete">
                            <div>
                              <label>{point_to_point_lang[lang].from}</label>
                              <Controller
                                control={control}
                                rules={{
                                  required: "Please select from port",
                                }}
                                render={({ onChange, value, ref }) => (
                                  <Select
                                    options={port_code.map((data) => ({
                                      value: data["port_code"],
                                      label:
                                        data["port_name"] +
                                        "-" +
                                        data["country_name"] +
                                        "(" +
                                        data["port_code"] +
                                        ")",
                                    }))}
                                    onChange={(val) => {
                                      setValue("from_port", val.value);
                                    }}
                                  />
                                )}
                                defaultValue=""
                                placeholder="Select platform"
                                name="from_port"
                                id="from_port"
                                setValue={setValue}
                              />

                              {errors.from_port && (
                                <span class="errors">
                                  {errors.from_port.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12">
                          <div class="autocomplete">
                            <div>
                              <label for="toends">{point_to_point_lang[lang].to}</label>
                              <Controller
                                control={control}
                                rules={{
                                  required: "Please select to port",
                                }}
                                render={({ onChange, value, ref }) => (
                                  <Select
                                    options={port_code.map((data) => ({
                                      value: data["port_code"],
                                      label:
                                        data["port_name"] +
                                        "-" +
                                        data["country_name"] +
                                        "(" +
                                        data["port_code"] +
                                        ")",
                                    }))}
                                    onChange={(val) => {
                                      setValue("to_port", val.value);
                                    }}
                                  />
                                )}
                                defaultValue=""
                                placeholder="Select platform"
                                name="to_port"
                                id="to_port"
                                setValue={setValue}
                              />
                              {errors.to_port && (
                                <span class="errors">
                                  {errors.to_port.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12">
                          <div class="autocomplete">
                            <div class="form-group">
                              <label>{point_to_point_lang[lang].Startdate}</label>
                              <div
                                id="datepicker"
                                class="input-group date datepicker"
                                data-date-format="dd/mm/yyyy"
                              >
                                <Controller
                                  control={control}
                                  rules={{
                                    required: "Please select your date",
                                  }}
                                  render={({ onChange, value, ref }) => (
                                    <Flatpickr
                                      options={{ dateFormat: "d-m-Y" }}
                                      value={date}
                                      onChange={(val) => {
                                        setValue("date", val[0]);
                                      }}
                                    />
                                  )}
                                  id="date"
                                  name="date"
                                  class="form-control"
                                  setValue={setValue}
                                />

                                {errors.date && (
                                  <span class="errors">
                                    {errors.date.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12">
                          <div>
                            <label>{point_to_point_lang[lang].Weeksahead}</label>
                            <select
                              class="selectpicker"
                              id="schedulept"
                              name="schedule"
                              {...register("schedule")}
                            >
                              <option value="6"> 6 </option>
                              <option value="7"> 7 </option>
                              <option value="8"> 8 </option>
                              <option value="9"> 9 </option>
                              <option value="10"> 10 </option>
                              <option value="11"> 11 </option>
                              <option value="12"> 12 </option>
                              <option value="13"> 13 </option>
                              <option value="14"> 14 </option>
                              <option value="15"> 15 </option>
                              <option value="16"> 16 </option>
                              <option value="17"> 17 </option>
                              <option value="18"> 18 </option>
                              <option value="19"> 19 </option>
                              <option value="20"> 20 </option>
                              <option value="21"> 21 </option>
                              <option value="22"> 22 </option>
                              <option value="23"> 23 </option>
                              <option value="24"> 24 </option>
                              <option value="25"> 25 </option>
                              <option value="26"> 26 </option>
                              <option value="27"> 27 </option>
                              <option value="28"> 28 </option>
                              <option value="29"> 29 </option>
                              <option value="30"> 30 </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <ul class="sec-pot-check new-pot-radio">
                        <li>
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              id="optradio"
                              value="0"
                              name="optradio"
                              class="custom-control-input"
                              checked
                              {...register("optradio")}
                            />
                            <label
                              class="custom-control-label radio-nane"
                              for="optradio"
                            >
                              {point_to_point_lang[lang].Departure}
                            </label>
                          </div>
                        </li>
                        <li>
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              id="optradio1"
                              value="1"
                              name="optradio"
                              class="custom-control-input"
                              {...register("optradio")}
                            />
                            <label
                              class="custom-control-label radio-nane"
                              for="optradio1"
                            >
                              {point_to_point_lang[lang].Arrival}
                            </label>
                          </div>
                        </li>
                        <li class="buts-in-cen">
                          <button
                            class="btn btn-default hovereffect portal-searc"
                            id="submitDetails"
                            name="my-buttons"
                          >
                            {point_to_point_lang[lang].Search}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {pointToPoint.length > 0 ? (
          <>
            <div class="container" style={{ minHeight: `200px;` }}>
              <div class="col-md-12 text-right p-2">
                {totalPages != 0 ? (
                  <>
                    {page != 1 ? (
                      <button
                        class="btn btn-primary ptp-btn"
                        onClick={() => {
                          var pages = page - 1;
                          pages = Math.max(pages, 1); //get 1 page when $_GET['page'] <= 0
                          pages = Math.min(
                            pages,
                            Math.ceil(totalPages / limit)
                          ); //get last page when $_GET['page'] > $totalPages
                          setpage(pages);
                          var offset = (pages - 1) * limit;
                          if (offset < 0) offset = 0;
                          setoffset(offset);
                        }}
                      >
                        Prev page
                      </button>
                    ) : (
                      ""
                    )}
                    <span class="p-2" style={{ fontSize: `12px` }}>
                      <strong>{page}</strong> of {Math.ceil(totalPages / limit)}
                    </span>
                    {page == Math.ceil(totalPages / limit) ? (
                      ""
                    ) : (
                      <button
                        class="btn btn-primary ptp-btn"
                        onClick={() => {
                          var pages = page + 1;
                          pages = Math.max(pages, 1); //get 1 page when $_GET['page'] <= 0
                          pages = Math.min(
                            pages,
                            Math.ceil(totalPages / limit)
                          ); //get last page when $_GET['page'] > $totalPages
                          setpage(pages);
                          var offset = (pages - 1) * limit;
                          if (offset < 0) offset = 0;
                          setoffset(offset);
                      //    alert(offset);
                        }}
                      >
                        Next page
                      </button>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <table style={{ width: `100%` }} id="myTable ptp-form-bd" className="sivpointtopoint">
                <thead
                  style={{ position: `sticky`, top: `0` }}
                  class="thead-dark ptp-tb-head"
                >
                  <tr>
                    <th>Vessel(code) / Voyage</th>
                    <th>Departure</th>
                    <th>Arrival</th>
                    <th>Line</th>
                    <th>Transit Time</th>
                  </tr>
                </thead>
                <tbody>
                  {pointToPoint.slice(offset, offset+limit).map((resultssss, i) => {
                    {
                      {
                      }
                    }
                    return (
                      <>
                        {resultssss["result_new"].map((results, j) => {
                          {
                            {
                              var td1 = results["vesselName"];
                              var td2 = moment(results["departureDate"]).format(
                                "DD MMM, YYYY"
                              );
                              var td3 = moment(results["arrivalDate"]).format(
                                "DD MMM, YYYY"
                              );
                              var td4 = results["line"];
                            }
                          }
                          return (
                            <tr
                              className={
                                i % 2 == 0
                                  ? "point-to-point-odd"
                                  : "point-to-point-even"
                              }
                            >
                              <td>
                                <b>
                                  {td1 +
                                    "(" +
                                    results["vesselCode"] +
                                    ") / "}
                                  <p>{results["voyage"]}</p>
                                </b>
                              </td>
                              <td>
                                {td2}
                                <p style={{ fontSize: '13px' }}>{results["departurePortName"]}</p>
                                <p  style={{ fontSize: '13px' }}>{results["depotFromName"]}</p>
                              </td>
                              <td>
                                {td3}
                                <p>{results["arrivalPortName"]}</p>
                                <p>{results["depotToName"]}</p>
                              </td>
                              <td>{td4}</td>
                              {j == 0 ? (
                                <td
                                  class="transit-time"
                                  rowspan={resultssss["result_new"].length}
                                >
                                  {results["transitTime"]}
                                </td>
                              ) : (
                                <></>
                              )}
                            </tr>
                          );
                        })}
                      </>
                    );
                  })}
                </tbody>
              </table>
              <div class="row  p-4">
                <p class="tariff-print" style={{ padding: `10px` }}>
                  <a
                    class="btn btn-info btn-lg"
                    onClick={point_to_point_submit_print}
                  >
                    <span class="glyphicon glyphicon-print"></span> Print
                  </a>
                </p>
                <p class="tariff-print" style={{ padding: `10px` }}>
                  <a
                    class="btn btn-info btn-lg"
                    onClick={point_to_point_submit_pdf}
                  >
                    <span class="glyphicon glyphicon-print"></span> PDF
                  </a>
                </p>
              </div>
            </div>
          </>
        ) : (
          <div
            class="row"
            style={{
              minHeight: `200px;`,
              border: `solid 1px;`,
              display: firstsearch ? "block" : "none",
            }}
          >
            <div class="col">
              <h3 style={{ paddingTop: `72px;`, textAlign: `center;` }}>
                <i class="fa fa-search" aria-hidden="true"></i> No Record Found.
              </h3>
            </div>
          </div>
        )}
        ;
      </section>
      <Services />
      <Packages />
      <GET_IN_TOUCH />
    </Layout>
  );
};
export default Point_to_point;
