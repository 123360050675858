import React, { Component } from 'react';
import Layout from '../../layout';
import GET_IN_TOUCH from '../../get_in_touch/index';
import Services from '../../get_in_touch/services';
import Packages from '../../get_in_touch/packages_block';
import Inner_container from '../inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import inside_heading from '../../../../src/assets/inside-heading.png';

const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class GslCookiesAndTrackingPolicy extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            factsfig: [],

        };

    }
    async componentDidMount() {

    }
    render() {
        return (

            <Layout>
                <Inner_container innerpage="gsl_cookies_policy"/>
                <section class="inner-center n-40">
                    <div class="container">
                        <div class="row m-0">
                            <div class="col-lg-12">
                                <form class="">

                                    <div class="row m-0">
                                        <div class="col-lg-6 col-sm-12 pot-form pl-0">
                                            <p>This Cookies and Tracking Policy (hereinafter, “Cookies Policy”) applies to all activities of Gold Star Line Ltd. (hereinafter called “GSL” or “We”) with respect to the cookies that GSL collects and stores in the course of its business activities, including through the GSL websites (“Websites”) and other interfaces with data subjects that it operates.</p>
                                            <h3 class="solas h"> General principles </h3>
                                            <p>GSL is committed to ensuring that your personal data and data privacy are protected in accordance with the best practices available, as well as according to GSL’s relevant legal obligations. This Policy explains your rights regarding the cookies GSL collects, processes and uses, including via the Websites, Personal Area, and Apps, as well as GSL’s use of safeguards to protect these rights. The Policy also applies to GSL’s use of cookies and tracking on the relevant website and application interfaces, as specified in this Cookies and Tracking Policy.</p>
                                            <p>We may revise this Policy from time to time, and such changes shall come into effect from the moment GSL notifies you of such changes and/or updates, either by email, on the Websites or in some other reasonable manner.</p>
                                            <p><b>Important note:</b>You may indicate your consent to this Cookies Policy by clicking “I agree” on the button that appears at the bottom of the Policy. [Consent tab at the end of the Policy]</p>
                                        </div>
                                        <div class="col-lg-6 col-sm-12 pot-form pl-0 dat-relat">
                                            <div class="data-protect pt-3" >
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="inner-center-data">
                    <div class="container">
                        <form class="">
                            <div class="">

                                <div class="row m-0">
                                    <div class="col-lg-12 p-0 text-center">
                                        <h3 class="solas h"> GSL’s Use of Cookies and Tracking</h3>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="accordion-toggle acc-pannel-foot">
                                            <h3 class="accordion-link accordion-on">What's a cookie?<span></span></h3>
                                            <div class="accordion-panel" >
                                                <p>A cookie is a small file sent from a website, which is then stored by your web browser.When you return to the Personal Area, the information in the cookie can be used to recognize your last session.Cookies cannot install malicious software such as viruses on your device, and they do not store any of your personal data or account information.</p>
                                            </div>

                                            <h3 class="accordion-link">Why do we use cookies?<span></span></h3>
                                            <div class="accordion-panel" >
                                                <p> We use cookies to save and maintain users' settings and selected preferences during browsing sessions, for saving repeating users' preferences for future use (e.g.geographical location, accessibility and language selections and preferences), and for statistical analysis of the Websites, Personal Area's’and Apps’ performance and trends and monitoring and identifying errors.</p>

                                            </div>

                                            <h3 class="accordion-link">Where do cookies come from?<span></span></h3>
                                            <div class="accordion-panel" >
                                                <p>Cookies originate from two types of sources: first party and third party.The classification whether a cookie is 'first' or 'third' party refers to the internet domain that is placing the cookie.<br />
                                                    •	First-party cookies are those set by a website that is being visited by the user at the time.<br />
                                                    •	Third-party cookies are cookies that are set by a domain other than that of the site being visited by the user.If a user visits a website and another entity sets a cookie through that site this would be a third-party cookie.
                                                </p>
                                            </div>
                                            <h3 class="accordion-link ">Opt-out</h3>
                                            <div class="accordion-panel" >
                                                <p> When you first visit the Websites and Personal Area, you will be given the option to accept or decline the use of cookies.You can also control the use of cookies at the individual browser level, and instructions for managing cookie usage can be found for the following browsers, at: <br />
                                                    •	Google Chrome<br />
                                                    •	Internet Explorer<br />
                                                    •	Firefox <br />
                                                    •	Safari<br />
                                                    •	Safari Mobile <br />
                                                    •	Opera
                                                </p>
                                                <p>If you reject cookies, you may still use the Websites and Personal Area, but your ability to use some features or areas of the Personal Area may be limited or unavailable.Many browsers allow a private mode to be activated through which the cookies are always erased after the visit, and you hereby irrevocably and unconditionally waive any claim or demand in this regard.</p>
                                                <p>If you wish to opt out of interest-based advertising click http://preferences-mgr.truste.com/ (if you are located in the European Union click http://www.youronlinechoices.eu/).</p>
                                            </div>

                                            <h3 class="accordion-link">Do Not Track/Privacy Mode</h3>
                                            <div class="accordion-panel" >
                                                <p>“Do not track” or “privacy mode” is a function that allows website users to opt out from being tracked by websites for any purpose including the use of analytics services, advertising networks and social platforms.Do not track options are available in a few browsers including: <br />
                                                    •	Firefox<br />
                                                    •	Internet Explorer <br />
                                                    •	Chrome <br />
                                                    •	Safari<br />
                                                    •	Opera
                                                </p>
                                                <p>If you have enabled the “do not track” function in your browser, you will not be tracked.This is in addition to you opting-out of the aggregation and analysis of data for the statistics of the Websites, Personal Area and Apps.</p>
                                            </div>

                                            <h3 class="accordion-link">Categories of cookies we use</h3>
                                            <div class="accordion-panel" >
                                                <p>We place a high priority on the security and confidentiality of the information stored in the persistent cookies we use in the Personal Area.For example, we do not store any of your personal information, payment information or passwords in persistent cookies.</p>
                                            </div>
                                            <h3 class="accordion-link">Can I withdraw consent  for the use of cookies or tracking tools?</h3>
                                            <div class="accordion-panel" >
                                                <p>If you wish to withdraw your consent, you can use our cookies control interface by clicking on this link.
                                                    Similarly, you can choose to opt-out of cookies at any time by managing and/or deleting your cookies using your internet browser settings.
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </section >
                <section class="inner-center-data dat-color" >
                    <div class="container">
                        <form class="">
                            <div class="row ml-0 mr-0 ">


                                <div class="col-lg-6 col-sm-12 pot-form pl-0 dat-relat">
                                    <div class="data-protect pt-3" 	>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-12 pot-form pl-0  ">
                                    <h3 class="solas h"> Additional Information, Updates and Questions</h3>
                                    <h5>Additional information on cookies in general:</h5>
                                    <p>A number of websites provide detailed information on cookies, including AboutCookies.org and AllAboutCookies.org.</p>
                                    <h5>Updates to our Policy</h5>
                                    <p>We update our Cookies Policy from time to time, if we introduce a new cookie or tracking tool which processes new information or wish to process the information collected by your cookies and tracing tools in a new manner, we shall notify you and ask you to consent to this updated processing or new cookie or tool.</p>
                                    <h5>Questions?</h5>
                                    <p>Should you have any questions about the use of cookies by GSL, please be in touch with our Data Protection Officer.</p>
                                </div>

                            </div>
                        </form>
                    </div>
                </section>






                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}