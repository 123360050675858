import React, { Component } from 'react';
import Layout from '../../layout';
import GET_IN_TOUCH from '../../get_in_touch/index';
import Services from '../../get_in_touch/services';
import Packages from '../../get_in_touch/packages_block';
import Inner_container from '../inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import inside_heading from '../../../../src/assets/inside-heading.png';

const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class Accessibility extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            factsfig: [],

        };

    }
    async componentDidMount() {

    }
    render() {
        return (

            <Layout>
                <Inner_container innerpage="accessibility" />
                <section class="inner-center n-40">
                    <div class="container">

                        <div class="row m-0">
                            <div class="col-lg-12 n-accessibility">
                                <form class="point-to">


                                    <div class="form-fback">
                                        <div class="row m-0">
                                            <div class="col-lg-12 col-sm-12 pot-form pl-0">
                                                <p> We, at GSL, are striving and taking steps to promote accessibility. We value our customers with disabilities equally to any other customer, and believe they are entitled to equal access to our facilities and services.</p>
                                                <p>GSL adheres to the provisions of the Hong Kong Accessibility Law, and even more so, in order to improve the quality of life of people with disabilities and assist them in their integration in our society in an equal, independent and dignified manner.</p>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 pot-form pl-0">
                                                <h3 class="solas h"> How is our Website Accessible?</h3>
                                                <p>We have adjusted our Website so is accessible according to the AA level set out in Hong Kong Standard, which corresponds with the equivalent AA level of the World Wide Web Consortium (W3C) Web Content Accessibility Guidelines (WCAG) version.</p>
                                                <p>Our website includes the following accessibility methods: </p>
                                                <p>•	Its structure is designed for accessibility technologies, including support for the customary accessibility navigation with the arrow keys and the Esc and Enter Buttons to enter or exit menus and windows.</p>
                                                <p>•	Has been tested and is customized for the highest levels of accessibility on Mozilla Firefox. It is also responsive and usable on Microsoft Explorer and Google Chrome.</p>
                                                <p>
                                                    In order to experience the optimal browsing experience with the most updated version of the NDVA "screen-reader" software
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row m-0">

                                            <div class="col-lg-12 col-sm-12 pot-form pl-0">
                                                <h3 class="solas h"> How is our Customer Service Accessible?</h3>
                                            </div>
                                            <div class="col-lg-6 col-sm-12 pot-form pl-0">
                                                <p>Our GSL employees Accessibility Training – We are in the process of training our staffs on the various aspects of accessibility.In order to foster awareness to the principles of accessibility and to grant them practical tools to provide such accessible services.</p>
                                            </div>
                                            <div class="col-lg-6 col-sm-12 pot-form pl-0">
                                                <p>
                                                    Accessible Call Center – Our call center's voice response has been made accessible in a manner of which words are pronounced clearly, and there is no background music.
                                                </p>
                                            </div>

                                            <div class="col-lg-12 col-sm-12 pot-form pl-0">
                                                <h3 class="solas h"> How are our Public Facilities being Accessible?</h3>
                                                <p>We are currently promoting accessibility at our headquarters (HKG) and in frontal customer facing offices and are amidst gradual transition to full accessibility under Law will conclude during 2018.</p>
                                            </div>

                                        </div>
                                    </div>


                                </form>				</div>
                        </div>

                    </div>
                </section>


                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}