import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import dry_cargo_services from '../../../../src/assets/dry-cargo-services.png';
import abt_icn from '../../../../src/assets/abt-icn.png';
import Inner_container from '../../innerpages/inner-container';
import Dry_cargo_lang from '../../lang/dry-cargo';

var jwt = require('jsonwebtoken');
export default class Dry_cargo extends Component {

    render() {
		var lang = window.localStorage.getItem('lang');
        return (

            <Layout>
                <Inner_container innerpage="dry_cargo"/>
                

                <section class="inner-center n-40">
	<div class="container">										
					<form class="abt-full">						
							<div class="row ml-0 mr-0 pjt-cargo-row">																									
									
								<div class="col-lg-5 col-sm-12 pot-form pl-0 dat-relat ">																			
										  							
									<div class=" cargo-n-imgg" >  																							 
										<img src={dry_cargo_services} />											
									</div>																			
								</div>																									
								<div class="col-lg-7 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s"  >
									<div class="car-ser-new">										
										<h3>{Dry_cargo_lang[lang].DryCargo}</h3>										
										<p>{Dry_cargo_lang[lang].Aspioneersofcontainer} </p>
							<p>{Dry_cargo_lang[lang].Everyshipmentdeservespersonal}  </p>
							<p>{Dry_cargo_lang[lang].Fromtheinitialbooking} </p>	
									</div>										
								</div>
							</div>													
					</form>									
	</div>
</section>
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout>);
    }
}