import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import Services from "../../../components/get_in_touch/services";
import Packages from "../../../components/get_in_touch/packages_block";
import Inner_container from "../../innerpages/inner-container";
import Api from "../../../../package.json";
import * as axios from "axios";
import inside_heading from "../../../../src/assets/inside-heading.png";
import Select from "react-select";
import "../tools/css/si-style.css";
import { useForm, Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import swal from "sweetalert";
import vgm_mass_upload_lang from '../../lang/vgm_mass_upload';
import HCaptcha from "@hcaptcha/react-hcaptcha";

const qs = require("qs");
var jwt = require("jsonwebtoken");

const Vgm_mass_upload = () => {

  var lang = window.localStorage.getItem("lang");
  if (window.localStorage.getItem("lang") == null) {
    lang = 'en';
    window.localStorage.setItem("lang", 'en');
  }

  const [country_code, setCountryCode] = useState(window.$country_code);
  const [port_code, setPortcode] = useState([]);
  const [country_list, setcountry_list] = useState([]);
  const [si_file, setSifile] = useState("");
  const [SubmitButton, setSubmitButton] = useState(vgm_mass_upload_lang[lang].Submit);
  const [date, setdate] = useState(new Date());
  const [VgmMassUpload, setVgmMassUpload] = useState([]);
  const [BookingOfficeLocation, setBookingOfficeLocation] = useState([]);
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const buttonRef = useRef();
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const [vgm_mass_captcha, setvgm_mass_captcha] = useState([]);
  const onSubmit = (data) => {
    console.log(data);
    buttonRef.current.disabled = true;
    setSubmitButton(vgm_mass_upload_lang[lang].Submit+'...');
    save_VgmMassUpload(data);
  };
  const [imgError, setImgError] = useState("");
  const [excelFile,setExcelFile] = useState("");

  const headers = {
		"Content-Type": "multipart/form-data",
		type: "application/json",
		Authorization: `Bearer `+window.localStorage.getItem('access_token'),
	  };
    const headers1 = {
      type: "application/json",
      Authorization: `Bearer `+window.localStorage.getItem('access_token'),
      };
  const vgm_office = async (country) =>{
    const response = await axios.post(
        Api.userdata.url + "/vgm_office",
        qs.stringify({ country: country, enquiry_captcha: token }, { parseArrays: false }),{headers:headers1}
      );
      const json = await response.data;
      if(json["status"] == 1)
      {
        setBookingOfficeLocation(json["message"]);
      }else{
        setBookingOfficeLocation([]);
      }
    
  }
  const onChangeExcel = (e) => { 
    setImgError(""); console.log(e);
    if (e.target.files[0].type != "") {
      if (e.target.files[0].type == "application/vnd.ms-excel" || e.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || e.target.files[0].type == "application/vnd.ms-excel.sheet.macroEnabled.12" ) {
        console.log("excel: ", e.target.files);
        setExcelFile(e.target.files[0]);
       
      } else {
        swal("Error !", "Invaild file format", "error");
        document.getElementById("exfile").value = "";
      }
    }else {
      swal("Error !", "Invaild file format", "error");
      document.getElementById("exfile").value = "";
    }
  };
  const save_VgmMassUpload = async (data) => {
    if(token != null)
    {
      const bodyFormData = new FormData();   
      bodyFormData.append("country_code", country_code);
      bodyFormData.append("cperson", data.cperson);
      bodyFormData.append("email", data.email);
      bodyFormData.append("country", data.country);
      bodyFormData.append("booking_office", data.booking_office);
      bodyFormData.append("receipt", data.receipt);
      bodyFormData.append("loading", data.loading);
      bodyFormData.append("code", data.code);
      bodyFormData.append("vgm_mass_captcha", token);
      bodyFormData.append("excelFile", excelFile);
  
      const response = await axios({ method: "post",
       url:Api.userdata.url + "/save_vgm_mass_upload",
        data: bodyFormData,
        headers:headers,}
      );
      const json = await response.data;	
      if (json["status"] == "OK") {
        swal("Successful Submitted", json["message"], "success").then((value) => {
      window.location.reload(false);
        });
      } else {
        swal("Error !", json["message"], "error");
      }
    }else{
      swal("Error !", "Invalid captcha", "error");
    }
   
    buttonRef.current.disabled = false;
    setSubmitButton(vgm_mass_upload_lang[lang].Submit);
  };
  const getLoadData = async () => {
    const port_codes_response = await axios.post(
      Api.userdata.url + "/port_codes",
      qs.stringify({ token: "2" }, { parseArrays: false }),{headers:headers1}
    );
    const port_codes_response_json = await port_codes_response.data;
    setPortcode(port_codes_response_json["message"]);

    const si_response = await axios.post(
      Api.userdata.url + "/si_submission",
      qs.stringify({ token: "3" }, { parseArrays: false }),{headers:headers1}
    );
    const si_response_json = await si_response.data;
    setSifile(si_response_json["message"]);

    const response = await axios.post(
      Api.userdata.url + "/country_list",
      qs.stringify({ token: "2" }, { parseArrays: false }),{headers:headers1}
    );
    const json = await response.data;
    setcountry_list(json["message"]);

    const VgmMassUpload_res = await axios.post(
      Api.userdata.url + "vgm_mass_token",
      qs.stringify({ country_code: country_code }, { parseArrays: false }),{headers:headers1}
    );
    const VgmMassUpload_json = await VgmMassUpload_res.data;
    setVgmMassUpload(VgmMassUpload_json["message"]);
    setvgm_mass_captcha(VgmMassUpload_json["captcha"]);
  };
  useEffect(() => {
    getLoadData();
  }, []);

  return (
    <Layout>

      <section class="inner-center">
        <div class="container">
        <div class="row pt-5">
            <div class="col-lg-12 col-md-12 col-lg-12">
                <div class="form-fback">
                  <div class="row m-0">
                    <div class="col-lg-5 col-md-12 col-sm-12 pot-form p-3">
                      <div class="dout-inner">
                        <h3>{vgm_mass_upload_lang[lang].VGMMassUpload}</h3>
                        <p>{vgm_mass_upload_lang[lang].VGMMassUpload1}</p>
                      </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 p-0 pot-form">


                    </div>
                  </div>
                </div>
             
            </div>
          </div>
          <form
            action="#"
            name="shipping_contact"
            id="shipping_contact"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div class="tab-content p-3 mt-2 border trade-tab-con booking-tab-cont">
              <div
                class="alert alert-danger"
                id="pol_error"
                style={{ display: "none" }}
                role="alert"
              >
                <strong>{vgm_mass_upload_lang[lang].Error} : </strong>{vgm_mass_upload_lang[lang].InvalidPortofLoading}
              </div>
              <div class="tab-pane active" role="tabpanel" id="step1">
                <div class="row m-0">
                  <div class="col-lg-6 col-sm-12 pot-form pl-0 si-downs">
                    <a
                      class="btn btn-link"
                      href={
                        Api.userdata.siteurl + "uploads/feedback/" + si_file
                      }
                    >
                      <span style={{ paddingRight: "10px" }}>
                        {" "}
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </span>
                      {vgm_mass_upload_lang[lang].DownloadVGMformtemplate} 
                    </a>
                  </div>
                  <div class="col-lg-6 col-sm-12 pot-form pl-0"></div>
                </div>

                <div class="row m-0">
                  <div class="col-lg-6 col-sm-12 pot-form pl-0">
                    <label>
                    {vgm_mass_upload_lang[lang].ContactPersonName}   <sup class="starss">*</sup>
                    </label>
                    <input
                      type="text"
                      name="cperson"
                      id="cperson"
                      placeholder=""
                     {...register("cperson",{
                        required: {
                          value: true,
                          message: "Contact Person Name-required field",
                        },
                        pattern: {
                          value: /^[A-Z\s]/i,
                          message: "Invalid name",
                        },
                      })}
                    />
                    {errors.cperson && (
                      <span class="errors">{errors.cperson.message}</span>
                    )}
                    <label>
                    {vgm_mass_upload_lang[lang].Email} <sup class="starss">*</sup>
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      placeholder=""
                     {...register("email",{
                        required: {
                          value: true,
                          message: "Email-required field",
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <span class="errors">{errors.email.message}</span>
                    )}
                    <label>
                    {vgm_mass_upload_lang[lang].BookingOfficeCountryLocation} <sup class="starss">*</sup>
                    </label>

                    <Controller
                      control={control}
                      rules={{ required: "Country-required field" }}
                      render={({ onChange, value, ref }) => (
                        <Select
                          options={country_list.map((data) => ({
                            value: data["name"],
                            phone_code: data["phonecode"],
                            label: data["name"],
                          }))}
                          onChange={(val) => {
                            vgm_office(val.label);
                            setValue("country", val.label);
                          }}
                        />
                      )}
                      defaultValue=""
                      placeholder="Select platform"
                      name="country"
                      setValue={setValue}
                    />
                    {errors.country && (
                      <span class="errors">{errors.country.message}</span>
                    )}

                    <label>{vgm_mass_upload_lang[lang].BookingOffice}</label>
                    
                      <Controller
                      control={control}
                      render={({ onChange, value, ref }) => (
                        <Select
                          options={BookingOfficeLocation.map((data) => ({
                            value: data["booking_offices"],
                            label: data["booking_offices"],
                          }))}
                          onChange={(val) => {
                            setValue("booking_office", val.label);
                          }}
                        />
                      )}
                      defaultValue=""
                      placeholder="Select platform"
                      name="booking_office"
                      setValue={setValue}
                    />
                      {errors.date && (
                        <span class="errors">{errors.date.message}</span>
                      )}
                    
                 
                  </div>
                  <div class="col-lg-6 col-sm-12 pot-form">
                    <label>{vgm_mass_upload_lang[lang].PlaceofReceipt}</label>
                    <Controller
                      control={control}
                      render={({ onChange, value, ref }) => (
                        <Select
                          options={port_code.map((data) => ({
                            value: data["port_code"],
                            label:
                              data["port_name"] +
                              "-" +
                              data["country_name"] +
                              "(" +
                              data["port_code"] +
                              ")",
                          }))}
                          onChange={(val) => {
                            setValue("receipt", val.value);
                          }}
                        />
                      )}
                      defaultValue=""
                      placeholder="Select platform"
                      name="receipt"
                      id="receipt"
                      setValue={setValue}
                    />
                    {errors.receipt && (
                      <span class="errors">{errors.receipt.message}</span>
                    )}
                    <label>
                    {vgm_mass_upload_lang[lang].PortofLoading} <sup class="starss">*</sup>
                    </label>
                    <Controller
                      control={control}
                      rules={{
                        required: "Loading Port-required field",
                      }}
                      render={({ onChange, value, ref }) => (
                        <Select
                          options={port_code.map((data) => ({
                            value: data["port_code"],
                            label:
                              data["port_name"] +
                              "-" +
                              data["country_name"] +
                              "(" +
                              data["port_code"] +
                              ")",
                          }))}
                          onChange={(val) => {
                            setValue("loading", val.value);
                          }}
                        />
                      )}
                      defaultValue=""
                      placeholder="Select platform"
                      name="loading"
                      id="loading"
                      setValue={setValue}
                    />
                    {errors.loading && (
                      <span class="errors">{errors.loading.message}</span>
                    )}
                    <label>
                    {vgm_mass_upload_lang[lang].CUCCCode} 
                      <sup class="starss"></sup>
                    </label>
                    <input
                      type="text"
                      name="code"
                      id="code"
                      placeholder=""
                      {...register("code")}
                    />
                    <label>
                    {vgm_mass_upload_lang[lang].ExcelFileonly} <sup class="starss">*</sup>
                    </label>
                    <input
                      type="file"
                      name="exfile"
                      id="exfile"
                      accept=".xlsm, .xls, .xlsx"
                     
                     {...register("exfile",{
                        required: {
                          value: true,
                          message: "Please provide excel file",
                        },
                      })}
                      onChange={onChangeExcel}
                    />
                      {errors.exfile && (
                      <span class="errors">{errors.exfile.message}</span>
                    )}
                  </div>
                 
                </div>
                <div class="d-flex si-booking-next">
                <HCaptcha
                  sitekey="a8400b17-0f2c-4116-85cb-91a16f434d49"
                  onVerify={setToken}
                  ref={captchaRef}
                  apihost="https://cn1.hcaptcha.com"
                  endpoint="https://cn1.hcaptcha.com"
                  assethost="https://assets-cn1.hcaptcha.com"
                  imghost="https://imgs-cn1.hcaptcha.com"
                  reportapi="https://reportapi-cn1.hcaptcha.com"
                />
                {errors.hcpactha && (
                    <span class="errors">{errors.message.hcpactha}</span>
                )}
                  <div className="booking-next">
                  <button
                    type="submit"
                    name="submit"
                    id="submit"
                    class="btn btn-default hovereffect hovereffect mr-1"
                    ref={buttonRef}
                  >
                    {SubmitButton}
                  </button></div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <Services />
      <Packages />
      <GET_IN_TOUCH />
    </Layout>
  );
};

export default Vgm_mass_upload;
