const homesolaslang =
{
    "en": {
        "SERVICES": "Services",
        "Sailingschedule": "Schedules",
        "Sailingschedule_p1": "Stay up to date with Gold Star Line's schedules",
        "Equipments": "Equipment",
        "Equipments_p1": "Container specifications, dimensions and measurements",
        "CargoServices": "Cargo Services",
        "CargoServices_p1": "Explore our diverse cargo solutions",
        "ECommercePartners": "eCommerce Partners",
        "ECommercePartners_p1": "Learn about Gold Star Line's advanced eCommerce systems",
        "KNOWMORE": "Learn More",
       
    },
    "cn": {
        "SERVICES": "服務",
        "Sailingschedule": "船期表",
        "Sailingschedule_p1": "及時了解金星航運船期表",
        "Equipments": "貨櫃箱",
        "Equipments_p1": "集裝箱規格和尺寸",
        "CargoServices": "貨運服務",
        "CargoServices_p1": "探索我們多樣化的貨運解決方案",
        "ECommercePartners": "電商合作夥伴",
        "ECommercePartners_p1": "了解金星航運的高級電子商務系統",
        "KNOWMORE": "閲讀更多",
       
    },
    "zh": {
        "SERVICES": "服务",
        "Sailingschedule": "船期表",
        "Sailingschedule_p1": "及时了解金星航运期表",
        "Equipments": "集装箱",
        "Equipments_p1": "集装箱规格和尺寸",
        "CargoServices": "货运服务",
        "CargoServices_p1": "探索我们多种化的货物运输解决方案",
        "ECommercePartners": "电商合作伙伴",
        "ECommercePartners_p1": "了解了金星航运的高级电子商务系统",
        "KNOWMORE": "知道更多",
       
    },
    "ko": {
        "SERVICES": "서비스",
        "Sailingschedule": "출항 스케줄",
        "Sailingschedule_p1": "Gold Star Line의 최신 일정 확인",
        "Equipments": "용기(컨테이너)",
        "Equipments_p1": "컨테이너 재원 및 규격",
        "CargoServices": "화물 서비스",
        "CargoServices_p1": "당사의 다양한 화물 솔루션 경험",
        "ECommercePartners": "eCommerce 파트너",
        "ECommercePartners_p1": "Gold Star Line의 진보된 eCommerce 시스템 알기",
        "KNOWMORE": "더 알아보기",
       
    },
    "vi": {
        "SERVICES": "DỊCH VỤ",
        "Sailingschedule": "Lịch tàu",
        "Sailingschedule_p1": "Lịch tàu Gold Star Lines luôn luôn được cập nhật",
        "Equipments": "Thiết bị",
        "Equipments_p1": "Thông số kỹ thuật, kích thước của container",
        "CargoServices": "Dịch vụ hàng hóa",
        "CargoServices_p1": "Khám phá các giải pháp vận chuyển hàng hóa đa dạng của chúng tôi",
        "ECommercePartners": "Các đối tác thương mại điện tử",
        "ECommercePartners_p1": "Tìm hiểu về hệ thống thương mại điện tử tiên tiến của Gold Star Line",
        "KNOWMORE": "Tìm hiểu thêm",
       
    },
    "th": {
        "SERVICES": "บริการของเรา",
        "Sailingschedule": "ตารางเรือ",
        "Sailingschedule_p1": "ติดตามข่าวสารล่าสุดเกี่ยวกับตารางเรือของ Gold Star Line",
        "Equipments": "ตู้ตอนเทนเนอร์",
        "Equipments_p1": "Container specifications, dimensions and measurements",
        "CargoServices": "บริการขนส่งสินค้า",
        "CargoServices_p1": "สำรวจโซลูชันการขนส่งสินค้าที่หลากหลายของเรา",
        "ECommercePartners": "พันธมิตรอีคอมเมิร์ซ",
        "ECommercePartners_p1": "เรียนรู้เกี่ยวกับระบบอีคอมเมิร์ซของ Gold Star Line",
        "KNOWMORE": "เพิ่มเติม",
       
    },
    "in": {
        "SERVICES": "Layanan",
        "Sailingschedule": "Jadwal Pelayaran",
        "Sailingschedule_p1": "Tetap up to date dengan jadwal Gold Star Line",
        "Equipments": "Peralatan",
        "Equipments_p1": "Spesifikasi kontainer, dimensi dan pengukuran",
        "CargoServices": "Layanan Kargo",
        "CargoServices_p1": "Jelajahi beragam solusi kargo kami",
        "ECommercePartners": "Mitra eCommerce",
        "ECommercePartners_p1": "Pelajari tentang sistem eCommerce canggih Gold Star Line",
        "KNOWMORE": "KETAHUI LEBIH LANJUT",
       
    },
    "ja": {
        "SERVICES": "サービス",
        "Sailingschedule": "航行スケジュール",
        "Sailingschedule_p1": "最新のスケジュールを検索",
        "Equipments": "装備",
        "Equipments_p1": "コンテナの種類と寸法",
        "CargoServices": "貨物サービス",
        "CargoServices_p1": "当社の多様な貨物ソリューションをご覧ください",
        "ECommercePartners": "eコマースパートナー",
        "ECommercePartners_p1": "Gold Star Line の高度な e コマース システムについて説明",
        "KNOWMORE": "Learn More",
       
    },
}
export default homesolaslang;