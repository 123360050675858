import React, { Component, useEffect, useState } from "react";
import AboutGsl from '../../assets/inner-images/about/about_gsl.jpg';
import companyProfile from '../../assets/inner-images/about/company_profile.jpg';
import companyHistory from '../../assets/inner-images/about/company_history.jpg';
import factsFigures from '../../assets/inner-images/about/facts_figures.jpg';
import cargoServices from '../../assets/inner-images/cargo_services/cargo_services.jpg';
import dangerousCargo from '../../assets/inner-images/cargo_services/dangerous_cargo.jpg';
import dryCargo from '../../assets/inner-images/cargo_services/dry_cargo.jpg';
import gslMonitor from '../../assets/inner-images/cargo_services/gsl_monitor.jpg';
import inlandTransport from '../../assets/inner-images/cargo_services/inland_transport.jpg';
import projectCargo from '../../assets/inner-images/cargo_services/project_cargo.jpg';
import reeferCargo from '../../assets/inner-images/cargo_services/reefer_cargo.jpg';
import temperatureControlledShipping from '../../assets/inner-images/cargo_services/temperature_controlled_shipping.jpg';
import highValueOrSensitiveCargo from '../../assets/inner-images/cargo_services/high_value_or_sensitive_cargo.jpg';
import Catalogue from '../../assets/inner-images/tools/catalogue.jpg';
import eblService from '../../assets/inner-images/tools/ebl_service.jpg';
import requestaQuote from '../../assets/inner-images/tools/request_a_quote.jpg';
import siSubmission from '../../assets/inner-images/tools/si_submission.jpg';
import bookingSubmission from '../../assets/inner-images/tools/booking_submission.jpg';
import vgmMassUpload from '../../assets/inner-images/tools/vgm_mass_upload.jpg';
import careers from '../../assets/inner-images/careers/careers.jpg';
import demurrage_and_detention_tariff from '../../assets/inner-images/local_info/demurrage_and_detention_tariff.jpg';
import local_info from '../../assets/inner-images/local_info/local_info.jpg';
import help_customer_advisory from '../../assets/inner-images/help/help_customer_advisory.jpg';
import newsletter from '../../assets/inner-images/newsletter/newsletter.jpg';
import terms_of_use from '../../assets/inner-images/help/terms_of_use.jpg';
import enquiry_us from '../../assets/inner-images/enquiry/enquiry_us.jpg';
import faq_gsl from '../../assets/inner-images/enquiry/faq_gsl.jpg';
import catalogues from '../../assets/inner-images/catalogue/catalogue.jpg';
import roro from '../../assets/inner-images/roro/roro.jpg';
import subscribe from '../../assets/inner-images/about/subscribe.jpg';
import shapeBlue from '../../../src/assets/banner-shape-blue.svg';
import shapeYellow from '../../../src/assets/banner-shape.svg';

const innerJSON =
{
    "subscribe": {
        "title": "Berlangganan",
        "discription": "Terima peringatan waktu nyata untuk kiriman Anda dan dapatkan visibilitas penuh atas status kargo Anda",
        "url": subscribe,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
        
    },
    "about_gsl": {
        "title": "Katalog Kargo",
        "discription": "Rencanakan pengiriman Anda sesuai dengan spesifikasi dan kebutuhan kargo Anda",
        "url": AboutGsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',

    },
    "cargo_catalogue": {
        "title": "Katalog Kargo",
        "discription": "Rencanakan pengiriman Anda sesuai dengan spesifikasi dan kebutuhan kargo Anda",
        "url": catalogues,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
        
    },
    "request_a_quote": {
        "title": "Meminta Penawaran",
        "discription": "Misi dan tujuan kami adalah menyediakan berbagai layanan kargo untuk mengutamakan bisnis Anda",
        "url": requestaQuote,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_submission": {
        "title": "Pengajuan Pemesanan",
        "discription": "Misi dan tujuan kami adalah menyediakan berbagai layanan kargo untuk mengutamakan bisnis Anda",
        "url": bookingSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ebl_service": {
        "title": "Layanan eBL",
        "discription": "Misi dan tujuan kami adalah menyediakan berbagai layanan kargo untuk mengutamakan bisnis Anda",
        "url": eblService,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "si_submission": {
        "title": "Pengajuan SI",
        "discription": "Penyampaian detail kargo, pihak-pihak yang terlibat, dan persyaratan secara mulus",
        "url": siSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ecommerce_partners": {
        "title": "Mitra Ecommerce",
        "discription": "Misi dan tujuan kami adalah menyediakan berbagai layanan kargo untuk mengutamakan bisnis Anda",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "vgm_mass_upload": {
        "title": "Unggahan massal Vgm",
        "discription": "Pengajuan VGM untuk beberapa pengiriman menjadi mudah dan familier",
        "url": vgmMassUpload,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "high_value_or_sensitive_cargo": {
        "title": "Kargo sensitif bernilai tinggi",
        "discription": " GSL Monitor memberi Anda alat yang tepat untuk melacak, memantau, dan mengontrol kargo berharga Anda, memastikannya dikirim dengan aman",
        "url": highValueOrSensitiveCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "enquiry": {
        "title": "Hubungi Kami",
        "discription": "Hubungi kami! Kami akan dengan senang hati memberi Anda semua informasi yang Anda perlukan",
        "url": enquiry_us,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "faq": {
        "title": "Pertanyaan yang Sering Diajukan",
        "discription": "Pelajari lebih lanjut tentang Gold Star Line dan layanan kami",
        "url": faq_gsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "roro-private": {
        "title": "Layanan Pembawa Mobil",
        "discription": "Memenuhi permintaan pengiriman otomotif yang terus meningkat di seluruh dunia",
        "url": roro,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "newsletter": {
        "title": "Berita & Pembaruan",
        "discription": "Nantikan informasi dan pengumuman terbaru!",
        "url": newsletter,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_newsletter": {
        "title": "Lihat Surat Kabar",
        "discription": "Misi dan tujuan kami adalah menyediakan berbagai layanan kargo untuk mengutamakan bisnis Anda",
        "url": newsletter,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "cargo_services": {
        "title": "Layanan Kargo",
        "discription": "Misi kami adalah menawarkan rangkaian lengkap layanan angkutan laut untuk semua jenis kargo.",
        "url": cargoServices,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "gsl_monitor": {
        "title": "GSL Monitor",
        "discription": "Lacak, pantau, dan bahkan kendalikan lingkungan kargo Anda terutama saat mengirim kargo sensitif, bernilai tinggi, atau reefer",
        "url": gslMonitor,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dry_cargo": {
        "title": "Kargo Dry",
        "discription": "Dengan pengalaman lebih dari 60 tahun yang didedikasikan untuk menangani semua jenis kargo kering, kami akan memenuhi kebutuhan Anda.",
        "url": dryCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "reefer_cargo": {
        "title": "Kargo Reefer",
        "discription": "Teknologi canggih kami memastikan kargo reefer Anda dikirimkan dengan aman, pada suhu yang tepat, dan tepat waktu.",
        "url": reeferCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "project_cargo": {
        "title": "Kargo Project",
        "discription": "Kami memiliki solusi kreatif dan keahlian yang diperlukan untuk memindahkan kargo khusus Anda dengan aman ke seluruh dunia.",
        "url": projectCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "inland_transport": {
        "title": "Transportasi Darat",
        "discription": "Menyediakan layanan transportasi darat yang andal melalui jaringan door-to-door kami yang luas",
        "url": inlandTransport,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "temperature_controlled_shipping": {
        "title": "Pengiriman Suhu Terkendali",
        "discription": "Dingin atau beku, kami menyediakan transportasi yang aman untuk semua produk yang sensitif terhadap suhu",
        "url": temperatureControlledShipping,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dangerous_cargo": {
        "title": "Kargo Berbahaya",
        "discription": "Kami menawarkan layanan khusus dan profesional untuk penyimpanan dan pengangkutan barang berbahaya yang aman",
        "url": dangerousCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "about_gsl": {
        "title": "Tentang kami",
        "discription": "Gold Star Line - pemain utama dalam Perdagangan Intra Asia, Oseania dan Afrika, melayani pasar yang dinamis dengan pendekatan yang gesit",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_profile": {
        "title": "Profil Perusahaan",
        "discription": "Fokus kami adalah menyediakan layanan yang paling sesuai dengan kebutuhan khusus pelanggan kami",
        "url": companyProfile,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_history": {
        "title": "Cerita kita",
        "discription": "Lakukan perjalanan melintasi waktu dan temukan sejarah, pencapaian, dan perkembangan Gold Star Line",
        "url": companyHistory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "facts_figures": {
        "title": "Fakta & Angka",
        "discription": "Misi kami adalah terus mencari solusi kreatif untuk mendukung kebutuhan pelanggan kami",
        "url": factsFigures,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "demurrage_and_detention_tariff": {
        "title": "tarif demurrage dan detensi",
        "discription": "Demurrage, penahanan, dan biaya per diem tidak harus menjadi misteri",
        "url": demurrage_and_detention_tariff,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "local_info": {
        "title": "Informasi Lokal",
        "discription": "Selalu perbarui informasi berguna yang spesifik untuk lokasi Anda",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "unsubscribe_schedule": {
        "title": "Berhenti Berlangganan Jadwal",
        "discription": "",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "contact_us": {
        "title": "Detail Kantor GSL",
        "discription": "Dapatkan detail berbagai kantor, alamat, dan kontak kami",
        "url": enquiry_us,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "careers": {
        "title": "Karir",
        "discription": "Bergabunglah dengan Gold Star Line dan tumbuh bersama kami!",
        "url": careers,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "solas_regulation": {
        "title": "Peraturan solas",
        "discription": "Misi dan tujuan kami adalah menyediakan berbagai layanan kargo untuk mengutamakan bisnis Anda",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "customer_advisory": {
        "title": "Penasihat Pelanggan",
        "discription": "Pantau terus notifikasi dan saran kami",
        "url": help_customer_advisory,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "accessibility": {
        "title": "Aksesibilitas",
        "discription": "Tingkatkan kualitas hidup dengan model aksesibilitas kami",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "data_protection_policy": {
        "title": "Kebijakan Perlindungan Data",
        "discription": "Dapatkan informasi tentang kebijakan kami tentang perlindungan data pribadi",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_cookies_policy": {
        "title": " Cookies and Tracking Policy",
        "discription": "Komitmen kami terhadap data pribadi dan privasi data Anda",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "quotation_terms_conditions": {
        "title": "Syarat Ketentuan Penawaran",
        "discription": "Tetap selaras dengan syarat dan ketentuan penawaran kami",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "terms_of_use": {
        "title": "Syarat Penggunaan",
        "discription": "Pedoman dan Ketentuan layanan",
        "url": terms_of_use,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_customer_advisory": {
        "title": "Penasihat Pelanggan",
        "discription": "Pengumuman dan Nasihat untuk Pelanggan kami",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "bl_terms_conditions": {
        "title": "Syarat ketentuan B/L",
        "discription": "Tetap perhatikan tentang ketentuan hukum B/L (Bill of Ladings) kami",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_go_terms_conditions": {
        "title": "Syarat dan ketentuan GSL GO",
        "discription": "Tetap perhatikan tentang ketentuan hukum GSL GO",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "about_car_carrier": {
        "title": "Tentang Pengangkut Mobil",
        "discription": "",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "egsl_terms_conditions": {
        "title": "syarat dan ketentuan eGSL",
        "discription": "Tetap perhatikan tentang ketentuan hukum B/L (Bill of Ladings) kami",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_clause": {
        "title": "Klausul Pemesanan",
        "discription": "Kenali klausul yang relevan dengan dokumen konfirmasi Pemesanan kami",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_company_news": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_regulation": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_industry": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },"sea_waybill_terms_conditions": {
        "title": " SEA WAYBILL TERMS AND CONDITIONS",
        "discription": "TERMS AND CONDITIONS",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
}


export default innerJSON;