import React, { Component } from 'react';
import Layout from '../../layout';
import GET_IN_TOUCH from '../../get_in_touch/index';
import Services from '../../get_in_touch/services';
import Packages from '../../get_in_touch/packages_block';
import Inner_container from '../inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import inside_heading from '../../../../src/assets/inside-heading.png';
import banner1 from "../../../assets/banner1.jpg";

const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class DataProtectpolicy extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            factsfig: [],

        };

    }
    async componentDidMount() {

    }
    render() {
        return (

            <Layout>
                <Inner_container innerpage="data_protection_policy" />
                <section class="inner-center-data">
                    <div class="container">
                        <div class="">
                            <form class="">
                                <div class="row m-0">
                                    <div class="col-lg-12 p-0 data-policy-para">
                                        <p>This Data Protection Policy (hereinafter, “Policy”) applies to all activities of Gold Star Line Ltd.(hereinafter called “GSL” or “We”) with respect to the protection of personal data that GSL collects, processes and stores in the course of its business activities, including through the GSL websites (“Websites”) and other interfaces with data subjects that it operates.</p>
                                    </div>
                                    <div class="col-lg-12 p-0 text-center ">
                                        <h3 class="solas h"> General Principles </h3>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 pot-form pl-0 data-policy-para">
                                        <p>GSL is committed to ensuring that your personal data and data privacy are protected in accordance with the best practices available, as well as according to GSL’s relevant legal obligations.This Policy explains your rights regarding the personal data GSL collects and processes, including via the Websites, Personal Area, and Apps, as well as GSL’s use of safeguards to protect these rights.The Policy also applies to GSL’s use of cookies and tracking on the relevant website and application interfaces, as specified in the Cookies and Tracking Policy.</p>
                                        <p>Your personal data will be collected and stored lawfully, fairly and transparently by GSL.In processing your data, we act in good faith, in a proportionate manner, and with the appropriate technical and organizational measures.</p>
                                        <p>The collection, processing and use of personal data may be carried out, in the course ofGSL’s business and supply of services, by external data processors on behalf of GSL.GSL requires, within the framework of its contracts with such external processors, for them to comply with relevant legal data protection rules and legislation applicable to the services they provide to GSL.</p>
                                        <p>We may revise this Policy from time to time, and such changes shall come into effect from the moment GSL notifies you of such changes and/or updates, either by email, on the Websites or in some other reasonable manner.</p>
                                        <p><b>Important note: </b> please refrain from logging in and making use of the Websites, Personal Area and Apps, or otherwise providing GSL with your personal data, if you do not consent to the collection, use, processing and disclosure of this data as set forth below.You may indicate your consent to this Policy, by clicking “I agree” on the button that appears at the bottom of the Policy.[Consent tab at the end of the Policy]</p>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div >
                </section >

                <section class="inner-center-data dat-color" style={{background: `#a7a7a72b;`}}>
                    <div class="container">

                        <div class="row m-0">
                            <div class="col-lg-12">
                                <form class="">
                                    <div class="">

                                        <div class="row ml-0 mr-0 ">
                                            <div class="col-lg-12 p-0 ">
                                                <h3 class="solas h"> GSL’s Data Protection Policy</h3>
                                            </div>
                                            <div class="col-lg-6 col-sm-12 pot-form pl-0  ">
                                                <h5>This Policy is divided into the following sections: </h5>
                                                <ul class="protc-dat">

                                                    <li>Your rights as a data subject</li>
                                                    <li>Why GSL collects your personal data and uses cookies on this site</li>
                                                    <li>The type of personal data we collect and how we use it</li>
                                                    <li>GSL’s protection of your personal data</li>
                                                    <li>Special protection of the personal data of children and minors</li>
                                                    <li>Cookies and Tracking Policy</li>

                                                </ul>


                                            </div>
                                            <div class="col-lg-6 col-sm-12 pot-form pl-0 dat-relat">
                                                <div class="data-protect pt-3" style={{ backgroundImage: `url(${banner1})` }}>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div >
                </section >

                <section class="inner-center-data">
                    <div class="container">

                        <div class="row m-0">
                            <div class="col-lg-12">
                                <form class="">
                                    <div class="">
                                        <div class="row ml-0 mr-0 ">
                                            <div class="col-lg-12 p-0 text-center">
                                                <h3 class="solas h"> YOUR RIGHTS AS A DATA SUBJECT</h3>
                                            </div>
                                            <div class="col-lg-6 col-sm-12 pot-form pl-0  data-policy-para">
                                                <p><b>•	Consent - </b>We will only process personal data if you have consented to such processing, unless We are otherwise legally authorized or obliged to collect and further process personal data relating to you. If We process data solely based on your informed consent, we will only use the data for the purposes stated in the consent procedure and within the scope outlined below.</p>
                                                <p><b>•	Withdrawal of consent - </b>You may revoke or withdraw your consent at any time by using this Consent Withdrawal Notice. Upon receipt of your notification, We will delete your data unless We are legally obliged or authorized to retain your personal data, including for ongoing legitimate business interests. Note that your notification of consent withdrawal may not be upheld by GSL for lack of relevant information provided by you. </p>
                                                <p><b>•	The “right to be forgotten” and data correction -</b> You may also request the erasure or correction of all or part of your personal data held by GSL, by using this Erasure and Correction Request Form. Upon your request, GSL will close your account/registration and/or remove or correct your personal data from all of our records, as soon as reasonably possible and when technically feasible, unless there is an overriding legal interest or other authorized basis for the continuation of the data processing.  The foregoing notwithstanding, GSL will store just enough of your personal data to ensure that the restriction is respected in the future. Please be aware that in the event of deletion or correction of personal data upon your request, GSL may not be able to further provide services via the Websites, Personal Area, Apps and other interfaces in whole or in part: in so requesting, you irrevocably agree and waive any claim against GSL's inability to provide the services through the Websites and Personal Area, or any interruption or malfunction resulting therefrom. Note that your request may also be rejected by GSL for lack of relevant information provided by you.</p>

                                            </div>
                                            <div class="col-lg-6 col-sm-12 pot-form pl-0 data-policy-para">
                                                <p><b>•	Personal data of children and minors -</b> We do not knowingly collect or process personal data relating to children and minors under the age of 16 years, unless We are legally obliged to do so. If We become aware that personal data was transferred to us or collected by us relating to children and minors under the age of 16 years without the informed consent of a parent or legal guardian, We will delete such personal data without undue delay. If the request for erasure of data relates to the personal data of a child or minor, please attach to the request adequate proof of your status as parent or guardian of the child or minor.</p>
                                                <p><b>•	Transparency.</b> You are entitled to ask us for information about what type of personal data about you has been collected online by GSL. You are also entitled to obtain (in a commonly used and machine-readable form) and reuse your personal data as you have provided to us (via consent or contract performance) and which We process by automated means for your own purposes across different services and free of charge, subject to technical feasibility and lawfulness considerations. To do so, please use this Data Portability Request Form. Note that your request may also be rejected by GSL for lack of relevant information provided by you.</p>
                                                <p><b>•	Automated decision-making.</b>GSL shall not intentionally take any potentially damaging decision concerning you because of using automated processing operations without human intervention; and commits to giving you the opportunity to obtain human intervention in such a decision, express your point of view, and obtain an explanation of the decision. </p>
                                                <p>In the implementation of these data protection rights, GSL is committed to providing a timely and transparent response to your requests, and upholding your right to contact GSL’s Data Protection Officer directly.  </p>
                                            </div>
                                        </div>



                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="inner-center-data dat-bacc" >
                    <div class="container">
                        <form class="">
                            <div class="">
                            <div class="row ml-0 mr-0 ">

                                <div class="col-lg-6 col-sm-12 pot-form pl-0  ">
                                    <div class="col-lg-12 p-0 text-left">
                                        <h3 class="solas h"> WHY GSL COLLECTS YOUR PERSONAL DATA AND USES COOKIES ON THIS SITE</h3>
                                    </div>

                                    <h5>GSL uses your personal data for the following reasons:</h5>
                                    <ul class="protc-dat">
                                        <li>to ensure that content in the Websites, Personal Area, Apps and other relevant interfaces is presented in the most effective manner for you;</li>
                                        <li>to process your requests and inquiries, including those that include payment-related data, where applicable;</li>
                                        <li>to provide the services requested by you.</li>

                                    </ul>
                                    <p>Cookies and tracking are used in the Websites, Personal Area and Apps to distinguish you from other users and to improve your use of these interfaces. In some cases, certain features may not function if you remove the cookies from your browser.</p>
                                    <p>Additional information about the use of cookies and tracking by GSL may be found in our Cookies and Tracking Policy below, under the title “The use of cookies and tracking on GSL websites”.</p>
                                 </div>
                            <div class="col-lg-6 col-sm-12 pot-form pl-0">

                                <div class="col-lg-12 p-0 text-left">
                                    <h3 class="solas h"> GSL’S PROTECTION OF YOUR PERSONAL DATA</h3>
                                </div>
                                <div class="col-lg-12 col-sm-12 pot-form pl-0  ">

                                    <ul class="protc-dat">
                                        <li>We have taken reasonable, industry-standard technical and organizational measures to protect your personal data against loss, alteration, theft or access by unauthorized third parties.</li>
                                        <li>We will not sell, share, transfer or use the data We collect from you for purposes other than those purposes stated herein above.</li>
                                        <li>GSL deletes personal data where the business purpose for which the data was being collected or processed ceases to apply, or if applicable data protection rules require us to delete such personal data.</li>
                                        <li>The above notwithstanding, GSL will not be liable or responsible for any damage or loss resulting from the improper use or any breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, personal data.</li>

                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </section>

                <section class="inner-center-data">
                    <div class="container">

                        <div class="row m-0">
                            <div class="col-lg-12">
                                <form class="">
                                    <div class="">
                                        <div class="row ml-0 mr-0 ">
                                            <div class="col-lg-12 p-0 text-center">
                                                <h3 class="solas h"> THE TYPE OF PERSONAL DATA WE COLLECT AND HOW WE USE IT</h3>
                                            </div>
                                            <div class="col-lg-6 col-sm-12 pot-form pl-0  data-policy-para">
                                                <p>• The personal data collected and used by GSL is limited strictly to that data necessary for us to provide you with services we supply in the course of our ongoing business while operating under GSL's relevant legal data protection legislation and regulations obligations, including the principles of data protection, reduction and data minimization.</p>
                                                <p>• The data collected and processed is both general in nature, (for example, for technical purposes) and personal data, contact details, signatures and other information inputted by you, depending on the services selected and utilized by you. </p>
                                                <p>• Some automatic processing of your personal data may occur. Data processed may include specifically your name, some identifying numbers, the name of your internet provider, your IP address, browser type and system software, as well as the websites you have visited before being transferred to GSL websites and apps, including keywords used for searches and the sites from which you have been transferred (e.g., search engine or linked content). As is true of most organizations operating websites and apps, we also gather certain information automatically and store it in log files. This information includes but is not limited to IP addresses, browser type, Internet service provider (ISP), referring/exit pages, operating system, date/time stamp and click stream data. We use this information to analyze trends, to administer the site, to track users' movements around the site and to gather information about our user base as a whole. We may link this automatically-collected data to personal information for legitimate business purposes, such as to detect and prevent fraudulent activity; and other authorized purposes.</p>

                                            </div>
                                            <div class="col-lg-6 col-sm-12 pot-form pl-0 data-policy-para">
                                                <p>• When you receive services from GSL through the Websites, Personal Area and Apps, you may also be directed to third-party processors such as payment service providers, including credit card or online payment providers. When GSL uses such third-party payment processors, we do not store credit card details, but instead rely on the third-party service provider to process personal data in order to provide these services, and GSL contractually requires such third-party payment processors to comply with the data protection and other laws and regulations applicable to their payment processing services. Please check the respective service provider's relevant privacy terms and compliance with applicable laws prior to usage of any such processor's services.</p>
                                                <p>• The Websites and Personal Area may, from time to time, contain links to and from websites of our partners’ networks, advertisers and affiliates. Such third-party websites are governed solely by such third parties' privacy policies and you are advised to be careful and check any such third party's privacy policies and compliance with laws, prior to supplying them with any of your personal data to them.</p>
                                                <p>• Please note that We may also disclose your data to a third party if We are required to do so by applicable law, court order or governmental regulation; or if such disclosure is otherwise authorized and necessary in support of any criminal or legal investigation or proceeding. </p>
                                                <p>• GSL may need to transfer the data submitted by you for the purposes described above to parties in locations outside the country in which you are physically located, potentially including countries which may not require an adequate level of protection for your personal data compared with that provided in your country. By accepting this Policy, you consent to the data transfer as described in this section.  </p>
                                            </div>
                                        </div>



                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}