import React, { Component, useEffect, useState, useRef } from "react";
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import Inland from '../../../../src/assets/Inland.jpeg';
import abt_icn from '../../../../src/assets/abt-icn.png';
import Inner_container from '../../innerpages/inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import contact_us_lang from '../../lang/contact_us'


const qs = require('qs');
var jwt = require('jsonwebtoken');

const Contact_us = (props) => {
	var lang = window.localStorage.getItem("lang");
	const headers = {
		type: "application/json",
		Authorization: `Bearer `+window.localStorage.getItem('access_token'),
	  };
	  const {
		register,
		control,
		setValue,
		handleSubmit,
		watch,
		getValues,
		formState: { errors },
	  } = useForm();
	const [country_code, setCountryCode] = useState(window.$country_code);
	const [general_info_officemap, setgeneral_info_officemap] = useState([]);
	const [port_code, setportCode] = useState([]);
	const [officemap_contact_point, setofficemap_contact_point] = useState([]);
	const [officemap_desk_point, setofficemap_desk_point] = useState([]);
	const [loaderFlag, setloaderFlag] = useState("is-inactive");
	const buttonRef = useRef();
	const getLoadData = async () => {
		const response = await axios.post(
			Api.userdata.url + "/header_search",
			qs.stringify({ country_code: window.localStorage.getItem('country_code') }, { parseArrays: false }),
			{ headers: headers }
		 );
		  const json = await response.data;
		  setportCode(json["message"]);
		var id = props.match.params.any;
		if(id  !== 'undefined' && id != null)
		{
			setTimeout(() => {
				setValue("from_port", { value: id });
			  }, 1000);

				const general_info_officemap = await axios.post(Api.userdata.url + '/general_info_officemap', qs.stringify({ 'token': id }, { parseArrays: false }),{headers:headers});
				const general_info_officemap_json = await general_info_officemap.data;
				(general_info_officemap_json['status'] == 1)?setgeneral_info_officemap(general_info_officemap_json['message']):setgeneral_info_officemap([]);
               const officemap_contact_point = await axios.post(Api.userdata.url + '/officemap_contact_point', qs.stringify({ 'token': id }, { parseArrays: false }),{headers:headers});
				const officemap_contact_point_json = await officemap_contact_point.data;
				(officemap_contact_point_json['status'] == 1)?setofficemap_contact_point(officemap_contact_point_json['message']):setofficemap_contact_point([]);
				const officemap_desk_point = await axios.post(Api.userdata.url + '/officemap_desk_point', qs.stringify({ 'token': id }, { parseArrays: false }),{headers:headers});
				const officemap_desk_point_json = await officemap_desk_point.data;
				(officemap_desk_point_json['status'] == 1)?setofficemap_desk_point(officemap_desk_point_json['message']):setofficemap_desk_point([]);
		}	


	}
	const onSubmit = (data) => {
		get_contacts(data);
	  };
	  const get_contacts = async (data) => {
		setloaderFlag("is-active");
		const general_info_officemap = await axios.post(Api.userdata.url + '/general_info_officemap', qs.stringify({ 'token': data.from_port }, { parseArrays: false }),{headers:headers});
		const general_info_officemap_json = await general_info_officemap.data;
		(general_info_officemap_json['status'] == 1)?setgeneral_info_officemap(general_info_officemap_json['message']):setgeneral_info_officemap([]);
		const officemap_contact_point = await axios.post(Api.userdata.url + '/officemap_contact_point', qs.stringify({ 'token': data.from_port }, { parseArrays: false }),{headers:headers});
		const officemap_contact_point_json = await officemap_contact_point.data;
		(officemap_contact_point_json['status'] == 1)?setofficemap_contact_point(officemap_contact_point_json['message']):setofficemap_contact_point([]);
		const officemap_desk_point = await axios.post(Api.userdata.url + '/officemap_desk_point', qs.stringify({ 'token': data.from_port }, { parseArrays: false }),{headers:headers});
		const officemap_desk_point_json = await officemap_desk_point.data;
		(officemap_desk_point_json['status'] == 1)?setofficemap_desk_point(officemap_desk_point_json['message']):setofficemap_desk_point([]);
	
		setloaderFlag("is-inactive");
	
	
	  };
	useEffect(() => {
	
		getLoadData();
	}, []);
	return (<Layout>

<Inner_container innerpage="enquiry" />

<section class="inner-center contatc_padd n-40">

      <div class={"loadernew loader-double " + loaderFlag}></div>
           
			<div class="container">
			<div class="row m-0">
            <div class="col-lg-12 col-md-12 col-lg-12">
              <form
                autocomplete="on"
                name="point-to-point-new"
                id="point-to-point-new"
                method="get"
                action="#"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div class="form-fback">
                  <div class="row m-0">
                    <div class="col-lg-6 col-md-12 col-sm-12 pot-form p-3">
                      <div class="dout-inner">
                        <h3>{contact_us_lang[lang].GSLOfficeDetails}</h3>
                        <p>{contact_us_lang[lang].Getourvariousofficedetails}</p>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 p-0 pot-form">
                      <div class="row">
                        <div class="col-md-8 col-lg-8 col-sm-12">
                          <div class="autocomplete">
                            <div>
                              <label>{contact_us_lang[lang].From}</label>
                              <Controller
                                control={control}
                                rules={{
                                  required: "Please select from port",
                                }}
                                render={({ onChange, value, ref }) => (
                                  <Select
                                    options={port_code.map((data) => ({
										value: data["PortCode"],
										label:
										  data["CountryName"] +
										  "-" +
										  data["PortName"] +
										  "(" +
										  data["PortCode"] +
										  ")",
                                    }))}
                                    onChange={(val) => {
                                      setValue("from_port", val.value);
                                    }}
                                  />
                                )}
                                defaultValue=""
                                placeholder="Select platform"
                                name="from_port"
                                id="from_port"
                                setValue={setValue}
                               
                              />

                              {errors.from_port && (
                                <span class="errors">
                                  {errors.from_port.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
						<div class="col-md-4 col-lg-4 col-sm-12  start-date-btn ">
                          <div class="form-group" style={{paddingTop: `37px`}}>
                            <button
                              class="btn btn-default hovereffect portal-searc-tariff"
                              id="submitDetails"
                              name="my-buttons"
                              ref={buttonRef}
                            >
                             {contact_us_lang[lang].Find} 
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
				<div class="row m-0">
					<div class="col-lg-12 col-xl-12 col-sm-12 col-md-12">
					
						<div class="row m-0">
							<div class="col-lg-12 col-sm-12 pot-form p-0 gsl-off-det">
								<h4> {contact_us_lang[lang].GeneralInfo}  </h4>
								{general_info_officemap !== 'undefined' && general_info_officemap.map(data => (
									<ul class="gsl-off-cont-detail">
										<li class="caon-first">
											<ul>
												<li>
													<p class="con-addrs-back">{contact_us_lang[lang].CountryName}</p>
													<p>: {data['CountryName']}</p>
												</li>
												<li style={{ background: "#f7f7f7;" }}>
													<p class="con-addrs-back">{contact_us_lang[lang].PortName}</p>
													<p>: {data['PortName']}</p>
												</li>
												<li>
													<p class="con-addrs-back">{contact_us_lang[lang].CompanyName}</p>
													<p>:{data['CompanyName']}</p>
												</li>
											</ul>
										</li>
										<li class="caon-second">
											<ul>
												<li style={{ background: "#f7f7f7;" }}>
													<p class="con-addrs-back">{contact_us_lang[lang].Address1}</p>
													<p>: {data['Address1']}</p>
												</li>
												<li>
													<p class="con-addrs-back">{contact_us_lang[lang].Address2}</p>
													<p>: {data['Address2']}</p>
												</li>
												<li style={{ background: "#f7f7f7;" }}>
													<p class="con-addrs-back">{contact_us_lang[lang].Address3}</p>
													<p>: {data['Address3']}</p>
												</li>
												<li>
													<p class="con-addrs-back">{contact_us_lang[lang].Address4}</p>
													<p>: {data['Address4']}</p>
												</li>
											</ul>
										</li>
										<li class="caon-third">
											<ul>
												<li style={{ background: "#f7f7f7;" }}>
													<p class="con-addrs-back">{contact_us_lang[lang].Telephone}</p>
													<p>:{data['Telephone']}</p>
												</li>
												<li>
													<p class="con-addrs-back">{contact_us_lang[lang].Fax}</p>
													<p>: {data['Fax']}</p>
												</li>
												<li style={{ background: "#f7f7f7;" }}>
													<p class="con-addrs-back">{contact_us_lang[lang].Contact}</p>
													<p>: {data['Contact']}</p>
												</li>
												<li>
													<p class="con-addrs-back">{contact_us_lang[lang].Email}</p>
													<p>: {data['Email']}</p>
												</li>
											</ul>
										</li>
									</ul>
								))}

							</div>

							<div class="col-lg-12 col-sm-12 pot-form p-0 gsl-off-det">
								<h4>{contact_us_lang[lang].ContactDetails} </h4>
								<div class="table-responsive">
									<table class="table table-hover off-detail-table">
										<thead>
											<tr class="off-con-dateails">
												<th>{contact_us_lang[lang].Division}</th>
												<th>{contact_us_lang[lang].Contact}</th>
												<th>{contact_us_lang[lang].Telephone}</th>
												<th>{contact_us_lang[lang].Fax}</th>
												<th>{contact_us_lang[lang].Email}</th>
											</tr>
										</thead>


										<tbody >

											{officemap_contact_point !== 'undefined' &&  officemap_contact_point.map(data => (

												<tr class="data-off-table">
													<td>{data['Division']}</td>
													<td>{data['Contact']}</td>
													<td>{data['Telephone']}</td>
													<td>{data['Fax']}</td>
													<td>{data['Email']}</td>
												</tr>

											))}
										</tbody>
									</table>
								</div>

							</div>
						</div>

						<div class="row m-0">

							<div class="col-lg-12 col-sm-12 pot-form p-0 gsl-off-det">
								<h4>{contact_us_lang[lang].DeskDetails} </h4>
								<div class="table-responsive">
									<table class="table table-hover off-detail-table">
										<thead>
											<tr class="off-desk-datil">
												<th>{contact_us_lang[lang].Function}</th>
												<th>{contact_us_lang[lang].Email}</th>
											</tr>
										</thead>
										<tbody >
											{officemap_desk_point !== 'undefined' &&  officemap_desk_point.map(data => (
												<tr class="data-off-table">
													<td>{data['Function']} </td>
													<td>{data['Email']}</td>

												</tr>
											))}


										</tbody>
									</table>
								</div>



							</div>

						</div>

					</div>
				</div>
			</div>

		</section>


		<Services />
		<Packages />
		<GET_IN_TOUCH />

	</Layout >);
}

export default Contact_us;