import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import Services from "../../../components/get_in_touch/services";
import Packages from "../../../components/get_in_touch/packages_block";
import Inland from "../../../../src/assets/Inland.jpeg";
import abt_icn from "../../../../src/assets/abt-icn.png";
import Inner_container from "../../innerpages/inner-container";
import Api from "../../../../package.json";
import Moment from "moment";
import * as axios from "axios";
import inside_heading from "../../../../src/assets/inside-heading.png";
import { Button, Modal } from "react-bootstrap";
import { Accordion, Card, ButtonGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import career_lang from '../../lang/career';
import HCaptcha from "@hcaptcha/react-hcaptcha";

const qs = require("qs");
var jwt = require("jsonwebtoken");

const Career = () => {
  var lang = window.localStorage.getItem('lang');
  const headers = {
    "Content-Type": "multipart/form-data",
    type: "application/json",
    Authorization: `Bearer `+window.localStorage.getItem('access_token'),
  };
  const headers1 = {
    type: "application/json",
    Authorization: `Bearer `+window.localStorage.getItem('access_token'),
  };
  const [country_code, setCountryCode] = useState(window.$country_code);
  const [career, setCareer] = useState([]);
  const [careerCaptcha, setcareerCaptcha] = useState([]);
  const [postId, setpostID] = useState('');
  const [SubmitButton, setSubmitButton] = useState("Apply Now");
  const [resumeFile,setResumeFile] = useState("");
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const buttonRef = useRef();
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const [showHide, setShowHide] = useState(false);
  const handleModalShowHide = (id) => {
    setpostID(id);
    setShowHide(!showHide);
  };
  const onSubmit = (data) => {
    buttonRef.current.disabled = true;
    setSubmitButton("submitting...");
    save_careers(data);
  };
  const save_careers = async (data) => {
    if(token != null)
    {
      const bodyFormData = new FormData();
      bodyFormData.append("country_code", country_code);
      bodyFormData.append("post_id",  data.post_id);
      bodyFormData.append("name", data.name);
      bodyFormData.append("email", data.email);
      bodyFormData.append("phoneone", data.phoneone);
      bodyFormData.append("phone", data.phone);
      bodyFormData.append("career_captcha", token);
      bodyFormData.append("resume", resumeFile);
  
  
      const response = await axios({method: "post",
      url:Api.userdata.url + "/save_careers",
       data: bodyFormData,
       headers: headers,}
      );
      const json = await response.data;
      if (json["status"] == "OK") {
        swal("Successful Submitted", json["message"], "success").then((value) => {
          window.location.reload(false);
        });
      } else {
        swal("Error !", json["message"], "error");
      }
    }else{
      swal("Error !", "Invalid captcha", "error");
    }
    
   
    buttonRef.current.disabled = false;
    setSubmitButton("Submit");
  };
  const getLoadData = async () => {
    const career_res = await axios.post(
      Api.userdata.url + "career_data",
      qs.stringify({ country_code: country_code,lang:lang }, { parseArrays: false }),{headers:headers1}
    );
    const career_json = await career_res.data;
    setcareerCaptcha(career_json["captcha"]);
    if(career_json['status'] != 0)
    {
      setCareer(career_json["message"]);
      
    }
  };
  useEffect(() => {
    getLoadData();
  }, []);
  const onChangeResume = (e) => { 
  console.log(e);
  //alert(e.target.files[0].type);

    if (e.target.files[0]) {
      if (e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/png" || e.target.files[0].type == "application/pdf"  || e.target.files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ) {
        console.log("excel: ", e.target.files);
        setResumeFile(e.target.files[0]);
        
      } else {
        swal("Error !", "Invaild file format", "error");
        document.getElementById("resume").value = "";
      }
    }
  };
  return (
    <Layout>
      <Inner_container innerpage="careers"/>
      <section class="inner-center n-40" id="n-career-page">
        <div class="container">
          <div class="row m-0">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12 n-career-base">
              <form class="point-to" method="POST">
                {/* <div
                  class="sche-title"
                  style={{ backgroundImage: `url(${inside_heading})` }}
                >
                  <h3>CAREER</h3>
                </div> */}
                <div class="form-fback">
                  <div class="row m-0">
                    <div class="col-lg-12 col-sm-12 col-md-12">
                      {career.length != 0 ? (
                        <div class="accordion-toggle">
                          <h2> {career_lang[lang].Openings}</h2>
                          {career.map((data) => {
                            var from_date = Moment(data["from_date"]).format(
                              "DD-MM-YYYY"
                            );
                            var to_date = Moment(data["to_date"]).format(
                              "DD-MM-YYYY"
                            );
                            return (
                              <div>
                                <Accordion defaultActiveKey={career[0]["id"]}>
                                  <Card>
                                    <Card.Header
                                      bsPrefix="h3"
                                      className="accordion-link accordion-on"
                                    >
                                      <Accordion.Toggle
                                        as={ButtonGroup}
                                        variant="link"
                                        eventKey={data["id"]}
                                      >
                                        <h3 class="accordion-link accordion-on">
                                          {" "}
                                          {data["title"]} <span></span>
                                        </h3>
                                      </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={data["id"]}>
                                      <Card.Body>
                                        <div class="accordion-panel">
                                        {career_lang[lang].PostedDate}:
                                          <span class="label label-primary">
                                            {from_date}
                                          </span>
                                          | {career_lang[lang].ExpireDate}:
                                          <span class="label label-warning">
                                            {to_date}
                                          </span>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: data["description"],
                                            }}
                                          ></div>
                                          <button
                                            type="button"
                                            class="btn btn-default hovereffect btn career_ap_bt car-pop-but"
                                            onClick={() =>
                                              handleModalShowHide(data["id"])
                                            }
                                          >
                                            {career_lang[lang].Apply}
                                          </button>
                                        </div>{" "}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </div>
                            );
                          })}{" "}
                        </div>
                      ) : (
                        <div><h2>{career_lang[lang].NojobOpenings}</h2></div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div>
          <Modal show={showHide}>
            <Modal.Header closeButton onClick={() => handleModalShowHide("")}>
              <h4 class="modal-title">{career_lang[lang].ApplyNow}</h4>
            </Modal.Header>
            <Modal.Body>
              <form
                name="careers"
                method="POST"
                id="reused_form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <input type="hidden"  name="post_id" id="post_id" value={postId} {...register('post_id')}/>
                <div class="form-group">
                  <label for="name">{career_lang[lang].Name}:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    name="name"
                    {...register("name",{
                      required: {
                        value: true,
                        message: "Please enter your full name",
                      },
                    })}
                  />
                  {errors.name && (
                    <span class="errors">{errors.name.message}</span>
                  )}
                </div>
                <div class="form-group">
                  <label for="email">{career_lang[lang].Email}:</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    name="email"
                    maxlength="50"
                    {...register("email",{
                      required: {
                        value: true,
                        message: "Enter your email-id",
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.email && (
                    <span class="errors">{errors.email.message}</span>
                  )}
                </div>
                <div class="form-group">
                  <label for="email">{career_lang[lang].Contact}:</label>
                  <div class="phone-country">
                    <label class="phone-labb">+</label>
                    <input
                      name="phoneone"
                      placeholder=""
                      class="contry1"
                      type="text"
                      min="3"
                      {...register("phoneone",{
                        required: {
                          value: true,
                          message: "*",
                        },
                        minLength: {
                          value: 1,
                          message: "Min length 1",
                        },
                        maxLength: {
                          value: 3,
                          message: "Max length 3",
                        },
                        pattern: {
                          value: /^-?[0-9]\d*\.?\d*$/i,
                          message: "Invalid",
                        },
                      })}
                    />
                    {errors.phoneone && (
                      <span class="errors">{errors.phoneone.message}</span>
                    )}

                    <label class="phone-labb">-</label>
                    <input
                      type="text"
                      class="form-control"
                      name="phone"
                      maxlength="50"
                      {...register("phone",{
                        required: {
                          value: true,
                          message: "Please enter your mobile number",
                        },
                        minLength: {
                          value: 6,
                          message: "Invalid phone number",
                        },
                        maxLength: {
                          value: 15,
                          message: "Invalid phone number",
                        },
                        pattern: {
                          value: /^-?[0-9]\d*\.?\d*$/i,
                          message: "Invalid",
                        },
                      })}
                    />
                    {errors.phone && (
                      <span class="errors">{errors.phone.message}</span>
                    )}
                  </div>
                </div>
                <div class="form-group">
                  <label for="resume">{career_lang[lang].Resume}({career_lang[lang].pdfjpgpngdocx})</label>
                  <input
                    class="form-control"
                    type="file"
                    name="resume"
                    id="resume"
                    accept=".pdf, .jpg, .png, .docx"
                    {...register("resume",{
                      required: {
                        value: true,
                        message: "Please upload your resume",
                      },
                    })}
                    onChange={onChangeResume}
                  />
                  {errors.resume && (
                    <span class="errors">{errors.resume.message}</span>
                  )}
                </div>
                <div class="form-group">
                <HCaptcha
                        sitekey="a8400b17-0f2c-4116-85cb-91a16f434d49"
                        onVerify={setToken}
                        ref={captchaRef}
                        apihost="https://cn1.hcaptcha.com"
                        endpoint="https://cn1.hcaptcha.com"
                        assethost="https://assets-cn1.hcaptcha.com"
                        imghost="https://imgs-cn1.hcaptcha.com"
                        reportapi="https://reportapi-cn1.hcaptcha.com"
                      />
                      {errors.hcpactha && (
                          <span class="errors">{errors.message.hcpactha}</span>
                      )}
                  <div>
                   
                    <button
                      type="submit"
                      class="btn btn-default hovereffect btn btn-lg car-pop-but btn-block"
                      id="btnContactUs"
                      ref={buttonRef}
                    >
                      {SubmitButton}
                    </button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </section>

      <Services />
      <Packages />
      <GET_IN_TOUCH />
    </Layout>
  );
};

export default Career;
