const homelang =
{
    "en": {
        "TareWeightInquiry": "Tare Weight Inquiry",
        "TareWeightInquiry1": "Get empty container weight",
        "CONTAINERNO": "CONTAINER NO",
        "FindContainer": "Find Container",
        "ContainerID": "Container ID",
        "WeightinKg": "Weight in Kg",
        "Weightinlbs": "Weight in lbs",
        "Formoreinformationaboutthe": "For more information about the",
        "VGMSOLASregulationclickhere": "VGM-SOLAS regulation click here",
        "PDF": "PDF",
        "Print": "Print",
        "NoRecordFound": "No Record Found",
        
    
    },
    "cn": {
        "TareWeightInquiry": "空櫃净重查詢",
        "TareWeightInquiry1": "空櫃净重",
        "CONTAINERNO": "櫃號",
        "FindContainer": "查詢貨櫃",
        "ContainerID": "櫃號",
        "WeightinKg": "公斤",
        "Weightinlbs": "磅",
        "Formoreinformationaboutthe": "關於",
        "VGMSOLASregulationclickhere": "VGM-SOLAS 細則，按此",
        "PDF": "PDF",
        "Print": "列印",
        "NoRecordFound": "沒有記錄",
       
       
    },
    "zh": {
        "TareWeightInquiry": "空箱净重查询",
        "TareWeightInquiry1": "空箱净重",
        "CONTAINERNO": "箱号",
        "FindContainer": "查询集装箱",
        "ContainerID": "箱号",
        "WeightinKg": "公斤",
        "Weightinlbs": "磅",
        "Formoreinformationaboutthe": "关于",
        "VGMSOLASregulationclickhere": "VGM-SOLAS 细则，按此",
        "PDF": "PDF",
        "Print": "列印",
        "NoRecordFound": "没有记录",
       
       
    },
    "ko": {
        "TareWeightInquiry": "용기(컨테이너) 무게 문의",
        "TareWeightInquiry1": "공컨테이너 무게 확인",
        "CONTAINERNO": "컨테이너 번호",
        "FindContainer": "컨테이너 조회",
        "ContainerID": "컨테이너 ID",
        "WeightinKg": "무게(Kg)",
        "Weightinlbs": "무게(Lbs)",
        "Formoreinformationaboutthe": "자세한 내용을 보려면",
        "VGMSOLASregulationclickhere": "VGM-SOLAS 규정을 클릭하십시오.",
        "PDF": "PDF",
        "Print": "출력",
        "NoRecordFound": "조회된 record 없음",
       
       
    },
    "vi": {
        "TareWeightInquiry": "Yêu cầu về trọng lượng vỏ",
        "TareWeightInquiry1": "Truy xuất thông tin trọng lượng container rỗng",
        "CONTAINERNO": "Số container",
        "FindContainer": "Tìm container",
        "ContainerID": "Container ID",
        "WeightinKg": "Trọng tải theo đơn vị Kg.",
        "Weightinlbs": "Trọng tải theo đơn vị lbs.",
        "Formoreinformationaboutthe": "Để biết thêm thông tin về quy định VGM-SOLAS",
        "VGMSOLASregulationclickhere": "Nhấn vào đây",
        "PDF": "Tải xuống bản PDF",
        "Print": "In ấn",
        "NoRecordFound": "Không tìm thấy thông tin",

    },
    "th": {
        "TareWeightInquiry": "สอบถามน้ำหนักตู้สินค้าเปล่า",
        "TareWeightInquiry1": "รับข้อมูลน้ำหนักตู้สินค้าเปล่า",
        "CONTAINERNO": "หมายเลขตู้คอนเทนเนอร์",
        "FindContainer": "ต้นหา",
        "ContainerID": "หมายเลขตู้คอนเทนเนอร์",
        "WeightinKg": "น้ำหนัก (กิโลกรัม)",
        "Weightinlbs": "น้ำหนัก (ปอนด์)",
        "Formoreinformationaboutthe": "ข้อมูลเพิ่มเติมเกี่ยวกับ",
        "VGMSOLASregulationclickhere": "VGM-SOLAS regulation คลิกที่นี่",
        "PDF": "PDF",
        "Print": "พิมพ์",
        "NoRecordFound": "ไม่พบข้อมูล",

    },
    "in": {
        "TareWeightInquiry": "Pertanyaan Berat Tara",
        "TareWeightInquiry1": "Dapatkan berat kontainer kosong",
        "CONTAINERNO": "NO. KONTAINER",
        "FindContainer": "Temukan Kontainer",
        "ContainerID": "ID Kontainer",
        "WeightinKg": "Berat dalam Kg",
        "Weightinlbs": "Berat dalam lbs",
        "Formoreinformationaboutthe": "Informasi lebih lanjut tentang",
        "VGMSOLASregulationclickhere": "regulasi VGM-SOLAS klik di sini",
        "PDF": "PDF",
        "Print": "Cetak",
        "NoRecordFound": "Data tidak ditemukan",
        
    
    },
    "ja": {
        "TareWeightInquiry": "風袋重量照会",
        "TareWeightInquiry1": "空のコンテナの重量を確認します",
        "CONTAINERNO": "コンテナ番号",
        "FindContainer": "コンテナを検索します",
        "ContainerID": "コンテナID",
        "WeightinKg": "重量（kg）",
        "Weightinlbs": "重量（ポンド）",
        "Formoreinformationaboutthe": "こちらをクリック",
        "VGMSOLASregulationclickhere": "VGM-SOLAS規則の詳細については",
        "PDF": "PDF",
        "Print": "印刷",
        "NoRecordFound": "記録が見つかりません。",
        
    
    },
}
export default homelang;