import React, { Component } from "react";
import Layout from "../../layout";
import Moment from "moment";
import GET_IN_TOUCH from "../../get_in_touch/index";
import Services from "../../get_in_touch/services";
import Packages from "../../get_in_touch/packages_block";
import inside_heading from "../../../../src/assets/inside-heading.png";
import Inner_container from "../inner-container";
import Api from "../../../../package.json";
import * as axios from "axios";
import "react-tabs/style/react-tabs.css";
import { Accordion, Card, Button, ButtonGroup } from "react-bootstrap";
import banner1 from "../../../../src/assets/banner1.jpg";
import abt_icn from "../../../../src/assets/abt-icn.png";
import "../help/customer-advisory.css";
import { NavLink as Link } from "react-router-dom";

const qs = require("qs");
var jwt = require("jsonwebtoken");
export default class about_car_carrier extends Component {
    timeout = null;
    constructor(props) {
        super(props);
        this.state = {
            country_code: window.$country_code,
            customer_advisory_data: [],
        };
    }
    async componentDidMount() { }
    render() {
        return (
            <Layout>
                <Inner_container innerpage="about_car_carrier" />
                <section class="inner-center n-40 bl_terms_conditions">
                    <div class="container">
                        <div class="row m-0">
                            <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12 p-0">
                              
                                <p>Gold Star Line with its unique experience in shipping industry is also specialised in operating car carriers around the world, GSL is well positioned to serve the growing needs of the automotive industry anywhere. </p>
                                <p>As global vehicle exports reach new heights, Pure car carriers (PCC) and roll-on/roll-off vessel carriers have definitely proved to be an essential part of the shipping industry, offering the unique ability to transfer valuable, heavy cargo, and taking vehicle logistics to a whole new level. These impressive, giant vessels are specifically designed to perform one task — safely transporting vehicles, including four-wheeled cars, tractors, enormous excavators, and even agricultural machinery to their destinations.</p>
                                <p>With a growing number of destinations, we have equally increased the number of PCC and RORO vessels in the last couple of years, and always look to expand our service to more customers.</p>
                                <p>Our world-wide network car carrier services are accompanied by expert guidance, providing a dynamic, tailor-made, and personalized solution that meets any needs of our customers.</p>
                                <p>We pride ourselves on quality service. Especially when it comes to precious valuable cargo, Gold
                                    Star Line makes sure it is carefully and constantly accounted for by appointing dedicated supervision at the port to oversee the roll-on/roll-off operation, even wearing outfits free of buttons or zippers, and using special gloves to guarantee that all vehicles are kept safe and sound. This way, they are delivered in perfect condition at their destination.</p>
                            </div>
                        </div>

                    </div>
                </section>

                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >
        );
    }
}
