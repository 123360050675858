const homelang =
{
    "en": {
        "InlandTransport": "Inland Transport",
        "GSLstransportcapabilities": "GSL’s transport capabilities reach far beyond ocean lanes and ports. We also provide extensive land transport and door-to-door services, offering customers a comprehensive solution with one point of contact, so you can rely on our trusted quality standards and advanced monitoring methods to take care of the entire shipping process.",
        "Weworkhandinhand": "We work hand-in-hand with the best providers to offer excellent full-service intermodal transport. By road, rail or barge, anyone transporting GSL cargo has undergone a rigorous selection process and adheres to strict guidelines. As an independent carrier, GSLis able to keep a close eye on each stage of transport, offering optimal support and meticulous care every step of the way.",
        "Thisallinclusive": "This all-inclusive system offers you maximal convenience and impressive cost-efficiency. GSL arranges all elements of your cargo's journey, including transportation to and from harbors, dry ports, and container depots. Providing a range of short, middle, or long-distance haulage options, we make sure the entire trip offers all the high standards and personally-customized services you expect from GSL.",
       
       
    },
    "cn": {
        "InlandTransport": "内陸運輸",
        "GSLstransportcapabilities": "金星航運不只提供航運, 亦能提供内陸運輸. 我們給予客戶點對點運送服務, 更可利用先進的監控技術, 使運送過程一目了然. 讓客戶安心.",
        "Weworkhandinhand": "我們擁有緊密合作商業夥伴,不論在陸路, 鐵路或駁船, 都能提供最優質運輸服務. 我們亦有清晰指引給於合作夥伴, 確保貨物運送過程順利. 金星航運會嚴格監控運輸過程, 亦會在過程中給予高度支援.",
        "Thisallinclusive": "這全方位服務帶給了方便又符合經濟效益. 金星航運全面安排貨物在港口, 碼頭和貨櫃場的處理, 亦會提供多種長短途轉運選擇. 我們確保整個運送過程能令客戶滿意.",
       
        
    },
    "zh": {
        "InlandTransport": "内陆运输",
        "GSLstransportcapabilities": "金星航运不单提供航运, 亦能提供内陆运输.我们给予客户点对点运送服务, 更可利用先进的监控技术, 使运送过程一目了然, 让客户安心",
        "Weworkhandinhand": "我们拥有紧密合作商业伙伴,无论在陆路, 铁路或驳船, 都能提供最优质运输服务. 我们亦有清晰指引给予合作伙伴, 确保货物运送过程顺利. 金星航运会严格监控运输过程, 亦会在过程中给予高度支援",
        "Thisallinclusive": "这全方位服务带给了方便又合符经济效益.金星航运全面安排货物在港口, 码头和集装箱堆场的处理, 亦会提供多种长短途转运选择. 我们确保整个运送过程能令客户满意",
       
        
    },
    "ko": {
        "InlandTransport": "내륙 운송",
        "GSLstransportcapabilities": "GSL의 운송 능력은 해양 항로와 항구를 훨씬 뛰어넘습니다. 또한 광범위한 육상 운송 및 방문 서비스를 제공하여 고객이 신뢰할 수 있는 품질 표준과 고급 모니터링 방법을 사용하여 전체 배송 프로세스를 관리할 수 있도록 고객에게 포괄적인 솔루션을 제공합니다.",
        "Weworkhandinhand": "우리는 최고의 공급업체와 협력하여 우수한 전체 서비스 모드 간 운송을 제공합니다. 도로, 철도 또는 바지선을 통해 GSL 화물을 운송하는 모든 사람은 엄격한 선택 과정을 거쳤으며 엄격한 지침을 준수합니다. GSL은 독립 운송 업체로서 각 운송 단계를 면밀히 주시할 수 있으며, 모든 단계에서 최적의 지원과 세심한 관리를 제공합니다.",
        "Thisallinclusive": "이 일체형 시스템은 최대의 편의성과 뛰어난 비용 효율성을 제공합니다. GSL은 항만, 건조 항구 및 컨테이너 창고로 오가는 운송을 포함하여 화물 여행의 모든 요소를 준비합니다. 단거리, 중거리 또는 장거리 운송 옵션을 제공하며 GSL에 기대하는 모든 높은 기준과 개인 맞춤형 서비스를 제공하도록 보장합니다.",
       
        
    },
    "vi": {
        "InlandTransport": "Vận chuyển nội địa",
        "GSLstransportcapabilities": "Khả năng vận chuyển của GSL vượt xa các tuyến đường biển và cảng. Chúng tôi cũng cung cấp dịch vụ vận chuyển đường bộ và vận chuyển tận cửa kho rộng rãi, mang đến cho khách hàng giải pháp toàn diện với một đầu mối liên hệ, vì vậy bạn có thể dựa vào các tiêu chuẩn chất lượng đáng tin cậy và phương pháp giám sát tiên tiến của chúng tôi để thực hiện toàn bộ quá trình vận chuyển.",
        "Weworkhandinhand": "Chúng tôi hợp tác với những nhà cung cấp tốt nhất để mang đến dịch vụ vận tải đa phương thức đầy đủ với dịch vụ tuyệt vời. Bằng đường bộ, đường sắt hoặc sà lan, bất kỳ ai vận chuyển hàng GSL đều phải trải qua quá trình lựa chọn khắt khe và tuân thủ các nguyên tắc nghiêm ngặt. Là một nhà vận chuyển độc lập, GSL có thể theo dõi sát sao từng giai đoạn vận chuyển, hỗ trợ tối ưu và chăm sóc tỉ mỉ từng bước trên đường đi.",
        "Thisallinclusive": "Hệ thống trọn gói này mang đến cho bạn sự tiện lợi tối đa và hiệu quả chi phí ấn tượng. GSL sắp xếp tất cả các yếu tố trong hành trình vận chuyển hàng hóa của bạn, bao gồm vận chuyển đến và đi từ các bến cảng, cảng cạn và kho chứa container. Cung cấp một loạt các tùy chọn quãng đường ngắn, trung bình hoặc đường dài, chúng tôi đảm bảo toàn bộ chuyến đi cung cấp tất cả các dịch vụ tiêu chuẩn cao và tùy chỉnh cá nhân mà bạn mong đợi từ GSL.",
       
        
    },
    "th": {
        "InlandTransport": "การขนส่งทางบก",
        "GSLstransportcapabilities": "ความสามารถในการขนส่งของ GSL ทำได้มากกว่าช่องทางเดินเรือ เรายังให้บริการขนส่งทางบกและบริการแบบ door-to-door ที่กว้างขวาง นำเสนอโซลูชันที่ครอบคลุมแก่ลูกค้า คุณจึงวางใจในมาตรฐานคุณภาพและวิธีการตรวจสอบขั้นสูงเพื่อดูแลกระบวนการจัดส่งทั้งหมด",
        "Weworkhandinhand": "เราทำงานร่วมกันกับผู้ให้บริการที่ดีที่สุดเพื่อนำเสนอการขนส่งเต็มรูปแบบที่ยอดเยี่ยม โดยทางถนน ทางรถไฟ หรือทางเรือผู้ให้บริการผ่านกระบวนการคัดเลือกและปฏิบัติตามแนวทางอย่างเข้มงวด GSLสามารถตรวจสอบการขนส่งได้อย่างใกล้ชิด พร้อมทั้งให้การสนับสนุนอย่างเหมาะสมและดูแลอย่างพิถีพิถันในทุกขั้นตอน",
        "Thisallinclusive": "ระบบที่รวมทุกอย่างนี้มอบความสะดวกสบายสูงสุดและคุ้มค่าคุ้มราคา GSL จัดการองค์ประกอบทั้งหมดในการขนส่งงของสินค้าของคุณ รวมถึงการขนส่งไปและกลับจากท่าเรือ และคลังสินค้า ด้วยตัวเลือกการขนส่งระยะสั้น ระยะกลาง หรือระยะไกล เรามั่นใจว่าการขนส่งทั้งมีมาตรฐานระดับสูงและบริการที่ปรับแต่งเฉพาะบุคคลที่คุณคาดหวังจาก GSL",
 
    },
    "in": {
        "InlandTransport": "Transportasi Darat",
        "GSLstransportcapabilities": "Kemampuan transportasi GSL menjangkau jauh melampaui jalur laut dan pelabuhan. Kami juga menyediakan transportasi darat yang ekstensif dan layanan door-to-door, menawarkan solusi komprehensif kepada pelanggan dengan satu titik kontak, sehingga Anda dapat mengandalkan standar kualitas tepercaya dan metode pemantauan canggih kami untuk menangani seluruh proses pengiriman.",
        "Weworkhandinhand": "Kami bekerja bahu-membahu dengan penyedia terbaik untuk menawarkan transportasi antar moda layanan lengkap yang sangat baik. Melalui jalan darat, kereta api, atau tongkang, siapa pun yang mengangkut kargo GSL telah menjalani proses seleksi yang ketat dan mengikuti pedoman yang ketat. Sebagai operator independen, GSL dapat mengawasi setiap tahap pengangkutan, menawarkan dukungan optimal dan penanganan yang cermat di setiap langkah.",
        "Thisallinclusive": "Sistem all-inclusive ini menawarkan kenyamanan maksimal dan efisiensi biaya yang mengesankan. GSL mengatur semua elemen perjalanan kargo Anda, termasuk transportasi ke dan dari pelabuhan, pelabuhan kering, dan depo peti kemas. Menyediakan berbagai opsi pengangkutan jarak pendek, menengah, atau jarak jauh, kami memastikan seluruh perjalanan menawarkan semua standar tinggi dan layanan yang disesuaikan secara pribadi yang Anda harapkan dari GSL.",
       
       
    },
    "ja": {
        "InlandTransport": "国内輸送",
        "GSLstransportcapabilities": "GSLの輸送能力は、海上レーンや港湾をはるかに超えています。陸上輸送やドア・ツー・ドアのサービスも充実させ、お客様に窓口一本で包括的なソリューションを提供しているので、信頼のおける品質基準と高度なモニタリング手法によって、輸送の全プロセスをお任せいただくことができます。 ",
        "Weworkhandinhand": "私たちは、最高のプロバイダーと共に、優れた全行程の複合一貫輸送を提供しています。陸上、鉄道、船舶のいずれでも、GSLの貨物を輸送する業者は厳格な選考プロセスを経ており、厳しいガイドラインを厳守しています。独立系事業者として、GSLは輸送の各段階に目を配り、最適なサポートときめ細かなケアを提供します。",
        "Thisallinclusive": "この包括的なシステムは、お客様に最大限の利便性と優れた費用対効果を提供します。港湾、海上港、ターミナルまでの輸送を含む、貨物の輸送に関わるすべての要素を手配します。GSLは短距離、中距離、長距離の輸送オプションをご用意し、お客様の個別のご希望に沿ったサービスを提供します。",
       
       
    },
}
export default homelang;