const homelang =
{
    "en": {
        "Newsletter": "Newsletter",
        "ReadMore": "Read More",
      
     
    },
    "cn": {
        "Newsletter": "最新消息",
        "ReadMore": "閲讀更多",
    },
    "zh": {
        "Newsletter": "最新消息",
        "ReadMore": "阅读更多",
    },
    "ko": {
        "Newsletter": "소식지",
        "ReadMore": "더 알아보기",
    },
    "vi": {
        "Newsletter": "Bản tin",
        "ReadMore": "Đọc thêm",
    },
    "th": {
        "Newsletter": "ข่าวสารและความเคลื่อนไหว",
        "ReadMore": "เพิ่มเติม",
    },
    "in": {
        "Newsletter": "Buletin",
        "ReadMore": "Baca selengkapnya",
    },
    "ja": {
        "Newsletter": "ニュースレター",
        "ReadMore": "詳細",
    },
}
export default homelang;