import React, { Component, useEffect, useState } from "react";
import AboutGsl from '../../assets/inner-images/about/about_gsl.jpg';
import companyProfile from '../../assets/inner-images/about/company_profile.jpg';
import companyHistory from '../../assets/inner-images/about/company_history.jpg';
import factsFigures from '../../assets/inner-images/about/facts_figures.jpg';
import cargoServices from '../../assets/inner-images/cargo_services/cargo_services.jpg';
import dangerousCargo from '../../assets/inner-images/cargo_services/dangerous_cargo.jpg';
import dryCargo from '../../assets/inner-images/cargo_services/dry_cargo.jpg';
import gslMonitor from '../../assets/inner-images/cargo_services/gsl_monitor.jpg';
import inlandTransport from '../../assets/inner-images/cargo_services/inland_transport.jpg';
import projectCargo from '../../assets/inner-images/cargo_services/project_cargo.jpg';
import reeferCargo from '../../assets/inner-images/cargo_services/reefer_cargo.jpg';
import temperatureControlledShipping from '../../assets/inner-images/cargo_services/temperature_controlled_shipping.jpg';
import highValueOrSensitiveCargo from '../../assets/inner-images/cargo_services/high_value_or_sensitive_cargo.jpg';
import Catalogue from '../../assets/inner-images/tools/catalogue.jpg';
import eblService from '../../assets/inner-images/tools/ebl_service.jpg';
import requestaQuote from '../../assets/inner-images/tools/request_a_quote.jpg';
import siSubmission from '../../assets/inner-images/tools/si_submission.jpg';
import bookingSubmission from '../../assets/inner-images/tools/booking_submission.jpg';
import vgmMassUpload from '../../assets/inner-images/tools/vgm_mass_upload.jpg';
import careers from '../../assets/inner-images/careers/careers.jpg';
import demurrage_and_detention_tariff from '../../assets/inner-images/local_info/demurrage_and_detention_tariff.jpg';
import local_info from '../../assets/inner-images/local_info/local_info.jpg';
import help_customer_advisory from '../../assets/inner-images/help/help_customer_advisory.jpg';
import newsletter from '../../assets/inner-images/newsletter/newsletter.jpg';
import terms_of_use from '../../assets/inner-images/help/terms_of_use.jpg';
import enquiry_us from '../../assets/inner-images/enquiry/enquiry_us.jpg';
import faq_gsl from '../../assets/inner-images/enquiry/faq_gsl.jpg';
import catalogues from '../../assets/inner-images/catalogue/catalogue.jpg';
import roro from '../../assets/inner-images/roro/roro.jpg';
import subscribe from '../../assets/inner-images/about/subscribe.jpg';
import shapeBlue from '../../../src/assets/banner-shape-blue.svg';
import shapeYellow from '../../../src/assets/banner-shape.svg';

const innerJSON =
{
    "subscribe": {
        "title": "구독",
        "discription": "선적 화물에 대한 실시간 알림을 받고 화물 상태를 완벽하게 파악할 수 있습니다.",
        "url": subscribe,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
        
    },
    "about_gsl": {
        "title": "화물 목록",
        "discription": "화물의 사양과 필요에 따라 선적을 계획하십시오.",
        "url": AboutGsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',

    },
    "cargo_catalogue": {
        "title": "화물 목록",
        "discription": "화물의 사양과 필요에 따라 선적을 계획하십시오.",
        "url": catalogues,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
        
    },
    "request_a_quote": {
        "title": "견적 요청",
        "discription": "우리의 사명과 목표는 귀하의 비즈니스를 사랑할 수 있는 다양한 화물 서비스를 제공하는 것입니다.",
        "url": requestaQuote,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_submission": {
        "title": "예약 제출",
        "discription": "우리의 사명과 목표는 귀하의 비즈니스를 사랑할 수 있는 다양한 화물 서비스를 제공하는 것입니다.",
        "url": bookingSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ebl_service": {
        "title": "eBL 서비스",
        "discription": "우리의 사명과 목표는 귀하의 비즈니스를 사랑할 수 있는 다양한 화물 서비스를 제공하는 것입니다.",
        "url": eblService,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "si_submission": {
        "title": "전작 지시서(요청서) 제출",
        "discription": "화물 세부 정보, 관련 당사자 및 요구 사항의 원활한 제출",
        "url": siSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ecommerce_partners": {
        "title": "eCommerce 파트너",
        "discription": "우리의 사명과 목표는 귀하의 비즈니스를 사랑할 수 있는 다양한 화물 서비스를 제공하는 것입니다.",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "vgm_mass_upload": {
        "title": "VGM 다량 제출",
        "discription": "여러 선적물에 대한 VGM 제출이 쉽고 간편해졌습니다.",
        "url": vgmMassUpload,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "high_value_or_sensitive_cargo": {
        "title": "고부가 혹은 민감한 화물",
        "discription": "GSL Monitor는 귀중한 화물을 추적, 모니터링 및 제어할 수 있는 정확한 도구를 제공하여 안전하게 배송되도록 합니다.",
        "url": highValueOrSensitiveCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "enquiry": {
        "title": "문의하기",
        "discription": "우리에게 연락하세요! 필요한 모든 정보를 기꺼이 제공해 드리겠습니다.",
        "url": enquiry_us,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "faq": {
        "title": "자주 묻는 질문",
        "discription": "Gold Star Line 및 당사 서비스에 대해 자세히 알아보기",
        "url": faq_gsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "roro-private": {
        "title": "자동차 선적 서비스",
        "discription": "전 세계적으로 증가하는 자동차 출하 수요 충족",
        "url": roro,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "newsletter": {
        "title": "뉴스 및 업데이트",
        "discription": "최신 정보 및 공지사항을 계속 확인하세요!",
        "url": newsletter,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_newsletter": {
        "title": "뉴스 소식지 보기",
        "discription": "우리의 사명과 목표는 귀하의 비즈니스를 사랑할 수 있는 다양한 화물 서비스를 제공하는 것입니다.",
        "url": newsletter,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "cargo_services": {
        "title": "화물 서비스",
        "discription": "우리의 임무는 모든 유형의 화물에 대해 완전한 범위의 해상 화물 서비스를 제공하는 것입니다.Our mission is to offer a complete range of ocean-freight services for all types of cargo.",
        "url": cargoServices,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "gsl_monitor": {
        "title": "GSL Monitor",
        "discription": "특히 민감한 고가 화물 또는 냉동 화물을 운송할 때 화물 환경을 추적, 모니터링 및 제어합니다.",
        "url": gslMonitor,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dry_cargo": {
        "title": "일반 화물",
        "discription": "60년 이상의 경험을 바탕으로 모든 유형의 건화물 취급에 전념하여 고객의 요구를 충족시켜 드립니다.",
        "url": dryCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "reefer_cargo": {
        "title": "냉동/냉장 화물",
        "discription": "당사의 첨단 기술은 귀하의 냉동 화물이 적시에 적절한 온도로 안전하게 배송되도록 보장합니다.",
        "url": reeferCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "project_cargo": {
        "title": "프로젝트 화물",
        "discription": "특수 화물을 전 세계로 안전하게 운송하는 데 필요한 창의적인 솔루션과 전문 지식을 보유하고 있습니다.",
        "url": projectCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "inland_transport": {
        "title": "내륙 운송",
        "discription": "광범위한 Door-to-Door 네트워크를 통해 안정적인 육상 운송 서비스 제공",
        "url": inlandTransport,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "temperature_controlled_shipping": {
        "title": "온도 조절 선적",
        "discription": "냉장 또는 냉동, 온도에 민감한 모든 제품을 안전하게 운송합니다.",
        "url": temperatureControlledShipping,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dangerous_cargo": {
        "title": "위험 화물",
        "discription": "위험물의 안전한 보관 및 운송을 위한 전문적이고 헌신적인 서비스를 제공합니다.",
        "url": dangerousCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "about_gsl": {
        "title": "우리는",
        "discription": "Gold Star Line - 민첩한 접근 방식으로 역동적인 시장에 서비스를 제공하는 인트라 아시아, 오세아니아 및 아프리카 무역의 주요 업체",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_profile": {
        "title": "회사 프로필",
        "discription": "우리의 사명은 고객의 요구 사항을 지원하기 위한 창의적인 솔루션을 지속적으로 찾는 것입니다.",
        "url": companyProfile,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_history": {
        "title": "우리 이야기",
        "discription": "시간 여행을 떠나 GoldStar Line의 역사와 이정표, 발전을 만나보세요.",
        "url": companyHistory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "facts_figures": {
        "title": "상세 정보",
        "discription": "우리의 사명은 고객의 요구 사항을 지원하기 위한 창의적인 솔루션을 지속적으로 찾는 것입니다.",
        "url": factsFigures,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "demurrage_and_detention_tariff": {
        "title": "체화료 및 지체료 요금표",
        "discription": "체화료 및 지체료에 대해 의구심을 가질 필요가 없습니다",
        "url": demurrage_and_detention_tariff,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "local_info": {
        "title": "지역 정보",
        "discription": "고객의 위치에 맞는 유용한 정보를 항상 최신 상태로 유지합니다.",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "unsubscribe_schedule": {
        "title": "스케쥴 구독 취소",
        "discription": "",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "contact_us": {
        "title": "GSL 사무실 정보",
        "discription": "여러 사무실 정보, 주소 및 연락처",
        "url": enquiry_us,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "careers": {
        "title": "운송사",
        "discription": "GoldStar와 함께하고 성장하세요",
        "url": careers,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "solas_regulation": {
        "title": "SOLAS 규칙",
        "discription": "우리의 사명과 목표는 귀하의 비즈니스를 사랑할 수 있는 다양한 화물 서비스를 제공하는 것입니다.",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "customer_advisory": {
        "title": "화주 안내문",
        "discription": "알림과 통보에 대해 계속 구독해 주세요",
        "url": help_customer_advisory,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "accessibility": {
        "title": "접근성",
        "discription": "접근성 모델로 삶의질 향상",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "data_protection_policy": {
        "title": "정보 보호 정책",
        "discription": "개인정보 보호 정책에 대한 정보 확인하기",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_cookies_policy": {
        "title": "개인 정보 처리 방침",
        "discription": "귀하의 개인 데이터 및 데이터 프라이버시에 대한 당사의 약속",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "quotation_terms_conditions": {
        "title": "견적 조건",
        "discription": "견적서 이용 약관을 준수해 주세요",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "terms_of_use": {
        "title": "사용 조건",
        "discription": "서비스 안내 및 조건",
        "url": terms_of_use,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_customer_advisory": {
        "title": "고객 안내",
        "discription": "당사 고객들을 위한 안내문",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "bl_terms_conditions": {
        "title": "B/L 조건",
        "discription": "당사B/L의 접적 조건에 대한 알림 받기.",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_go_terms_conditions": {
        "title": "GSL Go 조건",
        "discription": "GSL Go 의 법적 조건에 대한 알림 받기",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "about_car_carrier": {
        "title": "자동차 선적에 대해",
        "discription": "",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "egsl_terms_conditions": {
        "title": "eGSL 조건",
        "discription": "당사B/L의 접적 조건에 대한 알림 받기",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_clause": {
        "title": "예약 조항",
        "discription": "당사 예약 확인서 문서와 관련된 조항에 대해 알기",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_company_news": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_regulation": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_industry": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },"sea_waybill_terms_conditions": {
        "title": " SEA WAYBILL TERMS AND CONDITIONS",
        "discription": "TERMS AND CONDITIONS",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
}


export default innerJSON;