import React, { Suspense, lazy, useEffect, useState } from "react";
import WOW from "wowjs";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { NavLink as Link } from "react-router-dom";
import "./App.css";
import "./bootstrap.css";
import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import './components/en/home/home.css';
import "leaflet/dist/leaflet.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Cargo_services from "./components/innerpages/cargo-services/cargo-services";
import Gsl_monitor from "./components/innerpages/cargo-services/gsl-monitor";
import Dry_cargo from "./components/innerpages/cargo-services/dry-cargo";
import Reefer_cargo from "./components/innerpages/cargo-services/reefer-cargo";
import Project_cargo from "./components/innerpages/cargo-services/project-cargo";
import Inland_transport from "./components/innerpages/cargo-services/inland-transport";
import temperature_controlled_shipping from "./components/innerpages/cargo-services/temperature-controlled-shipping";
import Dangerous_cargo from "./components/innerpages/cargo-services/dangerous-cargo";
import High_value_or_sensitive_cargo from "./components/innerpages/cargo-services/high-value-or-sensitive-cargo";
import About_gsl from "./components/innerpages/about_gsl/about_gsl";
import Company_profile from "./components/innerpages/about_gsl/company-profile";
import Company_history from "./components/innerpages/about_gsl/company-history";
import Facts_figures from "./components/innerpages/about_gsl/facts-figures";
import Demurrage_and_detention_tariff from "./components/innerpages/localinfo/demurrage-and-detention-tariff";
import Local_info from "./components/innerpages/localinfo/local-info";
import Newsletter from "./components/innerpages/newsletter";
import View_newsletter from "./components/innerpages/newsletter/view_newsletter";
import Point_to_point from "./components/innerpages/schedule/point-to-point";
import Schedule_by_line from "./components/innerpages/schedule/schedule_by_line";
import Schedule_by_line_new from "./components/innerpages/schedule/schedule_by_line_new261124";
import Schedule_by_line_full_view from "./components/innerpages/schedule/schedule_by_line_full_view";
import Subscribe from "./components/innerpages/schedule/subscribe";
import Tariff_calculator from "./components/innerpages/tools/tariff_calculator";
import Tariff_calculator_new from "./components/innerpages/tools/tariff_calculator_new";
import Cargo_catalogue from "./components/innerpages/tools/cargo_catalogue";
import Tare_weight_inquiry from "./components/innerpages/tools/tare_weight_inquiry";
import Ebl_service from "./components/innerpages/tools/ebl_service";
import Track_shipment from "./components/innerpages/tools/track_shipment";
import Track_shipments from "./components/innerpages/tools/track_shipments.js";
import Shipment_unsubscribe from "./components/innerpages/tools/shipment_unsubscribe.js"
import Sob_india from "./components/innerpages/tools/sob-india";
import Request_a_quote from "./components/innerpages/tools/request_a_quote";
import Get_ip from "./components/innerpages/tools/get_ip";
import Booking_submission from "./components/innerpages/tools/booking-submission";
import Si_submission from "./components/innerpages/tools/si-submission";
import Vgm_mass_upload from "./components/innerpages/tools/vgm_mass_upload";
import Contact_us from "./components/innerpages/contact/contact_us";
import Career from "./components/innerpages/career/career";
import Quotation_terms_conditions from "./components/innerpages/help/quotation-terms-conditions";
import GSLGo_terms_and_conditions from "./components/innerpages/help/gsl-go-terms-and-condition";
import EGSL_terms_and_conditions from "./components/innerpages/help/egsl-terms-and-condition";
import Booking_clause from "./components/innerpages/help/booking-clause";
import Bl_terms_and_conditions from "./components/innerpages/help/bl-terms-and-conditions";
import Terms_of_use from "./components/innerpages/help/terms-of-use";
import Customer_advisory from "./components/innerpages/help/customer-advisory";
import View_Customer_advisory from "./components/innerpages/help/view_customer-advisory";
import SolasRegulation from "./components/innerpages/help/solas-regulation";
import Seawaybill_terms_and_conditions from "./components/innerpages/help/sea_waybill_terms_conditions";
import Enquiry from "./components/innerpages/contact/enquiry";
import Faq from "./components/innerpages/contact/faq";
import Submit_vgm from "./components/innerpages/tools/vgm_form";
import Accessibility from "./components/innerpages/policy/accessibility";
import DataProtectpolicy from "./components/innerpages/policy/data-protection-policy";
import GslCookiesAndTrackingPolicy from "./components/innerpages/policy/gsl-cookies-and-tracking-policy";
import Solas_regulation from "./components/innerpages/policy/solas-regulation";
import Ecommerce_partners from "./components/innerpages/cargo-services/ecommerce-partners";
import View_company_new from "./components/innerpages/subpages/company-new";
import View_regulation from "./components/innerpages/subpages/view_regulation";
import View_industry from "./components/innerpages/subpages/view_industry";
import View_our_mission from "./components/innerpages/about_gsl/view_our_mission";
import Who_we_are from "./components/innerpages/about_gsl/who_we_are";
import Api from "../package.json";
import * as axios from "axios";
import Loader from "./components/loader/Loader.js";
import Roro from "./components/innerpages/tools/roro";
import RoroPrivate from "./components/innerpages/tools/roro-private";
import UnsubscribeNewsletter from "./components/innerpages/localinfo/unsubscribe-newsletter";
import about_car_carrier from "./components/innerpages/help/about-car-carrier";
import QrCode from "./components/innerpages/qrcode/index";
import cmsPost from "./components/innerpages/cms/cms_post";
import pointToPointNew from "./components/innerpages/schedule/point-to-point-old";
import Ebl_service_new from "./components/innerpages/tools/ebl_service_new";
const qs = require("qs");
function App() {
  new WOW.WOW().init();
  var accessToken = window.localStorage.getItem("access_token");
  const [accessStatus, setAccessStatus] = useState(false);
  const [Home, setHome] = useState('');
  const [Gsl_go, setGslgo] = useState('');

  useEffect(() => {
    var lang = window.localStorage.getItem("lang");
    if (window.localStorage.getItem("lang") == null) {
      lang = 'en';
      window.localStorage.setItem("lang", 'en');
    }
    setHome(React.lazy(() => import('./components/' + lang + '/home')));
    setGslgo(React.lazy(() => import('./components/' + lang + '/innerpages/tools/gsl_go')));


    const getLoadData = async () => {
      const headers = {
        type: "application/json",
        Authorization: `Bearer ` + window.localStorage.getItem("access_token"),
      }; 
      /*const headers = {
        "Content-Type": "multipart/form-data",
        type: "application/json",
      };*/
      if (window.localStorage.getItem("access_token") == null) {
        const res1 = await axios.post(
          Api.userdata.url + "/access_token",
          qs.stringify(
            { country_code: window.localStorage.getItem("country_code") },
            { parseArrays: false }
          ),
          { headers: headers }
        );
        const json = await res1.data;
        console.log(json);
        window.localStorage.setItem("access_token", json["token"]);
      } else if (window.localStorage.getItem("access_token") != null) {
        const res1 = await axios.post(
          Api.userdata.url + "/access_token",
          qs.stringify(
            { country_code: window.localStorage.getItem("country_code") },
            { parseArrays: false }
          ),
          { headers: headers }
        );
        const json = await res1.data;
        console.log(json);
        if (json["token"] != '') {
          window.localStorage.setItem("access_token", json["token"]);
        }
      }
      setAccessStatus(true);
    };
    getLoadData();
  }, [accessToken]);
  if (accessStatus) {
    return (
      <Router>
        <Suspense fallback={<>
          <div class="loading-text">Please wait...</div></>}>
          <Switch>
            <Route key='HK' exact path="/" render={() => <Home regional="HK" roroView="No"/>} />
            <Route key='IN' exact path="/india" render={() => <Home regional="IN" roroView="No"/>} />
            <Route key='JP' exact path="/japan" render={() => <Home regional="JP" roroView="No"/>} />
            <Route key='SG' exact path="/singapore" render={() => <Home regional="SG" roroView="No"/>} />
            <Route key='MY' exact path="/malaysia" render={() => <Home regional="MY" roroView="No"/>} />
            <Route key='KR' exact path="/south_korea" render={() => <Home regional="KR" roroView="No"/>} />
            <Route key='CNS' exact path="/south_china" render={() => <Home regional="CNS" roroView="No"/>} />
            <Route key='TH' exact path="/thailand" render={() => <Home regional="TH" roroView="No"/>} />
            <Route key='TW' exact path="/taiwan" render={() => <Home regional="TW" roroView="No"/>} />
            <Route key='VN' exact path="/vietnam" render={() => <Home regional="VN" roroView="No"/>} />
            <Route key='NG' exact path="/nigeria" render={() => <Home regional="NG" roroView="No"/>} />
            <Route key='CNN' exact path="/north_china" render={() => <Home regional="CNN" roroView="No"/>} />
            <Route key='ID' exact path="/Indonesia/" render={() => <Home regional="ID" roroView="No"/>} />
            <Route key='BD' exact path="/Bangladesh/" render={() => <Home regional="BD" roroView="No"/>} />
            <Route key='AU' exact path="/australia/" render={() => <Home regional="AU" roroView="No"/>} />
            <Route key='NZ' exact path="/new_zealand/" render={() => <Home regional="NZ" roroView="No"/>} />
            <Route key='HK' exact path="/global/:id" render={() => <Home regional="HK" roroView="Yes"/>} />
            <Route key='IN' exact path="/india/:id" render={() => <Home regional="IN" roroView="Yes"/>} />
            <Route key='JP' exact path="/japan/:id" render={() => <Home regional="JP" roroView="Yes"/>} />
            <Route key='SG' exact path="/singapore/:id" render={() => <Home regional="SG" roroView="Yes" />} />
            <Route key='MY' exact path="/malaysia/:id" render={() => <Home regional="MY" roroView="Yes"/>} />
            <Route key='KR' exact path="/south_korea/:id" render={() => <Home regional="KR" roroView="Yes"/>} />
            <Route key='CNS' exact path="/south_china/:id" render={() => <Home regional="CNS" roroView="Yes"/>} />
            <Route key='TH' exact path="/thailand/:id" render={() => <Home regional="TH" roroView="Yes"/>} />
            <Route key='TW' exact path="/taiwan/:id" render={() => <Home regional="TW" roroView="Yes"/>} />
            <Route key='VN' exact path="/vietnam/:id" render={() => <Home regional="VN" roroView="Yes"/>} />
            <Route key='NG' exact path="/nigeria/:id" render={() => <Home regional="NG" roroView="Yes"/>} />
            <Route key='CNN' exact path="/north_china/:id" render={() => <Home regional="CNN" roroView="Yes"/>} />
            <Route key='ID' exact path="/Indonesia/:id" render={() => <Home regional="ID" roroView="Yes"/>} />
            <Route key='BD' exact path="/Bangladesh/:id" render={() => <Home regional="BD" roroView="Yes"/>} />
            <Route key='AU' exact path="/australia/:id" render={() => <Home regional="AU" roroView="Yes"/>} />
            <Route key='NZ' exact path="/new_zealand/:id" render={() => <Home regional="NZ" roroView="Yes"/>} />
            <Route exact path="/cargo_services" component={Cargo_services} />
            <Route exact path="/gsl_monitor" component={Gsl_monitor} />
            <Route exact path="/dry_cargo" component={Dry_cargo} />
            <Route exact path="/reefer_cargo" component={Reefer_cargo} />
            <Route exact path="/project_cargo" component={Project_cargo} />
            <Route
              exact
              path="/inland_transport"
              component={Inland_transport}
            />
            <Route
              exact
              path="/temperature_controlled_shipping"
              component={temperature_controlled_shipping}
            />
            <Route exact path="/dangerous_cargo" component={Dangerous_cargo} />
            <Route
              exact
              path="/high_value_or_sensitive_cargo"
              component={High_value_or_sensitive_cargo}
            />
            <Route exact path="/about_gsl" component={About_gsl} />
            <Route exact path="/company_profile" component={Company_profile} />
            <Route exact path="/company_history" component={Company_history} />
            <Route exact path="/facts_figures" component={Facts_figures} />
            <Route
              exact
              path="/demurrage_and_detention_tariff"
              component={Demurrage_and_detention_tariff}
            />
            <Route exact path="/local_info" component={Local_info} />
            <Route exact path="/newsletter" component={Newsletter} />
            <Route exact path="/view_newsletter" component={Newsletter} />
            <Route path="/view_newsletter/:id" component={View_newsletter} />
            <Route exact path="/point_to_point" component={Point_to_point} />
            <Route exact path="/point_to_point_new" component={pointToPointNew} />
            <Route
              exact
              path="/schedule_by_line"
              component={Schedule_by_line}
            />
            <Route
              exact
              path="/Schedule_by_line_new"
              component={Schedule_by_line_new}
            />
            <Route
              exact
              path="/schedule_by_line/:trades/:linecode"
              component={Schedule_by_line}
            />
            <Route
              exact
              path="/schedule_by_line_full_view/:trades/:linecode"
              component={Schedule_by_line_full_view}
            />
            <Route exact path="/subscribe" component={Subscribe} />
            <Route
              exact
              path="/tariff_calculator"
              component={Tariff_calculator}
            />
            <Route
              exact
              path="/Tariff_calculator_new"
              component={Tariff_calculator_new}
            />
            <Route exact path="/cargo_catalogue" component={Cargo_catalogue} />
            <Route
              exact
              path="/tare_weight_inquiry"
              component={Tare_weight_inquiry}
            />
            <Route exact path="/ebl_service" component={Ebl_service} />
            <Route exact path="/track_shipment" component={Track_shipment} />
            <Route exact path="/track_shipment_new" component={Track_shipments} />
            <Route exact path="/shipment_unsubscribe" component={Shipment_unsubscribe} />
            <Route
              exact
              path="/track_shipment/:containerid"
              component={Track_shipment}
            />
            <Route exact path="/sob_india" component={Sob_india} />
            <Route exact path="/request_a_quote" component={Request_a_quote} />
            <Route exact path="/si_submission" component={Si_submission} />
            <Route exact path="/vgm_mass_upload" component={Vgm_mass_upload} />
            <Route
              exact
              path="/booking_submission"
              component={Booking_submission}
            />
            <Route exact path="/contact_us/:any" component={Contact_us} />
            <Route exact path="/contact_us" component={Contact_us} />
            <Route
              exact
              path="/quotation_terms_conditions"
              component={Quotation_terms_conditions}
            />
            <Route
              exact
              path="/gsl-go-terms-and-condition"
              component={GSLGo_terms_and_conditions}
            />
             <Route
              exact
              path="/bl"
              component={Bl_terms_and_conditions}
            />
              <Route
              exact
              path="/bl_terms_and_conditions"
              component={Bl_terms_and_conditions}
            />
            <Route
              exact
              path="/wbl"
              component={Seawaybill_terms_and_conditions}
            />
            <Route
              exact
              path="/sea-way-bill-terms-and-condition"
              component={Seawaybill_terms_and_conditions}
            />
            <Route
              exact
              path="/egsl-terms-and-condition"
              component={EGSL_terms_and_conditions}
            />
            <Route exact path="/booking_clause" component={Booking_clause} />
            <Route
              exact
              path="/bl"
              component={Bl_terms_and_conditions}
            />
            <Route exact path="/terms_of_use" component={Terms_of_use} />
            <Route
              exact
              path="/customer_advisory"
              component={Customer_advisory}
            />
            <Route
              exact
              path="/view_customer_advisory/:id"
              component={View_Customer_advisory}
            />
            <Route exact path="/solas_regulation" component={SolasRegulation} />
            <Route exact path="/career" component={Career} />
            <Route exact path="/enquiry" component={Enquiry} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/get_ip/:id" component={Get_ip} />
            <Route exact path="/submit_vgm" component={Submit_vgm} />
            <Route exact path="/roro" component={Roro} />
            <Route exact path="/roro-private" component={RoroPrivate} />
            <Route exact path="/accessibility" component={Accessibility} />
            <Route
              exact
              path="/data-protect-policy"
              component={DataProtectpolicy}
            />
            <Route
              exact
              path="/gsl-cookies-and-tracking-policy"
              component={GslCookiesAndTrackingPolicy}
            />
            <Route
              exact
              path="/solas-regulation"
              component={Solas_regulation}
            />
            <Route
              exact
              path="/ecommerce-partners"
              component={Ecommerce_partners}
            />
            <Route
              exact
              path="/view-company-new/:id"
              component={View_company_new}
            />
            <Route
              exact
              path="/view-regulation/:id"
              component={View_regulation}
            />
            <Route exact path="/view-industry/:id" component={View_industry} />
            <Route
              exact
              path="/view-our-mission"
              component={View_our_mission}
            />
            <Route exact path="/who-we-are" component={Who_we_are} />
            <Route exact path="/unsubscribe-newsletter" component={UnsubscribeNewsletter} />
            <Route exact path="/landing_gslgo" component={Gsl_go} />
            <Route exact path="/about_car_carrier" component={about_car_carrier} />
            <Route exact path="/social/wechat-qr" component={QrCode} />
            <Route exact path="/events/:id" component={cmsPost} />
            
            <Route exact path="/ebl_service_new" component={Ebl_service_new} />
          </Switch>
        </Suspense>
      </Router>
    );
  } else {
    return (
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/" component={Loader} />
            <Route component={Loader} />
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

export default App;
