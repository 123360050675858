import React, { Component, useEffect, useState } from "react";
import AboutGsl from '../../assets/inner-images/about/about_gsl.jpg';
import companyProfile from '../../assets/inner-images/about/company_profile.jpg';
import companyHistory from '../../assets/inner-images/about/company_history.jpg';
import factsFigures from '../../assets/inner-images/about/facts_figures.jpg';
import cargoServices from '../../assets/inner-images/cargo_services/cargo_services.jpg';
import dangerousCargo from '../../assets/inner-images/cargo_services/dangerous_cargo.jpg';
import dryCargo from '../../assets/inner-images/cargo_services/dry_cargo.jpg';
import gslMonitor from '../../assets/inner-images/cargo_services/gsl_monitor.jpg';
import inlandTransport from '../../assets/inner-images/cargo_services/inland_transport.jpg';
import projectCargo from '../../assets/inner-images/cargo_services/project_cargo.jpg';
import reeferCargo from '../../assets/inner-images/cargo_services/reefer_cargo.jpg';
import temperatureControlledShipping from '../../assets/inner-images/cargo_services/temperature_controlled_shipping.jpg';
import highValueOrSensitiveCargo from '../../assets/inner-images/cargo_services/high_value_or_sensitive_cargo.jpg';
import Catalogue from '../../assets/inner-images/tools/catalogue.jpg';
import eblService from '../../assets/inner-images/tools/ebl_service.jpg';
import requestaQuote from '../../assets/inner-images/tools/request_a_quote.jpg';
import siSubmission from '../../assets/inner-images/tools/si_submission.jpg';
import bookingSubmission from '../../assets/inner-images/tools/booking_submission.jpg';
import vgmMassUpload from '../../assets/inner-images/tools/vgm_mass_upload.jpg';
import careers from '../../assets/inner-images/careers/careers.jpg';
import demurrage_and_detention_tariff from '../../assets/inner-images/local_info/demurrage_and_detention_tariff.jpg';
import local_info from '../../assets/inner-images/local_info/local_info.jpg';
import help_customer_advisory from '../../assets/inner-images/help/help_customer_advisory.jpg';
import newsletter from '../../assets/inner-images/newsletter/newsletter.jpg';
import terms_of_use from '../../assets/inner-images/help/terms_of_use.jpg';
import enquiry_us from '../../assets/inner-images/enquiry/enquiry_us.jpg';
import faq_gsl from '../../assets/inner-images/enquiry/faq_gsl.jpg';
import catalogues from '../../assets/inner-images/catalogue/catalogue.jpg';
import roro from '../../assets/inner-images/roro/roro.jpg';
import subscribe from '../../assets/inner-images/about/subscribe.jpg';
import shapeBlue from '../../../src/assets/banner-shape-blue.svg';
import shapeYellow from '../../../src/assets/banner-shape.svg';

const innerJSON =
{
    "subscribe": {
        "title": "ติดตาม",
        "discription": "รับการแจ้งเตือนตามเวลาจริงสำหรับการจัดส่งของคุณและรับการมองเห็นสถานะของสินค้าของคุณอย่างสมบูรณ์",
        "url": subscribe,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
        
    },
    "about_gsl": {
        "title": "แคตตาล็อก",
        "discription": "วางแผนการจัดส่งของคุณตามข้อกำหนดและความต้องการของสินค้าของคุณ",
        "url": AboutGsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',

    },
    "cargo_catalogue": {
        "title": "แคตตาล็อก",
        "discription": "วางแผนการจัดส่งของคุณตามข้อกำหนดและความต้องการของสินค้าของคุณ",
        "url": catalogues,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
        
    },
    "request_a_quote": {
        "title": "ขอใบเสนอราคา",
        "discription": "พันธกิจและเป้าหมายของเราคือการให้บริการขนส่งสินค้าที่หลากหลายเพื่อตอบสนองธุรกิจของคุณ",
        "url": requestaQuote,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_submission": {
        "title": "จองระวองเรือ",
        "discription": "พันธกิจและเป้าหมายของเราคือการให้บริการขนส่งสินค้าที่หลากหลายเพื่อตอบสนองธุรกิจของคุณ",
        "url": bookingSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ebl_service": {
        "title": "บริการ eBL",
        "discription": "พันธกิจและเป้าหมายของเราคือการให้บริการขนส่งสินค้าที่หลากหลายเพื่อตอบสนองธุรกิจของคุณ",
        "url": eblService,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "si_submission": {
        "title": "ส่ง SI",
        "discription": "การส่งรายละเอียดของสินค้า ฝ่ายที่เกี่ยวข้อง และข้อกำหนดอย่างราบรื่น",
        "url": siSubmission,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "ecommerce_partners": {
        "title": "พันธมิตรอีคอมเมิร์ซ",
        "discription": "พันธกิจและเป้าหมายของเราคือการให้บริการขนส่งสินค้าที่หลากหลายเพื่อตอบสนองธุรกิจของคุณ",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "vgm_mass_upload": {
        "title": "อัปโหลด VGM",
        "discription": "การส่ง VGM สำหรับชิปเมนท์หลายรายการเป็นเรื่องง่าย",
        "url": vgmMassUpload,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "high_value_or_sensitive_cargo": {
        "title": "สินค้าที่มีมูลค่าสูงหรือมีความละเอียดอ่อน",
        "discription": "GSL Monitor มอบเครื่องมือที่แม่นยำในการติดตาม ตรวจสอบ และควบคุมสินค้ามีค่าของคุณ เพื่อให้แน่ใจว่าสินค้าจะถูกจัดส่งอย่างปลอดภัย",
        "url": highValueOrSensitiveCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "enquiry": {
        "title": "ติดต่อเรา",
        "discription": "ติดต่อเรา! เรายินดีที่จะให้ข้อมูลทั้งหมดที่คุณต้องการ",
        "url": enquiry_us,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "faq": {
        "title": "คำถามที่พบบ่อย",
        "discription": "เรียนรู้เพิ่มเติมเกี่ยวกับ Gold Star Line และบริการของเรา",
        "url": faq_gsl,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "roro-private": {
        "title": "บริการเรือบรรทุกรถยนต์",
        "discription": "ตอบสนองความต้องการที่เพิ่มขึ้นสำหรับการขนส่งยานยนต์ทั่วโลก",
        "url": roro,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "newsletter": {
        "title": "ข้อมูลข่าวสาร",
        "discription": "คอยติดตามข้อมูลและประกาศล่าสุด!",
        "url": newsletter,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_newsletter": {
        "title": "ดูจดหมายข่าว",
        "discription": "พันธกิจและเป้าหมายของเราคือการให้บริการขนส่งสินค้าที่หลากหลายเพื่อตอบสนองธุรกิจของคุณ",
        "url": newsletter,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "cargo_services": {
        "title": "บริการขนส่งสินค้า",
        "discription": "ภารกิจของเราคือการให้บริการขนส่งสินค้าทางทะเลแบบครบวงจรสำหรับสินค้าทุกประเภท",
        "url": cargoServices,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "gsl_monitor": {
        "title": "GSL Monitor",
        "discription": "ติดตาม ตรวจสอบ และควบคุมสภาพแวดล้อมของสินค้าของคุณ โดยเฉพาะอย่างยิ่งเมื่อขนส่งสินค้าที่ละเอียดอ่อน มูลค่าสูง หรือสินค้าห้องเย็น",
        "url": gslMonitor,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dry_cargo": {
        "title": "สินค้าแห้ง",
        "discription": "ด้วยประสบการณ์กว่า 60 ปีในการจัดการกับสินค้าแห้งทุกประเภท เราจะตอบสนองความต้องการของคุณ",
        "url": dryCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "reefer_cargo": {
        "title": "สินค้าควบคุมอุณหภูมิ",
        "discription": "เทคโนโลยีขั้นสูงของเราช่วยให้มั่นใจได้ว่าสินค้าควบคุมอุณหภูมิของคุณจะถูกจัดส่งอย่างปลอดภัย ในอุณหภูมิที่เหมาะสม และตรงเวลา",
        "url": reeferCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "project_cargo": {
        "title": "สินค้าโครงการ",
        "discription": "เรามีโซลูชั่นที่สร้างสรรค์และความเชี่ยวชาญที่จำเป็นในการเคลื่อนย้ายสินค้าพิเศษของคุณไปทั่วโลกอย่างปลอดภัย",
        "url": projectCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "inland_transport": {
        "title": "ขนส่งทางบก",
        "discription": "ให้บริการขนส่งทางบกที่เชื่อถือได้ผ่านเครือข่ายแบบ door-to-door ที่กว้างขวางของเรา",
        "url": inlandTransport,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "temperature_controlled_shipping": {
        "title": "การขนส่งแบบควบคุมอุณหภูมิ",
        "discription": "แช่เย็นหรือแช่แข็ง เราให้บริการขนส่งที่ปลอดภัยสำหรับผลิตภัณฑ์ที่ไวต่ออุณหภูมิทั้งหมด",
        "url": temperatureControlledShipping,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "dangerous_cargo": {
        "title": "สินค้าอันตราย",
        "discription": "เรานำเสนอบริการที่ทุ่มเทและเป็นมืออาชีพสำหรับการจัดเก็บและการขนส่งสินค้าอันตรายอย่างปลอดภัย",
        "url": dangerousCargo,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "about_gsl": {
        "title": "เกี่ยวกับเรา",
        "discription": "Gold Star Line - ผู้เล่นหลักใน Intra Asia, Oceania และ Africa Trades ให้บริการตลาดแบบไดนามิกด้วยวิธีการที่คล่องตัว",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_profile": {
        "title": "ประวัติบริษัท",
        "discription": "ภารกิจของเราคือการค้นหาโซลูชันที่สร้างสรรค์อย่างต่อเนื่องเพื่อรองรับความต้องการของลูกค้า",
        "url": companyProfile,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "company_history": {
        "title": "เรื่องราวของเรา",
        "discription": "เดินทางข้ามเวลาและค้นพบประวัติศาสตร์ เหตุการณ์สำคัญ และการพัฒนาของ Gold Star Line",
        "url": companyHistory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "facts_figures": {
        "title": "ข้อมูลและตัวเลข",
        "discription": "ภารกิจของเราคือการค้นหาโซลูชันที่สร้างสรรค์อย่างต่อเนื่องเพื่อรองรับความต้องการของลูกค้า",
        "url": factsFigures,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "demurrage_and_detention_tariff": {
        "title": "อัตรา Demurrage และ Detention",
        "discription": "Demurrage และ Detention ไม่จำเป็นต้องเป็นเรื่องยาก",
        "url": demurrage_and_detention_tariff,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "local_info": {
        "title": "ข้อมูล Local information",
        "discription": "อัพเดทอยู่เสมอด้วยข้อมูล Local Informationที่เป็นประโยชน์เฉพาะสำหรับคุณ",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "unsubscribe_schedule": {
        "title": "ยกเลิกติดตามตารางเรือ",
        "discription": "",
        "url": local_info,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "contact_us": {
        "title": "รายละเอียดสำนักงาน GSL",
        "discription": "ดูรายละเอียดสำนักงาน ที่อยู่ และที่ติดต่อต่างๆ ของเรา",
        "url": enquiry_us,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "careers": {
        "title": "ร่วมงานกับเรา",
        "discription": "เข้าร่วม Gold Star Line และเติบโตไปกับเรา!",
        "url": careers,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "solas_regulation": {
        "title": "ระเบียบ SOLAS",
        "discription": "พันธกิจและเป้าหมายของเราคือการให้บริการขนส่งสินค้าที่หลากหลายเพื่อตอบสนองธุรกิจของคุณ",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "customer_advisory": {
        "title": "ประกาศแจ้งผู้ใช้บริการ",
        "discription": "คอยติดตามการแจ้งเตือนและคำแนะนำของเรา",
        "url": help_customer_advisory,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "accessibility": {
        "title": "การเข้าถึงข้อมูล",
        "discription": "ปรับปรุงคุณภาพชีวิตด้วยรูปแบบการเข้าถึงของเรา",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "data_protection_policy": {
        "title": "นโยบายการคุ้มครองข้อมูล",
        "discription": "รับข้อมูลเกี่ยวกับนโยบายการคุ้มครองข้อมูลส่วนบุคคลของเรา",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_cookies_policy": {
        "title": "นโยบายคุกกี้และการติดตาม",
        "discription": "ความมุ่งมั่นของเราเกี่ยวกับข้อมูลส่วนบุคคลและความเป็นส่วนตัวของข้อมูล",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "quotation_terms_conditions": {
        "title": "เงื่อนไขใบเสนอราคา",
        "discription": "ปฏิบัติตามข้อกำหนดและเงื่อนไขของใบเสนอราคา",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "terms_of_use": {
        "title": "เงื่อนไขการใช้บริการ",
        "discription": "หลักเกณฑ์และข้อกำหนดในการให้บริการ",
        "url": terms_of_use,
        "shape_color": shapeBlue,
        "font_color":'#FFFFFF',
    },
    "view_customer_advisory": {
        "title": "ประกาศแจ้งผู้ใช้บริการ",
        "discription": "ประกาศและคำแนะนำแก่ลูกค้า",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "bl_terms_conditions": {
        "title": "เงื่อนไขข้อกำหนด B/L",
        "discription": "รับการแจ้งเตือนเกี่ยวกับข้อกำหนดทางกฎหมายของ Bill of Ladings ",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "gsl_go_terms_conditions": {
        "title": "ข้อกำหนดและเงื่อนไขของ GSL GO",
        "discription": "รับการแจ้งเตือนเกี่ยวกับข้อกำหนดทางกฎหมายของ GSL GO",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "about_car_carrier": {
        "title": "เกี่ยวกับการขนส่งรถยนต์",
        "discription": "",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "egsl_terms_conditions": {
        "title": "ข้อกำหนดและเงื่อนไข eGSL",
        "discription": "รับการแจ้งเตือนเกี่ยวกับข้อกำหนดทางกฎหมายของ Bill of Ladings ",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "booking_clause": {
        "title": "รายละเอียดข้อกำหนดการจองบุคกิ้ง",
        "discription": "รับทราบข้อกำหนดที่เกี่ยวข้องกับเอกสารยืนยันการจองของเรา",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_company_news": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_regulation": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
    "view_industry": {
        "title": " ",
        "discription": "",
        "url": AboutGsl,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },"sea_waybill_terms_conditions": {
        "title": " SEA WAYBILL TERMS AND CONDITIONS",
        "discription": "TERMS AND CONDITIONS",
        "url": help_customer_advisory,
        "shape_color": shapeYellow,
        "font_color":'#123C60',
    },
}


export default innerJSON;