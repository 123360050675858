import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import Inland from '../../../../src/assets/Inland.jpeg';
import abt_icn from '../../../../src/assets/abt-icn.png';
import Inner_container from '../../innerpages/inner-container';
import Inland_transport_lang from '../../lang/inland-transport'


var jwt = require('jsonwebtoken');
export default class Inland_transport extends Component {

    render() {
		var lang = window.localStorage.getItem('lang');
        return (

            <Layout>
              <Inner_container innerpage="inland_transport" />
                
                <section class="inner-center n-40">
	<div class="container">										
					<form class="abt-full">						
							<div class="row ml-0 mr-0 pjt-cargo-row">																									
									
								<div class="col-lg-5 col-sm-12 pot-form pl-0 dat-relat ">																			
										  							
									<div class=" cargo-n-imgg" >  																							 
										<img src={Inland} />											
									</div>																			
								</div>																	
								
								<div class="col-lg-7 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s" >
									<div class="car-ser-new">
										
										<h3>{Inland_transport_lang[lang].InlandTransport}</h3>
										
										<p>{Inland_transport_lang[lang].GSLstransportcapabilities}</p>
							<p>{Inland_transport_lang[lang].Weworkhandinhand}</p>
							<p>{Inland_transport_lang[lang].Thisallinclusive} </p>	
									</div>										
								</div>
							</div>													
					</form>									
	</div>
</section>
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout>);
    }
}