const homelang =
{
    "en": {
        "VGMMassUpload": "VGM Mass Upload",
        "VGMMassUpload1": "Declare VGM for all shipments",
        "Error": "Error",
        "InvalidPortofLoading": "Invalid Port of Loading",
        "DownloadVGMformtemplate": "Download VGM form template",
        "ContactPersonName": "Contact Person Name",
        "Email": "Email",
        "BookingOfficeCountryLocation": "Booking Office Country Location",
        "BookingOffice": "Booking Office",
        "PlaceofReceipt": "Place of Receipt",
        "PortofLoading": "Port of Loading",
        "CUCCCode": "CUCC Code(Valid Customer Code Provide by GSL)",
        "ExcelFileonly": "Excel File only (xlsx)",
        "Typethecodeyousee": "Type the code you see",
        "Submit": "Submit",

    },
    "cn": {
        "VGMMassUpload": "統一上傳 VGM",
        "VGMMassUpload1": "申報所有貨物 VGM",
        "Error": "錯誤",
        "InvalidPortofLoading": "裝貨港不正確",
        "DownloadVGMformtemplate": "下載申報 VGM 表格",
        "ContactPersonName": "聯絡人姓名",
        "Email": "電郵",
        "BookingOfficeCountryLocation": "訂艙國家",
        "BookingOffice": "訂艙地區",
        "PlaceofReceipt": "收貨地",
        "PortofLoading": "載貨港",
        "CUCCCode": "金星航運提供的客戶代碼",
        "ExcelFileonly": "Excel 文件",
        "Typethecodeyousee": "請輸入驗證碼",
        "Submit": "提交",
    },
    "zh": {
        "VGMMassUpload": "批量上传 VGM",
        "VGMMassUpload1": "申报所有货物 VGM",
        "Error": "错误",
        "InvalidPortofLoading": "装货港不正确",
        "DownloadVGMformtemplate": "下载申报 VGM 表格",
        "ContactPersonName": "联络人姓名",
        "Email": "电邮",
        "BookingOfficeCountryLocation": "订舱国家",
        "BookingOffice": "订舱地区",
        "PlaceofReceipt": "收货地",
        "PortofLoading": "载货港",
        "CUCCCode": "金星航运提供的客户代码",
        "ExcelFileonly": "Excel 文件",
        "Typethecodeyousee": "请输入验证码",
        "Submit": "递交",
    },
    "ko": {
        "VGMMassUpload": "VGM 일괄 업로드",
        "VGMMassUpload1": "전체 선적분에 대한 VGM 신고",
        "Error": "장애",
        "InvalidPortofLoading": "선적항 오류",
        "DownloadVGMformtemplate": "VGM 양식 받기",
        "ContactPersonName": "연락 담당자 이름",
        "Email": "Email",
        "BookingOfficeCountryLocation": "예약 사무소 국가 위치",
        "BookingOffice": "예약 사무소",
        "PlaceofReceipt": "화물 인도 장소",
        "PortofLoading": "선적항",
        "CUCCCode": "화주 코드",
        "ExcelFileonly": "Excel 파일만 사용",
        "Typethecodeyousee": "보이는 코드를 입력하십시오",
        "Submit": "제출",
    },
    "vi": {
        "VGMMassUpload": "Tải lên VGM số lượng lớn",
        "VGMMassUpload1": "Khai báo VGM cho tất cả các lô hàng",
        "Error": "Lỗi",
        "InvalidPortofLoading": "Cảng xếp hàng không tồn tại",
        "DownloadVGMformtemplate": "Tải xuống mẫu khai báo VGM",
        "ContactPersonName": "Thông tin người liên hệ",
        "Email": "Thư điện tử",
        "BookingOfficeCountryLocation": "Vị trí quốc gia của Đại lý đặt chỗ",
        "BookingOffice": "Đại lý đặt chỗ",
        "PlaceofReceipt": "Nơi nhận hàng",
        "PortofLoading": "Cảng xếp hàng",
        "CUCCCode": "Mã CUCC (Mã khách hàng cung cấp bởi GSL)",
        "ExcelFileonly": "Chỉ nhận tệp Excel (xlsx)",
        "Typethecodeyousee": "Vui lòng nhập mã mà bạn thấy",
        "Submit": "Nhấn để gửi",
    },
    "th": {
        "VGMMassUpload": "VGM Mass อัปโหลด",
        "VGMMassUpload1": "แจ้ง VGMสำหรับทุกชิปเมนท์",
        "Error": "ข้อผิดพลาด",
        "InvalidPortofLoading": "สถานที่ต้นทางที่ส่งออกไม่ถูกต้อง",
        "DownloadVGMformtemplate": "ดาวน์โหลดแบบฟอร์ม VGM",
        "ContactPersonName": "ชื่อผู้ติดต่อ",
        "Email": "อีเมล",
        "BookingOfficeCountryLocation": "สถานที่จองบุคกิ้ง",
        "BookingOffice": "สาขาที่จอง",
        "PlaceofReceipt": "สถานที่รับสินค้า",
        "PortofLoading": "สถานที่ต้นทางที่ส่งออก",
        "CUCCCode": "รหัส CUCC (รหัสลูกค้าที่ถูกต้องให้โดย GSL)",
        "ExcelFileonly": "ไฟล์ Excel เท่านั้น (xlsx)",
        "Typethecodeyousee": "โปรดใส่รหัสตามภาพ",
        "Submit": "ส่ง",
    },
    "in": {
        "VGMMassUpload": "Unggah Massa VGM",
        "VGMMassUpload1": "Deklarasi VGM untuk semua pengiriman",
        "Error": "Kesalahan",
        "InvalidPortofLoading": "Invalid Port of Loading",
        "DownloadVGMformtemplate": "Pelabuhan muat tidak valid",
        "ContactPersonName": "Nama yang bisa dihubungi",
        "Email": "Email",
        "BookingOfficeCountryLocation": "Lokasi Kantor Negara Pemesanan",
        "BookingOffice": "Kantor Pemesanan",
        "PlaceofReceipt": "Tempat Penerimaan",
        "PortofLoading": "Pelabuhan Muat",
        "CUCCCode": "Kode CUCC (kode pelanggan yang valid dari GSL)",
        "ExcelFileonly": "Hanya berkas Excel (xlsx)",
        "Typethecodeyousee": "Masukkkan kode yang anda lihat",
        "Submit": "Ajukan",

    },
    "ja": {
        "VGMMassUpload": "VGM一括アップロード",
        "VGMMassUpload1": "すべての貨物のVGMを申告",
        "Error": "エラー",
        "InvalidPortofLoading": "積地港が無効です",
        "DownloadVGMformtemplate": "VGMフォームテンプレートをダウンロードします",
        "ContactPersonName": "担当者の氏名",
        "Email": "メールアドレス",
        "BookingOfficeCountryLocation": "予約を行った事務所の所在国",
        "BookingOffice": "予約を行った事務所",
        "PlaceofReceipt": "荷受地",
        "PortofLoading": "積地港",
        "CUCCCode": "CUCCコード（GSLが提供する有効な顧客コード）",
        "ExcelFileonly": "Excelファイル（xlsx）のみ",
        "Typethecodeyousee": "表示されたコードを入力します",
        "Submit": "送信",

    },
}
export default homelang;