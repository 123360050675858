const homelang =
{
    "en": {
        "Thefollowingtermsandconditions": 'The following terms and conditions of use constitute a legal agreement between you and Gold Star Line Ltd. ("GSL") regarding the access and use of the website (respectively the "Website" and "Terms"). By accessing, browsing and/or using the Website, you acknowledge that you have read, understood, and agree, to be bound by these terms and to comply with all applicable laws and regulations. If you do not agree to these terms, do not use the Website',
        "AccessandUseofthisWebsite": "Access and Use of this Website",
        "Youarepermittedtousedownload": "You are permitted to use, download, and print any page of the Website only for the purposes reasonably anticipated on the Website or as otherwise might reasonably be expected in the course of your relationship with GSL and in accordance with any procedures from time to time in force on the Website. Copying, reproducing, displaying, posting, downloading, re-transmission, re-publication, modification of the data or contents provided in the Website, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, is strictly prohibited without the prior written permission of GSL",
        "AsaconditionofyouruseoftheWebsite": "As a condition of your use of the Website, you will not use the Website for any purpose that is illegal or breaches any applicable laws or regulations or unlawful or otherwise prohibited by these Terms. You may not use the Website in any manner that could damage, disable, overburden, or impair any GSL systems, including GSL’s servers and/or the network(s) connected to any GSL server, or interfere with any other party's use and enjoyment of the Website. You may not attempt to gain unauthorized access to any services, accounts, systems or networks through hacking, password mining or any other means. You may not: (i) use or permit any other party to use all or any part of the Website, content or services in connection with activities that breach any applicable laws or regulations, infringe any third party’s rights, or breach any applicable standards, content requirements or codes; (ii) post to, upload to, temporarily store on (if such facility is provided) or transmit through, the Website any information, materials or content that might be or might encourage conduct that might be illegal, unlawful, threatening, abusive, defamatory, obscene, vulgar, discriminatory, pornographic, profane or indecent; (iii) use the Website for the purpose of or as a means to send or distribute software viruses, trojan horses, or any computer code, files, or programs designed to disrupt, destroy, invade, gain unauthorized access to, corrupt, observe or effect software or hardware",
        "IntellectualPropertyRights": "Intellectual Property Rights",
        "Ownershipofallcopyrights": "Ownership of all copyrights, database rights, patents, trade or service marks, product names or design rights (whether registered or unregistered), trade secrets and confidential information and any similar rights existing in any territory now or in future and similar rights and interests in all domain names, trademarks, logos, branding appearing on the Website and all content, or otherwise relating to the structure of the Website and the services offered by GSL via the Website, vests in GSL or its licensors",
        "InteractivityThirdPartyLinksandServiceProviders": "Interactivity, Third Party Links and Service Providers",
        "GSLmaymakeavailablecertain": 'GSL may make available certain features and/or services and/or hyperlinks, including certain interactive features which are provided by external and independent third parties ("Third Party Provider(s)"). You hereby acknowledge that the use of any such features and/or services and/or hyperlinks is subject to and governed by the applicable terms of use or such other agreement in connection therewith. GSL disclaims all warranties, express, implied or statutory, with respect to each Third-Party Provider and its respective feature and/or service, including but not limited to all warranties of merchantability, fitness for a particular purpose, title and non-infringement',
        "GSLmayprovidethroughtheWebsite": "GSL may provide through the Website certain interactive services and features, such as tracking and tracing of containers and shipping schedules. You acknowledge that the data provided through such interactive services and features is for information and convenience only and is not definitive. GSL does not warrant the accuracy or correctness of such data and any such data must be verified with applicable the local agents. Reliance on the information provided through the Website or its use is at Your own and sole responsibility",
        "MonitoringandUnlawfulActivity": "Monitoring and Unlawful Activity",
        "GSLhasnoobligationtomonitortheWebsite": "GSL has no obligation to monitor the Website; howeverGSL reserves the right to do so and prohibit or deny use or access at its sole discretion. Furthermore and without derogating from any other legal to which GSL may be entitled, GSL reserves the right to: (i) terminate or suspend your access to the entire Website or any part thereof at any time, without notice, for any reason whatsoever; (ii) restrict, edit or delete any materials provided or uploaded by You, without notice, for any reason whatsoever. GSL reserves the right to investigate complaints or reported violations of our Terms and to take any action we deem appropriate including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to user profiles, e-mail addresses, usage history, posted materials, IP addresses and traffic information",
        "AccuracyofthisWebsite": "Accuracy of this Website",
        "WhileGSLusescommerciallyreasonable": "While GSL uses commercially reasonable efforts to ensure that the information contained in the Website is accurate and up to date, the Website could include technical or other mistakes, inaccuracies or typographical errors. GSL does not warrant that the Content is accurate, sufficient, error free, complete or up to date at the time it is accessed. You should make further enquiries to satisfy yourself of the accuracy and completeness of any content before relying on it. Certain content may be labeled as indicative only, in which case GSL makes no warranties at all in relation to its quality, accuracy, completeness or timeliness",
        "InformativeData": "Informative Data",
        "Withoutderogatingfromtheaforesaid": "Without derogating from the aforesaid, you acknowledge that the information and/or data being offered or provided through the Website or its use, including without limitation with respect to shipment and material handling, tips and recommendations, information relating to local regulations etc., is purely informative in nature and provided for your convenience only without any representation or warranty by GSL. In no event will the information and/or data being offered or provided through the Website constitute professional, legal, or tax advice or full and accurate data",
        "Youacknowledgeandassumesole": "You acknowledge and assume sole and full responsibility for any liability or loss incurred as a consequence of the use, directly or indirectly, of any information the information and/or data being offered or provided through the Website. You hereby irrevocably and unconditionally waive any claim or demand regarding reliance on any of the information and/or data being offered or provided through the Website or its use",
        "Conflict": "Conflict",
        "Youherebyacknowledgeandconfirmthat": "You hereby acknowledge and confirm that in case of a conflict between these Terms or any other service information provided by the Website or its use and the information contained in the applicable GSL's internal documentation/agreement, the internal documentation/agreement will prevail",
        "Furthermoreandwithoutderogating": "Furthermore and without derogating from the aforesaid, the terms and conditions of GSL's Bill of Lading/Seaway Bill, including without limitation the timetables, governing law and jurisdiction clauses detailed therein shall prevail and supersede these Terms and/or any of the information provided through the Website or its use",
        "PersonalInformationandPrivacy": "Personal Information and Privacy",
        "GSLiscommittedtopreserving": "GSL is committed to preserving the privacy of the users in its Website. To learn about how GSL protects your personal information, such as your name and address, refer to GSL’s Privacy Policy. Except as set forth in the Privacy Policy or in these Terms, your personal information will be deemed to be confidential. Any non-personal information or material sent to GSL and/or provided by you through the Website will generally be deemed to NOT be confidential. You understand and agree that we may disclose information about you if we have a good faith belief that we are required to do so by law or legal process, to respond to claims, or to protect the rights, property, or safety of GSL or others, or as stated in our Privacy Policy",
        "DisclaimerofWarranties": "Disclaimer of Warranties",
        "EXCEPTASEXPRESSLYPROVIDEDOTHERWISE": 'EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AGREEMENT BETWEEN YOU AND GSL, THE WEBSITE, AND ALL MATERIALS AND SERVICES ACCESSIBLE THROUGH THE WEBSITE, ARE PROVIDED ON AN "AS IS" BASIS. GSL, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES RIGHTS AND FITNESS FOR A PARTICULAR PURPOSE. GSL, ITS AFFILIATES AND LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY, COMPLETENESS, SECURITY OR TIMELINESS OF THE SERVICES, CONTENT OR INFORMATION PROVIDED ON OR THROUGH THE USE OF THE WEBSITE. NO INFORMATION OBTAINED VIA THE WEBSITE SHALL CREATE ANY WARRANTY ON BEHALF OF GSL. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY LAST, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU',
        "GSLMAKESNOWARRANTYTHAT": "GSL MAKES NO WARRANTY THAT THE WEBSITE OR THE SERVICES PROVIDED VIA THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE, OR THAT THE WEBSITE OR ANY OF THE SERVICES, INFORMATION AND CONTENT PROVIDED THEREBY OR VIA THE APPLICABLE SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS",
        "AlthoughGSLusesreasonableattempts": "Although GSL uses reasonable attempts to exclude viruses or the like from the Website, it cannot ensure such exclusion. GSL assumes no responsibility, and shall not be liable for any damages to or viruses or the like that may infect your computer equipment or other property on account of your access to or use of or browsing of the Website or your downloading of any material, data, text, images or other from the Website and no liability is accepted for viruses or the like. Please take all appropriate safeguards to protect Your hardware, software and communication systems in connection with Your use or access of the Website",
        "LimitationofLiability": "Limitation of Liability",
        "TOTHEFULLESTEXTENTPERMITTED": "TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL GSL OR ANY OF ITS SUBSIDIARIES, AFFILIATES, STOCKHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, LICENSORS OR SUPPLIERS (THE “GSL PARTIES”) BE LIABLE, JOINTLY OR SEVERALLY, TO YOU OR ANY OTHER PERSON AS A RESULT OF YOUR USE OR ACCESS OF THE SERVICE, THE SITE OR ANY CONTENT APPEARING VIA THE SITE AND SERVICE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUE, PROFITS, USE, DATA OR OTHER INTANGIBLES (COLLECTIVELY, THE “EXCLUDED DAMAGES”), WHETHER OR NOT CHARACTERIZED IN NEGLIGENCE, TORT, CONTRACT OR OTHER THEORY OF LIABILITY, EVEN IF ANY OF THE GSL PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN ANY OF THE EXCLUDED DAMAGES, AND REGARDLESS OF ANY FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY. IF ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE UNENFORCEABLE, AND THEN THE LIABILITY OF THE GSL PARTIES SHALL BE LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY APPLICABLE LAW",
        "InternationalUsers": "International Users",
        "TheWebsitecanbeaccessedfrom": "The Website can be accessed from countries around the world and may contain references to GSL’s products, services, and programs that are not available in your country. These references do not imply that GSL intends to announce such products, services, or programs in your country. The Website is controlled and operated by GSL from its offices within Israel. GSL makes no representation that materials in the Website are appropriate or available for use in other locations, and access to them from territories where their contents are illegal is prohibited. Those who choose to access and use the Website from other locations do so, on their own initiative and are responsible for compliance with applicable local laws and regulations",
        "RemediesforViolations": "Remedies for Violations",
        "GSLreservestherighttoseek": "GSL reserves the right to seek all remedies available at law and in equity for violations of these Terms, including but not limited to the right to block access from a particular Internet address to the Website and any other provider and their features",
        "GoverningLawandJurisdiction": "Governing Law and Jurisdiction",
        "TheTermsaregoverned": "The Terms are governed by and construed in accordance with the laws of the State of Israel and any action arising out of or relating to these terms shall be filed only in the courts located in Haifa and you hereby consent and submit to the personal jurisdiction of such courts for the purpose of litigating any such action",
        "Revision": "Revision",
        "GSLreservestherighttochange": "GSL reserves the right to change, suspend or discontinue any aspect of the services and content provided through the Website at any time, without notice or liability. You should periodically check these Terms and Privacy Policy (which form and constitute an integral part hereof) posted on the Website to review the current terms and guidelines applicable to your use. Your continued use of the Website after such revisions constitutes your acceptance of these revisions",
        "General": "General",
        "TheseTermsconstitutetheentire": "These Terms constitute the entire agreement between you and GSL with reference to the Website. Use of the Website is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including without limitation this paragraph. In the event any provision of these Terms shall be deemed unenforceable, void or invalid, such provision shall be modified so as to make it valid and enforceable, and as so modified the entire Agreement shall remain in full force and effect. No failure or delay in exercising any right, power or privilege hereunder shall operate as a waiver thereof, nor shall any single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any right, power or privilege hereunder",
        "AdditionalInformationUpdates": "Additional Information, Updates and Questions",
        "Additionalinformationoncookiesingeneral": "Additional information on cookies in general",
        "Anumberofwebsitesprovide": "A number of websites provide detailed information on cookies, including AboutCookies.org and AllAboutCookies.org.",
        "UpdatestoourPolicy": "Updates to our Policy",
        "WeupdateourCookiesPolicyfromtimetotime": "We update our Cookies Policy from time to time, if we introduce a new cookie or tracking tool which processes new information or wish to process the information collected by your cookies and tracing tools in a new manner, we shall notify you and ask you to consent to this updated processing or new cookie or tool",
        "Adefaultconfigurationanduse": "A default configuration and use of our first-party cookies and third-party cookies used in the Personal Area are described in the table below",
       
       
     
    },
    "cn": {
        "Thefollowingtermsandconditions": '以下 terms and conditions of use constitute a legal agreement between you and Gold Star Line Ltd. ("GSL") regarding the access and use of the website (respectively the "Website" and "Terms"). By accessing, browsing and/or using the Website, you acknowledge that you have read, understood, and agree, to be bound by these terms and to comply with all applicable laws and regulations. If you do not agree to these terms, do not use the Website',
        "AccessandUseofthisWebsite": "Access and Use of this Website",
        "Youarepermittedtousedownload": "You are permitted to use, download, and print any page of the Website only for the purposes reasonably anticipated on the Website or as otherwise might reasonably be expected in the course of your relationship with GSL and in accordance with any procedures from time to time in force on the Website. Copying, reproducing, displaying, posting, downloading, re-transmission, re-publication, modification of the data or contents provided in the Website, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, is strictly prohibited without the prior written permission of GSL",
        "AsaconditionofyouruseoftheWebsite": "As a condition of your use of the Website, you will not use the Website for any purpose that is illegal or breaches any applicable laws or regulations or unlawful or otherwise prohibited by these Terms. You may not use the Website in any manner that could damage, disable, overburden, or impair any GSL systems, including GSL’s servers and/or the network(s) connected to any GSL server, or interfere with any other party's use and enjoyment of the Website. You may not attempt to gain unauthorized access to any services, accounts, systems or networks through hacking, password mining or any other means. You may not: (i) use or permit any other party to use all or any part of the Website, content or services in connection with activities that breach any applicable laws or regulations, infringe any third party’s rights, or breach any applicable standards, content requirements or codes; (ii) post to, upload to, temporarily store on (if such facility is provided) or transmit through, the Website any information, materials or content that might be or might encourage conduct that might be illegal, unlawful, threatening, abusive, defamatory, obscene, vulgar, discriminatory, pornographic, profane or indecent; (iii) use the Website for the purpose of or as a means to send or distribute software viruses, trojan horses, or any computer code, files, or programs designed to disrupt, destroy, invade, gain unauthorized access to, corrupt, observe or effect software or hardware",
        "IntellectualPropertyRights": "Intellectual Property Rights",
        "Ownershipofallcopyrights": "Ownership of all copyrights, database rights, patents, trade or service marks, product names or design rights (whether registered or unregistered), trade secrets and confidential information and any similar rights existing in any territory now or in future and similar rights and interests in all domain names, trademarks, logos, branding appearing on the Website and all content, or otherwise relating to the structure of the Website and the services offered by GSL via the Website, vests in GSL or its licensors",
        "InteractivityThirdPartyLinksandServiceProviders": "Interactivity, Third Party Links and Service Providers",
        "GSLmaymakeavailablecertain": 'GSL may make available certain features and/or services and/or hyperlinks, including certain interactive features which are provided by external and independent third parties ("Third Party Provider(s)"). You hereby acknowledge that the use of any such features and/or services and/or hyperlinks is subject to and governed by the applicable terms of use or such other agreement in connection therewith. GSL disclaims all warranties, express, implied or statutory, with respect to each Third-Party Provider and its respective feature and/or service, including but not limited to all warranties of merchantability, fitness for a particular purpose, title and non-infringement',
        "GSLmayprovidethroughtheWebsite": "GSL may provide through the Website certain interactive services and features, such as tracking and tracing of containers and shipping schedules. You acknowledge that the data provided through such interactive services and features is for information and convenience only and is not definitive. GSL does not warrant the accuracy or correctness of such data and any such data must be verified with applicable the local agents. Reliance on the information provided through the Website or its use is at Your own and sole responsibility",
        "MonitoringandUnlawfulActivity": "Monitoring and Unlawful Activity",
        "GSLhasnoobligationtomonitortheWebsite": "GSL has no obligation to monitor the Website; howeverGSL reserves the right to do so and prohibit or deny use or access at its sole discretion. Furthermore and without derogating from any other legal to which GSL may be entitled, GSL reserves the right to: (i) terminate or suspend your access to the entire Website or any part thereof at any time, without notice, for any reason whatsoever; (ii) restrict, edit or delete any materials provided or uploaded by You, without notice, for any reason whatsoever. GSL reserves the right to investigate complaints or reported violations of our Terms and to take any action we deem appropriate including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to user profiles, e-mail addresses, usage history, posted materials, IP addresses and traffic information",
        "AccuracyofthisWebsite": "Accuracy of this Website",
        "WhileGSLusescommerciallyreasonable": "While GSL uses commercially reasonable efforts to ensure that the information contained in the Website is accurate and up to date, the Website could include technical or other mistakes, inaccuracies or typographical errors. GSL does not warrant that the Content is accurate, sufficient, error free, complete or up to date at the time it is accessed. You should make further enquiries to satisfy yourself of the accuracy and completeness of any content before relying on it. Certain content may be labeled as indicative only, in which case GSL makes no warranties at all in relation to its quality, accuracy, completeness or timeliness",
        "InformativeData": "Informative Data",
        "Withoutderogatingfromtheaforesaid": "Without derogating from the aforesaid, you acknowledge that the information and/or data being offered or provided through the Website or its use, including without limitation with respect to shipment and material handling, tips and recommendations, information relating to local regulations etc., is purely informative in nature and provided for your convenience only without any representation or warranty by GSL. In no event will the information and/or data being offered or provided through the Website constitute professional, legal, or tax advice or full and accurate data",
        "Youacknowledgeandassumesole": "You acknowledge and assume sole and full responsibility for any liability or loss incurred as a consequence of the use, directly or indirectly, of any information the information and/or data being offered or provided through the Website. You hereby irrevocably and unconditionally waive any claim or demand regarding reliance on any of the information and/or data being offered or provided through the Website or its use",
        "Conflict": "Conflict",
        "Youherebyacknowledgeandconfirmthat": "You hereby acknowledge and confirm that in case of a conflict between these Terms or any other service information provided by the Website or its use and the information contained in the applicable GSL's internal documentation/agreement, the internal documentation/agreement will prevail",
        "Furthermoreandwithoutderogating": "Furthermore and without derogating from the aforesaid, the terms and conditions of GSL's Bill of Lading/Seaway Bill, including without limitation the timetables, governing law and jurisdiction clauses detailed therein shall prevail and supersede these Terms and/or any of the information provided through the Website or its use",
        "PersonalInformationandPrivacy": "Personal Information and Privacy",
        "GSLiscommittedtopreserving": "GSL is committed to preserving the privacy of the users in its Website. To learn about how GSL protects your personal information, such as your name and address, refer to GSL’s Privacy Policy. Except as set forth in the Privacy Policy or in these Terms, your personal information will be deemed to be confidential. Any non-personal information or material sent to GSL and/or provided by you through the Website will generally be deemed to NOT be confidential. You understand and agree that we may disclose information about you if we have a good faith belief that we are required to do so by law or legal process, to respond to claims, or to protect the rights, property, or safety of GSL or others, or as stated in our Privacy Policy",
        "DisclaimerofWarranties": "Disclaimer of Warranties",
        "EXCEPTASEXPRESSLYPROVIDEDOTHERWISE": 'EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AGREEMENT BETWEEN YOU AND GSL, THE WEBSITE, AND ALL MATERIALS AND SERVICES ACCESSIBLE THROUGH THE WEBSITE, ARE PROVIDED ON AN "AS IS" BASIS. GSL, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES RIGHTS AND FITNESS FOR A PARTICULAR PURPOSE. GSL, ITS AFFILIATES AND LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY, COMPLETENESS, SECURITY OR TIMELINESS OF THE SERVICES, CONTENT OR INFORMATION PROVIDED ON OR THROUGH THE USE OF THE WEBSITE. NO INFORMATION OBTAINED VIA THE WEBSITE SHALL CREATE ANY WARRANTY ON BEHALF OF GSL. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY LAST, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU',
        "GSLMAKESNOWARRANTYTHAT": "GSL MAKES NO WARRANTY THAT THE WEBSITE OR THE SERVICES PROVIDED VIA THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE, OR THAT THE WEBSITE OR ANY OF THE SERVICES, INFORMATION AND CONTENT PROVIDED THEREBY OR VIA THE APPLICABLE SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS",
        "AlthoughGSLusesreasonableattempts": "Although GSL uses reasonable attempts to exclude viruses or the like from the Website, it cannot ensure such exclusion. GSL assumes no responsibility, and shall not be liable for any damages to or viruses or the like that may infect your computer equipment or other property on account of your access to or use of or browsing of the Website or your downloading of any material, data, text, images or other from the Website and no liability is accepted for viruses or the like. Please take all appropriate safeguards to protect Your hardware, software and communication systems in connection with Your use or access of the Website",
        "LimitationofLiability": "Limitation of Liability",
        "TOTHEFULLESTEXTENTPERMITTED": "TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL GSL OR ANY OF ITS SUBSIDIARIES, AFFILIATES, STOCKHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, LICENSORS OR SUPPLIERS (THE “GSL PARTIES”) BE LIABLE, JOINTLY OR SEVERALLY, TO YOU OR ANY OTHER PERSON AS A RESULT OF YOUR USE OR ACCESS OF THE SERVICE, THE SITE OR ANY CONTENT APPEARING VIA THE SITE AND SERVICE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUE, PROFITS, USE, DATA OR OTHER INTANGIBLES (COLLECTIVELY, THE “EXCLUDED DAMAGES”), WHETHER OR NOT CHARACTERIZED IN NEGLIGENCE, TORT, CONTRACT OR OTHER THEORY OF LIABILITY, EVEN IF ANY OF THE GSL PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN ANY OF THE EXCLUDED DAMAGES, AND REGARDLESS OF ANY FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY. IF ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE UNENFORCEABLE, AND THEN THE LIABILITY OF THE GSL PARTIES SHALL BE LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY APPLICABLE LAW",
        "InternationalUsers": "International Users",
        "TheWebsitecanbeaccessedfrom": "The Website can be accessed from countries around the world and may contain references to GSL’s products, services, and programs that are not available in your country. These references do not imply that GSL intends to announce such products, services, or programs in your country. The Website is controlled and operated by GSL from its offices within Israel. GSL makes no representation that materials in the Website are appropriate or available for use in other locations, and access to them from territories where their contents are illegal is prohibited. Those who choose to access and use the Website from other locations do so, on their own initiative and are responsible for compliance with applicable local laws and regulations",
        "RemediesforViolations": "Remedies for Violations",
        "GSLreservestherighttoseek": "GSL reserves the right to seek all remedies available at law and in equity for violations of these Terms, including but not limited to the right to block access from a particular Internet address to the Website and any other provider and their features",
        "GoverningLawandJurisdiction": "Governing Law and Jurisdiction",
        "TheTermsaregoverned": "The Terms are governed by and construed in accordance with the laws of the State of Israel and any action arising out of or relating to these terms shall be filed only in the courts located in Haifa and you hereby consent and submit to the personal jurisdiction of such courts for the purpose of litigating any such action",
        "Revision": "Revision",
        "GSLreservestherighttochange": "GSL reserves the right to change, suspend or discontinue any aspect of the services and content provided through the Website at any time, without notice or liability. You should periodically check these Terms and Privacy Policy (which form and constitute an integral part hereof) posted on the Website to review the current terms and guidelines applicable to your use. Your continued use of the Website after such revisions constitutes your acceptance of these revisions",
        "General": "General",
        "TheseTermsconstitutetheentire": "These Terms constitute the entire agreement between you and GSL with reference to the Website. Use of the Website is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including without limitation this paragraph. In the event any provision of these Terms shall be deemed unenforceable, void or invalid, such provision shall be modified so as to make it valid and enforceable, and as so modified the entire Agreement shall remain in full force and effect. No failure or delay in exercising any right, power or privilege hereunder shall operate as a waiver thereof, nor shall any single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any right, power or privilege hereunder",
        "AdditionalInformationUpdates": "Additional Information, Updates and Questions",
        "Additionalinformationoncookiesingeneral": "Additional information on cookies in general",
        "Anumberofwebsitesprovide": "A number of websites provide detailed information on cookies, including AboutCookies.org and AllAboutCookies.org.",
        "UpdatestoourPolicy": "Updates to our Policy",
        "WeupdateourCookiesPolicyfromtimetotime": "We update our Cookies Policy from time to time, if we introduce a new cookie or tracking tool which processes new information or wish to process the information collected by your cookies and tracing tools in a new manner, we shall notify you and ask you to consent to this updated processing or new cookie or tool",
        "Adefaultconfigurationanduse": "默認配置 and use of our first-party cookies and third-party cookies used in the Personal Area are described in the table below",
       
       
    },
    "zh": {
        "Thefollowingtermsandconditions": 'The following terms and conditions of use constitute a legal agreement between you and Gold Star Line Ltd. ("GSL") regarding the access and use of the website (respectively the "Website" and "Terms"). By accessing, browsing and/or using the Website, you acknowledge that you have read, understood, and agree, to be bound by these terms and to comply with all applicable laws and regulations. If you do not agree to these terms, do not use the Website',
        "AccessandUseofthisWebsite": "Access and Use of this Website",
        "Youarepermittedtousedownload": "You are permitted to use, download, and print any page of the Website only for the purposes reasonably anticipated on the Website or as otherwise might reasonably be expected in the course of your relationship with GSL and in accordance with any procedures from time to time in force on the Website. Copying, reproducing, displaying, posting, downloading, re-transmission, re-publication, modification of the data or contents provided in the Website, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, is strictly prohibited without the prior written permission of GSL",
        "AsaconditionofyouruseoftheWebsite": "As a condition of your use of the Website, you will not use the Website for any purpose that is illegal or breaches any applicable laws or regulations or unlawful or otherwise prohibited by these Terms. You may not use the Website in any manner that could damage, disable, overburden, or impair any GSL systems, including GSL’s servers and/or the network(s) connected to any GSL server, or interfere with any other party's use and enjoyment of the Website. You may not attempt to gain unauthorized access to any services, accounts, systems or networks through hacking, password mining or any other means. You may not: (i) use or permit any other party to use all or any part of the Website, content or services in connection with activities that breach any applicable laws or regulations, infringe any third party’s rights, or breach any applicable standards, content requirements or codes; (ii) post to, upload to, temporarily store on (if such facility is provided) or transmit through, the Website any information, materials or content that might be or might encourage conduct that might be illegal, unlawful, threatening, abusive, defamatory, obscene, vulgar, discriminatory, pornographic, profane or indecent; (iii) use the Website for the purpose of or as a means to send or distribute software viruses, trojan horses, or any computer code, files, or programs designed to disrupt, destroy, invade, gain unauthorized access to, corrupt, observe or effect software or hardware",
        "IntellectualPropertyRights": "Intellectual Property Rights",
        "Ownershipofallcopyrights": "Ownership of all copyrights, database rights, patents, trade or service marks, product names or design rights (whether registered or unregistered), trade secrets and confidential information and any similar rights existing in any territory now or in future and similar rights and interests in all domain names, trademarks, logos, branding appearing on the Website and all content, or otherwise relating to the structure of the Website and the services offered by GSL via the Website, vests in GSL or its licensors",
        "InteractivityThirdPartyLinksandServiceProviders": "Interactivity, Third Party Links and Service Providers",
        "GSLmaymakeavailablecertain": 'GSL may make available certain features and/or services and/or hyperlinks, including certain interactive features which are provided by external and independent third parties ("Third Party Provider(s)"). You hereby acknowledge that the use of any such features and/or services and/or hyperlinks is subject to and governed by the applicable terms of use or such other agreement in connection therewith. GSL disclaims all warranties, express, implied or statutory, with respect to each Third-Party Provider and its respective feature and/or service, including but not limited to all warranties of merchantability, fitness for a particular purpose, title and non-infringement',
        "GSLmayprovidethroughtheWebsite": "GSL may provide through the Website certain interactive services and features, such as tracking and tracing of containers and shipping schedules. You acknowledge that the data provided through such interactive services and features is for information and convenience only and is not definitive. GSL does not warrant the accuracy or correctness of such data and any such data must be verified with applicable the local agents. Reliance on the information provided through the Website or its use is at Your own and sole responsibility",
        "MonitoringandUnlawfulActivity": "Monitoring and Unlawful Activity",
        "GSLhasnoobligationtomonitortheWebsite": "GSL has no obligation to monitor the Website; howeverGSL reserves the right to do so and prohibit or deny use or access at its sole discretion. Furthermore and without derogating from any other legal to which GSL may be entitled, GSL reserves the right to: (i) terminate or suspend your access to the entire Website or any part thereof at any time, without notice, for any reason whatsoever; (ii) restrict, edit or delete any materials provided or uploaded by You, without notice, for any reason whatsoever. GSL reserves the right to investigate complaints or reported violations of our Terms and to take any action we deem appropriate including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to user profiles, e-mail addresses, usage history, posted materials, IP addresses and traffic information",
        "AccuracyofthisWebsite": "Accuracy of this Website",
        "WhileGSLusescommerciallyreasonable": "While GSL uses commercially reasonable efforts to ensure that the information contained in the Website is accurate and up to date, the Website could include technical or other mistakes, inaccuracies or typographical errors. GSL does not warrant that the Content is accurate, sufficient, error free, complete or up to date at the time it is accessed. You should make further enquiries to satisfy yourself of the accuracy and completeness of any content before relying on it. Certain content may be labeled as indicative only, in which case GSL makes no warranties at all in relation to its quality, accuracy, completeness or timeliness",
        "InformativeData": "Informative Data",
        "Withoutderogatingfromtheaforesaid": "Without derogating from the aforesaid, you acknowledge that the information and/or data being offered or provided through the Website or its use, including without limitation with respect to shipment and material handling, tips and recommendations, information relating to local regulations etc., is purely informative in nature and provided for your convenience only without any representation or warranty by GSL. In no event will the information and/or data being offered or provided through the Website constitute professional, legal, or tax advice or full and accurate data",
        "Youacknowledgeandassumesole": "You acknowledge and assume sole and full responsibility for any liability or loss incurred as a consequence of the use, directly or indirectly, of any information the information and/or data being offered or provided through the Website. You hereby irrevocably and unconditionally waive any claim or demand regarding reliance on any of the information and/or data being offered or provided through the Website or its use",
        "Conflict": "Conflict",
        "Youherebyacknowledgeandconfirmthat": "You hereby acknowledge and confirm that in case of a conflict between these Terms or any other service information provided by the Website or its use and the information contained in the applicable GSL's internal documentation/agreement, the internal documentation/agreement will prevail",
        "Furthermoreandwithoutderogating": "Furthermore and without derogating from the aforesaid, the terms and conditions of GSL's Bill of Lading/Seaway Bill, including without limitation the timetables, governing law and jurisdiction clauses detailed therein shall prevail and supersede these Terms and/or any of the information provided through the Website or its use",
        "PersonalInformationandPrivacy": "Personal Information and Privacy",
        "GSLiscommittedtopreserving": "GSL is committed to preserving the privacy of the users in its Website. To learn about how GSL protects your personal information, such as your name and address, refer to GSL’s Privacy Policy. Except as set forth in the Privacy Policy or in these Terms, your personal information will be deemed to be confidential. Any non-personal information or material sent to GSL and/or provided by you through the Website will generally be deemed to NOT be confidential. You understand and agree that we may disclose information about you if we have a good faith belief that we are required to do so by law or legal process, to respond to claims, or to protect the rights, property, or safety of GSL or others, or as stated in our Privacy Policy",
        "DisclaimerofWarranties": "Disclaimer of Warranties",
        "EXCEPTASEXPRESSLYPROVIDEDOTHERWISE": 'EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AGREEMENT BETWEEN YOU AND GSL, THE WEBSITE, AND ALL MATERIALS AND SERVICES ACCESSIBLE THROUGH THE WEBSITE, ARE PROVIDED ON AN "AS IS" BASIS. GSL, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES RIGHTS AND FITNESS FOR A PARTICULAR PURPOSE. GSL, ITS AFFILIATES AND LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY, COMPLETENESS, SECURITY OR TIMELINESS OF THE SERVICES, CONTENT OR INFORMATION PROVIDED ON OR THROUGH THE USE OF THE WEBSITE. NO INFORMATION OBTAINED VIA THE WEBSITE SHALL CREATE ANY WARRANTY ON BEHALF OF GSL. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY LAST, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU',
        "GSLMAKESNOWARRANTYTHAT": "GSL MAKES NO WARRANTY THAT THE WEBSITE OR THE SERVICES PROVIDED VIA THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE, OR THAT THE WEBSITE OR ANY OF THE SERVICES, INFORMATION AND CONTENT PROVIDED THEREBY OR VIA THE APPLICABLE SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS",
        "AlthoughGSLusesreasonableattempts": "Although GSL uses reasonable attempts to exclude viruses or the like from the Website, it cannot ensure such exclusion. GSL assumes no responsibility, and shall not be liable for any damages to or viruses or the like that may infect your computer equipment or other property on account of your access to or use of or browsing of the Website or your downloading of any material, data, text, images or other from the Website and no liability is accepted for viruses or the like. Please take all appropriate safeguards to protect Your hardware, software and communication systems in connection with Your use or access of the Website",
        "LimitationofLiability": "Limitation of Liability",
        "TOTHEFULLESTEXTENTPERMITTED": "TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL GSL OR ANY OF ITS SUBSIDIARIES, AFFILIATES, STOCKHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, LICENSORS OR SUPPLIERS (THE “GSL PARTIES”) BE LIABLE, JOINTLY OR SEVERALLY, TO YOU OR ANY OTHER PERSON AS A RESULT OF YOUR USE OR ACCESS OF THE SERVICE, THE SITE OR ANY CONTENT APPEARING VIA THE SITE AND SERVICE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUE, PROFITS, USE, DATA OR OTHER INTANGIBLES (COLLECTIVELY, THE “EXCLUDED DAMAGES”), WHETHER OR NOT CHARACTERIZED IN NEGLIGENCE, TORT, CONTRACT OR OTHER THEORY OF LIABILITY, EVEN IF ANY OF THE GSL PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN ANY OF THE EXCLUDED DAMAGES, AND REGARDLESS OF ANY FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY. IF ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE UNENFORCEABLE, AND THEN THE LIABILITY OF THE GSL PARTIES SHALL BE LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY APPLICABLE LAW",
        "InternationalUsers": "International Users",
        "TheWebsitecanbeaccessedfrom": "The Website can be accessed from countries around the world and may contain references to GSL’s products, services, and programs that are not available in your country. These references do not imply that GSL intends to announce such products, services, or programs in your country. The Website is controlled and operated by GSL from its offices within Israel. GSL makes no representation that materials in the Website are appropriate or available for use in other locations, and access to them from territories where their contents are illegal is prohibited. Those who choose to access and use the Website from other locations do so, on their own initiative and are responsible for compliance with applicable local laws and regulations",
        "RemediesforViolations": "Remedies for Violations",
        "GSLreservestherighttoseek": "GSL reserves the right to seek all remedies available at law and in equity for violations of these Terms, including but not limited to the right to block access from a particular Internet address to the Website and any other provider and their features",
        "GoverningLawandJurisdiction": "Governing Law and Jurisdiction",
        "TheTermsaregoverned": "The Terms are governed by and construed in accordance with the laws of the State of Israel and any action arising out of or relating to these terms shall be filed only in the courts located in Haifa and you hereby consent and submit to the personal jurisdiction of such courts for the purpose of litigating any such action",
        "Revision": "Revision",
        "GSLreservestherighttochange": "GSL reserves the right to change, suspend or discontinue any aspect of the services and content provided through the Website at any time, without notice or liability. You should periodically check these Terms and Privacy Policy (which form and constitute an integral part hereof) posted on the Website to review the current terms and guidelines applicable to your use. Your continued use of the Website after such revisions constitutes your acceptance of these revisions",
        "General": "General",
        "TheseTermsconstitutetheentire": "These Terms constitute the entire agreement between you and GSL with reference to the Website. Use of the Website is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including without limitation this paragraph. In the event any provision of these Terms shall be deemed unenforceable, void or invalid, such provision shall be modified so as to make it valid and enforceable, and as so modified the entire Agreement shall remain in full force and effect. No failure or delay in exercising any right, power or privilege hereunder shall operate as a waiver thereof, nor shall any single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any right, power or privilege hereunder",
        "AdditionalInformationUpdates": "Additional Information, Updates and Questions",
        "Additionalinformationoncookiesingeneral": "Additional information on cookies in general",
        "Anumberofwebsitesprovide": "A number of websites provide detailed information on cookies, including AboutCookies.org and AllAboutCookies.org.",
        "UpdatestoourPolicy": "Updates to our Policy",
        "WeupdateourCookiesPolicyfromtimetotime": "We update our Cookies Policy from time to time, if we introduce a new cookie or tracking tool which processes new information or wish to process the information collected by your cookies and tracing tools in a new manner, we shall notify you and ask you to consent to this updated processing or new cookie or tool",
        "Adefaultconfigurationanduse": "A default configuration and use of our first-party cookies and third-party cookies used in the Personal Area are described in the table below",
       
       
    },
    "ko": {
        "Thefollowingtermsandconditions": 'The following terms and conditions of use constitute a legal agreement between you and Gold Star Line Ltd. ("GSL") regarding the access and use of the website (respectively the "Website" and "Terms"). By accessing, browsing and/or using the Website, you acknowledge that you have read, understood, and agree, to be bound by these terms and to comply with all applicable laws and regulations. If you do not agree to these terms, do not use the Website',
        "AccessandUseofthisWebsite": "Access and Use of this Website",
        "Youarepermittedtousedownload": "You are permitted to use, download, and print any page of the Website only for the purposes reasonably anticipated on the Website or as otherwise might reasonably be expected in the course of your relationship with GSL and in accordance with any procedures from time to time in force on the Website. Copying, reproducing, displaying, posting, downloading, re-transmission, re-publication, modification of the data or contents provided in the Website, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, is strictly prohibited without the prior written permission of GSL",
        "AsaconditionofyouruseoftheWebsite": "As a condition of your use of the Website, you will not use the Website for any purpose that is illegal or breaches any applicable laws or regulations or unlawful or otherwise prohibited by these Terms. You may not use the Website in any manner that could damage, disable, overburden, or impair any GSL systems, including GSL’s servers and/or the network(s) connected to any GSL server, or interfere with any other party's use and enjoyment of the Website. You may not attempt to gain unauthorized access to any services, accounts, systems or networks through hacking, password mining or any other means. You may not: (i) use or permit any other party to use all or any part of the Website, content or services in connection with activities that breach any applicable laws or regulations, infringe any third party’s rights, or breach any applicable standards, content requirements or codes; (ii) post to, upload to, temporarily store on (if such facility is provided) or transmit through, the Website any information, materials or content that might be or might encourage conduct that might be illegal, unlawful, threatening, abusive, defamatory, obscene, vulgar, discriminatory, pornographic, profane or indecent; (iii) use the Website for the purpose of or as a means to send or distribute software viruses, trojan horses, or any computer code, files, or programs designed to disrupt, destroy, invade, gain unauthorized access to, corrupt, observe or effect software or hardware",
        "IntellectualPropertyRights": "Intellectual Property Rights",
        "Ownershipofallcopyrights": "Ownership of all copyrights, database rights, patents, trade or service marks, product names or design rights (whether registered or unregistered), trade secrets and confidential information and any similar rights existing in any territory now or in future and similar rights and interests in all domain names, trademarks, logos, branding appearing on the Website and all content, or otherwise relating to the structure of the Website and the services offered by GSL via the Website, vests in GSL or its licensors",
        "InteractivityThirdPartyLinksandServiceProviders": "Interactivity, Third Party Links and Service Providers",
        "GSLmaymakeavailablecertain": 'GSL may make available certain features and/or services and/or hyperlinks, including certain interactive features which are provided by external and independent third parties ("Third Party Provider(s)"). You hereby acknowledge that the use of any such features and/or services and/or hyperlinks is subject to and governed by the applicable terms of use or such other agreement in connection therewith. GSL disclaims all warranties, express, implied or statutory, with respect to each Third-Party Provider and its respective feature and/or service, including but not limited to all warranties of merchantability, fitness for a particular purpose, title and non-infringement',
        "GSLmayprovidethroughtheWebsite": "GSL may provide through the Website certain interactive services and features, such as tracking and tracing of containers and shipping schedules. You acknowledge that the data provided through such interactive services and features is for information and convenience only and is not definitive. GSL does not warrant the accuracy or correctness of such data and any such data must be verified with applicable the local agents. Reliance on the information provided through the Website or its use is at Your own and sole responsibility",
        "MonitoringandUnlawfulActivity": "Monitoring and Unlawful Activity",
        "GSLhasnoobligationtomonitortheWebsite": "GSL has no obligation to monitor the Website; howeverGSL reserves the right to do so and prohibit or deny use or access at its sole discretion. Furthermore and without derogating from any other legal to which GSL may be entitled, GSL reserves the right to: (i) terminate or suspend your access to the entire Website or any part thereof at any time, without notice, for any reason whatsoever; (ii) restrict, edit or delete any materials provided or uploaded by You, without notice, for any reason whatsoever. GSL reserves the right to investigate complaints or reported violations of our Terms and to take any action we deem appropriate including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to user profiles, e-mail addresses, usage history, posted materials, IP addresses and traffic information",
        "AccuracyofthisWebsite": "Accuracy of this Website",
        "WhileGSLusescommerciallyreasonable": "While GSL uses commercially reasonable efforts to ensure that the information contained in the Website is accurate and up to date, the Website could include technical or other mistakes, inaccuracies or typographical errors. GSL does not warrant that the Content is accurate, sufficient, error free, complete or up to date at the time it is accessed. You should make further enquiries to satisfy yourself of the accuracy and completeness of any content before relying on it. Certain content may be labeled as indicative only, in which case GSL makes no warranties at all in relation to its quality, accuracy, completeness or timeliness",
        "InformativeData": "Informative Data",
        "Withoutderogatingfromtheaforesaid": "Without derogating from the aforesaid, you acknowledge that the information and/or data being offered or provided through the Website or its use, including without limitation with respect to shipment and material handling, tips and recommendations, information relating to local regulations etc., is purely informative in nature and provided for your convenience only without any representation or warranty by GSL. In no event will the information and/or data being offered or provided through the Website constitute professional, legal, or tax advice or full and accurate data",
        "Youacknowledgeandassumesole": "You acknowledge and assume sole and full responsibility for any liability or loss incurred as a consequence of the use, directly or indirectly, of any information the information and/or data being offered or provided through the Website. You hereby irrevocably and unconditionally waive any claim or demand regarding reliance on any of the information and/or data being offered or provided through the Website or its use",
        "Conflict": "Conflict",
        "Youherebyacknowledgeandconfirmthat": "You hereby acknowledge and confirm that in case of a conflict between these Terms or any other service information provided by the Website or its use and the information contained in the applicable GSL's internal documentation/agreement, the internal documentation/agreement will prevail",
        "Furthermoreandwithoutderogating": "Furthermore and without derogating from the aforesaid, the terms and conditions of GSL's Bill of Lading/Seaway Bill, including without limitation the timetables, governing law and jurisdiction clauses detailed therein shall prevail and supersede these Terms and/or any of the information provided through the Website or its use",
        "PersonalInformationandPrivacy": "Personal Information and Privacy",
        "GSLiscommittedtopreserving": "GSL is committed to preserving the privacy of the users in its Website. To learn about how GSL protects your personal information, such as your name and address, refer to GSL’s Privacy Policy. Except as set forth in the Privacy Policy or in these Terms, your personal information will be deemed to be confidential. Any non-personal information or material sent to GSL and/or provided by you through the Website will generally be deemed to NOT be confidential. You understand and agree that we may disclose information about you if we have a good faith belief that we are required to do so by law or legal process, to respond to claims, or to protect the rights, property, or safety of GSL or others, or as stated in our Privacy Policy",
        "DisclaimerofWarranties": "Disclaimer of Warranties",
        "EXCEPTASEXPRESSLYPROVIDEDOTHERWISE": 'EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AGREEMENT BETWEEN YOU AND GSL, THE WEBSITE, AND ALL MATERIALS AND SERVICES ACCESSIBLE THROUGH THE WEBSITE, ARE PROVIDED ON AN "AS IS" BASIS. GSL, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES RIGHTS AND FITNESS FOR A PARTICULAR PURPOSE. GSL, ITS AFFILIATES AND LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY, COMPLETENESS, SECURITY OR TIMELINESS OF THE SERVICES, CONTENT OR INFORMATION PROVIDED ON OR THROUGH THE USE OF THE WEBSITE. NO INFORMATION OBTAINED VIA THE WEBSITE SHALL CREATE ANY WARRANTY ON BEHALF OF GSL. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY LAST, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU',
        "GSLMAKESNOWARRANTYTHAT": "GSL MAKES NO WARRANTY THAT THE WEBSITE OR THE SERVICES PROVIDED VIA THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE, OR THAT THE WEBSITE OR ANY OF THE SERVICES, INFORMATION AND CONTENT PROVIDED THEREBY OR VIA THE APPLICABLE SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS",
        "AlthoughGSLusesreasonableattempts": "Although GSL uses reasonable attempts to exclude viruses or the like from the Website, it cannot ensure such exclusion. GSL assumes no responsibility, and shall not be liable for any damages to or viruses or the like that may infect your computer equipment or other property on account of your access to or use of or browsing of the Website or your downloading of any material, data, text, images or other from the Website and no liability is accepted for viruses or the like. Please take all appropriate safeguards to protect Your hardware, software and communication systems in connection with Your use or access of the Website",
        "LimitationofLiability": "Limitation of Liability",
        "TOTHEFULLESTEXTENTPERMITTED": "TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL GSL OR ANY OF ITS SUBSIDIARIES, AFFILIATES, STOCKHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, LICENSORS OR SUPPLIERS (THE “GSL PARTIES”) BE LIABLE, JOINTLY OR SEVERALLY, TO YOU OR ANY OTHER PERSON AS A RESULT OF YOUR USE OR ACCESS OF THE SERVICE, THE SITE OR ANY CONTENT APPEARING VIA THE SITE AND SERVICE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUE, PROFITS, USE, DATA OR OTHER INTANGIBLES (COLLECTIVELY, THE “EXCLUDED DAMAGES”), WHETHER OR NOT CHARACTERIZED IN NEGLIGENCE, TORT, CONTRACT OR OTHER THEORY OF LIABILITY, EVEN IF ANY OF THE GSL PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN ANY OF THE EXCLUDED DAMAGES, AND REGARDLESS OF ANY FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY. IF ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE UNENFORCEABLE, AND THEN THE LIABILITY OF THE GSL PARTIES SHALL BE LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY APPLICABLE LAW",
        "InternationalUsers": "International Users",
        "TheWebsitecanbeaccessedfrom": "The Website can be accessed from countries around the world and may contain references to GSL’s products, services, and programs that are not available in your country. These references do not imply that GSL intends to announce such products, services, or programs in your country. The Website is controlled and operated by GSL from its offices within Israel. GSL makes no representation that materials in the Website are appropriate or available for use in other locations, and access to them from territories where their contents are illegal is prohibited. Those who choose to access and use the Website from other locations do so, on their own initiative and are responsible for compliance with applicable local laws and regulations",
        "RemediesforViolations": "Remedies for Violations",
        "GSLreservestherighttoseek": "GSL reserves the right to seek all remedies available at law and in equity for violations of these Terms, including but not limited to the right to block access from a particular Internet address to the Website and any other provider and their features",
        "GoverningLawandJurisdiction": "Governing Law and Jurisdiction",
        "TheTermsaregoverned": "The Terms are governed by and construed in accordance with the laws of the State of Israel and any action arising out of or relating to these terms shall be filed only in the courts located in Haifa and you hereby consent and submit to the personal jurisdiction of such courts for the purpose of litigating any such action",
        "Revision": "Revision",
        "GSLreservestherighttochange": "GSL reserves the right to change, suspend or discontinue any aspect of the services and content provided through the Website at any time, without notice or liability. You should periodically check these Terms and Privacy Policy (which form and constitute an integral part hereof) posted on the Website to review the current terms and guidelines applicable to your use. Your continued use of the Website after such revisions constitutes your acceptance of these revisions",
        "General": "General",
        "TheseTermsconstitutetheentire": "These Terms constitute the entire agreement between you and GSL with reference to the Website. Use of the Website is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including without limitation this paragraph. In the event any provision of these Terms shall be deemed unenforceable, void or invalid, such provision shall be modified so as to make it valid and enforceable, and as so modified the entire Agreement shall remain in full force and effect. No failure or delay in exercising any right, power or privilege hereunder shall operate as a waiver thereof, nor shall any single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any right, power or privilege hereunder",
        "AdditionalInformationUpdates": "Additional Information, Updates and Questions",
        "Additionalinformationoncookiesingeneral": "Additional information on cookies in general",
        "Anumberofwebsitesprovide": "A number of websites provide detailed information on cookies, including AboutCookies.org and AllAboutCookies.org.",
        "UpdatestoourPolicy": "Updates to our Policy",
        "WeupdateourCookiesPolicyfromtimetotime": "We update our Cookies Policy from time to time, if we introduce a new cookie or tracking tool which processes new information or wish to process the information collected by your cookies and tracing tools in a new manner, we shall notify you and ask you to consent to this updated processing or new cookie or tool",
        "Adefaultconfigurationanduse": "A default configuration and use of our first-party cookies and third-party cookies used in the Personal Area are described in the table below",
       
       
    },
    "vi": {
        "Thefollowingtermsandconditions": 'The following terms and conditions of use constitute a legal agreement between you and Gold Star Line Ltd. ("GSL") regarding the access and use of the website (respectively the "Website" and "Terms"). By accessing, browsing and/or using the Website, you acknowledge that you have read, understood, and agree, to be bound by these terms and to comply with all applicable laws and regulations. If you do not agree to these terms, do not use the Website',
        "AccessandUseofthisWebsite": "Access and Use of this Website",
        "Youarepermittedtousedownload": "You are permitted to use, download, and print any page of the Website only for the purposes reasonably anticipated on the Website or as otherwise might reasonably be expected in the course of your relationship with GSL and in accordance with any procedures from time to time in force on the Website. Copying, reproducing, displaying, posting, downloading, re-transmission, re-publication, modification of the data or contents provided in the Website, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, is strictly prohibited without the prior written permission of GSL",
        "AsaconditionofyouruseoftheWebsite": "As a condition of your use of the Website, you will not use the Website for any purpose that is illegal or breaches any applicable laws or regulations or unlawful or otherwise prohibited by these Terms. You may not use the Website in any manner that could damage, disable, overburden, or impair any GSL systems, including GSL’s servers and/or the network(s) connected to any GSL server, or interfere with any other party's use and enjoyment of the Website. You may not attempt to gain unauthorized access to any services, accounts, systems or networks through hacking, password mining or any other means. You may not: (i) use or permit any other party to use all or any part of the Website, content or services in connection with activities that breach any applicable laws or regulations, infringe any third party’s rights, or breach any applicable standards, content requirements or codes; (ii) post to, upload to, temporarily store on (if such facility is provided) or transmit through, the Website any information, materials or content that might be or might encourage conduct that might be illegal, unlawful, threatening, abusive, defamatory, obscene, vulgar, discriminatory, pornographic, profane or indecent; (iii) use the Website for the purpose of or as a means to send or distribute software viruses, trojan horses, or any computer code, files, or programs designed to disrupt, destroy, invade, gain unauthorized access to, corrupt, observe or effect software or hardware",
        "IntellectualPropertyRights": "Intellectual Property Rights",
        "Ownershipofallcopyrights": "Ownership of all copyrights, database rights, patents, trade or service marks, product names or design rights (whether registered or unregistered), trade secrets and confidential information and any similar rights existing in any territory now or in future and similar rights and interests in all domain names, trademarks, logos, branding appearing on the Website and all content, or otherwise relating to the structure of the Website and the services offered by GSL via the Website, vests in GSL or its licensors",
        "InteractivityThirdPartyLinksandServiceProviders": "Interactivity, Third Party Links and Service Providers",
        "GSLmaymakeavailablecertain": 'GSL may make available certain features and/or services and/or hyperlinks, including certain interactive features which are provided by external and independent third parties ("Third Party Provider(s)"). You hereby acknowledge that the use of any such features and/or services and/or hyperlinks is subject to and governed by the applicable terms of use or such other agreement in connection therewith. GSL disclaims all warranties, express, implied or statutory, with respect to each Third-Party Provider and its respective feature and/or service, including but not limited to all warranties of merchantability, fitness for a particular purpose, title and non-infringement',
        "GSLmayprovidethroughtheWebsite": "GSL may provide through the Website certain interactive services and features, such as tracking and tracing of containers and shipping schedules. You acknowledge that the data provided through such interactive services and features is for information and convenience only and is not definitive. GSL does not warrant the accuracy or correctness of such data and any such data must be verified with applicable the local agents. Reliance on the information provided through the Website or its use is at Your own and sole responsibility",
        "MonitoringandUnlawfulActivity": "Monitoring and Unlawful Activity",
        "GSLhasnoobligationtomonitortheWebsite": "GSL has no obligation to monitor the Website; howeverGSL reserves the right to do so and prohibit or deny use or access at its sole discretion. Furthermore and without derogating from any other legal to which GSL may be entitled, GSL reserves the right to: (i) terminate or suspend your access to the entire Website or any part thereof at any time, without notice, for any reason whatsoever; (ii) restrict, edit or delete any materials provided or uploaded by You, without notice, for any reason whatsoever. GSL reserves the right to investigate complaints or reported violations of our Terms and to take any action we deem appropriate including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to user profiles, e-mail addresses, usage history, posted materials, IP addresses and traffic information",
        "AccuracyofthisWebsite": "Accuracy of this Website",
        "WhileGSLusescommerciallyreasonable": "While GSL uses commercially reasonable efforts to ensure that the information contained in the Website is accurate and up to date, the Website could include technical or other mistakes, inaccuracies or typographical errors. GSL does not warrant that the Content is accurate, sufficient, error free, complete or up to date at the time it is accessed. You should make further enquiries to satisfy yourself of the accuracy and completeness of any content before relying on it. Certain content may be labeled as indicative only, in which case GSL makes no warranties at all in relation to its quality, accuracy, completeness or timeliness",
        "InformativeData": "Informative Data",
        "Withoutderogatingfromtheaforesaid": "Without derogating from the aforesaid, you acknowledge that the information and/or data being offered or provided through the Website or its use, including without limitation with respect to shipment and material handling, tips and recommendations, information relating to local regulations etc., is purely informative in nature and provided for your convenience only without any representation or warranty by GSL. In no event will the information and/or data being offered or provided through the Website constitute professional, legal, or tax advice or full and accurate data",
        "Youacknowledgeandassumesole": "You acknowledge and assume sole and full responsibility for any liability or loss incurred as a consequence of the use, directly or indirectly, of any information the information and/or data being offered or provided through the Website. You hereby irrevocably and unconditionally waive any claim or demand regarding reliance on any of the information and/or data being offered or provided through the Website or its use",
        "Conflict": "Conflict",
        "Youherebyacknowledgeandconfirmthat": "You hereby acknowledge and confirm that in case of a conflict between these Terms or any other service information provided by the Website or its use and the information contained in the applicable GSL's internal documentation/agreement, the internal documentation/agreement will prevail",
        "Furthermoreandwithoutderogating": "Furthermore and without derogating from the aforesaid, the terms and conditions of GSL's Bill of Lading/Seaway Bill, including without limitation the timetables, governing law and jurisdiction clauses detailed therein shall prevail and supersede these Terms and/or any of the information provided through the Website or its use",
        "PersonalInformationandPrivacy": "Personal Information and Privacy",
        "GSLiscommittedtopreserving": "GSL is committed to preserving the privacy of the users in its Website. To learn about how GSL protects your personal information, such as your name and address, refer to GSL’s Privacy Policy. Except as set forth in the Privacy Policy or in these Terms, your personal information will be deemed to be confidential. Any non-personal information or material sent to GSL and/or provided by you through the Website will generally be deemed to NOT be confidential. You understand and agree that we may disclose information about you if we have a good faith belief that we are required to do so by law or legal process, to respond to claims, or to protect the rights, property, or safety of GSL or others, or as stated in our Privacy Policy",
        "DisclaimerofWarranties": "Disclaimer of Warranties",
        "EXCEPTASEXPRESSLYPROVIDEDOTHERWISE": 'EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AGREEMENT BETWEEN YOU AND GSL, THE WEBSITE, AND ALL MATERIALS AND SERVICES ACCESSIBLE THROUGH THE WEBSITE, ARE PROVIDED ON AN "AS IS" BASIS. GSL, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES RIGHTS AND FITNESS FOR A PARTICULAR PURPOSE. GSL, ITS AFFILIATES AND LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY, COMPLETENESS, SECURITY OR TIMELINESS OF THE SERVICES, CONTENT OR INFORMATION PROVIDED ON OR THROUGH THE USE OF THE WEBSITE. NO INFORMATION OBTAINED VIA THE WEBSITE SHALL CREATE ANY WARRANTY ON BEHALF OF GSL. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY LAST, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU',
        "GSLMAKESNOWARRANTYTHAT": "GSL MAKES NO WARRANTY THAT THE WEBSITE OR THE SERVICES PROVIDED VIA THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE, OR THAT THE WEBSITE OR ANY OF THE SERVICES, INFORMATION AND CONTENT PROVIDED THEREBY OR VIA THE APPLICABLE SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS",
        "AlthoughGSLusesreasonableattempts": "Although GSL uses reasonable attempts to exclude viruses or the like from the Website, it cannot ensure such exclusion. GSL assumes no responsibility, and shall not be liable for any damages to or viruses or the like that may infect your computer equipment or other property on account of your access to or use of or browsing of the Website or your downloading of any material, data, text, images or other from the Website and no liability is accepted for viruses or the like. Please take all appropriate safeguards to protect Your hardware, software and communication systems in connection with Your use or access of the Website",
        "LimitationofLiability": "Limitation of Liability",
        "TOTHEFULLESTEXTENTPERMITTED": "TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL GSL OR ANY OF ITS SUBSIDIARIES, AFFILIATES, STOCKHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, LICENSORS OR SUPPLIERS (THE “GSL PARTIES”) BE LIABLE, JOINTLY OR SEVERALLY, TO YOU OR ANY OTHER PERSON AS A RESULT OF YOUR USE OR ACCESS OF THE SERVICE, THE SITE OR ANY CONTENT APPEARING VIA THE SITE AND SERVICE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUE, PROFITS, USE, DATA OR OTHER INTANGIBLES (COLLECTIVELY, THE “EXCLUDED DAMAGES”), WHETHER OR NOT CHARACTERIZED IN NEGLIGENCE, TORT, CONTRACT OR OTHER THEORY OF LIABILITY, EVEN IF ANY OF THE GSL PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN ANY OF THE EXCLUDED DAMAGES, AND REGARDLESS OF ANY FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY. IF ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE UNENFORCEABLE, AND THEN THE LIABILITY OF THE GSL PARTIES SHALL BE LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY APPLICABLE LAW",
        "InternationalUsers": "International Users",
        "TheWebsitecanbeaccessedfrom": "The Website can be accessed from countries around the world and may contain references to GSL’s products, services, and programs that are not available in your country. These references do not imply that GSL intends to announce such products, services, or programs in your country. The Website is controlled and operated by GSL from its offices within Israel. GSL makes no representation that materials in the Website are appropriate or available for use in other locations, and access to them from territories where their contents are illegal is prohibited. Those who choose to access and use the Website from other locations do so, on their own initiative and are responsible for compliance with applicable local laws and regulations",
        "RemediesforViolations": "Remedies for Violations",
        "GSLreservestherighttoseek": "GSL reserves the right to seek all remedies available at law and in equity for violations of these Terms, including but not limited to the right to block access from a particular Internet address to the Website and any other provider and their features",
        "GoverningLawandJurisdiction": "Governing Law and Jurisdiction",
        "TheTermsaregoverned": "The Terms are governed by and construed in accordance with the laws of the State of Israel and any action arising out of or relating to these terms shall be filed only in the courts located in Haifa and you hereby consent and submit to the personal jurisdiction of such courts for the purpose of litigating any such action",
        "Revision": "Revision",
        "GSLreservestherighttochange": "GSL reserves the right to change, suspend or discontinue any aspect of the services and content provided through the Website at any time, without notice or liability. You should periodically check these Terms and Privacy Policy (which form and constitute an integral part hereof) posted on the Website to review the current terms and guidelines applicable to your use. Your continued use of the Website after such revisions constitutes your acceptance of these revisions",
        "General": "General",
        "TheseTermsconstitutetheentire": "These Terms constitute the entire agreement between you and GSL with reference to the Website. Use of the Website is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including without limitation this paragraph. In the event any provision of these Terms shall be deemed unenforceable, void or invalid, such provision shall be modified so as to make it valid and enforceable, and as so modified the entire Agreement shall remain in full force and effect. No failure or delay in exercising any right, power or privilege hereunder shall operate as a waiver thereof, nor shall any single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any right, power or privilege hereunder",
        "AdditionalInformationUpdates": "Additional Information, Updates and Questions",
        "Additionalinformationoncookiesingeneral": "Additional information on cookies in general",
        "Anumberofwebsitesprovide": "A number of websites provide detailed information on cookies, including AboutCookies.org and AllAboutCookies.org.",
        "UpdatestoourPolicy": "Updates to our Policy",
        "WeupdateourCookiesPolicyfromtimetotime": "We update our Cookies Policy from time to time, if we introduce a new cookie or tracking tool which processes new information or wish to process the information collected by your cookies and tracing tools in a new manner, we shall notify you and ask you to consent to this updated processing or new cookie or tool",
        "Adefaultconfigurationanduse": "A default configuration and use of our first-party cookies and third-party cookies used in the Personal Area are described in the table below",
       
       
    },
    "th": {
        "Thefollowingtermsandconditions": 'The following terms and conditions of use constitute a legal agreement between you and Gold Star Line Ltd. ("GSL") regarding the access and use of the website (respectively the "Website" and "Terms"). By accessing, browsing and/or using the Website, you acknowledge that you have read, understood, and agree, to be bound by these terms and to comply with all applicable laws and regulations. If you do not agree to these terms, do not use the Website',
        "AccessandUseofthisWebsite": "Access and Use of this Website",
        "Youarepermittedtousedownload": "You are permitted to use, download, and print any page of the Website only for the purposes reasonably anticipated on the Website or as otherwise might reasonably be expected in the course of your relationship with GSL and in accordance with any procedures from time to time in force on the Website. Copying, reproducing, displaying, posting, downloading, re-transmission, re-publication, modification of the data or contents provided in the Website, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, is strictly prohibited without the prior written permission of GSL",
        "AsaconditionofyouruseoftheWebsite": "As a condition of your use of the Website, you will not use the Website for any purpose that is illegal or breaches any applicable laws or regulations or unlawful or otherwise prohibited by these Terms. You may not use the Website in any manner that could damage, disable, overburden, or impair any GSL systems, including GSL’s servers and/or the network(s) connected to any GSL server, or interfere with any other party's use and enjoyment of the Website. You may not attempt to gain unauthorized access to any services, accounts, systems or networks through hacking, password mining or any other means. You may not: (i) use or permit any other party to use all or any part of the Website, content or services in connection with activities that breach any applicable laws or regulations, infringe any third party’s rights, or breach any applicable standards, content requirements or codes; (ii) post to, upload to, temporarily store on (if such facility is provided) or transmit through, the Website any information, materials or content that might be or might encourage conduct that might be illegal, unlawful, threatening, abusive, defamatory, obscene, vulgar, discriminatory, pornographic, profane or indecent; (iii) use the Website for the purpose of or as a means to send or distribute software viruses, trojan horses, or any computer code, files, or programs designed to disrupt, destroy, invade, gain unauthorized access to, corrupt, observe or effect software or hardware",
        "IntellectualPropertyRights": "Intellectual Property Rights",
        "Ownershipofallcopyrights": "Ownership of all copyrights, database rights, patents, trade or service marks, product names or design rights (whether registered or unregistered), trade secrets and confidential information and any similar rights existing in any territory now or in future and similar rights and interests in all domain names, trademarks, logos, branding appearing on the Website and all content, or otherwise relating to the structure of the Website and the services offered by GSL via the Website, vests in GSL or its licensors",
        "InteractivityThirdPartyLinksandServiceProviders": "Interactivity, Third Party Links and Service Providers",
        "GSLmaymakeavailablecertain": 'GSL may make available certain features and/or services and/or hyperlinks, including certain interactive features which are provided by external and independent third parties ("Third Party Provider(s)"). You hereby acknowledge that the use of any such features and/or services and/or hyperlinks is subject to and governed by the applicable terms of use or such other agreement in connection therewith. GSL disclaims all warranties, express, implied or statutory, with respect to each Third-Party Provider and its respective feature and/or service, including but not limited to all warranties of merchantability, fitness for a particular purpose, title and non-infringement',
        "GSLmayprovidethroughtheWebsite": "GSL may provide through the Website certain interactive services and features, such as tracking and tracing of containers and shipping schedules. You acknowledge that the data provided through such interactive services and features is for information and convenience only and is not definitive. GSL does not warrant the accuracy or correctness of such data and any such data must be verified with applicable the local agents. Reliance on the information provided through the Website or its use is at Your own and sole responsibility",
        "MonitoringandUnlawfulActivity": "Monitoring and Unlawful Activity",
        "GSLhasnoobligationtomonitortheWebsite": "GSL has no obligation to monitor the Website; howeverGSL reserves the right to do so and prohibit or deny use or access at its sole discretion. Furthermore and without derogating from any other legal to which GSL may be entitled, GSL reserves the right to: (i) terminate or suspend your access to the entire Website or any part thereof at any time, without notice, for any reason whatsoever; (ii) restrict, edit or delete any materials provided or uploaded by You, without notice, for any reason whatsoever. GSL reserves the right to investigate complaints or reported violations of our Terms and to take any action we deem appropriate including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to user profiles, e-mail addresses, usage history, posted materials, IP addresses and traffic information",
        "AccuracyofthisWebsite": "Accuracy of this Website",
        "WhileGSLusescommerciallyreasonable": "While GSL uses commercially reasonable efforts to ensure that the information contained in the Website is accurate and up to date, the Website could include technical or other mistakes, inaccuracies or typographical errors. GSL does not warrant that the Content is accurate, sufficient, error free, complete or up to date at the time it is accessed. You should make further enquiries to satisfy yourself of the accuracy and completeness of any content before relying on it. Certain content may be labeled as indicative only, in which case GSL makes no warranties at all in relation to its quality, accuracy, completeness or timeliness",
        "InformativeData": "Informative Data",
        "Withoutderogatingfromtheaforesaid": "Without derogating from the aforesaid, you acknowledge that the information and/or data being offered or provided through the Website or its use, including without limitation with respect to shipment and material handling, tips and recommendations, information relating to local regulations etc., is purely informative in nature and provided for your convenience only without any representation or warranty by GSL. In no event will the information and/or data being offered or provided through the Website constitute professional, legal, or tax advice or full and accurate data",
        "Youacknowledgeandassumesole": "You acknowledge and assume sole and full responsibility for any liability or loss incurred as a consequence of the use, directly or indirectly, of any information the information and/or data being offered or provided through the Website. You hereby irrevocably and unconditionally waive any claim or demand regarding reliance on any of the information and/or data being offered or provided through the Website or its use",
        "Conflict": "Conflict",
        "Youherebyacknowledgeandconfirmthat": "You hereby acknowledge and confirm that in case of a conflict between these Terms or any other service information provided by the Website or its use and the information contained in the applicable GSL's internal documentation/agreement, the internal documentation/agreement will prevail",
        "Furthermoreandwithoutderogating": "Furthermore and without derogating from the aforesaid, the terms and conditions of GSL's Bill of Lading/Seaway Bill, including without limitation the timetables, governing law and jurisdiction clauses detailed therein shall prevail and supersede these Terms and/or any of the information provided through the Website or its use",
        "PersonalInformationandPrivacy": "Personal Information and Privacy",
        "GSLiscommittedtopreserving": "GSL is committed to preserving the privacy of the users in its Website. To learn about how GSL protects your personal information, such as your name and address, refer to GSL’s Privacy Policy. Except as set forth in the Privacy Policy or in these Terms, your personal information will be deemed to be confidential. Any non-personal information or material sent to GSL and/or provided by you through the Website will generally be deemed to NOT be confidential. You understand and agree that we may disclose information about you if we have a good faith belief that we are required to do so by law or legal process, to respond to claims, or to protect the rights, property, or safety of GSL or others, or as stated in our Privacy Policy",
        "DisclaimerofWarranties": "Disclaimer of Warranties",
        "EXCEPTASEXPRESSLYPROVIDEDOTHERWISE": 'EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AGREEMENT BETWEEN YOU AND GSL, THE WEBSITE, AND ALL MATERIALS AND SERVICES ACCESSIBLE THROUGH THE WEBSITE, ARE PROVIDED ON AN "AS IS" BASIS. GSL, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES RIGHTS AND FITNESS FOR A PARTICULAR PURPOSE. GSL, ITS AFFILIATES AND LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY, COMPLETENESS, SECURITY OR TIMELINESS OF THE SERVICES, CONTENT OR INFORMATION PROVIDED ON OR THROUGH THE USE OF THE WEBSITE. NO INFORMATION OBTAINED VIA THE WEBSITE SHALL CREATE ANY WARRANTY ON BEHALF OF GSL. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY LAST, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU',
        "GSLMAKESNOWARRANTYTHAT": "GSL MAKES NO WARRANTY THAT THE WEBSITE OR THE SERVICES PROVIDED VIA THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE, OR THAT THE WEBSITE OR ANY OF THE SERVICES, INFORMATION AND CONTENT PROVIDED THEREBY OR VIA THE APPLICABLE SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS",
        "AlthoughGSLusesreasonableattempts": "Although GSL uses reasonable attempts to exclude viruses or the like from the Website, it cannot ensure such exclusion. GSL assumes no responsibility, and shall not be liable for any damages to or viruses or the like that may infect your computer equipment or other property on account of your access to or use of or browsing of the Website or your downloading of any material, data, text, images or other from the Website and no liability is accepted for viruses or the like. Please take all appropriate safeguards to protect Your hardware, software and communication systems in connection with Your use or access of the Website",
        "LimitationofLiability": "Limitation of Liability",
        "TOTHEFULLESTEXTENTPERMITTED": "TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL GSL OR ANY OF ITS SUBSIDIARIES, AFFILIATES, STOCKHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, LICENSORS OR SUPPLIERS (THE “GSL PARTIES”) BE LIABLE, JOINTLY OR SEVERALLY, TO YOU OR ANY OTHER PERSON AS A RESULT OF YOUR USE OR ACCESS OF THE SERVICE, THE SITE OR ANY CONTENT APPEARING VIA THE SITE AND SERVICE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUE, PROFITS, USE, DATA OR OTHER INTANGIBLES (COLLECTIVELY, THE “EXCLUDED DAMAGES”), WHETHER OR NOT CHARACTERIZED IN NEGLIGENCE, TORT, CONTRACT OR OTHER THEORY OF LIABILITY, EVEN IF ANY OF THE GSL PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN ANY OF THE EXCLUDED DAMAGES, AND REGARDLESS OF ANY FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY. IF ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE UNENFORCEABLE, AND THEN THE LIABILITY OF THE GSL PARTIES SHALL BE LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY APPLICABLE LAW",
        "InternationalUsers": "International Users",
        "TheWebsitecanbeaccessedfrom": "The Website can be accessed from countries around the world and may contain references to GSL’s products, services, and programs that are not available in your country. These references do not imply that GSL intends to announce such products, services, or programs in your country. The Website is controlled and operated by GSL from its offices within Israel. GSL makes no representation that materials in the Website are appropriate or available for use in other locations, and access to them from territories where their contents are illegal is prohibited. Those who choose to access and use the Website from other locations do so, on their own initiative and are responsible for compliance with applicable local laws and regulations",
        "RemediesforViolations": "Remedies for Violations",
        "GSLreservestherighttoseek": "GSL reserves the right to seek all remedies available at law and in equity for violations of these Terms, including but not limited to the right to block access from a particular Internet address to the Website and any other provider and their features",
        "GoverningLawandJurisdiction": "Governing Law and Jurisdiction",
        "TheTermsaregoverned": "The Terms are governed by and construed in accordance with the laws of the State of Israel and any action arising out of or relating to these terms shall be filed only in the courts located in Haifa and you hereby consent and submit to the personal jurisdiction of such courts for the purpose of litigating any such action",
        "Revision": "Revision",
        "GSLreservestherighttochange": "GSL reserves the right to change, suspend or discontinue any aspect of the services and content provided through the Website at any time, without notice or liability. You should periodically check these Terms and Privacy Policy (which form and constitute an integral part hereof) posted on the Website to review the current terms and guidelines applicable to your use. Your continued use of the Website after such revisions constitutes your acceptance of these revisions",
        "General": "General",
        "TheseTermsconstitutetheentire": "These Terms constitute the entire agreement between you and GSL with reference to the Website. Use of the Website is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including without limitation this paragraph. In the event any provision of these Terms shall be deemed unenforceable, void or invalid, such provision shall be modified so as to make it valid and enforceable, and as so modified the entire Agreement shall remain in full force and effect. No failure or delay in exercising any right, power or privilege hereunder shall operate as a waiver thereof, nor shall any single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any right, power or privilege hereunder",
        "AdditionalInformationUpdates": "Additional Information, Updates and Questions",
        "Additionalinformationoncookiesingeneral": "Additional information on cookies in general",
        "Anumberofwebsitesprovide": "A number of websites provide detailed information on cookies, including AboutCookies.org and AllAboutCookies.org.",
        "UpdatestoourPolicy": "Updates to our Policy",
        "WeupdateourCookiesPolicyfromtimetotime": "We update our Cookies Policy from time to time, if we introduce a new cookie or tracking tool which processes new information or wish to process the information collected by your cookies and tracing tools in a new manner, we shall notify you and ask you to consent to this updated processing or new cookie or tool",
        "Adefaultconfigurationanduse": "A default configuration and use of our first-party cookies and third-party cookies used in the Personal Area are described in the table below",
       
       
    },
    "in": {
        "Thefollowingtermsandconditions": 'The following terms and conditions of use constitute a legal agreement between you and Gold Star Line Ltd. ("GSL") regarding the access and use of the website (respectively the "Website" and "Terms"). By accessing, browsing and/or using the Website, you acknowledge that you have read, understood, and agree, to be bound by these terms and to comply with all applicable laws and regulations. If you do not agree to these terms, do not use the Website',
        "AccessandUseofthisWebsite": "Access and Use of this Website",
        "Youarepermittedtousedownload": "You are permitted to use, download, and print any page of the Website only for the purposes reasonably anticipated on the Website or as otherwise might reasonably be expected in the course of your relationship with GSL and in accordance with any procedures from time to time in force on the Website. Copying, reproducing, displaying, posting, downloading, re-transmission, re-publication, modification of the data or contents provided in the Website, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, is strictly prohibited without the prior written permission of GSL",
        "AsaconditionofyouruseoftheWebsite": "As a condition of your use of the Website, you will not use the Website for any purpose that is illegal or breaches any applicable laws or regulations or unlawful or otherwise prohibited by these Terms. You may not use the Website in any manner that could damage, disable, overburden, or impair any GSL systems, including GSL’s servers and/or the network(s) connected to any GSL server, or interfere with any other party's use and enjoyment of the Website. You may not attempt to gain unauthorized access to any services, accounts, systems or networks through hacking, password mining or any other means. You may not: (i) use or permit any other party to use all or any part of the Website, content or services in connection with activities that breach any applicable laws or regulations, infringe any third party’s rights, or breach any applicable standards, content requirements or codes; (ii) post to, upload to, temporarily store on (if such facility is provided) or transmit through, the Website any information, materials or content that might be or might encourage conduct that might be illegal, unlawful, threatening, abusive, defamatory, obscene, vulgar, discriminatory, pornographic, profane or indecent; (iii) use the Website for the purpose of or as a means to send or distribute software viruses, trojan horses, or any computer code, files, or programs designed to disrupt, destroy, invade, gain unauthorized access to, corrupt, observe or effect software or hardware",
        "IntellectualPropertyRights": "Intellectual Property Rights",
        "Ownershipofallcopyrights": "Ownership of all copyrights, database rights, patents, trade or service marks, product names or design rights (whether registered or unregistered), trade secrets and confidential information and any similar rights existing in any territory now or in future and similar rights and interests in all domain names, trademarks, logos, branding appearing on the Website and all content, or otherwise relating to the structure of the Website and the services offered by GSL via the Website, vests in GSL or its licensors",
        "InteractivityThirdPartyLinksandServiceProviders": "Interactivity, Third Party Links and Service Providers",
        "GSLmaymakeavailablecertain": 'GSL may make available certain features and/or services and/or hyperlinks, including certain interactive features which are provided by external and independent third parties ("Third Party Provider(s)"). You hereby acknowledge that the use of any such features and/or services and/or hyperlinks is subject to and governed by the applicable terms of use or such other agreement in connection therewith. GSL disclaims all warranties, express, implied or statutory, with respect to each Third-Party Provider and its respective feature and/or service, including but not limited to all warranties of merchantability, fitness for a particular purpose, title and non-infringement',
        "GSLmayprovidethroughtheWebsite": "GSL may provide through the Website certain interactive services and features, such as tracking and tracing of containers and shipping schedules. You acknowledge that the data provided through such interactive services and features is for information and convenience only and is not definitive. GSL does not warrant the accuracy or correctness of such data and any such data must be verified with applicable the local agents. Reliance on the information provided through the Website or its use is at Your own and sole responsibility",
        "MonitoringandUnlawfulActivity": "Monitoring and Unlawful Activity",
        "GSLhasnoobligationtomonitortheWebsite": "GSL has no obligation to monitor the Website; howeverGSL reserves the right to do so and prohibit or deny use or access at its sole discretion. Furthermore and without derogating from any other legal to which GSL may be entitled, GSL reserves the right to: (i) terminate or suspend your access to the entire Website or any part thereof at any time, without notice, for any reason whatsoever; (ii) restrict, edit or delete any materials provided or uploaded by You, without notice, for any reason whatsoever. GSL reserves the right to investigate complaints or reported violations of our Terms and to take any action we deem appropriate including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to user profiles, e-mail addresses, usage history, posted materials, IP addresses and traffic information",
        "AccuracyofthisWebsite": "Accuracy of this Website",
        "WhileGSLusescommerciallyreasonable": "While GSL uses commercially reasonable efforts to ensure that the information contained in the Website is accurate and up to date, the Website could include technical or other mistakes, inaccuracies or typographical errors. GSL does not warrant that the Content is accurate, sufficient, error free, complete or up to date at the time it is accessed. You should make further enquiries to satisfy yourself of the accuracy and completeness of any content before relying on it. Certain content may be labeled as indicative only, in which case GSL makes no warranties at all in relation to its quality, accuracy, completeness or timeliness",
        "InformativeData": "Informative Data",
        "Withoutderogatingfromtheaforesaid": "Without derogating from the aforesaid, you acknowledge that the information and/or data being offered or provided through the Website or its use, including without limitation with respect to shipment and material handling, tips and recommendations, information relating to local regulations etc., is purely informative in nature and provided for your convenience only without any representation or warranty by GSL. In no event will the information and/or data being offered or provided through the Website constitute professional, legal, or tax advice or full and accurate data",
        "Youacknowledgeandassumesole": "You acknowledge and assume sole and full responsibility for any liability or loss incurred as a consequence of the use, directly or indirectly, of any information the information and/or data being offered or provided through the Website. You hereby irrevocably and unconditionally waive any claim or demand regarding reliance on any of the information and/or data being offered or provided through the Website or its use",
        "Conflict": "Conflict",
        "Youherebyacknowledgeandconfirmthat": "You hereby acknowledge and confirm that in case of a conflict between these Terms or any other service information provided by the Website or its use and the information contained in the applicable GSL's internal documentation/agreement, the internal documentation/agreement will prevail",
        "Furthermoreandwithoutderogating": "Furthermore and without derogating from the aforesaid, the terms and conditions of GSL's Bill of Lading/Seaway Bill, including without limitation the timetables, governing law and jurisdiction clauses detailed therein shall prevail and supersede these Terms and/or any of the information provided through the Website or its use",
        "PersonalInformationandPrivacy": "Personal Information and Privacy",
        "GSLiscommittedtopreserving": "GSL is committed to preserving the privacy of the users in its Website. To learn about how GSL protects your personal information, such as your name and address, refer to GSL’s Privacy Policy. Except as set forth in the Privacy Policy or in these Terms, your personal information will be deemed to be confidential. Any non-personal information or material sent to GSL and/or provided by you through the Website will generally be deemed to NOT be confidential. You understand and agree that we may disclose information about you if we have a good faith belief that we are required to do so by law or legal process, to respond to claims, or to protect the rights, property, or safety of GSL or others, or as stated in our Privacy Policy",
        "DisclaimerofWarranties": "Disclaimer of Warranties",
        "EXCEPTASEXPRESSLYPROVIDEDOTHERWISE": 'EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AGREEMENT BETWEEN YOU AND GSL, THE WEBSITE, AND ALL MATERIALS AND SERVICES ACCESSIBLE THROUGH THE WEBSITE, ARE PROVIDED ON AN "AS IS" BASIS. GSL, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES RIGHTS AND FITNESS FOR A PARTICULAR PURPOSE. GSL, ITS AFFILIATES AND LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY, COMPLETENESS, SECURITY OR TIMELINESS OF THE SERVICES, CONTENT OR INFORMATION PROVIDED ON OR THROUGH THE USE OF THE WEBSITE. NO INFORMATION OBTAINED VIA THE WEBSITE SHALL CREATE ANY WARRANTY ON BEHALF OF GSL. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY LAST, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU',
        "GSLMAKESNOWARRANTYTHAT": "GSL MAKES NO WARRANTY THAT THE WEBSITE OR THE SERVICES PROVIDED VIA THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE, OR THAT THE WEBSITE OR ANY OF THE SERVICES, INFORMATION AND CONTENT PROVIDED THEREBY OR VIA THE APPLICABLE SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS",
        "AlthoughGSLusesreasonableattempts": "Although GSL uses reasonable attempts to exclude viruses or the like from the Website, it cannot ensure such exclusion. GSL assumes no responsibility, and shall not be liable for any damages to or viruses or the like that may infect your computer equipment or other property on account of your access to or use of or browsing of the Website or your downloading of any material, data, text, images or other from the Website and no liability is accepted for viruses or the like. Please take all appropriate safeguards to protect Your hardware, software and communication systems in connection with Your use or access of the Website",
        "LimitationofLiability": "Limitation of Liability",
        "TOTHEFULLESTEXTENTPERMITTED": "TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL GSL OR ANY OF ITS SUBSIDIARIES, AFFILIATES, STOCKHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, LICENSORS OR SUPPLIERS (THE “GSL PARTIES”) BE LIABLE, JOINTLY OR SEVERALLY, TO YOU OR ANY OTHER PERSON AS A RESULT OF YOUR USE OR ACCESS OF THE SERVICE, THE SITE OR ANY CONTENT APPEARING VIA THE SITE AND SERVICE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUE, PROFITS, USE, DATA OR OTHER INTANGIBLES (COLLECTIVELY, THE “EXCLUDED DAMAGES”), WHETHER OR NOT CHARACTERIZED IN NEGLIGENCE, TORT, CONTRACT OR OTHER THEORY OF LIABILITY, EVEN IF ANY OF THE GSL PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN ANY OF THE EXCLUDED DAMAGES, AND REGARDLESS OF ANY FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY. IF ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE UNENFORCEABLE, AND THEN THE LIABILITY OF THE GSL PARTIES SHALL BE LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY APPLICABLE LAW",
        "InternationalUsers": "International Users",
        "TheWebsitecanbeaccessedfrom": "The Website can be accessed from countries around the world and may contain references to GSL’s products, services, and programs that are not available in your country. These references do not imply that GSL intends to announce such products, services, or programs in your country. The Website is controlled and operated by GSL from its offices within Israel. GSL makes no representation that materials in the Website are appropriate or available for use in other locations, and access to them from territories where their contents are illegal is prohibited. Those who choose to access and use the Website from other locations do so, on their own initiative and are responsible for compliance with applicable local laws and regulations",
        "RemediesforViolations": "Remedies for Violations",
        "GSLreservestherighttoseek": "GSL reserves the right to seek all remedies available at law and in equity for violations of these Terms, including but not limited to the right to block access from a particular Internet address to the Website and any other provider and their features",
        "GoverningLawandJurisdiction": "Governing Law and Jurisdiction",
        "TheTermsaregoverned": "The Terms are governed by and construed in accordance with the laws of the State of Israel and any action arising out of or relating to these terms shall be filed only in the courts located in Haifa and you hereby consent and submit to the personal jurisdiction of such courts for the purpose of litigating any such action",
        "Revision": "Revision",
        "GSLreservestherighttochange": "GSL reserves the right to change, suspend or discontinue any aspect of the services and content provided through the Website at any time, without notice or liability. You should periodically check these Terms and Privacy Policy (which form and constitute an integral part hereof) posted on the Website to review the current terms and guidelines applicable to your use. Your continued use of the Website after such revisions constitutes your acceptance of these revisions",
        "General": "General",
        "TheseTermsconstitutetheentire": "These Terms constitute the entire agreement between you and GSL with reference to the Website. Use of the Website is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including without limitation this paragraph. In the event any provision of these Terms shall be deemed unenforceable, void or invalid, such provision shall be modified so as to make it valid and enforceable, and as so modified the entire Agreement shall remain in full force and effect. No failure or delay in exercising any right, power or privilege hereunder shall operate as a waiver thereof, nor shall any single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any right, power or privilege hereunder",
        "AdditionalInformationUpdates": "Additional Information, Updates and Questions",
        "Additionalinformationoncookiesingeneral": "Additional information on cookies in general",
        "Anumberofwebsitesprovide": "A number of websites provide detailed information on cookies, including AboutCookies.org and AllAboutCookies.org.",
        "UpdatestoourPolicy": "Updates to our Policy",
        "WeupdateourCookiesPolicyfromtimetotime": "We update our Cookies Policy from time to time, if we introduce a new cookie or tracking tool which processes new information or wish to process the information collected by your cookies and tracing tools in a new manner, we shall notify you and ask you to consent to this updated processing or new cookie or tool",
        "Adefaultconfigurationanduse": "A default configuration and use of our first-party cookies and third-party cookies used in the Personal Area are described in the table below",
       
       
     
    },
    "ja": {
        "Thefollowingtermsandconditions": 'The following terms and conditions of use constitute a legal agreement between you and Gold Star Line Ltd. ("GSL") regarding the access and use of the website (respectively the "Website" and "Terms"). By accessing, browsing and/or using the Website, you acknowledge that you have read, understood, and agree, to be bound by these terms and to comply with all applicable laws and regulations. If you do not agree to these terms, do not use the Website',
        "AccessandUseofthisWebsite": "Access and Use of this Website",
        "Youarepermittedtousedownload": "You are permitted to use, download, and print any page of the Website only for the purposes reasonably anticipated on the Website or as otherwise might reasonably be expected in the course of your relationship with GSL and in accordance with any procedures from time to time in force on the Website. Copying, reproducing, displaying, posting, downloading, re-transmission, re-publication, modification of the data or contents provided in the Website, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, is strictly prohibited without the prior written permission of GSL",
        "AsaconditionofyouruseoftheWebsite": "As a condition of your use of the Website, you will not use the Website for any purpose that is illegal or breaches any applicable laws or regulations or unlawful or otherwise prohibited by these Terms. You may not use the Website in any manner that could damage, disable, overburden, or impair any GSL systems, including GSL’s servers and/or the network(s) connected to any GSL server, or interfere with any other party's use and enjoyment of the Website. You may not attempt to gain unauthorized access to any services, accounts, systems or networks through hacking, password mining or any other means. You may not: (i) use or permit any other party to use all or any part of the Website, content or services in connection with activities that breach any applicable laws or regulations, infringe any third party’s rights, or breach any applicable standards, content requirements or codes; (ii) post to, upload to, temporarily store on (if such facility is provided) or transmit through, the Website any information, materials or content that might be or might encourage conduct that might be illegal, unlawful, threatening, abusive, defamatory, obscene, vulgar, discriminatory, pornographic, profane or indecent; (iii) use the Website for the purpose of or as a means to send or distribute software viruses, trojan horses, or any computer code, files, or programs designed to disrupt, destroy, invade, gain unauthorized access to, corrupt, observe or effect software or hardware",
        "IntellectualPropertyRights": "Intellectual Property Rights",
        "Ownershipofallcopyrights": "Ownership of all copyrights, database rights, patents, trade or service marks, product names or design rights (whether registered or unregistered), trade secrets and confidential information and any similar rights existing in any territory now or in future and similar rights and interests in all domain names, trademarks, logos, branding appearing on the Website and all content, or otherwise relating to the structure of the Website and the services offered by GSL via the Website, vests in GSL or its licensors",
        "InteractivityThirdPartyLinksandServiceProviders": "Interactivity, Third Party Links and Service Providers",
        "GSLmaymakeavailablecertain": 'GSL may make available certain features and/or services and/or hyperlinks, including certain interactive features which are provided by external and independent third parties ("Third Party Provider(s)"). You hereby acknowledge that the use of any such features and/or services and/or hyperlinks is subject to and governed by the applicable terms of use or such other agreement in connection therewith. GSL disclaims all warranties, express, implied or statutory, with respect to each Third-Party Provider and its respective feature and/or service, including but not limited to all warranties of merchantability, fitness for a particular purpose, title and non-infringement',
        "GSLmayprovidethroughtheWebsite": "GSL may provide through the Website certain interactive services and features, such as tracking and tracing of containers and shipping schedules. You acknowledge that the data provided through such interactive services and features is for information and convenience only and is not definitive. GSL does not warrant the accuracy or correctness of such data and any such data must be verified with applicable the local agents. Reliance on the information provided through the Website or its use is at Your own and sole responsibility",
        "MonitoringandUnlawfulActivity": "Monitoring and Unlawful Activity",
        "GSLhasnoobligationtomonitortheWebsite": "GSL has no obligation to monitor the Website; howeverGSL reserves the right to do so and prohibit or deny use or access at its sole discretion. Furthermore and without derogating from any other legal to which GSL may be entitled, GSL reserves the right to: (i) terminate or suspend your access to the entire Website or any part thereof at any time, without notice, for any reason whatsoever; (ii) restrict, edit or delete any materials provided or uploaded by You, without notice, for any reason whatsoever. GSL reserves the right to investigate complaints or reported violations of our Terms and to take any action we deem appropriate including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to user profiles, e-mail addresses, usage history, posted materials, IP addresses and traffic information",
        "AccuracyofthisWebsite": "Accuracy of this Website",
        "WhileGSLusescommerciallyreasonable": "While GSL uses commercially reasonable efforts to ensure that the information contained in the Website is accurate and up to date, the Website could include technical or other mistakes, inaccuracies or typographical errors. GSL does not warrant that the Content is accurate, sufficient, error free, complete or up to date at the time it is accessed. You should make further enquiries to satisfy yourself of the accuracy and completeness of any content before relying on it. Certain content may be labeled as indicative only, in which case GSL makes no warranties at all in relation to its quality, accuracy, completeness or timeliness",
        "InformativeData": "Informative Data",
        "Withoutderogatingfromtheaforesaid": "Without derogating from the aforesaid, you acknowledge that the information and/or data being offered or provided through the Website or its use, including without limitation with respect to shipment and material handling, tips and recommendations, information relating to local regulations etc., is purely informative in nature and provided for your convenience only without any representation or warranty by GSL. In no event will the information and/or data being offered or provided through the Website constitute professional, legal, or tax advice or full and accurate data",
        "Youacknowledgeandassumesole": "You acknowledge and assume sole and full responsibility for any liability or loss incurred as a consequence of the use, directly or indirectly, of any information the information and/or data being offered or provided through the Website. You hereby irrevocably and unconditionally waive any claim or demand regarding reliance on any of the information and/or data being offered or provided through the Website or its use",
        "Conflict": "Conflict",
        "Youherebyacknowledgeandconfirmthat": "You hereby acknowledge and confirm that in case of a conflict between these Terms or any other service information provided by the Website or its use and the information contained in the applicable GSL's internal documentation/agreement, the internal documentation/agreement will prevail",
        "Furthermoreandwithoutderogating": "Furthermore and without derogating from the aforesaid, the terms and conditions of GSL's Bill of Lading/Seaway Bill, including without limitation the timetables, governing law and jurisdiction clauses detailed therein shall prevail and supersede these Terms and/or any of the information provided through the Website or its use",
        "PersonalInformationandPrivacy": "Personal Information and Privacy",
        "GSLiscommittedtopreserving": "GSL is committed to preserving the privacy of the users in its Website. To learn about how GSL protects your personal information, such as your name and address, refer to GSL’s Privacy Policy. Except as set forth in the Privacy Policy or in these Terms, your personal information will be deemed to be confidential. Any non-personal information or material sent to GSL and/or provided by you through the Website will generally be deemed to NOT be confidential. You understand and agree that we may disclose information about you if we have a good faith belief that we are required to do so by law or legal process, to respond to claims, or to protect the rights, property, or safety of GSL or others, or as stated in our Privacy Policy",
        "DisclaimerofWarranties": "Disclaimer of Warranties",
        "EXCEPTASEXPRESSLYPROVIDEDOTHERWISE": 'EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AGREEMENT BETWEEN YOU AND GSL, THE WEBSITE, AND ALL MATERIALS AND SERVICES ACCESSIBLE THROUGH THE WEBSITE, ARE PROVIDED ON AN "AS IS" BASIS. GSL, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES RIGHTS AND FITNESS FOR A PARTICULAR PURPOSE. GSL, ITS AFFILIATES AND LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY, COMPLETENESS, SECURITY OR TIMELINESS OF THE SERVICES, CONTENT OR INFORMATION PROVIDED ON OR THROUGH THE USE OF THE WEBSITE. NO INFORMATION OBTAINED VIA THE WEBSITE SHALL CREATE ANY WARRANTY ON BEHALF OF GSL. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY LAST, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU',
        "GSLMAKESNOWARRANTYTHAT": "GSL MAKES NO WARRANTY THAT THE WEBSITE OR THE SERVICES PROVIDED VIA THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE, OR THAT THE WEBSITE OR ANY OF THE SERVICES, INFORMATION AND CONTENT PROVIDED THEREBY OR VIA THE APPLICABLE SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS",
        "AlthoughGSLusesreasonableattempts": "Although GSL uses reasonable attempts to exclude viruses or the like from the Website, it cannot ensure such exclusion. GSL assumes no responsibility, and shall not be liable for any damages to or viruses or the like that may infect your computer equipment or other property on account of your access to or use of or browsing of the Website or your downloading of any material, data, text, images or other from the Website and no liability is accepted for viruses or the like. Please take all appropriate safeguards to protect Your hardware, software and communication systems in connection with Your use or access of the Website",
        "LimitationofLiability": "Limitation of Liability",
        "TOTHEFULLESTEXTENTPERMITTED": "TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL GSL OR ANY OF ITS SUBSIDIARIES, AFFILIATES, STOCKHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, LICENSORS OR SUPPLIERS (THE “GSL PARTIES”) BE LIABLE, JOINTLY OR SEVERALLY, TO YOU OR ANY OTHER PERSON AS A RESULT OF YOUR USE OR ACCESS OF THE SERVICE, THE SITE OR ANY CONTENT APPEARING VIA THE SITE AND SERVICE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUE, PROFITS, USE, DATA OR OTHER INTANGIBLES (COLLECTIVELY, THE “EXCLUDED DAMAGES”), WHETHER OR NOT CHARACTERIZED IN NEGLIGENCE, TORT, CONTRACT OR OTHER THEORY OF LIABILITY, EVEN IF ANY OF THE GSL PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN ANY OF THE EXCLUDED DAMAGES, AND REGARDLESS OF ANY FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY. IF ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE UNENFORCEABLE, AND THEN THE LIABILITY OF THE GSL PARTIES SHALL BE LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY APPLICABLE LAW",
        "InternationalUsers": "International Users",
        "TheWebsitecanbeaccessedfrom": "The Website can be accessed from countries around the world and may contain references to GSL’s products, services, and programs that are not available in your country. These references do not imply that GSL intends to announce such products, services, or programs in your country. The Website is controlled and operated by GSL from its offices within Israel. GSL makes no representation that materials in the Website are appropriate or available for use in other locations, and access to them from territories where their contents are illegal is prohibited. Those who choose to access and use the Website from other locations do so, on their own initiative and are responsible for compliance with applicable local laws and regulations",
        "RemediesforViolations": "Remedies for Violations",
        "GSLreservestherighttoseek": "GSL reserves the right to seek all remedies available at law and in equity for violations of these Terms, including but not limited to the right to block access from a particular Internet address to the Website and any other provider and their features",
        "GoverningLawandJurisdiction": "Governing Law and Jurisdiction",
        "TheTermsaregoverned": "The Terms are governed by and construed in accordance with the laws of the State of Israel and any action arising out of or relating to these terms shall be filed only in the courts located in Haifa and you hereby consent and submit to the personal jurisdiction of such courts for the purpose of litigating any such action",
        "Revision": "Revision",
        "GSLreservestherighttochange": "GSL reserves the right to change, suspend or discontinue any aspect of the services and content provided through the Website at any time, without notice or liability. You should periodically check these Terms and Privacy Policy (which form and constitute an integral part hereof) posted on the Website to review the current terms and guidelines applicable to your use. Your continued use of the Website after such revisions constitutes your acceptance of these revisions",
        "General": "General",
        "TheseTermsconstitutetheentire": "These Terms constitute the entire agreement between you and GSL with reference to the Website. Use of the Website is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including without limitation this paragraph. In the event any provision of these Terms shall be deemed unenforceable, void or invalid, such provision shall be modified so as to make it valid and enforceable, and as so modified the entire Agreement shall remain in full force and effect. No failure or delay in exercising any right, power or privilege hereunder shall operate as a waiver thereof, nor shall any single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any right, power or privilege hereunder",
        "AdditionalInformationUpdates": "Additional Information, Updates and Questions",
        "Additionalinformationoncookiesingeneral": "Additional information on cookies in general",
        "Anumberofwebsitesprovide": "A number of websites provide detailed information on cookies, including AboutCookies.org and AllAboutCookies.org.",
        "UpdatestoourPolicy": "Updates to our Policy",
        "WeupdateourCookiesPolicyfromtimetotime": "We update our Cookies Policy from time to time, if we introduce a new cookie or tracking tool which processes new information or wish to process the information collected by your cookies and tracing tools in a new manner, we shall notify you and ask you to consent to this updated processing or new cookie or tool",
        "Adefaultconfigurationanduse": "A default configuration and use of our first-party cookies and third-party cookies used in the Personal Area are described in the table below",
       
       
     
    },
}
export default homelang;