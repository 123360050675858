import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import Services from "../../../components/get_in_touch/services";
import Packages from "../../../components/get_in_touch/packages_block";
import Inner_container from "../../innerpages/inner-container";
import Api from "../../../../package.json";
import * as axios from "axios";
import inside_heading from "../../../../src/assets/inside-heading.png";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import { NavLink as Link } from "react-router-dom";
import swal from "sweetalert";
import requestaquotelang from "../../lang/request_a_quote";
import HCaptcha from "@hcaptcha/react-hcaptcha";

const qs = require("qs");
var jwt = require("jsonwebtoken");
const Request_a_quote = () => {
  var lang = window.localStorage.getItem("lang");
  if (window.localStorage.getItem("lang") == null) {
    lang = 'en';
    window.localStorage.setItem("lang", 'en');
  }

  const headers = {
    type: "application/json",
    Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
  };
  const [country_code, Setcountry_code] = useState(window.$country_code);
  const [country_list, Setcountry_list] = useState([]);
  const [country_crm_list, Setcountry_crm_list] = useState([]);
  const [portLoading, SetPortLoading] = useState("");
  const [portDestination, SetPortDestination] = useState("");
  const [portLoadingSelectedCountry, SetPortLoadingSelectedCountry] = useState("");
  const [portDestinationSelectedCountry, SetPortDestinationSelectedCountry] = useState("");
  const [captcha, Setcaptcha] = useState([]);
  const [port_code, Setport_code] = useState([]);
  const [date, setdate] = useState(new Date());
  const [SubmitButton, setSubmitButton] = useState(requestaquotelang[lang].Submit);
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });
  const [token, setToken] = useState(null);
  const [countyState, setCountyState] = useState([]);
  const captchaRef = useRef(null);
  const buttonRef = useRef();
  const onSubmit = (data) => {
    //  console.log(data);
    buttonRef.current.disabled = true;
    setSubmitButton(requestaquotelang[lang].Submit + '...');
    save_quote(data);
  };
  const save_quote = async (data) => {

    if (token != null) {
      const response = await axios.post(
        Api.userdata.url + "/save_request_quote_form",
        qs.stringify({
          country_code: country_code,
          title: data.title,
          first_name: data.first_name,
          last_name: data.last_name,
          location: data.location,
          state: data.state,
          company: data.company,
          phone: data.phone,
          phoneone: data.phoneone,
          email: data.email,
          email_check: data.email_check,
          from_port: data.from_port,
          port_loading: portLoading,
          from_city_check: data.from_city_check,
          to_port: data.to_port,
          port_destination: portDestination,
          to_city_check: data.to_city_check,
          container_type: data.container_type,
          container_size: data.container_size,
          cucc_code: data.cucc_code,
          monthly_quantity: data.monthly_quantity,
          commodity_type: data.commodity_type,
          remark: data.remark,
          comment: data.comment,
          shipping_date: new Date(data.shipping_date).toISOString(),
          quotation_captcha: token,
        }), { headers: headers }
      );
      const json = await response.data;
      if (json["status"] == "OK") {
        swal('Successful Submitted', json["message"], 'success').then((value) => {
          window.location.reload(false);
        });

      } else {
        swal("Error !", json["message"], "error");
      }
    } else {
      swal("Error !", "Invalid captcha", "error");
    }

    buttonRef.current.disabled = false;
    setSubmitButton(requestaquotelang[lang].Submit);
  };
  const getLoadData = async () => {
    const response = await axios.post(
      Api.userdata.url + "/country_list",
      qs.stringify({ token: "2" }, { parseArrays: false }), { headers: headers }
    );
    const json = await response.data;
    Setcountry_list(json["message"]);
    Setcountry_crm_list(json["country_master"])
    Setcaptcha(json["captcha"]);

    const port_codes_response = await axios.post(
      Api.userdata.url + "/port_codes",
      qs.stringify({ token: "2" }, { parseArrays: false }), { headers: headers }
    );
    const port_codes_response_json = await port_codes_response.data;
    Setport_code(port_codes_response_json["message"]);

    const response_quote_captcha = await axios.post(
      Api.userdata.url + "/quotation_token",
      qs.stringify({ token: "2" }, { parseArrays: false }), { headers: headers }
    );
    /*   const json_quote_captcha = await response_quote_captcha.data;
      Setcaptcha(json_quote_captcha["message"]); */
  };
  const vgm_office = async (country) => {
    const response = await axios.post(
      Api.userdata.url + "/get_states_by_crm_master",
      qs.stringify({ country: country }, { parseArrays: false }), { headers: headers }
    );
    const json = await response.data;
    if (json["status"] == 1) {

      setCountyState(json["message"]);
    } else {
      setValue("state", '');
      setCountyState([]);
    }

  };
  var lang = window.localStorage.getItem("lang");
  useEffect(() => {
    getLoadData();
    setValue("shipping_date", date);
  }, []);
  return (
    <Layout>

      <section class="inner-center">
        <div class="container">
          <div class="row pt-5">
            <div class="col-lg-12 col-md-12 col-lg-12">
              <div class="form-fback">
                <div class="row m-0">
                  <div class="col-lg-5 col-md-12 col-sm-12 pot-form p-3">
                    <div class="dout-inner">
                      <h3>{requestaquotelang[lang].RequestaQuote}</h3>
                      <p>{requestaquotelang[lang].RequestaQuote_p1}</p>
                    </div>
                  </div>
                  <div class="col-lg-7 col-md-12 col-sm-12 p-0 pot-form">


                  </div>
                </div>
              </div>

            </div>
          </div>
          <form
            action="#"
            name="shipping_quote"
            id="shipping_quote"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div class="tab-content p-3 mt-2 border trade-tab-con booking-tab-cont">
              <div class="tab-pane active" role="tabpanel" id="step1">
                <div class="row m-0">
                  <div class="col-lg-6 col-sm-12 pot-form pl-0">
                    <h4>{requestaquotelang[lang].ContactDetails}</h4>
                    <div class="row m-0">
                      <div class="col-lg-2 col-sm-2 pot-form pl-0">
                        <label>
                          {requestaquotelang[lang].Title}<sup class="starss">*</sup>
                        </label>
                        <select
                          class="selectpicker"
                          name="title"
                          {...register("title")}>
                          <option value={'0001'}>Mr.</option>
                          <option value={'0002'}>Ms.</option>
                          <option value={'0006'}>Mrs.</option>
                          <option value={'0005'}>Other</option>
                        </select>
                      </div>
                      <div class="col-lg-5 col-sm-10 pot-form pl-0">
                        <label>
                          {requestaquotelang[lang].FirstName} <sup class="starss">*</sup>
                        </label>
                        <input
                          type="text"
                          name="first_name"
                          placeholder=""
                          {...register("first_name", {
                            required: {
                              value: true,
                              message: "First Name -required field.",
                            },  maxLength: {
                              value: 50,
                              message: "Your first name must be within 50 characters",
                            },
                          })}
                        />
                        {errors.first_name && (
                          <span class="errors">
                            {errors.first_name.message}
                          </span>
                        )}
                      </div>
                      <div class="col-lg-5  col-sm-10 pot-form pl-0">
                        <label>
                          {requestaquotelang[lang].LastName}   <sup class="starss">*</sup>
                        </label>
                        <input
                          type="text"
                          name="last_name"
                          placeholder=""
                          {...register("last_name", {
                            required: {
                              value: true,
                              message: "Last Name -required field.",
                            },  maxLength: {
                              value: 50,
                              message: "Your last name must be within 50 characters",
                            },
                          })}
                        />
                        {errors.last_name && (
                          <span class="errors">{errors.last_name.message}</span>
                        )}
                      </div>
                    </div>
                    <label>
                      {requestaquotelang[lang].Company}   <sup class="starss">*</sup>
                    </label>
                    <input
                      type="text"
                      name="company"
                      placeholder=""
                      {...register("company", {
                        required: {
                          value: true,
                          message: "Company Name -required field.",
                        },    maxLength: {
                          value: 100,
                          message: "Your company name must be within 100 characters",
                        },
                      })}
                    />
                    {errors.company && (
                      <span class="errors">{errors.company.message}</span>
                    )}
                    <label>
                      {requestaquotelang[lang].Location}  <sup class="starss">*</sup>
                    </label>
                    <Controller
                      control={control}
                      rules={{
                        required: "Please select your Country",
                      }}
                      render={({ onChange, value, ref }) => (
                        <Select
                          options={country_list.map((data) => ({
                            value: data["iso"],
                            label: data["name"],
                          }))}
                          onChange={(val) => {
                            setValue("location", val.value);
                            vgm_office(val.value);
                            const isValueInArray = country_crm_list.indexOf(val.value) !== -1;
                            console.log(country_crm_list);
                            if (isValueInArray) {
                              SetPortLoadingSelectedCountry('Yes');
                            } else {
                              SetPortLoadingSelectedCountry('');
                            }
                          }}
                        />
                      )}
                      name="location"
                      id="location"
                      className="selectpicker"
                      placeholder="--Select Location--"
                      setValue={setValue}
                    />

                    {errors.location && (
                      <span class="errors">{errors.location.message}</span>
                    )}
                    {countyState.length > 0 ? <><div class="booking_office_location">
                      <label>{requestaquotelang[lang].State}</label>
                      <Controller
                        control={control}
                        render={({ onChange, value, ref }) => (
                          <Select
                            options={countyState.map((data) => ({
                              value: data["code"],
                              label: data["state_name"],
                            }))}
                            onChange={(val) => {
                              setValue("state", val.value);
                            }}
                          />
                        )}
                        defaultValue=""
                        name="state"
                        className="selectpicker"
                        placeholder="--Select State--"
                        setValue={setValue}
                      />
                      {errors.state && (
                        <span class="errors">{errors.state.message}</span>
                      )}
                    </div></> : <></>}


                    <li
                      class="ind-f-full"
                      style={{ listStyleType: "none", margin: `6px 0;` }}
                    >
                      <label>
                        {requestaquotelang[lang].PhoneNumber}   <sup class="starss">*</sup>
                      </label>
                      <div class="phone-country">
                        <div class="feedback-code">
                          <label class="pl-feed">+</label>
                          <input
                            name="phoneone"
                            placeholder="852"
                            class="contry1"
                            type="text"
                            style={{ marginBottom: `0 !important` }}
                            {...register("phoneone", {
                              required: {
                                value: true,
                                message: "*",
                              },
                              minLength: {
                                value: 1,
                                message: "Min length 1",
                              },
                              maxLength: {
                                value: 3,
                                message: "Max length 3",
                              },
                              pattern: {
                                value: /^-?[0-9]\d*\.?\d*$/i,
                                message: "Invalid",
                              },
                            })}
                          />
                          {errors.phoneone && (
                            <span class="errors">
                              {errors.phoneone.message}
                            </span>
                          )}
                        </div>
                        <div class="feedback-number">
                          <label class="min-feed">-</label>
                          <input
                            name="phone"
                            id="phone"
                            placeholder="Contact number"
                            type="text"
                            style={{ marginBottom: `0 !important` }}
                            {...register("phone", {
                              required: {
                                value: true,
                                message: "Please enter your mobile number",
                              },
                              minLength: {
                                value: 6,
                                message: "Invalid phone number",
                              },
                              maxLength: {
                                value: 15,
                                message: "Invalid phone number",
                              },
                              pattern: {
                                value: /^-?[0-9]\d*\.?\d*$/i,
                                message: "Invalid",
                              },
                            })}
                          />
                          {errors.phone && (
                            <span class="errors">{errors.phone.message}</span>
                          )}
                        </div>
                      </div>
                    </li>
                    <label>
                      {requestaquotelang[lang].Email}  <sup class="starss">*</sup>
                    </label>
                    <input
                      type="text"
                      name="email"
                      placeholder=""
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Enter your email-id",
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                        maxLength: {
                          value: 100,
                          message: "Your email must be within 100 characters",
                        },
                      })}
                    />
                    {errors.email && (
                      <span class="errors">{errors.email.message}</span>
                    )}
                    <br></br>
                    <br></br>
                    <h4>{requestaquotelang[lang].LocationDetails}</h4>

                    <label>
                      {requestaquotelang[lang].FromCityPort}  <sup class="starss">*</sup>
                    </label>
                    <Controller
                      control={control}
                      rules={{
                        required: "Please select from port",
                      }}
                      render={({ onChange, value, ref }) => (
                        <Select
                          options={port_code.map((data) => ({
                            port_code: data["port_code"],
                            country: data["country"],
                            value:
                              data["port_name"] +
                              "-" +
                              data["country_name"] +
                              "(" +
                              data["port_code"] +
                              ")",
                            label:
                              data["port_name"] +
                              "-" +
                              data["country_name"] +
                              "(" +
                              data["port_code"] +
                              ")",
                          }))}
                          onChange={(val) => {
                            SetPortLoading(val.port_code);
                            setValue("from_port", val.label);

                          }}
                        />
                      )}
                      defaultValue=""
                      placeholder="Select platform"
                      name="from_port"
                      id="from_port"
                      setValue={setValue}
                    />
                    {errors.from_port && (
                      <span class="errors">{errors.from_port.message}</span>
                    )}
                    <div class="checkbox-animated">
                      <input
                        id="from_city_check"
                        name="from_city_check"
                        type="checkbox"
                        {...register('from_city_check')}
                      />
                      <label for="from_city_check">
                        <span class="check"></span>
                        <span class="box"></span>
                        {requestaquotelang[lang].LandTransportationExport}
                      </label>
                    </div>

                    <label>
                      {requestaquotelang[lang].ToCityPort}   <sup class="starss">*</sup>
                    </label>
                    <Controller
                      control={control}
                      rules={{
                        required: "Please select to port",
                      }}
                      render={({ onChange, value, ref }) => (
                        <Select
                          options={port_code.map((data) => ({
                            port_code: data["port_code"],
                            country: data["country"],
                            value:
                              data["port_name"] +
                              "-" +
                              data["country_name"] +
                              "(" +
                              data["port_code"] +
                              ")",
                            label:
                              data["port_name"] +
                              "-" +
                              data["country_name"] +
                              "(" +
                              data["port_code"] +
                              ")",
                          }))}
                          onChange={(val) => {
                            SetPortDestination(val.port_code);
                            setValue("to_port", val.label);

                          }}
                        />
                      )}
                      defaultValue=""
                      placeholder="Select platform"
                      name="to_port"
                      id="to_port"
                      setValue={setValue}
                    />
                    {errors.to_port && (
                      <span class="errors">{errors.to_port.message}</span>
                    )}
                    <div class="checkbox-animated">
                      <input
                        id="to_city_check"
                        name="to_city_check"
                        type="checkbox"
                        {...register('to_city_check')}
                      />
                      <label for="to_city_check">
                        <span class="check"></span>
                        <span class="box"></span>
                        {requestaquotelang[lang].LandTransportationImport}
                      </label>
                    </div>
                    <label>  {requestaquotelang[lang].CUCCCode} </label>
                    <input
                      type="text"
                      name="cucc_code"
                      id="cucc"
                      placeholder=""
                      {...register("cucc_code")}
                    />

                    <label>
                      {requestaquotelang[lang].ShipmentDate} <sup class="starss"></sup>
                    </label>
                    <div
                      id="datepicker"
                      class="input-group date datepicker"
                      data-date-format="dd/mm/yyyy"
                    >
                      <Controller
                        control={control}
                        rules={{
                          required: "Please select your date",
                        }}
                        render={({ onChange, value, ref }) => (
                          <Flatpickr
                            options={{ dateFormat: "d-m-Y" }}
                            value={date}
                            onChange={(val) => {
                              setValue("shipping_date", val[0]);
                            }}
                          />
                        )}
                        id="shipping_date"
                        name="shipping_date"
                        class="form-control"
                        setValue={setValue}
                      />
                      {errors.shipping_date && (
                        <span class="errors">
                          {errors.shipping_date.message}
                        </span>
                      )}
                      <span class="input-group-addon">
                        <i class="glyphicon glyphicon-calendar"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-12 pot-form">
                    <h4>{requestaquotelang[lang].CargoDetails} </h4>

                    <label>{requestaquotelang[lang].ContainerType} </label>
                    <select
                      class="selectpicker"
                      name="container_type"
                      {...register("container_type")}
                    >
                      <option value="">Select Container Type</option>
                      <option value="DV">DRY VAN</option>
                      <option value="FL">FLAT</option>
                      <option value="HC">HIGH CUBE</option>
                      <option value="OT">OPEN TOP</option>
                      <option value="RF">REEFER</option>
                      <option value="RH">HIGHCUBE REEFER</option>
                      <option value="TK">TANK (SHIPPER OWNED CONTAINER)</option>
                    </select>
                    <label>
                      {requestaquotelang[lang].ContainerSize}  <sup class="starss">*</sup>
                    </label>
                    <select
                      class="selectpicker"
                      name="container_size"
                      {...register("container_size")}
                    >
                      <option value="">Select Container Size</option>
                      <option value="20">20'</option>
                      <option value="40">40'</option>
                      <option value="45">45'</option>
                    </select>
                    <label>
                      {requestaquotelang[lang].MonthlyQuantity} <sup class="starss"></sup>
                    </label>
                    <input
                      type="text"
                      name="monthly_quantity"
                      {...register("monthly_quantity")}
                      placeholder=""
                    />
                    <label>
                      {requestaquotelang[lang].CommodityType} <sup class="starss">*</sup>
                    </label>
                    <select
                      class="selectpicker"
                      name="commodity_type"
                      {...register("commodity_type")}
                    >
                      <option value="">Select Commodity Type</option>
                      <option value="1">
                        Food &amp; Drinks
                      </option>
                      <option value="2">Tobacco</option>
                      <option value="3">Minerals</option>
                      <option value="4">
                        Chemicals and Allied Pharmaceutical
                      </option>
                      <option value="5">Pharmaceutical</option>
                      <option value="6">
                        Plastics &amp; Rubber
                      </option>
                      <option value="7">
                        Raw Hides,Skins,Leather,&amp; Furs
                      </option>
                      <option value="8">
                        Wood &amp; Paper Products(forest products)
                      </option>
                      <option value="9">
                        Scrap &amp; Waste Paper
                      </option>
                      <option value="10">
                        Arms, Ammunition &amp; Explosives
                      </option>
                      <option value="11">
                        Textile, Footwear &amp; other Apparel
                      </option>
                      <option value="12">
                        Stone &amp; Ceramic
                      </option>
                      <option value="13">Glass</option>
                      <option value="14">Metals</option>
                      <option value="15">
                        Machinery &amp; Mechanical appliances
                      </option>
                      <option value="16">
                        Electronics and Telecommunications
                      </option>
                      <option value="17">
                        Transportation &amp; Auto parts
                      </option>
                      <option value="18">
                        Optical, Watches, Musical instrument, Precious Stones
                      </option>
                      <option value="19">Furniture</option>
                      <option value="20">
                        Various Products &amp; Miscellaneous (incl. Retailers)
                      </option>
                      <option value="21">Special Cargo</option>
                    </select>

                    <label>   {requestaquotelang[lang].DescriptionOfGoods}  </label>
                    <textarea
                      name="comment"
                      style={{
                        marginTop: `0px;`,
                        marginBottom: `18px;`,
                        height: `116px;`,
                      }}
                      {...register("comment", {
                       
                        maxLength: {
                          value: 100,
                          message: "Description of goods must be within 100 characters.",
                        },
                      })}
                    ></textarea>   {errors.comment && (
                      <span class="errors">
                        {errors.comment.message}
                      </span>
                    )}
                    <label>{requestaquotelang[lang].Remark}  </label>
                    <textarea
                      name="remark"
                      style={{
                        marginTop: `0px;`,
                        marginBottom: `18px;`,
                        height: `116px;`,
                      }}
                      {...register("remark", {
                       
                        maxLength: {
                          value: 200,
                          message: "Remarks must be within 200 characters.",
                        },
                      })}
                    ></textarea>
                     {errors.remark && (
                        <span class="errors">
                          {errors.remark.message}
                        </span>
                      )}
                    <div class="checkbox-animated">
                      <input
                        id="email_check"
                        name="email_check"
                        type="checkbox"
                        value=""
                        {...register("email_check")}
                      />
                      <label for="email_check">
                        <span class="check"></span>
                        <span class="box"></span>
                        {requestaquotelang[lang].Remark_p1}
                      </label>
                    </div>
                    <label>
                      <Link
                        to="/quotation_terms_conditions"
                        target="_new"
                        className="drop-inner"
                      >
                        <i class="icon-link"></i>{requestaquotelang[lang].Quotationtearmscondition}
                      </Link>
                    </label>
                    <HCaptcha
                      sitekey="a8400b17-0f2c-4116-85cb-91a16f434d49"
                      onVerify={setToken}
                      ref={captchaRef}
                      apihost="https://cn1.hcaptcha.com"
                      endpoint="https://cn1.hcaptcha.com"
                      assethost="https://assets-cn1.hcaptcha.com"
                      imghost="https://imgs-cn1.hcaptcha.com"
                      reportapi="https://reportapi-cn1.hcaptcha.com"
                    />
                    {errors.hcpactha && (
                      <span class="errors">{errors.message.hcpactha}</span>
                    )}
                  </div>
                </div>
                <div class="booking-next">
                  <button
                    type="submit"
                    name="submit"
                    id="submit"
                    class="btn btn-default hovereffect mr-1"
                    ref={buttonRef}
                  >
                    {SubmitButton}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <Services />
      <Packages />
      <GET_IN_TOUCH />
    </Layout>
  );
};
export default Request_a_quote;
