import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import ecom_logo_3 from '../../../../src/assets/ecom-logo-3.png';
import ecom_logo_2 from '../../../../src/assets/ecom-logo-2.png';
import eandp from '../../../../src/assets/eandp.png';
import ecom_logo_1 from '../../../../src/assets/ecom-logo-1.png';
import Inner_container from '../../innerpages/inner-container';


var jwt = require('jsonwebtoken');
export default class Ecommerce_partners extends Component {

    render() {
        return (

            <Layout>

                <Inner_container innerpage="ecommerce_partners" />
                <section class="inner-center n-40">
                    <div class="container">
                        <form class="point-to">
                            <div class="cargo-inner">
                                <div class="row ml-0 mr-0 mt-3 mb-3 ">
                                    <div class=" col-sm-12 col-md-12 col-lg-12">
                                        <div class="eco-full" >
                                            <h4>Benefit from the unique advantages our eCommerce system offers you: </h4>
                                            <ul class="ecom-page">

                                                <li>Send booking and shipping instructions electronically</li>
                                                <li>Receive quick booking confirmations</li>
                                                <li>Trace your cargo easily</li>
                                                <li>Get proactive alerts about important milestones:<br />loading, discharge, customs clearance, etc.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class=" col-sm-12 col-md-12 col-lg-12">
                                        <div class="eco-full" >
                                            <h4>GSL offers E-commerce services via the following portals: </h4>
                                            <ul class="e-com-logo">
                                                <li><a href="http://www.gtnexus.com/"><img src={ecom_logo_3} /></a></li>
                                                <li><a href="https://booking.inttra.com/create?inttraCompanyId=OTAwMzk1"><img src={ecom_logo_2} /></a></li>
                                                <li><a href="https://www.cargosmart.com/en/default.htm"><img src={ecom_logo_1} /></a></li>
                                                <li><a href="https://www.easipass.com/#/"><img src={eandp} /></a></li>

                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </form>
                    </div>
                </section>

                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout>);
    }
}