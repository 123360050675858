import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import Services from "../../../components/get_in_touch/services";
import Packages from "../../../components/get_in_touch/packages_block";
import Insideheading from "../../../../src/assets/inside-heading.png";
import Inner_container from "../../innerpages/inner-container";
import Api from "../../../../package.json";
import * as axios from "axios";
import { useForm, Controller } from "react-hook-form";
import GoToTop from "../../../components/go-to-top";
import Select from "react-select";
import swal from "sweetalert";
import subscribe_lang from '../../lang/schedule_subscribe';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { NavLink as Link } from "react-router-dom";

const qs = require("qs");
var jwt = require("jsonwebtoken");

const Subscibe = () => {

  var lang = window.localStorage.getItem("lang");
  if (window.localStorage.getItem("lang") == null) {
    lang = 'en';
    window.localStorage.setItem("lang", 'en');
  }

  const headers = {
    type: "application/json",
    Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
  };
  const [country_code, setCountryCode] = useState(window.$country_code);
  const [schedule_list, setScheduleList] = useState([]);
  const [SubmitButton, setSubmitButton] = useState(subscribe_lang[lang].Submit);
  const [country_list, setcountry_list] = useState([]);
  const [subscribe_token, setsubscribe_token] = useState([]);
  const [captcha, setSubscribecaptcha] = useState([]);
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const buttonRef = useRef();
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const getLoadData = async () => {
    const response = await axios.post(
      Api.userdata.url + "/sailing_schedule",
      qs.stringify({ token: "2" }, { parseArrays: false }), { headers: headers }
    );
    const json = await response.data;
    setScheduleList(json["message"]);
    const country_list_response = await axios.post(
      Api.userdata.url + "/country_list",
      qs.stringify({ token: "2" }, { parseArrays: false }), { headers: headers }
    );
    const country_list_response_json = await country_list_response.data;
    setcountry_list(country_list_response_json["message"]);

    const Subscrib_token_res = await axios.post(
      Api.userdata.url + "subscribe_token",
      qs.stringify({ country_code: country_code }, { parseArrays: false }), { headers: headers }
    );
    const Subscrib_token_json = await Subscrib_token_res.data;
    setsubscribe_token(Subscrib_token_json["message"]);
  };
  const onSubmit = (data) => {

    buttonRef.current.disabled = true;
    setSubmitButton(subscribe_lang[lang].Submit + '...');
    console.log(data);
    save_subscribe(data);
  };
  const save_subscribe = async (data) => {
    if (token != null) {
      const response = await axios.post(
        Api.userdata.url + "save_subscribe",
        qs.stringify({
          country_code: country_code,
          company: data.company,
          email: data.email,
          firstname: data.firstname,
          lastname: data.lastname,
          location: data.location,
          country: data.country,
          subscribe_token: data.subscribe_token,
          email_type: data.email_type,
          subscribe_captcha: token,
        }), { headers: headers }
      );
      const json = await response.data;
      setSubscribecaptcha(json["captcha"]);
      if (json["status"] == "OK") {
        swal("Successful Submitted", json["message"], "success").then((value) => {
          window.location.reload(false);
        });
      } else {
        swal("Error !", json["message"], "error");
      }
    } else {
      swal("Error !", "Invalid captcha", "error");
    }

    buttonRef.current.disabled = false;
    setSubmitButton(subscribe_lang[lang].Submit);
  };
  useEffect(() => {
    getLoadData();
  }, []);
  return (
    <Layout>
      <Inner_container innerpage="subscribe" />

      <section class="inner-center n-40 ">

        <div class="container p-3">
          <div class="row m-0">

            <div class="col-lg-12">
              <form
                class="point-to"
                action="#"
                method="POST"
                id="subscribe-form"
                name="subscribe"
                onSubmit={handleSubmit(onSubmit)}
              >

                <div class="form-fback">
                  <div class="row m-0">
                    <div class="col-lg-12 col-sm-12 pot-form pl-0">
                      <div class="row m-0">
                        <div class="col-lg-6 col-sm-12 pot-form pl-0">
                          <label>{subscribe_lang[lang].EMail}</label>
                          <input
                            type="text"

                            id="email"
                            placeholder="Email id"
                            {...register("email", {
                              required: {
                                value: true,
                                message: "Enter your email-id",
                              },
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                              },
                            })}
                          />
                          {errors.email && (
                            <span class="errors">{errors.email.message}</span>
                          )}
                        </div>

                        <div class="col-lg-6 col-sm-12 pot-form">
                          <label>{subscribe_lang[lang].Company}</label>
                          <input
                            type="text"

                            id="company"
                            placeholder="Company Name"
                            {...register("company", {
                              required: {
                                value: true,
                                message: "Please enter your company name",
                              },
                            })}
                          />
                          {errors.company && (
                            <span class="errors">{errors.company.message}</span>
                          )}
                        </div>
                      </div>
                      <div class="row m-0">
                        <div class="col-lg-6 mt-2 col-sm-12 pot-form pl-0">
                          <label>{subscribe_lang[lang].Email_Type}</label>
                          <ul class="sec-pot-check new-pot-radio">
                            <li>
                              <div class="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="group"

                                  value="Group"
                                  class="custom-control-input"
                                  {...register("email_type")}
                                />
                                <label
                                  class="custom-control-label radio-nane"
                                  for="group"
                                >
                                  {subscribe_lang[lang].Group}
                                </label>
                              </div>
                            </li>
                            <li>
                              <div class="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="individual"

                                  value="Individual"
                                  class="custom-control-input"
                                  checked
                                  {...register('email_type')}
                                />
                                <label
                                  class="custom-control-label radio-nane"
                                  for="individual"
                                >
                                  {subscribe_lang[lang].Individual}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="col-lg-6 col-sm-12 pot-form ">
                          <label>{subscribe_lang[lang].FirstName}</label>
                          <input
                            type="text"

                            id="firstname"
                            placeholder="First Name"
                            {...register('firstname', {
                              required: {
                                value: true,
                                message: "Please enter your first name",
                              },
                              pattern: {
                                value: /^[A-Z\s]/i,
                                message: "Invalid name",
                              },
                            })}
                          />
                          {errors.firstname && (
                            <span class="errors">
                              {errors.firstname.message}
                            </span>
                          )}
                        </div>
                        <div class="col-lg-6 col-sm-12 pot-form pl-0">
                          <label>{subscribe_lang[lang].LastName}</label>
                          <input
                            type="text"

                            id="lastname"
                            placeholder="Last Name"
                            {...register('lastname', {
                              required: {
                                value: true,
                                message: "Please enter your last name",
                              },
                              pattern: {
                                value: /^[A-Z\s]/i,
                                message: "Invalid name",
                              },
                            })}
                          />
                          {errors.lastname && (
                            <span class="errors">
                              {errors.lastname.message}
                            </span>
                          )}
                        </div>

                        <div class="col-lg-6 col-sm-12 pot-form ">
                          <label>{subscribe_lang[lang].CountryName}</label>
                          <Controller
                            control={control}
                            rules={{
                              required: "Please select your country name",
                            }}
                            render={({ onChange, value, ref }) => (
                              <Select
                                options={country_list.map((data) => ({
                                  value: data["iso"],
                                  phone_code: data["phonecode"],
                                  label: data["name"],
                                }))}
                                onChange={(val) => {
                                  setValue("location", val.label);
                                }}
                              />
                            )}
                            defaultValue=""
                            placeholder="Select platform"
                            name="location"
                            setValue={setValue}
                          />
                          {errors.location && (
                            <span class="errors">
                              {errors.location.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row m-0 pt-4" id="checkboxSubscribercontainer">
                    <div class="col-lg-12 col-sm-12 pot-form pl-0"> <p>
                      {subscribe_lang[lang].Selecttheschedule1} : ({subscribe_lang[lang].Selecttheschedule2})
                    </p></div>

                    <div class="col-lg-6 col-sm-12 pot-form pl-0">
                      {schedule_list.map((data, i) => {
                        if (i % 2 == 0) {
                          {
                            {
                              var key = data["name"];
                              var value = data["full_name"];
                              var full_name = data["full_name"];
                              if (data['more_lang_details'] != '' && data['more_lang_details'] != null) {
                                const obj = JSON.parse(data['more_lang_details']);
                                if (typeof obj[lang] != 'undefined' && typeof obj[lang] != '') {
                                  full_name = obj[lang]
                                }
                              }
                              i++;
                            }
                          }
                          return (
                            <div class="checkbox-animated">
                              <input
                                type="checkbox"


                                id={key}
                                value={key}
                                {...register("country[]", {
                                  required: {
                                    value: true,
                                    message:
                                      "Choose at least one schedule you want to receive",
                                  },
                                })}
                              />
                              <label for={key}>
                                <span class="check"></span>
                                <span class="box"></span>
                                {full_name}
                              </label>{" "}
                            </div>
                          );
                        }
                      })}

                      {errors.country && (
                        <span class="errors">{errors.country.message}</span>
                      )}
                    </div>
                    <div class="col-lg-6 col-sm-12 pot-form pl-0">
                      {schedule_list.map((data, i) => {
                        if (i % 2 != 0) {
                          {
                            {
                              var full_name = data["full_name"];
                              if (data['more_lang_details'] != '' && data['more_lang_details'] != null) {
                                const obj = JSON.parse(data['more_lang_details']);
                                if (typeof obj[lang] != 'undefined' && typeof obj[lang] != '') {
                                  full_name = obj[lang]
                                }
                              }
                              var key = data["name"];
                              var value = data["full_name"];

                              i++;
                            }
                          }
                          return (
                            <div class="checkbox-animated">
                              <input
                                type="checkbox"

                                id={key}
                                value={key}
                                {...register("country[]", {
                                  required: {
                                    value: true,
                                    message:
                                      "Choose at least one schedule you want to receive",
                                  },
                                })}
                              />
                              <label for={key}>
                                <span class="check"></span>
                                <span class="box"></span>
                                {full_name}
                              </label>{" "}
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div class="col-lg-12 col-sm-12 pt-4 pot-form pl-0">
                      <p>

                        Gold Star Line Ltd. (hereinafter called “GSL”) is committed to ensuring that your personal data and data privacy are protected in accordance with the best practices available, as well as according to GSL’s relevant legal obligations. For more details,please visit   <Link
                                to="/data-protect-policy"
                                className="drop-inner"
                              >our Data Protection Policy.</Link>

                      </p> <p>By clicking the submit button below, you are providing consent to GSL to send you the requested Email Schedule updates.</p>

                    </div>
                    <div class="d-flex si-booking-next">
                      <div class="col-lg-12 col-sm-12 pot-form pl-0">
                        <div class="">
                          {/* <label for="captcha">
                            {subscribe_lang[lang].Typethecodeyousee} (*):
                          </label>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: subscribe_token,
                            }}
                          ></div>
                          <div class="form-group">
                            <input
                              type="text"

                              id="subscribe_token"
                              class="form-control"
                              placeholder="captcha"
                              {...register("subscribe_token", {
                                required: {
                                  value: true,
                                  message: "Please enter captcha",
                                },
                                pattern: {
                                  value: /^-?[0-9]\d*\.?\d*$/i,
                                  message: "Invalid captcha",
                                },
                              })}
                            />
                          </div>
                          {errors.subscribe_token && (
                            <span class="errors">{errors.subscribe_token.message}</span>
                          )} */}

                          <HCaptcha
                            sitekey="a8400b17-0f2c-4116-85cb-91a16f434d49"
                            onVerify={setToken}
                            ref={captchaRef}
                            apihost="https://cn1.hcaptcha.com"
                            endpoint="https://cn1.hcaptcha.com"
                            assethost="https://assets-cn1.hcaptcha.com"
                            imghost="https://imgs-cn1.hcaptcha.com"
                            reportapi="https://reportapi-cn1.hcaptcha.com"
                          />
                          {errors.hcpactha && (
                            <span class="errors">{errors.message.hcpactha}</span>
                          )}
                          <div className="booking-next">
                            <button
                              class="btn btn-default hovereffect portal-searc"
                              type="submit"
                              name="submit"
                              id="submit"
                              ref={buttonRef}
                            >
                              {SubmitButton}
                            </button></div>
                        </div>
                        <ul
                          class="sec-pot-check new-pot-radio"
                          style={{ textAlign: "center", marginTop: "10px;" }}
                        >
                          <li
                            class="buts-in-cen"
                            style={{ textAlign: "center" }}
                          ></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Services />
      <Packages />
      <GET_IN_TOUCH />
      <GoToTop />
    </Layout>
  );
};

export default Subscibe;
