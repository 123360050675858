const homelang =
{
    "en": {
        "CARCARRIERSERVICE": "CAR CARRIER SERVICE (RORO)",
        "CARCARRIERSERVICE1": "Request to ship your vehicle safe and reliable",
        "BookingDetails": "Booking Details",
        "Wheredoyouwanttoshipfrom": "Where do you want to ship from",
        "Wheredoyouwanttoshipto": "Where do you want to ship to",
        "Shipper": "Shipper",
        "Forwarder": "Forwarder",
        "ShipmentDate": "Shipment Date",
        "Additionalcomments": "Additional comments",
        "Contactdetails": "Contact details",
        "Contactname": "Contact name",
        "PhoneNumber": "Phone Number",
        "Contactemail": "Contact e-mail",
        "EnterCargodetails": "Enter Cargo details",
        "EnterCargodetails1": "Pick a unit of measure for dimensions",
        "Meters": "Meters (m)",
        "Inches": "Inches (in)",
        "Pickaunitofmeasureforweight": "Pick a unit of measure for weight",
        "Kilograms": "Kilograms (kg)",
        "Pounds": "Pounds (lbs)",
        "CargoClass": "Cargo Class",
        "Condition": "Condition",
        "Quantity": "Quantity",
        "UnitWeight": "Unit Weight",
        "Length": "Length",
        "Width": "Width",
        "Height": "Height",
        "TotalMeasurement": "Total Measurement",
        "TotalWeight": "Total Weight",
        "PackageandDescriptionofCargo": "Package and Description of Cargo",
        "AdditionalDocumentsallowedonly": "Additional Documents allowed only",
        "Thiscargoishazardous": "This cargo is hazardous or contains hazardous substances",
        "EditHazardousDetails": "Edit Hazardous Details",
        "ProperShippingName": "Proper Shipping Name",
        "UNNumber": "UN Number",
        "Class": "Class",
        "SubsidiaryRisk": "Subsidiary Risk",
        "DangerousComponent": "Dangerous Component",
        "optionalIfyouhaveIMO": "Optional - If you have IMO Dangerous Goods Decalration or Material Safety Data Sheet, it will help us give you a more correct rate. allowed only *(pdf,png,jpeg) - Size(2M)",
        "MaterialSafety": "Material Safety Data Sheet allowed only *(pdf,png,jpeg) - Size(2M)",
        "UnitofMeasureforWeight": "Unit of Measure for Weight",
        "UnitofMeasureforTemperature": "Unit of Measure for Temperature",
        "NetWeight": "Net Weight",
        "GrossWeight": "Gross Weight",
        "FlashpointTemperature": "Flashpoint Temperature",
        "NetExplosiveQuantity": "Net Explosive Quantity",
        "PackingGroup": "Packing Group",
        "PackageandDescriptionofCargo": "Package and Description of Cargo",
        "MarinePollution": "Marine Pollution",
        "LimitedQuantity": "Limited Quantity",
        "Comments": "Comments",
        "Delete": "Delete",
        "AddCargodetails": "Add Cargo details",
        "Typethecodeyousee": "Type the code you see",
        "Fromtheoptionsbelowselectifyouarea": "From the options below, select if you are a",
        "Businessrepresentative": "Business representative",
        "Privateperson": "Private person",
        "Submit": "Submit",
   

    },
    "cn": {
        "CARCARRIERSERVICE": "車輛托運服務 (RORO)",
        "CARCARRIERSERVICE1": "安全可靠地運送您的車輛",
        "BookingDetails": "訂艙單内容",
        "Wheredoyouwanttoshipfrom": "開始運送地",
        "Wheredoyouwanttoshipto": "到達運送地",
        "Shipper": "貨主",
        "Forwarder": "貨運代理",
        "ShipmentDate": "運送日期",
        "Additionalcomments": "附加資料",
        "Contactdetails": "聯絡資料",
        "Contactname": "聯絡人姓名",
        "PhoneNumber": "電話",
        "Contactemail": "電郵",
        "EnterCargodetails": "貨物資料",
        "EnterCargodetails1": "選擇尺寸單位",
        "Meters": "公尺",
        "Inches": "英寸",
        "Pickaunitofmeasureforweight": "選擇重量單位",
        "Kilograms": "公斤",
        "Pounds": "磅",
        "CargoClass": "貨物類別",
        "Condition": "狀況",
        "Quantity": "數量",
        "UnitWeight": "使用過",
        "Length": "長",
        "Width": "濶",
        "Height": "高",
        "TotalMeasurement": "總體積",
        "TotalWeight": "總重量",
        "PackageandDescriptionofCargo": "貨物包裝及描述",
        "AdditionalDocumentsallowedonly": "附加文件",
        "Thiscargoishazardous": "该货物具有危险性或含有有害物质",
        "EditHazardousDetails": "填寫危險品内容",
        "ProperShippingName": "正確貨運名稱",
        "UNNumber": "UN 編號",
        "Class": "類別",
        "SubsidiaryRisk": "附屬危險類別",
        "DangerousComponent": "危險品成分",
        "optionalIfyouhaveIMO": "*如能提供有關危險貨品詳細資料,我們可爲您提供更準確的報價",
        "MaterialSafety": "物料安全數據資料 *(pdf, png, jpeg) -  容量(2M)",
        "UnitofMeasureforWeight": "重量單位",
        "UnitofMeasureforTemperature": "溫度單位",
        "NetWeight": "净重量",
        "GrossWeight": "總重量",
        "FlashpointTemperature": "閃點溫度",
        "NetExplosiveQuantity": "净爆炸品數量",
        "PackingGroup": "包裝組別",
        "PackageandDescriptionofCargo": "貨物包裝及描述",
        "MarinePollution": "海洋污染",
        "LimitedQuantity": "有限程度",
        "Comments": "補充",
        "Delete": "刪除",
        "AddCargodetails": "增加貨物内容",
        "Typethecodeyousee": "請輸入驗證碼",
        "Fromtheoptionsbelowselectifyouarea": "從以下請選擇, 您是",
        "Businessrepresentative": "業務代表",
        "Privateperson": "個別人士",
        "Submit": "提交",
        
    },
    "zh": {
        "CARCARRIERSERVICE": "车辆托运服务 (RORO)",
        "CARCARRIERSERVICE1": "安全可靠地运送您的车辆",
        "BookingDetails": "订舱单内容",
        "Wheredoyouwanttoshipfrom": "开始运送地",
        "Wheredoyouwanttoshipto": "到达运送地",
        "Shipper": "货主",
        "Forwarder": "货运代理",
        "ShipmentDate": "运送日期",
        "Additionalcomments": "附加资料",
        "Contactdetails": "联络资料",
        "Contactname": "联络人姓名",
        "PhoneNumber": "电话",
        "Contactemail": "电邮",
        "EnterCargodetails": "货物资料",
        "EnterCargodetails1": "选择尺寸单位",
        "Meters": "公尺",
        "Inches": "英寸",
        "Pickaunitofmeasureforweight": "选择重量单位",
        "Kilograms": "公斤",
        "Pounds": "磅",
        "CargoClass": "货物类别",
        "Condition": "状况",
        "Quantity": "数量",
        "UnitWeight": "重量",
        "Length": "长",
        "Width": "阔",
        "Height": "高",
        "TotalMeasurement": "总体积",
        "TotalWeight": "总重量",
        "PackageandDescriptionofCargo": "货物包装及描述",
        "AdditionalDocumentsallowedonly": "附加文件",
        "Thiscargoishazardous": "这是属于危害货品或含有危害物质",
        "EditHazardousDetails": "填写危险品内容",
        "ProperShippingName": "正确货运名称",
        "UNNumber": "UN 编号",
        "Class": "类别",
        "SubsidiaryRisk": "附属危险类别",
        "DangerousComponent": "危险品成分",
        "optionalIfyouhaveIMO": "*如能提供有关危险货品详细资料,我们可为您提供更准确的报价",
        "MaterialSafety": "物料安全数据资料 *(pdf, png, jpeg) -  容量(2M)",
        "UnitofMeasureforWeight": "重量单位",
        "UnitofMeasureforTemperature": "温度单位",
        "NetWeight": "净重量",
        "GrossWeight": "总重量",
        "FlashpointTemperature": "闪点温度",
        "NetExplosiveQuantity": "净爆炸品数量",
        "PackingGroup": "包装组别",
        "PackageandDescriptionofCargo": "货物包装及描述",
        "MarinePollution": "海洋污染",
        "LimitedQuantity": "有限程度",
        "Comments": "补充",
        "Delete": "删除",
        "AddCargodetails": "增加货物内容",
        "Typethecodeyousee": "请输入验证码",
        "Fromtheoptionsbelowselectifyouarea": "从以下请选择, 您是",
        "Businessrepresentative": "业务代表",
        "Privateperson": "个别人士",
        "Submit": "递交",
        
    },
    "ko": {
        "CARCARRIERSERVICE": "자동차 선적 서비스(RORO)",
        "CARCARRIERSERVICE1": "안전하고 신뢰 가능한 차량 발송 요청",
        "BookingDetails": "예약 상세 내용",
        "Wheredoyouwanttoshipfrom": "선적 요청 지역",
        "Wheredoyouwanttoshipto": "배송 요청 지역",
        "Shipper": "송하인",
        "Forwarder": "포워더",
        "ShipmentDate": "선적 일자",
        "Additionalcomments": "추가 ",
        "Contactdetails": "담당자 상세 정보",
        "Contactname": "담당자 이름",
        "PhoneNumber": "전화번호",
        "Contactemail": "Email",
        "EnterCargodetails": "화물 정보 입력",
        "EnterCargodetails1": "화물 면적 측정 단위",
        "Meters": "미터 (m)",
        "Inches": "인치 (in)",
        "Pickaunitofmeasureforweight": "화물 무게 측정 단위",
        "Kilograms": "킬로그램 (kg)",
        "Pounds": "파운드 (ibs)",
        "CargoClass": "화물 구분",
        "Condition": "조건",
        "Quantity": "수량",
        "UnitWeight": "화물 무게",
        "Length": "길이",
        "Width": "폭",
        "Height": "높이",
        "TotalMeasurement": "총 부피",
        "TotalWeight": "총 무게",
        "PackageandDescriptionofCargo": "화물 포장 및 설명",
        "AdditionalDocumentsallowedonly": "다음 문서 들만 추가 접수 가능",
        "Thiscargoishazardous": "외 화물은 위험물이거나 유해한 화뭏ㄹ을 포함하고 있습니다",
        "EditHazardousDetails": "위험물 상세 정보 입력",
        "ProperShippingName": "적절한 배송 이름",
        "UNNumber": "Un No",
        "Class": "Class",
        "SubsidiaryRisk": "종속 위험",
        "DangerousComponent": "위험물 구성 요소",
        "optionalIfyouhaveIMO": "선택 사항 - IMO 위험물 표시 또는 물질 안전 데이터 시트가 있으면 보다 정확한 요금을 제공하는 데 도움이 됩니다. *(pdf,png,jpeg)만 허용 - 크기(2M)",
        "MaterialSafety": "안전 데이터 시므로 허용되는 자료 (pdf,png,jpeg) - Size(2M)",
        "UnitofMeasureforWeight": "중량 측정 단위",
        "UnitofMeasureforTemperature": "온도 측정 단위",
        "NetWeight": "화물 중량",
        "GrossWeight": "총 중량",
        "FlashpointTemperature": "착화점 온도",
        "NetExplosiveQuantity": "순수 폭발성 양",
        "PackingGroup": "Packing Group",
        "PackageandDescriptionofCargo": "화물 포장 및 설명",
        "MarinePollution": "해영 오염",
        "LimitedQuantity": "한정 수량",
        "Comments": "보충",
        "Delete": "삭제",
        "AddCargodetails": "화물 상세 정보 추가",
        "Typethecodeyousee": "보이는 코드를 입력하시오",
        "Fromtheoptionsbelowselectifyouarea": "아래 선택 거래 사항 중 선택 바랍니다.",
        "Businessrepresentative": "사업자",
        "Privateperson": "개인",
        "Submit": "제출",
        
    },
    "vi": {
        "CARCARRIERSERVICE": "Dịch vụ vận chuyển xe hơi (RORO)",
        "CARCARRIERSERVICE1": "Yêu cầu vận chuyển xe hơi của bạn một cách an toàn và đáng tin cậy",
        "BookingDetails": "Chi tiết đặt chỗ",
        "Wheredoyouwanttoshipfrom": "Bạn muốn vận chuyển từ",
        "Wheredoyouwanttoshipto": "Bạn muốn vận chuyển đến",
        "Shipper": "Người gửi hàng",
        "Forwarder": "Người giao nhận",
        "ShipmentDate": "Ngày vận chuyển",
        "Additionalcomments": "Các yêu cầu thêm",
        "Contactdetails": "Thông tin liên hệ",
        "Contactname": "Tên",
        "PhoneNumber": "Số điện thoại",
        "Contactemail": "Thư điện tử",
        "EnterCargodetails": "Nhập thông tin chi tiết hàng hóa",
        "EnterCargodetails1": "Chọn 1 đơn vị đo lường cho các kích thước",
        "Meters": "Mét (m)",
        "Inches": "Inches (in)",
        "Pickaunitofmeasureforweight": "Chọn 1 đơn vị đo lường cho trọng lượng",
        "Kilograms": "Kilograms (kg)",
        "Pounds": "Pounds (lbs)",
        "CargoClass": "Hạng hàng hóa",
        "Condition": "Điều kiện",
        "Quantity": "Số lượng",
        "UnitWeight": "Đơn vị trọng lượng",
        "Length": "Chiều dài",
        "Width": "chiều rộng",
        "Height": "chiều cao",
        "TotalMeasurement": "Tổng kích thước",
        "TotalWeight": "Tổng trọng lượng",
        "PackageandDescriptionofCargo": "Đóng gói và miêu tả hàng hóa",
        "AdditionalDocumentsallowedonly": "Tài liệu bổ sung được phép",
        "Thiscargoishazardous": "Hàng hóa này nguy hiểm hoặc chứa các chất nguy hiểm",
        "EditHazardousDetails": "Chỉnh sửa Thông tin hàng nguy hiểm",
        "ProperShippingName": "Tên đơn vị vận chuyển thích hợp",
        "UNNumber": "Số UN",
        "Class": "Nhóm",
        "SubsidiaryRisk": "Rủi ro phụ",
        "DangerousComponent": "Thành phần nguy hiểm",
        "optionalIfyouhaveIMO": "Tùy chọn - Nếu bạn có Giấy dán nhãn hàng hóa nguy hiểm IMO hoặc Bảng dữ liệu an toàn vật liệu, nó sẽ giúp chúng tôi cung cấp cho bạn báo giá chính xác hơn. File được phép * (pdf, png, jpeg) - Kích cỡ (2M)",
        "MaterialSafety": "Chỉ tải bảng dữ liệu an toàn vật liệu *(pdf,png,jpeg) - Kích cỡ (2M)",
        "UnitofMeasureforWeight": "Đơn vị đo lường trọng lượng",
        "UnitofMeasureforTemperature": "Đơn vị đo lường nhiệt độ",
        "NetWeight": "Khối lượng tịnh",
        "GrossWeight": "Tổng trọng lượng hàng hóa và bao bì",
        "FlashpointTemperature": "Điểm phát hỏa",
        "NetExplosiveQuantity": "Số lượng chất nổ ròng",
        "PackingGroup": "Nhóm Đóng gói",
        "PackageandDescriptionofCargo": "Đóng gói và miêu tả hàng hóa",
        "MarinePollution": "Ô nhiễm biển",
        "LimitedQuantity": "Số lượng giới hạn",
        "Comments": "Ý kiến",
        "Delete": "Xóa",
        "AddCargodetails": "Thêm mô tả hàng hóa",
        "Typethecodeyousee": "Vui lòng nhập mã mà bạn thấy",
        "Fromtheoptionsbelowselectifyouarea": "Chọn 1 trong các tùy chọn sau, bạn là:",
        "Businessrepresentative": "Đại diện kinh doanh",
        "Privateperson": "Cá nhân",
        "Submit": "Nhấn để gửi",
        
    },
    "th": {
        "CARCARRIERSERVICE": "บริการขนส่งเรือบรรทุกรถยนต์",
        "CARCARRIERSERVICE1": "Request to ship your vehicle safe and reliable",
        "BookingDetails": "รายละเอียดการจอง",
        "Wheredoyouwanttoshipfrom": "สถานที่ต้นทาง",
        "Wheredoyouwanttoshipto": "สถานที่ปลายทาง",
        "Shipper": "ผู้ส่งออก",
        "Forwarder": "ตัวแทนหรือบริษัททำหน้าที่เป็นผู้ส่งออก",
        "ShipmentDate": "วันที่ขนส่ง",
        "Additionalcomments": "ความคิดเห็นเพิ่มเติม",
        "Contactdetails": "รายละเอียดผู้ติดต่อ",
        "Contactname": "ชื่อผู้ติดต่อ",
        "PhoneNumber": "เบอร์โทร",
        "Contactemail": "อีเมล",
        "EnterCargodetails": "ใส่รายละเอียดสินค้า",
        "EnterCargodetails1": "เลือกหน่วยวัดปริมาตร",
        "Meters": "เมตร",
        "Inches": "นิ้ว",
        "Pickaunitofmeasureforweight": "เลือกหน่วยวัดน้ำหนัก",
        "Kilograms": "กิโลกรัม",
        "Pounds": "ปอนด์",
        "CargoClass": "Cargo Class",
        "Condition": "เงื่อนไข",
        "Quantity": "จำนวน",
        "UnitWeight": "หน่วยน้ำหนัก",
        "Length": "ความยาว",
        "Width": "ความกว้าง",
        "Height": "ความสูง",
        "TotalMeasurement": "Total Measurement",
        "TotalWeight": "น้ำหนักรวม",
        "PackageandDescriptionofCargo": "บรรจุภัณฑ์และรายละเอียดสินค้า",
        "AdditionalDocumentsallowedonly": "อนุญาตให้ใช้เอกสารเพิ่มเติมเท่านั้น",
        "Thiscargoishazardous": "This cargo is hazardous or contains hazardous substances",
        "EditHazardousDetails": "แก้ไขรายละเอียดสินค้าอันตราย",
        "ProperShippingName": "ชื่อที่ถูกต้องในการขนส่ง (Proper Shipping Name)",
        "UNNumber": "หมายเลข UN",
        "Class": "ประเภท (Class)",
        "SubsidiaryRisk": "ความเสี่ยงรอง (Subsidiary Risk)",
        "DangerousComponent": "ส่วนประกอบทีเป็นอันตราย",
        "optionalIfyouhaveIMO": "หากคุณมีใบประกาศสินค้าอันตรายของ IMO หรือเอกสารข้อมูลความปลอดภัยของวัสดุ จะช่วยให้เราระบุอัตราที่ถูกต้องมากขึ้น *(pdf,png,jpeg) - ขนาด(2M)",
        "MaterialSafety": "อนุญาตให้ใช้เอกสารข้อมูล MSDS เท่านั้น *(pdf,png,jpeg) - ขนาด(2M)",
        "UnitofMeasureforWeight": "หน่วยวัดน้ำหนัก",
        "UnitofMeasureforTemperature": "หน่วยวัดอุณหภูมิ",
        "NetWeight": "น้ำหนักสุทธิ",
        "GrossWeight": "น้ำหนักรวม",
        "FlashpointTemperature": "จุดวาบไฟ",
        "NetExplosiveQuantity": "Net Explosive Quantity",
        "PackingGroup": "กลุ่มการบรรจุ (Packing Group)",
        "PackageandDescriptionofCargo": "บรรจุภัณฑ์และรายละเอียดสินค้า",
        "MarinePollution": "มลพิษทางทะเล",
        "LimitedQuantity": "จำกัดปริมาณ",
        "Comments": "ความคิดเห็น",
        "Delete": "ลบ",
        "AddCargodetails": "เพิ่มรายละเอียดสินค้า",
        "Typethecodeyousee": "โปรดใส่รหัสตามภาพ",
        "Fromtheoptionsbelowselectifyouarea": "จากตัวเลือกด้านล่าง ให้เลือกว่าคุณเป็น",
        "Businessrepresentative": "Business representative",
        "Privateperson": "Private person",
        "Submit": "ส่ง",
        
    },
    "in": {
        "CARCARRIERSERVICE": "CAR CARRIER SERVICE (RORO)",
        "CARCARRIERSERVICE1": "Permintaan untuk mengirimkan kendaraan Anda dengan aman dan andal",
        "BookingDetails": "Detail Pemesanan",
        "Wheredoyouwanttoshipfrom": "Mau kirim dari mana",
        "Wheredoyouwanttoshipto": "Mau kirim ke mana",
        "Shipper": "Pengirim",
        "Forwarder": "Ekspeditur",
        "ShipmentDate": "Tanggal Pengiriman",
        "Additionalcomments": "Komentar tambahan",
        "Contactdetails": "Detail kontak",
        "Contactname": "Nama kontak",
        "PhoneNumber": "Nomor telepon",
        "Contactemail": "E-mail kontak",
        "EnterCargodetails": "Masukkan detail Kargo",
        "EnterCargodetails1": "Pilih satuan ukuran untuk dimensi",
        "Meters": "Meter (m)",
        "Inches": "Inci (in)",
        "Pickaunitofmeasureforweight": "Pilih satuan ukuran untuk berat",
        "Kilograms": "Kilogram (kg)",
        "Pounds": "Pound (lbs)",
        "CargoClass": "Kelas Kargo",
        "Condition": "Kondisi",
        "Quantity": "Kuantitas",
        "UnitWeight": "Berat unit",
        "Length": "Panjang",
        "Width": "Lebar",
        "Height": "Tinggi",
        "TotalMeasurement": "Pengukuran Total",
        "TotalWeight": "Berat Total",
        "PackageandDescriptionofCargo": "Paket dan Deskripsi Kargo",
        "AdditionalDocumentsallowedonly": "Dokumen Tambahan hanya diperbolehkan",
        "Thiscargoishazardous": "Kargo ini berbahaya atau mengandung zat berbahaya",
        "EditHazardousDetails": "Edit Detail Berbahaya",
        "ProperShippingName": "Nama Pengiriman yang Tepat",
        "UNNumber": "Nomor UN",
        "Class": "Kelas",
        "SubsidiaryRisk": "Subsidiary Risk",
        "DangerousComponent": "Komponen Berbahaya",
        "optionalIfyouhaveIMO": "Opsional - Jika Anda memiliki Deklarasi Barang Berbahaya IMO atau Lembar Data Keselamatan Bahan, ini akan membantu kami memberi Anda tarif yang lebih tepat. hanya diperbolehkan *(pdf,png,jpeg) - Ukuran(2M)",
        "MaterialSafety": "Ukuran(2M)",
        "UnitofMeasureforWeight": "Satuan ukuran untuk berat",
        "UnitofMeasureforTemperature": "Satuan ukuran untuk suhu",
        "NetWeight": "Berat bersih",
        "GrossWeight": "Berat kotor",
        "FlashpointTemperature": "Suhu Titik Nyala",
        "NetExplosiveQuantity": "Kuantitas Ledakan Bersih",
        "PackingGroup": "Grup Pengepakan",
        "PackageandDescriptionofCargo": "Paket dan Deskripsi Kargo",
        "MarinePollution": "Polusi Laut",
        "LimitedQuantity": "Jumlah terbatas",
        "Comments": "Komentar",
        "Delete": "Hapus",
        "AddCargodetails": "Tambahkan detail Kargo",
        "Typethecodeyousee": "Ketik kode yang Anda lihat",
        "Fromtheoptionsbelowselectifyouarea": "Dari opsi di bawah ini, pilih jika Anda seorang",
        "Businessrepresentative": "Perwakilan bisnis",
        "Privateperson": "Orang pribadi",
        "Submit": "Ajukan",
   

    },
    "ja": {
        "CARCARRIERSERVICE": "自動車運搬業（RORO）",
        "CARCARRIERSERVICE1": "安全かつ確実に車を配送するよう依頼",
        "BookingDetails": "予約の詳細",
        "Wheredoyouwanttoshipfrom": "どこから発送しますか",
        "Wheredoyouwanttoshipto": "どこに配送しますか",
        "Shipper": "荷送人",
        "Forwarder": "運送業者",
        "ShipmentDate": "出荷日",
        "Additionalcomments": "追加コメント",
        "Contactdetails": "連絡先の詳細",
        "Contactname": "連絡先の名前",
        "PhoneNumber": "電話番号",
        "Contactemail": "連絡先のメールアドレス",
        "EnterCargodetails": "貨物の詳細を入力します",
        "EnterCargodetails1": "寸法の単位を選択します",
        "Meters": "メートル（m）",
        "Inches": "インチ（in）",
        "Pickaunitofmeasureforweight": "重量の単位を選択します",
        "Kilograms": "キログラム（kg）",
        "Pounds": "ポンド（lbs）",
        "CargoClass": "貨物等級",
        "Condition": "条件",
        "Quantity": "数量",
        "UnitWeight": "単位　重さ",
        "Length": "長さ",
        "Width": "幅",
        "Height": "高さ",
        "TotalMeasurement": "測定値合計",
        "TotalWeight": "総重量",
        "PackageandDescriptionofCargo": "貨物のパッケージと内容",
        "AdditionalDocumentsallowedonly": "追加文書のみ可",
        "Thiscargoishazardous": "この貨物は危険物であるか、危険物質を含んでいます",
        "EditHazardousDetails": "危険物の詳細情報を編集",
        "ProperShippingName": "正しい貨物の名称",
        "UNNumber": "UN番号",
        "Class": "等級",
        "SubsidiaryRisk": "付随するリスク",
        "DangerousComponent": "危険なコンポーネント",
        "optionalIfyouhaveIMO": "オプション- IMO危険物申告書か製品安全データシートをお持ちであれば、より正確な料金を算出するのに役立ちます。*（pdf、png、jpeg）-サイズ（2M）のみ可",
        "MaterialSafety": "製品安全データシート　*（pdf、png、jpeg）-サイズ（2M）のみ可",
        "UnitofMeasureforWeight": "重さの測定単位",
        "UnitofMeasureforTemperature": "温度の測定単位",
        "NetWeight": "正味重量",
        "GrossWeight": "総重量",
        "FlashpointTemperature": "引火温度",
        "NetExplosiveQuantity": "正味爆発物量",
        "PackingGroup": "パッキンググループ",
        "PackageandDescriptionofCargo": "貨物のパッケージと内容",
        "MarinePollution": "海洋汚染",
        "LimitedQuantity": "数量限定",
        "Comments": "コメント",
        "Delete": "削除",
        "AddCargodetails": "貨物の詳細を追加します",
        "Typethecodeyousee": "表示されたコードを入力します",
        "Fromtheoptionsbelowselectifyouarea": "お客様は次のどちらですか",
        "Businessrepresentative": "営業担当者",
        "Privateperson": "個人",
        "Submit": "送信",
   

    },
    
}
export default homelang;