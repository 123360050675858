import React, { useRef, useState } from 'react';
import Header from './header/index';
import Footer from './footer';
import "../../src/bootstrap.css";
import GoToTop from '../components/go-to-top';

const layout = (props) => {
    return (<div>
       
        <Header />
        {props.children}
        <Footer />
        <GoToTop />
    </div>);
}
export default layout;