import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import Services from "../../../components/get_in_touch/services";
import Packages from "../../../components/get_in_touch/packages_block";
import Inland from "../../../../src/assets/Inland.jpeg";
import abt_icn from "../../../../src/assets/abt-icn.png";
import Inner_container from "../../innerpages/inner-container";
import Api from "../../../../package.json";
import * as axios from "axios";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import "../tools/css/css-loader.css";
import moment from "moment";
import { useParams } from "react-router-dom";
import { NavLink as Link } from "react-router-dom";
import schedule_by_line_lang from '../../lang/schedule_schedule_by_line'

var lang = window.localStorage.getItem("lang");
if (window.localStorage.getItem("lang") == null) {
  lang = 'en';
  window.localStorage.setItem("lang", 'en');
}
const Schedule = React.lazy(() => import('../../homepagecommon/' + lang + '/solas-vgm'));
const qs = require("qs");
var jwt = require("jsonwebtoken");
const Schedule_by_line = () => {
  const headers = {
    type: "application/json",
    Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
  };
  const [country_code, setCountryCode] = useState(window.$country_code);
  const [shipline, setshipline] = useState([]);
  const [tradeOption, settradeOption] = useState("");
  const [loaderFlag, setloaderFlag] = useState("is-inactive");
  const [scheduleByLine, setscheduleByLine] = useState([]);
  const [schedulesearchflag, setschedulesearchflag] = useState(false);
  const [publishDate, setpublishDate] = useState("");
  const [lineName, setlineName] = useState("");
  const [weekNumber, setweekNumber] = useState("");
  const [vslRemarks, setvslRemarks] = useState([]);
  const [rec1, setrec1] = useState([]);
  const [sort_array, setsort_array] = useState([]);
  const [resultstaus, setresultstaus] = useState(false);
  const [submitlinecode, setsubmitlinecode] = useState(false);
  const [submittrades, setsubmittrades] = useState(false);
  const ref = useRef(null);
  const { linecode } = useParams();
  const { trades } = useParams();
  const buttonRef = useRef();
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const get_line_code = async (val) => {
    setshipline([]);
    if (val != "") {
      settradeOption(val);
      const response = await axios.post(
        Api.userdata.url + "/sailing_schedule_by_trade",
        qs.stringify({ line_type: val }, { parseArrays: false }), { headers: headers }
      );

      const json = await response.data;
      setshipline(json["message"]);


    }

    //track_shipment_submit(data);
  };
  const tare_weight_submit_print = async () => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/get_schedule_by_line_pdf_view",
      qs.stringify({
        country_code: country_code,
        sailing: getValues("shipline"),
      }),
      {
        headers: headers,
        responseType: "blob",
      }
    );
    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    window.focus();
    setloaderFlag("is-inactive");
  };
  const tare_weight_submit_pdf = async () => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/get_schedule_by_line_pdf_view",
      qs.stringify({
        country_code: country_code,
        sailing: getValues("shipline"),
      }),
      {
        headers: headers,
        responseType: "blob",
      }
    );
    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const tempLink = document.createElement('a');
    tempLink.href = fileURL;
    tempLink.setAttribute('download', getValues("shipline") + "-linecode" + '.pdf');
    tempLink.click();
    setloaderFlag("is-inactive");
  };
  const onSubmit = (data) => {
    console.log(data);
    schedule_by_line_submit(data);
  };
  const schedule_by_line_submit = async (data) => {
    setloaderFlag("is-active");
    const response = await axios.post(
      Api.userdata.url + "/get_schedule_by_line",
      qs.stringify({
        country_code: country_code,
        sailing: data.shipline,
      }), { headers: headers }
    );
    setsubmittrades(data.trade);
    setsubmitlinecode(data.shipline);
    const json = await response.data;

    setloaderFlag("is-inactive");
    setschedulesearchflag(true);

    if (json["status"] == "OK") {
      setrec1([]);
      setsort_array([]);
      setpublishDate(json["result"]["publishDate"]);
      setweekNumber(json["result"]["weekNumber"]);
      setlineName(json["result"]["lineName"]);
      var vslRemarkss = json["result"]["vslRemarks"];
      setvslRemarks(vslRemarkss);
      console.log(vslRemarkss);
      var sort_arrays = json["result"]["sort_array"];
      var rec1s = json["result"]["rec1"];
      setrec1(rec1s);
      setsort_array(sort_arrays);
     
    
      setresultstaus(true);
    } else {
      setresultstaus(false);

    }
  };
  useEffect(() => {
    if (linecode != null && trades != "") {
      settradeOption(trades);
      get_line_code(trades);
      setValue('trade', trades);
      setValue("shipline", linecode);
      buttonRef.current.click();

    }
  }, [linecode, trades]);

  return (
    <Layout>
      <div class={"loadernew loader-double " + loaderFlag}></div>
      <section class="inner-center">
        <br></br>
        <div class="container">
          <div class="row m-0">
            <div class="col-lg-12 col-md-12 col-lg-12">
              <form
                autocomplete="on"
                name="point-to-point-new"
                id="point-to-point-new"
                method="get"
                action="#"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div class="form-fback">
                  <div class="row m-0">
                    <div class="col-lg-5 col-md-12 col-sm-12 pot-form p-3">
                      <div class="dout-inner">
                        <h3>{schedule_by_line_lang[lang].SchedulebyLine}</h3>
                        <p>{schedule_by_line_lang[lang].SchedulebyLine1}</p>
                      </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 p-0 pot-form ">
                      <label>{schedule_by_line_lang[lang].Trade}</label>
                      <select
                        class="selectpicker"
                        id="trade"
                        {...register("trade", {
                          required: {
                            value: true,
                            message: "This field is required.",
                          },
                        })}
                        value={tradeOption}
                        onChange={(e) => get_line_code(e.target.value)}
                      >
                        <option value="">{schedule_by_line_lang[lang].SelectTrade}</option>

                        <option value="Intra-Asia" data-id="2">
                        {schedule_by_line_lang[lang].IntraAsia}
                        </option>
                        <option value="Asia-Africa" data-id="21">
                        {schedule_by_line_lang[lang].AsiaAfrica}
                        </option>
                        <option value="Asia-Oceania" data-id="21">
                        {schedule_by_line_lang[lang].AsiaOceania} 
                        </option>
                        <option value="India-Subcon-And-Middle-East" data-id="21">
                        {schedule_by_line_lang[lang].IndiaSubconMiddleEast} 
                        </option>
                        <option value="RORO" data-id="21">
                        {schedule_by_line_lang[lang].RORO} 
                        </option>
                      </select>
                      {errors.trade && (
                        <span class="errors">{errors.trade.message}</span>
                      )}
                      <label>{schedule_by_line_lang[lang].ShippingLine}</label>
                      <Controller
                        control={control}
                        rules={{
                          required: "Please select any one Shipping Line",
                        }}
                        render={({ onChange, value, ref }) => (
                          <Select
                            options={shipline.map((data) => {
                              {
                                {
                                  var full_name = data["full_name"];
                                  if (data['more_lang_details'] != '' && data['more_lang_details'] != null) {
                                    const obj = JSON.parse(data['more_lang_details']);
                                    if (typeof obj[lang] != 'undefined' && typeof obj[lang] != '') {
                                      full_name = obj[lang]
                                    }
                                  } 

                                }
                              } return ({
                                value: data["name"],
                                label: full_name
                              })
                            })}
                            onChange={(val) => {
                              setValue("shipline", val.value);
                            }}
                          />
                        )}
                        isClearable={true}
                        placeholder="Select platform"
                        {...register("shipline")}
                        id="shipline"
                        setValue={setValue}

                        defaultInputValue={linecode}
                      />
                      {errors.shipline && (
                        <span class="errors">{errors.shipline.message}</span>
                      )}
                      <div>
                        <button
                          type="submit"
                          class="btn btn-default hovereffect portal-searc mt-4"
                          ref={buttonRef}
                        >
                          {schedule_by_line_lang[lang].Search}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="container" style={{ minHeight: `200px;` }}>
          {resultstaus == true ? (
            <>  <div class="row">
              <div
                id="fill"
                class="col-md-12 col-lg-12 col-sm-12 p-4 sch-line-tb-form"
                style={{ display: schedulesearchflag ? "block" : "none" }}
              >
                <div class="">
                  <div class="pb-2 pr-2 sch-full-form-btn text-right">
                    <p class="tariff-print" style={{ padding: `10px` }}>
                      <a class="btn btn-info btn-lg" onClick={tare_weight_submit_print} >
                        <span class="glyphicon glyphicon-print"></span>  {schedule_by_line_lang[lang].Clicktoviewschedule}
                      </a>
                    </p>


                  </div>
                  <div class="row pb-1 pr-0">
                    <div class="col-md-8 col-12">
                      <p class="pt-2">
                        {" "}
                        <b>{schedule_by_line_lang[lang].LineName}: </b>
                        {lineName} | <b>{schedule_by_line_lang[lang].Number}: </b> {weekNumber} |{" "}
                        <b>{schedule_by_line_lang[lang].PublishDate} : </b>
                        {publishDate}
                      </p>
                    </div>
                    <div
                      class="col-md-1 col-2 col-xs-3 directional-arrow"
                      onClick={() => {
                        ref.current.scrollLeft -= 60;
                      }}
                    >
                      <a
                        id="left-button"
                        class="btn btn-lg "
                        href="javascript: void(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-arrow-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                          ></path>
                        </svg>{" "}
                      </a>
                    </div>
                    <div
                      class="col-md-1  col-2 col-xs-3 directional-arrow"
                      onClick={() => {
                        ref.current.scrollLeft += 60;
                      }}
                    >
                      <a
                        id="right-button"
                        class="btn btn-lg"
                        href="javascript: void(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-arrow-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                          ></path>
                        </svg>
                      </a>
                    </div>
                    <div
                      class="col-md-1 col-xs-3  col-2 directional-arrow"
                      onClick={() => {
                        ref.current.scrollTop += 60;
                      }}
                    >
                      <a
                        id="top-button"
                        class="btn btn-lg "
                        href="javascript: void(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-arrow-down"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                          />
                        </svg>
                      </a>
                    </div>
                    <div
                      class="col-md-1 col-xs-3 col-2 directional-arrow"
                      onClick={() => {
                        ref.current.scrollTop -= 60;
                      }}
                    >
                      <a
                        id="bottom-button"
                        class="btn btn-lg"
                        href="javascript: void(0)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-arrow-up"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="table-responsive" border="1" ref={ref}>
                  <table align="center" border="1">
                    <thead class="sch-tb-row-head">
                      <tr>
                        <th valign="top">
                          <p>
                            {schedule_by_line_lang[lang].Vessel}<br></br>
                            <br></br>
                          </p>
                          <p>{schedule_by_line_lang[lang].Voyage}</p>
                          <p>{schedule_by_line_lang[lang].Partnervoyage}</p>
                        </th>
                        {rec1.length > 0 && sort_array.length >0 &&
                          sort_array.map((sort_arrays, index) => {
                            {
                              {
                                var h1 = (typeof rec1[sort_arrays[1]]["vesselName"] !== "undefined") ? rec1[sort_arrays[1]]["vesselName"] : '';
                                var h3 = rec1[sort_arrays[1]]["vesselCode"];
                                var h2 = rec1[sort_arrays[1]]["voyNo"];
                                var partnerVoyage = [];
                                var partnerVoyage_new = [];
                                var test = "";
                                rec1[sort_arrays[1]]["ports"].forEach(
                                  (ports) => {
                                    if (
                                      ports["partnerVoyage"] != "" &&
                                      ports["partnerVoyage"] != null
                                    ) {
                                      partnerVoyage.push(
                                        ports["partnerVoyage"]
                                      );
                                    }
                                  }
                                );
                                const unique = new Set(partnerVoyage);

                                partnerVoyage_new = [...unique]; // array
                              }
                            }
                            return (
                              <th valign="top">
                                <p>{h1}<br></br> {"(" + h3 + ")"}</p>
                                <p>{h2}</p>
                                {partnerVoyage_new &&
                                  partnerVoyage_new.map((ports, index) => {
                                    return <p>{ports}</p>;
                                  })}
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody class="sch-tb-row-body">
                      {rec1.length > 0 &&
                        rec1[0]["ports"].map((ports, k) => {
                          {
                            {
                            }
                          }
                          return (
                            <tr>
                              <td>
                                <p> {schedule_by_line_lang[lang].Port}</p>
                                <p> {schedule_by_line_lang[lang].Arrival}</p>
                                <p> {schedule_by_line_lang[lang].Departure}</p>
                              </td>
                              {sort_array &&
                                sort_array.map((sort_arrays, n) => {
                                  {
                                    {
                                      var port_key = "";
                                      var exceptionPort = "";
                                      var direction = "";
                                      var output_v1 = "-";
                                      var output_v2 = "-";
                                      var output_v3 = "-";
                                      if (
                                        rec1[sort_arrays[1]]["ports"][k][
                                        "locArrDate"
                                        ]
                                      ) {
                                        if (
                                          rec1[sort_arrays[1]]["ports"][k][
                                          "exceptionPort"
                                          ] == "Y"
                                        ) {
                                          exceptionPort = "*";
                                        }
                                        direction =
                                          rec1[sort_arrays[1]]["ports"][k][
                                          "direction"
                                          ];
                                        if (
                                          rec1[sort_arrays[1]]["ports"][k][
                                          "locArrDate"
                                          ]
                                        ) {
                                          output_v1 =
                                            exceptionPort +
                                            " " +
                                            rec1[sort_arrays[1]]["ports"][k][
                                            "portName"
                                            ] +
                                            "(" +
                                            direction +
                                            ")";
                                        }
                                      }
                                      if (
                                        rec1[sort_arrays[1]]["ports"][k][
                                        "locArrDate"
                                        ]
                                      ) {
                                        var dt =
                                          rec1[sort_arrays[1]]["ports"][k][
                                          "locArrDate"
                                          ];

                                        output_v2 = moment(dt).format("D-MMM");
                                      }
                                      if (
                                        rec1[sort_arrays[1]]["ports"][k][
                                        "locDepDate"
                                        ]
                                      ) {
                                        var dt =
                                          rec1[sort_arrays[1]]["ports"][k][
                                          "locDepDate"
                                          ];
                                        output_v3 = moment(dt).format("D-MMM");
                                      }
                                    }
                                  }
                                  return (
                                    <td
                                      className={
                                        "schedule-" + (n % 2 ? "odd" : "even")
                                      }
                                    >
                                      <p>{output_v1}</p>
                                      <p>{output_v2}</p>
                                      <p>{output_v3}</p>
                                    </td>
                                  );
                                })}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div class="voyage_details_base">
                  <div class="container">
                    <div class="voyage_details_inner">
                      <h5>{schedule_by_line_lang[lang].VOYAGEREMARKS}</h5>
                      {vslRemarks &&
                        vslRemarks.map((vslRemarkss, n) => {
                          return <p>{vslRemarkss}</p>;
                        })}
                    </div>
                  </div>
                </div>
                <div class="voyage_details_base">
                  <div class="container">
                    <div class="voyage_details_inner">
                      <h5>{schedule_by_line_lang[lang].Exceptionalportforvoyage}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div class="row  p-4">
                <p class="tariff-print" style={{ padding: `10px` }}>
                  <a class="btn btn-info btn-lg" onClick={tare_weight_submit_print} >
                    <span class="glyphicon glyphicon-print"></span>{schedule_by_line_lang[lang].Print}
                  </a>
                </p>
                <p class="tariff-print" style={{ padding: `10px` }}>
                  <a class="btn btn-info btn-lg" onClick={tare_weight_submit_pdf} >
                    <span class="glyphicon glyphicon-print"></span> {schedule_by_line_lang[lang].PDF}
                  </a>
                </p>
              </div> </>
          ) : (
            <div
              class="row"
              style={{
                minHeight: `200px;`,
                border: `solid 1px;`,
                display: schedulesearchflag ? "block" : "none",
              }}
            >
              <div class="col">
                <h3 style={{ paddingTop: `72px;`, textAlign: `center;` }}>
                  <i class="fa fa-search" aria-hidden="true"></i> {schedule_by_line_lang[lang].NoRecordFound}
                </h3>
              </div>
            </div>
          )}
        </div>
      </section>
      <Services />
      <Packages />
      <GET_IN_TOUCH />
    </Layout>
  );
};
export default Schedule_by_line;
