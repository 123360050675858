import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import container_cargo from '../../../../src/assets/container-cargo.png';
import abt_icn from '../../../../src/assets/abt-icn.png';
import Inner_container from '../../innerpages/inner-container';
import Cargo_services_lang from '../../lang/cargo-services';
import play_icon from "../../../../src/assets/play-icon.png";
import { Accordion, Card, Button, ButtonGroup } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';



var jwt = require('jsonwebtoken');
export default class Cargo_services extends Component {

  render() {
    var lang = window.localStorage.getItem('lang');
    return (

      <Layout>

        <Inner_container innerpage="cargo_services" />


        <section class="inner-center n-40">
          <div class="container">

            <div class="row ml-0 mr-0 pjt-cargo-row">

              <div class=" ">

                <div class=" car-ser-new" >
                  <h1>eB/L Service</h1>
                  <h3 className='mb-3'>Secure, Smooth and Sustainable</h3>
                  <h4>ZIM’s Digital Documentation Platform is Now Available!</h4>
                  <p className='mb-3'>ZIM leads the way with a revolutionary platform that digitizes all trade-related documents, including Bills of Lading, Bank Guarantees, Certificates of Origin, Bills of Exchange, Promissory notes, Certificates of Inspection, Invoices, etc.</p>
                  <p className='mb-5'>From the smallest importer to the biggest bank, ZIM's easy to use paperless bills of lading platform powered by WAVE BL offers a decentralized way to issue, transfer, endorse, and deliver bills of lading documents with <strong>full control of your privacy.</strong> </p>
                </div>
                <div className='text-center mb-5'>

                  <button
                    type="submit"
                    name="submit"
                    id="submit"
                    class="btn btn-default hovereffect mr-1">GO TO WAVE B/L</button>
                </div>
              </div>

              <ul>
                <li className='mb-3'>By clicking on the link below you acknowledge that registration to the electronic Bill of Lading contractual terms of carriage shall be subject to ZIM's B/L terms and conditions.</li>
                <li className='mb-3'>By clicking on the link below you agree to be transferred to Wave BL website, which is not operated or managed by ZIM. ZIM shall not be liable for any damages incurred as a result of your use of Wave BL website.</li>

              </ul>


            </div>

            <div className='ebl-card-sec'>
              <div className='ebl-card p-4'>
                <h3>Safe & Secure</h3>
                <p>Every transaction is recorded and verifiable, thanks to blockchain technology.</p>

              </div>
              <div className='ebl-card p-4'>
                <h3>Instant Transfer</h3>
                <p>Every transfer takes minutes..</p>

              </div>
              <div className='ebl-card p-4'>
                <h3>Seamless Business</h3>
                <p>No disruptions from loss, delay or forgery. At a fraction of the cost.</p>

              </div>

            </div>

            <div className='ebl-video-sec common-padd'>

              <video controls="true">
                <source src="https://www.youtube.com/watch?v=O3LBm-tac4c" type="video/mp4" />
              </video>

              <div className='ebl-play-btn'>
                <img src={play_icon} />
              </div>
            </div>

          </div>
        </section>


        <section className='accodian-sec'>
          <div className='container'>
            <h1 className='ebl-tittle mb-5'>eBL FAQ</h1>

            <div className='d-flex align-items-center mb-5'>
              <Form.Control size="lg" type="text" placeholder="Large text" /><span className='search-btn'><a href=''>Search</a></span>
            </div>
            <Accordion defaultActiveKey="0">
            <div class="accordion-toggle acc-pannel-foot">
  <Card>
    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
        Click me!
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>Hello! I'm the body</Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card>
    <Card.Header bsPrefix="h3" className="accordion-link accordion-on">
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
        Click me 2
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body>Hello! I'm another body</Card.Body>
    </Accordion.Collapse>
  </Card>
  </div>
</Accordion>



          </div>
        </section>
        <Services />
        <Packages />
        <GET_IN_TOUCH />
      </Layout>);
  }
}