import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';

import Inner_container from '../../innerpages/inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';


import inside_heading from '../../../../src/assets/inside-heading.png';
import flak from '../../../../src/assets/flak.png';
import dry from '../../../../src/assets/dry.png';
import high_cube from '../../../../src/assets/high-cube.png';
import open_top from '../../../../src/assets/open-top.png';
import flexitank from '../../../../src/assets/flexitank.png';
import reefer from '../../../../src/assets/reefer.png';
import reefercube from '../../../../src/assets/reefercube.png';
import Cargo_catalogue_lang from '../../lang/cargo_catalogue';



const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class Cargo_catalogue extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            activeTabIndex: 0


        };

    }
    async componentDidMount() {

        const headers = {
            'type': 'application/json',
            "Access-Control-Origin": "*",
            'Authorization': jwt.sign({ foo: 'bar' }, '123456')
        }

       

    }
    changeTab(newActiveTabIndex) {
        this.setState({
          activeTabIndex: newActiveTabIndex
        });
      }
    render() {
        const { activeTabIndex } = this.state;
		var lang = window.localStorage.getItem('lang');
        return (

            <Layout>
				<Inner_container innerpage="cargo_catalogue" />

                <section class="inner-center n-40">
                    <div class="container">
                        {/* <div class="row m-0">
                            <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12 p-0">
                                <div class="sche-title" style={{ backgroundImage: `url(${inside_heading})` }}>
                                    <h3>Cargo Catalogue</h3>
                                </div>
                            </div>
                        </div> */}
                        <ul class="nav nav-tabs trade-tab-li" role="tablist">
                <li role="presentation" class="nav-item">
                    <a href="javascript: void(0)" className={activeTabIndex === 0 ? 'nav-link active' : 'nav-link'}
                    onClick={this.changeTab.bind(this, 0)} >{Cargo_catalogue_lang[lang].DryVan}</a>
                </li>
                <li role="presentation" class="nav-item">
                    <a href="javascript: void(0)" className={activeTabIndex === 1 ? 'nav-link active' : 'nav-link'}
                    onClick={this.changeTab.bind(this, 1)} >{Cargo_catalogue_lang[lang].HighCube}</a>
                </li>
                <li role="presentation" class="nav-item">
                    <a href="javascript: void(0)" className={activeTabIndex === 2 ? 'nav-link active' : 'nav-link'}
                    onClick={this.changeTab.bind(this, 2)} >{Cargo_catalogue_lang[lang].Opentop}</a>
                </li>
                <li role="presentation" class="nav-item">
                    <a href="javascript: void(0)" className={activeTabIndex === 3 ? 'nav-link active' : 'nav-link'}
                    onClick={this.changeTab.bind(this, 3)}  >{Cargo_catalogue_lang[lang].Flexitank} </a>
                </li>
				<li role="presentation" class="nav-item">
                    <a href="javascript: void(0)" className={activeTabIndex === 4 ? 'nav-link active' : 'nav-link'}
                    onClick={this.changeTab.bind(this, 4)}  >{Cargo_catalogue_lang[lang].Reefer} </a>
                </li>
				<li role="presentation" class="nav-item">
                    <a href="javascript: void(0)" className={activeTabIndex === 5 ? 'nav-link active' : 'nav-link'}
                    onClick={this.changeTab.bind(this, 5)}  >{Cargo_catalogue_lang[lang].ReeferHighCube} </a>
                </li>
				<li role="presentation" class="nav-item">
                    <a href="javascript: void(0)" className={activeTabIndex === 6 ? 'nav-link active' : 'nav-link'}
                    onClick={this.changeTab.bind(this, 6)}  >{Cargo_catalogue_lang[lang].Flatrack} </a>
                </li>
            </ul>
            <div class="tab-content border trade-tab-con booking-tab-cont">
            <div className={activeTabIndex === 0 ? 'tab-pane active' : 'tab-pane'}  role="tabpanel" id="step1">
                        <div class=" ">
							<h2 class="text-center table-heading">{Cargo_catalogue_lang[lang].DryVan}  </h2> 
									<div class="row m-0">
										<h3 class="table-heading1"> {Cargo_catalogue_lang[lang].GeneralUse}: </h3>
										<div class="col-md-7">
											<p class="image-l-txt"> {Cargo_catalogue_lang[lang].Suitableforcommoditiesinbundles}.</p>
										</div>
										<div class="col-md-5">
											<img src={dry} class="table-img" alt=""/>
										</div>
									</div>
									<div class="table-responsive">
										<table class="table table-bordered gsl-equi">
										
											  <colgroup span="3"></colgroup>
											  <colgroup span="3"></colgroup>
											  <tr>
												<th>{Cargo_catalogue_lang[lang].Size}</th>
												<th colspan="3" scope="colgroup">{Cargo_catalogue_lang[lang].WeightKgsLbs}</th>
												<th colspan="3" scope="colgroup">{Cargo_catalogue_lang[lang].InternalDimensionsMMFtInches}</th> 
												<th colspan="2" scope="colgroup">{Cargo_catalogue_lang[lang].DoorOpeningsMMFtInches}</th> 
												<th>{Cargo_catalogue_lang[lang].Vol}({Cargo_catalogue_lang[lang].m}<sup>3</sup> {Cargo_catalogue_lang[lang].fl}<sup>3</sup>)</th> 
												<th>{Cargo_catalogue_lang[lang].notes}</th>
												
											  </tr>
											  <tr>
												<th> {Cargo_catalogue_lang[lang].Feet} </th>
												<th scope="col">{Cargo_catalogue_lang[lang].MaxGrossWeight}</th>
												<th scope="col">{Cargo_catalogue_lang[lang].TareWeight}</th>
												<th scope="col">{Cargo_catalogue_lang[lang].MaxPayload}</th>
												<th scope="col">{Cargo_catalogue_lang[lang].Length}</th>
												<th scope="col">{Cargo_catalogue_lang[lang].Width}</th>
												<th scope="col">{Cargo_catalogue_lang[lang].Height}</th>
												<th scope="col">{Cargo_catalogue_lang[lang].Width}</th>
												<th scope="col">{Cargo_catalogue_lang[lang].Height}</th>
												<th> {Cargo_catalogue_lang[lang].Capacity} </th>
												<th> {Cargo_catalogue_lang[lang].Allsteelconstruction}	</th>									
											  </tr>
											  <tr>
												<th rowspan="4" scope="rowgroup">20</th>
												<td>24,000</td>
												<td>2,200</td>
												<td>21,800</td>
												<td>5,902</td>
												<td>2,350</td>
												<td>2,392</td>
												<td>2,341</td>
												<td>2,280</td>
												<td>33.2</td>
												<th rowspan="4" scope="rowgroup"></th>
											  </tr>
											  <tr>
												<td>52,910</td>
												<td>4,850</td>
												<td>48,060</td>
												<td>19' 4 <br/> 23/64"</td>
												<td>7' 8 <br/> 33/64"</td>
												<td>7' 10 <br/> 11/64"</td>
												<td>7' 8 <br/> 11/64" </td>
												<td>7' 5 <br/> 49/64"</td>
												<td>1,172</td>
											  </tr>
											  <tr>
												<td>30,480</td>
												<td>2,250</td>
												<td>28,230</td>
												<td>5,902</td>
												<td>2,350</td>	
												<td>2,392</td>
												<td>2,341</td>
												<td>2,280</td>
												<td>33.2</td>
											  </tr>
											   <tr>
												<td>67,200</td>
												<td>4,960</td>	
												<td>62,240</td>	
												<td>19' 4<br/>
												23/64"</td>	
												<td>7' 8<br/>
												33/64"</td>
												<td>7' 10<br/>
												11/64"	</td>
												<td>7' 8<br/>
												11/64"</td>
												<td> 7' 5
												49/64"	</td>
												<td>1,172</td>
											  </tr>
											  <tr>
												<th rowspan="4" scope="rowgroup">40</th>
												<td>30,480</td>
												<td>3,700</td>
												<td>26,780</td>
												<td>12,032</td>
												<td>2,350</td>
												<td>2,390</td>
												<td>2,338</td>
												<td>2,280</td>
												<td>67.6</td>
												<th rowspan="4" scope="rowgroup"></th>
											  </tr>
											    <tr>
												<td>67,200</td>
												<td>8,380</td>
												<td>58,820</td>
												<td>39' 5 <br/>45/64"
												</td>
												<td>7' 8 <br/>33/64"
												</td>
												<td>7' 10<br/>7/64"
												</td>
												<td>7' 8 <br/>3/64"
												</td>
												<td>7' 5 <br/>49/64"
												</td>
												<td>2,387</td>
											  </tr>
										
											</table>
									
										<p style={{padding:`10px 20px`}} class="table-bot-text">
											{Cargo_catalogue_lang[lang].Allinformationinthisguide}
										
										</p>
										
									</div>							
															
						</div>														
                    </div>
                    <div  className={activeTabIndex === 1 ? 'tab-pane active' : 'tab-pane'} role="tabpanel" id="step2">
									<div class="">
											<h2 class="text-center table-heading">{Cargo_catalogue_lang[lang].HighCube} </h2> 
											<div class="row m-0">
												<h3 class="table-heading1"> {Cargo_catalogue_lang[lang].GeneralUse}: </h3>
												<div class="col-md-7">
													<p class="image-l-txt"> {Cargo_catalogue_lang[lang].Suitableforcommoditiesinbundles}. </p>
												</div>
												<div class="col-md-5">
													<img src={high_cube} class="table-img" alt=""/>
												</div>
											</div>
											<div class="table-responsive">
												<table class="table table-bordered gsl-equi">
													<col></col>
													  <colgroup span="3"></colgroup>
													  <colgroup span="3"></colgroup>
													  <tr>
														<th>{Cargo_catalogue_lang[lang].Size}</th>
														<th colspan="3" scope="colgroup">{Cargo_catalogue_lang[lang].WeightKgsLbs}</th>
														<th colspan="3" scope="colgroup">{Cargo_catalogue_lang[lang].InternalDimensionsMMFtInches}</th>
														<th colspan="2" scope="colgroup">{Cargo_catalogue_lang[lang].DoorOpeningsMMFtInches}</th>
														<th>{Cargo_catalogue_lang[lang].Vol}({Cargo_catalogue_lang[lang].m}<sup>3</sup> {Cargo_catalogue_lang[lang].fl}<sup>3</sup>)</th>
														<th>{Cargo_catalogue_lang[lang].notes}</th>
														
													  </tr>
													  <tr>
														<th> {Cargo_catalogue_lang[lang].Feet} </th>
														<th scope="col">{Cargo_catalogue_lang[lang].MaxGrossWeight}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].TareWeight}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].MaxPayload}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].Length}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].Width}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].Height}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].Width}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].Height}</th>
														<th> {Cargo_catalogue_lang[lang].Capacity} </th>
														<th> {Cargo_catalogue_lang[lang].Allsteelconstruction}	</th>									
													  </tr>
													  <tr>
														<th rowspan="4" scope="rowgroup">40</th>
														<td>30,480</td>
														<td>3,900</td>
														<td>26,580</td>
														<td>12,033</td>
														<td>2,350</td>
														<td>2,695.5</td>
														<td>2,338</td>
														<td>2,585</td>
														<td>76.2</td>
														<th rowspan="4" scope="rowgroup"></th>
													  </tr>
													  <tr>
														<td>67,200 </td>
														<td>8,600</td>
														<td>58,600</td>
														<td>39' 5 <br/>
														45/64"</td>
														<td>7' 8<br/>
														33/64"</td>
														<td>8' 10 <br/>
														1/8"</td>
														<td>7' 8 <br/>
														3/64"</td>
														<td>8' 5 <br/>
														49/64"</td>
														<td>2,690</td>
													  </tr>
													  <tr>
														<td>32,500 </td>
														<td>3,920</td>
														<td>28,580</td>
														<td>12,033</td>
														<td>2,350</td>
														<td>2,695.5</td>
														<td>2,338</td>
														<td>2,585</td>
														<td>76.4</td>
													  </tr>
													   <tr>
															<td>71,650 </td>
															<td>8,640</td>
															<td>63,010</td>
															<td>39' 5 <br/>
															45/64"</td>
															<td>7' 8<br/>
															33/64"</td>
															<td>8' 10 <br/>
															1/8"</td>
															<td>7' 8<br/> 
															3/64"</td>
															<td>8' 5 <br/>
															49/64"</td>
															<td>2,690</td>
													  </tr>
													  <tr>
														<th rowspan="4" scope="rowgroup">20</th>
														<td>30,480</td>
														<td>4,800</td>
														<td>25,680</td>
														<td>13,556</td>
														<td>2,350</td>
														<td>2,698</td>
														<td>2,338</td>
														<td>2,585</td>
														<td>86.0</td>
														<th rowspan="4" scope="rowgroup"></th>
													  </tr>
													  <tr>
														<td>67,200 </td>
														<td>10,580</td>
														<td>56,620</td>
														<td>44' 5 
														11/16</td>
														<td>8 
														33/64</td>
														<td>10
														7/32</td>
														<td>7' 8 
														3/64"</td>
														<td>8' 5 
														49/64"</td>
														<td>3,040</td>
													  </tr>
												
													</table>
											
												<p style={{padding:`10px 20px`}} class="table-bot-text">
													{Cargo_catalogue_lang[lang].Allinformationinthisguide}.
												
												</p>
												
											</div>
									</div>
						</div>
                        <div  className={activeTabIndex === 2 ? 'tab-pane active' : 'tab-pane'} role="tabpanel" id="step3">
									<div class="">
										<h2 class="text-center table-heading">{Cargo_catalogue_lang[lang].OpenTop}</h2> 
										<div class="row m-0">
											<h3 class="table-heading1"> {Cargo_catalogue_lang[lang].GeneralUse}: </h3>
											<div class="col-md-7">
												<p class="image-l-txt"> {Cargo_catalogue_lang[lang].Suitableforallorlargecargo}. </p>
											</div>
											<div class="col-md-5">
												<img src={open_top} class="table-img" alt=""/>
											</div>
										</div>
										<div class="table-responsive">
											<table class="table table-bordered gsl-equi">
												
												  <colgroup span="3"></colgroup>
												  <colgroup span="3"></colgroup>
												  <tr>
													<th>{Cargo_catalogue_lang[lang].Size}</th>
													<th colspan="3" scope="colgroup">{Cargo_catalogue_lang[lang].WeightKgsLbs}</th>
													<th colspan="3" scope="colgroup">{Cargo_catalogue_lang[lang].InternalDimensionsMMFtInches}</th>
													<th colspan="2" scope="colgroup">{Cargo_catalogue_lang[lang].DoorOpeningsMMFtInches}</th>
													<th>{Cargo_catalogue_lang[lang].Vol}({Cargo_catalogue_lang[lang].m}<sup>3</sup> {Cargo_catalogue_lang[lang].fl}<sup>3</sup>)</th>
													<th>{Cargo_catalogue_lang[lang].notes}</th>
													
												  </tr>
												  <tr>
													<th> {Cargo_catalogue_lang[lang].Feet} </th>
													<th scope="col">{Cargo_catalogue_lang[lang].MaxGrossWeight}</th>
													<th scope="col">{Cargo_catalogue_lang[lang].TareWeight}</th>
													<th scope="col">{Cargo_catalogue_lang[lang].MaxPayload}</th>
													<th scope="col">{Cargo_catalogue_lang[lang].Length}</th>
													<th scope="col">{Cargo_catalogue_lang[lang].Width}</th>
													<th scope="col">{Cargo_catalogue_lang[lang].Height}</th>
													<th scope="col">{Cargo_catalogue_lang[lang].Width}</th>
													<th scope="col">{Cargo_catalogue_lang[lang].Height}</th>
													<th> {Cargo_catalogue_lang[lang].Capacity} </th>
													<th> {Cargo_catalogue_lang[lang].Doorheadlockremovablesteel}	</th>									
												  </tr>
												  <tr>
													<th rowspan="2" scope="rowgroup">20</th>
													<td>24,000</td>
													<td>2,140</td>
													<td>21,860</td>
													<td>5,894</td>
													<td>2,344</td>
													<td>2,347</td>
													<td>2.336</td>
													<td>2,275</td>
													<td>31.5</td>
													<th rowspan="4" scope="rowgroup"></th>
												  </tr>
												  <tr>
														<td> 52,910 </td>
														<td> 4,717</td>
														<td> 48,192</td>
														<td> 19' 4 <br/>
														1/8"</td>
														<td> 7' 8 <br/>
														5/16"</td>
														<td> 7' 8 <br/>
														3/8"</td>
														<td> 7' 7 <br/>
														15/16"</td>
														<td> 7' 5 <br/>
														1/2"</td>
														<td> 1,112</td>
												  </tr>
												
												  <tr>
													<th rowspan="2" scope="rowgroup">20</th>
														<td>30,480 </td>
														<td>2,250</td>
														<td>28,230</td>
														<td>5,894</td>
														<td>2,344</td>
														<td>2,347</td>
														<td>2.336</td>
														<td>2,275</td>
														<td>31.5</td>
													<th rowspan="4" scope="rowgroup"></th>
												  </tr>
												  <tr>
													<td> 67,200 </td>
													<td> 4,960 </td>
													<td> 62,240 </td>
													<td> 19' 4 
													1/8" </td>
													<td> 7' 8 
													5/16"</td>
													<td> 7' 8 
													3/8"</td>
													<td> 7' 7 
													15/16"</td>
													<td> 7' 5 
													1/2"</td>
													<td> 1,112</td>
												  </tr>
												  
												  <tr>
													<th rowspan="2" scope="rowgroup">40</th>
														<td>30,480</td>
														<td>3,800</td>
														<td>26,680</td>
														<td>12,027</td>
														<td>2,344</td>
														<td>2,347</td>
														<td>2,336</td>
														<td>2,275</td>
														<td>67.0</td>
													<th rowspan="4" scope="rowgroup"></th>
												  </tr>
												  <tr>
													<td>67,200 </td>
													<td>8,158</td>
													<td>59,049</td>
													<td>39' 5 <br/>
													1/2"</td>
													<td>7' 8 <br/>
													5/16"</td>
													<td>7' 8 <br/>
													3/8"</td>
													<td>7' 7 <br/>
													15/16"</td>
													<td>7' 5 <br/>
													1/2"</td>
													<td>2,365.6</td>
												  </tr>
											
												</table>
										
											<p style={{padding:`10px 20px`}} class="table-bot-text">
												{Cargo_catalogue_lang[lang].Allinformationinthisguide}.
											
											</p>
											
										</div>
									</div>
                    </div>
                    <div className={activeTabIndex === 3 ? 'tab-pane active' : 'tab-pane'} role="tabpanel" id="step4">
							<div class="">
										<h2 class=" text-center table-heading">{Cargo_catalogue_lang[lang].Flexitank} </h2> 
										<div class="row m-0">
											<h3 class="table-heading1"> {Cargo_catalogue_lang[lang].GeneralUse}: </h3>
											<div class="col-md-12">
												<p class="image-l-txt"> {Cargo_catalogue_lang[lang].Flexitankisahermeticsealed}. <br/> <br/>
												
												{Cargo_catalogue_lang[lang].InstallationofFlexitankin}.  <br/> <br/>												
											</p>											
											</div>
										</div>
										<div class="row m-0">
											<div class="col-md-8">
												<h3 class="table-heading">{Cargo_catalogue_lang[lang].Whatcargocanbeshipped}  </h3>
												<p class="image-l-txt"> 
												{Cargo_catalogue_lang[lang].InFlexitankitispossible}. <br/> <br/>
														
												{Cargo_catalogue_lang[lang].Someliquidspropertiesare}	
												</p>
											</div>
											<div class="col-md-4">
												<img src={flexitank} alt=""/>
											</div>
										</div>
									</div>
                    </div>
                    <div className={activeTabIndex === 4 ? 'tab-pane active' : 'tab-pane'}  role="tabpanel" id="step5">
												<div class="">
													<h2 class="text-center table-heading">{Cargo_catalogue_lang[lang].Reefer} </h2> 
												<div class="row m-0">
													<h3 class="table-heading1"> {Cargo_catalogue_lang[lang].GeneralUse}: </h3>
													<div class="col-md-7">
														<p class="image-l-txt"> {Cargo_catalogue_lang[lang].Suitablefortransporting}
														 </p>
													</div>
													<div class="col-md-5">
														<img src={reefer} class="table-img" alt=""/>
													</div>
												</div>
												<div class="table-responsive">
													<table class="table table-bordered gsl-equi">
														
														  <colgroup span="3"></colgroup>
														  <colgroup span="3"></colgroup>
														  <tr>
															<th>{Cargo_catalogue_lang[lang].Size}</th>
															<th colspan="3" scope="colgroup">{Cargo_catalogue_lang[lang].WeightKgsLbs}</th>
															<th colspan="3" scope="colgroup">{Cargo_catalogue_lang[lang].InternalDimensionsMMFtInches}</th>
															<th colspan="2" scope="colgroup">{Cargo_catalogue_lang[lang].DoorOpeningsMMFtInches}</th>
															<th>{Cargo_catalogue_lang[lang].Vol}({Cargo_catalogue_lang[lang].m}<sup>3</sup> {Cargo_catalogue_lang[lang].fl}<sup>3</sup>)</th>
															<th>{Cargo_catalogue_lang[lang].notes}</th>
														
														  </tr>
														  <tr>
															<th> {Cargo_catalogue_lang[lang].Feet} </th>
															<th scope="col">{Cargo_catalogue_lang[lang].MaxGrossWeight}</th>
															<th scope="col">{Cargo_catalogue_lang[lang].TareWeight}</th>
															<th scope="col">{Cargo_catalogue_lang[lang].MaxPayload}</th>
															<th scope="col">{Cargo_catalogue_lang[lang].Length}</th>
															<th scope="col">{Cargo_catalogue_lang[lang].Width}</th>
															<th scope="col">{Cargo_catalogue_lang[lang].Height}</th>
															<th scope="col">{Cargo_catalogue_lang[lang].Width}</th>
															<th scope="col">{Cargo_catalogue_lang[lang].Height}</th>
															<th> {Cargo_catalogue_lang[lang].Capacity} </th>
															<th> {Cargo_catalogue_lang[lang].AluminiumStainlessSteel}	</th>									
														  </tr>
														  <tr>
															<th rowspan="2" scope="rowgroup">20</th>
															<td>24,000 </td>
															<td>2,850 </td>
															<td>21,150 </td>
															<td>5,449 </td>
															<td>2,286 </td>
															<td>2,122 </td>
															<td>2,294 </td>
															<td>2,266 </td>
															<td>26.1 </td>
															<th rowspan="4" scope="rowgroup"></th>
														  </tr>
														  <tr>
																<td>52,910 </td>
																<td>6,280</td>
																<td>46,630</td>
																<td>17' 10 <br/> 1/2"</td>
																<td>7' 6"</td>
																<td>6' 11 <br/> 1/2"</td>
																<td>7' 6 <br/>10/32"</td>
																<td>7' 5 1/5"</td>
																<td>922</td>
														  </tr>
														
														  <tr>
															<th rowspan="2" scope="rowgroup">20</th>
																<td>30,480</td>
																<td>3,080</td>
																<td>27,400</td>
																<td>5,451</td>
																<td>2,290</td>
																<td>2,263</td>
																<td>2,290</td>
																<td>2,252</td>
																<td>26.2 </td>
														
														  </tr>
														  <tr>
															<td>67,200</td>
															<td>6,780</td>
															<td>60,376</td>
															<td>17' 10 5/8"</td>
															<td>7' 6 3/16"</td>
															<td>7' 5 1/8"</td>
															<td>7' 6 3/16"</td>
															<td>7' 4 11/16"</td>
															<td>925 </td>
														  </tr>
																								  
													
														</table>
												
													<p style= {{ padding:`10px 20px`}} class="table-bot-text">
														{Cargo_catalogue_lang[lang].Allinformationinthisguide}.
													</p>
													
												</div>
												</div>
                    </div>
                    <div className={activeTabIndex === 5 ? 'tab-pane active' : 'tab-pane'} role="tabpanel" id="step6">
							<div class="">
											<h2 class="text-center table-heading">{Cargo_catalogue_lang[lang].ReeferHighCube}</h2> 
											<div class="row m-0">
											<h3 class="table-heading1"> {Cargo_catalogue_lang[lang].GeneralUse}: </h3>
												<div class="col-md-7">
													<p class="image-l-txt"> {Cargo_catalogue_lang[lang].Suitablefortransporting}
													 </p>
												</div>
												<div class="col-md-5">
													<img src={reefercube} class="table-img" alt=""/>
												</div>
											</div>
											<div class="table-responsive">
												<table class="table table-bordered gsl-equi">
												
													  <colgroup span="3"></colgroup>
													  <colgroup span="3"></colgroup>
													  <tr>
														<th>{Cargo_catalogue_lang[lang].Size}</th>
														<th colspan="3" scope="colgroup">{Cargo_catalogue_lang[lang].WeightKgsLbs}</th>
														<th colspan="3" scope="colgroup">{Cargo_catalogue_lang[lang].InternalDimensionsMMFtInches}</th>
														<th colspan="2" scope="colgroup">{Cargo_catalogue_lang[lang].DoorOpeningsMMFtInches}</th>
														<th>{Cargo_catalogue_lang[lang].Vol}({Cargo_catalogue_lang[lang].m}<sup>3</sup> {Cargo_catalogue_lang[lang].fl}<sup>3</sup>)</th>
														<th>{Cargo_catalogue_lang[lang].notes}</th>
													
													  </tr>
													  <tr>
														<th> {Cargo_catalogue_lang[lang].Feet} </th>
														<th scope="col">{Cargo_catalogue_lang[lang].MaxGrossWeight}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].TareWeight}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].MaxPayload}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].Length}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].Width}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].Height}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].Width}</th>
														<th scope="col">{Cargo_catalogue_lang[lang].Height}</th>
														<th> {Cargo_catalogue_lang[lang].Capacity} </th>
														<th> {Cargo_catalogue_lang[lang].AluminiumStainlessSteel}	</th>									
													  </tr>
													  <tr>
														<th rowspan="2" scope="rowgroup">40</th>
														<td>32,500</td>
														<td>4,650</td>
														<td>27,850</td>
														<td>11,583</td>
														<td>2,286</td>
														<td>2,404</td>
														<td>2,294</td>
														<td>2,500</td>
														<td>63.5 </td>
														<th rowspan="4" scope="rowgroup"></th>
													  </tr>
													  <tr>
															<td>71,650</td>
															<td>10,250</td>
															<td>61,400</td>
															<td>38'</td>
															<td>7' 6"</td>
															<td>8' 10 
															5/8"</td>
															<td>7' 6 
															5/16"</td>
															<td>8' 2 
															3/4"</td>
															<td>2,242</td>
													  </tr>
													
													  <tr>
														<th rowspan="2" scope="rowgroup">40</th>
															<td>30,000</td>
															<td>4,900</td>
															<td>29,100</td>
															<td>11,590</td>
															<td>2,284</td>
															<td>2,548</td>
															<td>2,290</td>
															<td>2,569</td>
															<td>67.5</td>
													  </tr>
													  <tr>
														<td>74,960</td>
														<td>10,800</td>
														<td>64,160</td>
														<td>38' 
														19/64"</td>
														<td>7' 5 
														59/64"</td>
														<td>8' 4 
														7/16"</td>
														<td>7' 6 
														5/32"</td>
														<td>8' 5 
														9/64"</td>
														<td>2,384</td>
													  </tr>
																							  
												
													</table>
											
												<p style={{ padding:`10px 20px`}} class="table-bot-text">
													{Cargo_catalogue_lang[lang].Allinformationinthisguide}.
												</p>
												
											</div>
							</div>
                    </div>
                    <div className={activeTabIndex === 6 ? 'tab-pane active' : 'tab-pane'}  role="tabpanel" id="step7">
							<div class="">
										<h2 class="text-center table-heading">{Cargo_catalogue_lang[lang].Flatrack}</h2> 
										<div class="row m-0">
										<h3 class="table-heading1"> {Cargo_catalogue_lang[lang].GeneralUse}: </h3>
											<div class="col-md-7">
												<p class="image-l-txt"> {Cargo_catalogue_lang[lang].Usedforcarryingoddlyshaped}.
												 </p>
											</div>
											<div class="col-md-5">
												<img src={flak} class="table-img" alt=""/>
											</div>
										</div>
										<div class="table-responsive">
											<table class="table table-bordered gsl-equi">
												
												  <colgroup span="3"></colgroup>
												  <colgroup span="3"></colgroup>
												  <tr>
													<th style={{width:`100px`}}>{Cargo_catalogue_lang[lang].Size}</th>
													<th colspan="3" scope="colgroup">{Cargo_catalogue_lang[lang].WeightKgsLbs}</th>
													<th colspan="3" scope="colgroup">{Cargo_catalogue_lang[lang].InternalDimensionsMMFtInches}</th>
													
													<th colspan="3" scope="colgroup" style={{width:`100px`}}>{Cargo_catalogue_lang[lang].notes}</th>
													
												  </tr>
												  <tr>
													<th> {Cargo_catalogue_lang[lang].Feet} </th>
													<th scope="col">{Cargo_catalogue_lang[lang].MaxGrossWeight}</th>
													<th scope="col">{Cargo_catalogue_lang[lang].TareWeight}</th>
													<th scope="col">{Cargo_catalogue_lang[lang].MaxPayload}</th>
													<th scope="col">{Cargo_catalogue_lang[lang].Length}</th>
													<th scope="col">{Cargo_catalogue_lang[lang].Width}</th>
													<th scope="col">{Cargo_catalogue_lang[lang].Height}</th>
												
													<th rowspan="6" scope="rowgroup"> {Cargo_catalogue_lang[lang].Steel}	</th>									
												  </tr>
												  <tr>
													<th rowspan="2" scope="rowgroup">20</th>
													<td>34,000</td>
													<td>2,890</td>
													<td>31,110</td>
													<td>5,638</td>
													<td>2,226</td>
													<td>2,231</td>
													
													
												  </tr>
												  <tr>
														<td> 74,950 </td>
														<td> 6,370 </td>
														<td> 68,580 </td>
														<td> 19' 4 1/8"</td>
														<td> 7' 8 5/16" </td>
														<td> 7' 8 3/8" </td>
												  </tr>
												
												  <tr>
													<th rowspan="2" scope="rowgroup">40</th>
														<td>45,000</td>
														<td>4,950</td>
														<td>40,050</td>
														<td>12,064</td>
														<td>2,369</td>
														<td>1,943</td>
														
												  </tr>
												  <tr>
													<td>99,202</td>
													<td>11,683</td>
													<td>87,518</td>
													<td>19' 4 1/8"</td>
													<td>7' 8 5/16"</td>
													<td>6' 4 1/2"</td>
													
												  </tr>
												  
												  <tr>
													<th rowspan="2" scope="rowgroup">40</th>
														<td>50,000</td>
														<td>5,000</td>
														<td>45,000</td>
														<td>11,980 <br/>({Cargo_catalogue_lang[lang].BetweenHeaders})</td>
														<td>2,378 <br/> ({Cargo_catalogue_lang[lang].DeckWidth})</td>
														<td>1,960</td>
														
												  </tr>
												  <tr>
													<td>110,230</td>
													<td>11,023</td>
													<td>99,207</td>
													<td>11,762 <br/> ({Cargo_catalogue_lang[lang].BetweenPosts}) </td>
													<td>7' 8 5/16" <br/> ({Cargo_catalogue_lang[lang].BetweenStanchions})</td>
													<td>6' 5 1/8"<br/>({Cargo_catalogue_lang[lang].Loading}) </td>
													
												  </tr>
																						  
											
												</table>
										
											<p style={{padding:`10px 20px`}} class="table-bot-text">
												{Cargo_catalogue_lang[lang].Allinformationinthisguide}.
											</p>
											
										</div>
							</div>
                    </div>
            </div>
                      
                    </div>
                </section>
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}