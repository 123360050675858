import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import Inner_container from '../../innerpages/inner-container';
import inside_heading from '../../../assets/inside-heading.png';
import Api from '../../../../package.json'
import abt_icn from '../../../../src/assets/abt-icn.png';
import container_cargo from '../../../../src/assets/container-cargo.png';
import Moment from 'moment';
import * as axios from 'axios';


const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class View_newsletter extends Component {
    constructor(props) {

        super(props);

        this.state = {
            country_code: window.$country_code,
            newletter: [],
        };

        //  alert(this.props.match.params.id);
    }
    async componentDidMount() {
        var id = this.props.match.params.id;
        const headers = {
          
            type: "application/json",
            Authorization: `Bearer `+window.localStorage.getItem('access_token'),
          };
          var lang = window.localStorage.getItem("lang");
        const response = await axios.post(Api.userdata.url + '/newletter', qs.stringify({ 'id': id, 'lang': lang  }, { parseArrays: false }), { headers: headers });
        const json = await response.data;
        this.setState({ newletter: json['message'][0] });



    }

    render() {

        const template = () => {
            if (this.state.newletter['template'] == "Template-1") {
                return <section class="inner-center">
                    <div class="container-fluid col-lg-10">


                        <h3>{this.state.newletter['title']}</h3>


                        <div dangerouslySetInnerHTML={{ __html: this.state.newletter['description_1'] }} ></div>

                    </div>
                </section>
            } else if (this.state.newletter['template'] == "Template-2") {
                var img = JSON.parse(this.state.newletter['img']);
                return <section class="inner-center">
                    <div class="container">
                        <form class="abt-full">
                            <div class="row ml-0 mr-0 pjt-cargo-row">

                                <div class="col-lg-5 col-sm-12 pot-form pl-0 dat-relat ">

                                    <div class=" cargo-n-imgg" >
                                        <img scr={(img[0] != '') ? Api.userdata.siteurl + 'uploads/si-doc/' + img[0] : container_cargo} />
                                    </div>
                                </div>

                                <div class="col-lg-7 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s"  >
                                    <div class="car-ser-new">

                                        <h3><img src={abt_icn} />  {this.state.newletter['title']}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.newletter['description_1'] }} ></div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s"  >
                                    <div class="car-ser-new">

                                        <div dangerouslySetInnerHTML={{ __html: this.state.newletter['description_2'] }} ></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            } else if (this.state.newletter['template'] == "Template-3") {
                var img = JSON.parse(this.state.newletter['img']);
                return <div> <section class="inner-center">
                    <div class="container">
                        <form class="abt-full">
                            <div class="row ml-0 mr-0 pjt-cargo-row">
                                <div class="col-lg-7 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s" >
                                    <div class="abt-text-con">
                                        <img src={abt_icn} />
                                        <h3>  {this.state.newletter['title']}</h3>
                                    </div>
                                    <div class="car-ser-new">

                                        <div dangerouslySetInnerHTML={{ __html: this.state.newletter['description_1'] }} ></div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-sm-12 pot-form pl-0 dat-relat ">

                                    <div class=" cargo-n-imgg" >

                                        <img scr={(img[0] != '') ? Api.userdata.siteurl + 'uploads/si-doc/' + img[0] : container_cargo} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>

                    <section class="inner-center">
                        <div class="container">
                            <form class="abt-full">
                                <div class="row ml-0 mr-0 pjt-cargo-row">

                                    <div class="col-lg-5 col-sm-12 pot-form pl-0 dat-relat ">

                                        <div class=" cargo-n-imgg" >
                                            <img scr={(img[1] != '') ? Api.userdata.siteurl + 'uploads/si-doc/' + img[1] : container_cargo} />


                                        </div>
                                    </div>

                                    <div class="col-lg-7 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s" >
                                        <div class="car-ser-new">
                                            <div dangerouslySetInnerHTML={{ __html: this.state.newletter['description_2'] }} ></div>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section></div>

            } else if (this.state.newletter['template'] == "Template-4") {
                var img = JSON.parse(this.state.newletter['img']);
                return <div>
                    <section class="inner-center">
                        <div class="container">
                            <form class="abt-full">
                                <div class="row ml-0 mr-0 pjt-cargo-row">
                                    <div class="col-lg-7 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s" >
                                        <div class="abt-text-con">
                                            <img src={abt_icn} />
                                            <h3>{this.state.newletter['title']}</h3>
                                        </div>
                                        <div class="car-ser-new">
                                            {this.state.newletter['description_1']}
                                        </div>
                                    </div>
                                    <div class="col-lg-5 col-sm-12 pot-form pl-0 dat-relat ">

                                        <div class=" cargo-n-imgg" >

                                            <img scr={(img[0] != '') ? Api.userdata.siteurl + 'uploads/si-doc/' + img[0] : container_cargo} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>

                    <section class="inner-center">
                        <div class="container">
                            <form class="abt-full">
                                <div class="row ml-0 mr-0 pjt-cargo-row">

                                    <div class="col-lg-5 col-sm-12 pot-form pl-0 dat-relat ">

                                        <div class=" cargo-n-imgg" >

                                            <img scr={(img[1] != '') ? Api.userdata.siteurl + 'uploads/si-doc/' + img[1] : container_cargo} />
                                        </div>
                                    </div>

                                    <div class="col-lg-7 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s" >
                                        <div class="car-ser-new">
                                            {this.state.newletter['description_2']}

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                    <section class="inner-center">
                        <div class="container">
                            <form class="abt-full">
                                <div class="row ml-0 mr-0 pjt-cargo-row">
                                    <div class="col-lg-7 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s" >
                                        <div class="car-ser-new">
                                            {this.state.newletter['description_3']}
                                        </div>
                                    </div>
                                    <div class="col-lg-5 col-sm-12 pot-form pl-0 dat-relat ">

                                        <div class=" cargo-n-imgg" >
                                            <img scr={(img[2] != '') ? Api.userdata.siteurl + 'uploads/si-doc/' + img[2] : container_cargo} />

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            }
        }
        return (
            <Layout >
                <Inner_container innerpage="view_newsletter" />
                {template()}
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >
        );
    }
}