import React, { Component } from 'react';
import Layout from '../../layout';
import GET_IN_TOUCH from '../../get_in_touch/index';
import Services from '../../get_in_touch/services';
import Packages from '../../get_in_touch/packages_block';
import Inner_container from '../inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import inside_heading from '../../../../src/assets/inside-heading.png';
import facts_figures_lang from '../../lang/facts-figures'



const qs = require('qs');
var jwt = require('jsonwebtoken');
export default class Facts_figures extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            factsfig: [],
           
        };

    }
    async componentDidMount() {
        var lang = window.localStorage.getItem("lang");
        const headers = {
            type: "application/json",
            Authorization: `Bearer `+window.localStorage.getItem('access_token'),
          };

        const response = await axios.post(Api.userdata.url + '/factsfig', qs.stringify({ 'lang': lang }, { parseArrays: false }), { headers: headers });
        const json = await response.data;
        this.setState({ factsfig: json['message'][0] });

    }
    render() {
        var lang = window.localStorage.getItem("lang");
        return (

            <Layout>
                 <Inner_container innerpage ="facts_figures"/>
                <section class="inner-center n-40">
                    <div class="container">
                        <div class="row m-0">
                            <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12">
                                <form class="point-to" >
                                    
                                    <div class="form-fback">
                                        <div class="row m-0">
                                            <div class="col-lg-12 col-md-12 col-sm-12 pot-form p-0 ">
                                                <ul class="factadfi">


                                                    <style>

                                                    </style>
                     
                                                    <li> <a class="heading-facts-de"><b> {facts_figures_lang[lang].AnnualTEUCarried}:  </b></a><a> {this.state.factsfig['ateu']} </a></li>
                                                    <li> <a class="heading-facts-de"> <b>{facts_figures_lang[lang].TotalTEUCapacity}:  <br />({facts_figures_lang[lang].OwnedandCharteredVessel})  </b></a><a>  {this.state.factsfig['tteu']}</a></li>

                                                    <li> <a class="heading-facts-de"> <b>{facts_figures_lang[lang].Vessels}:   </b></a><a>   {this.state.factsfig['vessels']} </a></li>
                                                    <li> <a class="heading-facts-de"><b>{facts_figures_lang[lang].PortsofCall}:  </b></a><a>   {this.state.factsfig['ports']}</a></li>
                                                    <li> <a class="heading-facts-de"> <b> {facts_figures_lang[lang].Services} : </b></a><a>  {this.state.factsfig['services']}</a></li>
                                                    <li> <a class="heading-facts-de"><b> {facts_figures_lang[lang].Employees}:  </b></a><a>  {this.state.factsfig['employ']}</a></li>
                                                    <li><a class="heading-facts-de"><b> {facts_figures_lang[lang].Agents} : </b></a><a>  {this.state.factsfig['agents']}</a></li>
                                                    <li><a class="heading-facts-de"><b> {facts_figures_lang[lang].StrongChinesePresence}: </b> </a><a>   {this.state.factsfig['strong']}</a></li>
                                                    <li> <a class="heading-facts-de"><b> {facts_figures_lang[lang].ITSystems} : </b></a><a>   {this.state.factsfig['itsystem']}</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>


                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}