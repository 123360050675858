import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import Services from "../../../components/get_in_touch/services";
import Packages from "../../../components/get_in_touch/packages_block";
import Api from "../../../../package.json";
import * as axios from "axios";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import GoToTop from "../../../components/go-to-top";
import { useForm, Controller } from "react-hook-form";
import "../tools/css/css-loader.css";
import moment from "moment";
import point_to_point_lang from '../../lang/schedule_point_to_point'
import Accordion from 'react-bootstrap/Accordion';
import { Container, Card, Button } from "react-bootstrap";
import Arrowsvg from "../../../assets/left-svg.svg";
import Shipiconpoint from "../../../assets/shipiconpoint_new.png";
import Containericonpoint from "../../../assets/containerpoint.png";
import Flagiconpoint from "../../../assets/flagpoint.png";
import Form from 'react-bootstrap/Form';


const qs = require("qs");
var jwt = require("jsonwebtoken");
const Point_to_point = () => {

    var lang = window.localStorage.getItem("lang");
    if (window.localStorage.getItem("lang") == null) {
        lang = 'en';
        window.localStorage.setItem("lang", 'en');
    }

    const headers = {
        type: "application/json",
        Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
    };
    const [country_code, setCountryCode] = useState(window.$country_code);
    const [port_code, setport_code] = useState([]);
    const [date, setdate] = useState(new Date());
    const [loaderFlag, setloaderFlag] = useState("is-inactive");
    const [pointToPoint, setpointToPoint] = useState([]);
    const [limit, setlimit] = useState("10");
    const [offset, setoffset] = useState("0");
    const [totalPages, settotalPages] = useState(0);
    const [firstsearch, setfirstsearch] = useState(false);
    const [page, setpage] = useState(0);
    const [fromport, setFromPort] = useState('');
    const [toport, setToPort] = useState('');
    const [fromportnew, setFromPortnew] = useState('');
    const [toportnew, setToPortnew] = useState('');
    const [arrivalDeparture, setArrivalDeparture] = useState("0");
    const [activeKey, setActiveKey] = useState(null);
    const contentRef = useRef(null);
    const handleAccordionToggle = (eventKey) => {
        //eventKey.preventDefault();
        setActiveKey(eventKey);
        console.log(eventKey + "siva");
    };
    const {
        register,
        control,
        setValue,
        handleSubmit,
        watch,
        getValues,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        console.log(data);
        point_to_point_submit(data);
    };
    const point_to_point_submit = async (data) => {
        setloaderFlag("is-active");
        setFromPortnew(fromport)
        setToPortnew(toport)
        const response = await axios.post(
            Api.userdata.url + "/get_point_to_point_new",
            qs.stringify({
                country_code: country_code,
                originCode: data.from_port,
                destCode: data.to_port,
                departureArrivalIndicator: data.optradio,
                schedule: data.schedule,
                date: data.date,
            }), { headers: headers }
        );
        const json = await response.data;
        if (json["status"] == "OK") {
            var new_data = json["result"];
            var totalPages = new_data.length;
            var limit = 10;
            var page = 0;
            var offset = 0;

            page = Math.max(page, 1); //get 1 page when $_GET['page'] <= 0
            page = Math.min(page, totalPages); //get last page when $_GET['page'] > $totalPages
            offset = (page - 1) * limit;
            //  alert(page);
            setpointToPoint(new_data);
            if (offset < 0) offset = 0;

            settotalPages(totalPages);
            setlimit(limit);
            setpage(page);
            setoffset(offset);
        } else {
            setpointToPoint([]);
        }
        setfirstsearch(true);
        setloaderFlag("is-inactive");
    };
    const point_to_point_submit_print = async () => {
        setloaderFlag("is-active");
        const response1 = await axios.post(
            Api.userdata.url + "/get_point_to_point_pdf",
            qs.stringify({
                country_code: country_code,
                originCode: getValues("from_port"),
                destCode: getValues("to_port"),
                departureArrivalIndicator: getValues("optradio"),
                schedule: getValues("schedule"),
                date: getValues("date"),
            }),
            {
                headers: headers,
                responseType: "blob",
            }
        );
        const json1 = await response1.data;
        const file = new Blob([json1], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        window.focus();
        setloaderFlag("is-inactive");
    };
    const point_to_point_submit_pdf = async () => {
        setloaderFlag("is-active");
        const response1 = await axios.post(
            Api.userdata.url + "/get_point_to_point_pdf",
            qs.stringify({
                country_code: country_code,
                originCode: getValues("from_port"),
                destCode: getValues("to_port"),
                departureArrivalIndicator: getValues("optradio"),
                schedule: getValues("schedule"),
                date: getValues("date"),
            }),
            {
                headers: headers,
                responseType: "blob",
            }
        );
        const json1 = await response1.data;
        const file = new Blob([json1], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const tempLink = document.createElement("a");
        tempLink.href = fileURL;
        tempLink.setAttribute("download", "Point-to-Point" + ".pdf");
        tempLink.click();
        setloaderFlag("is-inactive");
    };
    const getLoadData = async () => {
        const response = await axios.post(
            Api.userdata.url + "/port_codes",
            qs.stringify({ token: "2" }, { parseArrays: false }), { headers: headers }
        );
        const json = await response.data;
        setport_code(json["message"]);
    };
    const saveArrivalDeparture = (event) => {
        setArrivalDeparture(event.target.value);
    };
    useEffect(() => {
        getLoadData();
        setValue("date", date);
    }, [offset]);
    return (
        <Layout>
            <div class={"loadernew loader-double " + loaderFlag}></div>
            <section class="inner-center">
                <br></br>
                <div class="container">
                    <div class="row m-0">
                        <div class="col-lg-12 col-md-12 col-lg-12">
                            <form
                                autocomplete="on"
                                name="point-to-point-new"
                                id="point-to-point-new"
                                method="get"
                                action="#"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <div class="form-fback">
                                    <div class="row m-0">
                                        <div class="col-lg-5 col-md-12 col-sm-12 pot-form p-3">
                                            <div class="dout-inner">
                                                <h3>{point_to_point_lang[lang].PointtoPoint}</h3>
                                                <p>{point_to_point_lang[lang].PointtoPoint1}</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 col-md-12 col-sm-12 p-0 pot-form">
                                            <div class="row">
                                                <div class="col-md-6 col-lg-6 col-sm-12">
                                                    <div class="autocomplete">
                                                        <div>
                                                            <label>{point_to_point_lang[lang].from}</label>
                                                            <Controller
                                                                control={control}
                                                                rules={{
                                                                    required: "Please select from port",
                                                                }}
                                                                render={({ onChange, value, ref }) => (
                                                                    <Select
                                                                        options={port_code.map((data) => ({
                                                                            value: data["port_code"],
                                                                            label:
                                                                                data["port_name"] +
                                                                                "-" +
                                                                                data["country_name"] +
                                                                                "(" +
                                                                                data["port_code"] +
                                                                                ")",
                                                                            portname: data["port_name"] +
                                                                                "-" +
                                                                                data["country_name"] +
                                                                                " (" +
                                                                                data["port_code"] +
                                                                                ")"
                                                                        }))}
                                                                        onChange={(val) => {
                                                                            setValue("from_port", val.value);
                                                                            setFromPort(val.portname);
                                                                        }}
                                                                    />
                                                                )}
                                                                defaultValue=""
                                                                placeholder="Select platform"
                                                                name="from_port"
                                                                id="from_port"
                                                                setValue={setValue}
                                                            />

                                                            {errors.from_port && (
                                                                <span class="errors">
                                                                    {errors.from_port.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12">
                                                    <div class="autocomplete">
                                                        <div>
                                                            <label for="toends">{point_to_point_lang[lang].to}</label>
                                                            <Controller
                                                                control={control}
                                                                rules={{
                                                                    required: "Please select to port",
                                                                }}
                                                                render={({ onChange, value, ref }) => (
                                                                    <Select
                                                                        options={port_code.map((data) => ({
                                                                            value: data["port_code"],
                                                                            label:
                                                                                data["port_name"] +
                                                                                "-" +
                                                                                data["country_name"] +
                                                                                "(" +
                                                                                data["port_code"] +
                                                                                ")",
                                                                            portname: data["port_name"] +
                                                                                "-" +
                                                                                data["country_name"] +
                                                                                " (" +
                                                                                data["port_code"] +
                                                                                ")"
                                                                        }))}
                                                                        onChange={(val) => {
                                                                            setValue("to_port", val.value);
                                                                            setToPort(val.portname);
                                                                        }}
                                                                    />
                                                                )}
                                                                defaultValue=""
                                                                placeholder="Select platform"
                                                                name="to_port"
                                                                id="to_port"
                                                                setValue={setValue}
                                                            />
                                                            {errors.to_port && (
                                                                <span class="errors">
                                                                    {errors.to_port.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 col-lg-6 col-sm-12">
                                                    <div class="autocomplete">
                                                        <div class="form-group">
                                                            <label>{point_to_point_lang[lang].Startdate}</label>
                                                            <div
                                                                id="datepicker"
                                                                class="input-group date datepicker"
                                                                data-date-format="dd/mm/yyyy"
                                                            >
                                                                <Controller
                                                                    control={control}
                                                                    rules={{
                                                                        required: "Please select your date",
                                                                    }}
                                                                    render={({ onChange, value, ref }) => (
                                                                        <Flatpickr
                                                                            options={{ dateFormat: "d-m-Y" }}
                                                                            value={date}
                                                                            onChange={(val) => {
                                                                                setValue("date", val[0]);
                                                                            }}
                                                                        />
                                                                    )}
                                                                    id="date"
                                                                    name="date"
                                                                    class="form-control"
                                                                    setValue={date}
                                                                />

                                                                {errors.date && (
                                                                    <span class="errors">
                                                                        {errors.date.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12">
                                                    <div>
                                                        <label>{point_to_point_lang[lang].Weeksahead}</label>
                                                        <select
                                                            class="selectpicker"
                                                            id="schedulept"
                                                            name="schedule"
                                                            {...register("schedule")}
                                                        >
                                                            <option value="6"> 6 </option>
                                                            <option value="7"> 7 </option>
                                                            <option value="8"> 8 </option>
                                                            <option value="9"> 9 </option>
                                                            <option value="10"> 10 </option>
                                                            <option value="11"> 11 </option>
                                                            <option value="12"> 12 </option>
                                                            <option value="13"> 13 </option>
                                                            <option value="14"> 14 </option>
                                                            <option value="15"> 15 </option>
                                                            <option value="16"> 16 </option>
                                                            <option value="17"> 17 </option>
                                                            <option value="18"> 18 </option>
                                                            <option value="19"> 19 </option>
                                                            <option value="20"> 20 </option>
                                                            <option value="21"> 21 </option>
                                                            <option value="22"> 22 </option>
                                                            <option value="23"> 23 </option>
                                                            <option value="24"> 24 </option>
                                                            <option value="25"> 25 </option>
                                                            <option value="26"> 26 </option>
                                                            <option value="27"> 27 </option>
                                                            <option value="28"> 28 </option>
                                                            <option value="29"> 29 </option>
                                                            <option value="30"> 30 </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul class="sec-pot-check new-pot-radio">
                                                <li>
                                                    <div class="custom-control custom-radio">
                                                        <input
                                                            type="radio"
                                                            id="optradio"
                                                            value="0"
                                                            name="optradio"
                                                            class="custom-control-input"
                                                            checked={arrivalDeparture === "0"}
                                                            onClick={saveArrivalDeparture}
                                                            {...register("optradio")}
                                                        />
                                                        <label
                                                            class="custom-control-label radio-nane"
                                                            for="optradio"
                                                        >
                                                            {point_to_point_lang[lang].Departure}
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="custom-control custom-radio">
                                                        <input
                                                            type="radio"
                                                            id="optradio1"
                                                            value="1"
                                                            name="optradio1"
                                                            class="custom-control-input"
                                                            checked={arrivalDeparture === "1"}
                                                            onClick={saveArrivalDeparture}
                                                            {...register("optradio1")}
                                                        />
                                                        <label
                                                            class="custom-control-label radio-nane"
                                                            for="optradio1"
                                                        >
                                                            {point_to_point_lang[lang].Arrival}
                                                        </label>
                                                    </div>
                                                </li>
                                                <li class="buts-in-cen">
                                                    <button
                                                        class="btn btn-default hovereffect portal-searc"
                                                        id="submitDetails"
                                                        name="my-buttons"
                                                    >
                                                        {point_to_point_lang[lang].Search}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>




            </section>
            <section className="tools-card d-none d-md-block d-sm-block">
                {pointToPoint.length > 0 ?

                    <>
                        <Container>

                            <div className="tools-title mb-2 mb-md-3">
                                <h3>{fromportnew}</h3>
                                <div className=" ">
                                    <img className="card-icon " src={Arrowsvg} />
                                </div>
                                <h3>{toportnew}</h3>

                            </div>

                            <div className="filter-base d-flex justify-content-between align-items-center ps-4 pe-4  mb-3">

                                <div className="search-result">
                                    <h4>{pointToPoint.length} Results</h4>
                                </div>
                                <div className="">

                                    <i className="fa-solid fa-anchor "></i>
                                    <a className=""> Port Contacts </a>

                                </div>

                            </div>

                            <Accordion activeKey={activeKey} onSelect={handleAccordionToggle} ref={contentRef}>


                                {pointToPoint.map((resultssss, i) => {
                                    {
                                        {
                                            var td1 = resultssss["vesselName"];
                                            var td2 = moment(resultssss["departureDate"]).format(
                                                "DD MMM, YYYY"
                                            );
                                            var td3 = moment(resultssss["arrivalDate"]).format(
                                                "DD MMM, YYYY"
                                            );
                                            var td4 = resultssss["line"];
                                        }
                                    }
                                    return (
                                        <>

                                            <Card>
                                                <Card.Header>

                                                    <div className="card-item ">
                                                        <div className="card-wrapper">




                                                            <div className="card-desktop-view row ">
                                                                <Accordion.Toggle as={Button} variant="link" eventKey={i + 1} >
                                                                    <i className={`default-arrow ${activeKey === i + 1 ? 'active' : ''}`}></i>

                                                                </Accordion.Toggle>


                                                                <div className=" card-data card-first-element ">

                                                                    <h4 className="card-element-head">Departure <span className="card-info">{td2}</span></h4>

                                                                </div>
                                                                <div className=" ">
                                                                    <img className="card-icon " src={Arrowsvg} />
                                                                </div>
                                                                <div className=" card-data card-second-element ">
                                                                    <h4 className="card-element-head ">Arrival  <span className="card-info">{td3}</span></h4>

                                                                </div>
                                                                <div className=" card-data card-third-element ">
                                                                    <h4 className="card-element-head">{resultssss["transitTime"]} Days  <span className="card-info">{resultssss["result_new"].length > 1 ? (resultssss["result_new"].length - 1) + ' Transshipment' : 'Direct'}</span></h4>

                                                                </div>
                                                                <div className=" card-data vessel-details">
                                                                    <h4 className="card-element-head">Vessel   <span className="card-info">
                                                                        {td1 + "(" + resultssss["vesselCode"] + ")/"}{resultssss["voyage"]}</span></h4>

                                                                </div>
                                                                {/*<div className=" card-data  ">
                                                                    <h4 className="card-element-head">VGM</h4>
                                                                    <p className="">-</p>
                                                                </div>
                                                                <div className=" card-data ">
                                                                    <h4 className="card-element-head">Last Gate In</h4>
                                                                    <p className="">-</p>
                                                                </div>
                                                                <div className=" card-data  ">
                                                                    <h4 className="card-element-head">Doc Cut Off</h4>
                                                                    <p className="">-</p>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Card.Header>
                                                <Accordion.Collapse eventKey={i + 1}>
                                                    <Card.Body>
                                                        {resultssss["result_new"].map((results, j) => {
                                                            {
                                                                {
                                                                    var departureDate = moment(results["departureDate"]).format(
                                                                        "DD MMM, YYYY H:mm"
                                                                    );
                                                                    var arrivalDate = moment(results["arrivalDate"]).format(
                                                                        "DD MMM, YYYY H:mm"
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <div className="inner-card-detail mt-5">
                                                                    <ul className="card-detail-list">
                                                                        <li className="card-inner-detail">
                                                                            <div className="line">
                                                                                <div className="">
                                                                                    <img src={Shipiconpoint}></img>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row-line">
                                                                                <div className="row card-detail-data">
                                                                                    <div className="col-md-3">  <p className="">  {results['vesselName'] +
                                                                                        "(" +
                                                                                        results["vesselCode"] +
                                                                                        ") / "} {results["voyage"]}</p></div>
                                                                                    <div className="col-md-2">{departureDate}<p>{results["departurePortName"]}<br></br>{results["depotToName"]}<br></br>{results["depotFromName"]}</p></div>
                                                                                    <div className="col-md-1">
                                                                                        <img className="card-icon " src={Arrowsvg} />
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        {arrivalDate} <p>{results["arrivalPortName"]}</p>
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        Line Code : <p>{results["line"]}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        {/* <li className="card-inner-detail">
                                                                        <div className="line">
                                                                            <div className="">
                                                                                <img src={Containericonpoint}></img>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row-line">
                                                                            <div className="row card-detail-data">
                                                                                <div className="col-md-3">Feeder (N/A) / N/A / N/A</div>
                                                                                <div className="col-md-2">12-Apr-2023 12:00 Nangang</div>
                                                                                <div className="col-md-1">
                                                                                    <img className="card-icon " src={Arrowsvg} />
                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    15-Apr-2023 12:00 Shekou (GD)
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>  */}
                                                                        {resultssss["result_new"].length - 1 == j ? <li className="card-inner-detail">
                                                                            <div className="line">
                                                                                <div className="">
                                                                                    <img src={Flagiconpoint}></img>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row-line">
                                                                                <div className="row card-detail-data"></div>
                                                                            </div>
                                                                        </li> : <></>}
                                                                    </ul>
                                                                </div>)
                                                        })}</Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </>)
                                })}



                            </Accordion>
                            <div class="row  p-4 mb-4">
                                <p class="tariff-print" >
                                    <a class="btn btn-info btn-lg" onClick={point_to_point_submit_print} >
                                        <span class="glyphicon glyphicon-print"></span>Print
                                    </a>
                                </p>
                                <p class="tariff-print mx-3" >
                                    <a class="btn btn-info btn-lg" onClick={point_to_point_submit_pdf} >
                                        <span class="glyphicon glyphicon-print"></span> PDF
                                    </a>
                                </p>
                            </div>
                        </Container>
                    </> : (
                        <div
                            class="row"
                            style={{
                                minHeight: `200px;`,
                                border: `solid 1px;`,
                                display: firstsearch ? "block" : "none",
                            }}
                        >
                            <div class="col">
                                <h3 style={{ paddingTop: `72px;`, textAlign: `center;` }}>
                                    <i class="fa fa-search" aria-hidden="true"></i> No Record Found.
                                </h3>
                            </div>
                        </div>
                    )}
            </section>
            <section className="tools-card d-block d-sm-none">
                {pointToPoint.length > 0 ?

                    <>
                        <Container>
                            <div className="tools-title mb-md-3 mb-2">
                                <h3>{fromportnew}</h3>
                                <div className=" ">
                                    <img className="card-icon " src={Arrowsvg} />
                                </div>
                                <h3>{toportnew}</h3>

                            </div>
                            <Accordion activeKey={activeKey} onSelect={handleAccordionToggle}>
                                {pointToPoint.map((resultssss, i) => {
                                    {
                                        {
                                            var td1 = resultssss["vesselName"];
                                            var td2 = moment(resultssss["departureDate"]).format(
                                                "DD MMM, YYYY"
                                            );
                                            var td3 = moment(resultssss["arrivalDate"]).format(
                                                "DD MMM, YYYY"
                                            );
                                            var td4 = resultssss["line"];
                                        }
                                    }
                                    return (
                                        <>
                                            <Card>
                                                <Card.Header>

                                                    <div className="card-item ">
                                                        <div className="card-wrapper">

                                                            <div className="card-desktop-view mb-2 row ">

                                                                <div className=" card-data card-first-element col  card-data-m">

                                                                    <h4 className="card-element-head  ">Departure</h4>
                                                                    <p> {td2}</p>
                                                                </div>
                                                                <div className="col-3 ">
                                                                    <img className="card-icon " src={Arrowsvg} />
                                                                </div>

                                                                <div className=" card-data card-second-element col ">
                                                                    <h4 className="card-element-head ">Arrival</h4>
                                                                    <p className=""> {td3}</p>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-2 ">
                                                                <div className=" card-data card-data-m col-6">
                                                                    <h4 className="card-element-head">Vessel</h4>
                                                                    <p className="m-0">  {td1 +
                                                                        "(" +
                                                                        resultssss["vesselCode"] +
                                                                        ") / "}{resultssss["voyage"]}</p>
                                                                </div>
                                                                <div className=" card-data col-6">
                                                                    <h4 className="card-element-head">{resultssss["result_new"].length > 1 ? (resultssss["result_new"].length - 1) + ' Transshipment' : 'Direct'}</h4>
                                                                    <p className="m-0">  {resultssss["transitTime"]} Days</p>
                                                                </div>
                                                            </div>

                                                            <Accordion.Toggle as={Button} variant="link" eventKey={i + 1}>
                                                                <i className={`default-arrow ${activeKey === i + 1 ? 'active' : ''}`}></i>

                                                            </Accordion.Toggle>
                                                        </div>
                                                    </div>

                                                </Card.Header>
                                                <Accordion.Collapse eventKey={i + 1}>
                                                    <Card.Body>
                                                        {resultssss["result_new"].map((results, j) => {
                                                            {
                                                                {
                                                                    var departureDate = moment(results["departureDate"]).format(
                                                                        "DD MMM, YYYY H:mm"
                                                                    );
                                                                    var arrivalDate = moment(results["arrivalDate"]).format(
                                                                        "DD MMM, YYYY H:mm"
                                                                    );
                                                                }
                                                            }
                                                            return (
                                                                <div className="inner-card-detail mt-5">
                                                                    <ul className="card-detail-list">
                                                                        <li className="card-inner-detail">
                                                                            <div className="line">
                                                                                <div className="">
                                                                                    <img src={Shipiconpoint}></img>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row-line">
                                                                                <div className="row card-detail-data">
                                                                                    <div className="col-md-3">  <p className="">  {results['vesselName'] +
                                                                                        "(" +
                                                                                        results["vesselCode"] +
                                                                                        ") / "} {results["voyage"]}</p></div>
                                                                                    <div className="col-md-2">{departureDate}<p>{results["departurePortName"]}<br></br>{results["depotToName"]}<br></br>{results["depotFromName"]}</p></div>
                                                                                    <div className="col-md-1">
                                                                                        <img className="card-icon " src={Arrowsvg} />
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        {arrivalDate} <p>{results["arrivalPortName"]}</p>
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        Line Code : <p>{results["line"]}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        {resultssss["result_new"].length - 1 == j ? <li className="card-inner-detail">
                                                                            <div className="line">
                                                                                <div className="">
                                                                                    <img src={Flagiconpoint}></img>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row-line">
                                                                                <div className="row card-detail-data"></div>
                                                                            </div>
                                                                        </li> : <></>}

                                                                    </ul>
                                                                </div>)
                                                        })}</Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </>)
                                })}
                            </Accordion>

                            <div class="row  p-4 mb-4">
                                <p class="tariff-print" >
                                    <a class="btn btn-info btn-lg"  >
                                        <span class="glyphicon glyphicon-print"></span>Print
                                    </a>
                                </p>
                                <p class="tariff-print mx-3" >
                                    <a class="btn btn-info btn-lg"  >
                                        <span class="glyphicon glyphicon-print"></span> View
                                    </a>
                                </p>
                            </div>

                        </Container>   </> : (
                        <div
                            class="row"
                            style={{
                                minHeight: `200px;`,
                                border: `solid 1px;`,
                                display: firstsearch ? "block" : "none",
                            }}
                        >
                            <div class="col">
                                <h3 style={{ paddingTop: `72px;`, textAlign: `center;` }}>
                                    <i class="fa fa-search" aria-hidden="true"></i> No Record Found.
                                </h3>
                            </div>
                        </div>
                    )}
            </section>
            <Services />
            <Packages />
            <GET_IN_TOUCH />
        </Layout>
    );
};
export default Point_to_point;
