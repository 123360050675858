const homelang =
{
    "en": {
        "FormsandDocuments": "Forms and Documents",
        "LocalCharge": "Local Charge",
        "LocalSchedules": "Local Schedules",
        "Find": "Find",
    },
    "cn": {
        "FormsandDocuments": "表格及文件",
        "LocalCharge": "本地收費",
        "LocalSchedules": "本地船期表",
        "Find": "查閲",
    },
    "zh": {
        "FormsandDocuments": "表格及文件",
        "LocalCharge": "本地收费",
        "LocalSchedules": "本地船期表",
        "Find": "查阅",
    },
    "ko": {
        "FormsandDocuments": "양식 및 서류",
        "LocalCharge": "로컬 비용",
        "LocalSchedules": "로컬 스케줄",
        "Find": "찾기",
    },
    "vi": {
        "FormsandDocuments": "Biểu mẫu và Tài liệu",
        "LocalCharge": "Phụ phí địa phương",
        "LocalSchedules": "Lịch tàu địa phương",
        "Find": "Tìm kiếm",
    },
    "th": {
        "FormsandDocuments": "แบบฟอร์มและเอกสาร",
        "LocalCharge": "ค่าใช้จ่าย - Local Charge",
        "LocalSchedules": "ตารางเรือ - Local Schedules",
        "Find": "ค้นหา",
    },
    "in": {
        "FormsandDocuments": "Formulir dan Dokumen",
        "LocalCharge": "Biaya Lokal",
        "LocalSchedules": "Jadwal Lokal",
        "Find": "Temukan",
    },
    "ja": {
        "FormsandDocuments": "フォームと資料",
        "LocalCharge": "現地手数料",
        "LocalSchedules": "現地スケジュール",
        "Find": "検索",
    },
}
export default homelang;