import React, { Component, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import Inland from '../../../../src/assets/Inland.jpeg';
import abt_icn from '../../../../src/assets/abt-icn.png';
import Inner_container from '../../innerpages/inner-container';
import Api from '../../../../package.json'
import * as axios from 'axios';
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import contact_us_lang from '../../lang/contact_us'


const qs = require('qs');
var jwt = require('jsonwebtoken');

const CmsPost = (props) => {
	var lang = window.localStorage.getItem("lang");
	const headers = {
		type: "application/json",
		Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
	};
	const {
		register,
		control,
		setValue,
		handleSubmit,
		watch,
		getValues,
		formState: { errors },
	} = useForm();
	const [country_code, setCountryCode] = useState(window.$country_code);
	const [cmspost, setcmspost] = useState('');
	const [cmstitle, setcmstitle] = useState('');
	const [cmsbgimage, setcmsbgimage] = useState('');
	const [cmsdescription, setdescription] = useState('');
	const [noResultFlag, setNoResultFlag] = useState('');
	const buttonRef = useRef();
	const { id } = useParams();
	const getLoadData = async () => {
	

		const response = await axios.post(
			Api.userdata.url + "/cmspost_news",
			qs.stringify({ id: id }, { parseArrays: false }),
			{ headers: headers }
		);
		const cms_json = await response.data;
		if (cms_json['status'] == '1') {
			setNoResultFlag(false);
			setcmspost(cms_json['message']['description']);
			setcmstitle(cms_json['message']['title']);
			setdescription(cms_json['message']['desp']);
			setcmsbgimage(Api.userdata.siteurl + 'media_management/files/' + cms_json['message']['filep']);
		}
		else {
			setNoResultFlag(true);
		}



	}

	useEffect(() => {

		getLoadData();
	}, []);
	return (<Layout>
		{(noResultFlag == false) ?
			<>{(cmsdescription != '') ? <Inner_container innerpage="enquiry" titles={cmstitle} description={cmsdescription} bgimage={cmsbgimage} /> : ''}

				<section class="inner-center contatc_padd n-40" style={(cmsdescription == '') ? { marginTop: 140 } : null}>
					<div class="container">
						<div class="row">
							<div class="col-lg-12 col-md-12 col-sm-12 col-sm-12 pot-form p-0 gsl-off-det">
								<div dangerouslySetInnerHTML={{ __html: cmspost }} ></div>

							</div>

						</div>

					</div>

				</section></> : <><section class="inner-center contatc_padd n-40" style={(cmsdescription == '') ? { marginTop: 300,marginBottom: 400 } : null}>
					<div class="container">
						<div class="row"><div class="col-12"> <h2 class="services-box-heading">No Data</h2></div></div></div></section></>

		}



		<Services />
		<Packages />
		<GET_IN_TOUCH />

	</Layout >);
}

export default CmsPost;