import React, { Component, useEffect, useState,useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import "flatpickr/dist/themes/material_green.css";
import { useForm, Controller } from "react-hook-form";
import * as axios from "axios";
import Api from "../../../../package.json";
import "../tools/css/css-loader.css";
import moment from "moment";
import downarrow from "../../../assets/down-arrow.png";
import { Accordion, Card, Button, ButtonGroup } from "react-bootstrap";
import track_shipment_lang from '../../lang/track_shipment'

  
const qs = require("qs");
var jwt = require("jsonwebtoken");
const Track_shipment = (props) => {
  const [country_code, setCountryCode] = useState(window.country_code);
  const [trackShipmentRes, settrackShipmentRes] = useState([]);
  const [loaderFlag, setloaderFlag] = useState("is-inactive");
  const [pdfbuttonstatus, setpdfbuttonstatus] = useState(false);
  const [outputflag, setoutputflag] = useState(false);
  const [trackShipmentflag, settrackShipmentflag] = useState(false);
  const submitForm = useRef();
  var lang = window.localStorage.getItem("lang");
  if (window.localStorage.getItem("lang") == null) {
    lang = 'en';
    window.localStorage.setItem("lang", 'en');
  }

  const headers = {
		type: "application/json",
		Authorization: `Bearer `+window.localStorage.getItem('access_token'),
	  };
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    track_shipment_submit(data);
  };
  const checkcontainer = async (val) => {
    const response = await axios.post(
      Api.userdata.url + "/container_bl_validation",
      qs.stringify({ containerid: val }),{headers:headers}
    );
    const res = await response.data;
    if (res == false) {
      return "Enter container number - format : 4 letters followed by 7 digits";
    } else {
      return true;
    }
  };

  const track_shipment_submit = async (datas) => {
    setpdfbuttonstatus(false);
    setloaderFlag("is-active");
    const response = await axios.post(
      Api.userdata.url + "/get_track_shipment_val",
      qs.stringify({
        country_code: country_code,
        containerid: datas.containerid,
      }),{headers:headers}
    );
    const json = await response.data;
    settrackShipmentRes([]);
    if (json["status"] == 1) {
      setoutputflag(true);
      setpdfbuttonstatus(true);
      settrackShipmentRes(json["message"]);
      
    } else {
      setoutputflag(false);
      setpdfbuttonstatus(false);
     
    }
    settrackShipmentflag(true);
    setloaderFlag("is-inactive");
  };
  const track_shipment_submit_pdf = async () => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/get_track_shipment_pdf",
      qs.stringify({
        country_code: country_code,
        containerid: getValues("containerid"),
      }),
      {
        headers:headers,
        responseType: "blob",
      }
    );
    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const tempLink = document.createElement("a");
    tempLink.href = fileURL;
    tempLink.setAttribute("download", getValues("containerid") + ".pdf");
    tempLink.click();

    setloaderFlag("is-inactive");
  };
  const track_shipment_submit_print = async () => {
    setloaderFlag("is-active");
    const response1 = await axios.post(
      Api.userdata.url + "/get_track_shipment_pdf",
      qs.stringify({
        country_code: country_code,
        containerid: getValues("containerid"),
      }),
      {
        headers:headers,
        responseType: "blob",
      }
    );
    const json1 = await response1.data;
    const file = new Blob([json1], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    window.focus();
    setloaderFlag("is-inactive");
  };
	useEffect(() => {
    var id = props.match.params.containerid;
    if(id != null)
    {
     setValue('containerid',id);
    submitForm.current.click();
    }
  
	}, []);
  return (
    <Layout>
      <div class={"loadernew loader-double " + loaderFlag}></div>
      <section class="inner-center">
        <br></br>
        <div class="container">
          <div class="row m-0">
            <div
              class="col-lg-12 col-md-12 col-lg-12"
              style={{ minHeight: "500px;" }}
            >
              <form
                autocomplete="on"
                name="point-to-point-new"
                id="point-to-point-new"
                method="get"
                action="#"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div class="form-fback">
                  <div class="row m-0">
                    <div class="col-lg-5 col-md-12 col-sm-12 pot-form p-3">
                      <div class="dout-inner">
                        <h3>{track_shipment_lang[lang].TrackaShipment}</h3>
                        <p>{track_shipment_lang[lang].TrackaShipment1}</p>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 p-0 pot-form">
                      <div class="row">
                        <div class="col-md-8 col-lg-8 col-sm-12">
                          <label>{track_shipment_lang[lang].EnterBLNoorContainerNo}</label>
                          <input
                            type="text"
                            id="containerid"
                            name="containerid"
                            class="form-control"
                            style={{ textTransform: "uppercase" }}
                            {...register("containerid", {
                              validate: (value) => {
                                return checkcontainer(value);
                              },
                              required: {
                                value: true,
                                message: "This field is required.",
                              },
                            })}
                          />
                          {errors.containerid && (
                            <span class="errors">
                              {errors.containerid.message}
                            </span>
                          )}
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-12 track-cont-no">
                          <div class="form-group">
                            <button
                              class="btn btn-default hovereffect portal-searc-tariff"
                              id="submitDetails"
                              name="my-buttons"
                              type="submit"
                              ref={submitForm}
                            >
                             {track_shipment_lang[lang].FindContainer}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="container" style={{ minHeight: `200px;` }}>
          <div class="row">
            <div id="fill" class="col-md-12 col-lg-12 col-sm-12 p-4">
              {outputflag ? (
                <>
                  {" "}
                  <div>
                    {trackShipmentRes["container_flag"] == true ? (
                      <div class="row track-shipment-output">
                        <table class="table track-shipment-output-tb1">
                          <thead>
                            <th>
                              <h3 class="track-shipment-heading">
                                <span>{track_shipment_lang[lang].BLNo} </span>
                                {trackShipmentRes["containerid"]}
                              </h3>
                            </th>
                          </thead>
                        </table>
                        <div class="col-md-6 col-lg-6 col-sm-12 p-1">
                          <div class="card p-3 main-cargo-head-container">
                            <table class="table table1">
                              <tbody>
                                {trackShipmentRes["pol"]["port_of_receipt"] !=
                                "" ? (
                                  <tr>
                                    <td class="table-font-weight">
                                    {track_shipment_lang[lang].PortofReceipt} 
                                    </td>
                                    <td>
                                      {
                                        trackShipmentRes["pol"][
                                          "port_of_receipt"
                                        ]
                                      }
                                    </td>
                                  </tr>
                                ) : (
                                  <></>
                                )}
                                <tr>
                                  <td class="table-font-weight">
                                  {track_shipment_lang[lang].PortofLoading}
                                  </td>
                                  <td>
                                    {trackShipmentRes["pol"]["port_of_loading"]}
                                  </td>
                                </tr>
                                <tr>
                                  {" "}
                                  <td class="table-font-weight">
                                  {track_shipment_lang[lang].TerminalName}
                                  </td>
                                  <td>
                                    {trackShipmentRes["pol"]["terminal_name"]}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="table-font-weight">
                                  {track_shipment_lang[lang].SailingDate} 
                                  </td>
                                  <td>
                                    {moment(
                                      trackShipmentRes["pol"]["sailingdate"]
                                    ).format("DD-MMM-YYYY")}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 p-1 ">
                          <div class="card p-3 main-cargo-head-container">
                            <table class="table table1">
                              <tbody>
                                <tr>
                                  {" "}
                                  <td class="table-font-weight">
                                  {track_shipment_lang[lang].PortofDischarge}
                                  </td>
                                  <td>
                                    {
                                      trackShipmentRes["pod"][
                                        "port_of_discharge"
                                      ]
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td class="table-font-weight">
                                  {track_shipment_lang[lang].FinalDestination}:
                                  </td>
                                  <td>
                                    {
                                      trackShipmentRes["pod"][
                                        "final_destination"
                                      ]
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td class="table-font-weight">
                                  {track_shipment_lang[lang].EstimatedTimeofArrival} 
                                  </td>
                                  <td>
                                    {
                                      trackShipmentRes["pod"][
                                        "estimated_time_of_arrival"
                                      ]
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {typeof trackShipmentRes["response"]["blRouteLegs"] !==
                          "undefined" &&
                          trackShipmentRes["response"]["blRouteLegs"].map(
                            (blRouteLegsss, i) => {
                              {
                                {
                                  var last_leg = true;
                                  if (
                                    trackShipmentRes["response"]["blRouteLegs"]
                                      .length -
                                      1 ==
                                    i
                                  ) {
                                    if (
                                      blRouteLegsss["arrivalDateTz"][
                                        "dateTime"
                                      ] != null &&
                                      blRouteLegsss["portToType"] == "DEL"
                                    ) {
                                      last_leg = true;
                                    } else {
                                      last_leg = false;
                                    }
                                  }

                                  var ETA = "";

                                  if (i != 0) {
                                    var arrivalDateTz =
                                      trackShipmentRes["response"][
                                        "blRouteLegs"
                                      ][i - 1]["arrivalDateTz"]["dateTime"];
                                    if (arrivalDateTz != null) {
                                      ETA =
                                        moment(arrivalDateTz).format(
                                          "DD-MMM-YYYY"
                                        );
                                      /* 	var date = date_create($arrivalDateTz);
		 ETA = date_format($date,"d-M-Y"); */
                                    }
                                  }
                                }
                                return (
                                  <>
                                    <div class="table-responsive p-1">
                                      <table
                                        class="table"
                                        style={{
                                          background: `#f3f3f3`,
                                          color: `#123c60`,
                                        }}
                                      >
                                        <tbody>
                                          <tr
                                            style={{
                                              background:
                                                last_leg == true
                                                  ? ``
                                                  : `#ffe787`,
                                            }}
                                          >
                                            <td width="25%">
                                              <p
                                                class="table-font-weight"
                                                style={{ paddingRight: `20px` }}
                                              >
                                                {typeof trackShipmentRes[
                                                  "array"
                                                ][
                                                  blRouteLegsss["portFromType"]
                                                ] !== "undefined"
                                                  ? trackShipmentRes["array"][
                                                      blRouteLegsss[
                                                        "portFromType"
                                                      ]
                                                    ]
                                                  : blRouteLegsss[
                                                      "portFromType"
                                                    ]}
                                              </p>
                                              <p>
                                                {blRouteLegsss["portNameFrom"] +
                                                  ", " +
                                                  blRouteLegsss[
                                                    "countryNameFrom"
                                                  ]}
                                              </p>
                                            </td>
                                            {blRouteLegsss["voyage"] != null ? (
                                              <td
                                                width="25%"
                                                style={{
                                                  background: `#ffe2d1`,
                                                }}
                                              >
                                                <p
                                                  class="table-font-weight"
                                                  style={{
                                                    paddingRight: `20px`,
                                                  }}
                                                >
                                                 {track_shipment_lang[lang].VesselVoyage} 
                                                </p>
                                                <p>
                                                  {blRouteLegsss["vesselName"]}/
                                                  {blRouteLegsss["voyage"] +
                                                    " (" +
                                                    blRouteLegsss["vessel"] +
                                                    ")"}
                                                </p>
                                              </td>
                                            ) : last_leg ? (
                                              <td
                                                width="25%"
                                                style={{
                                                  background: `#ffe2d1`,
                                                }}
                                              >
                                                <p
                                                  class="table-font-weight"
                                                  style={{
                                                    paddingRight: `20px`,
                                                  }}
                                                >
                                                  {track_shipment_lang[lang].TruckRail}
                                                </p>
                                                <p></p>
                                              </td>
                                            ) : (
                                              <td width="25%"> </td>
                                            )}
                                            <td width="25%">
                                              {ETA != "" ? (
                                                <>
                                                  <p
                                                    class="table-font-weight"
                                                    style={{
                                                      paddingRight: `20px`,
                                                    }}
                                                  >
                                                    {" "}
                                                    {track_shipment_lang[lang].ETA}
                                                  </p>
                                                  <p>{ETA}</p>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </td>
                                            <td width="25%">
                                              {typeof blRouteLegsss[
                                                "sailingDateTz"
                                              ]["dateTime"] !== "undefined" &&
                                              blRouteLegsss["sailingDateTz"][
                                                "dateTime"
                                              ] != null ? (
                                                <>
                                                  <p
                                                    class="table-font-weight"
                                                    style={{
                                                      paddingRight: `20px`,
                                                    }}
                                                  >
                                                    {track_shipment_lang[lang].ETD}
                                                  </p>
                                                  <p>
                                                    {moment(
                                                      blRouteLegsss[
                                                        "sailingDateTz"
                                                      ]["dateTime"]
                                                    ).format("DD-MMM-YYYY")}
                                                  </p>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    {trackShipmentRes["response"]["blRouteLegs"]
                                      .length !=
                                    i + 1 ? (
                                      <div class="col-12 text-center">
                                        <img
                                          src={downarrow}
                                          style={{ width: `25px` }}
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    {blRouteLegsss["arrivalDateTz"][
                                      "dateTime"
                                    ] != null &&
                                    blRouteLegsss["portToType"] == "DEL" ? (
                                      <>
                                        <div class="col-12 text-center">
                                          <img
                                            src={downarrow}
                                            style={{ width: `25px` }}
                                          />
                                        </div>
                                        <div class="table-responsive p-1">
                                          <table
                                            class="table"
                                            style={{
                                              background: `#f3f3f3`,
                                              color: `#123c60`,
                                            }}
                                          >
                                            <tbody>
                                              <tr
                                                style={{
                                                  background: `#ffe787`,
                                                }}
                                              >
                                                <td width="25%">
                                                  <p
                                                    class="table-font-weight"
                                                    style={{
                                                      paddingRight: `20px`,
                                                    }}
                                                  >
                                                    {typeof trackShipmentRes[
                                                      "array"
                                                    ][
                                                      blRouteLegsss[
                                                        "portToType"
                                                      ]
                                                    ] !== "undefined"
                                                      ? trackShipmentRes[
                                                          "array"
                                                        ][
                                                          blRouteLegsss[
                                                            "portToType"
                                                          ]
                                                        ]
                                                      : blRouteLegsss[
                                                          "portToType"
                                                        ]}
                                                  </p>
                                                  <p>
                                                    {blRouteLegsss[
                                                      "portNameTo"
                                                    ] +
                                                      ", " +
                                                      blRouteLegsss[
                                                        "countryNameTo"
                                                      ]}
                                                  </p>
                                                </td>
                                                <td width="25%"></td>
                                                <td width="25%">
                                                  <p
                                                    class="table-font-weight"
                                                    style={{
                                                      paddingRight: `20px;`,
                                                    }}
                                                  >
                                                    {track_shipment_lang[lang].ETA}{" "}
                                                  </p>
                                                  <p>
                                                    {" "}
                                                    {moment(
                                                      blRouteLegsss[
                                                        "arrivalDateTz"
                                                      ]["dateTime"]
                                                    ).format("DD-MMM-YYYY")}
                                                  </p>
                                                </td>
                                                <td width="25%"></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              }
                            }
                          )}
                      </div>
                    ) : (
                      <div class="row track-shipment-output">
                        <table class="table track-shipment-output-tb1">
                          <thead>
                            <th>
                              <h3 class="track-shipment-heading">
                                <span>{track_shipment_lang[lang].ContainerNo}</span>
                                {trackShipmentRes["containerid"]}
                              </h3>
                            </th>
                          </thead>
                        </table>
                      </div>
                    )}
                  </div>
                  <Accordion>
                    <div class="table-responsive">
                      <table class="table ">
                        <thead>
                          <tr>
                            <th scope="col" width="15%">
                            {track_shipment_lang[lang].Container} 
                            </th>
                            <th scope="col" width="35%">
                            {track_shipment_lang[lang].LastActivity}
                            </th>
                            <th scope="col" width="20%">
                            {track_shipment_lang[lang].Location}
                            </th>
                            <th scope="col" width="12%">
                            {track_shipment_lang[lang].Date}
                            </th>
                            <th scope="col" width="18%">
                            {track_shipment_lang[lang].VesselVoyage}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {typeof trackShipmentRes["result"] !== "undefined" &&
                            trackShipmentRes["result"].map((results, i) => {
                              {
                              }
                              return (
                                <>
                                  <tr class="expand-button">
                                    <Accordion.Toggle
                                      as={ButtonGroup}
                                      variant="link"
                                      eventKey={i + 1}
                                    >
                                      <td class="expand-button">
                                        {results["container"]}
                                      </td>
                                    </Accordion.Toggle>
                                    <td>{results["last_activity"]}</td>
                                    <td>
                                      {results["placeFromDesc"] != null
                                        ? results["placeFromDesc"]
                                        : "" +
                                          ", " +
                                          (results["location"] != null)
                                        ? results["location"]
                                        : ""}
                                    </td>
                                    <td>
                                      {results["date"] != null
                                        ? moment(results["date"]).format(
                                            "DD-MMM-YYYY"
                                          )
                                        : ""}
                                    </td>
                                    <td> </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colSpan={5}
                                      style={{ padding: `0px !important` }}
                                    >
                                      <Accordion.Collapse eventKey={i + 1}>
                                        <table class="table table2">
                                          <thead>
                                            <tr>
                                              <th scope="col" width="15%"></th>
                                              <th scope="col" width="35%">
                                              {track_shipment_lang[lang].LastActivity} 
                                              </th>
                                              <th scope="col" width="20%">
                                                {track_shipment_lang[lang].Location}
                                              </th>
                                              <th scope="col" width="12%">
                                              {track_shipment_lang[lang].Date}
                                              </th>
                                              <th scope="col" width="18%">
                                                {track_shipment_lang[lang].VesselVoyage}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                           
                                              {typeof results["new_rec"] !==
                                                "undefined" &&
                                                results["new_rec"]
                                                  .reverse()
                                                  .map((new_rec, i) => {
                                                    {
                                                    }
                                                    return (
                                                      <tr
                                                        style={{
                                                          background: `#ffffff`,
                                                        }}
                                                      >
                                                        <td width="15%"></td>
                                                        <td>
                                                          {
                                                            new_rec[
                                                              "activityDesc"
                                                            ]
                                                          }
                                                        </td>
                                                        <td>
                                                          {new_rec[
                                                            "placeFromDesc"
                                                          ] +
                                                            ", " +
                                                            new_rec[
                                                              "countryFromName"
                                                            ]}
                                                        </td>
                                                        <td>
                                                          {moment(
                                                            new_rec[
                                                              "activityDateTz"
                                                            ]
                                                          ).format(
                                                            "DD-MMM-YYYY"
                                                          )}
                                                        </td>
                                                        <td>
                                                          {new_rec["voyage"] !=
                                                          " / "
                                                            ? new_rec["voyage"]
                                                            : ""}
                                                        </td>
                                                      </tr>
                                                    );
                                                  })}
                                        
                                          </tbody>
                                        </table>
                                      </Accordion.Collapse>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </Accordion>
                </>
              ) : (
                <div
                  class="row"
                  style={{
                    minHeight: `200px;`,
                    border: `solid 1px;`,
                    display: trackShipmentflag ? "block" : "none",
                  }}
                >
                  <div class="col">
                    <h3 style={{ paddingTop: `72px;`, textAlign: `center;` }}>
                      <i class="fa fa-search" aria-hidden="true"></i>{track_shipment_lang[lang].NoRecordFound}.
                    </h3>
                  </div>
                </div>
              )}
              <div class="container" >
                <div class="col-md-12  p-2">
                  <div
                    class="row"
                    style={{
                      display: pdfbuttonstatus ? `` : `none`,
                    }}
                  >
                    <div class="pb-2 pr-2 sch-full-form-btn ">
                      <p class="tariff-print" style={{ padding: `10px` }}>
                        <a
                          onClick={track_shipment_submit_pdf}
                          class="btn btn-info btn-lg"
                        >
                          <span class="glyphicon glyphicon-print"></span> {track_shipment_lang[lang].PDF}
                        </a>
                      </p>
                    </div>
                    <div class="pb-2 pr-2 sch-full-form-btn ">
                      <p class="tariff-print" style={{ padding: `10px` }}>
                        <a
                          onClick={track_shipment_submit_print}
                          class="btn btn-info btn-lg"
                        >
                          <span class="glyphicon glyphicon-print"></span> {track_shipment_lang[lang].Print}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <GET_IN_TOUCH />
    </Layout>
  );
};
export default Track_shipment;
