import React, { Component } from "react";
import Layout from "../../layout";
import GET_IN_TOUCH from "../../get_in_touch/index";
import Packages from "../../get_in_touch/packages_block";
import inside_heading from "../../../../src/assets/inside-heading.png";
import Inner_container from "../inner-container";
import Api from "../../../../package.json";
import * as axios from "axios";
import "react-tabs/style/react-tabs.css";
import { Accordion, Card, Button, ButtonGroup } from "react-bootstrap";
import banner1 from "../../../../src/assets/banner1.jpg";
import faq_lang from '../../lang/faq'
import Services from '../../../components/get_in_touch/services';

const qs = require("qs");
var jwt = require("jsonwebtoken");
export default class Faq extends Component {
  timeout = null;
  constructor(props) {
    super(props);
    this.state = {
      country_code: window.$country_code,
      faq: [],
      faq_data: [],
    };
  }
  
  async componentDidMount() {
    var lang = window.localStorage.getItem('lang');
    const headers = {
      type: "application/json",
      Authorization: `Bearer `+window.localStorage.getItem('access_token'),
      };
    const response = await axios.post(
      Api.userdata.url + "/faq",
      qs.stringify({ token: "7",lang:lang }, { parseArrays: false }),{headers:headers}
    );
    const json = await response.data;
    this.setState({ faq: json["message"] });
  }
  async get_faq_data(id) {
    const headers = {
      type: "application/json",
      Authorization: `Bearer `+window.localStorage.getItem('access_token'),
      };
    const response = await axios.post(
      Api.userdata.url + "/faq_data",
      qs.stringify({ id: id }, { parseArrays: false }),{headers:headers}
    );
    const json = await response.data;
    this.setState({ faq_data: json["message"] });
  }
  render() {
    var lang = window.localStorage.getItem('lang');
    return (
      <Layout>
        <Inner_container innerpage="faq" />

        <section class="inner-center n-40">
          <div class="container">
            <div class="row m-0">
              <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12 p-0">
                {/* <div class="sche-title" style={{ backgroundImage: `url(${inside_heading})` }}>
                                         <h3>Frequently Asked Questions</h3>
                                        
                                     </div> */}
                <div class="row m-0">
                  <div class="col-lg-12">
                    {this.state.faq.map((data) => {
                      return (
                        <div class="col-lg-12 col-sm-12 faq-heading">
                          <h2>
                            {" "}
                            {data["group_name"]} <span></span>
                          </h2>

                          {data["faq_data"].map((data_by_group) => {
                            return (
                              <Accordion>
                                <Card>
                                  <Card.Header
                                    bsPrefix="h3"
                                    className="accordion-link accordion-on"
                                  >
                                    <Accordion.Toggle
                                      as={ButtonGroup}
                                      variant="link"
                                      eventKey={data_by_group["id"]}
                                    >
                                      {data_by_group["title"]}
                                    </Accordion.Toggle>
                                  </Card.Header>
                                  <Accordion.Collapse
                                    eventKey={data_by_group["id"]}
                                  >
                                    <Card.Body>
                                      <div
                                        class="accordion-panel"
                                        dangerouslySetInnerHTML={{
                                          __html: data_by_group["description"],
                                        }}
                                      ></div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        
        <Services /> 
 
        <Packages />
        <GET_IN_TOUCH />
      </Layout>
    );
  }
}
