import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import project_cargo_ser1 from '../../../../src/assets/project-cargo-ser1.png';
import project_cargo_ser2 from '../../../../src/assets/project-cargo-ser2.png';
import abt_icn from '../../../../src/assets/abt-icn.png';
import Inner_container from '../../innerpages/inner-container';
import Project_cargo_lang from '../../lang/project-cargo';


var jwt = require('jsonwebtoken');
export default class Project_cargo extends Component {

    render() {
        var lang = window.localStorage.getItem('lang');
        return (

            <Layout>
                <Inner_container innerpage="project_cargo" />
                

                <section class="inner-center n-40">
                    <div class="container">
                        <form class="abt-full">
                            <div class="row ml-0 mr-0 pjt-cargo-row">

                                <div class="col-lg-5 col-sm-12 pot-form pl-0 dat-relat ">

                                    <div class=" cargo-n-imgg" >
                                        <img src={project_cargo_ser1} />
                                    </div>
                                </div>
                                <div class="col-lg-7 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s" >
                                    <div class="car-ser-new">
                                        <h3>{Project_cargo_lang[lang].ProjectCargo}</h3>
                                        <p>{Project_cargo_lang[lang].GSLsprojectcargo}</p>
                                        <p>{Project_cargo_lang[lang].Withsafetyasournumber}</p>
                                        <p>{Project_cargo_lang[lang].Extraordinarytasksare} </p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
                <section class="inner-center n-40">
                    <div class="container">
                        <form class="abt-full">
                            <div class="row ml-0 mr-0 ">


                                <div class="col-lg-7 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s" >
                                    <div class="car-ser-new">
                                        <h3>{Project_cargo_lang[lang].OutofGaugeCargo}</h3>
                                            <p>{Project_cargo_lang[lang].ThecargoGSLcarries}</p>
                                            <p>{Project_cargo_lang[lang].Wereadaptable}</p>
                                            <p>{Project_cargo_lang[lang].GSLteamsare}</p>
									</div>
                                    </div>
                                    <div class="col-lg-5 col-sm-12 pot-form pl-0 dat-relat ">

                                        <div class=" cargo-n-imgg" >
                                            <img src={project_cargo_ser2} />											
									</div>
                                        </div>
                                    </div>													
					</form>
                            </div>
</section>
                        <Services />
                        <Packages />
                        <GET_IN_TOUCH />
            </Layout>);
    }
}