const homelang =
{
    "en": {
        "ProjectCargo": "Project Cargo",
        "GSLsprojectcargo": "GSL's project cargo team handles a full range of special cargoes, specializing in creative solutions, one-on-one service, and cutting-edge expertise. Our extensive experience is the key to handling out of the ordinary shipments, identifying and catering to your unique needs, no matter how unusual. Outfitted with flats, open tops, and exclusive monitoring equipment, we are prepared to take on shipping challenges ranging from hazardous materials to historic planes.",
        "Withsafetyasournumber": "With safety as our number one priority, the GSL fleet transports the full range of dangerous materials, breakbulk cargoes and awkward-sized items that require special treatment. Dangerous cargoes such as biohazards or flammable substances, as well as Out-of-Gauge (OOG) cargoes like large vehicles, demand expertise and precision, and often need specialized equipment. Meanwhile, breakbulk cargo, such as crates, barrels, or bales, calls for top-notch experience and organization to transport every shipment quickly and efficiently. All these specialized cargoes are in good hands with GSL, as our unique know-how and personalized care guide them into a safe harbor.",
        "Extraordinarytasksare": "Extraordinary tasks are everyday jobs for GSL's project cargo team. They specialize in finding innovative ways to transport irregular cargo, using GSL's resources around the globe to safely get your cargo wherever it needs to go. From port, to ship, to truck, and right to their final destination, we offer outstanding service for cargo that stands out.",
        "OutofGaugeCargo": "Out-of-Gauge Cargo (OOG)",
        "ThecargoGSLcarries": "The cargo GSL carries is as diverse as our customers. Although the container shipping era has represented a sea change for global transportation, not all large and heavy cargo fits easily in a standard container. With GSL, you can be sure that your freight is in safe hands, no matter its shape or size.",
        "Wereadaptable": "We're adaptable, and we're happy for the chance to prove that fact day after day. Our expert staff reliably finds innovative solutions for OOG cargo using flat racks, and a wide variety of special equipment to get all Over Dimensional Cargo wherever it needs to go. Throughout every stage, we maintain the highest standards of precision and professionalism, ensuring that even the most oversized cargo is transported safely and efficiently. Our team of experts provide consultation and support throughout the shipping process.",
        "GSLteamsare": "GSL teams are in contact with our customers throughout the entire logistics procedure, starting from the designing process, making sure the manufactured part can be shipped on a container vessel, on through the planning and execution of the actual shipping process, until the cargo arrives safely at its destination. For each shipment, an array of experts – project managers, ship’s crew, land transportation specialists, surveyors and more – team up to handle the job in the best possible way. Optimal treatment of your cargo is our top priority.",
       
       
    },
    "cn": {
        "ProjectCargo": "項目貨物",
        "GSLsprojectcargo": "金星航運項目貨物團隊擁有豐富經驗處理任何類別貨物. 我們能幫助客戶界定及分類所有特殊貨品, 提供最合適運送方式. 不論是平板櫃, 開頂櫃或特別監控處理, 又或是從危險貨到貴重物品, 我們都能為客戶承擔運送責任。",
        "Withsafetyasournumber": "不論運送危險貨物, 大型貨物及特殊體積貨物, 金星航運都會小心處理, 著重安全. 如生化或易燃物品, 又或是車輛, 都須要專業處理及設備來維持運輸過程安全. 一些超大型或不規則包裝貨物，亦須要高度專業能力來安排快速和有效率的運送. 對所有特別貨物, 都能交給金星航運, 憑著我們經驗, 定能使貨物安全處理.",
        "Extraordinarytasksare": "金星航運項目貨物團隊熟練於處理非一般貨運程序.團隊善於提出有效解決方案來處理特別貨物. 更會利用公司全球網絡資源來確保客戶貨物安全運送. 無論在任何運輸工具上甚至在港口或碼頭, 我們都提供優質服務令貨物處理穩妥.",
        "OutofGaugeCargo": "超大型貨物",
        "ThecargoGSLcarries": "金星航運一直運載各式各樣的貨物. 貨櫃載運雖然主導航運行業, 但一些大型及重型貨物並不適合於傳統貨櫃運輸. 所以我們公司對這些貨物運送有特定方案來解決, 令客戶安心.",
        "Wereadaptable": "我們公司樂意為客戶服務, 因我們擁有專業團隊為客戶制定超大型貨物運送解決方案, 無論任何體積重量或運送到何地, 也能提出有效方法處理. 在運送過程中, 我們都能展現出高標準及高專業能力, 更會全力支援及指導, 務求做到安全有效地把貨物運送到目的地. ",
        "GSLteamsare": "金星航運團隊全程與客戶保持緊密聯係,由制定方案到確認貨物上載船隻. 從計劃到實行, 直至貨物抵達目的地, 每個環節都有我們合作團隊全力支援. 包括項目經理, 船隊船員, 陸路運輸隊伍, 測量師等. 一致合力將客戶貨物優先處理.",
       
    },
    "zh": {
        "ProjectCargo": "项目型货物",
        "GSLsprojectcargo": "金星航运项目团队拥有丰富经验处理任何类别货物.我们能帮助客户界定及分类所有特殊货品, 提供最合适运送方式. 无论是平板箱, 开顶箱或特别监控处理, 又或是从危险货到贵重物品, 我们都能为客户承担运送责任",
        "Withsafetyasournumber": "无论运送危险货物, 大型货物及特殊体积货物, 金星航运都会小心处理, 著重安全. 如生化或易燃物品, 又或是车辆, 都须要专业处理及设备来维持运输过程安全. 一些超大型或不规则包装货物, 亦须要高度专业能力来安排快速和有效率的运送. 对所有特别货物, 都能交给金星航运, 凭著我们经验, 定能使货物安全处理",
        "Extraordinarytasksare": "金星航运项目货物团队熟练于处理非一般货运程序.团队善于提出有效解决方案来处理特别货物. 更会利用公司全球网络资源来确保客户货物安全运送. 无论在任何运输工具上甚至在港口或码头, 我们都提供优质服务令货物处理稳妥. ",
        "OutofGaugeCargo": "超大型货物",
        "ThecargoGSLcarries": "金星航运一直运载各式各样的货物,货箱载运虽然主导航运行业, 但一些大型及重型货物并不适合于传统货箱运输. 所以我们公司对这些货物运送有特定方案来解决, 令客户安心",
        "Wereadaptable": "我们公司乐意为客户服务,因我们拥有专业团队为客户制定超大型货物运送解决方案, 无论任何体积重量或送达何地, 也能提出有效方法处理. 在运送过程中, 我们都能展现出高标准及高专业能力, 更会全力支援及指导, 务求做到安全有效地把货物运送到目的地",
        "GSLteamsare": "金星航运团队全程与客户保持紧密联系,由指定方案到确认货物上载船只. 从计划到实行, 直至货物抵达目的地, 每个环节都有我们合作团队全力支援. 包括项目经理, 船队船员, 陆路运输队伍, 测量师等. 一致合力将客户货物优先处理",
       
    },
    "ko": {
        "ProjectCargo": "Project Cargo",
        "GSLsprojectcargo": "GSL의 프로젝트 화물 팀은 크리에이티브 솔루션, 일대일 서비스 및 최첨단 전문 지식을 전문으로 하는 모든 범위의 특수 화물을 취급합니다. 당사의 광범위한 경험은 아무리 이례적이더라도 귀사의 고유한 요구를 파악하고 충족시키는 일반적인 발송물을 처리하는 데 있어 핵심입니다. 플랫, 오픈 탑, 전용 모니터링 장비를 갖춘 당사는 위험 물질에서 역사적인 비행기에 이르기까지 운송 문제에 대처할 준비가 되어 있습니다.",
        "Withsafetyasournumber": "안전을 최우선으로 하여 GSL 배는 모든 종류의 위험 물질, 방파제 화물 및 특별한 처리가 필요한 곤란한 크기의 물품을 운송합니다. 생물학적 위험물이나 인화성 물질과 같은 위험한 화물은 물론 대형 차량과 같은 OOG(Out-of-Gauge) 화물은 전문성과 정밀성이 요구되며 전문 장비가 필요한 경우가 많습니다. 한편, 상자, 배럴 또는 대야와 같은 대형 화물은 모든 화물을 빠르고 효율적으로 운송할 수 있는 최고의 경험과 조직을 필요로 합니다. 우리의 독특한 노하우와 개인 맞춤형 케어가 그들을 안전한 항구로 안내하기 때문에 이 모든 전문 화물은 GSL과 잘 통합니다.",
        "Extraordinarytasksare": "GSL의 프로젝트 화물팀의 일상 업무는 특별합니다. 그들은 전세계 GSL의 자원을 이용하여 화물이 어디로 가든지 안전하게 화물을 운반할 수 있는 혁신적인 방법을 찾는 것을 전문으로 합니다. 항구, 선박, 트럭, 그리고 최종 목적지까지 눈에 띄는 화물에 대한 탁월한 서비스를 제공합니다.",
        "OutofGaugeCargo": "Out-of-Gauge Cargo (OOG)",
        "ThecargoGSLcarries": "GSL이 운반하는 화물은 우리 고객만큼 다양합니다. 컨테이너 선적 시대는 전 세계 운송에 있어 획기적인 변화를 나타내왔지만, 모든 크고 무거운 화물이 표준 컨테이너에 쉽게 들어가는 것은 아니다. GSL을 사용하면 화물의 형태나 크기에 관계없이 화물이 안전하게 보관될 수 있습니다.",
        "Wereadaptable": "우리는 적응력이 있고, 매일 그 사실을 증명할 수 있는 기회를 갖게 되어 기쁩니다. 당사의 전문 직원은 플랫 랙과 필요한 모든 Over Dimensional Cargo를 어디에서나 사용할 수 있는 다양한 특수 장비를 사용하여 OOG 화물에 대한 혁신적인 솔루션을 안정적으로 찾습니다. 모든 단계에서 우리는 최고 수준의 정밀도와 전문성을 유지하여 아무리 큰 화물이라도 안전하고 효율적으로 운송할 수 있도록 보장합니다. 저희 전문가 팀은 배송 과정 전반에 걸쳐 상담과 지원을 제공합니다.",
        "GSLteamsare": "GSL 팀은 설계 프로세스부터 시작하여 화물이 목적지에 안전하게 도착할 때까지 실제 선적 프로세스의 계획과 실행을 통해 제조된 부품을 컨테이너 선박에 선적할 수 있도록 모든 물류 프로세스에서 고객과 접촉합니다. 각 선적에 대해 프로젝트 매니저, 선박 승무원, 육상 운송 전문가, 측량사 등 다양한 전문가들이 팀을 이루어 최선의 방법으로 작업을 처리합니다. 당신의 화물을 최적으로 처리하는 것이 우리의 최우선 과제입니다.",
       
    },
    "vi": {
        "ProjectCargo": "Hàng dự án",
        "GSLsprojectcargo": "Nhóm vận chuyển hàng hóa dự án của GSL có thể xử lý phạm vi rộng khắp của các loại hàng hóa đặc biệt, chuyên về các giải pháp sáng tạo, dịch vụ một đối một và chuyên môn tiên tiến. Kinh nghiệm dày dặn của chúng tôi là chìa khóa để xử lý các lô hàng thông thường, xác định và đáp ứng các nhu cầu riêng biệt của bạn, cho dù yêu cầu cao đến mức nào. Được trang bị bởi các loại containers như flats, open tops và các thiết bị giám sát độc quyền, chúng tôi sẵn sàng đối mặt với những thách thức vận chuyển từ vật liệu nguy hiểm đến máy bay lịch sử.",
        "Withsafetyasournumber": "Sự an toàn là ưu tiên số một của chúng tôi, GSL vận chuyển tất cả thể loại vật liệu nguy hiểm, hàng quá khổ và các mặt hàng có kích cỡ khó xử lý cần được xử lý đặc biệt. Các loại hàng hóa nguy hiểm như chất nguy hiểm sinh học hoặc chất dễ cháy, cũng như hàng hóa quá cỡ (OOG) như xe lớn, đòi hỏi chuyên môn và độ chính xác, và yêu cầu thiết bị chuyên dụng. Trong khi đó, các loại hàng hóa dạng quá khổ, chẳng hạn như thùng hoặc kiện lớn, đòi hỏi kinh nghiệm và tổ chức hàng đầu để vận chuyển mọi lô hàng một cách nhanh chóng và hiệu quả. Tất cả những hàng hóa chuyên dụng này đều nằm trong tầm tay của GSL, vì bí quyết độc đáo và dịch vụ chăm sóc cá nhân hóa của chúng tôi sẽ vận chuyển chúng đến bến cảng an toàn.",
        "Extraordinarytasksare": "Nhiệm vụ đặc biệt là công việc hàng ngày đối với nhóm vận chuyển hàng hóa dự án của GSL. Họ chuyên tìm kiếm những cách thức sáng tạo để vận chuyển hàng hóa không thường xuyên, sử dụng các nguồn lực của GSL trên toàn cầu để vận chuyển hàng hóa của bạn một cách an toàn đến bất cứ nơi đâu. Từ cảng, đến tàu, đến xe tải và đến đích cuối cùng của họ, chúng tôi cung cấp dịch vụ tuyệt vời cho hàng hóa đặc biệt.",
        "OutofGaugeCargo": "Hàng quá khổ (OOG)",
        "ThecargoGSLcarries": "Hàng hóa GSL chuyên chở cũng đa dạng như khách hàng của chúng tôi. Mặc dù thời đại vận chuyển container đã đại diện cho sự thay đổi đáng kể đối với vận tải toàn cầu, nhưng không phải tất cả hàng hóa lớn và nặng đều dễ dàng nằm gọn trong một container tiêu chuẩn. Với GSL, bạn có thể chắc chắn rằng hàng hóa của bạn luôn ở trong tình trạng an toàn, bất kể hình dạng hay kích cỡ của nó.",
        "Wereadaptable": "Chúng tôi có khả năng thích nghi và rất vui vì có cơ hội chứng minh điều đó theo thời gian. Đội ngũ chuyên gia đáng tin cậy của chúng tôi có thể tìm ra các giải pháp sáng tạo cho hàng hóa quá khổ bằng cách sử dụng giá đỡ phẳng và nhiều loại thiết bị đặc biệt để vận chuyển tất cả hàng hóa quá khổ đến bất cứ nơi đâu cần đến. Trong suốt mọi khâu, chúng tôi duy trì các tiêu chuẩn cao nhất về độ chính xác và tính chuyên nghiệp, đảm bảo rằng ngay cả những hàng hóa quá khổ nhất cũng được vận chuyển một cách an toàn và hiệu quả. Đội ngũ chuyên gia của chúng tôi luôn tư vấn và hỗ trợ trong suốt quá trình vận chuyển.",
        "GSLteamsare": "Các nhóm GSL giữ liên hệ với khách hàng của chúng tôi trong toàn bộ quy trình hậu cần, bắt đầu từ quá trình thiết kế, đảm bảo bộ phận được sản xuất có thể được vận chuyển trên tàu container, thông qua việc lập kế hoạch và thực hiện quy trình vận chuyển thực tế, cho đến khi hàng hóa đến nơi an toàn tại điểm đến của nó. Đối với mỗi chuyến hàng, một loạt các chuyên gia - quản lý dự án, thủy thủ đoàn, chuyên gia vận tải đường bộ, nhân viên khảo sát, v.v. - hợp tác để xử lý công việc theo cách tốt nhất có thể. Áp dụng phương pháp làm hàng tối ưu đối với hàng hóa của bạn là ưu tiên hàng đầu của chúng tôi.",
       
    },
    "th": {
        "ProjectCargo": "สินค้าโครงการ",
        "GSLsprojectcargo": "ทีมงานขนส่งสินค้าตามโครงการของ GSL จัดการสินค้าพิเศษอย่างเต็มรูปแบบ เชี่ยวชาญด้านโซลูชั่นที่สร้างสรรค์ บริการแบบเฉพาะตัว และความเชี่ยวชาญที่ล้ำสมัย ประสบการณ์ที่กว้างขวางของเราคือกุญแจสำคัญในการจัดการกับการขนส่งที่ไม่ปกติ การระบุและตอบสนองความต้องการเฉพาะของคุณ ไม่ว่าจะพบความยากเพียงใด เราพร้อมที่จะรับมือกับความท้าทายในการขนส่งตั้งแต่วัสดุอันตรายไปจนถึงเครื่องบิน",
        "Withsafetyasournumber": "ความปลอดภัยเป็นสิ่งที่เราให้ความสำคัญเป็นอันดับหนึ่ง กองเรือ GSL จึงขนส่งวัสดุอันตรายอย่างเต็มรูปแบบ สินค้าที่มีปริมาณมาก และสิ่งของที่มีขนาดใหญ่ซึ่งต้องได้รับการดูแลเป็นพิเศษ สินค้าอันตราย เช่น วัตถุอันตรายทางชีวภาพหรือสารไวไฟ เช่นเดียวกับสินค้า OOG เช่น ยานพาหนะขนาดใหญ่ ความต้องการความเชี่ยวชาญและความแม่นยำ ในขณะเดียวกัน การขนส่งสินค้าเทกอง เช่น ลัง บาร์เรล หรือหีบห่อ จำเป็นต้องใช้ประสบการณ์และองค์กรชั้นยอดในการขนส่งสินค้าทุกชิ้นอย่างรวดเร็วและมีประสิทธิภาพ ให้GSLดูแลสินค้าของคุณ เนื่องจากความรู้ความชำนาญเฉพาะตัวและการดูแลเฉพาะบุคคลของเราจะนำสินค้าไปสู่ปลายทางอย่างปลอดภัย",
        "Extraordinarytasksare": "งานที่พิเศษคืองานประจำวันสำหรับทีมขนส่งสินค้าของโครงการของ GSL เราเชี่ยวชาญในการหาวิธีใหม่ในการขนส่งสินค้า เพื่อขนส่งสินค้าของคุณอย่างปลอดภัยทุกที่ที่ต้องการ จากท่าเรือ สู่เรือและการขนส่งทางบก ให้ถึงจุดหมายปลายทาง เรานำเสนอบริการที่โดดเด่นสำหรับคุณ",
        "OutofGaugeCargo": "สินค้าที่มีขนาดใหญ่",
        "ThecargoGSLcarries": "การขนส่งสินค้า GSL มีความหลากหลาย เหมือนกับลูกค้าของเรา แม้ว่ายุคการขนส่งตู้คอนเทนเนอร์จะเป็นตัวแทนของการเปลี่ยนแปลงทางทะเลสำหรับการขนส่งทั่วโลก แต่สินค้าขนาดใหญ่และหนักทั้งหมดนั้นไม่สามารถบรรจุลงในตู้คอนเทนเนอร์มาตรฐานได้ คุณสามารถมั่นใจได้ว่าสินค้าของคุณจะอยู่ในมืออย่างปลอดภัย ไม่ว่ารูปร่างหรือขนาดจะเป็นอย่างไร",
        "Wereadaptable": "เราปรับตัวและเรามีความสุขกับโอกาสที่จะพิสูจน์ข้อเท็จจริงนั้นวันแล้ววันเล่า เจ้าหน้าที่ผู้เชี่ยวชาญของเราจะค้นหาโซลูชันที่เป็นนวัตกรรมใหม่สำหรับสินค้า OOG โดยใช้ Flat racksและอุปกรณ์พิเศษที่หลากหลายเพื่อรับสินค้าขนาดมหญ่พิเศษได้ทุกที่ที่ต้องการ ตลอดทุกขั้นตอน เรารักษามาตรฐานสูงสุดของความเที่ยงตรงและความเป็นมืออาชีพ เพื่อให้มั่นใจว่าแม้สินค้าจะได้รับการขนส่งอย่างปลอดภัยและมีประสิทธิภาพ ทีมผู้เชี่ยวชาญของเราให้คำปรึกษาและสนับสนุนตลอดกระบวนการจัดส่ง",
        "GSLteamsare": "ทีมงานของ GSL ติดต่อกับลูกค้าของตลอดกระบวนการด้านโลจิสติกส์ เริ่มจากขั้นตอนการออกแบบ ตรวจสอบให้แน่ใจว่าชิ้นส่วนที่สามารถจัดส่งบนเรือคอนเทนเนอร์ ผ่านการวางแผนและการดำเนินการตามกระบวนการขนส่งจริง จนกว่าสินค้าจะมาถึงอย่างปลอดภัยที่ปลายทาง สำหรับการจัดส่งแต่ละครั้ง ผู้เชี่ยวชาญจำนวนมาก - ผู้จัดการโครงการ, ลูกเรือ, ผู้เชี่ยวชาญด้านการขนส่งทางบก, ผู้สำรวจและอื่น ๆ - จะร่วมมือกันจัดการงานนี้ด้วยวิธีที่ดีที่สุด การดูแลสินค้าของคุณอย่างเหมาะสมคือสิ่งสำคัญที่สุดของเรา",
       
    },
    "in": {
        "ProjectCargo": "Project Cargo",
        "GSLsprojectcargo": "Tim kargo proyek GSL menangani berbagai macam kargo khusus, yang berspesialisasi dalam solusi kreatif, layanan one-on-one, dan keahlian mutakhir. Pengalaman kami yang luas adalah kunci untuk menangani pengiriman yang tidak biasa, mengidentifikasi dan melayani kebutuhan unik Anda, tidak peduli seberapa tidak biasa. Dilengkapi dengan flat, atasan terbuka, dan peralatan pemantauan eksklusif, kami siap menghadapi tantangan pengiriman mulai dari bahan berbahaya hingga pesawat bersejarah.",
        "Withsafetyasournumber": "Dengan keselamatan sebagai prioritas nomor satu kami, armada GSL mengangkut berbagai macam bahan berbahaya, kargo curah, dan barang berukuran aneh yang memerlukan perlakuan khusus. Kargo berbahaya seperti biohazards atau zat yang mudah terbakar, serta kargo Out-of-Gauge (OOG) seperti kendaraan besar, menuntut keahlian dan ketelitian, dan seringkali membutuhkan peralatan khusus. Sementara itu, kargo breakbulk, seperti peti, tong, atau bal, membutuhkan pengalaman dan pengaturan terbaik untuk mengangkut setiap pengiriman dengan cepat dan efisien. Semua kargo khusus ini ditangani dengan baik oleh GSL, karena pengetahuan unik dan perawatan pribadi kami memandu mereka ke pelabuhan yang aman.",
        "Extraordinarytasksare": "Tugas luar biasa adalah pekerjaan sehari-hari untuk tim kargo proyek GSL. Mereka berspesialisasi dalam menemukan cara inovatif untuk mengangkut kargo tidak beraturan, menggunakan sumber daya GSL di seluruh dunia untuk membawa kargo Anda dengan aman ke mana pun harus pergi. Dari pelabuhan, ke kapal, ke truk, dan langsung ke tujuan akhir mereka, kami menawarkan layanan luar biasa untuk kargo yang menonjol.",
        "OutofGaugeCargo": "Out-of-Gauge Cargo (OOG)",
        "ThecargoGSLcarries": "Kargo yang dibawa GSL beragam seperti pelanggan kami. Meskipun era pengiriman peti kemas telah mewakili perubahan besar untuk transportasi global, tidak semua kargo besar dan berat dapat dengan mudah masuk ke dalam peti kemas standar. Dengan GSL, Anda dapat yakin bahwa barang Anda berada di tangan yang aman, apa pun bentuk atau ukurannya.",
        "Wereadaptable": "Kami mudah beradaptasi, dan kami senang mendapat kesempatan untuk membuktikan fakta itu hari demi hari. Staf ahli kami dengan andal menemukan solusi inovatif untuk kargo OOG menggunakan rak datar, dan berbagai macam peralatan khusus untuk membawa semua Kargo Over Dimensional ke mana pun harus pergi. Di setiap tahapan, kami mempertahankan standar presisi dan profesionalisme tertinggi, memastikan bahwa kargo yang paling besar pun diangkut dengan aman dan efisien. Tim ahli kami memberikan konsultasi dan dukungan selama proses pengiriman.",
        "GSLteamsare": "Tim GSL berhubungan dengan pelanggan kami selama seluruh prosedur logistik, mulai dari proses perancangan, memastikan suku cadang yang diproduksi dapat dikirim dengan kapal kontainer, melalui perencanaan dan pelaksanaan proses pengiriman yang aktual, hingga kargo tiba dengan selamat di tempat tujuannya. Untuk setiap pengiriman, berbagai ahli – manajer proyek, awak kapal, spesialis transportasi darat, surveyor, dan banyak lagi – bekerja sama untuk menangani pekerjaan dengan cara terbaik. Penanganan yang optimal untuk kargo Anda adalah prioritas utama kami.",
       
       
    },
    "ja": {
        "ProjectCargo": "特殊貨物",
        "GSLsprojectcargo": "GSLの特殊貨物チームは、創造的なソリューション、個別対応のサービス、最先端の専門知識を専門として、あらゆる種類の特殊貨物を取り扱っています。当社の豊富な経験は、通常とは異なる貨物の取り扱いの鍵となり、どんなに珍しいものであっても、お客様独自のニーズを特定しそれにお応えします。フラット、オープントップ、専用監視装置を装備し、危険物から歴史的な飛行機まで、さまざまな輸送の課題に対応します。",
        "Withsafetyasournumber": "安全性を第一に考え、危険物、ばら積み貨物、特殊な取り扱いを必要とする大型貨物など、あらゆる貨物の輸送に対応します。バイオハザードや可燃性物質などの危険物、大型車両などの定型外（OOG）貨物は、専門知識と正確さが要求され、専用機材が必要になることも少なくありません。一方、木箱、樽、俵などのばら積み貨物は、すべての貨物を迅速かつ効率的に輸送するために、一流の経験と統制が必要とされます。こうした特殊な貨物も、GSLなら独自のノウハウときめ細かな対応で、安全な輸送を実現します。",
        "Extraordinarytasksare": "GSLの特殊貨物チームにとっては、非日常的なタスクは日常的な仕事です。世界中のGSLのリソースを駆使して、非定形貨物の革新的な輸送方法を見つけ出し、お客様の貨物を必要な場所に安全にお届けすることを専門としています。港から船、トラック、そして最終目的地まで、卓越したサービスを提供します。",
        "OutofGaugeCargo": "定型外貨物(OOG)",
        "ThecargoGSLcarries": "GSLが取り扱う貨物は、お客様と同様に多種多様です。コンテナ輸送の時代となり、世界の輸送は大きく変わりましたが、大型貨物や重量貨物は、通常のコンテナに簡単に収まるものばかりではありません。GSLでは、どのような形や大きさの貨物でも安心してお任せいただけます。",
        "Wereadaptable": "私たちはそのことを日々実感しています。当社の専門スタッフは、フラットラックや様々な特殊機器を使用して、定形外貨物のための革新的なソリューションを確実に見つけ出し、すべての定形外貨物を必要な場所に運びます。すべての段階において、最高水準の精度とプロフェッショナリズムを維持し、最大級の貨物でも安全かつ効率的に輸送することをお約束します。当社のエキスパート・チームは、輸送プロセスを通じてコンサルティングとサポートを提供します。",
        "GSLteamsare": "GSLチームは設計段階から製作した部品がコンテナ船で輸送できることを確認し、実際の輸送計画を立て実行し、目的地に無事に到着するまで、物流プロセス全体を通してお客様とコンタクトを取らせていただきます。各貨物について、プロジェクトマネージャー、船員、陸上輸送の専門家、測量士など、さまざまな専門家がチームを組み、最適な方法で輸送を行います。当社はお客様の貨物の最適な取り扱いを最優先に考えています。",
       
       
    },
}
export default homelang;