import React, { Component, useEffect, useState, useRef } from "react";
import Layout from "../../../components/layout";
import GET_IN_TOUCH from "../../../components/get_in_touch/index";
import Services from "../../../components/get_in_touch/services";
import Packages from "../../../components/get_in_touch/packages_block";
import Api from "../../../../package.json";
import * as axios from "axios";
import Select from "react-select";
import GoToTop from "../../../components/go-to-top";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { useForm, Controller } from "react-hook-form";
import "../tools/css/css-loader.css";
import { Accordion, Card, Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
import sob_india_lang from '../../lang/sob-india'

const qs = require("qs");
var jwt = require("jsonwebtoken");

const Sob_india = () => {
  const [country_code, setCountryCode] = useState(window.country_code);
  const [trackShipmentRes, settrackShipmentRes] = useState([]);
  const [loaderFlag, setloaderFlag] = useState("is-inactive");
  const [pdfbuttonstatus, setpdfbuttonstatus] = useState(false);
  const [schedulesearchflag, setschedulesearchflag] = useState(false);

  var lang = window.localStorage.getItem("lang");
  if (window.localStorage.getItem("lang") == null) {
    lang = 'en';
    window.localStorage.setItem("lang", 'en');
  }

  const headers = {
		type: "application/json",
		Authorization: `Bearer `+window.localStorage.getItem('access_token'),
	  };
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    track_shipment_submit(data);
  };
  const track_shipment_submit = async (datas) => {
    setschedulesearchflag(false);
    setloaderFlag("is-active");
    const response = await axios.post(
      Api.userdata.url + "/get_sob_india",
      qs.stringify({
        country_code: country_code,
        containerid: datas.containerid,
      }),
      {
        headers:headers,
        responseType: "blob",
      }
    );
    const json1 = await response.data;
 
  
    if (new Blob([response.data]).size > 5) {
      const file = new Blob([json1], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const tempLink = document.createElement("a");
      tempLink.href = fileURL;
      tempLink.setAttribute("download", getValues("containerid") + ".pdf");
      tempLink.click();
      setValue("containerid",'');
      swal("Success !","SOB certificate downloaded", "success");
    } else {
      setschedulesearchflag(true);
    }
    
    setloaderFlag("is-inactive");
  };
  const checkcontainer = async (val) => {
    const response = await axios.post(
      Api.userdata.url + "/container_bl_validation",
      qs.stringify({ containerid: val }),{headers:headers}
    );
    const res = await response.data;
    if (res == false) {
      return "Enter container number - format : 4 letters followed by 7 digits";
    } else {
      return true;
    }
  };
  useEffect(() => {}, []);
  return (
    <Layout>
      <div class={"loadernew loader-double " + loaderFlag}></div>
      <section class="inner-center">
        <br></br>
        <div class="container">
          <div class="row m-0">
            <div
              class="col-lg-12 col-md-12 col-lg-12"
              style={{ minHeight: "500px;" }}
            >
              <form
                autocomplete="on"
                name="point-to-point-new"
                id="point-to-point-new"
                method="get"
                action="#"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div class="form-fback">
                  <div class="row m-0">
                    <div class="col-lg-5 col-md-12 col-sm-12 pot-form p-3">
                      <div class="dout-inner">
                        <h3>{sob_india_lang[lang].SOB_India}</h3>
                        <p>{sob_india_lang[lang].SOB_India1} </p>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 p-0 pot-form">
                      <div class="row">
                        <div class="col-md-8 col-lg-8 col-sm-12">
                          <label>{sob_india_lang[lang].BillofLadingNo}</label>
                          <input
                            type="text"
                            id="containerid"
                            name="containerid"
                            class="form-control"
                            style={{ textTransform: "uppercase" }}
                            {...register("containerid", {
                              validate: (value) => {
                                return checkcontainer(value);
                              },
                              required: {
                                value: true,
                                message: "This field is required.",
                              },
                            })}
                          />
                          {errors.containerid && (
                            <span class="errors">
                              {errors.containerid.message}
                            </span>
                          )}
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-12 track-cont-no">
                          <div class="form-group">
                            <button
                              class="btn btn-default hovereffect portal-searc-tariff"
                              id="submitDetails"
                              name="my-buttons"
                              type="submit"
                              style={{width: `250px`}}
                            >
                            {sob_india_lang[lang].DownloadCertificate} 
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            class="row"
            style={{
              minHeight: `200px`,
              border: `solid 1px`,
              display: schedulesearchflag ? "block" : "none",
            }}
          >
            <div class="col">
              <h3 style={{ paddingTop: `72px`, textAlign: `center` }}>
                <i class="fa fa-search" aria-hidden="true"></i> {sob_india_lang[lang].NoRecordFound}.
              </h3>
            </div>
          </div>
        </div>
      </section>

      <GET_IN_TOUCH />
    </Layout>
  );
};

export default Sob_india;
