import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import container_cargo from '../../../../src/assets/container-cargo.png';
import abt_icn from '../../../../src/assets/abt-icn.png';
import Inner_container from '../../innerpages/inner-container';
import Cargo_services_lang from '../../lang/cargo-services';


var jwt = require('jsonwebtoken');
export default class Cargo_services extends Component {

    render() {
        var lang = window.localStorage.getItem('lang');
        return (

            <Layout>

                <Inner_container innerpage="cargo_services" />


                <section class="inner-center n-40">
                    <div class="container">
                        <form class="abt-full">
                            <div class="row ml-0 mr-0 pjt-cargo-row">

                                <div class="col-lg-5 col-sm-12 pot-form pl-0 dat-relat ">

                                    <div class=" cargo-n-imgg" >
                                        <img src={container_cargo} />
                                    </div>
                                </div>

                                <div class="col-lg-7 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s" >
                                    <div class="car-ser-new">

                                        <h3>{/* <img src={abt_icn} /> */}{Cargo_services_lang[lang].Cargo_Services}</h3>

                                        <p>{Cargo_services_lang[lang].GSLensuressmoothsailing}</p>
                                        <p>{Cargo_services_lang[lang].Differentcargoesnaturally}</p>
                                        <p>{Cargo_services_lang[lang].Weunderstandthateach}  </p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout>);
    }
}