const homelang =
{
    "en": {
        "GSLOfficeDetails": "GSL Office Details",
        "Getourvariousofficedetails": "Get our various office details",
        "From": "From",
        "Find": "Find",
        "GeneralInfo": "General Info",
        "CountryName": "Country Name",
        "PortName": "Port Name",
        "CompanyName": "Company Name",
        "Address1": "Address1",
        "Address2": "Address2",
        "Address3": "Address3",
        "Address4": "Address4",
        "Telephone": "Telephone",
        "Fax": "Fax",
        "Contact": "Contact",
        "Email": "Email",
        "ContactDetails": "Contact Details",
        "Division": "Division",
        "Contact": "Contact",
        "Telephone": "Telephone",
        "Fax": "Fax",
        "Email": "Email",
        "DeskDetails": "Desk Details",
        "Function": "Function",
      
    },
    "cn": {
        "GSLOfficeDetails": "金星航運辦事處資料",
        "Getourvariousofficedetails": "取得其它公司資料",
        "From": "由",
        "Find": "查詢",
        "GeneralInfo": "一般資料",
        "CountryName": "國家",
        "PortName": "港口",
        "CompanyName": "公司名字",
        "Address1": "地址 1",
        "Address2": "地址 2",
        "Address3": "地址 3",
        "Address4": "地址 4",
        "Telephone": "電話",
        "Fax": "傳真號碼",
        "Contact": "聯絡人",
        "Email": "電郵",
        "ContactDetails": "聯絡資料",
        "Division": "主職",
        "Contact": "聯絡人",
        "Telephone": "電話",
        "Fax": "傳真號碼",
        "Email": "電郵",
        "DeskDetails": "部門資料",
        "Function": "部門資料",
        
    },
    "zh": {
        "GSLOfficeDetails": "金星航运办事处资料",
        "Getourvariousofficedetails": "取得其它公司资料",
        "From": "由",
        "Find": "查询",
        "GeneralInfo": "一般资料",
        "CountryName": "国家",
        "PortName": "港口",
        "CompanyName": "公司名字",
        "Address1": "地址 1",
        "Address2": "地址 2",
        "Address3": "地址 3",
        "Address4": "地址 4",
        "Telephone": "电话",
        "Fax": "传真号码",
        "Contact": "联络人",
        "Email": "电邮",
        "ContactDetails": "联络资料",
        "Division": "主职",
        "Contact": "联络人",
        "Telephone": "电话",
        "Fax": "传真号码",
        "Email": "电邮",
        "DeskDetails": "部门资料",
        "Function": "部门资料",
        
    },
    "ko": {
        "GSLOfficeDetails": "GSL 사무소 정보",
        "Getourvariousofficedetails": "당사의 사무실 정보 받기",
        "From": "시작",
        "Find": "찾기",
        "GeneralInfo": "일반 정보",
        "CountryName": "국가 이름",
        "PortName": "항구/도시 이름",
        "CompanyName": "회사 이름",
        "Address1": "주소 1",
        "Address2": "주소 2",
        "Address3": "주소 3",
        "Address4": "주소 4",
        "Telephone": "전화 번호",
    },
    "vi": {
        "GSLOfficeDetails": "Chi tiết về văn phòng GSL",
        "Getourvariousofficedetails": "Thêm thông tin về các văn phòng của chúng tôi",
        "From": "Từ",
        "Find": "Tìm kiếm",
        "GeneralInfo": "Thông tin chung",
        "CountryName": "Tên quốc gia",
        "PortName": "Tên cảng",
        "CompanyName": "Tên công ty",
        "Address1": "Địa chỉ 1",
        "Address2": "Địa chỉ 2",
        "Address3": "Địa chỉ 3",
        "Address4": "Địa chỉ 4",
        "Telephone": "Điện thoại",
        "Fax": "Fax",
        "Contact": "Liên hệ",
        "Email": "Thư điện tử",
        "ContactDetails": "Chi tiết liên hệ",
        "Division": "Phòng ban",
        "Contact": "Liên hệ",
        "Telephone": "Điện thoại",
        "Fax": "Fax",
        "Email": "Thư điện tử",
        "DeskDetails": "Bộ phận cụ thể",
        "Function": "Chức vụ",
        
    },
    "th": {
        "GSLOfficeDetails": "รายละเอียดสำนักงาน GSL",
        "Getourvariousofficedetails": "รายละเอียดสำนักงานต่างๆของเรา",
        "From": "จาก",
        "Find": "ค้นหา",
        "GeneralInfo": "ข้อมูลทั่วไป",
        "CountryName": "ประเทศ",
        "PortName": "ชื่อท่าเรือ",
        "CompanyName": "ชื่อบริษัท",
        "Address1": "ที่อยู่ 1",
        "Address2": "ที่อยู่ 2",
        "Address3": "ที่อยู่ 3",
        "Address4": "ที่อยู่ 4",
        "Telephone": "เบอร์โทรศัพท์",
        "Fax": "แฟกซ์",
        "Contact": "ชื่อผู้ติดต่อ",
        "Email": "อีเมล",
        "ContactDetails": "รายละเอียดการติดต่อ",
        "Division": "แผนก",
        "Contact": "ชื่อผู้ติดต่อ",
        "Telephone": "เบอร์โทรศัพท์",
        "Fax": "แฟกซ์",
        "Email": "อีเมล",
        "DeskDetails": "รายละเอียด Desk",
        "Function": "ฟังก์ชั่น",
        
    },
    "in": {
        "GSLOfficeDetails": "Detail Kantor GSL",
        "Getourvariousofficedetails": "Dapatkan berbagai detail kantor kami",
        "From": "Dari",
        "Find": "Temukan",
        "GeneralInfo": "Informasi Umum",
        "CountryName": "Nama Negara",
        "PortName": "Nama Pelabuhan",
        "CompanyName": "Nama Perusahaan",
        "Address1": "Alamat1",
        "Address2": "Alamat2",
        "Address3": "Alamat3",
        "Address4": "Alamat4",
        "Telephone": "Telepon",
        "Fax": "Facs",
        "Contact": "Kontak",
        "Email": "Email",
        "ContactDetails": "Detail Kontak",
        "Division": "Divisi",
        "Contact": "Kontak",
        "Telephone": "Telepon",
        "Fax": "Facs",
        "Email": "Email",
        "DeskDetails": "Detail Meja",
        "Function": "Fungsi",
      
    },
    "ja": {
        "GSLOfficeDetails": "GSLオフィス詳細",
        "Getourvariousofficedetails": "各拠点の詳細",
        "From": "ここから",
        "Find": "検索",
        "GeneralInfo": "一般情報",
        "CountryName": "国名",
        "PortName": "都市名",
        "CompanyName": "会社名",
        "Address1": "住所1",
        "Address2": "住所2",
        "Address3": "住所3",
        "Address4": "住所4",
        "Telephone": "電話番号",
        "Fax": "ファックス番号",
        "Contact": "連絡先",
        "Email": "電子メール",
        "ContactDetails": "連絡先詳細",
        "Division": "事業部",
        "Contact": "連絡先",
        "Telephone": "電話番号",
        "Fax": "ファックス",
        "Email": "電子メール",
        "DeskDetails": "デスク詳細",
        "Function": "部署名",
      
    },
}
export default homelang;