import React, { Component } from 'react';
import Layout from '../../../components/layout';
import GET_IN_TOUCH from '../../../components/get_in_touch/index';
import Services from '../../../components/get_in_touch/services';
import Packages from '../../../components/get_in_touch/packages_block';
import Inner_container from '../../innerpages/inner-container';
import Api from '../../../../package.json'
import Moment from 'moment';
import * as axios from 'axios';
import abt_icn from '../../../../src/assets/abt-icn.png';
import './style.css'
import {
    NavLink as Link
} from "react-router-dom";
import newsletter_lang from '../../lang/newsletter'


const qs = require('qs');
var jwt = require('jsonwebtoken');


export default class Newsletter extends Component {
    timeout = null;
    constructor(props) {

        super(props);
        this.state = {

            country_code: window.$country_code,
            newletter_list: [],


        };

    }
    async componentDidMount() {

        const headers = {
           
            type: "application/json",
            Authorization: `Bearer `+window.localStorage.getItem('access_token'),
          };
          var lang = window.localStorage.getItem("lang");
        const response = await axios.post(Api.userdata.url + '/newletter_list', qs.stringify({ 'country_code': this.state.country_code, 'lang': lang }, { parseArrays: false }), { headers: headers });
        const json = await response.data;
        this.setState({ newletter_list: json['message'] });



    }
    open_dem() {
        var selectElem = document.getElementById('filenamee').value;
        window.open(selectElem);

    }
    render() {
        var lang = window.localStorage.getItem('lang');
        return (

            <Layout>
                <Inner_container innerpage="newsletter" />
                <section class="inner-center n-40">
                    <div class="container">
                        <form class="abt-full">
                            <div class="row ml-0 mr-0 ">

                                <div class="col-lg-12 col-sm-12 pot-form pl-0  wow fadeInDown" data-wow-delay=".2s"  >
                                    <div class="abt-text-con">
                                        
                                        <h3>{newsletter_lang[lang].Newsletter}</h3>


                                    </div>

                                    <div class="press-release-base">
                                        <div class="container">
                                            {this.state.newletter_list.map(data => {
                                                var date = Moment(data['createdon']).format('DD-MM-YYYY');
                                                var time = Moment(data['createdon']).format('hh:mm');

                                                return <div class="press-release-inner">
                                                    <h3><Link to={"/view_newsletter/" + data['id']}>{data['title']}</Link></h3>

                                                    <h6>{date + " | " + time}</h6>
                                                    <div class="press-release-content">
                                                        <p>{data['short_des']}</p>
                                                    </div>
                                                    <div class="press-release-readmore">
                                                        <Link to={"/view_newsletter/" + data['id']}>{newsletter_lang[lang].ReadMore} <span> {'>'} </span></Link>
                                                    </div>
                                                </div>
                                            })}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </section>
                <Services />
                <Packages />
                <GET_IN_TOUCH />
            </Layout >);
    }
}