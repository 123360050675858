import React, { Suspense, lazy, useEffect, useState } from "react";
import 'react-tabs/style/react-tabs.css';
import insidepage_banner from '../../../src/assets/insidepage_banner.png';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import submis_ic_white from '../../../src/assets/submis-ic-white.png';
import search_ic from '../../../src/assets/search-ic.png';
import cargo_tr from '../../../src/assets/cargo-tr.png';
import tare_weight_ic from '../../../src/assets/tare-weight-ic.png';
import schedule_ic from '../../../src/assets/schedule-ic.png';
import submis_ic from '../../../src/assets/submis-ic.png';
import about_us_bg from "../../../src/assets/about-us-bg.png";
import inner_demo_img from '../../../src/assets/inner-demo-img.jpg';
import Cargo_Catalogue from '../../../src/assets/Cargo-Catalogue.jpg';
import Banner_shape from '../../../src/assets/banner-shape.svg';
import innerpagejson_en from '../../../src/components/en/innerpage';
import innerpagejson_cn from '../../../src/components/cn/innerpage';
import innerpagejson_zh from '../../../src/components/zh/innerpage';
import innerpagejson_ko from '../../../src/components/ko/innerpage';
import innerpagejson_vi from '../../../src/components/vi/innerpage';
import innerpagejson_th from '../../../src/components/th/innerpage';
import innerpagejson_in from '../../../src/components/in/innerpage';
import innerpagejson_ja from '../../../src/components/ja/innerpage';

var lang = window.localStorage.getItem("lang");
if (window.localStorage.getItem("lang") == null) {
    lang = 'en';
    window.localStorage.setItem("lang", 'en');
}

const Inner_container = ({ innerpage, titles, description, bgimage }) => {

    const currentPathName = window.location.href.split('/');
    var title = currentPathName[currentPathName.length - 1].replaceAll("_", " ");
    const [currentPathNameNew, setCurrentPathNameNew] = useState(title);
    const [country_code, setCountryCode] = useState(window.$country_code);

    useEffect(() => {

    }, [currentPathNameNew]);

    if (lang == 'en') {
        return (
            <Suspense fallback={<div>Loading ... </div>}>
                <section className="about-us">
                    <div className="container-fluid inner-container" style={{ backgroundImage: (bgimage=='' || typeof(bgimage) === 'undefined')?`url(${innerpagejson_en[innerpage].url})`:`url(${bgimage})` }}>
                    <div className="banner-design" style={{ backgroundImage: `url(${innerpagejson_en[innerpage].shape_color})`}} >
                        <div className="container">
                            <div className="row">
                            
                                <div className="inner-container-section-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                     {/* <img src={Banner_shape}/> */}
                                    <h6  style={{ color: innerpagejson_en[innerpage].font_color}}>{(titles != null) ? titles : innerpagejson_en[innerpage].title}</h6>
                                    <p  style={{ color: innerpagejson_en[innerpage].font_color}}>{(description != null) ? description : innerpagejson_en[innerpage].discription}</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>

            </Suspense>
        );
    } else if (lang == 'cn') {
        return (
            <Suspense fallback={<div>Loading ... </div>}>
                <section className="about-us">

                                 <div className="container-fluid inner-container" style={{ backgroundImage: (bgimage=='' || typeof(bgimage) === 'undefined')?`url(${innerpagejson_cn[innerpage].url})`:`url(${bgimage})` }}>
                    <div className="banner-design" style={{ backgroundImage: `url(${innerpagejson_cn[innerpage].shape_color})`}} >
                        <div className="container">
                            <div className="row">
                            
                                <div className="inner-container-section-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                     {/* <img src={Banner_shape}/> */}
                                    <h6 style={{ color: innerpagejson_cn[innerpage].font_color}}>{(titles != null) ? titles : innerpagejson_cn[innerpage].title}</h6>
                                    <p style={{ color: innerpagejson_cn[innerpage].font_color}}>{(description != null) ? description : innerpagejson_cn[innerpage].discription}</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>

            </Suspense>
        );
    }
    else if (lang == 'zh') {
        return (
            <Suspense fallback={<div>Loading ... </div>}>
                <section className="about-us">
                <div className="container-fluid inner-container" style={{ backgroundImage: (bgimage=='' || typeof(bgimage) === 'undefined')?`url(${innerpagejson_zh[innerpage].url})`:`url(${bgimage})` }}>
                    <div className="banner-design" style={{ backgroundImage: `url(${innerpagejson_zh[innerpage].shape_color})`}} >
                        <div className="container">
                            <div className="row">
                            
                                <div className="inner-container-section-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                     {/* <img src={Banner_shape}/> */}
                                    <h6 style={{ color: innerpagejson_zh[innerpage].font_color}}>{(titles != null) ? titles : innerpagejson_zh[innerpage].title}</h6>
                                    <p style={{ color: innerpagejson_zh[innerpage].font_color}}>{(description != null) ? description : innerpagejson_zh[innerpage].discription}</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>

            </Suspense>
        );
    }
    else if (lang == 'ko') {
        return (
            <Suspense fallback={<div>Loading ... </div>}>
                <section className="about-us">
                <div className="container-fluid inner-container" style={{ backgroundImage: (bgimage=='' || typeof(bgimage) === 'undefined')?`url(${innerpagejson_ko[innerpage].url})`:`url(${bgimage})` }}>
                    <div className="banner-design" style={{ backgroundImage: `url(${innerpagejson_ko[innerpage].shape_color})`}} >
                        <div className="container">
                            <div className="row">
                            
                                <div className="inner-container-section-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                     {/* <img src={Banner_shape}/> */}
                                    <h6 style={{ color: innerpagejson_ko[innerpage].font_color}}>{(titles != null) ? titles : innerpagejson_ko[innerpage].title}</h6>
                                    <p style={{ color: innerpagejson_ko[innerpage].font_color}}>{(description != null) ? description : innerpagejson_ko[innerpage].discription}</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>

            </Suspense>
        );
    }else if (lang == 'vi') {
        return (
            <Suspense fallback={<div>Loading ... </div>}>
                <section className="about-us">
                <div className="container-fluid inner-container" style={{ backgroundImage: (bgimage=='' || typeof(bgimage) === 'undefined')?`url(${innerpagejson_vi[innerpage].url})`:`url(${bgimage})` }}>
                    <div className="banner-design" style={{ backgroundImage: `url(${innerpagejson_vi[innerpage].shape_color})`}} >
                        <div className="container">
                            <div className="row">
                            
                                <div className="inner-container-section-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                     {/* <img src={Banner_shape}/> */}
                                    <h6 style={{ color: innerpagejson_vi[innerpage].font_color}}>{(titles != null) ? titles : innerpagejson_vi[innerpage].title}</h6>
                                    <p style={{ color: innerpagejson_vi[innerpage].font_color}}>{(description != null) ? description : innerpagejson_vi[innerpage].discription}</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>

            </Suspense>
        );
    }else if (lang == 'th') {
        return (
            <Suspense fallback={<div>Loading ... </div>}>
                <section className="about-us">
                <div className="container-fluid inner-container" style={{ backgroundImage: (bgimage=='' || typeof(bgimage) === 'undefined')?`url(${innerpagejson_th[innerpage].url})`:`url(${bgimage})` }}>
                    <div className="banner-design" style={{ backgroundImage: `url(${innerpagejson_th[innerpage].shape_color})`}} >
                        <div className="container">
                            <div className="row">
                            
                                <div className="inner-container-section-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                     {/* <img src={Banner_shape}/> */}
                                    <h6 style={{ color: innerpagejson_th[innerpage].font_color}}>{(titles != null) ? titles : innerpagejson_th[innerpage].title}</h6>
                                    <p style={{ color: innerpagejson_th[innerpage].font_color}}>{(description != null) ? description : innerpagejson_th[innerpage].discription}</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>

            </Suspense>
        );
    }else if (lang == 'in') {
        return (
            <Suspense fallback={<div>Loading ... </div>}>
                <section className="about-us">
                <div className="container-fluid inner-container" style={{ backgroundImage: (bgimage=='' || typeof(bgimage) === 'undefined')?`url(${innerpagejson_in[innerpage].url})`:`url(${bgimage})` }}>
                    <div className="banner-design" style={{ backgroundImage: `url(${innerpagejson_in[innerpage].shape_color})`}} >
                        <div className="container">
                            <div className="row">
                            
                                <div className="inner-container-section-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                     {/* <img src={Banner_shape}/> */}
                                    <h6 style={{ color: innerpagejson_in[innerpage].font_color}}>{(titles != null) ? titles : innerpagejson_in[innerpage].title}</h6>
                                    <p style={{ color: innerpagejson_in[innerpage].font_color}}>{(description != null) ? description : innerpagejson_in[innerpage].discription}</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>

            </Suspense>
        );
    }else if (lang == 'ja') {
        return (
            <Suspense fallback={<div>Loading ... </div>}>
                <section className="about-us">
                <div className="container-fluid inner-container" style={{ backgroundImage: (bgimage=='' || typeof(bgimage) === 'undefined')?`url(${innerpagejson_ja[innerpage].url})`:`url(${bgimage})` }}>
                    <div className="banner-design" style={{ backgroundImage: `url(${innerpagejson_ja[innerpage].shape_color})`}} >
                        <div className="container">
                            <div className="row">
                            
                                <div className="inner-container-section-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                     {/* <img src={Banner_shape}/> */}
                                    <h6 style={{ color: innerpagejson_ja[innerpage].font_color}}>{(titles != null) ? titles : innerpagejson_ja[innerpage].title}</h6>
                                    <p style={{ color: innerpagejson_ja[innerpage].font_color}}>{(description != null) ? description : innerpagejson_ja[innerpage].discription}</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>

            </Suspense>
        );
    }else{
        return (
            <Suspense fallback={<div>Loading ... </div>}>
                <section className="about-us">
                <div className="container-fluid inner-container" style={{ backgroundImage: (bgimage=='' || typeof(bgimage) === 'undefined')?`url(${innerpagejson_en[innerpage].url})`:`url(${bgimage})` }}>
                    <div className="banner-design" style={{ backgroundImage: `url(${innerpagejson_en[innerpage].shape_color})`}} >
                        <div className="container">
                            <div className="row">
                            
                                <div className="inner-container-section-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                     {/* <img src={Banner_shape}/> */}
                                    <h6 style={{ color: innerpagejson_en[innerpage].font_color}}>{(titles != null) ? titles : innerpagejson_en[innerpage].title}</h6>
                                    <p style={{ color: innerpagejson_en[innerpage].font_color}}>{(description != null) ? description : innerpagejson_en[innerpage].discription}</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>

            </Suspense>
        );
    }

}
export default Inner_container;